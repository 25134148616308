<div class="accounting-process__content p-3 my-3" [class.accounting-process__content--add]="isNew">
	<div class="d-inline-flex align-items-center w-50 mb-3" [class.has-error]="!form.valid">
		<h4 class="text-nowrap mb-1 me-2">
			@if (isNew) {
			Add New Accounting Operation:
			} @else {
			Accounting Operation:
			}
		</h4>
		<input type="text" class="accounting-process__content-input form-control" [formControl]="form.controls.name"
			placeholder="Add new value" data-cy="OperationNameInput">
		<button class="btn btn-info btn-sm ms-2" (click)="saveAccountingOperation()" [disabled]="!form?.valid"
			[hidden]="!form?.controls?.name?.dirty || (isSaving$ | async)" data-cy="SaveButton">
			Save
		</button>
	</div>
	<div ngbAccordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false">
		<div ngbAccordionItem="triggers" #triggers="ngbAccordionItem" [collapsed]="false">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : triggers.collapsed}" ngbAccordionButton data-cy="TriggersToggleAccordion">
					<span class="cursor-pointer">
						<ng-container *ngIf="isNew">Add </ng-container>Triggers
					</span>
					<span class="panel__ops">
						@if (triggersComplete) {
						<span class="d-inline-block text-success-light">
							<i class="fas fa-check-circle"></i>
						</span>
						}
						<span class="d-inline-block ms-3 text-center" style="width:30px;" data-cy="ExpandCollapseArrow">
							<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
								[title]="triggers.collapsed ? 'Expand' : 'Collapse'"
								[attr.data-cy]="triggers.collapsed ? 'ToExpand' : 'ToCollapse'"
								[class.open]="!triggers.collapsed"></i>
						</span>
					</span>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						<rl-add-accounting-operation-triggers [triggersFormArray]="form.controls.triggers"
							[anchorTemplate]="anchorTemplate" [disabled]="!form.valid">
						</rl-add-accounting-operation-triggers>
					</ng-template>
				</div>
			</div>
		</div>
		<div ngbAccordionItem="events" #events="ngbAccordionItem" [disabled]="!triggersComplete">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : events.collapsed}" ngbAccordionButton data-cy="EventsToggleAccordion">
					<span class="cursor-pointer">
						<ng-container *ngIf="isNew">Add </ng-container>Events
					</span>
					<span class="panel__ops">
						<span *ngIf="eventsComplete" class="d-inline-block text-success-light">
							<i class="fas fa-check-circle"></i>
						</span>
						<span class="d-inline-block ms-3 text-center" style="width:30px;" data-cy="ExpandCollapseArrow">
							<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
								[title]="events.collapsed ? 'Expand' : 'Collapse'"
								[attr.data-cy]="events.collapsed ? 'ToExpand' : 'ToCollapse'"
								[class.open]="!events.collapsed">
							</i>
						</span>
					</span>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						<rl-add-accounting-operation-events [eventsFormArray]="form.controls.events"
							[anchorTemplate]="anchorTemplate" [accounts]="accounts" [accountGroups]="accountGroups" [workflowTrigger]="workflowTrigger">
						</rl-add-accounting-operation-events>
					</ng-template>
				</div>
			</div>
		</div>
		<ng-container *rlFeatureEnabled="'accProPhase2'">
			<div ngbAccordionItem="dates" #dates="ngbAccordionItem" [disabled]="!eventsComplete">
				<div ngbAccordionHeader>
					<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
						[ngClass]="{'collapsed' : dates.collapsed}" ngbAccordionButton data-cy="DateToggleAccordion">
						<span class="cursor-pointer">
							Date Population
						</span>
						<span class="panel__ops">
							<span *ngIf="datesComplete" class="d-inline-block text-success-light">
								<i class="fas fa-check-circle"></i>
							</span>
							<span class="d-inline-block ms-3 text-center" style="width:30px;"
								data-cy="ExpandCollapseArrow">
								<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
									[title]="events.collapsed ? 'Expand' : 'Collapse'"
									[attr.data-cy]="events.collapsed ? 'ToExpand' : 'ToCollapse'"
									[class.open]="!events.collapsed">
								</i>
							</span>
						</span>
					</button>
				</div>
				<div ngbAccordionCollapse>
					<div ngbAccordionBody>
						<ng-template>
							<rl-add-accounting-operation-dates [datesFormArray]="form.controls.dates" [anchorTemplate]="anchorTemplate" [anchorTemplatesDates]="anchorTemplatesDates"
							[operationTriggers]="operationTriggers" [operationId]="operation?.id">
							</rl-add-accounting-operation-dates>
						</ng-template>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>