import { transition, trigger, useAnimation } from "@angular/animations";
import { NgClass } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { every } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { AccountingProcessActiveIndicator } from "rl-common/services/accounting-processes/models/accounting-process-active-indicator";
import { IAccountingOperation } from "rl-common/services/accounting-processes/models/i-accounting-operation";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { IAccountingProcessDetails } from "rl-common/services/accounting-processes/models/i-accounting-process-details";
import { ICreateAccountingOperation } from "rl-common/services/accounting-processes/models/i-create-accounting-operation";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Subscription } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { AccountOperationComponent } from "../account-operation/account-operation.component";
import { BuildAccountingOperationComponent } from "../build-accounting-operation/build-accounting-operation.component";

@Component({
	selector: "rl-accounting-operations",
	templateUrl: "./accounting-operations.component.html",
	styleUrls: ["./accounting-operations.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, BuildAccountingOperationComponent, AccountOperationComponent]
})
export class AccountingOperationsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

	@Input()
	accountingProcess: IAccountingProcessDetails;

	@Input()
	openPanelIds = [];

	@Input()
	editMode = false;

	@Input()
	accounts: IAccountingProcessAccount[] = [];

	@Input()
	accountGroups: IAccountingProcessAccountGroup[] = [];

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	anchorTemplatesDates: { [key: number]: ICharacteristicMetaData[] } = {};

	@Output()
	onEdit = new EventEmitter<IAccountingOperation>();

	@Output()
	onDelete = new EventEmitter<IAccountingOperation>();

	get accountingOperations(): IAccountingOperation[] {
		return this.accountingProcess?.operations ?? [];
	}

	get isArchived() {
		return this.accountingProcess?.activeIndicator === AccountingProcessActiveIndicator.Archived;
	}

	get hasContractAdjustments() {
		return this.accountingProcess?.mostRecentlySent?.length > 0;
	}

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	@ViewChildren("item")
	accordionItemsQueryList: QueryList<NgbAccordionItem>;

	accordionItems: NgbAccordionItem[] = [];

	get isAllCollapsed() {
		return every(this.accordionItems, item => item.collapsed);
	}

	deletingOperations: Set<string> = new Set<string>();
	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _oneConfig: OneConfigService,
		private readonly _accountingProcessService: AccountingProcessesService,
		private readonly _promptService: PromptService
	) { }

	ngOnInit(): void {
		this.updateAnchorTemplate();
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.selectedProcess && changes.accountingProcess.currentValue && changes.accountingProcess.previousValue?.id !== changes.accountingProcess.currentValue?.id) {
			this.updateAnchorTemplate();
		}
	}

	ngAfterViewInit(): void {
		this.accordionItems = this.accordionItemsQueryList.toArray();
	}

	private updateAnchorTemplate() {
		if (this.accountingProcess) {
			this.anchorTemplate = this._oneConfig.getTemplate(CharTypeId.Amount, this.accountingProcess.anchorTemplateId);
		}
	}

	operationSaved(event: ICreateAccountingOperation) {
		const operation = this.accountingProcess.operations.find(x => x.id == event.id);
		operation.operationName = event.operationName;
	}

	editOperation(operation: IAccountingOperation) {
		this.onEdit.emit(operation);
	}

	deleteOperation(deleteProcessPrompt: TemplateRef<unknown>, operation: IAccountingOperation) {
		const sub = this._promptService.confirm("Delete Accounting Operation", deleteProcessPrompt, { operation }).pipe(
			filter(confirm => confirm),
			tap(() => this.deletingOperations.add(operation.id)),
			switchMap(() => this._accountingProcessService.deleteAccountingOperation(operation.id))
		).subscribe(() => {
			this.onDelete.emit(operation);
		});
		this._subs.push(sub);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
