
export class DateAlerts {

	static defaultSubject(recordId?: number, title?: string): string {
		const subjectParts = [
			`Rightsline Date Alert`,
			recordId > -1 ? recordId : null,
			title
		];
		return subjectParts.filter(x => !!x).join(` - `);
	}

}