<div class="modal-header">
	<h2 class="modal-title" [innerText]="modalTitle"></h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body scroll-vert">
	<rl-component-relationship [dataSource]="dataSource" [assocCharTypeId]="assocCharTypeId"
		(onEntityRelationshipChange)="updateEntityRelationship($event)">
	</rl-component-relationship>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()"
		data-cy="SelectAssociationCancelButton">Cancel</button>
	<button class="btn btn-success ms-2" type="button" (click)="apply()"
		data-cy="SelectAssociationSaveButton">Save</button>
</div>