import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ISecurityPolicy, IWfRole } from "rl-common/services/company/company.models";
import { UserService } from "rl-common/services/user/user.service";
import { combineLatest } from "rxjs";
import { finalize } from "rxjs/operators";
import { NgFor } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@Component({
    selector: "rl-select-wf-roles",
    templateUrl: "./select-wf-roles.component.html",
    styleUrls: ["./select-wf-roles.component.scss"],
    imports: [NgFor, ReactiveFormsModule, FormsModule]
})
export class SelectWfRolesComponent implements OnInit {

	workflowRoles: IWfRole[] = [];
	selectedWfRoles: Set<number> = new Set<number>();
	policies: ISecurityPolicy[] = [];
	rolesLoaded = false;

	@Output()
	onChange = new EventEmitter<number[]>();

	constructor(
		private readonly _userService: UserService,
	) { }

	getRoles() {
		combineLatest([this._userService.getRoles(), this._userService.getPolicies()]).pipe(
			finalize(() => {
				this.rolesLoaded = true;
			})
		).subscribe(
			([roles, policies]) => {
				this.policies = policies;
				this.workflowRoles = roles.workflowRoles;
			});
	}

	toggleRole(role: IWfRole) {
		const roleId = role.roleID;
		if (this.selectedWfRoles.has(roleId)) {
			this.selectedWfRoles.delete(roleId);
		} else {
			this.selectedWfRoles.add(roleId);
		}
		const selected = Array.from(this.selectedWfRoles);
		this.onChange.next(selected);
	}

	getPolicyName(id: string) {
		return this.policies.find(x => x.securityPolicyID === id)?.policyTitle;
	}

	ngOnInit(): void {
		this.getRoles();
	}

}
