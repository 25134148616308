import { Component, OnInit, Input } from "@angular/core";
import { IRecentActivityItem } from "rl-common/services/entity-audit-history/entity-audit-history.models";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { NewTabInModalDirective } from "../../../../directives/new-tab-in-modal.directive";
import { RouterLink } from "@angular/router";
import { CharTypeNamePipe } from "../../../../pipes/char-type-name.pipe";
import { LocalDatePipe } from "../../../../pipes/local-date.pipe";

@Component({
    selector: "rl-recent-activity-item",
    templateUrl: "./recent-activity-item.component.html",
    styleUrls: ["./recent-activity-item.component.scss"],
    imports: [NewTabInModalDirective, RouterLink, CharTypeNamePipe, LocalDatePipe]
})
export class RecentActivityItemComponent implements OnInit {
	@Input()
	recentActivityItem: IRecentActivityItem;

	cssClass: string;
	constructor(
		public readonly _linkHelper: LinkHelperService
	) { }

	ngOnInit() {
		this.cssClass = CharTypeIdUtil.toCssClass(this.recentActivityItem.charTypeId);
	}

}
