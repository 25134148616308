<div class="modal-header" data-cy="ValidationErrorsModalHeader">
	<h2 class="modal-title" [attr.data-cy]="title + '_Title'">{{ title }}
		<!--TODO: Make this work up here  - {{result.key}} -->
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="dismiss()"
		data-cy="ValidationErrorsButtonXClose">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" data-cy="ValidationErrorsModalBody">
	<ul ngbNav #nav="ngbNav" class="nav-tabs">
		<li ngbNavItem id="avails-validation" *ngIf="calcInProgress">
			<a ngbNavLink data-cy="AvailsValidationTab">
				Avails Calculation
			</a>
			<ng-template ngbNavContent>
				{{availsCalcInProgressErrorMessage}}
			</ng-template>
		</li>
		<li ngbNavItem id="entity-validation" *ngIf="hasErrors$ | async">
			<a ngbNavLink data-cy="DataAssociationsTab">
				Data & Associations
			</a>
			<ng-template ngbNavContent>
				<div *ngFor="let result of allValidationErrors.serverErrors">
					<ul class="list-unstyled mb-0">
						<li>{{result.value}}</li>
					</ul>
					<div>
						Workflow may be enabled, but there may be {{result.key}} errors that are not displayed.
						Proceed with caution when taking workflow actions.
					</div>
				</div>
				<ng-container *ngIf="(canExecuteWorkflow$ | async) === false && (allValidationErrors.errors.length > 0 ||
					allValidationErrors.dbValidationErrors.length > 0)">
					Workflow Actions are disabled until the following validation errors are resolved:
				</ng-container>
				<div *ngFor="let result of allValidationErrors.errors">
					<ul class="list-unstyled mb-0">
						<li *ngFor="let v of result.value" class="py-2 border-bottom" [innerHTML]="v"></li>
					</ul>
				</div>
				<div>
					<ul class="list-unstyled mb-0">
						<li class="py-2 border-bottom" *ngFor="let error of allValidationErrors.dbValidationErrors">
							<rl-validation-message [validationError]="error"></rl-validation-message>
						</li>
					</ul>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem id="rights-validation" *ngIf="hasConflictCheckError">
			<a ngbNavLink data-cy="RightsConflictsTab">
				Rights Conflicts
				<span class="btn-icon btn-info btn-sm ms-1 py-0" data-pendo="tip-rights-conflicts" role="button"
					aria-label="Tooltip"><i class="far fa-question-circle"></i></span>
			</a>
			<ng-template ngbNavContent>
				<rl-avails-status></rl-avails-status>
				<div *ngIf="!isUpdatingConflictCheckStatus">
					<h5 class="my-3">Workflow Actions are disabled until either all workflow blocking errors are
						reconciled or set to an Ignored (Non-Blocker or Resolved) status.</h5>

					<div class="d-flex justify-content-end align-items-center mb-3">
						<rl-lov-filter [lovCmds]="rightsetCMDs$ | async" [isLoading]="isFiltering"
							[lovDict]="lovDict$ | async" [lovFilters]="rightsFilters.lovFilter"
							(onFilterChanged)="lovFilterChanged($event)" class="me-auto">
						</rl-lov-filter>

						<div class="input-group flex-shrink-1 align-items-end w-auto me-2 pe-3 border-end">
							<input type="text" class="form-control form-control-sm" placeholder="Catalog Search"
								[(ngModel)]="rightsGroupFilters.taggedCatalogKeywords" (keyup.enter)="searchChanged()"
								data-cy="CatalogSearchField" />
							<button class="btn btn-icon btn-sm btn-info border-bottom" (click)="searchChanged()"
								data-cy="CatalogSearchButton" title="Search"><i class="far fa-search"></i></button>
						</div>

						<button class="btn btn-icon btn-info ms-2" [ngbPopover]="dateFilterPopover" [autoClose]="false"
							placement="bottom-right" data-cy="RightsCalendarButton" title="Filter by Dates">
							<i class="far fa-calendar-alt"></i>
						</button>

						<rl-custom-views [charTypeId]="0" [reportType]="GridViewReportType.ConflictList"
							[gridViewColumnStrategy]="dataSource.columnStrategy" [isConflictCheck]="true">
						</rl-custom-views>

						<div [autoClose]="'outside'" ngbDropdown placement="bottom-right">
							<button class="btn btn-icon btn-info hide-caret ms-2" ngbDropdownToggle role="button"
								aria-label="Expand Menu">
								<i class="far fa-bars"></i>
							</button>
							<div ngbDropdownMenu class="p-2" style="width:250px;">
								<ul ngbNav #nestednav="ngbNav" class="nav-tabs">
									<li ngbNavItem>
										<a ngbNavLink>
											<i class="far fa-th-list" title="Conflicts" data-cy="ConflictsTab"></i>
										</a>

										<ng-template ngbNavContent>
											<div class="list-group list-group-flush">
												<h6 class="dropdown-header">Conflicts</h6>
												<button class="list-group-item" (click)="exportCsv()">
													Export CSV
												</button>
											</div>
										</ng-template>
									</li>
									<li ngbNavItem *ngIf="canOverrideConflictCheck">
										<a ngbNavLink>
											<i class="far fa-chevron-circle-right" title="Workflow"
												data-cy="WorkflowTab"></i>
										</a>

										<ng-template ngbNavContent>
											<div class="list-group list-group-flush">
												<h6 class="dropdown-header">Workflow</h6>
												<span style="color:#F90;"
													*ngIf="(canResolveConflicts$ | async) === false">
													Reduced Actions- Only Assignee can update Conflict statuses
												</span>
												<span style="color:#F90;"
													*ngIf="!canUpdateToBlocked && !canUpdateToNotBlocked && dataSelectStrategy.selectedState.selectedIds.size > 0">
													Reduced Actions- Only Conflicts of the same status can be updated in
													bulk
												</span>
												@if(rowsAreSelected$ | async) {
												<button class="list-group-item"
													[disabled]="!canUpdateToBlocked || (canResolveConflicts$ | async) === false"
													(click)="setStatus(true)">
													Set to {{getConflictActionLabel(false)}}
												</button>
												<button class="list-group-item"
													[disabled]="!canUpdateToNotBlocked || (canResolveConflicts$ | async) === false"
													(click)="setStatus(false)">
													Set to {{getConflictActionLabel(true)}}
												</button>
												}
												@else {
												<button class="list-group-item" disabled>
													Select records to take allowable Actions.
												</button>
												}
												<button *ngIf="canAssignConflict$ | async" ngbDropdownItem
													rlDebounceClick (debounceClick)="assignConflicts()"
													[debounceTime]="500"
													[disabled]="dataSelectStrategy.selectedState.selectedIds.size <= 0 && !dataSelectStrategy.selectedState.isAllSelected"
													data-cy="AssignConflict">
													Assign Selected Conflicts ({{
													dataSelectStrategy.selectCounts$.value[3] ?? "0" }})
												</button>

											</div>
										</ng-template>
									</li>
								</ul>
								<div [ngbNavOutlet]="nestednav"></div>

							</div>
						</div>

					</div>

					<rl-grid [options]="gridOptions" [dataSource]="dataSource" data-cy="ValidationErrorsRLGrid">
						<div class="text-truncate" *rlCellTemplate="'status'; let cellData;"
							[class]="getStatusClass(cellData.conflictStatusLabel)"
							[title]="cellData.conflictStatusLabel">
							<i class="fa fa-minus-circle"></i>
						</div>

						<div class="text-truncate" *rlCellTemplate="'date-range'; let cellData">
							{{cellData.startDate | localeDate}} to {{cellData.endDate | localeDate}}
						</div>

						<div class="text-truncate" *rlCellTemplate="'conflict-check-issue'; let cellData">
							<rl-conflict-check-issue [issue]="cellData"></rl-conflict-check-issue>
						</div>

						<ng-container *rlGridSubgridTemplate="let rowPath = rowPath; let rowData = rowData">
							<rl-conflicts-subgrid [rowPath]="rowPath" [rowData]="rowData"
								[allowActions]="limitedByClearanceRightsActions" [refresh$]="refreshSubgrids$">
							</rl-conflicts-subgrid>
						</ng-container>
					</rl-grid>
				</div>

				<div *ngIf="isUpdatingConflictCheckStatus">
					<rl-conflict-check-update-status [isBlocker]="isBlocker"
						[selectedState]="dataSelectStrategy.selectedState" [filterQueryNodes]="filterQueryNodes"
						[alternativeLabelConflictTypeIds]="alternateLabelConflictTypeIds"
						(onCancel)="isUpdatingConflictCheckStatus = false" (onSave)="onSave()">
					</rl-conflict-check-update-status>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem id="financial-validation" *ngIf="financialErrors.length > 0">
			<a ngbNavLink data-cy="FinancialTab">
				Financial
			</a>
			<ng-template ngbNavContent>
				<ul class="list-unstyled">
					<li *ngFor="let error of financialErrors" class="py-2 border-bottom">{{error}}</li>
				</ul>
			</ng-template>
		</li>
		<li ngbNavItem id="royalty-processing-errors" *ngIf="royaltyErrors.length > 0">
			<a ngbNavLink data-cy="RoyaltyProcessingErrorsTab">
				Royalty Processing Errors
			</a>
			<ng-template ngbNavContent>
				<ul class="list-unstyled">
					<li *ngFor="let error of royaltyErrors" class="py-2 border-bottom">{{error}}</li>
				</ul>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>

</div>
<div class="modal-footer" *ngIf="!isUpdatingConflictCheckStatus" data-cy="ValidationErrorsModalFooter">
	<button type="button" class="btn btn-info" (click)="close()" data-cy="ValidationErrorsButtonClose">Close</button>
</div>

<ng-template #dateFilterPopover>
	<div class="popover-close d-block text-end">
		<button type="button" class="btn btn-close" aria-label="Close" (click)="closePopover()">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="d-flex">
		<label style="width:70px;">Start Date</label>
		<rl-date-input [formControl]="startDateControl"></rl-date-input>
	</div>
	<div class="d-flex">
		<label style="width:70px;">End Date</label>
		<rl-date-input [formControl]="endDateControl"></rl-date-input>
	</div>
	<div class="text-danger" *ngIf="form?.hasError('invalidRange')">End date must be after the start date.</div>
	<div class="mt-2 text-end">
		<button class="btn btn-sm btn-light" (click)="clearDateFilters()" [disabled]="isDatesEmpty">Reset</button>
		<button class="btn btn-sm btn-info ms-2" (click)="applyDateFilters()" [disabled]="!form?.valid">Apply</button>
	</div>
</ng-template>