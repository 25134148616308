import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { IWfAction } from "rl-common/services/company/company.models";
import { ISelectedChainedAction, IWFAction, IWFProcessConfig } from "rl-common/services/entity-config/entity-config.models";
import { IWorkflowActionForm } from "../../workflow-action-edit.models";
import { NgFor } from "@angular/common";

@Component({
    selector: "rl-chained-action-row",
    templateUrl: "./chained-action-row.component.html",
    styleUrls: ["./chained-action-row.component.scss"],
    imports: [ReactiveFormsModule, FormsModule, NgFor]
})
export class ChainedActionRowComponent implements OnInit {
	filteredNextActions: IWFAction[];

	@Input()
	form: FormGroup<IWorkflowActionForm>;

	@Input()
	processes: IWFProcessConfig[];

	@Input()
	actions: IWfAction[];

	@Input()
	chainedActionControl: FormControl<ISelectedChainedAction>;

	constructor() { }

	ngOnInit(): void {
		this.getNextActions(this.chainedActionControl.value.nextActionId);
	}

	getNextActions(actionId: number = null) {
		const processId = this.chainedActionControl?.value?.processId;

		if (processId) {
			const process = this.processes.find(p => p.processID === processId);
			const processLabel = process ? `${process.processID} ${process.processName}` : `WF Process ${processId}`;
			const nextActionName = this.actions.find(a => a.actionID === actionId)?.actionName ?? "";
			this.filteredNextActions = process?.actions ?? [];
			this.chainedActionControl.setValue({
				processId,
				processLabel,
				nextActionId: actionId ? actionId : null,
				nextActionLabel: nextActionName,
			}, { emitEvent: false });
		}
	}

	omitChainedActionOption(id: string) {
		return this.form.controls.chainedActions.value.map(ca => ca.processLabel).includes(id);
	}
}
