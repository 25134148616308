import { Injectable } from "@angular/core";
import * as d3 from "d3";
import { DonutChartTooltipPositionMode, IChartProperties, ISVGProperties } from "./donut-chart.models";


@Injectable({ providedIn: "root" })
export class DonutChartService {
	private numberFormatWithCommasNoDecimals = /\B(?=(\d{3})+(?!\d))/g;
	private propertiesByDonutChart: IChartProperties[];

	constructor() {
		this.propertiesByDonutChart = [];
	}

	private setChartProperties(
		chartId: string,
		svgChartElement: HTMLElement
	): void {
		const chartProperties = {
			chartId: chartId,
			properties: {
				svgNode: svgChartElement,
				svgProperties: <ISVGProperties>{}
			}
		};

		this.setSvgProperties(chartProperties);

		const existingChartPropertiesItemIndex = this.propertiesByDonutChart.findIndex(x => x.chartId === chartId);

		if (existingChartPropertiesItemIndex >= 0) {
			this.propertiesByDonutChart[existingChartPropertiesItemIndex] = chartProperties;
		} else {
			this.propertiesByDonutChart.push(chartProperties);
		}
	}

	private setSvgProperties(chartProperties: IChartProperties): void {
		chartProperties.properties.svgProperties.position = {
			left: chartProperties.properties.svgNode.getBoundingClientRect().left,
			top: chartProperties.properties.svgNode.getBoundingClientRect().top
		};

		chartProperties.properties.svgProperties.dimensions = {
			width: (chartProperties.properties.svgNode.clientWidth || chartProperties.properties.svgNode.parentElement.clientWidth),
			height: (chartProperties.properties.svgNode.clientHeight || chartProperties.properties.svgNode.parentElement.clientHeight)
		};

		chartProperties.properties.svgProperties.centerPoint = {
			left: chartProperties.properties.svgProperties.position.left + (chartProperties.properties.svgProperties.dimensions.width / 2),
			top: chartProperties.properties.svgProperties.position.top + (chartProperties.properties.svgProperties.dimensions.height / 2)
		};
	}

	private addChartTitle(
		gTopLevel: d3.Selection<SVGGElement, unknown, null, undefined>,
		chartTitleHeader: string,
		chartTitleDescription: string
	): void {
		// Add our title and sub header in the center of the donut chart
		const centerText = gTopLevel.append("text");

		centerText
			.append("tspan")
			.attr("text-anchor", "middle")
			.attr("x", 0)
			.attr("font-family", "'Plus Jakarta Sans', sans-serif")
			.attr("font-size", "28px")
			.attr("font-weight", "bold")
			.text(chartTitleHeader);

		centerText
			.append("tspan")
			.attr("text-anchor", "middle")
			.attr("x", 0)
			.attr("dy", 22)
			.attr("font-family", "'Plus Jakarta Sans', sans-serif")
			.attr("font-size", "12px")
			.text((chartTitleDescription.length > 18 ? chartTitleDescription.substr(0, 18) + "..." : chartTitleDescription))
			.append("title")
			.text(chartTitleDescription);
	}

	private showTooltipForChart(
		dataPoint: d3.PieArcDatum<number | { valueOf(): number; }>,
		donutChartService: any,
		chartId: string,
		chartTooltipElement: HTMLElement,
		tooltipPositionMode: DonutChartTooltipPositionMode
	): void {
		const chartProperties = donutChartService.propertiesByDonutChart.find(x => x.chartId === chartId);
		const tooltipPosition = { left: d3.event.pageX, top: d3.event.pageY }; // Position the tooltip such that it tracks cursor (default behavior, sort of)
		const chartTooltipTailInnerElement = chartTooltipElement.getElementsByClassName("donutChartTooltipTailInner")[0];

		const currentRadius: number = (Math.min(chartProperties.properties.svgProperties.dimensions.width, chartProperties.properties.svgProperties.dimensions.height) / 2);

		const currentOuterRadius: number = currentRadius - 1;

		if (tooltipPositionMode === DonutChartTooltipPositionMode.ArcMidpoint) {
			const angleRadians = ((dataPoint.startAngle + dataPoint.endAngle) / 2) - (Math.PI / 2);

			const midPointCenter = {
				left: chartProperties.properties.svgProperties.centerPoint.left + (Math.cos(angleRadians) * currentOuterRadius),
				top: chartProperties.properties.svgProperties.centerPoint.top + (Math.sin(angleRadians) * currentOuterRadius)
			};

			tooltipPosition.left = midPointCenter.left;
			tooltipPosition.top = midPointCenter.top;
			// Position the tooltip on the outer edge of the arc that currently has focus, tracking the cursor's location
		} else if (tooltipPositionMode === DonutChartTooltipPositionMode.ArcOuterEdge) {
			const dataPointToCenterPointDeltaX = d3.event.pageX - chartProperties.properties.svgProperties.centerPoint.left;
			const dataPointToCenterPointDeltaY = d3.event.pageY - chartProperties.properties.svgProperties.centerPoint.top;
			const angleDataPointToCenterPoint = Math.atan2(dataPointToCenterPointDeltaY, dataPointToCenterPointDeltaX);
			let angleDataPointToCenterPointAdjusted = angleDataPointToCenterPoint + 1.5708;

			angleDataPointToCenterPointAdjusted = (angleDataPointToCenterPointAdjusted < 0
				? angleDataPointToCenterPointAdjusted + (2 * Math.PI)
				: angleDataPointToCenterPointAdjusted);

			const angleRadiansCurrent = angleDataPointToCenterPointAdjusted - (Math.PI / 2);

			const currentPointOnEdge = {
				left: chartProperties.properties.svgProperties.centerPoint.left + (Math.cos(angleRadiansCurrent) * currentOuterRadius),
				top: chartProperties.properties.svgProperties.centerPoint.top + (Math.sin(angleRadiansCurrent) * currentOuterRadius)
			};

			tooltipPosition.left = currentPointOnEdge.left;
			tooltipPosition.top = currentPointOnEdge.top;
		}

		chartTooltipElement.style.left = "";
		chartTooltipElement.style.right = "";
		chartTooltipElement.style.top = "";
		chartTooltipElement.style.bottom = "";

		chartTooltipElement.classList.remove("donutChartTooltipPanelTopRight");
		chartTooltipElement.classList.remove("donutChartTooltipPanelBottomRight");
		chartTooltipElement.classList.remove("donutChartTooltipPanelBottomLeft");
		chartTooltipElement.classList.remove("donutChartTooltipPanelTopLeft");

		const chartTooltipTailElement = chartTooltipElement.getElementsByClassName("donutChartTooltipTail")[0];

		chartTooltipTailElement.classList.remove("donutChartTooltipTailTopRight");
		chartTooltipTailElement.classList.remove("donutChartTooltipTailBottomRight");
		chartTooltipTailElement.classList.remove("donutChartTooltipTailBottomLeft");
		chartTooltipTailElement.classList.remove("donutChartTooltipTailTopLeft");

		chartTooltipTailInnerElement.classList.remove("donutChartTooltipTailInnerTopRight");
		chartTooltipTailInnerElement.classList.remove("donutChartTooltipTailInnerBottomRight");
		chartTooltipTailInnerElement.classList.remove("donutChartTooltipTailInnerBottomLeft");
		chartTooltipTailInnerElement.classList.remove("donutChartTooltipTailInnerTopLeft");

		// Position the tooltip, to the left/top of the cursor in the top right quadrant, bottom/right of cursor in bottom right quadrant, etc
		let isTooltipOnLeftHalfOfChart = tooltipPosition.left <
			chartProperties.properties.svgProperties.position.left + (chartProperties.properties.svgProperties.dimensions.width / 2);

		let isTooltipOnTopHalfOfChart = tooltipPosition.top <
			chartProperties.properties.svgProperties.position.top + (chartProperties.properties.svgProperties.dimensions.height / 2);

		// Try and improve experience a bit for scenarios where tooltip would otherwise be cutoff by edge of screen
		// Position tooltip, to not fall off the screen to the left
		if (isTooltipOnLeftHalfOfChart && tooltipPosition.left - chartTooltipElement.clientWidth < 0) {
			isTooltipOnLeftHalfOfChart = false;
			// Position tooltip, to not fall off the screen to the right
		} else if (!isTooltipOnLeftHalfOfChart && tooltipPosition.left + chartTooltipElement.clientWidth > window.innerWidth) {
			isTooltipOnLeftHalfOfChart = true;
		}

		// Position tooltip, to not fall off the screen to the top
		if (isTooltipOnTopHalfOfChart && tooltipPosition.top - chartTooltipElement.clientHeight < 0) {
			isTooltipOnTopHalfOfChart = false;
			// Position tooltip, to not fall off the screen to the bottom
		} else if (!isTooltipOnLeftHalfOfChart && tooltipPosition.top + chartTooltipElement.clientHeight > window.innerHeight) {
			isTooltipOnTopHalfOfChart = true;
		}

		if (isTooltipOnLeftHalfOfChart) {
			tooltipPosition.left = tooltipPosition.left - chartTooltipElement.clientWidth; // Position it to the left of cursor
		}

		if (isTooltipOnTopHalfOfChart) {
			tooltipPosition.top = tooltipPosition.top - chartTooltipElement.clientHeight; // Position it to the left of cursor
		}

		let tooltipPanelQuadrantClass: string;
		let chartTooltipTailElementClass: string;
		let donutChartTooltipTailInnerElementClass: string;

		if (!isTooltipOnLeftHalfOfChart && isTooltipOnTopHalfOfChart) {
			tooltipPanelQuadrantClass = "donutChartTooltipPanelTopRight";
			chartTooltipTailElementClass = "donutChartTooltipTailTopRight";
			donutChartTooltipTailInnerElementClass = "donutChartTooltipTailInnerTopRight";
		} else if (!isTooltipOnLeftHalfOfChart && !isTooltipOnTopHalfOfChart) {
			tooltipPanelQuadrantClass = "donutChartTooltipPanelBottomRight";
			chartTooltipTailElementClass = "donutChartTooltipTailBottomRight";
			donutChartTooltipTailInnerElementClass = "donutChartTooltipTailInnerBottomRight";
		} else if (isTooltipOnLeftHalfOfChart && !isTooltipOnTopHalfOfChart) {
			tooltipPanelQuadrantClass = "donutChartTooltipPanelBottomLeft";
			chartTooltipTailElementClass = "donutChartTooltipTailBottomLeft";
			donutChartTooltipTailInnerElementClass = "donutChartTooltipTailInnerBottomLeft";
		} else if (isTooltipOnLeftHalfOfChart && isTooltipOnTopHalfOfChart) {
			tooltipPanelQuadrantClass = "donutChartTooltipPanelTopLeft";
			chartTooltipTailElementClass = "donutChartTooltipTailTopLeft";
			donutChartTooltipTailInnerElementClass = "donutChartTooltipTailInnerTopLeft";
		}

		chartTooltipElement.classList.add(tooltipPanelQuadrantClass);
		chartTooltipTailElement.classList.add(chartTooltipTailElementClass);
		chartTooltipTailInnerElement.classList.add(donutChartTooltipTailInnerElementClass);

		const tooltipPositionTailOffsetLeft = (isTooltipOnLeftHalfOfChart ? -chartTooltipTailInnerElement.clientWidth : chartTooltipTailInnerElement.clientWidth);

		tooltipPosition.left += tooltipPositionTailOffsetLeft;

		chartTooltipElement.style.left = `${tooltipPosition.left}px`;
		chartTooltipElement.style.top = `${tooltipPosition.top}px`;

		chartTooltipElement.getElementsByClassName("donutChartTooltipTitle")[0].textContent = (<any>dataPoint).data.name;
		chartTooltipElement.getElementsByClassName("donutChartTooltipDescription")[0].textContent =
			(<any>dataPoint).value.toString().replace(donutChartService.numberFormatWithCommasNoDecimals, ",");

		chartTooltipElement.style.opacity = "1";
	}

	private hideTooltipForChart(chartTooltipElement: HTMLElement): void {
		chartTooltipElement.style.opacity = "0";
	}

	public drawDonutChart(
		chartId: string,
		svgChartElement: HTMLElement,
		chartTitleHeader: string,
		chartTitleDescription: string,
		chartTooltipElement: HTMLElement,
		data: { name: string, value: number }[],
		options: { width: number, height: number },
		tooltipPositionMode: DonutChartTooltipPositionMode = DonutChartTooltipPositionMode.ArcMidpoint
	): void {
		const width: number = options.width;
		const height: number = options.height;
		const radius: number = Math.min(width, height) / 2;
		const innerRadius: number = radius * 0.67;
		const outerRadius: number = radius - 1;

		const arcGenerator = d3.arc()
			.innerRadius(innerRadius)
			.outerRadius(outerRadius);

		const tweenArc = function (b) {
			const interpolateFn = d3.interpolate({ startAngle: 0, endAngle: 0 }, b);
			return function (t) { return arcGenerator(interpolateFn(t)); };
		};

		const arcData = d3.pie()
			.value((dataPoint: any) => dataPoint.value)
			(<any>data);

		const svg = d3.select(svgChartElement)
			.attr("viewBox", <any>[0, 0, width, height])
			.attr("text-anchor", "middle");

		const gTopLevel = svg
			.append("g")
			.attr("transform", `translate(${width / 2},${height / 2})`)
			.attr("class", "arc");

		const gAll = gTopLevel
			.selectAll(".arc")
			.data(arcData)
			.enter()
			.append("g");

		const paths = gAll.append("path");

		const colors = [
			"#4F3099", //primary
			"#288077", //secondary turquoise, darkened*
			"#FF5050", //danger medium
			"#08c", //secondary aqua dark, darkened*
			"#A297E0", //primary medium
			"#7074d2", //secondary navy, lightened*
			"#F90", //warning
			"#3CBCAF", //secondary turquoise
			"#5cc3ff", //secondary aqua, lightened*
			"#3A40B6", //secondary navy
			//"#FFCC4D", //warning medium
		];

		const getColor = d3
			.scaleOrdinal()
			.domain(data.map(d => d.name))
			.range(colors);

		paths.attr("fill", d => <string>getColor((<any>d.data).name))
			.transition()
			.duration(1000)
			.attrTween("d", <any>tweenArc);

		this.addChartTitle(gTopLevel, chartTitleHeader, chartTitleDescription);

		paths
			.on("mouseenter", (dataPoint) => {
				const donutChartService = this;
				donutChartService.setChartProperties(chartId, svgChartElement);
			})
			.on("mousemove", (dataPoint) => {
				const donutChartService = this;
				this.showTooltipForChart(dataPoint, donutChartService, chartId, chartTooltipElement, tooltipPositionMode);
			})
			.on("mouseout", () => {
				this.hideTooltipForChart(chartTooltipElement);
			});
	}
}
