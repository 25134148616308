<div class="modal-header mb-3">
	<h2 class="modal-title">
		Bulk Create Amounts
	</h2>
	<button *ngIf="step !== 'in-progress'" type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="p-3">
	<rl-panel-switcher [activeId]="step" class="no-panel">
		<rl-panel id="bulk-create">
			<ng-template rlPanelContent>
				<rl-bulk-create-amounts-form [templateId]="templateId" [bulkConfig]="bulkConfig"
					[dataSource]="dataSource" (onUpdated)="updateFormData($event)">
				</rl-bulk-create-amounts-form>

				<div *ngIf="dataSource">
					<rl-grid #tableGrid [dataSource]="dataSource" [options]="tableGridOptions"
						data-cy="BulkCreateAmountRLGrid">
						<ng-container
							*rlGridNestedTemplate="let amountsDataSource;let rowPath = rowPath; let parentRowData = parentRowData">
							<div class="mx-4 mt-2 mb-4">
								<rl-bulk-nested-amount-grid [tableDataSource]="dataSource"
									[amountsDataSource]="amountsDataSource" [parentRowData]="parentRowData"
									[rowPath]="rowPath">
								</rl-bulk-nested-amount-grid>
							</div>
						</ng-container>
					</rl-grid>
				</div>

				<div class="row mt-3">
					<div class="col-12 text-end">
						<button class="btn btn-light" (click)="cancel()"
							data-cy="CancelBulkCreateAmount">Cancel</button>
						<button class="btn btn-success ms-3" [disabled]="isInvalid" (click)="bulkCreate()"
							data-cy="CreateBulkAmount">Create</button>
					</div>
				</div>
			</ng-template>
		</rl-panel>

		<rl-panel id="in-progress">
			<ng-template rlPanelContent>
				<div class="modal-body">
					<rl-job-progress [jobId]="jobId" (onComplete)="complete()" *ngIf="jobId"></rl-job-progress>
				</div>
			</ng-template>
		</rl-panel>
	</rl-panel-switcher>
</div>