import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { EntityConfigUtil } from "config/utils/entity-config.util";
import { Dictionary, groupBy } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { CharTypeId } from "rl-common/consts";
import { IWfAction, IWfRole, IWfStep } from "rl-common/services/company/company.models";
import { ICharType, ISelectedChainedAction, ISelectedNotification, IWFAction, IWFActionConfig, IWFActionSaveRequest, IWFEditActionConfig, IWFParty, IWFProcessConfig, IWFStepConfig, IWFUpdatePointProcIdRequest } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { IWorkflowActionExtensionResponse } from "rl-common/services/workflow/workflow.messages";
import { IPartyNotification, IPartyWithNotif, IWorkflowNotification } from "rl-common/services/workflow/workflow.models";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { DocumentOptionsUtil } from "rl-common/utils/document-options.util";
import { Subscription } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { WorkflowProcessesService } from "../../../workflow-processes.service";
import { IKeyValueOption, IWfProcessAndActions, IWorkflowActionForm, PointProcIds, duplicateNotificationsControlValidator, notificationControlValidator, workflowActionRequiredFieldsValidator } from "./workflow-action-edit.models";
import { NgIf, NgFor } from "@angular/common";
import { TextInputComponent } from "../../../../../../../common/components/text/text-input/text-input.component";
import { TextAreaComponent } from "../../../../../../../common/components/text/text-area/text-area.component";
import { CheckboxInputComponent } from "../../../../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { WorkflowAssociatedRolesComponent } from "./workflow-associated-roles/workflow-associated-roles.component";
import { WorkflowChainedActionComponent } from "./workflow-chained-action/workflow-chained-action.component";
import { WorkflowNotificationComponent } from "./workflow-notification/workflow-notification.component";

@Component({
    selector: "rl-workflow-action-edit",
    styleUrls: ["./workflow-action-edit.component.scss"],
    templateUrl: "./workflow-action-edit.component.html",
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: "250ms ease-out"
                    }
                })
            ])
        ])
    ],
    imports: [NgIf, TextInputComponent, ReactiveFormsModule, TextAreaComponent, CheckboxInputComponent, DropdownSingleComponent, WorkflowAssociatedRolesComponent, WorkflowChainedActionComponent, WorkflowNotificationComponent, NgFor]
})
export class WorkflowActionEditComponent implements OnInit, OnDestroy {

	_subs: Subscription[] = [];
	roles: IWfRole[];
	charTypes: ICharType[];
	actions: IWFAction[][];
	charTypeId: number;
	processId: number;
	actionId = -1;
	dummyCharTypeId = -1;
	title: string;
	formLoaded = false;
	form: FormGroup<IWorkflowActionForm>;
	isSaving = false;
	setActionName: string;
	initialActionBool: boolean;
	initialActionNum: number;
	nextStatus: IWfStep;
	allowActionDuringValidations: boolean = false;
	allowActionWithBlockers: boolean = false;
	wfEditActionConfig: IWFEditActionConfig;
	partyWithNotifs: IPartyWithNotif[];
	wfExtensionResult: IWorkflowActionExtensionResponse;
	allRoles: IWfRole[];
	preselectedRoleNotifs: IWfRole[] = [];
	processes: IWFProcessConfig[];
	allParties: IWFParty[];
	allSteps: DropdownOptions<IWfStep> = {
		items: [],
		rowKeyFn: (type: IWfStep) => type.stepID,
		rowLabelFn: (type: IWfStep) => type.stepName
	};
	notifications: IWorkflowNotification[];
	nextStatuses: IWFStepConfig;
	filteredNextActions: IWFAction[];
	documentOptionsList: DropdownOptions<IKeyValueOption> = {
		items: [],
		rowKeyFn: (type: IKeyValueOption) => type.key,
		rowLabelFn: (type: IKeyValueOption) => type.value
	};
	selectedChainedActionDict: Dictionary<[number, number]> = {};
	selectedRoleNotifDict: Dictionary<[number, number]> = {};
	selectedPartyNotifications: IPartyNotification[];
	selectedStepIds: Dictionary<[number, number]> = {};
	selectedRoleIds: number[] = [];

	@Input()
	wfActionModel: IWFActionConfig;

	@Output()
	onComplete = new EventEmitter<boolean>();

	get isCreate() {
		return this.wfActionModel && this.wfActionModel.actionID > 0 ? false : true;
	}

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService,
		private readonly _workflowProcessesService: WorkflowProcessesService
	) { }

	ngOnInit() {
		this.getDocumentOptionList();

		const sub = this._workflowProcessesService.fetchCharTypeProcessIds$().pipe(
			tap(result => {
				this.charTypeId = result[0];
				this.processId = result[1];

				if (!this.isCreate) {
					this.actionId = this.wfActionModel.actionID;
					this.setActionName = this.wfActionModel.actionName;
				}

				this.setTitle();
			}),
			switchMap(() => this._workflowProcessesService.workflowProcessesData$),
			tap(results => {
				this.processes = results;
				this.actions = this.processes.map(x => x.actions);
			}),
			switchMap(() => this._entityConfigService.getParties()),
			tap(results => {
				this.allParties = results;
			}),
			switchMap(() => this._entityConfigService.getNotifications(this.processId)),
			tap(results => this.notifications = results),
			switchMap(() => this._entityConfigService.getWFEditActionsPartyListAndExtensions(this.dummyCharTypeId, this.processId, this.actionId)),
			tap(results => {
				this.wfEditActionConfig = results.wfEditActionConfig;
				this.partyWithNotifs = results.partyWithNotifs;
				this.wfExtensionResult = results.wfExtensionResult;
				this.allRoles = results.wfEditActionConfig.allRoles.sort((a, b) => a.roleID - b.roleID);
				this.allSteps.items = results.wfEditActionConfig.allSteps;

				if (!this.isCreate) {
					this.initialActionBool = this.wfActionModel.pointProcID !== 1 ? false : true;
					this.nextStatus = this.allSteps.items.find(s => s.stepName === this.wfActionModel.nextStep);
					this.allowActionDuringValidations = this.wfEditActionConfig.allowActionDuringValidations;
					this.allowActionWithBlockers = this.wfEditActionConfig.allowActionWithBlockers;
					this.wfActionModel.selectedAssociatedRoleLabels = this.wfEditActionConfig.selectedRoles;
					this.wfActionModel.selectedRoleNotifications = this.buildNotificationsMap();
					this.wfActionModel.selectedChainedActions = this.buildChainedActionsMap();
					this.buildForm(this.wfActionModel);
				} else {
					this.buildForm();
				}
			})
		).subscribe();

		this._subs.push(sub);
	}

	setTitle() {
		const charTypeName = CharTypeIdUtil.toReportLabel(this.charTypeId);
		this.title = this.isCreate ? "Create New Workflow Action" : `Edit ${charTypeName} Workflow Action: ${this.setActionName}`;
	}

	getDefaultWorkflowStepById(stepId = null): IWfStep {
		return {
			stepID: stepId,
			processID: null,
			divisionID: null,
			stepName: null,
			stepDescription: null,
			lockIndicator: null,
			activeIndicator: null,
			deleteIndicator: null,
			sequenceNumber: null,
			systemIndicator: null,
			createdBy: null,
			createdAt: null,
			updatedBy: null,
			updatedAt: null,
			actionName: null
		}
	}

	buildForm(model: IWFActionConfig = null) {
		if (model) {
			this.form = this._fb.group<IWorkflowActionForm>({
				actionId: this._fb.control(this.wfActionModel.actionID),
				actionName: this._fb.control(this.wfActionModel.actionName),
				actionDescription: this._fb.control(this.wfActionModel.actionDescription),
				initialAction: this._fb.control({ value: this.initialActionBool, disabled: this.initialActionBool === true }),
				nextStatus: this._fb.control(this.nextStatus),
				docIndicator: this._fb.control({ key: this.wfActionModel.docGenIndicator, value: null }),
				associatedRoles: this._fb.control(this.wfActionModel.selectedAssociatedRoleLabels),
				chainedActions: new FormArray<FormControl<ISelectedChainedAction>>(this.wfActionModel.selectedChainedActions.map(a => new FormControl(a))),
				notifications: new FormArray<FormControl<ISelectedNotification>>(this.wfActionModel.selectedRoleNotifications.map(n => new FormControl(n, notificationControlValidator))),
				allowActionDuringValidations: this._fb.control(this.allowActionDuringValidations),
				allowActionWithBlockers: this._fb.control(this.allowActionWithBlockers),
				updateCurrencyConversion: this._fb.control(this.wfActionModel.updateCurrencyConversion)
			}, { validators: [workflowActionRequiredFieldsValidator, duplicateNotificationsControlValidator] });
		} else {
			this.form = this._fb.group<IWorkflowActionForm>({
				actionId: this._fb.control(0),
				actionName: this._fb.control(null),
				actionDescription: this._fb.control(null),
				initialAction: this._fb.control(false),
				nextStatus: this._fb.control(this.getDefaultWorkflowStepById()),
				docIndicator: this._fb.control({ key: null, value: null }),
				associatedRoles: this._fb.control(null),
				chainedActions: new FormArray<FormControl<ISelectedChainedAction>>([]),
				notifications: new FormArray<FormControl<ISelectedNotification>>([].map(n => new FormControl(n, notificationControlValidator))),
				allowActionDuringValidations: this._fb.control(this.allowActionDuringValidations),
				allowActionWithBlockers: this._fb.control(this.allowActionWithBlockers),
				updateCurrencyConversion: this._fb.control(this.charTypeId === CharTypeId.Invoice ? false : null)
			}, { validators: [workflowActionRequiredFieldsValidator, duplicateNotificationsControlValidator] });
		}

		this.formLoaded = true;
	}

	buildNotificationsMap() {
		const outputArray = [] as ISelectedNotification[];
		const selectedRoleNotifs = this.wfEditActionConfig.selectedRoleNotifications;
		const selectedRoleIds = Object.entries(selectedRoleNotifs).reduce((acc, curr) => {
			const roleId = curr[0];
			const id = curr[1].id;
			if (!(id in acc)) {
				acc[id] = [];
			}
			acc[id].push(+roleId);
			return acc;
		}, []);
		const groupedNotifsByParty = groupBy(this.partyWithNotifs, "messageID");
		const selectedPartyArray: { [partyId: number]: boolean }[] = Object.entries(groupedNotifsByParty).reduce((acc, curr) => {
			const parties = curr[1].reduce((partyAcc, p) => {
				partyAcc[p.partyID] = p.notifyChildren;
				return partyAcc;
			}, {});
			acc[curr[0]] = parties;
			return acc;
		}, []);

		this.notifications.forEach(n => {
			const selectedRoles = Object.entries(selectedRoleIds).filter(x => +x[0] === n.id)[0];
			const partiesFound = Object.entries(selectedPartyArray).find(p => +p[0] === n.id);
			const selectedParties = partiesFound ? Object.entries(partiesFound[1]).map(([key, value]) => ({ [key]: value })) : [];

			if (selectedRoles !== undefined || selectedParties.length) {
				outputArray.push({
					notificationId: n.id,
					selectedRoleIds: selectedRoles ? selectedRoles[1] : [],
					selectedParties
				});
			}
		});

		return outputArray;
	}

	buildChainedActionsMap() {
		const outputArray = [] as ISelectedChainedAction[];
		const allProcesses = this.wfEditActionConfig.allProcesses;
		const selectedProcessIds = Object.keys(this.wfEditActionConfig.selectedChainedActions).map(Number);
		const selectedProcesses = allProcesses.filter(p => selectedProcessIds.indexOf(p.processID) !== -1);
		const selectedActions = this.processes.filter(p => selectedProcessIds.indexOf(p.processID) !== -1).reduce((acc, curr) => {
			acc[curr.processID] = curr.actions;
			return acc;
		}, {} as IWfAction);

		if (Object.keys(selectedActions).length) {
			const combined: IWfProcessAndActions[] = Object.values(selectedProcesses).map(p => Object.assign({}, p, { actions: selectedActions[p.processID] }));
			combined.forEach(a => {
				const label = `${a.processID} ${a.processName}`;
				const actions = a.actions;
				const actionId = Object.entries(this.wfEditActionConfig.selectedChainedActions).find(x => +x[0] === a.processID)[1];
				const actionName = actions.find(x => x.actionID === actionId).actionName;
				outputArray.push({
					processId: a.processID,
					processLabel: label,
					nextActionId: +actionId,
					nextActionLabel: actionName
				});
			});
		}

		return outputArray;
	}

	getDocumentOptionList() {
		for (const d of EntityConfigUtil.getDocumentOptions()) {
			if (d > -1) {
				this.documentOptionsList.items.push({ key: d, value: DocumentOptionsUtil.toDisplayName(d) });
			}
		}
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	private mapSelectedChainedActionIds() {
		const chainedActions = this.form.controls.chainedActions.value.filter(a => a.processId !== null && a.nextActionId !== null);

		if (chainedActions.length) {
			const dict = chainedActions.reduce((acc, curr) => {
				acc[curr.processId] = curr.nextActionId;
				return acc;
			}, {});

			this.selectedChainedActionDict = dict;
		}
	}

	private isEmptyNotifRow(notif: ISelectedNotification) {
		return notif.notificationId === null && !notif.selectedRoleIds.length && !notif.selectedParties.length;
	}

	private mapSelectedRoleNotifIds() {
		const notifications = this.form.controls.notifications.value.filter(n => !this.isEmptyNotifRow(n));

		if (notifications.length) {
			const dict = notifications.reduce((acc, curr) => {
				const { notificationId, selectedRoleIds } = curr;

				selectedRoleIds.forEach(id => {
					acc[id] = notificationId;
				});

				return acc;
			}, {});

			this.selectedRoleNotifDict = dict;
		}
	}

	private mapSelectedPartyNotifs() {
		const notifications = this.form.controls.notifications.value.filter(n => !this.isEmptyNotifRow(n));

		if (notifications.length) {
			const mappedNotifications: IPartyNotification[] = notifications.reduce((acc, curr) => {
				const { notificationId, selectedParties } = curr;
				const mappedParties = Object.values(selectedParties).map((partyObj: { [partyId: number]: boolean }) => {
					const [partyId] = Object.keys(partyObj).map(Number);
					const notifyChildren = partyObj[partyId] as boolean;
					return { partyId, notificationId, notifyChildren };
				});

				acc.push(...mappedParties);
				return acc;
			}, []);

			this.selectedPartyNotifications = mappedNotifications;
		}
	}

	private mapSelectedStepId() {
		const nextActionId = this.form.controls.nextStatus.value.stepID;
		const values = [{ key: this.processId, value: nextActionId }];
		const dict = values.reduce((acc, curr) => {
			acc[curr.key] = curr.value;
			return acc;
		}, {});

		this.selectedStepIds = dict;
	}

	private buildSubmitModelInputs() {
		this.initialActionNum = this.form.controls.initialAction?.value === true ? 1 : 0;
		this.selectedRoleIds = this.form.controls.associatedRoles?.value?.map(r => r.roleID);
		this.mapSelectedChainedActionIds();
		this.mapSelectedRoleNotifIds();
		this.mapSelectedPartyNotifs();
		this.mapSelectedStepId();
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._progressService.startProgress();
			let success = true;
			try {
				this.buildSubmitModelInputs();
				const model: IWFActionSaveRequest = {
					charTypeId: this.charTypeId,
					actionId: this.form.controls.actionId.value,
					actionName: this.form.controls.actionName.value,
					actionDescription: this.form.controls.actionDescription.value,
					docGenIndicator: this.form.controls.docIndicator.value.key,
					pointProcId: this.initialActionNum,
					processId: this.processId,
					selectedChainedActionIds: this.selectedChainedActionDict,
					selectedNotificationIds: this.selectedRoleNotifDict,
					selectedPartyNotifications: this.selectedPartyNotifications,
					selectedStepIds: this.selectedStepIds,
					selectedRoleIds: this.selectedRoleIds,
					createdBy: this.wfActionModel ? this.wfActionModel.createdBy : null,
					sequenceNumber: this.wfActionModel ? this.wfActionModel.sequenceNumber : null,
					allowActionDuringValidations: this.form.controls.allowActionDuringValidations.value,
					allowActionWithBlockers: this.form.controls.allowActionWithBlockers.value,
					updateCurrencyConversion: this.form.controls.updateCurrencyConversion.value ? this.form.controls.updateCurrencyConversion.value : false
				};

				if (model.pointProcId === PointProcIds.initialAction) {
					const filteredActions = this.actions.find(actions => actions[0]?.processID === this.processId);
					if (filteredActions) {
						const prcActWithInitialAction = filteredActions.find(a => a.pointProcID === PointProcIds.initialAction && a.actionID !== model.actionId);
						if (prcActWithInitialAction) {
							const pointProcIdModel: IWFUpdatePointProcIdRequest = {
								charTypeId: this.charTypeId,
								processId: this.processId,
								actionId: prcActWithInitialAction.actionID,
								pointProcId: PointProcIds.middleOrFinalAction
							};
							this._growlerService.warning().growl("If another action is set to the Initial Action, saving will overwrite and set the current action to the Initial Action.");
							await this._entityConfigService.updateActionPointProcId(pointProcIdModel).toPromise();
							await this._entityConfigService.saveWFAction(model).toPromise();
						} else {
							await this._entityConfigService.saveWFAction(model).toPromise();
						}
					} else {
						throw new Error(`No workflow process actions match the process Id ${this.processId}.`);
					}
				} else {
					await this._entityConfigService.saveWFAction(model).toPromise();
				}
			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				this.isSaving = false;
				if (success) {
					this.onComplete.next(true);
					this._growlerService.success().growl("Your changes were saved.");
				} else {
					this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
				}
			}
		}
	}

	close() {
		this.onComplete.next(null);
	}
}
