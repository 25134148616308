import { VisibilityIndicator } from "rl-common/consts";

export interface VisibilityIndicatorMeta {
	displayName: string;
	indId: number;
}

export namespace VisibilityIndicatorUtil {
	export const visibilityIndicatorLookup: { [key in VisibilityIndicator]: VisibilityIndicatorMeta } = {
		[VisibilityIndicator.Hidden]: {
			displayName: "Hidden",
			indId: 0
		},
		[VisibilityIndicator.InternalOnly]: {
			displayName: "Internal Only",
			indId: 1
		},
		[VisibilityIndicator.InternalExternal]: {
			displayName: "Both Internal & External",
			indId: 2
		}
	};

	export function toDisplayName(visibilityIndicator: VisibilityIndicator) {
		return visibilityIndicatorLookup[visibilityIndicator].displayName;
	}
}
