import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ComponentKey } from "rl-common/components/mod-details-layout/mod-layout.consts";
import { IPanelSection, toComponentKeyName } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { BuildAccountingTablesElementComponent } from "../build-accounting-tables-element/build-accounting-tables-element.component";
import { BuildAssociationGridElementComponent } from "../build-association-grid-element/build-association-grid-element.component";
import { BuildComponentGridElementComponent } from "../build-component-grid-element/build-component-grid-element.component";
import { BuildContactPartyGroupElementComponent } from "../build-contact-party-group-element/build-contact-party-group-element.component";
import { BuildPanelFieldSectionElementComponent } from "../build-panel-field-section-element/build-panel-field-section-element.component";
import { BuildPanelUdfGridElementComponent } from "../build-panel-udf-grid-element/build-panel-udf-grid-element.component";


@Component({
	selector: "rl-build-panel-element",
	templateUrl: "./build-panel-element.component.html",
	styleUrls: ["./build-panel-element.component.scss"],
	imports: [BuildPanelFieldSectionElementComponent, BuildContactPartyGroupElementComponent, BuildAccountingTablesElementComponent, BuildComponentGridElementComponent, BuildPanelUdfGridElementComponent, BuildAssociationGridElementComponent]
})
export class BuildPanelElementComponent implements OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<unknown>;

	@Input()
	componentKey: ComponentKey;

	@Output()
	onChange = new EventEmitter<IPanelSection<unknown>>();

	constructor() { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section && this.section) {
			this.componentKey = this.section.key;
		} else if (changes.componentKey) {
			this.keyChanged();
		}
	}

	keyChanged() {
		// clear out the params;
		this.section = {
			key: this.componentKey as ComponentKey,
			params: null
		};

		this.onChange.emit(this.section);
	}

	toComponentKeyName(key: ComponentKey) {
		return toComponentKeyName(key);
	}

	updateParams(params: unknown) {
		this.section.params = params;
		this.onChange.emit(this.section);
	}

}
