import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IWFActionConfig, IWFParty, IWFProcessConfig } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { TokenStorageService } from "rl-common/services/token-storage/token-storage.service";
import { BehaviorSubject, ReplaySubject, Subscription } from "rxjs";
import { delay, filter, switchMap, take, tap } from "rxjs/operators";

@Injectable()
export class WorkflowProcessesService implements OnDestroy {
	wfProcessOnComplete$ = new BehaviorSubject<boolean>(false);
	wfStatusOnComplete$ = new BehaviorSubject<boolean>(false);
	wfActionOnComplete$ = new BehaviorSubject<boolean>(false);
	wfNotifOnComplete$ = new BehaviorSubject<boolean>(false);
	workflowProcessesData$ = new ReplaySubject<IWFProcessConfig[]>(1);
	charTypeProcessIds$ = new ReplaySubject<[number, number]>(1);
	wfDocTemplateOnComplete$ = new BehaviorSubject<boolean>(false);


	charTypeId: number;
	processId: number;
	wfProcessesData: IWFProcessConfig[];
	workflowPartiesData: IWFParty[];
	actions: IWFActionConfig[];

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _entityConfigService: EntityConfigService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _tokenStorageService: TokenStorageService,
		private _route: ActivatedRoute
	) {
		const sub = this._entityConfigService.getWFProcesses().pipe(
			switchMap(results => {
				this.workflowProcessesData$.next(results);
				return this.wfProcessesData = results;
			}),
			switchMap(() => this._entityConfigService.getParties()),
			tap(results => {
				this.workflowPartiesData = results;
			})
		).subscribe();

		this._subs.push(sub);
	}

	fetchCharTypeProcessIds$() {
		let selectedProcess: IWFProcessConfig;

		return this._route.params.pipe(
			filter(params => !!params && "charTypeId" in params),
			delay(0),
			take(1),
			tap(params => {
				this.charTypeId = +params["charTypeId"];
				this.processId = +params["processId"];
			}),
			switchMap(() => {
				this.charTypeProcessIds$.next([this.charTypeId, this.processId]);
				return this.charTypeProcessIds$;
			})
		);
	}

	refreshOneConfig() {
		const sub = this._oneConfigService.refresh(this._tokenStorageService.jwt).subscribe();
		this._subs.push(sub);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}
}
