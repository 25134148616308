<div class="modal-header">
	<h2 class="modal-title">Locked Parent Records</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()" data-cy="closeModal">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<p>Bulk Edit is disabled due to the following selected component records having locked parents:</p>

	<div *ngFor="let record of recordsWithLockedParents | keyvalue" class="pb-3">
		<button class="btn btn-icon" (click)="expandedRows[record.key.recordID] = !expandedRows[record.key.recordID]">
			<i class="far" [class.fa-angle-right]="!expandedRows[record.key.recordID]"
				[class.fa-angle-down]="expandedRows[record.key.recordID]"
				[attr.data-cy]="'OpenItem' + record.key.recordID"></i>
		</button>
		<strong class="ms-2">{{ record.key.recordID }} - {{ record.key.title }}</strong>

		<ul *ngIf="expandedRows[record.key.recordID]"
			class="list-group list-group-flush border-top ms-4 mt-2 mb-4 w-75">
			<li *ngFor="let parent of record.value" class="list-group-item border-bottom py-2">
				{{ parent.charTypeID | charTypeName }} {{ parent.recordID }} - {{ parent.title }}
			</li>
		</ul>
	</div>

	<p>Deselect the above records to continue with Bulk Edit.</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-info ms-3" (click)="accept()" data-cy="closeButton">Close</button>
</div>