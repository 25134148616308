import { NgFor } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IWfAction } from "rl-common/services/company/company.models";
import { ISelectedChainedAction, IWFProcessConfig } from "rl-common/services/entity-config/entity-config.models";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { IWorkflowActionForm } from "../workflow-action-edit.models";
import { ChainedActionRowComponent } from "./chained-action-row/chained-action-row.component";

@Component({
	selector: "rl-workflow-chained-action",
	templateUrl: "./workflow-chained-action.component.html",
	styleUrls: ["./workflow-chained-action.component.scss"],
	imports: [ReactiveFormsModule, NgFor, ChainedActionRowComponent]
})
export class WorkflowChainedActionComponent implements OnInit, OnDestroy {
	private readonly _subs: Subscription[] = [];

	@Input()
	actions: IWfAction[];

	@Input()
	form: FormGroup<IWorkflowActionForm>;

	@Input()
	processes: IWFProcessConfig[];

	// @ViewChildren(WorkflowActionEditComponent)
	// chainedActions: QueryList<WorkflowActionEditComponent>;

	get chainedActionsFormArray() {
		return this.form.controls.chainedActions;
	}

	get chainedActionsIsEmpty() {
		return this.chainedActionsFormArray && this.chainedActionsFormArray.length === 0;
	}

	get chainedActionsFormControls() {
		return this.chainedActionsFormArray && this.chainedActionsFormArray.controls;
	}

	get chainedActionsAllInUse() {
		return this.form.controls.chainedActions.length === this.actions.length;
	}

	constructor() { }

	ngOnInit(): void {
		const sub = this.chainedActionsFormArray.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(() => {
			const newValue = this.chainedActionsFormArray.value;
			this.form.controls.chainedActions.setValue(newValue, { emitEvent: false });
		});

		this._subs.push(sub);
	}

	addNewRow() {
		this.chainedActionsFormArray.push(new FormControl<ISelectedChainedAction>({
			processId: null,
			processLabel: "",
			nextActionId: null,
			nextActionLabel: ""
		}));
	}

	removeRow(index: number) {
		this.chainedActionsFormArray.removeAt(index);
	}

	clearRows() {
		this.chainedActionsFormArray.clear();
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
