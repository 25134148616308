<div *ngIf="triggerControls.length > 0">
	<ng-container *ngFor="let triggerControl of triggerControls;let first = first; let i=index">
		<strong *ngIf="!first" class="d-block p-2 pb-1">AND</strong>
		<ng-container *ngIf="editIndex === i">
			<div class="mt-2">
				<rl-add-accounting-operation-trigger [trigger]="triggerControl.value" [anchorTemplate]="anchorTemplate"
					[otherTriggers]="otherTriggers" [disabled]="disabled" (onSave)="updateTrigger($event)"
					(onCancel)="cancelEdit(i)">
				</rl-add-accounting-operation-trigger>
			</div>
		</ng-container>
		<ng-container *ngIf="editIndex !== i">
			<div class="d-flex align-items-center p-1 trigger-item">
				<div class="flex-grow-1">
					<rl-accounting-operation-trigger [anchorTemplateId]="anchorTemplate?.templateID"
						[trigger]="triggerControl.value">
					</rl-accounting-operation-trigger>
				</div>
				<div class="trigger-item__buttons">
					<button class="btn btn-sm btn-icon me-2" (click)="editTrigger(i)" [disabled]="isEditing"
						title="Edit" [attr.data-cy]="'editTrigger' + i">
						<i class="far fa-pencil"></i>
					</button>
					<button class="btn btn-sm btn-icon" (click)="removeTrigger(i, confirmDeletePrompt)"
						[disabled]="isEditing" title="Delete" [attr.data-cy]="'deleteTrigger' + i">
						<i class="far fa-trash-alt"></i>
					</button>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<div *ngIf="!isEditing" class="d-flex">
		<strong class="p-2">AND</strong>
		<button class="btn btn-sm btn-link" (click)="addTrigger(false)">Add Another Trigger</button>
	</div>
</div>
<div *ngIf="triggerControls.length === 0">
	<div class="alert alert-warning">
		No Triggers Added.
	</div>
	<button class="mt-2 btn btn-sm btn-link" (click)="addTrigger(false)">+ Add a Trigger</button>
</div>

<ng-template #confirmDeletePrompt let-prompt="prompt">
	<div>
		<p>
			Are you sure you want to delete this Trigger?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="Cancel">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)" data-cy="Delete">
			Delete
		</button>
	</div>
</ng-template>