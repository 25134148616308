@switch(componentKey) {
@case("field-section") {
<rl-build-panel-field-section-element [templateMetaData]="templateMetaData" [section]="section"
	(paramsChange)="updateParams($event)">
</rl-build-panel-field-section-element>
}
@case("contact-party-group") {
<rl-build-contact-party-group-element [templateMetaData]="templateMetaData" [section]="section"
	(paramsChange)="updateParams($event)">
</rl-build-contact-party-group-element>
}
@case("accounting-tables") {
<rl-build-accounting-tables-element [templateMetaData]="templateMetaData" [section]="section"
	(paramsChange)="updateParams($event)">
</rl-build-accounting-tables-element>
}
@case("component-grid") {
<rl-build-component-grid-element [templateMetaData]="templateMetaData" [section]="section"
	(paramsChange)="updateParams($event)">
</rl-build-component-grid-element>
}
@case("udf-lookup") {
<rl-build-panel-udf-grid-element [section]="section" (paramsChange)="updateParams($event)">
</rl-build-panel-udf-grid-element>
}
@case("association-grid") {
<rl-build-association-grid-element [templateMetaData]="templateMetaData" [section]="section"
	(paramsChange)="updateParams($event)">
</rl-build-association-grid-element>
}
}