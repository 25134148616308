import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { some } from "lodash";
import { EntityRelationshipComponent, ISelectedChangeEvent } from "rl-common/components/associations/entity-relationships/entity-relationship/entity-relationship.component";
import { CharTypeId } from "rl-common/consts";
import { SearchType } from "rl-common/factories";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { IDirectionChangedEvent } from "../../new-association.models";
import { RelationshipTypeSelectComponent } from "../relationship-type-select/relationship-type-select.component";
import { IGridDataSource } from "./../../../grid/datasource/grid-datasource";

export interface IAssociationsSelectedEvent {
	selected: IRecordTitle[];
}

@Component({
	selector: "rl-select-associations",
	templateUrl: "./select-associations.component.html",
	styleUrls: ["./select-associations.component.scss"],
	imports: [NgIf, RelationshipTypeSelectComponent, ReactiveFormsModule, FormsModule, EntityRelationshipComponent]
})
export class SelectAssociationsComponent implements OnInit {
	@Input()
	dataSource: IGridDataSource<number, IEntitySearchDoc>;

	@Input()
	parentTitle: string;

	@Input()
	charTypeId: CharTypeId;

	@Input()
	selectedAssociations: IRecordTitle[] = [];

	@Input()
	isMultiple = true;

	@Input()
	searchType: SearchType;

	@Output()
	onSelected = new EventEmitter<IAssociationsSelectedEvent>();

	@Input()
	direction: RelationshipTypes;

	@Input()
	includeCurrentAssociations: false;

	@Output()
	onDirectionChange = new EventEmitter<IDirectionChangedEvent>();

	@Input()
	relationshipDirectionTypes: RelationshipTypes[];

	@Input()
	keywords: string;

	charTypeDisplayNamePlural: string;
	relState: IEntityRelationshipState<number, IEntitySearchDoc>;

	@ViewChild(EntityRelationshipComponent)
	public entityRelationshipComponent: EntityRelationshipComponent;

	get selectedRelationshipsIsEmpty() {
		return !some(this.selectedAssociations);
	}

	get isIntraChar() {
		return this.charTypeId === this._parentEntityService.charTypeId;
	}

	constructor(
		private readonly _parentEntityService: ParentEntityService,
	) { }

	ngOnInit() {
		if (!this.direction) {
			this.direction = RelationshipTypes.Child;
		}

		if (!this.relationshipDirectionTypes) {
			this.relationshipDirectionTypes = [RelationshipTypes.Child, RelationshipTypes.Parent];
		}

		this.charTypeDisplayNamePlural = CharTypeIdUtil.toDisplayNamePlural(this.charTypeId);
	}

	directionChanged() {
		this.onDirectionChange.emit({ direction: this.direction });
	}

	setSelectedAssociations(event: ISelectedChangeEvent) {
		this.selectedAssociations = event.selectedRelationships;
		this.emitSelectedEvent();
	}

	emitSelectedEvent() {
		this.onSelected.emit({ selected: this.selectedAssociations });
	}
}
