import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IIzendaReport } from "app/+reporting/services/report.models";
import { ReportService } from "app/+reporting/services/report.service";
import { IWidgetOptions, Widgets, WidgetSize } from "app/rightsline-app/components/dashboard/widget/widget.models";
import { uniq } from "lodash";
import { IzendaReportWidgetParams } from "rl-common/components/widgets/izenda-report-widget/izenda-report-widget.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { ISelectedWidget } from "../add-widget-modal.models";
import { SelectIzendaReportComponent } from "../select-izenda-report/select-izenda-report.component";

@Component({
	selector: "rl-select-widget",
	templateUrl: "./select-widget.component.html",
	styleUrls: ["./select-widget.component.scss"],
	imports: [NgIf, NgFor, SelectIzendaReportComponent, LoaderComponent, AsyncPipe]
})
export class SelectWidgetComponent implements OnInit {

	unselectableReports: Set<string> = new Set<string>();

	categories$ = this._reportService.getIzendaReports();

	@Output()
	onWidgetSelected = new EventEmitter<ISelectedWidget>();

	selectedReports: Set<string> = new Set<string>();

	@Input()
	set currentWidgets(currentWidgets: IWidgetOptions[]) {
		if (!currentWidgets) {
			return;
		}
		const reportsAlreadyAdded = currentWidgets
			.filter(x => x.type === Widgets.IzendaReport)
			.map(x => x.params.reportId)
			.filter(id => !!id);

		this.unselectableReports = new Set<string>(uniq(reportsAlreadyAdded));
	}

	@Input()
	set selectedWidgets(selectedWidgets: ISelectedWidget[]) {
		if (!selectedWidgets) {
			return;
		}
		const selectedReportIds = selectedWidgets
			.filter(x => x.widget.type === Widgets.IzendaReport)
			.map(x => x.widget.params.reportId)
			.filter(id => !!id);
		this.selectedReports = new Set<string>(uniq(selectedReportIds));
	}


	constructor(private readonly _reportService: ReportService) { }

	ngOnInit(): void {
	}

	reportSelected(report: IIzendaReport) {
		const params: IzendaReportWidgetParams = {
			reportId: report.id,
			reportName: report.name
		};
		const widget: IWidgetOptions = {
			type: Widgets.IzendaReport,
			size: WidgetSize.Two,
			params: params
		};
		const selected: ISelectedWidget = {
			widget: widget,
			name: report.name
		};
		this.onWidgetSelected.emit(selected);
	}

}
