<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="row">
				<div class="col-8">
					<div class="char-data__group">
						<rl-form-table-row [labelText]="'Notification Name'" [required]="true"
							[class.has-error]="form.controls.label.errors">
							<ng-template rlFormTableControl>
								<rl-text-input [(formControl)]="form.controls.label"
									[dataLabel]="'NotificationNameInput'"></rl-text-input>
							</ng-template>
							<ng-template rlFormTableMessage
								*ngIf="form.controls.label.errors && form.controls.label.touched">
								Notification Name is required.
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Description'">
							<ng-template rlFormTableControl>
								<rl-text-input [(formControl)]="form.controls.description"
									[dataLabel]="'DescriptionInput'"></rl-text-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Subject'" [required]="true"
							[class.has-error]="form.controls.subject.errors">
							<ng-template rlFormTableControl>
								<rl-text-input [(formControl)]="form.controls.subject"
									[dataLabel]="'SubjectInput'"></rl-text-input>
							</ng-template>
							<ng-template rlFormTableMessage
								*ngIf="form.controls.subject.errors && form.controls.subject.touched">
								Subject is required.
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Preview Before Send'">
							<ng-template rlFormTableControl>
								<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.previewIndicator"
									[dataLabel]="'checkboxInputPreviewBeforeSendYes'"
									class="d-block py-1"></rl-checkbox-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Add Action Button'">
							<ng-template rlFormTableControl>
								<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.addActionBtn"
									[dataLabel]="'checkboxInputAddActionButtonYes'"
									class="d-block py-1"></rl-checkbox-input>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Trigger SNS/SQS Message'">
							<ng-template rlFormTableControl>
								<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.triggerSNSOrSQS"
									[dataLabel]="'TriggerSnsSqsYes'" class="d-inline-block py-1"></rl-checkbox-input>
								<span class="btn-icon btn-info btn-sm cursor-pointer text-medium"
									*ngIf="!notificationMessagesEnabled"><i aria-label="Tooltip" role="button"
										data-pendo="enable-notification-messages"
										class="far fa-question-circle"></i></span>
							</ng-template>
						</rl-form-table-row>

					</div>
					<div class="char-data__group">
						<rl-form-table-row [labelText]="'Plain Text'" [required]="true"
							[class.has-error]="form.controls.body.errors">
							<ng-template rlFormTableControl>
								<rl-text-area [(formControl)]="form.controls.body"
									[dataLabel]="'plainTextInput'"></rl-text-area>
							</ng-template>
							<ng-template rlFormTableMessage
								*ngIf="form.controls.body.errors && form.controls.body.touched">
								Plain Text is required.
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'HTML'">
							<ng-template rlFormTableControl>
								<rl-html-editor [formControl]="form.controls.bodyHtml"></rl-html-editor>
							</ng-template>
						</rl-form-table-row>

						<rl-form-table-row [labelText]="'Attachment Types'">
							<ng-template rlFormTableControl>
								<div class="vert-scroll">
									<label class="d-block py-1" *ngFor="let docTemp of allDocTemplatesForCharType">
										<input type="checkbox" [id]="docTemp.doc.documentTemplateId"
											[value]="docTemp.doc.documentTemplateId" class="form-check-input me-2"
											[checked]="isDocumentTypeIdChecked(docTemp.doc.documentTemplateId)"
											(change)="onDocumentTypesChange(docTemp.doc.documentTemplateId, $event.target.checked)"
											[attr.data-cy]="'attachmentTypes' + docTemp.doc.templateLabel | trimWhitespace" />
										{{ docTemp.doc.templateLabel }}
									</label>
								</div>
							</ng-template>
						</rl-form-table-row>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>