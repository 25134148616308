import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IAmortizationModel } from "rl-common/services/amortization/amortization.models";
import { EditAmortizationModelComponent } from "./edit-amortization-model.component";

@Component({
    selector: "rl-amortization-model-modal",
    templateUrl: "./amortization-model-modal.component.html",
    imports: [EditAmortizationModelComponent]
})
export class AmortizationModelModalComponent implements OnInit {

	@Input()
	amortizationModel: IAmortizationModel;

	@Input()
	isView: boolean;

	@Output()
	onComplete = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit(): void {

	}

	close(requiresRefresh) {
		this.onComplete.emit(requiresRefresh);
	}
}
