import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbHighlight, NgbTypeahead, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { ISearchResponse } from "rl-common/services/search/search.models";
import { SearchService } from "rl-common/services/search/search.service";
import { QueryUtil } from "rl-common/utils";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";

export interface ITypeAheadSelectedEvent {
	recordId: number;
	title: string;
}

@Component({
    selector: "rl-ap-contact-type-ahead",
    templateUrl: "./ap-contact-type-ahead.component.html",
    styleUrls: ["./ap-contact-type-ahead.component.scss"],
    imports: [ReactiveFormsModule, NgbTypeahead, FormsModule, NgbHighlight]
})
export class APContactTypeAheadComponent implements OnInit {
	@Input()
	keyword = "";

	@Input()
	partyId: number;

	@Input()
	validTemplates: ICharacteristicTemplate[];

	defaultKeyword: string;

	@Output()
	onTypeAheadSelected = new EventEmitter<ITypeAheadSelectedEvent>();
	constructor(
		private readonly _searchService: SearchService
	) { }

	ngOnInit() {
		this.defaultKeyword = this.keyword;
	}

	inputFormatter = () => {
		return this.keyword || "";
	}

	resultFormatter = (document: IEntitySearchDoc) => {
		return document.title;
	}

	typeAheadSearch = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => {
				const options = this.buildSearchOptions();
				return this._searchService.search(CharTypeId.User, term, {}, options)
					.pipe(
						catchError(() => {
							return of([]);
						}));
			}),
			map((data: ISearchResponse) => {
				return data.documents;
			})
		)

	typeAheadSelected($event: NgbTypeaheadSelectItemEvent) {
		this.onTypeAheadSelected.emit({
			recordId: $event.item.recordID,
			title: $event.item.title
		});
		if (!this.defaultKeyword) {
			this.keyword = "";
		}
	}

	buildSearchOptions(): ISearchRequestOptions {
		const filterQueries = [
			QueryUtil.$eq(SearchFieldNames.Entity.isActive, true)
		];

		if (this.partyId >= 0 && this.validTemplates.length > 0) {
			const validTemplates = cloneDeep(this.validTemplates);
			filterQueries.push(QueryUtil.$eq_any(SearchFieldNames.Entity.templateID, validTemplates.map(x => x.templateID)));
		}

		return {
			rows: 20,
			start: 0,
			sortField: "score",
			fields: [],
			facetFields: ["template_meta", "status_meta"],
			filterQueries
		};
	}
}
