import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { SearchType } from "rl-common/factories";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { PluralCharTypeNamePipe } from "../../pipes/plural-char-type-name.pipe";
import { IAssociatedRecords } from "./../../services/associations/association.models";
import { NewAssociationComponent } from "./new-association-wizard/new-association.component";
import { IAssociationEvent, ICreateAssociationEvent } from "./new-association-wizard/new-association.component.models";

@Component({
	selector: "rl-new-association-modal",
	templateUrl: "./new-association-modal.component.html",
	styleUrls: ["./new-association-modal.component.scss"],
	imports: [NgIf, NewAssociationComponent, PluralCharTypeNamePipe]
})
export class NewAssociationModalComponent implements OnInit {

	@Input()
	keywords: string;

	@Input()
	charTypeId: CharTypeId;

	@Input()
	parentAssociations: IAssociatedRecords;

	@Input()
	parentTemplateId: number;

	@Input()
	parentTitle: string;

	@Input()
	showExisting = true;

	@Input()
	shouldSave = true;

	@Input()
	defaultSelectedIds: number[] = [];

	@Input()
	isMultiple = true;

	@Input()
	relationshipType: RelationshipTypes;

	@Input()
	relationshipDirectionTypes: RelationshipTypes[];

	@Input()
	searchType: SearchType;

	@Input()
	partyId?: number;

	@Input()
	hasValidCreateTemplates = true;

	@Input()
	isLocked = false;

	@Output()
	onCreate = new EventEmitter<ICreateAssociationEvent>();

	@Output()
	onApply = new EventEmitter<IAssociationEvent>();

	isCollapsed = true;

	constructor(private readonly _modal: NgbActiveModal) { }

	ngOnInit() {

	}

	cancel() {
		this._modal.dismiss();
	}

	toggle() {
		this.isCollapsed = !this.isCollapsed;
	}

	emitCreate(event: ICreateAssociationEvent) {
		this.onCreate.emit(event);
		this._modal.close();
	}

}
