import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IWfRole } from "rl-common/services/company/company.models";
import { ISelectedNotification, IWFParty } from "rl-common/services/entity-config/entity-config.models";
import { IWorkflowNotification } from "rl-common/services/workflow/workflow.models";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { IWorkflowActionForm, notificationControlValidator } from "../workflow-action-edit.models";
import { NotificationRowComponent } from "./notification-row/notification-row.component";

@Component({
	selector: "rl-workflow-notification",
	templateUrl: "./workflow-notification.component.html",
	styleUrls: ["./workflow-notification.component.scss"],
	imports: [ReactiveFormsModule, NgFor, NotificationRowComponent, NgIf]
})
export class WorkflowNotificationComponent implements OnInit, OnDestroy {
	private readonly _subs: Subscription[] = [];

	@Input()
	form: FormGroup<IWorkflowActionForm>;

	@Input()
	notifications: IWorkflowNotification[];

	@Input()
	allRoles: IWfRole[];

	@Input()
	allParties: IWFParty[];

	@Input()
	isCreate: boolean;

	// @ViewChildren(WorkflowActionEditComponent)
	// notificationArray: QueryList<WorkflowActionEditComponent>;

	get notificationsFormArray() {
		return this.form.controls.notifications;
	}

	get notificationsIsEmpty() {
		return this.notificationsFormArray && this.notificationsFormArray.length === 0;
	}

	get notificationsFormControls() {
		return this.notificationsFormArray && this.notificationsFormArray.controls;
	}

	get notificationsAllInUse() {
		return this.form.controls.notifications.length === this.notifications.length;
	}

	constructor() { }

	ngOnInit() {
		const sub = this.notificationsFormArray.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(() => {
			const newValue = this.notificationsFormArray.value;
			this.form.controls.notifications.setValue(newValue, { emitEvent: false });
		});

		this._subs.push(sub);
	}

	addNewRow() {
		this.notificationsFormArray.push(new FormControl<ISelectedNotification>({
			notificationId: null,
			selectedRoleIds: [],
			selectedParties: []
		}, [notificationControlValidator]));
	}

	removeRow(index: number) {
		this.notificationsFormArray.removeAt(index);
	}

	clearRows() {
		this.notificationsFormArray.clear();
	}

	hasError(notificationControl: FormControl<ISelectedNotification>) {
		if (!this.form.hasError("duplicateNotificationId")) {
			return notificationControl.touched && !notificationControl.valid;
		} else {
			return this.form.hasError("duplicateNotificationId");
		}
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
