<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeWorkflowModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<div class="char-data__group__element"
					[class.has-error]="form.hasError('charTypeIdRequired')  && form.controls.charType.touched">
					<div class="char-data__label pt-2">
						<span>Type</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-dropdown-single [(formControl)]="form.controls.charType" [options]="charTypeList"
								[showEmptyOption]="true" [isReadOnly]="!isCreate" [dataLabel]="'selectDropdownType'">
							</rl-dropdown-single>
						</div>
						<div *ngIf="form.hasError('charTypeIdRequired') && form.controls.charType.touched"
							class="help-block">Type is required.</div>
					</div>
				</div>
				<div class="char-data__group__element"
					[class.has-error]="form.hasError('processNameRequired') && form.controls.processName.touched">
					<div class="char-data__label pt-2">
						<span>Workflow Name</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-text-input [formControl]="form.controls.processName"
								[dataLabel]="'textInputWorkflowName'"></rl-text-input>
						</div>
						<div *ngIf="form.hasError('processNameRequired') && form.controls.processName.touched"
							class="help-block">Workflow Name is required.</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Description</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-text-area [formControl]="form.controls.processDescription" [styles]="{maxLength: 10000}"
								[dataLabel]="'textAreaDescription'"></rl-text-area>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelWorkflowEdit">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveWorkflowEdit">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</ng-template>