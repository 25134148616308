import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, AbstractControlOptions, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { NgbActiveModal, NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import _, { cloneDeep, Dictionary, intersection, isEmpty, some, uniq, values } from "lodash";
import moment from "moment";
import { GridViewReportType } from "reporting/services/report.models";
import { SYSTEM_DATE_FORMAT } from "rl-common/components/date-edit/date-edit.models";
import { isGridViewColumnStrategy } from "rl-common/components/grid/datasource/columns/grid-view-column-strategy";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { CommonSubgridStrategy } from "rl-common/components/grid/datasource/subgrid/common-subgrid.strategy";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { ILovFilterChanged } from "rl-common/components/rights/lov-filter/lov-filter.component";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { IFacetResult } from "rl-common/models/i-facet-result";
import { IQueryNode } from "rl-common/models/i-query-node";
import { Acl } from "rl-common/rl-common-acl.consts";
import { AceStatusService } from "rl-common/services/ace-status/ace-status.service";
import { IComponentFilterOptions, IComponentsGroupFilterOptions } from "rl-common/services/components/component-group.models";
import { ConflictsService } from "rl-common/services/conflicts/conflicts.service";
import { DealService } from "rl-common/services/deal/deal.service";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { IGridViewColumn } from "rl-common/services/grid-view/models/i-grid-view-column";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { IValidationErrors } from "rl-common/services/mod-detail/models/i-validation-errors";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { QueryUtil } from "rl-common/utils";
import { AclUtil } from "rl-common/utils/acl.util";
import { combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { defaultIfEmpty, filter, map, switchMap, tap } from "rxjs/operators";
import { DebounceClickDirective } from "../../../directives/debounce-click.directive";
import { FaIconDirective } from "../../../directives/fa-icon.directive";
import { SearchOptionsFactory } from "../../../factories";
import { LocaleDatePipe } from "../../../pipes/locale-date.pipe";
import { AdminConfigService } from "../../../services/admin-config/admin-config.service";
import { ContactService } from "../../../services/contact/contact.service";
import { AvailsStatusComponent } from "../../avails-status/avails-status.component";
import { CustomViewsComponent } from "../../custom-views/custom-views.component";
import { DateInputComponent } from "../../date-input/date-input.component";
import { GridDataSourceBuilder } from "../../grid/datasource/builders/grid-datasource-builder";
import { IGridDataSelectStrategy } from "../../grid/datasource/data-select/grid-data-select.strategy";
import { SearchGridDataSource } from "../../grid/datasource/search-grid.datasource";
import { CellTemplateDirective } from "../../grid/directives/cell-template.directive";
import { GridSubgridTemplateDirective } from "../../grid/directives/grid-subgrid-template.directive";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";
import { LovFilterComponent } from "../../rights/lov-filter/lov-filter.component";
import { ConflictCheckIssueComponent } from "./conflict-check-issue/conflict-check-issue.component";
import { ConflictCheckUpdateStatusComponent } from "./conflict-check-update-status/conflict-check-update-status.component";
import { ConflictsSubgridComponent } from "./conflicts-subgrid/conflicts-subgrid.component";
import { availsCalcInProgressErrorMessage, ConflictCheckStatus, ConflictCheckType, ConflictListReportColumnNames, IConflictCheckDealConflicts, IConflictListTuple } from "./validation-errors-modal.models";
import { ValidationMessageComponent } from "./validation-message/validation-message.component";

@Component({
	selector: "rl-validation-errors-modal",
	templateUrl: "./validation-errors-modal.component.html",
	styleUrls: ["./validation-errors-modal.component.scss"],
	imports: [NgbNav, NgIf, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgFor, ValidationMessageComponent, AvailsStatusComponent, LovFilterComponent, ReactiveFormsModule, FormsModule, NgbPopover, CustomViewsComponent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem, DebounceClickDirective, NgbNavOutlet, GridTableComponent, CellTemplateDirective, FaIconDirective, ConflictCheckIssueComponent, GridSubgridTemplateDirective, ConflictsSubgridComponent, ConflictCheckUpdateStatusComponent, DateInputComponent, AsyncPipe, LocaleDatePipe]
})
export class ValidationErrorsModalComponent implements OnInit, OnDestroy {
	@Input()
	title: string;
	@Input()
	allValidationErrors: IValidationErrors;
	@Input()
	financialErrors: string[] = [];
	@Input()
	hasRightConflictErrors: boolean;
	@Input()
	calcInProgress = false;
	@Input()
	royaltyErrors: string[] = [];

	@Output()
	onRefresh = new EventEmitter<void>();

	@ViewChild(NgbDropdown)
	dropdown: NgbDropdown;

	@ViewChild(NgbPopover)
	dateFilterPopover: NgbPopover;

	private readonly _subscriptions: Subscription[] = [];

	GridViewReportType = GridViewReportType;

	gridOptions: GridOptions<IConflictCheckDealConflicts> = {
	};

	isFiltering: boolean;
	rightsFilters: IComponentFilterOptions = {
		lovFilter: {}
	};
	rightsGroupFilters: IComponentsGroupFilterOptions = {
		dateFilter: {}
	};
	rightsetCMDs$: Observable<ICharacteristicMetaData[]>;
	lovDict$: Observable<Dictionary<ICharacteristicMetaDataValue[]>>;

	dataSource: SearchGridDataSource<IConflictListTuple>;
	dataSelectStrategy: IGridDataSelectStrategy<IConflictListTuple, string, IConflictCheckDealConflicts>;
	rowsAreSelected$: Observable<boolean>;

	statuses = this.enumSelector(ConflictCheckStatus);
	types = this.enumSelector(ConflictCheckType);
	isUpdatingConflictCheckStatus = false;
	statusFilter = ConflictCheckStatus.AllStatuses;
	typeFilter = ConflictCheckType.AllTypes.key;
	filterQueryNodes: _.Dictionary<IQueryNode>;
	isBlocker = false;
	canOverrideConflictCheck = false;
	hasConflictCheckError = false;
	canUpdateToBlocked = false;
	canUpdateToNotBlocked = false;
	canExecuteWorkflow$ = this.modDetailService.canExecuteWorkflow$;
	availsCalcInProgressErrorMessage = availsCalcInProgressErrorMessage;
	hasAvailsError = false;
	hasFacetsSet = false;

	noRecordsSelected$: Observable<boolean>;
	form: UntypedFormGroup;
	canResolveConflicts$: Observable<boolean>;

	refreshSubgrids$ = new Subject<void>();

	get hasErrors$() {
		return this.modDetailService.hasValidationErrors$
			.pipe(
				map(hasValidationErrors => hasValidationErrors || this.hasValidationResults || this.hasDbValidationErrors)
			);
	}

	get hasValidationResults() {
		return some(this.allValidationErrors.errors);
	}

	get hasDbValidationErrors() {
		return some(this.allValidationErrors.dbValidationErrors);
	}

	get isDatesEmpty() {
		return !this.rightsGroupFilters.dateFilter.startDate && !this.rightsGroupFilters.dateFilter.endDate;
	}

	get startDateControl() {
		return this.form && this.form.get("startDate");
	}

	get endDateControl() {
		return this.form && this.form.get("endDate");
	}

	private _validDateRange: ValidatorFn = (c: AbstractControl) => {
		const start = this.startDateControl && this.startDateControl.value;
		const end = this.endDateControl && this.endDateControl.value;
		if (!start && !end) {
			return {
				"required": true
			};
		}
		if (!start || !end) {
			return null;
		}
		const startDate = moment(start, SYSTEM_DATE_FORMAT);
		const endDate = moment(end, SYSTEM_DATE_FORMAT);
		if (!endDate.isAfter(startDate)) {
			return {
				"invalidRange": true
			};
		}
		return null;
	}

	canAssignConflict$ = this.sessionService.acls$
		.pipe(
			map((acls) => {
				if (this.conflictAssignmentEnabled) {
					const activityAcl = Acl.Activities.ActivityAdminAcls.RecordSettingsAcls.AssignConflicts.toString();
					return AclUtil.hasReadAccess(acls, activityAcl);
				}
				return false;
			}),
			defaultIfEmpty(false)
		);

	conflictAssignmentEnabled: boolean;
	userParentIds: string[] = [];
	alternateLabelConflictTypeIds: number[] = [];
	selectedConflictTypeIds: number[] = [];
	limitedByClearanceRightsActions: boolean = false;

	constructor(
		private activeModal: NgbActiveModal,
		private readonly gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly dealService: DealService,
		private readonly modDetailService: ModDetailService,
		private readonly sessionService: SessionService,
		private readonly growlerService: GrowlerService,
		private readonly oneConfig: OneConfigService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly _conflictsService: ConflictsService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _oneConfigService: OneConfigService,
		private readonly _contactService: ContactService,
		private readonly _adminConfigService: AdminConfigService,
		private readonly _aceStatusService: AceStatusService
	) {

		const settingsSub = this._adminConfigService.getConflictControlSettings()
			.subscribe((settings) => {
				this.conflictAssignmentEnabled = settings.conflictAssignment;
				this.alternateLabelConflictTypeIds = settings.alternativeLabelConflictTypes;
				this.limitedByClearanceRightsActions = settings.limitedByClearanceRightsActions;
			});
		this._subscriptions.push(settingsSub);

		const parentSub = this._contactService.getParentContacts(`d${this.sessionService.divId}c2r${this.sessionService.userId}`)
			.subscribe((parents) => {
				this.userParentIds = parents;
			});
		this._subscriptions.push(parentSub);

		this.dataSelectStrategy = this.gridDataServiceBuilder.dataSelectStrategies.commonDataSelectStrategy<IConflictListTuple, string, IConflictCheckDealConflicts>(
			(rowData) => rowData[0].id, (rowData) => rowData[0]);

		this.rowsAreSelected$ = this.dataSelectStrategy.selectStateChange$.pipe(map(state => state.isAllSelected || state.selectedIds.size > 0));

		const sub = this.dataSelectStrategy.selectStateChange$.pipe(
			tap(() => {
				this.canUpdateToBlocked = false;
				this.canUpdateToNotBlocked = false;
			}),
			filter(() => !!this.dataSource),
			switchMap(state => {
				const selectedIds = state ? Array.from(state.selectedIds) || [] : [];
				const deselectedIds = state ? Array.from(state.deselectedIds) || [] : [];
				const selectedValues = state ? Array.from(state.selectedValues) || [] : [];
				const isAllSelected = state ? state.isAllSelected : false;

				if (state.isAllSelected) {

					const conflictTypeTitles = this.dataSource.facetResults$.value["conflict_type"].map(x => x.name);
					const searchConflictTypes = this.enumSelector(ConflictCheckType).filter(x => conflictTypeTitles.indexOf(x.title.value.toUpperCase()) > 0);
					this.selectedConflictTypeIds = uniq(searchConflictTypes.map(x => +x.value));

					const columnFilters = this.buildColumnFilters();

					if (deselectedIds.length > 0) {
						if (columnFilters[ConflictListReportColumnNames.ConflictId] == null) {
							columnFilters[ConflictListReportColumnNames.ConflictId] = QueryUtil.$eq_none(ConflictListReportColumnNames.ConflictId, deselectedIds);
						}
					}

					const filterQueryNode = QueryUtil.$and(...values(columnFilters));
					const ds = this.dataSource;
					let gridViewColumns: IGridViewColumn[] = null;
					if (isGridViewColumnStrategy(ds.columnStrategy)) {
						if (ds.columnStrategy.gridView$.value) {
							gridViewColumns = ds.columnStrategy.gridView$.value.columns;
						}
					}

					return this._conflictsService.conflictCheck(
						this.modDetailService.charTypeId,
						this.modDetailService.recordId,
						filterQueryNode,
						ds.sortKey$.value as string,
						ds.sortDir$.value,
						ds.rowOffset$.value,
						ds.pageSize$.value,
						gridViewColumns);
				} else {
					this.selectedConflictTypeIds = uniq(Array.from(state.selectedValues).map(x => x.conflictTypeId));
					return of({
						data: {
							canUpdateToBlocked: selectedValues.length > 0 && selectedValues.every(x => x.ignored),
							canUpdateToNotBlocked: selectedValues.length > 0 && selectedValues.every(x => !x.ignored)
						}
					});
				}
			}),
			tap(results => {
				this.canUpdateToBlocked = results.data.canUpdateToBlocked;
				this.canUpdateToNotBlocked = results.data.canUpdateToNotBlocked;
			})
		).subscribe();
		this._subscriptions.push(sub);

		const subgridStrategy = new CommonSubgridStrategy<IConflictListTuple>()
			.withShowArrowFn((_rowPath, rowData) => {
				const showHistory = !!rowData[0].historyCreatedTimeStamp;
				// make sure the config setting is enabled
				const showActions = this.limitedByClearanceRightsActions && rowData[0].conflictTypeId == ConflictCheckType.NoClearance.key;
				// show an arrow if either of these are active
				return showHistory || showActions;
			});

		const columnStrategy = this.gridDataServiceBuilder.columnStrategies.conflictReportColumnViewStrategy();
		this.dataSource = this.gridDataServiceBuilder.searchGridDataSource<IConflictListTuple>(row => row[0].id)
			.withProgress(true)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: ConflictListReportColumnNames.ConflictUpdatedAt, sortDir: 1 })
			.withColumnStrategy(columnStrategy)
			.withSubgridStrategy(subgridStrategy)
			.withDataSelectStrategy(this.dataSelectStrategy)
			.withFetchFn((ds) => {

				let gridViewColumns: IGridViewColumn[] = null;
				if (isGridViewColumnStrategy(ds.columnStrategy)) {
					if (ds.columnStrategy.gridView$.value) {
						gridViewColumns = ds.columnStrategy.gridView$.value.columns;
					}
				}

				const filters = this.buildFilterQueryNode();

				return this._conflictsService.conflictCheck(
					this.modDetailService.charTypeId,
					this.modDetailService.recordId,
					filters,
					ds.sortKey$.value as string,
					ds.sortDir$.value,
					ds.rowOffset$.value,
					ds.pageSize$.value,
					gridViewColumns)
					.pipe(
						tap(results => {
							// this.canUpdateToBlocked = results.data.canUpdateToBlocked;
							// this.canUpdateToNotBlocked = results.data.canUpdateToNotBlocked;
							const facetDict = results.data.facets.reduce((acc, el) => {
								acc[el.key] = el.value;
								return acc;
							}, {} as _.Dictionary<IFacetResult[]>);

							ds.setFacetResults(facetDict);
							if (!this.hasFacetsSet) {
								this.hasFacetsSet = true;

							}

							// reload any open subgrids
							this.refreshSubgrids$.next();
						}),
						map(results => {

							const tupleRows = results.data.rows.map(r => {
								const t: IConflictListTuple = [r, null, null, null, null, null];
								t[1] = results.data.catalogDocs.find(d => d.recordID === r.catalogId);
								t[2] = results.data.rightsetDocs.find(d => d.recordID === r.right1Id);
								t[3] = results.data.rightsetDocs.find(d => d.recordID === r.right2Id);
								t[4] = results.data.dealDocs.find(d => d.recordID === r.deal1Id);
								t[5] = results.data.dealDocs.find(d => d.recordID === r.deal2Id);
								return t;
							});

							return { rowCount: results.numFound, rowData: tupleRows, extraGridColumnResults: results.data.dealExtraGridColumnResults } as IGridFetchResults<IConflictListTuple>;
						})
					);
			});

		this.canResolveConflicts$ = combineLatest([this.dataSelectStrategy.selectStateChange$, this.canAssignConflict$])
			.pipe(
				map(([selection, canAssignConflict]) => {

					if (!this.conflictAssignmentEnabled) {
						return true;
					}
					// Users that can assign conflicts can always resolve them as well
					if (canAssignConflict) {
						return true;
					}
					const conflicts = selection.selectedValues;
					const assigneeIds = uniq([...conflicts].map(x => x.assigneeId));
					if (assigneeIds.every(x => x == this.sessionService.userId || this.userParentIds.indexOf(`d${this.sessionService.divId}c2r${x}`) > -1)) {
						return true;
					}
					return false;
				})
			);


		const rightsDims = this.oneConfig.getRightsDimensions();
		const cmds = this.oneConfig.getCharTypeCmds(CharTypeId.Right);
		const lovCmds: ICharacteristicMetaData[] = [];
		const lovDict: _.Dictionary<ICharacteristicMetaDataValue[]> = {};
		if (rightsDims) {
			[1, 2, 3, 4].forEach(dimId => {
				if (rightsDims.characteristics[dimId]) {
					const cmd = cmds.find(cmd2 => cmd2.characteristicID === rightsDims.characteristics[dimId].characteristicID);
					lovCmds.push(cmd);
					if (rightsDims.lovs[dimId]) {
						lovDict[cmd.charValueSourceID] = rightsDims.lovs[dimId].listOfValues;
					}
				}
			});
		}

		this.rightsetCMDs$ = of(lovCmds);
		this.lovDict$ = of(lovDict);

		const startDateControl = new UntypedFormControl(null);
		const endDateControl = new UntypedFormControl(null);
		const options: AbstractControlOptions = { validators: [this._validDateRange] };
		this.form = this.formBuilder.group({
			startDate: startDateControl,
			endDate: endDateControl
		}, options);
	}

	getConflictActionLabel(ignored: boolean) {
		if (intersection(this.selectedConflictTypeIds, this.alternateLabelConflictTypeIds).length == this.selectedConflictTypeIds.length) {
			if (ignored) return "Resolved";
			else return "Needs Attention";
		} else {
			if (intersection(this.selectedConflictTypeIds, this.alternateLabelConflictTypeIds).length >= 0) {
				this.growlerService.warning("There are a mix of selected Conflicts with standard and alternative status labeling.");
			}

			if (ignored) return "Non-Blocker";
			else return "Blocker";
		}
	}

	getStatusClass(conflictStatusLabel: "Blocked" | "Ignored" | "Needs Attention" | "Resolved") {

		switch (conflictStatusLabel) {
			case "Blocked":
				return "text-danger";
			case "Ignored":
				return "text-warning"
			case "Needs Attention":
				return "text-primary"
			case "Resolved":
				return "text-success"
			default:
				return "";
		}
	}

	assignConflicts() {
		const options = SearchOptionsFactory.buildUserSearchPageOptions();

		this._modalService.searchEntity(options).subscribe((user) => {
			const selectState = this.dataSelectStrategy.selectedState;

			const sub = this._conflictsService.assignConflictsToUser(this.modDetailService.charTypeId, this.modDetailService.recordId,
				Array.from(selectState.selectedIds), Array.from(selectState.deselectedIds), this.dataSelectStrategy.selectedState.isAllSelected, user.recordTitles[0].recordId)
				.subscribe(() => {
					this.dataSelectStrategy.clearSelected();
					this.reload();
					this.growlerService.success("Blocker(s) Assigned");
				});
			this._subscriptions.push(sub);
		});
	}

	buildColumnFilters() {
		const columnFilters = cloneDeep(this.dataSource.columnFilters$.value);

		if (!isEmpty(this.rightsGroupFilters.taggedCatalogKeywords)) {
			columnFilters[ConflictListReportColumnNames.CatalogName] = QueryUtil.$contains(ConflictListReportColumnNames.CatalogName, this.rightsGroupFilters.taggedCatalogKeywords);
		}

		if (this.rightsGroupFilters.dateFilter?.startDate) {
			columnFilters[ConflictListReportColumnNames.TermEnd] = QueryUtil.$range(ConflictListReportColumnNames.TermEnd, this.rightsGroupFilters.dateFilter?.startDate, "*");
		}

		if (this.rightsGroupFilters.dateFilter?.endDate) {
			columnFilters[ConflictListReportColumnNames.TermStart] = QueryUtil.$range(ConflictListReportColumnNames.TermStart, "*", this.rightsGroupFilters.dateFilter?.endDate);
		}

		if (!isEmpty(this.rightsFilters.lovFilter)) {
			const rightsDims = this.oneConfig.getRightsDimensions().characteristics;
			[1, 2, 3, 4].forEach((dim) => {
				const dimChar = rightsDims[dim];
				if (dimChar && this.rightsFilters.lovFilter[dimChar.tagLabel]) {
					const leafIds = this.rightsFilters.lovFilter[dimChar.tagLabel]?.leafValueIds ?? [];
					if (leafIds.length > 0) {
						let columnName: string = null;
						switch (dim) {
							case 1:
								columnName = ConflictListReportColumnNames.Dim1Labels;
								break;
							case 2:
								columnName = ConflictListReportColumnNames.Dim2Labels;
								break;
							case 3:
								columnName = ConflictListReportColumnNames.Dim3Labels;
								break;
							case 4:
								columnName = ConflictListReportColumnNames.Dim4Labels;
								break;
							default:
								break;
						}
						columnFilters[columnName] = QueryUtil.$eq_any(columnName, leafIds);
					}
				}
			});
		}
		return columnFilters;
	}

	buildFilterQueryNode(): IQueryNode {
		let filters: IQueryNode = {};

		const columnFilters = this.buildColumnFilters();

		if (!isEmpty(columnFilters)) {
			filters = QueryUtil.$and(...(values(columnFilters) ?? []));
		}

		return filters;
	}

	ngOnInit() {
		this.reload();
		const acls = this.sessionService.acls;
		const activityAcl = Acl.Activities.ActivityAdminAcls.RecordSettingsAcls.ConflictCheck.toString();
		this.canOverrideConflictCheck = AclUtil.hasReadAccess(acls, activityAcl);
		this.hasConflictCheckError = this._aceStatusService.aceStatus()?.hasConflicts;
	}

	reload() {
		const sub = this.reload$().subscribe();
		this._subscriptions.push(sub);
	}

	reload$() {
		this.canUpdateToBlocked = false;
		this.canUpdateToNotBlocked = false;

		this.dataSource.setPaging({ rowOffset: 0 })
			.queryChanged();
		return this.dataSource.fetchRows()
			.pipe(
				tap(() => {
					this.refreshSubgrids$.next();
				})
			);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	public close() {
		this.activeModal.close(false);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}

	enumSelector(definition) {
		return Object.keys(definition).map(x => ({ title: definition[x], value: x }));
	}

	setStatus(isBlocker: boolean) {
		this.isBlocker = isBlocker;
		this.filterQueryNodes = this.buildColumnFilters();
		this.isUpdatingConflictCheckStatus = true;
	}

	onSave() {
		this.isUpdatingConflictCheckStatus = false;
		this.dataSelectStrategy.clearSelected();
		const sub = this.reload$()
			.pipe(
				tap(() => {
					this._aceStatusService.startACEPolling();
				}),
				switchMap(() => this.dataSource.fetchRows())
			)
			.subscribe();
		this._subscriptions.push(sub);
	}

	filterStatus(conflict: IConflictCheckDealConflicts): boolean {
		return conflict.ignored && (this.statusFilter === ConflictCheckStatus.NonBlocker) ||
			!conflict.ignored && (this.statusFilter === ConflictCheckStatus.Blocker);
	}

	filterType(conflict: IConflictCheckDealConflicts): boolean {
		return conflict.conflictTypeId === +this.typeFilter;
	}

	getColumnLink(recordId: number, charTypeId: CharTypeId, title: string): unknown {
		return recordId ? ({ charTypeId, recordId, title } as IGridViewAssocEntityRec) : null;
	}

	exportCsv() {
		this.dropdown.close();

		const ds = this.dataSource;
		let gridViewColumns: IGridViewColumn[] = null;
		if (isGridViewColumnStrategy(ds.columnStrategy)) {
			if (ds.columnStrategy.gridView$.value) {
				gridViewColumns = ds.columnStrategy.gridView$.value.columns;
			}
		}

		const filters = this.buildFilterQueryNode();

		this._conflictsService.exportConflictCheck(
			this.modDetailService.charTypeId,
			this.modDetailService.recordId,
			filters,
			ds.sortKey$.value as string,
			ds.sortDir$.value, gridViewColumns)
			.subscribe(() => {
				this.growlerService.success().growl("An email with a download link to the CSV file has been sent.");
			});
	}

	public lovFilterChanged(event: ILovFilterChanged) {
		this.isFiltering = true;
		this.rightsFilters.lovFilter = event.filters;
		const sub = this.reload$()
			.pipe(tap(() => this.isFiltering = false))
			.subscribe(() => this.dataSelectStrategy.clearSelected());
		this._subscriptions.push(sub);
	}

	public searchChanged() {
		const sub = this.dataSource.fetchRows().subscribe(() => this.dataSource.dataSelectStrategy.clearSelected());
		this._subscriptions.push(sub);
	}

	clearDateFilters() {
		this.startDateControl.setValue(null);
		this.endDateControl.setValue(null);
		this.applyDateFilters();
	}

	applyDateFilters() {
		const startDate = this.startDateControl.value;
		const endDate = this.endDateControl.value;
		this.rightsGroupFilters.dateFilter.startDate = startDate;
		this.rightsGroupFilters.dateFilter.endDate = endDate;
		this.dateFilterPopover.close();
		const sub = this.dataSource.fetchRows()
			.subscribe(() => this.dataSource.dataSelectStrategy.clearSelected());
		this._subscriptions.push(sub);
	}

	closePopover() {
		this.dateFilterPopover.close();
	}


}
