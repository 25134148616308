<div *ngIf="form && isFirst" class="mb-2">
	<p class="py-2">When the Triggers are met, the <strong>Transaction Date Field</strong>
		will be populated with:
	</p>
	<div class="row mb-2">
		<div class="col-2">
			<select type="number" class="form-select" data-cy="SelectEvent" [formControl]="form.controls.dateTypeId">
				<option [ngValue]="1">Date of Event</option>
				<option [ngValue]="2">Date Field Value</option>
			</select>
		</div>
		<ng-container *ngIf="form.controls?.dateTypeId?.value === 2">
			<div class="col-2 text-end pe-2">
				<label class="mt-1"><strong>Date Field:</strong></label>
			</div>
			<div class="col-2"
				[class.has-error]="form.hasError('dateFieldRequired') && form.controls.characteristicId.touched">
				<select class="form-select" [formControl]="form.controls.characteristicId" data-cy="SelectDateField">
					<option [ngValue]="null">Select</option>
					<option *ngFor="let dateField of anchorTemplateDates" [ngValue]="dateField.characteristicID">{{
						dateField.label }}
					</option>
				</select>
				<div *ngIf="form.hasError('dateFieldRequired') && form.controls.characteristicId.touched"
					class="help-block">Date Field
					is required.</div>
			</div>
			<div class="col-6">
				<input type="checkbox" class="form-check-input me-2" [(ngModel)]="isCheckboxChecked"
					[ngModelOptions]="{standalone: true}" (change)="toggleCheckbox()">
				<label class="form-check-label">Choose default, if selected Field is blank?</label>
			</div>
		</ng-container>
	</div>
	<div class="row mb-2" *ngIf="form.controls?.dateTypeId?.value === 2 && isCheckboxChecked"
		[class.has-error]="form.hasError('defaultDateFieldRequired') && form.controls.characteristicIdDefault1.touched">
		<div class="col-2 offset-2 text-end pe-2">
			<label for="defaultFieldSelect" class="mt-1"><strong>Default Date Field:</strong></label>
		</div>
		<div class="col-2">
			<select id="defaultFieldSelect" class="form-select" [formControl]="form.controls.characteristicIdDefault1"
				data-cy="DefaultDateField">
				<option [ngValue]="null">Select</option>
				<ng-container *ngIf="form.controls?.characteristicId?.value">
					<option *ngFor="let dateField of anchorTemplateDefaultDates" [ngValue]="dateField.characteristicID">
						{{
						dateField.label }}
					</option>
				</ng-container>
				<ng-container *ngIf="!form.controls?.characteristicId?.value">
					<option disabled>Select a Date Field Value first</option>
				</ng-container>
			</select>
			<div *ngIf="form.hasError('defaultDateFieldRequired') && form.controls.characteristicIdDefault1.touched"
				class="help-block">Default Date Field is required.</div>
		</div>
	</div>
</div>

<div *ngIf="form && !isFirst" class="mb-2">
	<p class="py-2">The following Date Field will be populated:</p>
	<div class="row mb-2" *ngIf="form.controls?.dateGroupTypeId?.value === 2">
		<div class="col-2 text-end">
			<label class="pt-1"><strong>Date Field:</strong></label>
		</div>
		<div class="col-2">
			<select class="form-select" [formControl]="form.controls.characteristicId" data-cy="SelectDateField">
				<option *ngFor="let dateField of dateFieldDates" [ngValue]="dateField.characteristicId"
					[disabled]="dateField.disabled">{{ dateField.label }}
				</option>
			</select>
		</div>
		<div class="col-2 text-end">
			<label class="pt-1"><strong>Populate with:</strong></label>
		</div>
		<div class="col-2">
			<select class="form-select" [formControl]="form.controls.populateTypeId" data-cy="PopulateTypeSelect">
				<option [ngValue]="tuple[0]" *ngFor="let tuple of populateTypes">{{tuple[1]}}</option>
			</select>
		</div>
	</div>
</div>

<div class="d-flex mb-2">
	<div class="ms-auto">
		<span *ngIf="form.controls?.dateTypeId?.value === 2" class="me-3">
			<i>Note: If all selected date values are blank, Transaction Date will be defaulted to Date of Event.</i>
		</span>
		<button class="btn btn-light btn-sm me-2" (click)="cancel()" data-cy="CancelAddDate">
			Cancel
		</button>
		<button class="btn btn-info btn-sm" (click)="save()" [disabled]="!form?.valid" data-cy="SaveAddDate">
			Save
		</button>
	</div>
</div>