import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { sortBy } from "lodash";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { AclUtil } from "rl-common/utils/acl.util";

@Component({
    selector: "rl-child-template-selector",
    templateUrl: "./child-template-selector.component.html",
    styleUrls: ["./child-template-selector.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChildTemplateSelectorComponent),
            multi: true
        }
    ],
    imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class ChildTemplateSelectorComponent implements OnInit, ControlValueAccessor {
	@Input()
	templateId: number;

	@Input()
	charTypeId: number;

	@Input()
	childCharTypeId: number;

	templates: ICharacteristicTemplate[] = [];
	_selectedTemplate: ICharacteristicTemplate;
	template: UntypedFormControl;

	@Output()
	onTemplateChange = new EventEmitter<number>();

	onChange: any = () => { };
	onTouched: any = () => { };

	get selectedTemplate() {
		return this._selectedTemplate;
	}

	set selectedTemplate(val) {
		this._selectedTemplate = val;
		this.onTemplateChange.emit(this.selectedTemplate.templateID);
		this.onChange(val);
		this.onTouched();
	}

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly _session: SessionService
	) { }

	ngOnInit() {
		const acls = this._session.acls;
		const templates = sortBy(this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.templateId, this.childCharTypeId)
			.filter(t => AclUtil.hasCreateAccess(acls, t.acl)), t => t.sequenceNumber);

		this.templates = templates;
		this.selectedTemplate = templates[0];
		this.template = new UntypedFormControl(this.selectedTemplate.templateID);
	}
	changeTemplate() {
		this.selectedTemplate = this.templates.find(t => t.templateID === +this.template.value);
	}

	writeValue(value: any): void {
		if (value) {
			this.selectedTemplate = value;
		}
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
	}
}
