import { Injectable } from "@angular/core";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { BehaviorSubject } from "rxjs";

//APP-7555: Adding this service to keep track of commonCmds for deals. Potentially variedFields will be used with later feature additions to applying RRPs.
@Injectable({ providedIn: "root" })
export class VariesByDealService {
	public variedFields$ = new BehaviorSubject<{ [sysInd: number]: boolean; }>({});
	public variedFieldsCmd: ICharacteristicMetaData[] = [];
	public commonCmds: ICharacteristicMetaData[] = [];

	constructor() { }

	get variedFields() {
		return this.variedFields$.value;
	}

	get isVaried(): boolean {
		for (const field in this.variedFields) {
			if (this.variedFields[field]) {
				return true;
			}
		}

		return false;
	}

	reset() {
		this.variedFields$.next({});
		this.variedFieldsCmd = [];
		this.commonCmds = [];
	}
}
