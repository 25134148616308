import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "multipleIndicatorName" })
export class MultipleIndicatorNamePipe implements PipeTransform {

	transform(value: number): string {
		if (value === undefined || value === null || Number.isNaN(value) || typeof value !== "number") {
			return "";
		}
		switch (value) {
			case 0:
				return "No";
			case 1:
				return "Yes";
		}
		return "";
	}

}
