import { NgClass, NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { isEqual, some } from "lodash";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { AssocEntitySelectDataSource } from "rl-common/components/grid/datasource/search/assoc-entity-select.datasource";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { SelectState } from "rl-common/services/entity/entity.messages";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { CharTypeNamePipe } from "../../../../pipes/char-type-name.pipe";
import { ComponentRelationshipComponent } from "../entity-relationship/component-relationship.component";
import { SqlComponentRelationshipComponent } from "../entity-relationship/sql-component-relationship.component";
import { OneConfigService } from "./../../../../services/one-config/one-config.service";
import { SettingsService } from "./../../../../services/settings/settings.service";
import { IChipDeselectedEvent } from "./../../../chip/chips/chips.component";

@Component({
    selector: "rl-component-relationships",
    templateUrl: "./component-relationships.component.html",
    styleUrls: ["./component-relationships.component.scss"],
    imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgIf, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, SqlComponentRelationshipComponent, ComponentRelationshipComponent, CharTypeNamePipe]
})
export class ComponentRelationshipsComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	recordId: number = null;

	@Input()
	defaultSelectStateDict: { [charTypeId: number]: SelectState<number, IGridViewAssocEntityRec> } = {};

	@Output()
	onRelationshipStateUpdated = new EventEmitter<{ [charTypeId: number]: IEntityRelationshipState<IEntitySearchDoc, unknown> }>();

	parentCharTypeId: number;
	parentRecordId: number;
	relationshipCharTypeIds: number[];
	visibleRelCharTypeIds: number[];
	charTypeTemplateDictionary: { [charTypeId: number]: ICharacteristicTemplate[] } = {};
	selectedStateDictionary: { [charTypeId: number]: IEntityRelationshipState<IEntitySearchDoc, unknown> } = {};
	dataSourceDict: { [relCharTypeId: number]: AssocEntitySelectDataSource<IEntitySearchDoc, unknown> } = {};
	requiredCharTypeDict: { [charTypeId: number]: boolean } = {};
	useSqlServerDatasources = false;

	private readonly _subscriptions: Subscription[] = [];
	chipIdFn = (chip: IEntitySearchDoc) => chip.recordID;
	chipDisplayFn = (chip: IEntitySearchDoc) => chip.title;

	get hasRels() {
		return this.visibleRelCharTypeIds && some(this.visibleRelCharTypeIds);
	}

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly cd: ChangeDetectorRef,
		private readonly _settings: SettingsService
	) { }

	ngOnInit() {
		if (this._parentEntityService?.entity) {
			this.parentCharTypeId = this._parentEntityService.entity.charTypeId;
			this.parentRecordId = this._parentEntityService.entity.recordId;
			if (this._parentEntityService.entity.parent) {
				this.parentCharTypeId = this._parentEntityService.entity.parent.charTypeId;
				this.parentRecordId = this._parentEntityService.entity.parent.recordId;
			}
		}

		const possibleCharTypes = this.charTypeId !== CharTypeId.User ? [CharTypeId.User, CharTypeId.Property, CharTypeId.Project, CharTypeId.Inventory, CharTypeId.Mock] : [];

		this.charTypeTemplateDictionary = possibleCharTypes.reduce((acc, childCharTypeId) => {
			acc[childCharTypeId] = this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.templateId, childCharTypeId);
			return acc;
		}, {});

		this.visibleRelCharTypeIds = Object.keys(this.charTypeTemplateDictionary)
			.map(key => +key)
			.filter(key => {
				const isVisible = some(this.charTypeTemplateDictionary[key]);
				if (this.charTypeId === CharTypeId.Right && this._parentEntityService.parent) {
					return isVisible && (key !== this._parentEntityService.parent.charTypeId);
				}
				return isVisible;
			});

		this.requiredCharTypeDict = this.visibleRelCharTypeIds.reduce((acc, childCharTypeId) => {
			acc[childCharTypeId] = this._oneConfigService.isChildRequired(this.charTypeId, this.templateId, childCharTypeId);
			return acc;
		}, {});

		this._settings.divSettings$.pipe(
			map(settings => settings["useSqlServerDatasource"])
		).subscribe(setting => {
			this.useSqlServerDatasources = setting?.value?.toLocaleLowerCase() === "true";
			this.buildDataSources();
		})
	}

	ngAfterViewInit() {
		if (this.accordion && this.visibleRelCharTypeIds.indexOf(CharTypeId.Property) > -1) {
			this.accordion.expand(CharTypeId.Property.toString());
		}
	}

	buildDataSources() {
		this.dataSourceDict = this.visibleRelCharTypeIds.reduce((acc, relCharTypeId) => {
			const defaultSelectedIds = this.defaultSelectStateDict[relCharTypeId] ? this.defaultSelectStateDict[relCharTypeId].selectedIds : [];
			// TODO: div 66 hack (remove me later)
			if (this.useSqlServerDatasources && (this.isCatalogsOnRights(relCharTypeId) || this.isNestedTables(relCharTypeId) || this.isTablesOnDeal(relCharTypeId))) {
				acc[relCharTypeId] = this._gridDataSourceBuilder.sqlAssocEntitySearchDataSource(relCharTypeId,
					this.charTypeId, this.templateId, this.recordId, defaultSelectedIds, this.parentCharTypeId, this.parentRecordId);
			} else {
				acc[relCharTypeId] = this._gridDataSourceBuilder.assocEntitySearchDataSource(relCharTypeId,
					this.charTypeId, this.templateId, this.recordId, defaultSelectedIds, this.parentCharTypeId, this.parentRecordId);
			}
			return acc;
		}, {});
	}

	getCharTypeKeys() {
		return Object.keys(this.selectedStateDictionary).filter(key => this.selectedStateDictionary[key].selectedCount > 0).map(x => +x);
	}

	isCatalogsOnRights(relCharTypeId: CharTypeId) {
		return this.charTypeId === CharTypeId.Right && relCharTypeId === CharTypeId.Property;
	}

	isNestedTables(relCharTypeId: CharTypeId) {
		return this.parentCharTypeId === CharTypeId.Relationship && this.charTypeId === CharTypeId.Usage && relCharTypeId === CharTypeId.Property;
	}

	isTablesOnDeal(relCharTypeId: CharTypeId) {
		return this.parentCharTypeId === CharTypeId.Transaction && this.charTypeId === CharTypeId.Usage && relCharTypeId === CharTypeId.Property;
	}


	deselectRow(event: IChipDeselectedEvent<number, IEntitySearchDoc>, relCharTypeId: CharTypeId) {
		const dataSelectStrategy = this.dataSourceDict[relCharTypeId].dataSelectStrategy;
		if (event.isSelectAll) {
			dataSelectStrategy.deselectAll();
			return;
		}
		dataSelectStrategy.deselectRowById(event.id, event.value);
	}

	updateState(state: IEntityRelationshipState<IEntitySearchDoc, unknown>, relCharTypeId: CharTypeId) {
		this.selectedStateDictionary[relCharTypeId] = state;
		const lastState = relCharTypeId in this.selectedStateDictionary ? this.selectedStateDictionary[relCharTypeId] : null;
		const stateChanged = !isEqual(lastState, state);
		if (stateChanged) {
			this.cd.detectChanges();
		}
		this.onRelationshipStateUpdated.emit(this.selectedStateDictionary);
	}

	isRequiredInvalid(relCharTypeId: string) {
		const ct = +relCharTypeId;
		return this._oneConfigService.isChildRequired(this.charTypeId, this.templateId, ct) && this?.dataSourceDict[ct]?.formGroup.hasError("requiredGrid");
	}

	selectedRels() {
		return Object.entries(this.selectedStateDictionary)
			.map(kvp => {
				const charTypeId = +kvp[0];
				const chips = Array.from(kvp[1].selectedValues) as IEntitySearchDoc[];
				return {
					charTypeId,
					chips
				};
			})
			.filter(rel => rel.chips.length > 0);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
