<div class="modal-header">
	<h2 class="modal-title">Add to {{clipboardTypeName}}</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="CloseButton">
		<i class="far fa-times"></i>
	</button>
</div>
@if(selectSnapshot){
<div class="modal-body">
	<div class="bg-light rounded-panel p-3 mb-4">The Avails data saved to Snapshots is static and will not be updated in
		accordance to any Avails related data changes made in the application thereafter.
		<span class="btn-icon btn-info btn-sm cursor-pointer"><i aria-label="Tooltip" role="button"
				data-pendo="tip-avails-snapshot" class="far fa-question-circle"></i></span>
	</div>
	<div class="d-flex justify-content-center">
		<div class="grid">
			<div>
				<input type="radio" id="snapshotAction" name="snapshotAction" [value]="1" [(ngModel)]="mode"
					class="form-check-input">
			</div>
			<div>
				<strong>Create New Snapshot</strong>
				<p>Create a new Snapshot with selected items.</p>
			</div>
			<div *ngIf="snapshots?.length > 0">
				<input type="radio" class="form-check-input" id="snapshotAction" name="snapshotAction" [value]="2"
					[(ngModel)]="mode">
			</div>
			<div *ngIf="snapshots && snapshots.length > 0">
				<strong>Modify Existing Snapshot</strong>
				<p>Add selected items to an existing Snapshot.</p>
				<div [ngClass]="{'has-error': mode == 2 && selectedSnapshot === null}">
					<select class="form-select" [(ngModel)]="selectedSnapshot" [disabled]="mode !== 2"
						data-cy="selectSnapshot">
						<option [ngValue]="null">Select Snapshot</option>
						<option *ngFor="let s of snapshots" [ngValue]="s" [attr.data-cy]="'option' + s">
							{{s.title}}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="cancel()" data-cy="cancelButton">Cancel</button>
	<button type="button" class="btn btn-success ms-2" (click)="continue()" data-cy="continueButton"
		[disabled]="mode == 2 && selectedSnapshot === null">Continue</button>
</div>
} @else {
<rl-edit-clipboard (onComplete)="complete($event)" [items]="items" [clipboardType]="clipboardType"
	[selectedSnapshot]="selectedSnapshot" [isModify]="mode === 1 ? false : true"></rl-edit-clipboard>
}