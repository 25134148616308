import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchOptions } from "rl-common/components/entities/entity-search/entity-search.models";
import { ISelectStateChangeEvent } from "rl-common/components/entities/entity-search/select-state-net-changes";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { SearchOptionsFactory } from "rl-common/factories/search-options.factory";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ActionOptions } from "rl-common/services/entity/models/action-options";
import { IMoveOrCopyEntitiesRequest } from "rl-common/services/entity/models/i-move-or-copy-entities-request";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { SessionService } from "rl-common/services/session.service";
import { IDUtil } from "rl-common/utils";
import { CharTypeNamePipe } from "../../../pipes/char-type-name.pipe";
import { EntityService } from "../../../services/entity/entity.service";
import { EntitySearchComponent } from "../../entities/entity-search/entity-search.component";


@Component({
	selector: "rl-move-or-copy-entities-modal",
	templateUrl: "./move-or-copy-entities-modal.component.html",
	styleUrls: ["./move-or-copy-entities-modal.component.scss"],
	imports: [NgIf, EntitySearchComponent, CharTypeNamePipe]
})
export class MoveOrCopyEntitiesModalComponent implements OnInit {

	@Input()
	isCopy: boolean;

	@Input()
	entityId: string;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	childModel: ISearchRequestModel;

	@Input()
	childCount: number;

	@Input()
	childCharTypeId: number;

	@Input()
	associatedTemplates: number[];

	@Input()
	dataSource: SearchGridDataSource<IEntitySearchDoc>;

	@Output()
	onComplete = new EventEmitter<string>();

	isSaving: boolean;
	destinationEntityIds: string[];
	searchOptions: SearchOptions = {} as SearchOptions;
	templateIds: number[] = [];
	saveEnabled = false;
	selectedCount = 0;


	constructor(private readonly _modal: NgbActiveModal,
		private readonly _entityService: EntityService,
		private readonly _sessionService: SessionService,
		private gridDataSourceBuilder: GridDataSourceBuilder) { }


	ngOnInit() {
		this.searchOptions = SearchOptionsFactory.buildParentAssociationOptions(this.charTypeId, this.childCharTypeId, this.templateId);
		this.searchOptions.filteredTemplateIds = this.associatedTemplates;
		this.buildDataSource();
	}

	select(event: ISelectStateChangeEvent<number, IEntitySearchDoc>) {
		if (event.selectedCount > 0) {
			const selectedIds = Array.from(event.netChanges.addedIds);
			this.destinationEntityIds = selectedIds.map(addedId => IDUtil.toID(this._sessionService.divId, this.charTypeId, addedId));
			this.saveEnabled = true;
		}
		this.selectedCount = event.selectedCount;
	}

	save() {
		this.isSaving = true;

		const request: IMoveOrCopyEntitiesRequest = {
			sourceEntityId: this.entityId,
			childCharTypeId: this.childCharTypeId,
			childModel: this.childModel,
			destinationEntityIds: this.destinationEntityIds,
			action: this.isCopy ? ActionOptions.Copy : ActionOptions.Move
		};

		this._entityService.moveOrCopyEntities(request).subscribe((jobId) => {
			this.onComplete.emit(jobId.toString());
			this.isSaving = false;
			this._modal.close();
		});
	}

	buildDataSource() {
		const columnStrategy = this.gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(this.charTypeId);
		const hierarchyStrategy = this.gridDataSourceBuilder.hierarchyStrategies.entitySearchDocHierarchyStrategy(this.charTypeId, this.childCharTypeId);
		const dataSelectStrategy = this.gridDataSourceBuilder.dataSelectStrategies
			.entityRelationshipDataSelectStrategy<IEntitySearchDoc, number, IGridViewAssocEntityRec>(
				(doc) => doc.recordID, (doc) => (<IGridViewAssocEntityRec>{ charTypeId: doc.charTypeID, recordId: doc.recordID, templateId: doc.templateID, title: doc.title })
			);

		if (!this.isCopy) {
			dataSelectStrategy.withSelectType(GridSelectType.Radio);
		}

		this.dataSource = this.gridDataSourceBuilder.entitySearchDataSource(this.charTypeId)
			.withProgress(true)
			.withTemplateFilter(this.associatedTemplates)
			.setSorting({ sortKey: "status_updated", sortDir: 0 })
			.setPaging({ pageSize: 25 })
			.withColumnStrategy(columnStrategy)
			.withHierarchyStrategy(hierarchyStrategy)
			.withDataSelectStrategy(dataSelectStrategy);

	}

	cancel() {
		this.onComplete.emit();
		this._modal.close();
	}
}
