<!-- Any verbiage changes or additions made here must also be made in Rightsline.RestApi > ValidationMessages.cs for the API -->
<div [ngSwitch]="validationError.errorType">
	<span *ngSwitchCase="'INV-RQD-VAL'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is required
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-NON-MULTI-VAL'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) doesn't allow multiple values
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-LOV-VAL'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) has an invalid value: {{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-YEAR'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a 4 digit year:
		{{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-URL'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a valid URL: {{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-NUM'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a valid number:
		{{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-DATE'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a valid date: {{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-EML'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a valid email: {{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-PERC'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a valid percent:
		{{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-MAX-LEN'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) has an invalid length:
		{{validationError.value}}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-TME-CDE'" class="py-2 px-1">
		<span *ngIf="validationError.childCharTypeId"><b>{{validationError.childCharTypeId | charTypeName}}</b>: </span>
		{{validationError.charLabel}} ({{validationError.charTagLabel}}) is not a valid timecode:
		{{ validationError.value }}
		<span *ngIf="validationError.childCharTypeId">
			on <i>{{validationError.childEntityTemplate}}</i>
			{{ validationError.childEntityId | parseRecordId }} {{validationError.childEntityTitle}}
		</span>
	</span>
	<span *ngSwitchCase="'INV-RGT-CAT'" class="py-2 px-1">
		{{validationError.entityId | parseCharType | pluralCharTypeName}} {{validationError.entityTitle}} is not
		associated to a {{validationError.childCharTypeId | charTypeName}}
	</span>
	<span *ngSwitchCase="'INV-RGT-TERM'" class="py-2 px-1">
		Rights Record {{validationError.childEntityId | parseRecordId}} Term Start must be before Term End.
	</span>
	<span *ngSwitchCase="'INV-NO-ASSOC'" class="py-2 px-1">
		<b>{{validationError.entityId | parseCharType | charTypeName}}</b>: No
		{{validationError.childCharTypeId | pluralCharTypeName }} associated to
		<i> {{validationError.entityTemplate}}</i> {{validationError.entityTitle}}
	</span>
	<span *ngSwitchCase="'INV-INACTV-ASSOC'" class="py-2 px-1">
		<b>{{validationError.childCharTypeId | charTypeName}}</b>: {{validationError.childEntityTitle}} is not in
		an Active status
	</span>
	<span *ngSwitchCase="'INV-DUPE'" class="py-2 px-1">
		There is already a <b>{{validationError.entityId | parseCharType | charTypeName}}</b> with title
		{{validationError.entityTitle}}
	</span>
	<span *ngSwitchCase="'INV-MXD-CUR'" class="py-2 px-1">
		<b>{{validationError.entityId | parseCharType | charTypeName}}</b>: <i>{{validationError.entityTemplate}}</i>
		does not allow mixed currency
		<b>{{validationError.childCharTypeId | pluralCharTypeName}}</b>
	</span>
	<span *ngSwitchCase="'INV-RQD-PTY'" class="py-2 px-1">
		<b>Party</b>: <i>{{validationError.relationshipLabel}}</i> requires a Contact
	</span>
	<span *ngSwitchCase="'INV-NON-MULTI-PTY'" class="py-2 px-1">
		<b>Party</b>: <i>{{validationError.relationshipLabel}}</i> does not allow multiple
		{{validationError.childCharTypeId | pluralCharTypeName}}
	</span>
	<span *ngSwitchCase="'INV-TMP-PTY'" class="py-2 px-1">
		<b>Party</b>: <i>{{validationError.relationshipLabel}}</i> does not allow
		{{validationError.childCharTypeId | pluralCharTypeName}} of template
		<i>{{validationError.childEntityTemplate}}</i>
	</span>
	<span *ngSwitchCase="'INV-DIV-59-RPT-LNE'" class="py-2 px-1">
		Reporting Line Item Label | Account pair {{validationError.value}} appears more than once
	</span>
	<span *ngSwitchCase="'INV-DIV-59-RPT-TAG'" class="py-2 px-1">
		Different sets of income groups found for the same reporting tag: {{validationError.value}}
	</span>
	<span *ngSwitchCase="'INV-DIV-59-EXP'" class="py-2 px-1">
		{{validationError.childEntityTemplate}} {{ validationError.childEntityId | parseRecordId }} has no income groups
	</span>
	<span *ngSwitchCase="'INV-DIV-59-ALLOC'" class="py-2 px-1">
		{{validationError.entityId | parseCharType | charTypeName}} allocations do not equal 100%
	</span>
	<span *ngSwitchCase="'GEN-ERR'" class="py-2 px-1">
		{{validationError.value}}
	</span>
	<span *ngSwitchDefault class="py-2 px-1">
		Unknown Error Type '{{validationError.errorType}}'
	</span>
</div>