import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IFavoriteLink } from "rl-common/models/i-favorite-link";
import { QuickLinksService } from "rl-common/services/quick-links/quick-links.service";
import { Subscription } from "rxjs";
import { NewTabInModalDirective } from "../../../common/directives/new-tab-in-modal.directive";

@Component({
    selector: "rl-favorites",
    templateUrl: "./favorites.component.html",
    styleUrls: ["./favorites.component.scss"],
    imports: [NgIf, NgFor, NewTabInModalDirective, RouterLink, AsyncPipe]
})
export class FavoritesComponent implements OnInit, OnDestroy {

	links$ = this._quickLinksService.links$;
	deleted = this._quickLinksService.deleted;
	private readonly _subscriptions: Subscription[] = [];

	constructor(private readonly _quickLinksService: QuickLinksService) { }

	ngOnInit(): void {
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	removeLink(link: IFavoriteLink) {
		const sub = this._quickLinksService.deleteQuickLinks(link.id).subscribe();
		this._subscriptions.push(sub);
	}
}
