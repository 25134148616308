<div class="modal-header">
	<h2 class="modal-title">Select Fields</h2>
	<button aria-label="Close" class="btn btn-close ms-2" type="button" (click)="cancel()" data-cy="closeModal"><i
			class="far fa-times"></i></button>
</div>
<div class="rl-grid__filter--search my-2" data-cy="GridFilterSearchForm">
	<div class="input-group">
		<rl-text-input [(ngModel)]="keywords" [placeholder]="'Search'" [styles]="{classes: 'form-control-sm'}"
			[dataLabel]="'SearchTextboxInput'"></rl-text-input>
		<button [hidden]="!keywords" type="button" class="btn btn-icon btn-sm btn-remove border-bottom"
			(click)="clearText()" data-cy="ClearSearchButton" title="Clear Search">
			<i class="far fa-times"></i>
		</button>
		<div ngbDropdown autoClose="outside" #searchInfoDropdown="ngbDropdown">
			<button type="button" class="btn btn-icon btn-sm btn-info hide-caret border-bottom" ngbDropdownToggle
				role="button" data-cy="SearchInfoButton" title="Search Info">
				<i class="far fa-question-circle"></i>
			</button>
			<rl-search-tips ngbDropdownMenu aria-labelledby="searchInfoDropdown" class="py-0 search-tips dropdown-menu">
			</rl-search-tips>
		</div>
		<button class="btn btn-icon btn-sm btn-info border-bottom" (click)="submit()" data-cy="SearchButton"
			title="Search">
			<i class="far fa-search"></i>
		</button>
	</div>
</div>
<rl-grid [dataSource]="dataSource" [options]="options"></rl-grid>

<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()" data-cy="closeButton">Close</button>
	<button type="button" class="btn btn-info" (click)="apply()" data-cy="applyButton">Apply</button>
</div>