import { CdkDrag } from "@angular/cdk/drag-drop";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { Subscription } from "rxjs";
import { ChipComponent } from "../../../../../common/components/chip/chip.component";
import { NewTabInModalDirective } from "../../../../../common/directives/new-tab-in-modal.directive";
import { CharDataTypeNamePipe } from "../../../../../common/pipes/char-data-type-name.pipe";
import { VisibilityIndicatorPipe } from "../../../../../common/pipes/visibility-indicator-name.pipe";
import { MultipleIndicatorNamePipe } from "../../../../pipes/multiple-indicator-name.pipe";
import { RequiredIndicatorNamePipe } from "../../../../pipes/required-indicator-name.pipe";
import { VisibilityIndicatorNamePipe } from "../../../../pipes/visibility-indicator-name.pipe";
import { ICreateTemplateFormData } from "../../template-fields/template-field-add/create-template-field-wizard/template-field-policy-editor/template-field-policy-editor.models";


@Component({
	selector: "rl-field-section-select-chars-modal",
	templateUrl: "./field-section-select-chars-modal.component.html",
	styleUrls: ["./field-section-select-chars-modal.component.scss"],
	imports: [CdkDrag, NewTabInModalDirective, RouterLink, NgFor, ChipComponent, NgIf, CharDataTypeNamePipe, VisibilityIndicatorPipe, VisibilityIndicatorNamePipe, RequiredIndicatorNamePipe, MultipleIndicatorNamePipe]
})
export class FieldSectionSelectCharsModalComponent implements OnInit, OnDestroy {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	selectedChars: number[] = [];

	@Output()
	onComplete = new EventEmitter<Set<number>>();

	formGroup: FormGroup<ICreateTemplateFormData>;
	isSaving = false;

	get charTypeId() {
		return this.templateMetaData.charTypeID;
	}

	get templateName() {
		return this.templateMetaData.template.templateName;
	}

	get templateId() {
		return this.templateMetaData.template.templateID;
	}

	conditionalTriggerFieldId: FormControl<number>;
	templateChars: ICharacteristicMetaData[] = [];
	fieldsToAdd = new Set<number>();

	private readonly _subs: Subscription[] = [];

	constructor() { }

	ngOnInit(): void {
		this.templateChars = this.templateMetaData.characteristicMetaDatas.filter(cmd => !this.selectedChars.includes(cmd.characteristicID));
	}

	cancel() {
		this.onComplete.next(null);
	}

	toggleField(characteristicID: number) {
		if (this.fieldsToAdd.has(characteristicID)) {
			this.fieldsToAdd.delete(characteristicID);
		} else {
			this.fieldsToAdd.add(characteristicID);
		}
	}

	done() {
		this.onComplete.emit(this.fieldsToAdd);
	}

	getFieldById(id: number) {
		const cmd = this.templateChars.find((x) => x.characteristicID === id);
		return cmd.label;
	}

	isChecked(characteristicID: number) {
		return this.fieldsToAdd.has(characteristicID) ? true : false;
	}

	includeOnCopy(copyIndicator: number) {
		return copyIndicator === 1;
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
