import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { SessionService } from "rl-common/services/session.service";
import { NewTabInModalDirective } from "../../directives/new-tab-in-modal.directive";
import { RlFooterComponent } from "../../../rightsline-app/components/footer/footer.component";

@Component({
    selector: "rl-intelligence-center",
    templateUrl: "./intelligence-center.component.html",
    styleUrls: ["./intelligence-center.component.scss"],
    imports: [NewTabInModalDirective, RouterLink, RouterLinkActive, RouterOutlet, RlFooterComponent]
})

export class IntelligenceCenterComponent implements OnInit {

	tabLinks = {
		api: "api",
		auditMessaging: "audit-messaging",
		awsSettings: "aws-settings",
		dataBridgeUserLogs: "data-bridge-user-logs",
		eSignature: 'e-signature',
		ssoSetup: "sso-setup"
	};


	constructor(
		private browserTitle: Title,
		public route: ActivatedRoute,
		private readonly sessionService: SessionService) { }

	ngOnInit() {
		this.browserTitle.setTitle("Intelligence Center");

		/*this.route.firstChild.url.subscribe((url) => {
			const urlPath = url[url.length - 1]?.path;
			this.ngbNav.select(urlPath);
		});*/
	}

}