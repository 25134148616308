<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<div class="char-data__group__element"
					[class.has-error]="form.hasError('actionNameRequired') && form.controls.actionName.touched">
					<div class="char-data__label pt-2">
						<span>Action Name</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-text-input [formControl]="form.controls.actionName"
								[dataLabel]="'inputFieldActionName'"></rl-text-input>
						</div>
						<div *ngIf="form.hasError('actionNameRequired') && form.controls.actionName.touched"
							class="help-block">Action Name is required.</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Description</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-text-area [formControl]="form.controls.actionDescription" [styles]="{maxLength: 10000}"
								[dataLabel]="'textareaDescription'"></rl-text-area>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Initial Action</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.initialAction"
								[dataLabel]="'checkboxInputInitialActionYes'" class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element"
					[class.has-error]="form.hasError('nextStatusRequired') && form.controls.nextStatus.touched">
					<div class="char-data__label pt-2">
						<span>Next Status</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-dropdown-single [(formControl)]="form.controls.nextStatus" [options]="allSteps"
								[showEmptyOption]="true" [dataLabel]="'selectDropdownNextStatus'">
							</rl-dropdown-single>
						</div>
						<div *ngIf="form.hasError('nextStatusRequired') && form.controls.nextStatus.touched"
							class="help-block">Next Status is required.</div>
					</div>
				</div>
				<div class="char-data__group__element"
					[class.has-error]="form.hasError('docIndicatorRequired') && form.controls.docIndicator.touched">
					<div class="char-data__label pt-2">
						<span>Document Options</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-dropdown-single [(formControl)]="form.controls.docIndicator"
								[options]="documentOptionsList" [showEmptyOption]="true"
								[dataLabel]="'selectDropdownDocumentOptions'">
							</rl-dropdown-single>
						</div>
						<div *ngIf="form.hasError('docIndicatorRequired') && form.controls.docIndicator.touched"
							class="help-block">Document Option is required.</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="charTypeId === 7">
					<div class="char-data__label pt-2">
						<span>Update Currency Conversion</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display d-flex align-items-center">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.updateCurrencyConversion"
								[dataLabel]="'checkboxInputUpdateCurrencyConversionYes'"
								class="d-block py-1"></rl-checkbox-input>
							<span class="h-auto btn-icon btn-info btn-sm cursor-pointer text-medium" role="button"
								data-pendo="tooltip-update-currency-conversion"><i aria-label="Tooltip"
									class="far fa-question-circle"></i></span>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="charTypeId === 1 || charTypeId === 4"
					[class.has-error]="form.hasError('docIndicatorRequired') && form.controls.docIndicator.touched">
					<div class="char-data__label pt-2">
						<span>Action allowed from Status with Blocker Conflicts</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.allowActionWithBlockers"
								[dataLabel]="'checkboxInputAllowBlockersYes'" class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Associated Roles</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-workflow-associated-roles [form]="form" [allRoles]="allRoles"
								[isCreate]="isCreate"></rl-workflow-associated-roles>
						</div>
					</div>
				</div>
			</div>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Chained Actions</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-workflow-chained-action [form]="form" [processes]="processes" [actions]="actions">
							</rl-workflow-chained-action>
						</div>
					</div>
				</div>
			</div>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Notifications</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display"
							[class.has-error]="form.controls.notifications.touched && form.hasError('duplicateNotificationId')">
							<rl-workflow-notification [form]="form" [notifications]="notifications"
								[allRoles]="allRoles" [allParties]="allParties" [isCreate]="isCreate">
							</rl-workflow-notification>
						</div>
					</div>
				</div>
			</div>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Extensions</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<div class="d-flex align-items-center p-2" *ngIf="wfExtensionResult.paths.length === 0">None
							</div>
							<table class="table rl-table" *ngIf="wfExtensionResult.paths.length > 0">
								<thead>
									<tr>
										<th>Name</th>
										<th>Type</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let path of wfExtensionResult.paths">
										<td>{{ path.path }}</td>
										<td>{{ path.pathTypeID === 1 ? 'Stored Proc' : 'Service' }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Allow Action During Validations</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'"
								[formControl]="form.controls.allowActionDuringValidations"
								[dataLabel]="'checkboxInputAllowActionDuringValidations'"
								class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton"><i *ngIf="isSaving" class="far fa-spinner fa-spin"></i>Save</button>
		</div>
	</div>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>