import { CommonModule, NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { MachineLearningService } from "../../../services/ml/ml.service";
import { LoaderComponent } from "../../panel/loader/loader.component";

@Component({
    selector: "rl-ai-model-information",
    templateUrl: "./ai-model-information.component.html",
	styleUrls: ["./ai-model-information.component.scss"],
	imports: [NgFor, CommonModule, LoaderComponent]
})

export class AiModelInformationComponent implements OnInit {
	normalizationFactor = 1500000;
	normalizedTokenCount: number;
	monthlyTokenPercentage: number;
	userUsage: { [key: string]: number };
	isLoading: boolean;
	private subs: Subscription[] = [];

	constructor(private readonly _mlService: MachineLearningService) {
	}
	ngOnInit(): void {
		this.isLoading = true;
		const sub = this._mlService.getUsageMetrics().subscribe((usage) => {
			this.normalizedTokenCount = Math.round(usage.monthlyLimit / this.normalizationFactor);
			this.monthlyTokenPercentage = Math.round((usage.monthlyUsage * 100) / usage.monthlyLimit);
			this.userUsage = usage.byUserUsage;
			this.isLoading = false;
		});

		this.subs.push(sub);
	}
}