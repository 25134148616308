import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { every } from "lodash";
import { HierarchyGroupComponent } from "../hierarchy-group/hierarchy-group.component";
import { TransferAssociationGroupComponent } from "../transfer-association-group/transfer-association-group.component";
import { ICharTypeAssociations } from "../transfer-associations.model";

@Component({
    selector: "rl-transfer-association-char-type",
    templateUrl: "./transfer-association-char-type.component.html",
    styleUrls: ["./transfer-association-char-type.component.scss"],
    imports: [HierarchyGroupComponent, ReactiveFormsModule, FormsModule, NgFor, TransferAssociationGroupComponent]
})
export class TransferAssociationCharTypeComponent implements OnInit {
	@Input()
	charType: ICharTypeAssociations;

	@Output()
	onChange = new EventEmitter<void>();

	@ViewChildren(TransferAssociationGroupComponent) entityAssociationGroupComponents: QueryList<TransferAssociationGroupComponent>;

	public areAllSelected = true;
	constructor() { }

	ngOnInit() {
	}

	public toggleSelectAll() {
		this.entityAssociationGroupComponents.toArray().forEach(comp => {
			comp.areAllSelected = this.areAllSelected;
			comp.selectAll(this.areAllSelected);
		});
		this.onChange.emit();
	}

	updateSelectAll() {
		this.areAllSelected = every(this.entityAssociationGroupComponents.toArray(), comp => comp.areAllSelected);
		this.onChange.emit();
	}
}
