import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IAssetAttachmentModel } from "rl-common/services/attachment/attachment.models";
import { IPreviewDocument } from "rl-common/services/workflow/workflow.models";
import { PreviewDocumentComponent } from "../../execute-workflow-modal/execute-workflow-wizard/preview-document/preview-document.component";

@Component({
    selector: "rl-preview-media-modal",
    templateUrl: "./preview-media-modal.component.html",
    styleUrls: ["./preview-media-modal.component.scss"],
    imports: [PreviewDocumentComponent]
})
export class PreviewMediaModalComponent implements OnInit {

	@Input()
	set attachment(attachment: IAssetAttachmentModel) {
		this.file = {
			fileName: attachment && attachment.fileName,
			uri: attachment && attachment.mediaUrl
		};
	}

	file: IPreviewDocument;

	@Output()
	onClose = new EventEmitter<void>();

	constructor() { }

	ngOnInit() {
	}

	close() {
		this.onClose.emit();
	}
}
