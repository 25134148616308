export interface IGetUserNotificationsRequest {
	start: number;
	count: number;
	statusLabel: string;
	sortColumn: string;
	sortDirection: string;
}

export interface INotificationResponse {
	data: INotification[];
	numFound: number;
	pageCount: number;
}

export interface INotification {
	divisionId: number;
	processId: number;
	id: number;
	charTypeId: number;
	recordId: number;
	recordRevisionId: number;
	entityTitle: string;
	fromId: number;
	fromEmail: string;
	toId: number;
	toEmail: string;
	label: string;
	description: string;
	subject: string;
	body: string;
	bodyHtml: string;
	sequenceNumber: number;
	documentTypes: number[];
	createdBy: number;
	createdAt: string;
	updatedBy: number;
	updatedAt: string;
	markedReadAt: string;
	previewIndicator: number;
	status: string;
	addActionBtn: boolean;
}

export enum NotificationSortDirection {
	Ascending = "ASC",
	Descending = "DESC"
}

export enum NotificationSortColumn {
	Created = "cre_tms",
	Id = "rec_id",
	Title = "rec_ttl",
	DateSent = "rec_rev_id",
	Subject = "sbj",
	Body = "msg",
	Status = "sta",
	DateRead = "read_tms"
}

export enum NotificationStatus {
	All = "R,S",
	Read = "R",
	Unread = "S",
	Deleted = "D"
}
