<div>
	<a class="form-check d-flex align-items-center">
		<i class="far me-2 fa-chevron-down" (click)="toggle()"
			[ngClass]="{'fa-chevron-down': isExpanded, 'fa-chevron-right ms-1' : !isExpanded }">
		</i>
		<ng-content select="[rlHierarchyGroupHead]"></ng-content>
	</a>
</div>
<div *ngIf="isExpanded">
	<ng-content select="[rlHierarchyGroupContent]"></ng-content>
</div>