import { KeyValuePipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CharTypeId } from "rl-common/consts";
import { AssociationService } from "rl-common/services/associations/association.service";
import { IDocument } from "rl-common/services/entity/models/i-document";
import { EntityData } from "rl-common/services/mod-detail/mod-detail.models";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { DocumentIndicators, IExecuteWorkflowMetaData, IGenerateDocumentResponse, IPreviewDocument, IProcessActionAssociation, IWorkflowAction } from "rl-common/services/workflow/workflow.models";
import { WorkflowService } from "rl-common/services/workflow/workflow.service";
import { Subscription } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { EntityLinkComponent } from "../../entity/entity-link/entity-link.component";
import { FeatureFlagComponent } from "../../feature-flag/feature-flag.component";
import { JobProgressComponent } from "../../modals/job-progress-modal/job-progress/job-progress.component";
import { IJobProgressResult } from "../../modals/job-progress-modal/job-progress/job-progress.component.models";
import { PanelContentDirective } from "../../panel-switcher/panel-content.directive";
import { PanelSwitcherComponent } from "../../panel-switcher/panel-switcher.component";
import { PanelComponent } from "../../panel-switcher/panel/panel.component";
import { FeatureKeys, FeatureService } from "./../../../../+admin/components/features/feature.service";
import { OneConfigService } from "./../../../services/one-config/one-config.service";
import { ExecuteWorkFlowStep } from "./execute-workflow-wizard.models";
import { GenerateDocumentComponent } from "./generate-document/generate-document.component";
import { PreviewDocumentComponent } from "./preview-document/preview-document.component";
import { PreviewNotificationsComponent } from "./preview-notifications/preview-notifications.component";
import { SendForESignatureComponent } from "./send-for-esignature/send-for-esignature.component";

@Component({
	selector: "rl-execute-workflow-wizard",
	templateUrl: "./execute-workflow-wizard.component.html",
	styleUrls: ["./execute-workflow-wizard.component.scss"],
	imports: [PanelSwitcherComponent, PanelComponent, PanelContentDirective, GenerateDocumentComponent, SendForESignatureComponent, NgIf, PreviewNotificationsComponent, FeatureFlagComponent, NgFor, EntityLinkComponent, JobProgressComponent, PreviewDocumentComponent, KeyValuePipe]
})
export class ExecuteWorkflowWizardComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	models: ISearchRequestModel[];

	@Input()
	action: IWorkflowAction;

	@Input()
	executeWorkflowMetaData: IExecuteWorkflowMetaData;

	@Input()
	documents: IDocument[] = [];

	@Input()
	entityData: EntityData = null;

	@Input()
	expectedCount: number;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onComplete = new EventEmitter<IJobProgressResult>();

	step: ExecuteWorkFlowStep = ExecuteWorkFlowStep.Loading;

	isSendForSignature = false;
	isSendForSignaturePartiesOnly = false;
	isGenerateDocument = false;
	jobId: string;

	file: IPreviewDocument;
	hasNextView = false;
	totalActionCount: number;
	subs: Subscription[] = [];

	private readonly _wfNotExecutedSteps = [
		ExecuteWorkFlowStep.GenerateDocument,
		ExecuteWorkFlowStep.SendForSignature,
		ExecuteWorkFlowStep.SendForSignaturePartiesOnly,
		ExecuteWorkFlowStep.PreviewNotifications
	];

	constructor(
		private readonly _workflowService: WorkflowService,
		private readonly _associationService: AssociationService,
		private readonly _oneConfigService: OneConfigService,
		private readonly featureService: FeatureService
	) { }
	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	ngOnInit() {
		this.isSendForSignature = this.action.docGenIndicator === DocumentIndicators.SendForSignature;
		this.isSendForSignaturePartiesOnly = this.action.docGenIndicator === DocumentIndicators.SendForSignaturePartiesOnly;
		this.isGenerateDocument = this.action.docGenIndicator === DocumentIndicators.DocumentGeneration;
		this.totalActionCount = this.executeWorkflowMetaData.totalChainedActions;
		const shouldImmediatelyExecuteWorkflow$ = this.featureService.isEnabled$(FeatureKeys.PreviewChainedWorkflow)
			.pipe(
				filter(isPreviewChainedWorkflowEnabled => {
					if (this.isGenerateDocument) {
						this.step = ExecuteWorkFlowStep.GenerateDocument;
						return false;
					} else if (this.isSendForSignature) {
						this.step = ExecuteWorkFlowStep.SendForSignature;
						return false;
					} else if (this.isSendForSignaturePartiesOnly) {
						this.step = ExecuteWorkFlowStep.SendForSignaturePartiesOnly;
						return false;
					} else if (isPreviewChainedWorkflowEnabled && this.executeWorkflowMetaData.chainedActions && this.totalActionCount > 0) {
						this.step = ExecuteWorkFlowStep.PreviewChained;
						return false;
					} else if (this.executeWorkflowMetaData.notifications.length > 0) {
						this.step = ExecuteWorkFlowStep.PreviewNotifications;
						return false;
					}
					return true;
				})
			);
		const sub = shouldImmediatelyExecuteWorkflow$
			.pipe(switchMap(() => {
				return this.executeWorkflowActions$();
			})).subscribe();
		this.subs.push(sub);
	}

	getWorkflowActionName(actionAssociation: IProcessActionAssociation) {
		const charTypeId = actionAssociation.charTypeId;
		const processId = actionAssociation.processAction.processId;
		const actionId = actionAssociation.processAction.actionId;
		const action = this._oneConfigService.getWorkflowAction(charTypeId, processId, actionId);
		return action.actionName;
	}

	executeWorkflowActions$() {
		return this._workflowService.bulkExecuteJob(this.charTypeId, this.models, this.action.actionId, this.expectedCount)
			.pipe(
				tap((jobId) => {
					this.proceedToProgressStep(jobId);
				})
			);
	}
	executeWorkflowActions() {
		const sub = this.executeWorkflowActions$()
			.subscribe();
		this.subs.push(sub);
	}

	cancel() {
		if (this._wfNotExecutedSteps.includes(this.step)) {
			this.onCancel.emit();
		}
		this.complete(null);
	}

	complete(result: IJobProgressResult) {
		this.onComplete.emit(result);
	}

	next() {
		// TODO: Refactor to allow refreshing a specific association (Files) instead of all.
		if (this.entityData) {
			this._associationService.refreshAvailableAssociations(this.entityData);
		}
		this.step = ExecuteWorkFlowStep.PreviewDocument;
	}

	proceedToProgressStep(jobId: string) {
		this.jobId = jobId;
		this.step = ExecuteWorkFlowStep.InProgress;
	}

	generateDocumentProgress(event: IGenerateDocumentResponse) {
		this.hasNextView = true;
		this.jobId = event.jobId;
		this.file = event.generatedDocument;
		this.step = ExecuteWorkFlowStep.InProgress;
	}

	previewDocument(file: IPreviewDocument) {
		this.file = file;
		this.step = ExecuteWorkFlowStep.PreviewDocument;
	}

}
