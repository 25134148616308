<div class="datemath__search-table__outer p-4">
	<form class="row pb-2" (ngSubmit)="submitSearch()">
		<div class="col-3">
			<h3>Select a Date
				<!--(step {{step}})-->
			</h3>
		</div>
		<div class="col-7 d-inline-flex align-items-center" *ngIf="step === SearchStep.Entity && !isRelative">
			<div class="form-check me-4 mt-1 text-nowrap">
				<input type="radio" class="form-check-input me-1" value="assoc" [formControl]="allOrAssocControl"
					data-cy="AssociatedOnlyRadio">
				<label for="" class="form-check-label">Associated Only</label>
			</div>
			<div class="form-check me-4 mt-1 text-nowrap">
				<input type="radio" class="form-check-input me-1" value="all" [formControl]="allOrAssocControl"
					data-cy="AllRadio">
				<label for="" class="form-check-label">All</label>
			</div>
			<div class="form-check me-4 mt-1 text-nowrap" *ngIf="shouldShowTermStart">
				<input type="radio" class="form-check-input me-1" value="term-start" [formControl]="allOrAssocControl"
					data-cy="TermStartRadio">
				<label for="" class="form-check-label">Rightset Term Start</label>
			</div>
			<select class="form-select me-2 w-auto" *ngIf="isAllSelected" [formControl]="charTypeIdControl">
				<option *ngFor="let pair of charTypesList" [ngValue]="pair[0]" [innerText]="pair[1]"></option>
			</select>
			<select class="form-select w-auto" *ngIf="isAllSelected && templatesList.length > 0"
				[formControl]="templateIdControl">
				<option [ngValue]='null' [innerText]="'Select Template'"></option>
				<option *ngFor="let pair of templatesList" [ngValue]="pair[0]" [innerText]="pair[1]"></option>
			</select>
		</div>
		<div class="col-2 d-flex align-items-end justify-content-end" *ngIf="showKeywords">
			<input class="form-control form-control-sm search-field" type="text" placeholder="Search"
				[formControl]="keywordsControl">
			<button class="btn btn-icon btn-sm border-bottom" disabled="true" title="Search"><i
					class="far fa-search"></i></button>
		</div>
	</form>
	<div>
		<ul *ngIf="path.length > 0" class="list-inline">
			<li class="list-inline-item pe-3 me-3 title--overline border-end" data-cy="StartOverButton"><a href="#"
					(click)="goToPath($event, null, -1)">Start
					Over</a></li>
			<li *ngIf="isRelative" class="list-inline-item" (click)="goToPath($event, null, -1)"><span
					class="rrpTag">{{rrpTag}}</span> <span class="ps-2 text-grey">&gt;</span></li>
			<li *ngFor="let pathItem of path; let i=index" class="list-inline-item">
				<ng-container
					[ngSwitch]="pathItem.step === SearchStep.SelectRecCharID || pathItem.step === SearchStep.SelectCharId">
					<span *ngSwitchCase="true" [innerText]="pathItem.title"></span>
					<a *ngSwitchDefault href="#" [innerText]="pathItem.title" (click)="goToPath($event, pathItem, i)"
						[attr.data-cy]="pathItem.title"></a>
				</ng-container>
				<span class="ps-2 text-grey">&gt;</span>
			</li>
		</ul>
	</div>
	<ng-template #searching>
		<rl-loader [text]="'Searching'"></rl-loader>
	</ng-template>
	<rl-grid *ngIf="(dataSource.loading$ | async) === false; else searching" class="datemath__search-table__grid"
		[options]="gridOptions" [dataSource]="dataSource" [layoutOptions]="{maxHeight:400}"
		data-cy="DateMathSearchRLGrid">
		<div *rlCellTemplate="'step1_title'; let cellData">
			<a href="#" (click)="goToStep2($event, cellData)" [innerText]="cellData.title"
				[attr.data-cy]="cellData.title"></a>
		</div>
		<div *rlCellTemplate="'step2_title'; let cellData">
			<a href="#" (click)="goToStep3Or4($event, cellData)" [innerText]="cellData.title"
				[attr.data-cy]="cellData.title"></a>
		</div>
		<div *rlCellTemplate="'step3_title'; let cellData">
			<ng-container [ngSwitch]="cellData.entityId === contextEntityId && cellData.charId === contextCharId">
				<a *ngSwitchCase="false" href="#" (click)="selectDate($event, cellData)" [innerText]="cellData.title"
					[attr.data-cy]="cellData.title"></a>
				<span *ngSwitchDefault="" [innerText]="cellData.title"></span>
			</ng-container>
		</div>
		<div *rlCellTemplate="'step4_title'; let cellData">
			<a href="#" (click)="goToStep5($event, cellData)" [innerText]="cellData.title"
				[attr.data-cy]="cellData.title"></a>
		</div>
		<div *rlCellTemplate="'step5_date'; let cellData">
			<ng-container *ngIf="cellData">
				<input *ngIf="cellData.entityId !== contextEntityId || cellData.charId !== contextCharId" type="radio"
					(change)="selectDateRadio($event, cellData)" class="form-check-input"
					[checked]="relationship?.parentRecCharID && relationship?.parentRecCharID === cellData.recCharId">
				<label for="" class="mb-0" [innerText]="formatDate(cellData.dateValue)"
					[title]="relationship?.parentRecCharID + ' '+ cellData.recCharId"></label>
			</ng-container>
		</div>
		<div *rlCellTemplate="'step5_amounts'; let cellData">
			<ng-container *ngIf="cellData">
				<a href="#" [innerText]="cellData.title" (click)="goToStep6($event, cellData)"
					[attr.data-cy]="cellData.title"></a>
			</ng-container>
		</div>
		<div *rlCellTemplate="'step5_assoc_items'; let cellData">
			<div class="text-truncate" *ngIf="cellData" [title]="cellData.join(' | ')">
				<span [innerText]="cellData.join(' | ')"></span>
			</div>
		</div>
		<div *rlCellTemplate="'step9_title'; let cellData">
			<a href="#" (click)="selectRelativeToCatalogChar($event, cellData)" [innerText]="cellData.title"></a>
		</div>
		<div *rlCellTemplate="'step2_switch'; let cellData">
			<a href="#" (click)="switchToStep2($event, cellData)">Dates from Associations</a>
		</div>
		<div *rlCellTemplate="'step12_title'; let cellData">
			<a href="#" (click)="selectRelativeToDealChar($event, cellData)" [innerText]="cellData.title"></a>
		</div>
	</rl-grid>
</div>