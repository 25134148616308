import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { IDataDefaultSet } from "rl-common/services/data-default-set/data-default-set.models";
import { DataDefaultSetService } from "rl-common/services/data-default-set/data-default-set.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgFor, NgIf } from "@angular/common";

@Component({
    selector: "rl-apply-data-default-modal",
    templateUrl: "./apply-data-default-modal.component.html",
    imports: [ReactiveFormsModule, FormsModule, NgFor, NgIf]
})
export class ApplyDataDefaultModalComponent implements OnInit, OnDestroy {

	constructor(
		private readonly _dataDefaultSetService: DataDefaultSetService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService,
		private readonly activeModal: NgbActiveModal,

	) { }

	private readonly _subscriptions: Subscription[] = [];
	@Output()
	onComplete = new EventEmitter<string[]>();

	@Input()
	charTypeId: number;

	@Input()
	recordId: number;

	@Input()
	dataDefaults: IDataDefaultSet[];

	rightCharTypeId: CharTypeId.Right;
	selectedDataDefault: IDataDefaultSet;
	isRunning = false;

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	ngOnInit(): void {

	}

	get isValid() {
		return this.selectedDataDefault != null;
	}

	applyDataDefault() {
		this.isRunning = true;
		const sub = this._dataDefaultSetService.copyDataDefaultSet(this.selectedDataDefault.id, this.charTypeId, this.recordId).pipe(
			finalize(() => {
				this.isRunning = false;
			})
		).subscribe((result) => {
				this.activeModal.close();
				this._growlerService.success().growl(`Table Profile Set ${this.selectedDataDefault.label} applied.`);
				this.onComplete.emit(result.entityIds);
			});
		this._subscriptions.push(sub);
	}

	cancel() {
		this.activeModal.dismiss();
	}
}
