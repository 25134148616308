import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { SessionService } from "rl-common/services/session.service";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Directive({ selector: "[rlIsRlAdmin]" })
export class IsRlAdminDirective implements OnInit, OnDestroy {

	private readonly _subscriptions: Subscription[] = [];

	constructor(private readonly _templateRef: TemplateRef<any>,
		private readonly _viewContainer: ViewContainerRef,
		private readonly _sessionService: SessionService) {
	}

	ngOnInit(): void {
		const sub = this._sessionService.isRlAdmin$.pipe(
			distinctUntilChanged()
		).subscribe(isRlAdmin => {
			if (isRlAdmin) {
				this._viewContainer.createEmbeddedView(this._templateRef);
			} else {
				this._viewContainer.clear();
			}
		});
		this._subscriptions.push(sub);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

}