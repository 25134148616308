<div class="modal-header">
	<h2 class="modal-title">Give Rightsline Login Permissions</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div *ngIf="step == 0">
		<h4>Give login permissions to an existing Contact below, or click the button to "Create New User".</h4>
		<rl-entity-search [dataSource]="dataSource" [searchOptions]="searchOptions" target="_blank"
			[alwaysShowHeader]="true">
		</rl-entity-search>
	</div>
	<div *ngIf="step == 1">
		<h4 class="mb-4">Create a new Rightsline User</h4>
		<div class="form" [formGroup]="form">
			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span class="text-danger me-1">*</span>
					<span>Name</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display" [class.has-error]="form.controls.name.invalid">
						<input type="text" class="form-control" formControlName="name" />
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span class="text-danger me-1">*</span>
					<span>Email</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display" [class.has-error]="form.controls.email.invalid">
						<input type="text" class="form-control" formControlName="email" />
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span class="text-danger me-1">*</span>
					<span>Template</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display" [class.has-error]="form.controls.template.invalid">
						<select class="form-select" formControlName="template">
							<option *ngFor="let template of templates" [ngValue]="template.templateID">
								{{ template.templateName }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="char-data__group__element ">
				<div class="char-data__label col-form-label">
					<span class="text-danger me-1">*</span>
					<span>Grant Access to</span>
				</div>
				<div class="char-data__control container m-0" [class.text-danger]="form.controls.applications.invalid">
					<div class="char-data-element-display" formArrayName="applications">
						<div *ngFor="let app of apps; let i = index" class="d-inline-flex">
							<input type="checkbox" class="form-check-input" id="app-{{ app.appUrlID }}"
								[formControlName]="i" />
							<label class="form-check-label ms-2" for="app-{{ app.appUrlID }}">
								{{ app.label }}
							</label>
						</div>
					</div>
				</div>
			</div>

			<div formArrayName="identityRoles" class="mt-4">
				<h5><span class="text-danger me-1">*</span> Select at least one Identity Role:</h5>
				<div class="border" style="max-height:350px;overflow:auto;"
					[class.text-danger]="form.controls.identityRoles.invalid">
					<div *ngFor="let role of identityRoles; let i = index" class="d-inline-flex p-2 border-bottom">
						<input type="checkbox" id="idRole-{{role.roleID}}" class="form-check-input"
							[formControlName]="i">
						<label for="idRole-{{role.roleID}}" class="form-check-label ms-2">
							{{ role.name }}
						</label>
					</div>
				</div>
			</div>

			<div formArrayName="workflowRoles" class="mt-4">
				<h5><span class="text-danger me-1">*</span> Select at least one Workflow Role:</h5>
				<div class="border" style="max-height:350px;overflow:auto;"
					[class.text-danger]="form.controls.workflowRoles.invalid">
					<div *ngFor="let role of workflowRoles; let i = index" class="d-inline-flex p-2 border-bottom">
						<input type="checkbox" id="wfRole-{{role.roleID}}" class="form-check-input"
							[formControlName]="i">
						<label for="wfRole-{{role.roleID}}" class="form-check-label ms-2">
							{{ role.roleName }}
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer d-inline-flex">
	<button type="button" class="btn btn-light ms-5" (click)="close()">Cancel</button>
	<button class="btn btn-info ms-3" type="button" *ngIf="selectedUser && step == 0" (click)="nextStep()">
		<span>Invite Existing User</span>
	</button>
	<button class="btn btn-success ms-3" type="button" *ngIf="!selectedUser && step == 0" (click)="nextStep()">
		<span>Create New User</span>
	</button>
	<button class="btn btn-success ms-3" type="button" [disabled]="form?.invalid || isSaving" *ngIf="step == 1"
		(click)="inviteUser()">
		<span>Save</span>
	</button>
</div>