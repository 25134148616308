import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Provider } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { IAssocChanges } from "rl-common/services/entity/models/assoc-changes.model";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { SettingsService } from "rl-common/services/settings/settings.service";
import { Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { ComponentRelationshipComponent } from "../../../associations/entity-relationships/entity-relationship/component-relationship.component";
import { GridDataSourceBuilder } from "../../datasource/builders/grid-datasource-builder";
import { ParentEntityService } from "./../../../../services/entity/parent-entity/parent-entity.service";
import { ModDetailService } from "./../../../../services/mod-detail/mod-detail.service";
import { EntityRelationshipDataSelectStrategy } from "./../../datasource/data-select/entity-relationship-data-select.strategy";
import { AssocEntitySelectDataSource } from "./../../datasource/search/assoc-entity-select.datasource";
import { SqlAssocEntitySearchDataSource } from "./../../datasource/search/sql-assoc-entity-search.datasource";
import { AssocEntitySelectModalAdapter, IAssocEntitySelectModalComponent } from "./assoc-entity-select-modal.adapter";

@Component({
    selector: "rl-assoc-entity-select-modal",
    templateUrl: "./assoc-entity-select-modal.component.html",
    styleUrls: ["./assoc-entity-select-modal.component.scss"],
    imports: [ComponentRelationshipComponent]
})
export class AssocEntitySelectModalComponent implements OnInit, OnDestroy, IAssocEntitySelectModalComponent {

	@Input()
	modalTitle: string;

	@Input()
	contextCharTypeId: number;

	@Input()
	contextRecordId: number = null;

	@Input()
	contextTemplateId: number;

	@Input()
	assocCharTypeId: number;

	@Input()
	assocChanges: IAssocChanges;

	@Output()
	associatedEntityRecsChange = new EventEmitter<IAssocChanges>();

	dataSource: AssocEntitySelectDataSource<IEntitySearchDoc, IEntitySearchDoc> | SqlAssocEntitySearchDataSource<IEntitySearchDoc, IEntitySearchDoc>;
	dataSelectStrategy: EntityRelationshipDataSelectStrategy<IEntitySearchDoc, number, IGridViewAssocEntityRec>;
	entityRelationshipState: IEntityRelationshipState<number, IGridViewAssocEntityRec>;
	isFirstLoad = true;
	private useSqlServerDataSource = false;

	private _subs: Subscription[] = [];

	constructor(
		private readonly _modal: NgbActiveModal,
		private readonly gridDataSourceBuilder: GridDataSourceBuilder,
		@Optional() private readonly _modDetailService: ModDetailService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _settings: SettingsService
	) {

	}

	ngOnInit(): void {
		const sub = this._settings.divSettings$.pipe(
			map(settings => settings["useSqlServerDatasource"]),
			tap(setting => this.useSqlServerDataSource = setting?.value?.toLocaleLowerCase() === "true")
		).subscribe(setting => {
			this.buildDataSource();
		});
		this._subs.push(sub);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	buildDataSource() {
		let parentCharTypeId = null;
		let parentRecordId = null;
		if (this._modDetailService) {
			parentCharTypeId = this._modDetailService.entity.charTypeId;
			parentRecordId = this._modDetailService.entity.recordId;
			if (this._parentEntityService.entity) {
				parentCharTypeId = this._parentEntityService.entity.charTypeId;
				parentRecordId = this._parentEntityService.entity.recordId;
				if (this._parentEntityService.entity.parent) {
					parentCharTypeId = this._parentEntityService.entity.parent.charTypeId;
					parentRecordId = this._parentEntityService.entity.parent.recordId;
				}
			}
		}
		const defaultSelectedIds = (this.assocChanges?.current ?? []).map(r => r.recordId);
		const contextTemplateId = this.contextTemplateId ?? this._parentEntityService.contextTemplateId;
		// TODO: div 66 hack (remove me later)
		if (this.useSqlServerDataSource && (this.isCatalogsOnRights(this.assocCharTypeId) || this.isNestedTables(this.assocCharTypeId, parentCharTypeId) || this.isTablesOnDeal(this.assocCharTypeId, parentCharTypeId))) {
			this.dataSource = this.gridDataSourceBuilder.sqlAssocEntitySearchDataSource(this.assocCharTypeId, this.contextCharTypeId, contextTemplateId,
				this.contextRecordId, defaultSelectedIds, parentCharTypeId, parentRecordId);
		} else {
			this.dataSource = this.gridDataSourceBuilder.assocEntitySearchDataSource(this.assocCharTypeId, this.contextCharTypeId, contextTemplateId,
				this.contextRecordId, defaultSelectedIds, parentCharTypeId, parentRecordId);
		}

		const sub = this.dataSource.fetchRows().subscribe();
		const sub2 = this.dataSource.indexedRowData$
			.pipe(
				filter(rd => !!rd)
			)
			.subscribe((rowData) => {
				if (this.assocChanges?.selectState?.netChanges?.isAllSelected) {
					const dataSelectStrategy = this.dataSource.dataSelectStrategy;
					if (this.isFirstLoad) {
						dataSelectStrategy.selectAll();
					}
					const deletedIds = Array.from(this.assocChanges.selectState.netChanges.deletedIds);
					const deselectedRowData = rowData.filter(rd => deletedIds.includes(rd.data.recordID));
					deselectedRowData.forEach(rd => dataSelectStrategy.deselectRow(rd));
				}
				this.isFirstLoad = false;
			});
		this._subs.push(sub, sub2);
	}

	isCatalogsOnRights(relCharTypeId: CharTypeId) {
		return this.contextCharTypeId === CharTypeId.Right && relCharTypeId === CharTypeId.Property;
	}

	isNestedTables(relCharTypeId: CharTypeId, parentCharTypeId: CharTypeId) {
		return parentCharTypeId === CharTypeId.Relationship && this.contextCharTypeId === CharTypeId.Usage && relCharTypeId === CharTypeId.Property;
	}

	isTablesOnDeal(relCharTypeId: CharTypeId, parentCharTypeId: CharTypeId) {
		return parentCharTypeId === CharTypeId.Transaction && this.contextCharTypeId === CharTypeId.Usage && relCharTypeId === CharTypeId.Property;
	}

	close() {
		this._modal.close();
	}

	updateEntityRelationship(event: IEntityRelationshipState<number, IGridViewAssocEntityRec>) {
		this.entityRelationshipState = event;
	}

	apply() {
		const associatedEntityRecs = Array.from(this.entityRelationshipState.selectedValues);
		this.associatedEntityRecsChange.emit({
			current: associatedEntityRecs,
			selectState: this.entityRelationshipState,
			charTypeId: this.assocCharTypeId
		});
	}
}

export const ASSOCENTITYSELECT_MODAL_PROVIDER: Provider = {
  provide: AssocEntitySelectModalAdapter,
  useFactory: () => new AssocEntitySelectModalAdapter(AssocEntitySelectModalComponent)
};

