import { ICharacteristicType } from "rl-common/models/i-characteristic-type";

export class GetCopyCharTypesResponse {
	copyToCharTypes: ICharacteristicType[];
	switchToCharTypes: ICharacteristicType[];
}

export class CopyWarningChecks {
	lockedAssociations: string;
	invalidFields: string;
	invalidParties: string;
	invalidAssociations: [number[]];
}

export enum CopyMethod {
	Copy,
	CopyTo,
	SwitchTemplate
}
