<div class="d-inline-flex align-items-center" *ngIf="templates.length > 1">
	<label class="title--overline mb-0 text-nowrap" [for]="templateSelectorId">
		<ng-content></ng-content>
	</label>
	<select [id]="templateSelectorId" class="form-select ms-2" [formControl]="templateControl"
		(change)="changeTemplate()" data-cy="TemplateSelector">
		<option *ngIf="includeSelect" [value] ="-1">Select a Template</option>
		<option *ngFor="let t of templates" [value]="t.templateID" [attr.data-cy]="t.templateName">
			{{labelFn(t)}}
		</option>
	</select>
</div>