<div [formGroup]="form">
	<div class="d-flex align-items-center bg-light p-2 border-bottom" formArray="notificationArray"
		*ngFor="let notificationControl of notificationsFormControls; let i = index; let last = last"
		[class.mb-3]="last">
		<div class="flex-grow-1">
			<div [class.has-error]="hasError(notificationControl)">
				<rl-notification-row [form]="form" [notifications]="notifications"
					[notificationControl]="notificationControl" [allRoles]="allRoles" [allParties]="allParties"
					[isCreate]="isCreate"></rl-notification-row>
				<ng-container *ngIf="hasError(notificationControl)">
					<div *ngIf="notificationControl.errors?.invalidNotificationId" class="help-block">Notification is
						required.</div>
					<div *ngIf="notificationControl.errors?.invalidRolesAndParties && !form.errors?.duplicateNotificationId"
						class="help-block">At least one Role or Party is required.</div>
					<div *ngIf="form.errors?.duplicateNotificationId" class="help-block">Duplicate notifications are not
						permitted.
					</div>
				</ng-container>
			</div>
		</div>
		<button type="button" class="btn btn-icon btn-remove me-2 ms-auto" (click)="removeRow(i)" title="Remove Row"
			data-cy="removeRow"><i class="far fa-times"></i></button>
	</div>
	<div class="d-flex justify-content-between">
		<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="notificationsIsEmpty"
			[disabled]="notificationsAllInUse" data-cy="addAnotherRow">
			<i class="far fa-plus"></i> Add another row
		</button>
		<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="!notificationsIsEmpty"
			data-cy="addRow">
			<i class="far fa-plus"></i> Add row
		</button>
		<button type="button" class="btn btn-sm btn-remove text-grey" (click)="clearRows()"
			[hidden]="notificationsIsEmpty" data-cy="clearRows">
			<i class="far fa-times me-1"></i> Clear all
		</button>
	</div>
	<div class="d-flex mt-4">
		<em>Note: To prevent spam designation and delivery delays,
			we recommend a limit of 100 recipients (Roles & Parties) for each notification.
		</em>
	</div>
</div>