import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Provider } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { IDocument } from "rl-common/services/entity/models/i-document";
import { EntityData } from "rl-common/services/mod-detail/mod-detail.models";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { IExecuteWorkflowMetaData, IWorkflowAction } from "rl-common/services/workflow/workflow.models";
import { of, Subscription } from "rxjs";
import { IJobProgressResult } from "../modals/job-progress-modal/job-progress/job-progress.component.models";
import { WorkflowService } from "./../../services/workflow/workflow.service";
import { ExecuteWorkflowModalAdapter, IExecuteWorkflowModalComponent } from "./execute-workflow-modal.adapter";
import { ExecuteWorkflowWizardComponent } from "./execute-workflow-wizard/execute-workflow-wizard.component";

@Component({
	selector: "rl-execute-workflow-modal",
	templateUrl: "./execute-workflow-modal.component.html",
	styleUrls: ["./execute-workflow-modal.component.scss"],
	imports: [NgIf, ExecuteWorkflowWizardComponent]
})
export class ExecuteWorkflowModalComponent implements OnInit, OnDestroy, IExecuteWorkflowModalComponent {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	models: ISearchRequestModel[];

	@Input()
	recordId: number;

	@Input()
	expectedCount: number;

	@Input()
	entityData: EntityData;

	@Input()
	documents: IDocument[];

	@Input()
	action: IWorkflowAction;

	@Output()
	onComplete = new EventEmitter<IExecuteWorkflowMetaData>();

	executeWorkflowMetaData: IExecuteWorkflowMetaData;
	subscriptions: Subscription[] = [];
	public INVALID_PARAMETERS_ERROR = `Either a record id or a list of search models needs to be defined. `;

	constructor(
		private readonly _workflowService: WorkflowService,
		private readonly _modal: NgbActiveModal
	) { }

	ngOnInit() {
		let metaData$ = of(null);
		if (this.recordId) {
			metaData$ = this._workflowService.getExecuteWorkflowMetaData(this.charTypeId, this.recordId, this.action.processId, this.action.actionId);
		} else if (this.models && this.models.length > 0) {
			metaData$ = this._workflowService.getBulkExecuteWorkflowMetaData(this.charTypeId, this.models, this.action.processId, this.action.actionId, this.expectedCount);
		} else {
			throw new Error(this.INVALID_PARAMETERS_ERROR);
		}
		const sub = metaData$
			.subscribe(executeWorkflowMetaData => this.executeWorkflowMetaData = executeWorkflowMetaData);
		this.subscriptions.push(sub);
	}

	cancel() {
		this._modal.close();
	}

	emitComplete(result: IJobProgressResult) {
		this.executeWorkflowMetaData.result = result;
		this.onComplete.emit(this.executeWorkflowMetaData);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}

export const EXECUTEWORKFLOW_MODAL_PROVIDER: Provider = {
	provide: ExecuteWorkflowModalAdapter,
	useFactory: () => new ExecuteWorkflowModalAdapter(ExecuteWorkflowModalComponent)
};

