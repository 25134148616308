import { NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { DivAvailsStatus } from "rl-common/services/admin-util/admin-util.models";
import { AdminUtilService } from "rl-common/services/admin-util/admin-util.service";
import { interval, of, Subscription } from "rxjs";
import { catchError, startWith, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "rl-avails-status",
	templateUrl: "./avails-status.component.html",
	styleUrls: ["./avails-status.component.scss"],
	imports: [NgSwitch, NgSwitchCase, NgbAlert, NgSwitchDefault]
})
export class AvailsStatusComponent implements OnInit, OnDestroy {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	DivAvailsStatus = DivAvailsStatus;
	divAvailsStatus: DivAvailsStatus;
	pendingResync = false;

	private _subs: Subscription[] = [];

	public get showAlert() {
		return [DivAvailsStatus.Inactive, DivAvailsStatus.Paused, DivAvailsStatus.ConfigMismatch].includes(this.divAvailsStatus) || this.pendingResync;
	}

	public get showAlertOnDashboard() {
		return [DivAvailsStatus.Paused, DivAvailsStatus.ConfigMismatch].includes(this.divAvailsStatus) || this.pendingResync;
	}


	constructor(private readonly _adminUtilService: AdminUtilService) { }
	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	ngOnInit(): void {

		const sub = interval(60_000)
			.pipe(
				startWith(0),
				switchMap(() => this._adminUtilService.getDivAvailsStatus()),
				tap(({ status, pendingResync }) => {
					this.divAvailsStatus = status;
					this.pendingResync = pendingResync;
				}),
				catchError(() => of(null))
			)
			.subscribe();
		this._subs.push(sub);
	}

}
