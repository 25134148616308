import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService } from "./../../services/authentication/authentication.service";

@Component({
    selector: "rl-maintenance",
    templateUrl: "./maintenance.component.html",
    styleUrls: ["./maintenance.component.scss"]
})
export class MaintenanceComponent implements OnInit {
	private _subs: Subscription[] = [];
	@Output()
	onBypass = new EventEmitter();
	constructor(
		private readonly _authenticationService: AuthenticationService
	) { }

	ngOnInit(): void {
	}
	bypassMaintenance() {
		const sub = this._authenticationService.bypassMaintenance().subscribe(() => {
			this.onBypass.emit();
		});
		this._subs.push(sub);
	}
}
