import { CdkPortalOutlet, ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectorRef, Component, effect, EventEmitter, Injector, input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { chain, Dictionary } from "lodash";
import { ICharDataChangedEvent } from "rl-common/components/char-data/char-data.models";
import { tableValidator } from "rl-common/components/char-data/elements/element-validators";
import { IModLayout, IPanelGroup, IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { ModPanelService } from "rl-common/services/mod-detail/mod-panel.service";
import { SessionService } from "rl-common/services/session.service";
import { AclUtil } from "rl-common/utils/acl.util";
import { merge, Observable, Subscription } from "rxjs";
import { debounceTime, filter, pairwise, tap } from "rxjs/operators";
import { MOD_SECTION_PARAMS } from "../mod-details-layout/mod-layout-sections-params.models";
import { ComponentKey } from "../mod-details-layout/mod-layout.consts";
import { componentMappings } from "../mod-details-layout/mod-layout.models.mappings";
import { EditMode } from "../panel-switcher/panel-switcher.models";

interface IModSection {
	key: ComponentKey;
	portal: ComponentPortal<any>;
	section: IPanelSection<unknown>;
}

@Component({
	selector: "rl-mod-layout-char-data-table",
	templateUrl: "./mod-layout-char-data-table.component.html",
	styleUrls: ["./mod-layout-char-data-table.component.scss"],
	providers: [CharDataTableService, ModPanelService],
	imports: [CdkPortalOutlet]
})
export class ModLayoutCharDataTableComponent implements OnInit, OnDestroy {

	layout = input.required<IModLayout>();
	charData = input<ICharacteristicData[]>([]);
	template = input.required<ICharacteristicMetaDataCollection>();
	recordId = input<number>();
	editMode = input<boolean>(false);

	@Output()
	tableCharDataChange = new EventEmitter<ICharDataChangedEvent>();

	sections: IModSection[] = [];

	tableFormGroup: UntypedFormGroup;
	groups: ITemplateCharacteristicGroup[] = [];
	groupCmds: Dictionary<ICharacteristicMetaData[]> = {};

	private _tableFormSub: Subscription;
	private _tableFormValueChangeSubs: Subscription[] = [];

	get valid() {
		return this.tableFormGroup?.valid ?? false;
	}

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _injector: Injector,
		private readonly _cdRef: ChangeDetectorRef,
		private readonly _charDataTableService: CharDataTableService,
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _sessionService: SessionService,
		private readonly _modPanelService: ModPanelService,
	) {
		effect(() => {
			const editMode = this.editMode() ? EditMode.Edit : EditMode.Read;
			this._modPanelService.toggleEditMode(editMode);
		});
	}

	ngOnInit() {

		if (this.layout()) {
			const fieldSections = this.getAllFieldSections(this.layout());
			this.sections = fieldSections.map(s => this.buildSectionPortal(s)).filter(x => !!x);
		}

		this.initCharData(this.recordId(), this.charData(), this.template());
	}

	private getAllFieldSections(layout: IModLayout) {
		const sections: IPanelSection<unknown>[] = [];
		const layoutGroups = layout?.groups ?? [];
		layoutGroups.forEach(grp => {

			const panels = grp?.panels ?? [];
			panels.forEach(panel => {
				const childSections: IPanelSection<unknown>[] = [];
				this.getFieldSections(panel, childSections);
				sections.push(...childSections);
			});

		});
		return sections;
	}

	private getFieldSections(panel: IPanelGroup, sections: IPanelSection<unknown>[]) {
		if (!panel) {
			return;
		}

		const fieldSections = (panel.sections ?? []).filter(x => x.key === ComponentKey.FieldSection);
		sections.push(...fieldSections);

		const children = panel.childGroups ?? [];

		children.forEach(child => {
			this.getFieldSections(child, sections);
		});
	}

	buildSectionPortal(section: IPanelSection<unknown>): IModSection {
		const component = componentMappings[section.key];

		// if there's no mapping we default to the not found component
		if (!component) {
			return null;
		}

		const injector = Injector.create({
			parent: this._injector,
			providers: [
				{ provide: MOD_SECTION_PARAMS, useValue: section ?? {} }
			]
		});

		return {
			portal: new ComponentPortal(component, null, injector),
			key: section.key,
			section: section
		};
	}

	initCharData(recordId: number, charData: ICharacteristicData[], template: ICharacteristicMetaDataCollection) {
		let updateTableFormGroup = false;
		let cmds: ICharacteristicMetaData[];
		let groupCmds: Dictionary<ICharacteristicMetaData[]>;
		let groups: ITemplateCharacteristicGroup[];
		this._charDataTableService.setShowEmpty(true);

		if (recordId) {
			updateTableFormGroup = this._charDataTableService.recordID$.value !== recordId;
			this._charDataTableService.setRecordId(recordId);
		}

		let startingTagLabels = new Set<string>();
		if (this._charDataTableService.template) {
			cmds = this._charDataTableService.cmds;
			groupCmds = this._charDataTableService.groupCmds;
			groups = this._charDataTableService.groups;
			// get a list of any cmds from before a possible table group rebuild
			startingTagLabels = new Set<string>(cmds.map(cmd => cmd.tagLabel));
		}

		this._charDataTableService.setCharData(charData ?? []);

		let tableFormGroup = this.tableFormGroup;
		if (template) {
			// changed template means lets rebuild the table group from scratch
			this._charDataTableService.setTemplate(template);
			tableFormGroup = this._formBuilder.group({}, { validator: tableValidator(this._charDataTableService.cmds) });
			this._tableFormValueChangeSubs.forEach(s => s.unsubscribe());
			const sub1 = this.getTableFormGroupValueChangesObs(tableFormGroup).subscribe();
			this._tableFormValueChangeSubs.push(sub1);
			const sub2 = tableFormGroup.statusChanges
				.pipe(
					pairwise(),
					filter(pair => pair[0] === "PENDING"),
					tap(() => this._cdRef.markForCheck())
				)
				.subscribe();

			this._tableFormValueChangeSubs.push(sub2);

			updateTableFormGroup = true;
		}

		if (updateTableFormGroup && this._charDataTableService.template) {
			cmds = this._charDataTableService.cmds;
			groupCmds = this._charDataTableService.groupCmds;
			groups = this._charDataTableService.groups;
			// figure out which startingTagLabels aren't there anymore
			cmds.forEach(cmd => startingTagLabels.delete(cmd.tagLabel));
			// unsub previous sub
			this._tableFormSub?.unsubscribe();
			// setup tableFormGroup, subscribe to it's observables
			const { charDataRules$ } = this._charDataTableService.setupTableFormGroup(tableFormGroup, cmds, this._charDataTableService.charDatas, Array.from(startingTagLabels));
			this._tableFormSub = charDataRules$.subscribe();
			this.groupCmds = groupCmds;
			this.groups = groups;
			this._charDataTableService.setTableFormGroupValues(tableFormGroup, false);
		} else if (this._charDataTableService.template && charData) {
			// if no template change, update tableFormGroup values from charData
			this._charDataTableService.setTableFormGroupValues(tableFormGroup, false);
		}

		this.tableFormGroup = tableFormGroup;

		if (updateTableFormGroup && charData) {
			cmds.forEach(cmd => {
				if (charData) {
					const cd = charData.filter(x => x.charactersticID === cmd.characteristicID);
					if (!this.hasPermissions(cmd, cd)) {
						this.getFormControl(cmd)?.disable();
					}
				}
			});
		}
	}

	private getTableFormGroupValueChangesObs(tableFormGroup: UntypedFormGroup): Observable<void> {
		const statusChanged$ = tableFormGroup.statusChanges.pipe(
			pairwise(),
			filter(pair => pair[0] === "PENDING" && pair[0] !== pair[1])
		);
		const localAlertsChanged$ = this._charDataTableService.localAlerts$;
		return merge(tableFormGroup.valueChanges, this._charDataTableService.charDataInternallyModified$, statusChanged$, localAlertsChanged$)
			.pipe(
				debounceTime(100),
				tap(() => {
					const charDatas = this._charDataTableService.charDatas;
					const tableValues = this.tableFormGroup?.getRawValue();
					const cmds = this._charDataTableService.cmds ?? [];
					cmds.forEach(cmd => {
						const value = tableValues[cmd.tagLabel] as ICharacteristicData[];
						if (value == null) {
							delete charDatas[cmd.characteristicID];
						} else {
							charDatas[cmd.characteristicID] = value;
						}
					});
					const charData = chain(charDatas).values().flatten().value();
					const alerts = this._charDataTableService.localAlerts$.value;
					this.tableCharDataChange.emit({ isValid: this.tableFormGroup?.valid, charData, alerts });
					// setTimeout(() => this.onChange(this.charData));
				})
			);
	}

	getFormControl<T extends UntypedFormControl = UntypedFormControl>(cmd: ICharacteristicMetaData): T {
		return this.tableFormGroup.controls[cmd.tagLabel] as T;
	}

	hasPermissions(cmd: ICharacteristicMetaData, cd: ICharacteristicData[]) {
		return AclUtil.hasWriteAccess(this._sessionService.acls, cmd.acl);
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
