import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { INotification, NotificationStatus } from "rl-common/components/notifications/notification.models";
import { CharTypeId } from "rl-common/consts";
import { DateTimeFormatType } from "rl-common/utils";
import { Subscription } from "rxjs";
import { NotificationService } from "../notification.service";
import { NgIf } from "@angular/common";
import { CharTypeNamePipe } from "../../../pipes/char-type-name.pipe";
import { LocalDatePipe } from "../../../pipes/local-date.pipe";

@Component({
    selector: "rl-notification-modal",
    templateUrl: "./notification-modal.component.html",
    styleUrls: ["./notification-modal.component.scss"],
    imports: [NgIf, CharTypeNamePipe, LocalDatePipe]
})
export class NotificationModalComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	notification: INotification;

	@Output()
	markedAsRead = new EventEmitter<boolean>();

	markingAsRead = false;

	private readonly _subscriptions: Subscription[] = [];

	DateTimeFormatType = DateTimeFormatType;

	get isUnread() {
		return this.notification.status !== NotificationStatus.Read;
	}

	constructor(private readonly _notificationService: NotificationService) { }

	ngOnInit(): void {
	}

	markAsRead() {
		this.markingAsRead = true;
		const sub = this._notificationService.setNotificationStatus(this.charTypeId, [this.notification.id], NotificationStatus.Read).subscribe(() => {
			this.markingAsRead = false;
			this.markedAsRead.next(true);
		});
		this._subscriptions.push(sub);
	}

	close() {
		this.markedAsRead.next(false);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

}
