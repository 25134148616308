<div data-cy="DeleteConfirmationModalContainer">
	<div class="modal-header" data-cy="DeleteConfirmationModalHeader">
		@if (showChips) {
		<h2 class="modal-title">Delete {{ numRecordsToDelete }} Selected {{ recordLabel }}</h2>
		} @else {
		<h2 class="modal-title">Delete {{ recordLabel }}</h2>
		}
		<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()" data-cy="CloseDeleteModalButton">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body" data-cy="DeleteConfirmationModalBody">
		<ng-container *ngIf="initializedWithDataBridgedRecords">
			<h4 class="w-75">Some records have been synced to Alliant which may potentially be involved in financial calculations and additional
				checks are required to confirm this. Are you sure you wish to also proceed with the deletion of the “Synced Alliant Records?” </h4>
			<h4 class="mb-3">An email will be sent to you with the deletion status once complete. If you do not wish to delete these synced Alliant
				records, x out
				those records in the section below.</h4>
		</ng-container>
		<ng-container *ngIf="selectedRecords.length > 0">
			@if (showChips) {
			<h4>Selected {{ recordLabel }}:</h4>
			<rl-chip *ngFor="let record of selectedRecords" (onRemove)="remove(record)" [attr.data-cy]="record.title">
				{{ record.title }}
			</rl-chip>
			} @else {
			<h4>Are you sure you want to delete {{ selectedRecords.length }} {{ recordLabel }}?</h4>
			}
		</ng-container>
		<ng-container *ngIf="selectedDataBridgedRecords.length > 0">
			@if (showChips) {
			<h4 class="mt-3">Selected Data Bridged {{ recordLabel }}:</h4>
			<rl-chip *ngFor="let record of selectedDataBridgedRecords" (onRemove)="removeDataBridgedRecord(record)" [attr.data-cy]="record.title">
				{{ record.title }}
			</rl-chip>
			} @else {
			<h4>Are you sure you want to delete {{ selectedDataBridgedRecords.length }} {{ recordLabel }}?</h4>
			}
		</ng-container>
	</div>
	<div class="modal-footer" data-cy="DeleteConfirmationModalFooter">
		<div class="d-flex align-items-center">
			<input type="text" name="deleteConfirm" placeholder="Type 'DELETE' to confirm." (keyup)="confirmDelete($event)" class="form-control me-4"
				data-cy="DeleteConfirmField" [(ngModel)]="deleteInput" rlAutoFocus />
			<button type="button" class="btn btn-light" (click)="decline()" data-cy="CancelDeleteModalButton">Cancel</button>
			<button type="button" class="btn btn-info ms-3" (click)="accept()" data-cy="ConfirmDeleteModalButton" [disabled]="!deleteConfirmed">Delete
				{{ numRecordsToDelete }} {{ recordLabel }}</button>
		</div>
	</div>
</div>