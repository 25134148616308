import { AsyncPipe, NgClass } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { SessionService } from "rl-common/services/session.service";
import { TokenService } from "rl-common/services/token.service";
import { Subscription } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { ProfilePictureComponent } from "../profile-picture/profile-picture.component";

@Component({
	selector: "rl-upload-profile-picture",
	templateUrl: "./upload-profile-picture.component.html",
	styleUrls: ["./upload-profile-picture.component.scss"],
	imports: [NgClass, ProfilePictureComponent, AsyncPipe]
})
export class UploadProfilePictureComponent implements OnInit, OnDestroy {

	@Input()
	size: "xxs" | "xs" | "sm" | "md" | "lg";

	private subscriptions: Subscription[] = [];

	constructor(
		public session: SessionService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _tokenService: TokenService) { }

	ngOnInit() {
	}

	openUploadModal() {
		const sub = this._modalService.openSelectAvatarModal()
			.pipe(
				tap((result) => this.session.profileAvatar$.next(result)),
				mergeMap(() => this._tokenService.refreshToken()))
			.subscribe();

		this.subscriptions.push(sub);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
