import { NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { flatMap, sum } from "lodash";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { BulkCreateAmountsColumnStrategy } from "rl-common/components/grid/datasource/columns/bulk-create-amounts-column-strategy";
import { EntityCharDataDataChangeStrategy } from "rl-common/components/grid/datasource/data-change/entity-chardata-data-change.strategy";
import { IGridDataSource } from "rl-common/components/grid/datasource/grid-datasource";
import { RowNestedToggleEvent } from "rl-common/components/grid/datasource/nesting/grid-nested.models";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { QueryFactory } from "rl-common/factories";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { IRelSearchDoc } from "rl-common/models/i-rel-search-doc";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { forkJoin, of, Subscription } from "rxjs";
import { pairwise, startWith, switchMap, tap } from "rxjs/operators";
import { GridDataSourceBuilder } from "../../grid/datasource/builders/grid-datasource-builder";
import { GridNestedTemplateDirective } from "../../grid/directives/grid-nested-template.directive";
import { JobProgressComponent } from "../../modals/job-progress-modal/job-progress/job-progress.component";
import { PanelContentDirective } from "../../panel-switcher/panel-content.directive";
import { PanelSwitcherComponent } from "../../panel-switcher/panel-switcher.component";
import { PanelComponent } from "../../panel-switcher/panel/panel.component";
import { BulkConfig, BulkCreateType, BulkGridStep, BulkNestedStrategy } from "../bulk-config.strategy";
import { BulkCreateAmountRequest, INewEntityTemplateCharData } from "../bulk-grid.models";
import { BulkService } from "../bulk.service";
import { CharTypeId } from "./../../../rl-common.consts";
import { OneConfigService } from "./../../../services/one-config/one-config.service";
import { SessionService } from "./../../../services/session.service";
import { IDUtil } from "./../../../utils/id.util";
import { BulkCreateAmountsDataSource } from "./../../grid/datasource/bulk/bulk-create/bulk-create-amounts-datasource";
import { BulkCreateNestedDataSource } from "./../../grid/datasource/bulk/bulk-create/bulk-create-nested-datasource";
import { BulkCreateAmountsFormComponent } from "./bulk-create-amounts-form/bulk-create-amounts-form.component";
import { DefaultBulkCreateAmountsFormData, IBulkCreateAmountsFormData } from "./bulk-create-amounts-form/bulk-create-amounts-form.models";
import { AmountSplitType, BulkCreateAmountsRequest } from "./bulk-create-amounts.models";
import { BulkNestedAmountGridComponent } from "./bulk-nested-amount-grid/bulk-nested-amount-grid.component";

@Component({
	selector: "rl-bulk-create-amounts",
	templateUrl: "./bulk-create-amounts.component.html",
	styleUrls: ["./bulk-create-amounts.component.scss"],
	imports: [NgIf, PanelSwitcherComponent, PanelComponent, PanelContentDirective, BulkCreateAmountsFormComponent, GridTableComponent, GridNestedTemplateDirective, BulkNestedAmountGridComponent, JobProgressComponent]
})
export class BulkCreateAmountsComponent implements OnInit, OnDestroy {
	@Input()
	templateId: number;

	@Input()
	recordIds: number[];

	@Input()
	charTypeId: CharTypeId;

	@Output()
	onComplete = new EventEmitter<boolean>();

	@ViewChild("tableGrid")
	tableGrid: GridTableComponent;

	amountCharTypeId: CharTypeId = CharTypeId.Amount;
	dataSource: BulkCreateNestedDataSource<INewEntityTemplateCharData>;
	tableGridOptions: GridOptions<IRelSearchDoc> = {
		defaultGetCellDataFn: d => d
	};
	amountPreviewDataSource;
	amountTypeOptions: any[] = [];

	readonly subs: Subscription[] = [];
	jobId: string;
	step = BulkGridStep.BulkEdit;
	areErrorsVisible = false;
	validGrids: { [rowPath: string]: boolean } = {};
	bulkConfig: BulkConfig;

	constructor(
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _bulkService: BulkService,
		private readonly _oneConfigService: OneConfigService,
		@Optional() private readonly _modDetailService: ModDetailService,
		private readonly _sessionService: SessionService,
		private readonly cd: ChangeDetectorRef,
	) { }

	ngOnInit() {
		this.bulkConfig = this.buildBulkConfig();
		this.dataSource = this._gridDataSourceBuilder.bulkCreateNestedDataSource(this.bulkConfig);

		const sub = this.dataSource.fetchRows().pipe(
			switchMap(() => this.openTables()),
			tap((toggleEvents: RowNestedToggleEvent[]) => {
				toggleEvents.forEach(toggleEvent => this.update(toggleEvent));
			})
		).subscribe();

		const sub2 = this.dataSource.formData$
			.pipe(
				startWith([null]),
				pairwise(),
				switchMap((pair) => {
					const previousFormData = pair[0] as IBulkCreateAmountsFormData;
					const currentFormData = pair[1] as IBulkCreateAmountsFormData;
					return this.dataSource.nestedStrategy.nestedDataSourceMap$.pipe(
						switchMap(nestedDataSourceMap => {
							const observables = Array.from(nestedDataSourceMap.entries())
								.map(kvp => {
									const nestedDs = (kvp[1] as unknown) as BulkCreateAmountsDataSource<INewEntityTemplateCharData>;
									const columnStrategy = (nestedDs.columnStrategy as unknown) as BulkCreateAmountsColumnStrategy;
									const newTemplateId = currentFormData.templateId;
									columnStrategy.setTemplate(newTemplateId);
									const shouldResetPaging = previousFormData?.templateId !== newTemplateId || previousFormData?.numRows !== currentFormData.numRows
										|| previousFormData?.perCatalogItem !== currentFormData.perCatalogItem;
									if (shouldResetPaging) {
										nestedDs.setPaging({ rowOffset: 0 });
									}
									return columnStrategy.fetchColumns().pipe(
										switchMap(() => nestedDs.fetchRows()),
										tap(() => {
											this.updateNestedDataSource(nestedDs);
										})
									);
								});
							this.cd.detectChanges();
							return forkJoin(observables);
						})
					);
				}))
			.subscribe();

		this.subs.push(sub, sub2);
	}

	private buildBulkConfig() {
		const templateId = this.templateId;
		const query = QueryFactory.FilterByRecordsAndGroups(SearchFieldNames.Entity.templateID, templateId, this.recordIds);
		const bulkStrategy = new BulkNestedStrategy()
			.withCharTypeId(CharTypeId.Amount);
		const rows = DefaultBulkCreateAmountsFormData.numRows;
		const config = new BulkConfig(this.charTypeId)
			.withBulkStrategy(bulkStrategy)
			.withTemplate(this.templateId)
			.withParent(this._modDetailService.charTypeId, this._modDetailService.recordId)
			.setSearchModel({
				query: query,
				rows: rows,
			});
		return config;
	}

	updateFormData(formData: IBulkCreateAmountsFormData) {
		const oldTemplateId = this.dataSource?.formData$?.value.templateId;
		if (oldTemplateId !== formData.templateId) {
			this.validGrids = {};
		}

		this.dataSource.setFormData(formData);

		this.cd.detectChanges();
	}

	update(event: RowNestedToggleEvent) {
		const nestedDs = event.nestedDataSource as BulkCreateAmountsDataSource<INewEntityTemplateCharData>;
		this.updateNestedDataSource(nestedDs);
	}

	private setRequiredColumnDefaults(characteristicMetaDatas: ICharacteristicMetaData[], dataChangeStrategy) {
		const singleRequiredLovs = characteristicMetaDatas
			.filter(cmd => cmd.charValueSourceID && cmd.requiredIndicator === 1 && cmd.multipleIndicator === 0);

		dataChangeStrategy.setDefaultLovColumnEdits(singleRequiredLovs);
	}

	private openTables() {
		return this.dataSource.indexedRowData$
			.pipe(
				switchMap(rowData => {
					if (!rowData || rowData.length === 0) {
						return of();
					}
					const observables = rowData.map(rd => {
						const gridNestedStrategy = this.dataSource.nestedStrategy;
						return gridNestedStrategy.toggleOpen(rd.rowPath, rd.data, true);
					});
					return forkJoin(observables);
				})
			);
	}

	private updateNestedDataSource(nestedDataSource: BulkCreateAmountsDataSource<INewEntityTemplateCharData>) {

		const dataChangeStrategy = nestedDataSource.dataChangeStrategy;
		const amountTemplate = this._oneConfigService.getTemplateMetaData(CharTypeId.Amount, this.dataSource.formData$.value.templateId);
		this.setRequiredColumnDefaults(amountTemplate.characteristicMetaDatas, dataChangeStrategy);
	}

	get isInvalid() {
		const isFormInvalid = !this.dataSource?.formData$?.value.valid;
		if (isFormInvalid || !this.dataSource) {
			return true;
		}

		const nestedDatasources = Array.from(this.dataSource.nestedStrategy.nestedDataSourceMap$.value.values());

		return nestedDatasources.some(ds => ds.formGroup?.invalid);
	}

	bulkCreate() {
		this.areErrorsVisible = true;

		const fd = this.dataSource.formData$.value;
		if (!fd.valid) {
			return;
		}
		const templateId = fd.templateId;

		const bulkCreateRequests = flatMap(Array.from(this.dataSource.nestedStrategy.nestedDataSourceMap$.value.entries()), (kvp) => {
			const ds = (kvp[1] as IGridDataSource<INewEntityTemplateCharData>).dataChangeStrategy as EntityCharDataDataChangeStrategy<INewEntityTemplateCharData>;
			const cmds = this._oneConfigService.getTemplateCmds(CharTypeId.Amount, templateId);
			const matchingTable = this.dataSource.indexedRowData$.value.find(rd => rd.rowPath === kvp[0]);
			const assocs = this.dataSource.extraGridColumnResults$.value.assoc_entity[matchingTable.data.recordID] ?? {};
			const perCatalogRecordIds = fd.perCatalogItem && assocs[CharTypeId.Property] ? assocs[CharTypeId.Property].map(assoc => assoc.recordId) : [];
			const relRecId = matchingTable.data.relRecId;
			const parentCharTypeId = matchingTable.data.isRel ? CharTypeId.Relationship : this.bulkConfig.charTypeId;
			const parentRecordId = relRecId;
			const req: BulkCreateAmountRequest = {
				bulkCreateType: BulkCreateType.Amount,
				charTypeId: CharTypeId.Amount,
				templateId: templateId,
				parentEntityId: IDUtil.toID(this._sessionService.divId, parentCharTypeId, parentRecordId),
				numRows: fd.numRows,
				package: ds.makeCharDataBulkEditPackage(cmds),
				perCatalogRecordIds
			};
			return req;
		});
		let unevenValues = [];
		switch (fd.splitType) {
			case AmountSplitType.UnevenlyByAmount:
				unevenValues = fd.unevenAmount;
				break;
			case AmountSplitType.UnevenlyByPercentage:
				unevenValues = fd.unevenPercentage;
				break;
		}
		const total = sum(bulkCreateRequests.map(r => r.perCatalogRecordIds.length === 0 ? r.numRows : r.numRows * r.perCatalogRecordIds.length));
		const req: BulkCreateAmountsRequest = {
			total,
			templateId: templateId,
			amountColumnEdit: {
				splitType: fd.splitType,
				unevenValues: unevenValues.reduce((acc, curr, i) => {
					if ((curr && +curr) || +curr === 0) {
						acc[i] = curr;
					}
					return acc;
				}, {}),
			},
			dueDateColumnEdit: {
				splitType: fd.dueDateType,
				recurrenceType: fd.recurrence,
				unevenValues: fd.unevenDate.reduce((acc, curr, i) => {
					if (curr) {
						acc[i] = curr;
					}
					return acc;
				}, {}),
				recurrenceDate: fd.recurrenceDate
			},
			copyFromTables: fd.copyFromTables,
			bulkCreateRequests,
			perCatalogItem: fd.perCatalogItem
		};
		const sub = this._bulkService.bulkCreateNestedAmounts(req)
			.subscribe(jobId => {
				this.jobId = jobId;
				this.step = BulkGridStep.InProgress;
			});
		this.subs.push(sub);
	}

	complete() {
		if (this._modDetailService) {
			this._modDetailService.refreshParentCharDataDeprecated(this._modDetailService.entityData.entityId).subscribe();
		}
		this.onComplete.next(true);
	}

	cancel() {
		this.onComplete.next(false);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => {
			sub.unsubscribe();
		});
	}
}
