<div class="modal-header">
	<h2 class="modal-title">Calculate Allocation</h2>
</div>
<div class="modal-body">
	<div class="mb-2">
		<div class="d-flex align-items-center">
			<input class="form-check-input" type="radio" id="durationOfRightsGrant" [(ngModel)]="allocationType"
				[value]="0" data-cy="durationOfRightsGrant">
			<label class="form-check-label" for="durationOfRightsGrant">
				Duration of Rights Grant &nbsp; <span class="text-muted"><em>(Note: Catalog items with Rights in
						perpetuity cannot be allocated by this method.)</em></span>
			</label>
		</div>
	</div>

	<div class="mb-2">
		<div class="d-flex align-items-center">
			<input class="form-check-input" type="radio" id="evenlyByCatalogItem" [(ngModel)]="allocationType"
				[value]="1" data-cy="evenlyByCatalogItem">
			<label class="form-check-label" for="evenlyByCatalogItem">
				Evenly by Catalog Item
			</label>
		</div>
	</div>

	<h5 class="mt-4">Select Catalog templates for Allocation:</h5>

	<div class="d-inline-flex align-items-center mb-2">
		<label class="form-check-label text-nowrap">
			<input class="form-check-input" type="checkbox" [checked]="isEveryTemplateSelected"
				(change)="selectAllTemplates()" data-cy="selectAllTemplates">
			Select All
		</label>
	</div>
	<div *ngFor="let template of usedTemplates">
		<div class="d-inline-flex align-items-center mb-2">
			<label class="form-check-label text-nowrap">
				<input class="form-check-input" type="checkbox"
					[checked]="selectedTemplates.indexOf(template.templateID) >= 0"
					(change)="selectTemplate(template.templateID)" [attr.data-cy]="'template_' + template.templateID">
				{{template.templateName}}
			</label>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()" data-cy="closeButton">Close</button>
	<button type="button" class="btn btn-info" (click)="calculate()" [disabled]="selectedTemplates.length == 0"
		data-cy="calculateButton">Calculate</button>
</div>