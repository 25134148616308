import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { ErrorType } from "rl-common/components/error-dialog/error-dialog.models";
import { ErrorDialogService } from "rl-common/components/error-dialog/error-dialog.service";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { GrowlerService } from "./growler.service";
import { Logger } from "./logger.service";
import { ProgressService } from "./progress.service";
import { HttpStatusCode, IUnauthorizedError, UnauthorizedReasonCode } from "./rl-http.models";
import { SessionService } from "./session.service";
import { LocalStorageKeys, StorageUtil } from "./storage.service";
import { TokenService } from "./token.service";



@Injectable({ providedIn: "root" })
export class RLHttpInterceptor implements HttpInterceptor {

	private log = new Logger("HttpService");

	constructor(
		private readonly _tokenService: TokenService,
		private readonly _sessionService: SessionService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService,
		private readonly router: Router,
		private readonly _errorDialogService: ErrorDialogService
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this._tokenService.assertToken()
			.pipe(mergeMap((token) => {
				let request = req.clone();
				if (token != null) {
					const headers = {
						Authorization: `Bearer ${token}`
					};

					const isCypress = StorageUtil.get(LocalStorageKeys.CypressBypassLockout);
					if (isCypress) {
						headers[LocalStorageKeys.CypressBypassLockout] = "true";
					}
					request = request.clone({
						setHeaders: headers
					});
				}
				return next.handle(request)
					.pipe(
						tap(res => {
							if (res instanceof HttpResponse) {
								this._sessionService.setApiVersion(res.headers.get("rl-version"));
							}
						}),
						catchError((err) => {
							this._progressService.clearProgress();

							this.log.error(err);

							let notifyError = null;

							switch (err.status) {
								case HttpStatusCode.ServiceUnavailable:
									this._sessionService.logout();
									localStorage.setItem("maintenance", "true");
									this.router.navigate(["/login"]);
									break;
								case HttpStatusCode.InternalServerError:
									notifyError = "An unexpected error occurred. ";
									break;
								case HttpStatusCode.GatewayTimeout:
									notifyError = "A timeout occurred. ";
									break;
								case HttpStatusCode.BadConfig:
									// TODO: At some point we can provide more guidance when config related problems occur, maybe have a list of common error codes?
									this._growlerService.warning("Config Error").growl(err?.error?.message);
									break;
								case HttpStatusCode.BadRequest:
									if (environment.name === "dev") {
										this._growlerService.error("Bad request").growl(err?.error?.message);
									}
									break;
								case HttpStatusCode.PreConditionFailed:
									if (environment.name === "dev") {
										this._growlerService.warning("Concurrency Error").withAllowDismiss(true).growl(err?.error?.message);
									}
									break;
								case HttpStatusCode.Unauthorized:
									this.handleUnauthorizedResponse(err?.error);
									break;
								case HttpStatusCode.NoBackendService:
									this._errorDialogService.openDialog(ErrorType.BackendDown)?.subscribe();
									break;
							}

							// the izenda token endpoint is expected to fail in most environments that are non production
							if (req.url.endsWith(`/admin-api/report/izenda-token`) && !environment.production) {
								return throwError(err);
							}

							if (environment.name === "dev" && notifyError) {
								this._growlerService.error().growl(notifyError);
							}

							return throwError(err);
						}));
			}));
	}

	handleUnauthorizedResponse(error: IUnauthorizedError) {
		if (error?.reasonCode === UnauthorizedReasonCode.UserInactive) {
			this._sessionService.logout();
			this.router.navigate(["/login"]);
		}
	}

}
