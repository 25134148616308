<div [formGroup]="form">
	<div class="me-2 w-100" ngbDropdown autoClose="outside" placement="bottom-left"
		#notificationRolesDropdown="ngbDropdown">
		<button class="form-select text-start associated-roles-toggle" ngbDropdownToggle role="button"
			data-cy="selectRolesButton">Select Roles
			({{ selectedRoleCount }})</button>
		<div ngbDropdownMenu data-cy="selectRolesMenu">
			<div class="scroll-vert position-relative mini-table-container mx-2">
				<table class="table table-hover mini-table"
					[attr.style]="'width: ' + (associatedRolesToggleWidth - 30) + 'px'">
					<thead>
						<tr>
							<th class="col-id">
								<input type="checkbox" class="form-check-input me-1"
									(click)="selectAllRoles($event.target.checked)" data-cy="selectAllRoles" />
								ID
							</th>
							<th class="col-title associated-roles-col-title"
								[attr.style]="'width: ' + (associatedRolesToggleWidth - 124) + 'px'">ROLE</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let role of allRoles">
							<td class="col-id">
								<input type="checkbox" class="form-check-input me-1"
									[checked]="isRoleIdChecked(role.roleID)"
									(change)="onRoleChange(role, $event.target.checked)"
									[attr.data-cy]="'selectRoleCheckbox' + role.roleName | trimWhitespace" />
								{{ role.roleID }}
							</td>
							<td class="col-title">{{ role.roleName }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>