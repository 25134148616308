import { Component, OnInit } from '@angular/core';
import { SessionService } from "../../../../common/services/session.service";
import { SiteService } from "../../../../common/services/site/site.service";
import { IDatamartRecordCount } from "../../../../common/services/site/site.service.models";
import { NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'rl-datamart-record-count',
    templateUrl: './datamart-record-count.component.html',
    styleUrls: ['./datamart-record-count.component.scss'],
    imports: [NgFor, DatePipe]
})
export class DatamartRecordCountComponent implements OnInit {

	public divId: number;
	counts: IDatamartRecordCount[];

	constructor(private sessionService: SessionService, private siteService: SiteService) {
		this.divId = sessionService.divId;
	}

	ngOnInit() {
		this.getCounts();
	}

	private getCounts() {
		this.siteService.getDatamartRecordCount()
			.subscribe((counts) => {
				this.counts = counts;
			})
	}
}
