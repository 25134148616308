<div class="modal-body">
	<h3>Transform Avails to Selected Rights Template</h3>
	<div class="border my-3 p-3 rounded-panel">
		<div class="scroll-vert px-3">
			<div *ngFor="let group of groupedTemplates | keyvalue">
				<h5 class="text-muted text-uppercase mt-3 mb-1">{{group.key}}</h5>
				<div class="py-1" *ngFor="let template of group.value">
					<input [id]="template.templateID" type="radio" name="template" class="form-check-input"
						[formControl]="selectedTemplateForm.controls.templateId" [value]="template.templateID" />
					<label class="form-check-label ms-2" [for]="template.templateID">{{template.templateName}}</label>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button class="btn btn-light me-3" (click)="cancel()">
		Cancel
	</button>
	<button class="btn btn-info" type="button" (click)="previous()">
		<i class="far fa-chevron-left me-2"></i> Previous
	</button>
	<button class="btn btn-success" type="button" (click)="apply()"
		[disabled]="!selectedTemplateForm.controls.templateId.value">
		Continue <i class="far fa-chevron-right ms-2"></i>
	</button>
</div>