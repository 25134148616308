import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ICategory, IIzendaReport } from "app/+reporting/services/report.models";
import { isEmpty } from "lodash";

@Component({
	selector: "rl-select-izenda-report",
	templateUrl: "./select-izenda-report.component.html",
	styleUrls: ["./select-izenda-report.component.scss"],
	imports: [NgIf, NgFor]
})
export class SelectIzendaReportComponent implements OnInit {

	@Input()
	level = 0;

	@Input()
	selectedReports: Set<string> = new Set<string>();

	@Input()
	unselectableReports: Set<string> = new Set<string>();

	@Input()
	category: ICategory;

	@Output()
	onReportSelected = new EventEmitter<IIzendaReport>();

	isExpanded = false;

	reportCount;
	categoryName: string;
	nameIsEmpty = false;

	constructor() { }

	ngOnInit(): void {
		this.categoryName = this.category.name;
		if (isEmpty(this.categoryName) && this.level === 0) {
			this.categoryName = "Uncategorized Reports";
		}
		this.nameIsEmpty = isEmpty(this.categoryName);
		this.reportCount = this.getReportCount(this.category);
		this.isExpanded = this.nameIsEmpty;
	}

	getReportCount(category: ICategory) {
		let subCatCounts = 0;
		category.subCategories.forEach(cat => {
			subCatCounts += this.getReportCount(cat);
		});
		return category.reports.length + subCatCounts;
	}

	selectReport(report: IIzendaReport) {
		this.onReportSelected.emit(report);
	}

}
