<div class="modal-header">
	<h2 class="modal-title">{{titleText}} Sub-Allocation Model</h2>
	<button type="button" class="btn btn-close" (click)="cancel()" aria-label="Close">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body form" [formGroup]="form">
	<rl-form-table-row [labelText]="'Name'" [required]="true"
		[class.has-error]="form.controls.name.errors && form.controls.name.touched">
		<ng-template rlFormTableControl>
			<rl-text-input [(formControl)]="form.controls.name" [dataLabel]="'modelNameField'"
				(onValueChange)="checkForError()"></rl-text-input>
		</ng-template>
		<ng-template rlFormTableMessage *ngIf="form.controls.name.errors && form.controls.name.touched">
			Name is required.
		</ng-template>
	</rl-form-table-row>
	<div class="panel mt-2 mx-3 mb-3 px-0">
		<div class="row pt-3">
			<div class="col-12 tabset tabset--rl">
				<ul ngbNav #nav="ngbNav" class="nav-tabs">
					<li ngbNavItem="allocation-model-tab">
						<a ngbNavLink data-cy="AllocationModelsTab">
							Dimension Allocation
						</a>
						<ng-template ngbNavContent>
							<p class="mt-3">Click each dimension below to expand and assign allocation percentages.<br>
								At least one dimension type must be completed in order to save.</p>

							@if (dimensions?.length > 0) {
							<div ngbAccordion #accordion="ngbAccordion">
								@for (dimension of dimensions; track dimension.charValueSourceID; let j = $index) {
								<div ngbAccordionItem="{{ dimension.charValueSourceID }}">
									<div ngbAccordionHeader>
										<div
											class="accordion-button panel__title d-flex justify-content-between align-items-center">
											<button ngbAccordionButton class="cursor-pointer">
												{{dimension.charValueSourceLabel}}
											</button>
											<div class="panel__ops text-nowrap">
												@if (topNodePercentages &&
												topNodePercentages[dimension.charValueSourceID] > 0) {
												<span
													[ngClass]="{'text-success' : topNodePercentages[dimension.charValueSourceID] === 100, 'text-danger' : topNodePercentages[dimension.charValueSourceID] !== 100 }">{{topNodePercentages[dimension.charValueSourceID]}}%
													allocated </span>
												} @else {
												<span>Nothing allocated</span>
												}
											</div>
										</div>
									</div>
									<div ngbAccordionCollapse>
										<div ngbAccordionBody>
											<ng-template>
												@if (lovs[dimension.charValueSourceID]) {
												<rl-tree-list-edit-control #cmp
													[lovs]='lovs[dimension.charValueSourceID]'
													[dimensionId]="dimension.charValueSourceID"
													(topNodeAmountChanged)='topNodePercentageChange($event, dimension.charValueSourceID)'
													(percentageAmountChanged)='dimensionValuesChanged($event)'
													[rightRelRecId]="dimension.charValueSourceID" [singleMode]="false"
													[isPercentage]="true"
													[existingDimensionPercentages]="existingDimensionPercentages">
												</rl-tree-list-edit-control>
												<div class="text-end">
													<button (click)="clearAll(dimension.charValueSourceID, cmp)"
														class="btn btn-sm btn-remove text-muted">
														<i class="far fa-times me-2"></i>Clear all
													</button>
												</div>
												}
											</ng-template>
										</div>
									</div>
								</div>
								}
							</div>
							} @else {
							<div class="p-4 text-center">
								<rl-loader></rl-loader>
							</div>
							}

						</ng-template>
					</li>
					<li ngbNavItem="catalog-hierarchy-allocation-tab">
						<a ngbNavLink data-cy="CatalogHierarchyAllocationTab">
							Catalog Hierarchy Allocation
						</a>
						<ng-template ngbNavContent>
							<div class="d-flex px-2 my-4">
								<span class="pe-2">Select the Catalog level which will be primarily allocated
									to:</span>
								<rl-template-selector [charTypeId]="charTypeId" [options]="templateOptions"
									[includeSelect]="selectedTemplateId === -1"
									(onChange)="updateValue($event)"></rl-template-selector>
							</div>
							<section [hidden]="hideHierarchy" class="px-2">
								<p class="mb-2">Select the Catalog level associated with Sub-Allocation Amounts:</p>
								<div *ngIf="!hideHierarchy">
									<div class="container-fluid hierarchy-table">
										<div class="row align-items-center px-1 py-2 mb-1 hierarchy-table-head">
											<div class="col-2">Level</div>
											<div class="col-9">Template</div>
										</div>
										<rl-catalog-hierarchy-allocation *ngFor="let child of catalogTemplates"
											[charTypeId]="charTypeId" [template]="child"
											[parentTemplateId]="selectedTemplateId"
											[selectedSuballocationId]="suballocationTemplateId"
											(onChange)="checkValidity($event)"></rl-catalog-hierarchy-allocation>
									</div>
								</div>
							</section>

						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav"></div>
			</div>
		</div>

	</div>


	@if (needsDimensions && (!catalogHierarchySelectionsValid || catalogHierarchyUntouched)) {
	<p class="text-danger">
		At least one tab must be completed.
	</p>
	}
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success ms-3" [disabled]="isDisabled()" (click)="save()">Save</button>
</div>