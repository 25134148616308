<div class="modal-header">
	<h2 class="modal-title">Update Your Avatar</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="h-100 overflow-auto">
		<div class="d-inline-block m-1" *ngFor="let avatar of avatars">
			<button class="btn btn-link select-button p-0 m-0" [class.selected]="selectedAvatar === avatar"
				(click)="selectAvatar(avatar)">
				<rl-profile-picture [userName]="userName" [avatar]="avatar" size="sm"></rl-profile-picture>
			</button>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success" (click)="saveAvatar()" [disabled]="isSaving">Save</button>
</div>