<ng-container *ngIf="solrDataOption">
	<ng-container [ngSwitch]="solrDataOption.dataType">
		<ng-container *ngSwitchCase="solrDate">
			<div class="mb-2 d-inline-block">
				<rl-text-mode-date-input [(ngModel)]="value" (ngModelChange)="emitDateChange()"></rl-text-mode-date-input>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="solrDateTime">
			<div class="mb-2 d-inline-block">
				<rl-text-mode-date-input [(ngModel)]="value" (ngModelChange)="emitDateTimeChange()"></rl-text-mode-date-input>
			</div>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<ng-template [ngIf]="hasTypeahead">
				<span class="inputTypeAhead">
					<select class="form-select inputTypeAhead-select" autocomplete="off">
						<!-- leave blank - used for styling purposes only -->
					</select>
					<input class="form-control inputTypeAhead-input" type="text" name="single-value-input"
						[(ngModel)]="typeaheadValue" (keydown.backspace)="backspacePressed($event)"
						[placeholder]="solrDataOption.placeholder" [ngbTypeahead]="searchValues"
						(focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)"
						(selectItem)="valueSelected($event)" (keyup.enter)="typeAheadEntered()"
						[inputFormatter]="formatter" [resultFormatter]="formatter" autocomplete="off" #typeahead
						data-cy="ValueInputWithTypeAheadTextBox" />
				</span>
			</ng-template>
			<ng-template [ngIf]="!hasTypeahead">
				<input class="form-control" type="text" [(ngModel)]="value" (ngModelChange)="emitChange()"
					(keydown.enter)="enterKeyPress()" [placeholder]="solrDataOption.placeholder"
					data-cy="ValueInputTextBox" />
			</ng-template>
		</ng-container>
	</ng-container>
</ng-container>