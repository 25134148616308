
export enum WidgetSize {
	One = 1,
	Two = 2,
	Three = 3
}

export enum Widgets {
	Favorites = 0,
	Notifications = 1,
	RecentActivity = 2,
	SavedSearch = 3,
	DealsByStatus = 4,
	IzendaReport = 5,
	AssignedConflicts = 6,
}

export interface IWidgetOptions {
	id?: string;
	type: Widgets;
	size: WidgetSize;
	params?: any;
}

