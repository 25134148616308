import { Component, OnInit } from "@angular/core";
import { SessionService } from "rl-common/services/session.service";
import { AclUtil } from "rl-common/utils/acl.util";
import { NgFor } from "@angular/common";

export interface IAclRow {
	uri: string;
	hasReadAccess: boolean;
	hasWriteAccess: boolean;
	hasCreateAccess: boolean;
	hasDeleteAccess: boolean;
}

@Component({
    selector: "rl-user-acl-info",
    templateUrl: "./user-acl-info.component.html",
    styleUrls: ["./user-acl-info.component.scss"],
    imports: [NgFor]
})
export class UserAclInfoComponent implements OnInit {

	acls: IAclRow[] = [];

	constructor(
		private readonly _sessionService: SessionService
	) { }

	ngOnInit() {
		this.acls = this._sessionService.acls.map(acl => {
			const parsed = new URL(acl);
			parsed.search = "";
			const targetAcl = parsed.toString();

			const row: IAclRow = {
				uri: acl,
				hasReadAccess: AclUtil.hasReadAccess([acl], targetAcl),
				hasWriteAccess: AclUtil.hasWriteAccess([acl], targetAcl),
				hasCreateAccess: AclUtil.hasCreateAccess([acl], targetAcl),
				hasDeleteAccess: AclUtil.hasDeleteAccess([acl], targetAcl)
			};
			return row;
		});

		this.acls.sort((a, b) => a.uri.localeCompare(b.uri));
	}

}
