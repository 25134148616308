import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { every, isEmpty, some } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { IQueryNode } from "rl-common/models/i-query-node";
import { ISavedSearch } from "rl-common/services/advanced-search/advanced-search.models";
import { SessionService } from "rl-common/services/session.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Acl } from "../../../rl-common-acl.consts";
import { AclUtil } from "../../../utils/acl.util";
import { AdvancedSearchBuilderComponent } from "../advanced-search-builder/advanced-search-builder.component";
import { IAdvancedSearchForm, IApplySearchEvent } from "./advanced-search-models";


@Component({
	selector: "rl-advanced-search-modal",
	templateUrl: "./advanced-search-modal.component.html",
	styleUrls: ["./advanced-search-modal.component.scss"],
	imports: [ReactiveFormsModule, AdvancedSearchBuilderComponent, NgIf]
})
export class AdvancedSearchModalComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	parentCharTypeId: CharTypeId;

	@Input()
	parentTemplateId: number;

	@Input()
	savedSearch: ISavedSearch;

	@Output()
	onApplySearch = new EventEmitter<IApplySearchEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	form: FormGroup<IAdvancedSearchForm>;

	private readonly _subscriptions: Subscription[] = [];
	createGlobalPermissions = false;
	editGlobalPermissions = false;
	deleteGlobalPermissions = false;

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _sessionService: SessionService
	) { }

	ngOnInit() {
		this.createGlobalPermissions = AclUtil.hasReadAccess(this._sessionService.acls, Acl.Activities.ActivityAdminAcls.SharedSearchesAcls.Create.toString());
		this.editGlobalPermissions = AclUtil.hasReadAccess(this._sessionService.acls, Acl.Activities.ActivityAdminAcls.SharedSearchesAcls.Edit.toString());
		this.deleteGlobalPermissions = AclUtil.hasReadAccess(this._sessionService.acls, Acl.Activities.ActivityAdminAcls.SharedSearchesAcls.Delete.toString());

		const query = this.savedSearch?.query;
		const queryNodes = query?.$and || [{}];
		const queryNodeControls = queryNodes.map(q => new FormControl<IQueryNode>(q));

		this.form = this._formBuilder.group<IAdvancedSearchForm>({
			searchName: new FormControl<string>(this.savedSearch?.name),
			saveAsName: new FormControl<string>(null),
			displayOnDashboard: new FormControl<boolean>(this.savedSearch?.displayOnDashboard),
			query: new FormControl<IQueryNode>(query, [this.QueryIsValid]),
			queryNodeRows: new FormArray<FormControl<IQueryNode>>(queryNodeControls),
			global: new FormControl<boolean>(this.editGlobalPermissions ? this.savedSearch?.global : false)
		});

		const saveAsChangesSub = this.form.controls.saveAsName.valueChanges.pipe(
			filter(() => !!this.savedSearch?.id)
		).subscribe(() => {
			const saveAsName = this.form.controls.saveAsName.value;
			const enabled = isEmpty(saveAsName);
			this.toggleControl(this.form.controls.searchName, enabled);
		});

		const searchNameChangesSub = this.form.controls.searchName.valueChanges.pipe(
			filter(() => !!this.savedSearch?.id)
		).subscribe(() => {
			const searchName = this.form.controls.searchName.value;
			const enabled = searchName === this.savedSearch.name;
			this.toggleControl(this.form.controls.saveAsName, enabled);
		});

		this._subscriptions.push(saveAsChangesSub, searchNameChangesSub);
	}

	private toggleControl(formControl: AbstractControl, enabled: boolean) {
		if (formControl.enabled === enabled) {
			return;
		}
		if (enabled) {
			formControl.enable();
		} else {
			formControl.disable();
		}
	}

	private QueryIsValid(control: AbstractControl) {
		const query: IQueryNode = control.value;
		const validQuery = query && some(query.$and) && every(query.$and, query => !!query);
		return validQuery ? null : { validQuery };
	}

	applySearch() {
		const searchName = this.form.controls.searchName.value;
		const saveToNewQueryName = this.form.controls.saveAsName.value;
		const saveQuery = !isEmpty(searchName);
		const saveAsNewQuery = !isEmpty(saveToNewQueryName);
		const displayOnDashboard = this.form.controls.displayOnDashboard.value;
		const query = this.form.controls.query.value;
		const global = this.form.controls.global.value;
		const newSearch = {
			divId: this._sessionService.divId,
			charTypeId: this.charTypeId,
			name: searchName,
			displayOnDashboard: displayOnDashboard,
			query,
			global: global
		};
		const merged = { ...this.savedSearch, ...newSearch };
		if (saveAsNewQuery) {
			merged.id = null;
			merged.name = saveToNewQueryName;
		}
		const event: IApplySearchEvent = {
			saveQuery: saveQuery,
			saveAsNewQuery: saveAsNewQuery,
			savedSearch: merged
		};
		this.onApplySearch.emit(event);
	}

	cancel() {
		this.onCancel.emit();
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
