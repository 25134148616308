import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "visibilityIndicatorName" })
export class VisibilityIndicatorNamePipe implements PipeTransform {

	transform(value: number): string {
		if (value === undefined || value === null || Number.isNaN(value) || typeof value !== "number") {
			return "";
		}
		switch (value) {
			case 0:
				return "Hidden";
			case 1:
				return "Internal Only";
			case 2:
				return "Both Internal & External";
		}
		return "";
	}

}
