import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { chain, cloneDeep, Dictionary, first, isEmpty, isEqual, snakeCase } from "lodash";
import { BulkType } from "rl-common/components/bulk-grid/bulk-config.strategy";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridUtil } from "rl-common/components/grid/grid.util";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { CharDataType, CharTypeId, SystemIndicators } from "rl-common/consts";
import { ICharDataExtDataDateMathRel } from "rl-common/models/i-char-data-ext-data-date-math-rel";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntity } from "rl-common/models/i-entity";
import { BulkGridService } from "rl-common/services/bulk-grid/bulk-grid.service";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { CharDataTableServiceScope } from "rl-common/services/char-data-table.service.models";
import { DateMathEntitiesSearchRequest, DateMathEntitiesSearchResponse, DateMathSearchAssocEntityDataResults, DateMathSearchDetailsAssocResult, DateMathSearchDetailsDateCharsResult, DateMathSearchRow, DateMathSearchSetupTableResults, DateMathSearchStep, DateMathSearchTableSelectEvent } from "rl-common/services/datemath/datemath.models";
import { DateMathService } from "rl-common/services/datemath/datemath.service";
import { IEntityRecTemplateCharData } from "rl-common/services/entity/models/i-entity-rec-template-char-data";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { GridColumnTypes } from "rl-common/services/grid-view/models/grid-column-types";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { IGridViewColumn } from "rl-common/services/grid-view/models/i-grid-view-column";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { VariesByCatalogService } from "rl-common/services/varies-by-catalog.service";
import { VariesByDealService } from "rl-common/services/varies-by-deal.service";
import { IDUtil } from "rl-common/utils";
import { Observable, of, Subscription } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { CellTemplateDirective } from "../../grid/directives/cell-template.directive";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { BLANK_DATE, RelativeDateType } from "../date-edit.models";
import { DateUtil } from "./../../../utils/date.util";

interface DateCellData {
	entityId: string;
	charId: number;
	recCharId?: number;
	dateValue?: string;
	title: string;
}

type DateMathRowTypes = DateMathSearchRow | IEntityRecTemplateCharData;

enum SearchTypeRadio {
	Associated = "assoc",
	All = "all",
	RightsTermStart = "term-start"
}

@Component({
	selector: "rl-datemath-search-table",
	templateUrl: "./datemath-search-table.component.html",
	styleUrls: ["./datemath-search-table.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ReactiveFormsModule, FormsModule, NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, LoaderComponent, GridTableComponent, CellTemplateDirective, AsyncPipe]
})
export class DatemathSearchTableComponent implements OnInit, OnDestroy {
	@Input()
	contextEntityId: string;

	@Input()
	contextCharId: number;

	@Input()
	relationship: ICharDataExtDataDateMathRel;

	@Input()
	isRelative = false;

	@Input()
	rrpOptionSelected: number = null;

	@Input()
	rrpParentEntityId?: string = "";

	@Input()
	relationships: ICharDataExtDataDateMathRel[];

	@Input()
	cmd: ICharacteristicMetaData;

	@Output()
	dateSelect = new EventEmitter<DateMathSearchTableSelectEvent>();

	SearchStep = DateMathSearchStep;

	allOrAssocControl: UntypedFormControl;
	charTypeIdControl: UntypedFormControl;
	templateIdControl: UntypedFormControl;
	keywordsControl: UntypedFormControl;
	shouldShowTermStart = false;

	step1Columns: GridColumn<DateMathSearchRow>[] =
		[
			{
				key: "title",
				renderer: "step1_title",
				headerName: "",
				width: "auto",
				getCellData: (dataRow) => dataRow
			}
		];

	step2Columns: GridColumn<DateMathSearchRow>[] =
		[
			{
				key: "title",
				renderer: "step2_title",
				headerName: "",
				width: "auto",
				getCellData: (dataRow) => dataRow
			}
		];

	step3Columns: GridColumn<DateMathSearchRow>[] =
		[
			{
				key: "title",
				renderer: "step3_title",
				headerName: "",
				width: "auto",
				getCellData: (dataRow) => {
					const dr = cloneDeep(dataRow);
					const split = dr.title.split(" | ");
					const label = split[0];
					const storageDate = split[1];
					if (!storageDate || storageDate === "[no date value set]") {
						return dataRow;
					}
					dr.title = `${label} | ${DateUtil.formatStorageDateAsBrowserDateOrLabel(storageDate)}`;
					return dr;
				}
			}
		];

	step4Columns: GridColumn<DateMathSearchRow>[] =
		[
			{
				key: "title",
				renderer: "step4_title",
				headerName: "",
				width: "max-content",
				getCellData: (dataRow) => dataRow
			}
		];

	step9Columns: GridColumn<DateMathSearchRow>[] =
		[
			{
				key: "title",
				renderer: "step9_title",
				headerName: "",
				width: "auto",
				getCellData: (dataRow) => dataRow
			}
		];

	step12Columns: GridColumn<DateMathSearchRow>[] =
		[
			{
				key: "title",
				renderer: "step12_title",
				headerName: "",
				width: "auto",
				getCellData: (dataRow) => dataRow
			}
		];

	gridOptions: GridOptions = {

	};

	BLANK_DATE = BLANK_DATE;

	private readonly _subs: Subscription[] = [];

	step: DateMathSearchStep = DateMathSearchStep.Entity;
	stepContextRow: DateMathSearchRow;
	searchForStep: DateMathSearchStep = DateMathSearchStep.Entity;

	path: DateMathSearchRow[] = [];

	charTypesList: [number, string][] = [];
	templatesList: [number, string][] = [];

	dataSource: CommonGridDataSource<DateMathRowTypes>;

	private parentEntityId: string;
	rrpTag = "";

	private _emptySearchResults: DateMathEntitiesSearchResponse = {
		rows: [],
		templates: [],
		numFound: 0
	};

	get isAllSelected() {
		return this.allOrAssocControl.value === SearchTypeRadio.All;
	}

	get showKeywords() {
		return (this.step === DateMathSearchStep.Entity || this.step === DateMathSearchStep.AssocList || this.step === DateMathSearchStep.AmountsList) && this.isAllSelected;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private dateMathService: DateMathService,
		private gridDataSourceBuilder: GridDataSourceBuilder,
		@Optional() private readonly _modDetailService: ModDetailService,
		private variesByCatalogService: VariesByCatalogService,
		private variesByDealService: VariesByDealService,
		private readonly charDataTableService: CharDataTableService,
		private readonly _oneConfigService: OneConfigService,
		private readonly parentEntityService: ParentEntityService,
		private readonly sessionService: SessionService,
		@Optional() private readonly _bulkGridService: BulkGridService
	) {
		this.dataSource = this.gridDataSourceBuilder.commonGridDataSource<DateMathRowTypes>(row => null)
			.setPaging({ pageSize: 25 })
			.withFetchFn((ds) => {
				let obs: Observable<IGridFetchResults<DateMathRowTypes>>;
				switch (this.searchForStep) {
					case DateMathSearchStep.SearchSetupTable:
						obs = this.searchSetupTable();
						break;
					case DateMathSearchStep.Entity:
						obs = this.searchEntities();
						break;
					case DateMathSearchStep.EntityDetail:
						obs = this.searchDetailAssocTypes();
						break;
					case DateMathSearchStep.DetailCharDates:
						obs = this.searchDetailDateChars();
						break;
					case DateMathSearchStep.AssocTemplates:
						obs = this.searchAssocTypeTemplates();
						break;
					case DateMathSearchStep.AssocList:
						obs = this.searchAssocEntityData();
						break;
					case DateMathSearchStep.AmountsList:
						obs = this.searchAssocAmountsData();
						break;
					case DateMathSearchStep.RelativeToCatalog:
						obs = this.searchRelativeToCatalog();
						break;
					case DateMathSearchStep.RelativeToRights:
						obs = this.searchModuleRoot();
						break;
					case DateMathSearchStep.RelativeToTableRow:
						obs = this.searchTableTriggerParentEntity();
						break;
					case DateMathSearchStep.RelativeToDeal:
						obs = this.searchRelativeToDeal();
						break;
					default:
						obs = of(null);
				}
				return obs
					.pipe(
						tap(() => {
							this.step = this.searchForStep;
						})
					);
			});

		this.allOrAssocControl = this.fb.control(SearchTypeRadio.Associated);
		this.keywordsControl = this.fb.control("", { updateOn: "change" });
		this.charTypeIdControl = this.fb.control(this._modDetailService?.charTypeId ?? 1);
		this.templateIdControl = this.fb.control(null);

		const sub = this.allOrAssocControl.valueChanges
			.subscribe((val) => {
				if (val === SearchTypeRadio.RightsTermStart) {
					this.autoSelectTermStart();
				} else {
					this.dataSource.setPaging({ rowOffset: 0 });
					this.refreshSearch(this.step);
				}
			});

		const sub2 = this.charTypeIdControl.valueChanges
			.subscribe(() => {
				this.dataSource.setPaging({ rowOffset: 0 });
				// reset template when chartypeid changed
				this.templateIdControl.patchValue("", { emitEvent: false });
				this.refreshSearch(this.step);
			});

		const sub3 = this.templateIdControl.valueChanges
			.subscribe(() => {
				this.dataSource.setPaging({ rowOffset: 0 });
				this.refreshSearch(this.step);
			});

		const sub4 = this.keywordsControl.valueChanges.pipe(
			debounceTime(500)
		).subscribe(() => {
			this.dataSource.setPaging({ rowOffset: 0 });
			this.refreshSearch(this.step);
		});
		if (this._modDetailService) {
			const sub5 = this._modDetailService.entityIdString$.subscribe(entityId => {
				this.parentEntityId = entityId;
			});
			this._subs.push(sub5);
		}
		this._subs.push(sub, sub2, sub3, sub4);
	}

	ngOnInit() {
		this.getCharTypes();
		const parsed = IDUtil.splitEntityID(this.contextEntityId);
		this.shouldShowTermStart = parsed?.charTypeID === CharTypeId.Right && this.cmd?.systemIndicator === SystemIndicators.RightsEndDate;
		if (this.relationship) {
			this.refreshSearch(DateMathSearchStep.SearchSetupTable);
		} else {
			if (this.rrpOptionSelected) {
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToCatalog) {
					this.refreshSearch(DateMathSearchStep.RelativeToCatalog);
				}
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToRights) {
					this.refreshSearch(DateMathSearchStep.RelativeToRights);
				}
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToTableRow) {
					this.refreshSearch(DateMathSearchStep.RelativeToTableRow);
				}
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToDeal) {
					this.refreshSearch(DateMathSearchStep.RelativeToDeal);
				}
			} else {
				this.refreshSearch(DateMathSearchStep.Entity);
			}
		}
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.rrpOptionSelected) {
			if (this.rrpOptionSelected === RelativeDateType.DateRelativeToCatalog) {
				this.rrpTag = "Date Relative to Catalog";
				this.step = DateMathSearchStep.RelativeToCatalog;
				this.dataSource.setColumns(this.step9Columns);
				if (this.variesByCatalogService.commonCmds.length < 1) {
					this.dateMathService.getCommonDateChars(CharTypeId.Property).subscribe((results) => {
						this.variesByCatalogService.commonCmds = this.variesByCatalogService.commonCmds.concat(results.commonCmds);
						this.refreshSearch(this.step);
					});
				} else {
					this.refreshSearch(this.step);
				}
			} else if (this.rrpOptionSelected === RelativeDateType.DateRelativeToRights) {
				this.step = DateMathSearchStep.RelativeToRights;
				this.refreshSearch(this.step);
				this.rrpTag = "Date Relative to Rights";
			} else if (
				this.rrpOptionSelected === RelativeDateType.DateRelativeToTableRow) {
				this.step = DateMathSearchStep.RelativeToTableRow;
				this.refreshSearch(this.step);
				this.rrpTag = "Date Relative to Table Row";
			} else if (this.rrpOptionSelected === RelativeDateType.DateRelativeToDeal) {
				this.rrpTag = "Date Relative to Deal";
				this.step = DateMathSearchStep.RelativeToDeal;
				this.dataSource.setColumns(this.step12Columns);
				if (this.variesByDealService.commonCmds.length < 1) {
					this.dateMathService.getCommonDateChars(CharTypeId.Transaction).subscribe((results) => {
						this.variesByDealService.commonCmds = this.variesByDealService.commonCmds.concat(results.commonCmds);
						this.refreshSearch(this.step);
					});
				} else {
					this.refreshSearch(this.step);
				}
			}
		}
	}

	getCharTypes() {
		const allowedTypes = new Set<number>([CharTypeId.Transaction, CharTypeId.Project, CharTypeId.Inventory, CharTypeId.Property, CharTypeId.Job, CharTypeId.Invoice]);
		this.charTypesList = this._oneConfigService.getCharTypes()
			.filter(ct => allowedTypes.has(ct.charTypeID))
			.map(ct => [ct.charTypeID, ct.charTypeName]);
	}

	submitSearch() {
		this.dataSource.setPaging({ rowOffset: 0 });
		this.refreshSearch(this.step);
	}

	refreshSearch(step: DateMathSearchStep) {
		this.searchForStep = step;
		const sub = this.dataSource.fetchRows().subscribe();
		this._subs.push(sub);
	}


	searchModuleRoot() {
		let relativeToRightsNodes: DateMathRowTypes[] = [];
		return this.dateMathService.getModuleParent(this.rrpParentEntityId).pipe(
			tap((results) => {
				this.dataSource.setColumns(this.step1Columns);
				if (results.templates) {
					this.templatesList = results.templates.map(kvp => [kvp.key, kvp.value]);
				} else {
					this.templatesList = [];
				}
				if (results.rows.length > 0) {
					relativeToRightsNodes = relativeToRightsNodes.concat(results.rows);
				}

				if (this.variesByCatalogService.isVaried) {
					const variedChar = this.variesByCatalogService.variedFieldsCmd.find(x => x.characteristicID === this.contextCharId);
					if (variedChar) {
						relativeToRightsNodes.push({ title: "Relative to Rights", step: DateMathSearchStep.RelativeToRights });
					}
				}
			}),
			switchMap(() => this.searchEntities()),
			tap((results) => {
				relativeToRightsNodes = relativeToRightsNodes.concat(results.rowData);
			}),
			map(() => ({ rowData: relativeToRightsNodes, rowCount: relativeToRightsNodes.length } as IGridFetchResults<DateMathRowTypes>)
			));
	}

	searchTableTriggerParentEntity() {
		return this.dateMathService.getTableTriggerParentEntity().pipe(
			tap((results) => {
				this.dataSource.setColumns(this.step1Columns);
				if (results.templates) {
					this.templatesList = results.templates.map(kvp => [kvp.key, kvp.value]);
				} else {
					this.templatesList = [];
				}

				if (this.variesByCatalogService.isVaried) {
					const variedChar = this.variesByCatalogService.variedFieldsCmd.find(x => x.characteristicID === this.contextCharId);
					if (variedChar) {
						results.rows.push({ title: "Relative to Table Row", step: DateMathSearchStep.RelativeToTableRow });
					}
				}
			}),
			map(results => ({ rowData: results.rows, rowCount: results.numFound } as IGridFetchResults<DateMathRowTypes>))
		);
	}

	searchEntities() {
		const onlyAssociated = this.allOrAssocControl.value === "assoc";
		const parentEntityId = this.isRelative ? this.parentEntityService.entityId : this.parentEntityId;
		const request: DateMathEntitiesSearchRequest = {
			contextEntityId: this.contextEntityId,
			parentEntityId,
			onlyAssociated
		};

		if (!onlyAssociated) {
			request.start = this.dataSource.rowOffset$.value;
			request.rows = this.dataSource.pageSize$.value;
			request.keywords = this.keywordsControl.value;
			request.charTypeId = parseInt(this.charTypeIdControl.value);
			request.templateId = parseInt(this.templateIdControl.value);
		}

		let entitySearch$: Observable<DateMathEntitiesSearchResponse> = of(this._emptySearchResults);
		if (this.parentEntityId || !onlyAssociated || !this._modDetailService) {
			entitySearch$ = this.dateMathService.searchEntities(request);
		}

		return entitySearch$.pipe(
			map(searchResults => {
				const localSearchRows = this.localSearchEntitiesRows(this.contextEntityId);
				const found = searchResults.rows.find(x => isEqual(x, first(localSearchRows)));
				const isBulkEdit = this._bulkGridService?.config?.bulkStrategy?.bulkType === BulkType.Edit;
				if (onlyAssociated && !isBulkEdit && this.isLocalEntity(this.contextEntityId) && !found) {
					searchResults.rows = searchResults.rows.concat(localSearchRows);
					searchResults.numFound += localSearchRows.length;
				}
				return searchResults;
			}),
			tap((results) => {
				this.dataSource.setColumns(this.step1Columns);
				if (results.templates) {
					this.templatesList = results.templates.map(kvp => [kvp.key, kvp.value]);
				} else {
					this.templatesList = [];
				}

				if (this.variesByCatalogService.isVaried) {
					const variedChar = this.variesByCatalogService.variedFieldsCmd.find(x => x.characteristicID === this.contextCharId);
					if (variedChar) {
						results.rows.push({ title: "Relative to Catalog", step: DateMathSearchStep.RelativeToCatalog });
					}
				}
			}),
			map(results => ({ rowData: results.rows, rowCount: results.numFound } as IGridFetchResults<DateMathRowTypes>))
		);
	}

	private localSearchEntitiesRows(entityId: string) {
		return [{
			step: DateMathSearchStep.EntityDetail,
			title: this.charDataTableService.entityTitle$.value,
			entityId
		}];
	}

	searchDetailAssocTypes() {
		const entityId = this.stepContextRow.entityId;
		let detailSearch$: Observable<DateMathSearchDetailsAssocResult>;
		if (this.isLocalEntity(entityId) && !this.isRelative) {
			const result: DateMathSearchDetailsAssocResult = {
				rows: this.localDetailsRows(entityId)
			};
			detailSearch$ = of(result);
		} else {
			detailSearch$ = this.dateMathService.searchDetailsAssoc(entityId);
		}
		return detailSearch$
			.pipe(
				tap(results => {
					this.dataSource.setColumns(this.step2Columns);
				}),
				map(results => ({ rowData: results.rows, rowCount: results.rows.length } as IGridFetchResults<DateMathRowTypes>))
			);
	}

	private localDetailsRows(entityId: string) {
		return [
			{
				step: DateMathSearchStep.DetailCharDates,
				title: "Details",
				entityId
			}
		];
	}

	searchDetailDateChars() {
		const entityId = this.stepContextRow?.entityId;
		let detailsCharDateCharsSearch$: Observable<DateMathSearchDetailsDateCharsResult>;
		if (this.isLocalEntity(entityId)) {
			const result: DateMathSearchDetailsDateCharsResult = {
				rows: this.localDetailDateCharacteristicRows(entityId)
			};
			detailsCharDateCharsSearch$ = of(result);
		} else {
			detailsCharDateCharsSearch$ = this.dateMathService.searchDetailsDateChars(entityId);
		}
		return detailsCharDateCharsSearch$
			.pipe(
				tap(results => {
					this.dataSource.setColumns(this.step3Columns);
				}),
				map(results => ({ rowData: results.rows, rowCount: results.rows.length } as IGridFetchResults<DateMathRowTypes>))
			);
	}

	autoSelectTermStart() {
		const contextEntityId = IDUtil.splitEntityID(this.contextEntityId);
		const char = this._oneConfigService.getCharBySystemIndicator(contextEntityId.charTypeID, SystemIndicators.RightsStartDate);
		this.autoSelectLocalChar(char.characteristicID);
	}

	autoSelectLocalChar(charId: number) {
		const rows = this.localDetailDateCharacteristicRows(this.contextEntityId);
		const matchingRow = rows.find(c => c.charId === charId);
		this.selectDateInner(matchingRow);
	}

	private localDetailDateCharacteristicRows(entityId: string): DateMathSearchRow[] {
		return this.charDataTableService.template.characteristicMetaDatas
			.filter(x => x.dataTypeID === CharDataType.Date)
			.map<DateMathSearchRow>(x => {
				const labels = [x.label];
				if (this.charDataTableService.scope === CharDataTableServiceScope.charDataTable) {
					const cd = first(this.charDataTableService.charDatas[x.characteristicID]);
					const dateValue = cd && cd.value || "";
					const currentDate = `${isEmpty(dateValue) || dateValue === BLANK_DATE ? "[no date value set]" : dateValue}`;
					labels.push(currentDate);
				}
				const title = labels.join(" | ");
				return {
					step: DateMathSearchStep.SelectCharId,
					title,
					charId: x.characteristicID,
					entityId
				};
			});
	}

	searchAssocTypeTemplates() {
		const entityId = this.stepContextRow.entityId;
		const charTypeId = this.stepContextRow.charTypeId;
		return this.dateMathService.searchAssocTypeTemplates(entityId, charTypeId)
			.pipe(
				tap(results => {
					this.dataSource.setColumns(this.step4Columns);
				}),
				map(results => ({ rowData: results.rows, rowCount: results.rows.length } as IGridFetchResults<DateMathRowTypes>))
			);
	}

	searchAssocEntityData() {
		const entityId = this.stepContextRow.entityId;
		const parsedEntityId = IDUtil.splitEntityID(entityId);
		const charTypeId = this.stepContextRow.charTypeId;
		let templateId: number = null;
		if (this.stepContextRow.templateId) {
			templateId = IDUtil.splitTemplateID(this.stepContextRow.templateId).templateID;
		}
		const keywords = this.keywordsControl.value;
		const start = this.dataSource.rowOffset$.value;
		const pageSize = this.dataSource.pageSize$.value;
		const searchDocColumnStrategy = this.gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy(charTypeId, null, templateId);
		return searchDocColumnStrategy.fetchGridViews().pipe(
			map(results => {
				const assocColumns = results.availableColumns.filter(column => {
					const isSameCharType = parsedEntityId.charTypeID === column.charTypeId;
					return column.columnType === GridColumnTypes.AssocEntity && column.columnKey === "name" && !isSameCharType;
				});
				return assocColumns;
			}),
			switchMap(assocColumns => this.dateMathService.searchAssocEntityData(entityId, charTypeId, templateId, assocColumns, keywords, start, pageSize).pipe(
				map(results => ([assocColumns, results] as [IGridViewColumn[], DateMathSearchAssocEntityDataResults]))
			)),
			map(([assocColumns, results]) => this.setupCharDataRows(
				charTypeId,
				results.records,
				results.charDatas,
				results.templates,
				results.numFound,
				results.recordIdsWithAmounts,
				assocColumns,
				results.extraGridColumnResults.assoc_entity))
		);
	}

	searchAssocAmountsData(): Observable<IGridFetchResults<DateMathRowTypes>> {
		const entityId = this.stepContextRow.entityId;
		const charTypeId = this.stepContextRow.charTypeId;
		const keywords = this.keywordsControl.value;
		const start = this.dataSource.rowOffset$.value;
		const pageSize = this.dataSource.pageSize$.value;

		return this.dateMathService.searchAssocAmountsData(entityId, keywords, start, pageSize)
			.pipe(
				map(results => this.setupCharDataRows(
					charTypeId,
					results.records,
					results.charDatas,
					results.templates,
					results.numFound,
					[]))
			);
	}

	searchTableTriggerData(): Observable<IGridFetchResults<DateMathRowTypes>> {
		const entityId = this.stepContextRow.entityId;
		const charTypeId = this.stepContextRow.charTypeId;
		const keywords = this.keywordsControl.value;
		const start = this.dataSource.rowOffset$.value;
		const pageSize = this.dataSource.pageSize$.value;

		return this.dateMathService.searchAssocAmountsData(entityId, keywords, start, pageSize)
			.pipe(
				map(results => this.setupCharDataRows(
					charTypeId,
					results.records,
					results.charDatas,
					results.templates,
					results.numFound,
					[]))
			);
	}

	searchRelativeToCatalog(): Observable<IGridFetchResults<DateMathRowTypes>> {
		const rowData = this.variesByCatalogService.commonCmds.map(x => {
			const rec: DateMathSearchRow = ({
				step: DateMathSearchStep.RelativeToCatalog,
				title: x.label,
				entityId: this.contextEntityId
				// template: null,
				// assocChanges: {},
				// charDatas: []
			});
			return rec;
		});

		return new Observable<IGridFetchResults<DateMathRowTypes>>(subscriber => {
			subscriber.next({
				rowData,
				rowCount: rowData.length
			});
		});
	}

	searchRelativeToDeal(): Observable<IGridFetchResults<DateMathRowTypes>> {
		const rowData = this.variesByDealService.commonCmds.map(x => ({
			title: x.label,
			record: null,
			template: null,
			assocChanges: {},
			charDatas: []
		}));

		return new Observable<IGridFetchResults<DateMathRowTypes>>(subscriber => {
			subscriber.next({
				rowData,
				rowCount: rowData.length
			});
		});
	}

	searchSetupTable(): Observable<IGridFetchResults<DateMathRowTypes>> {
		const pageSize = this.dataSource.pageSize$.value;
		let setupSearch$: Observable<DateMathSearchSetupTableResults>;
		if (this.isLocalEntity(this.contextEntityId) && this.relationship == null) {
			const result = this.getLocalTableSetupSearchResult(this.contextEntityId);
			setupSearch$ = of(result);
		} else {
			setupSearch$ = this.dateMathService.searchSetupTable(this.contextEntityId, this.relationship, pageSize);
		}
		return setupSearch$
			.pipe(
				tap((results) => {
					this.dataSource.setPaging({ rowOffset: results.start });
					this.path = results.path;
					if (results.detailRows) {
						this.dataSource.setColumns(this.step3Columns);
					}
				}),
				map((results) => {
					let fetchResults: IGridFetchResults<DateMathRowTypes>;
					if (results.detailRows) {
						fetchResults = { rowData: results.detailRows, rowCount: results.detailRows.length };
					} else {
						fetchResults = this.setupCharDataRows(
							this.relationship.parentCharTypeID,
							results.records,
							results.charDatas,
							results.templates,
							results.numFound,
							results.recordIdsWithAmounts
						);
					}
					return fetchResults;
				})
			);
	}

	private getLocalTableSetupSearchResult(entityId: string) {
		const path = !this.isRelative ? this.localSearchEntitiesRows(entityId).concat(this.localDetailsRows(entityId)) : this.localSearchEntitiesRows(entityId);
		const result: DateMathSearchSetupTableResults = {
			path,
			detailRows: this.localDetailDateCharacteristicRows(entityId),
			records: [],
			charDatas: {},
			templates: {},
			start: 0,
			numFound: 0,
			recordIdsWithAmounts: [],
			usageCatalogTitles: {}
		};
		return result;
	}

	goToStep2($event: Event, row: DateMathSearchRow) {
		if (row.step === DateMathSearchStep.RelativeToCatalog) {
			this.goToStep9($event, row);
		} else {
			$event.preventDefault();
			this.stepContextRow = row;
			this.dataSource.setPaging({ rowOffset: 0 });
			this.path.push(row);
			this.refreshSearch(DateMathSearchStep.EntityDetail);
		}
	}

	goToStep3Or4($event: Event, row: DateMathSearchRow) {
		$event.preventDefault();
		this.dataSource.setPaging({ rowOffset: 0 });
		this.stepContextRow = row;
		this.path.push(row);
		this.refreshSearch(row.step);
	}

	goToStep5($event: Event, row: DateMathSearchRow) {
		$event.preventDefault();
		this.dataSource.setPaging({ rowOffset: 0 });
		this.keywordsControl.patchValue(null, { emitEvent: false });
		this.stepContextRow = row;
		this.path.push(row);
		this.refreshSearch(DateMathSearchStep.AssocList);
	}

	goToStep6($event: Event, row: DateMathSearchRow) {
		$event.preventDefault();
		this.dataSource.setPaging({ rowOffset: 0 });
		this.keywordsControl.patchValue(null, { emitEvent: false });
		this.stepContextRow = row;
		this.path.push(row);
		// do search
		this.refreshSearch(DateMathSearchStep.AmountsList);
	}

	goToStep9($event: Event, row: DateMathSearchRow) {
		$event.preventDefault();
		this.dataSource.setPaging({ rowOffset: 0 });
		this.path.push(row);
		this.dataSource.setColumns(this.step9Columns);
		this.refreshSearch(row.step);
	}

	goToPath($event: Event, row: DateMathSearchRow, index: number) {
		$event.preventDefault();
		let nextStep: DateMathSearchStep;
		if (row == null) {
			if (this.isRelative) {
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToCatalog) {
					nextStep = DateMathSearchStep.RelativeToCatalog;
				}
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToRights) {
					nextStep = DateMathSearchStep.RelativeToRights;
				}
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToTableRow) {
					nextStep = DateMathSearchStep.RelativeToTableRow;
				}
				if (this.rrpOptionSelected === RelativeDateType.DateRelativeToDeal) {
					nextStep = DateMathSearchStep.RelativeToDeal;
				}
			} else {
				nextStep = DateMathSearchStep.Entity;
			}
			this.stepContextRow = null;
			this.path = [];
		} else {
			nextStep = row.step;
			this.stepContextRow = row;
			this.path = this.path.slice(0, index + 1);
		}
		this.refreshSearch(nextStep);
	}

	switchToStep2($event: Event, row: IEntityRecTemplateCharData) {
		$event.preventDefault();
		this.stepContextRow = {
			step: DateMathSearchStep.EntityDetail,
			title: row.record.title,
			entityId: row.record.id
		};
		this.dataSource.setPaging({ rowOffset: 0 });
		this.path = [cloneDeep(this.stepContextRow)];
		this.refreshSearch(DateMathSearchStep.EntityDetail);
	}

	private setupCharDataRows(
		charTypeId: number,
		records: IEntity[],
		charDatas: Dictionary<ICharacteristicData[]>,
		templates: Dictionary<ICharacteristicMetaDataCollection>,
		numFound: number,
		recordIdsWithAmounts: number[],
		assocColumns: IGridViewColumn[] = [],
		assocEntityRecords: Dictionary<Dictionary<IGridViewAssocEntityRec[]>> = {}
	): IGridFetchResults<DateMathRowTypes> {
		const cmds = chain(templates)
			.values()
			.orderBy(t => t.template.sequenceNumber)
			.flatMap(t => t.characteristicMetaDatas)
			.groupBy(cmd => cmd.characteristicID)
			.toPairs()
			.map(pair => first(pair[1]))
			.value();

		const step5Columns = cmds.map<GridColumn<IEntityRecTemplateCharData>>(cmd => {
			const column: GridColumn<IEntityRecTemplateCharData> = {
				key: cmd.tagLabel,
				headerName: cmd.label,
				renderer: "char_data",
				width: GridUtil.getCharDataColumnWidthByCmd(cmd),
				getCellData: (tuple) => {
					const template = templates[tuple.record.templateID];
					// double check this CMD is available in this rows template
					const cmd2 = template.characteristicMetaDatas.find(cmd3 => cmd3.characteristicID === cmd.characteristicID);
					const cds = tuple.charDatas;
					if (cmd2) {
						return cds.filter(cd => cd.charactersticID === cmd.characteristicID);
					}
					return null;
				},
				getCellConfig: (tuple) => {
					const template = templates[tuple.record.templateID];
					// double check this CMD is available in this rows template
					const cmd2 = template.characteristicMetaDatas.find(cmd3 => cmd3.characteristicID === cmd.characteristicID);
					if (cmd2) {
						return { cmd: cmd2 };
					}
					return null;
				},
			};

			if (cmd.dataTypeID === CharDataType.Date) {
				column.renderer = "step5_date";
				column.width = "max-content";
				column.getCellData = (tuple): DateCellData => {
					const template = templates[tuple.record.templateID];
					const cmd2 = template.characteristicMetaDatas.find(cmd3 => cmd3.characteristicID === cmd.characteristicID);
					if (cmd2) {
						const cd = first(tuple.charDatas.filter(cd2 => cd2.charactersticID === cmd2.characteristicID));
						const cellData: DateCellData = {
							entityId: tuple.record.id,
							charId: cmd2.characteristicID,
							title: cmd2.label
						};
						if (cd) {
							cellData.dateValue = DateUtil.formatStorageDateAsBrowserDateOrLabel(cd.value);
							cellData.recCharId = cd.recordCharacteristicID;
						}
						return cellData;
					} else {
						return null;
					}
				};
			}
			return column;
		});

		assocColumns.forEach(column => {
			const assocColumn: GridColumn<IEntityRecTemplateCharData> = {
				key: snakeCase(column.displayName),
				headerName: column.displayName,
				width: "minmax(max-content, 200px)",
				renderer: "step5_assoc_items",
				getCellData: (tuple): string[] => {
					const lookup = assocEntityRecords[tuple.record.recordID];
					if (!lookup || !(column.charTypeId in lookup)) {
						return [];
					}
					return lookup[column.charTypeId].map(x => x.title);
				}
			};
			step5Columns.unshift(assocColumn);
		});

		// if this is a Usage list, include the amounts column
		if (charTypeId === CharTypeId.Usage) {
			const amountsColumn: GridColumn<IEntityRecTemplateCharData> = {
				key: "_amounts",
				headerName: "",
				width: "max-content",
				renderer: "step5_amounts",
				getCellData: (tuple): DateMathSearchRow => {
					if (recordIdsWithAmounts.includes(tuple.record.recordID)) {
						return {
							step: DateMathSearchStep.AmountsList,
							title: "Amounts",
							charTypeId: CharTypeId.Amount,
							entityId: tuple.record.id
						};
					} else {
						return null;
					}
				}
			};

			step5Columns.unshift(amountsColumn);
		} else {
			const switchContextEntityColumn: GridColumn<IEntityRecTemplateCharData> = {
				key: "_switch_entity",
				headerName: "",
				width: "max-content",
				renderer: "step2_switch",
				getCellData: (tuple) => tuple
			};

			step5Columns.unshift(switchContextEntityColumn);
		}

		this.dataSource.setColumns(step5Columns);
		const rowData = records.map<IEntityRecTemplateCharData>(record => ({ record, charDatas: charDatas[record.recordID], template: templates[record.templateID], assoc_entity: {}, assocChanges: {} }));
		return { rowData, rowCount: numFound } as IGridFetchResults<DateMathRowTypes>;
	}

	selectDate($event: Event, row: DateMathSearchRow) {
		$event.preventDefault();
		this.selectDateInner(row);
	}

	selectDateInner(row: DateMathSearchRow) {
		this.path.push({
			step: DateMathSearchStep.SelectCharId,
			title: row.title
		});

		const $event2: DateMathSearchTableSelectEvent = {
			entityId: row.entityId,
			step: DateMathSearchStep.SelectCharId,
			charId: row.charId,
			recCharId: row.recCharId
		};

		this.dateSelect.emit($event2);
	}

	selectDateRadio($event: Event, cellData: DateCellData) {
		const step = cellData.recCharId ? DateMathSearchStep.SelectRecCharID : DateMathSearchStep.SelectCharId;
		this.path.push({
			step,
			title: cellData.title
		});

		const $event2: DateMathSearchTableSelectEvent = {
			entityId: cellData.entityId,
			charId: cellData.charId,
			step,
			recCharId: cellData.recCharId
		};

		this.dateSelect.emit($event2);
	}

	selectRelativeToCatalogChar($event: Event, row: DateMathSearchRow) {
		let entityId = row.entityId;
		const parentRecId = IDUtil.splitEntityID(this.contextEntityId).recID;
		if (this.isRelative) {
			entityId = IDUtil.toID(this.sessionService.divId, CharTypeId.Property, parentRecId);
		}
		$event.preventDefault();
		this.path.push({
			step: DateMathSearchStep.SelectCharId,
			title: row.title
		});

		const relativeCatalogCharId = this.variesByCatalogService.commonCmds.find(x => x.label === row.title).characteristicID;

		const $event2: DateMathSearchTableSelectEvent = {
			entityId,
			charId: row.charId,
			recCharId: row.recCharId,
			step: DateMathSearchStep.RelativeToCatalog,
			relativeCatalogCharId
		};

		this.dateSelect.emit($event2);
	}

	selectRelativeToDealChar($event: Event, row: DateMathSearchRow) {
		let entityId = row.entityId;
		const parentRecId = IDUtil.splitEntityID(this.contextEntityId).recID;
		if (this.isRelative) {
			entityId = IDUtil.toID(this.sessionService.divId, CharTypeId.Transaction, parentRecId);
		}
		$event.preventDefault();
		this.path.push({
			step: DateMathSearchStep.SelectCharId,
			title: row.title
		});

		const relativeDealCharId = this.variesByDealService.commonCmds.find(x => x.label === row.title).characteristicID;

		const $event2: DateMathSearchTableSelectEvent = {
			entityId,
			step: DateMathSearchStep.SelectCharId,
			charId: row.charId,
			recCharId: row.recCharId,
			relativeDealCharId
		};

		this.dateSelect.emit($event2);
	}

	formatDate(input: string) {
		if (input == null) {
			return "";
		} else if (input === BLANK_DATE) {
			return "[TBD]";
		} else {
			return input;
		}
	}

	private isLocalEntity(entityId: string) {
		const parsed = IDUtil.splitEntityID(entityId);
		return parsed.recID <= -1;
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}
}
