<div class="modal-header">
	<h2 class="modal-title">
		Edit Sections
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-3">
			@if (form?.errors?.["titleUniqueness"]) {
			<div class="alert alert-danger">
				Titles must be unique.
			</div>
			}
			@if (form?.errors?.["sectionsRequired"]) {
			<div class="alert alert-danger">
				You must have at least one section.
			</div>
			}
			<div cdkDropList class="drop-list-container" (cdkDropListDropped)="dropSection($event)">
				<div class="d-flex align-items-center p-1" *ngFor="let section of sections; let i = index" cdkDrag>
					<span class="drag__handle pe-2" cdkDragHandle>
						<i class="far fa-ellipsis-v"></i>
					</span>
					<div class="flex-grow-1">
						<rl-text-input class="mb-4" [(ngModel)]="section.title" [dataLabel]="'TitleInput'">
						</rl-text-input>
					</div>
					<span class="ps-2">
						<button class="btn btn-icon" (click)="moveToTop(i)" title="Move to top">
							<i class="far fa-arrow-to-top"></i>
						</button>
					</span>
					<span class="ps-2">
						<button class="btn btn-icon btn-remove p-2" title="Remove" (click)="deleteSection(deleteGroupPrompt, i)">
							<i class="far fa-times"></i>
						</button>
					</span>
				</div>
				<div class="d-flex align-items-center p-1">
					<div class="flex-grow-1">
						<rl-text-input class="mb-4" [(ngModel)]="newGroupName" [placeholder]="'Add New Section'" (keydown.enter)="addSection()">
						</rl-text-input>
					</div>
					<span class="ps-2">
						<button class="btn btn-icon" (click)="addSection()" title="Move to top" [disabled]="newGroupName?.length === 0">
							<i class="far fa-plus"></i>
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light ms-auto" (click)="close()">Cancel</button>
	<button (click)="done()" [disabled]="!isValid" class="btn btn-success">
		Done
	</button>
</div>

<ng-template #deleteGroupPrompt let-prompt="prompt">
	<div>
		<div class="alert alert-warning">
			This panel group has one or more child sections.
		</div>
		<p>
			Are you sure you want to delete <b>{{ prompt.context.group.title }}</b>?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)">
			No
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)">
			Yes
		</button>
	</div>
</ng-template>