import { Injectable } from "@angular/core";
import { RlHttpClient } from "rl-common/services/rl-http-client.service";
import { IAllocatedDimension, IAllocationModel } from "./allocation.models";

@Injectable({ providedIn: "root" })
export class AllocationService {
	private _baseUrl = "/allocation";

	constructor(private readonly _httpClient: RlHttpClient) { }

	createUpdateAllocationModel(model: IAllocationModel) {
		return this._httpClient
			.post(`${this._baseUrl}/model`)
			.body(model)
			.fetch<string>();
	}

	getAllocationModelListByParentTemplateId(parentCharTemplateId: number) {
		return this._httpClient
			.get(`${this._baseUrl}/model-list`)
			.params({
				parentCharTemplateId
			})
			.fetch<IAllocationModel[]>();
	}

	getAllocationModelDetails(id: number) {
		return this._httpClient
			.get(`${this._baseUrl}/model`)
			.params({
				id
			})
			.fetch<IAllocationModel>();
	}

	deleteAllocationModel(allocationModelId: string) {
		return this._httpClient
			.delete(`${this._baseUrl}/model/${allocationModelId}`)
			.fetch<void>();
	}


	createUpdateAllocatedDimensions(dimension: IAllocatedDimension[]) {
		return this._httpClient
			.post(`${this._baseUrl}/dimension`)
			.body(dimension)
			.fetch<void>();
	}

	getAllocatedDimensionsByModelId(id: string) {
		return this._httpClient
			.get(`${this._baseUrl}/dimensions`)
			.params({
				id
			})
			.fetch<IAllocatedDimension[]>();
	}

	deleteAllocatedDimensions(allocatedDimensionIds: string[]) {
		return this._httpClient
			.delete(`${this._baseUrl}/dimensions`)
			.body(allocatedDimensionIds)
			.fetch<void>();
	}
}
