import { Pipe, PipeTransform } from "@angular/core";
import { CharDataType } from "rl-common/consts";
import { CharDataTypeUtil } from "rl-common/utils/char-data-type.util";

@Pipe({ name: "charDataTypeName" })
export class CharDataTypeNamePipe implements PipeTransform {

	transform(value: CharDataType, ...args: unknown[]): unknown {
		return CharDataTypeUtil.toDisplayName(value);
	}

}
