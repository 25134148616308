import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { AccountingProcesses } from "../../accounting-processes.consts";
import { IAccountingOperationTriggerForm } from "../add-accounting-operation-trigger.models";
import { DataBehaviorSingleValueEntryComponent } from "../data-behavior-single-value-entry/data-behavior-single-value-entry.component";

@Component({
	selector: "rl-data-behavior-value-entry",
	templateUrl: "./data-behavior-value-entry.component.html",
	styleUrls: ["./data-behavior-value-entry.component.scss"],
	imports: [NgSwitch, NgSwitchCase, DataBehaviorSingleValueEntryComponent, NgIf]
})
export class DataBehaviorValueEntryComponent implements OnInit {

	@Input()
	valueEntryType: AccountingProcesses.ValueEntryType;

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	form: FormGroup<IAccountingOperationTriggerForm>;

	constructor() { }

	ngOnInit(): void {

	}

}
