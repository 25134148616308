import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { ExtractPdfComponent } from "./extract-pdf.component";

@Component({
    selector: "rl-extract-pdf-container",
    templateUrl: "./extract-pdf-container.component.html",
    imports: [ExtractPdfComponent]
})
export class ExtractPdfContainerComponent implements OnInit {
	charTypeId: number;
	templateId: number;

	constructor(private sessionService: SessionService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private progressService: ProgressService,
		private titleService: Title) {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.charTypeId = +params["charTypeId"];
			this.templateId = +params["templateId"];
		});
	}

	ngOnInit(): void {
		this.titleService.setTitle("Extract Directly From PDF");
	}

	cancel() {
		const cancelUrl = `mod${this.charTypeId}`;

		this.router.navigateByUrl(cancelUrl);
	}
}
