<div class="modal-header">
	<h2 class="modal-title">Setup Google Authenticator</h2>
</div>
<div class="modal-body">
	<ol class="auth-steps">
		<li class="mb-5">
			<h4 class="mb-3">Install Google Authenticator App</h4>
			<a target="_blank"
				href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
				<rl-google-app-store-badge [height]="45"></rl-google-app-store-badge>
			</a>
			<span class="mx-3">|</span>
			<a target="_blank" href="https://itunes.apple.com/us/app/google-authenticator/id388497605">
				<rl-apple-app-store-badge [height]="45"></rl-apple-app-store-badge>
			</a>
		</li>
		<li class="mb-3">
			<h4>Scan QR Code or enter Secret Key below</h4>
			<div class="mb-2">
				<div class="d-flex align-items-center">
					<div>
						<img [src]="otpUrl" />
					</div>
					<div class="ms-3">
						<p><strong>Account</strong>: {{username}}</p>
						<p><strong>Key</strong>: {{ secretKey }}</p>
					</div>
				</div>
			</div>
		</li>
		<li class="mb-3">
			<h4>Confirm by entering code below</h4>
			<div class="input-group col-sm-4">
				<input name="code" type="text" class="form-control" [formControl]="code" placeholder="ex: 123456"
					maxlength="6" (keydown.enter)="verifyCode()" />
			</div>
		</li>
	</ol>
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button type="button" class="btn btn-success ms-2" [disabled]="googleAuthForm.invalid || isVerifying"
		(click)="verifyCode()">
		<i *ngIf="isVerifying" class="fa fa-spinner fa-spin"></i>
		<i *ngIf="!isVerifying" class="fa fa-check"></i>
		<span class="ps-1">Verify</span>
	</button>
</div>