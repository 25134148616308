import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cloneDeep } from "lodash";
import { ITemplateHierarchy } from "rl-common/components/create-workflow/create-entity.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { FaIconDirective } from "../../../directives/fa-icon.directive";

@Component({
	selector: "rl-catalog-hierarchy-allocation",
	templateUrl: "./catalog-hierarchy-allocation.component.html",
	styleUrl: "./catalog-hierarchy-allocation.component.scss",
	imports: [NgIf, FaIconDirective, ReactiveFormsModule, FormsModule, NgFor]
})
export class CatalogHierarchyAllocationComponent implements OnInit {

	@Input()
	template: ITemplateHierarchy;

	@Input()
	charTypeId: number;

	@Input()
	parentTemplateId: number;

	@Input()
	selectedSuballocationId: number;

	@Output()
	onChange = new EventEmitter<number>();

	constructor(private readonly _oneConfigService: OneConfigService) { }

	ngOnInit() {
		const templates = this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.template.templateID, this.charTypeId);

		this.template.childTemplates = [];
		templates.forEach(t => {
			const parentTemplates = cloneDeep(this.template.directParentTemplates);
			parentTemplates.push(this.template.templateID);
			const newTemp: ITemplateHierarchy = {
				directParentTemplates: parentTemplates,
				templateID: t.templateID,
				templateLabel: t.templateName,
				childTemplates: null,
				level: this.template.level + 1,
				numberOfRecords: 0
			};

			// ensure that this template doesn't exist in the recursive line of direct parent templates
			if (parentTemplates.indexOf(newTemp.templateID) < 0) {
				this.template.childTemplates.push(newTemp);
			}
		});

	}

	toggleHierarchy(template: ITemplateHierarchy) {
		template.numberOfRecords = template.numberOfRecords > 0 ? 0 : 1;
	}

	updateValue(templateID: number) {
		this.onChange.emit(templateID);
	}
}