<div class="modal-header" data-cy="EditWarningModalHeader">
	<h2 class="modal-title">Save Record and Relationships</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()"
		data-cy="EditWarningModalCloseButton">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" *ngIf="warningMessage" [innerHTML]="warningMessage" data-cy="EditWarningModalBody"></div>
<div class="modal-footer" data-cy="EditWarningModalFooter">
	<button type="button" class="btn btn-light" (click)="decline()"
		data-cy="EditWarningModalCancelButton">Cancel</button>
	<button type="button" class="btn btn-info" (click)="accept()" data-cy="EditWarningModalYesButton">Yes</button>
</div>