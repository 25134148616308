import { Pipe, PipeTransform } from "@angular/core";
import { IDUtil } from "rl-common/utils";

@Pipe({ name: "parseRecordId" })
export class ParseRecordIdPipe implements PipeTransform {

	transform(entityId: string): number {
		if (!entityId || !IDUtil.isEntityID(entityId)) {
			return null;
		}
		const split = IDUtil.splitEntityID(entityId);
		return split.recID;
	}

}
