<div class="modal-header">
	<h2 class="modal-title">My Reports run in the last 72 hours</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<rl-grid [dataSource]="dataSource" [layoutOptions]="layoutOptions">
		<div *rlCellTemplate="'progress'; let cellData" class="pe-4">
			<div class="d-flex" style="gap:5px;">
				<div class="progress" style="height:18px; width:100%">
					<div class="progress-bar progress-bar-striped" [class.bg-success]="cellData[3] == 3"
						[class.bg-warning]="cellData[3] > 3" [class.progress-bar-animated]="cellData[3]==2"
						[style.width.%]="cellData[2]">
					</div>
				</div>
				<span style="min-width:40px;">{{cellData[2]}}%</span>
			</div>
		</div>
		<div *rlCellTemplate="'results'; let cellData" class="pe-4">
			<ng-container [ngSwitch]="cellData?.type">
				<span *ngSwitchCase="'download'">
					@for (file of cellData?.files; track $index) {
					@if ($index > 0){
					<span> | </span>
					}
					<a [href]="file.url | safe" target="_blank">{{file.filename}} <i class="far fa-download"></i></a>
					}
				</span>
				<!-- <span *ngSwitchDefault [innerText]="cellData | json"></span> -->
			</ng-container>
		</div>
		<div *rlCellTemplate="'name_link'; let cellData" class="pe-4">
			<a *ngIf="cellData[1]?.length > 0" [href]="cellData[1] | safe" target="_blank">
				{{ cellData[0] }} <i class="far fa-external-link"></i>
			</a>
			<span *ngIf="cellData[1] == null" [innerText]="cellData[0]"></span>
		</div>
		<div *rlCellTemplate="'ops'; let cellData" class="pe-4">
			<div class="d-flex justify-content-end">
				<button *ngIf="cellData?.statusId < 3" type="button" class="btn btn-sm btn-outline-warning"
					title="Cancel Report" (click)="cancelJob(cellData)" rlBusyButton>
					<i class="far fa-times-circle"></i>
				</button>
				<button *ngIf="cellData?.statusId >= 3" type="button" class="btn btn-sm btn-outline-danger"
					title="Delete Report" (click)="deleteJob(cellData)" rlBusyButton>
					<i class="far fa-trash-alt"></i>
				</button>
			</div>
		</div>
	</rl-grid>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-info" (click)="close()" data-cy="jobProgressClose">
		Close
	</button>
</div>