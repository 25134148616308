import { Component, EventEmitter, Input, OnInit, Output, Provider } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EditEntityComponent } from "../edit-entity/edit-entity.component";
import { EditEntityCompleteEvent } from "../edit-entity/models/edit-entity-complete-event";
import { ParentEntityService } from "./../../../services/entity/parent-entity/parent-entity.service";
import { EditEntityModalAdapter, IEditEntityModalComponent } from "./edit-entity-modal.adapter";

@Component({
	selector: "rl-edit-entity-modal",
	templateUrl: "./edit-entity-modal.component.html",
	providers: [ParentEntityService],
	imports: [EditEntityComponent]
})
export class EditEntityModalComponent implements OnInit, IEditEntityModalComponent {
	@Input()
	public parentEntityId?: string;

	@Input()
	public editingEntityId: string;

	@Input()
	public baseEntityId: string;


	@Input()
	public isRelative: boolean = false;

	@Output()
	onComplete = new EventEmitter<EditEntityCompleteEvent>();

	constructor(
		private readonly _modal: NgbActiveModal,
	) { }

	ngOnInit() {
	}

	save(result: EditEntityCompleteEvent) {
		this.onComplete.emit(result);
		this._modal.close();
	}

	cancel() {
		this.onComplete.emit({
			success: false,
			entityId: this.parentEntityId,
			relWasAdded: false,
			relWasDeleted: false,
			charDataChangesWereMade: false,
			anyChangesWereMade: false,
			relChangesWereMade: false
		});
		this._modal.close();
	}

}

export const EDITENTITY_MODAL_PROVIDER: Provider = {
  provide: EditEntityModalAdapter,
  useFactory: () => new EditEntityModalAdapter(EditEntityModalComponent)
};

