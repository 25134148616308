import { Directive, HostListener, HostBinding, Optional, Input, ElementRef, AfterContentInit, Attribute, Inject, ContentChild, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { NgForm, FormGroupDirective } from "@angular/forms";
import { Subscription } from "rxjs";
import { FaIconDirective } from "./fa-icon.directive";
import { ProgressService } from "rl-common/services/progress.service";

@Directive({ selector: "button[rlBusyButton]" })
export class BusyButtonDirective implements AfterContentInit, OnInit, OnDestroy {
	busy: boolean;

	/* eslint-disable-next-line @angular-eslint/no-input-rename */
	@Input("disabled")
	prevDisabled: boolean;

	@HostBinding("disabled")
	get isDisabled() {
		return this.prevDisabled || this.busy;
	}

	@Attribute("busyButton")
	busyClass: string;

	@ContentChild(FaIconDirective, /* TODO: add static flag */ { static: true })
	faIcon: FaIconDirective;

	defaultBusyClass = "fa fa-spin fa-circle-o-notch";

	prevClass: string;

	formSub: Subscription;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private progressService: ProgressService,
		@Optional() @Attribute("type") private buttonType,
		@Optional() @Inject(NgForm) private parentForm: NgForm,
		@Optional() @Inject(FormGroupDirective) private parentFormGroup: FormGroupDirective) {

		this.busy = false;
		this.progressService.progressEnded.subscribe(() => {
			this.teardown();
		});
	}

	ngOnInit() {

		// form submit buttons can listen to the parent form's submit event
		if (this.buttonType === "submit") {
			if (this.parentForm != null) {
				this.formSub = this.parentForm.ngSubmit
					.subscribe(($event: Event) => {
						if (!$event.defaultPrevented) {
							this.setup();
						}
					});
			} else if (this.parentFormGroup != null) {
				this.formSub = this.parentFormGroup.ngSubmit
					.subscribe(($event: Event) => {
						if (!$event.defaultPrevented) {
							this.setup();
						}
					});
			}
		}
	}

	ngOnDestroy() {
		if (this.formSub != null) {
			this.formSub.unsubscribe();
		}
	}

	ngAfterContentInit() {
		if (this.faIcon) {
			this.prevClass = this.faIcon.iconClass;
		}
	}

	@HostListener("click", ["$event"])
	onclick($event: MouseEvent) {
		if (!$event.defaultPrevented) {
			// let submit buttons listen for the form event
			if (this.buttonType !== "submit") {
				this.setup();
			}
		} else {
			this.teardown();
		}
		return true;
	}

	setup() {
		this.busy = true;
		this.renderer.setProperty(this.el, "disabled", true);
		if (this.faIcon) {
			this.faIcon.iconClass = this.busyClass || this.defaultBusyClass;
		}
	}

	teardown() {
		if (this.busy) {
			this.busy = false;
			this.renderer.setProperty(this.el, "disabled", this.prevDisabled);
			if (this.faIcon) {
				this.faIcon.iconClass = this.prevClass;
			}
		}
	}
}

export const BUSY_BUTTON_DIRECTIVES = [BusyButtonDirective, FaIconDirective];
