<div class="panel mx-3 mb-3">
	<div class="panel__title">
		<span class="title--overline">Intelligence Center</span>
		<h3>Hints</h3>
	</div>
	<section class="mt-3">
		<p>Any additional information about where a field might be found in your contracts can make Intelligent Extract more accurate.</p><br />
		<p>The following fields have Hints set up that will be used when prompting the model for that field:</p><br />
		<p>No hints found.</p><br /><br />
		<button class="btn btn-success ms-2"  data-cy="HintsButton">
			Create Hints
		</button>
	</section>
</div>