<div class="modal-header">
	<h2 class="modal-title" [innerHTML]="title"></h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()"
		data-cy="ConfirmationModalCloseButton">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer" data-cy="DeleteConfirmationModalFooter">
	<button type="button" class="btn btn-light" (click)="decline()" *ngIf="btnCancelText"
		data-cy="ConfirmationModalCancelButton">{{ btnCancelText }}</button>
	<button type="button" class="btn btn-success ms-3" (click)="accept()" *ngIf="btnOkText"
		data-cy="ConfirmationModalOkButton">{{ btnOkText }}</button>
</div>