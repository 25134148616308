import { Injectable } from "@angular/core";
import { IPagedResponse } from "rl-common/models/i-paged-response";
import { RlHttpClient } from "../rl-http-client.service";
import { IClosedPeriodSettings, IFinancialPeriod } from "./financial-period.models";

@Injectable({ providedIn: "root" })
export class FinancialService {
	private _baseUrl = "/financial-period";

	constructor(private readonly _httpClient: RlHttpClient) { }

	getClosePeriodSettings() {
		return this._httpClient
			.get(`${this._baseUrl}/close-period-settings`)
			.fetch<IClosedPeriodSettings>();
	}

	getPeriodsPaged(page: number, pageSize: number, sortField: string, sortDir: number) {
		return this._httpClient
			.get(`${this._baseUrl}/financial-periods-paged`)
			.params({ page, pageSize, sortField, sortDir })
			.fetch<IPagedResponse<IFinancialPeriod[]>>();
	}

	createFinancialPeriod(period: IFinancialPeriod) {
		return this._httpClient
			.post(`${this._baseUrl}`)
			.body(period)
			.fetch<void>();
	}

	updateFinancialPeriod(period: IFinancialPeriod) {
		return this._httpClient
			.patch(`${this._baseUrl}`)
			.body(period)
			.fetch<void>();
	}

	deleteFinancialPeriod(period: IFinancialPeriod) {
		return this._httpClient
			.delete(`${this._baseUrl}`)
			.body(period)
			.fetch<void>();
	}

	closePeriod(period: IFinancialPeriod) {
		return this._httpClient
			.post(`${this._baseUrl}/close-period`)
			.body(period)
			.fetch<string>();
	}

	saveWorkflowLockedAction(request: { processId: number; actionId: number; }[]) {
		return this._httpClient
			.post(`${this._baseUrl}/workflow-locked-action`)
			.body(request)
			.fetch<void>();
	}

	validatePeriods() {
		return this._httpClient
			.get(`${this._baseUrl}/validate`)
			.fetch<string[]>();
	}
}
