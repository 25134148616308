import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CharTypeId } from "rl-common/consts";
import { SearchType } from "rl-common/factories";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IAssociatedRecords } from "rl-common/services/associations/association.models";
import { IDUtil } from "rl-common/utils/id.util";
import { NewAssociationComponent } from "../../new-association-modal/new-association-wizard/new-association.component";
import { RevokeOrGrantAccessRequest } from "./../../../services/document/document.models";
import { DocumentService } from "./../../../services/document/document.service";
import { IAssociationEvent } from "./../../new-association-modal/new-association-wizard/new-association.component.models";

@Component({
	selector: "rl-revoke-or-grant-access-modal",
	templateUrl: "./revoke-or-grant-access-modal.component.html",
	styleUrls: ["./revoke-or-grant-access-modal.component.scss"],
	imports: [NewAssociationComponent]
})
export class RevokeOrGrantAccessModalComponent implements OnInit {

	@Input()
	isGrantAccess: boolean;
	@Input()
	parentTitle: string;
	@Input()
	parentTemplateId: number;
	@Input()
	associatedRecords: IAssociatedRecords;
	@Input()
	selectedAssociations: IRecordTitle[] = [];
	@Input()
	selectedEntityIds: string[];

	@Output()
	onApply = new EventEmitter<string>();

	modalTitle: string;
	relationshipType: RelationshipTypes;
	charTypeId = CharTypeId.User;
	searchType = SearchType.NewRelationship;


	constructor(private readonly _documentService: DocumentService) { }

	ngOnInit() {
		this.modalTitle = this.isGrantAccess ? "Grant Access To" : "Revoke Access From";
	}

	revokeOrGrantAccess($event: IAssociationEvent) {
		if ($event.selectedState.isAllSelected) {
			console.error(`Select all is not implemented for this case`);
			return;
		}
		const contactIds = Array.from($event.selectedState.selectedIds);
		const docIds = this.selectedEntityIds.map(x => IDUtil.splitEntityID(x).recID);
		const request: RevokeOrGrantAccessRequest = {
			isGrantAccess: this.isGrantAccess,
			contactRecIds: contactIds,
			documentEntityRecIds: docIds
		};
		this._documentService.revokeOrGrantContactAccess(request).subscribe(response => {
			this.onApply.emit(response.jobId);
		});
	}

	close() {
		this.onApply.emit(null);
	}
}
