<div class="datemath-edit__outer">
	<div class="datemath-edit__header align-items-center mb-3">
		<div class="d-flex align-content-center justify-content-space">
			<span class="mt-1 text-nowrap text-large" [innerText]="cmd?.label"></span>
			<select class="form-select ms-3 w-auto" style="font-size:16px;" [formControl]="timeframeFC"
				data-cy="DateMathSelector">
				<option *ngFor="let pair of timeframeList" [ngValue]="pair[0]" [innerText]="pair[1]"></option>
			</select>
			<select *ngIf="isRelative" class="form-select ms-3 w-auto" style="font-size:16px;"
				data-cy="DateMathSelector" [(ngModel)]="selectedOptionId" (change)="checkForSearch()">
				<option *ngFor="let option of relativeOptions" [ngValue]="option.value" [innerText]="option.text">
				</option>
			</select>
			<em class="text-nowrap mt-2 ms-auto">Set this date to be triggered by any other date in the system.</em>
		</div>
	</div>
	<div class="datemath-edit__body" data-cy="DatemathEditBody">
		<div class="datemath-edit--busy p-2" *ngIf="isBusy$ | async">
			<rl-loader></rl-loader>
		</div>
		<div class="text-medium mb-4">
			<span [innerText]="entityTitle$ | async"></span>
			<span *ngIf="!calcDescription">
				<span class="ms-1" [innerText]="cmd?.label"></span>
				<span class="ms-1" [innerText]="calcDescription"></span>
			</span>
			<span class="ms-1" [innerText]="calcDescription" *ngIf="calcDescription"></span>
		</div>
		<div class="row">
			<div class="col" cdkDropList (cdkDropListDropped)="dropSort($event)">
				<ng-container *ngFor="let pair of relInfoPairs; trackBy:relTrackBy">
					<rl-datemath-edit-row cdkDrag cdkDragLockAxis="y" [cdkDragData]="pair[0]" [relationship]="pair[0]"
						[parentRelInfo]="pair[1]" [contextEntityId]="contextEntityId"
						[contextCharId]="cmd.characteristicID" [closeSearch$]="closeRows$" (remove)="removeRel($event)"
						(dateSelect)="dateSelected($event, pair[0])" (relChange)="relChanged($event)">
						<span slot="drag-handle" [hidden]="!canReorder" cdkDragHandle class="drag__handle pe-2">
							<i class="far fa-ellipsis-v"></i>
						</span>
						<ng-container slot="drag-placeholder">
							<div class="datemath__drag-placeholder" *cdkDragPlaceholder></div>
						</ng-container>
					</rl-datemath-edit-row>
				</ng-container>
				<div *ngIf="showCompareToDate" class="datemath__compare-row d-flex align-items-center">
					<span slot="drag-handle" *ngIf="canReorder" cdkDragHandle class="drag__handle pe-2">
						<i class="far fa-ellipsis-v"></i>
					</span>
					<div>
						<rl-date-edit-control [dateValue]="compareToDate" [allowPerpetuity]="false"
							[isEstimatedEnabled]="true" [placeholder]="'Add specific date'"
							(dateValueChange)="compareToDateChanged($event)">
						</rl-date-edit-control>
					</div>
				</div>
			</div>
		</div>
		<div class="row my-2" *ngIf="showAddAnother">
			<div class="col"><strong>OR</strong>
				<button class="btn btn-link ms-2" (click)="addAnother()" data-cy="DatemathAddAnotherDate">
					Add another date <i class="far ms-2" [class.fa-angle-down]="isSelectOpen"></i>
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<ng-template [ngIf]="isSelectOpen">
					<rl-datemath-search-table [contextEntityId]="contextEntityId" [contextCharId]="cmd.characteristicID"
						[isRelative]="isRelative" [rrpOptionSelected]="selectedOptionId" [relationships]="relationships"
						[rrpParentEntityId]="parentEntityId" [cmd]="cmd" (dateSelect)="dateSelected($event, null)">
					</rl-datemath-search-table>
				</ng-template>
			</div>
		</div>
	</div>
	<div class="datemath-edit__footer">
		<div class="row mt-4">
			<div class="col-12 col-lg-9">
				<div class="d-flex align-items-center">
					<select class="form-select me-3" [formControl]="recalcOptionFC" data-cy="RecalcOptionFc">
						<option *ngFor="let pair of recalcOptionList" [ngValue]="pair[0]" [innerText]="pair[1]"
							[attr.data-cy]="pair[0]">
						</option>
					</select>
					<select class="form-select me-5" [formControl]="calcConditionFC" data-cy="CalcConditionFc">
						<option *ngFor="let pair of calcConditionList" [ngValue]="pair[0]" [innerText]="pair[1]"
							[attr.data-cy]="pair[0]">
						</option>
					</select>
					<label class="py-3 text-nowrap">
						<input class="me-1 form-check-input" type="checkbox" [formControl]="dateOptionFC"
							data-cy="RoundDateDownCheckbox">
						Round date down to first of the month
					</label>
				</div>
			</div>
			<div class="col-12 col-lg-3 text-end">
				<button type="button" class="btn btn-light" (click)="cancel()"
					data-cy="DateMathCancelButton">Cancel</button>
				<button type="button" class="btn btn-success ms-3" (click)="apply()" [disabled]="!canApply()"
					data-cy="DateMathApplyButton">Apply</button>
			</div>
		</div>
	</div>
	<!-- {{ _dateMath | json}} -->
	<!-- {{relInfoPairs | json}} -->
</div>