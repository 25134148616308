import { Injectable } from "@angular/core";
import { RlHttpClient } from "rl-common/services/rl-http-client.service";
import { IAmortizationModel, IAutomatedAmortization } from "./amortization.models";

@Injectable({ providedIn: "root" })
export class AmortizationService {
	private _baseUrl = "/amortization";

	constructor(private readonly _httpClient: RlHttpClient) { }

	getAmortizationList() {
		return this._httpClient
			.get(`${this._baseUrl}/list`)
			.fetch<IAmortizationModel[]>();
	}

	editAmortizationModel(model: IAmortizationModel) {
		return this._httpClient
			.post(`${this._baseUrl}/model`)
			.body(model)
			.fetch<void>();
	}

	deleteAmortizationModel(id: number) {
		return this._httpClient
			.delete(`${this._baseUrl}/model/${id}`)
			.fetch<void>();
	}

	getAutomatedAmortizationList() {
		return this._httpClient
			.get(`${this._baseUrl}/automated-amortization`)
			.fetch<IAutomatedAmortization[]>();
	}


	createAutomatedAmortization(dealTemplateId: number, dealStatuses: number[], allocationStatuses: number[]) {
		return this._httpClient
			.post(`${this._baseUrl}/automated-amortization`)
			.body({
				dealTemplateId,
				dealStatuses,
				allocationStatuses
			})
			.fetch();
	}

	updateAutomatedAmortization(amortJobId: number, dealTemplateId: number, dealStatuses: number[], allocationStatuses: number[]) {
		return this._httpClient
			.put(`${this._baseUrl}/automated-amortization`)
			.body({
				amortJobId,
				dealTemplateId,
				dealStatuses,
				allocationStatuses
			})
			.fetch();
	}

	deleteAutomatedAmortization(amortJobId: number) {
		return this._httpClient
			.delete(`${this._baseUrl}/automated-amortization/${amortJobId}`)
			.fetch<void>();
	}
}
