import { AsyncPipe, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { remove } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PluralCharTypeNamePipe } from "../../../../../pipes/plural-char-type-name.pipe";
import { ChipsComponent } from "../../../../chip/chips/chips.component";
import { IChipDeselectedEvent } from "./../../../../chip/chips/chips.component";
import { SearchGridDataSource } from "./../../../../grid/datasource/search-grid.datasource";

@Component({
    selector: "rl-association-chips",
    templateUrl: "./association-chips.component.html",
    styleUrls: ["./association-chips.component.scss"],
    imports: [NgIf, ChipsComponent, AsyncPipe, PluralCharTypeNamePipe]
})
export class AssociationChipsComponent implements OnInit {
	@Input()
	dataSource: SearchGridDataSource<IEntitySearchDoc>;

	@Input()
	charTypeId: CharTypeId;

	@Input()
	newRecordTitle: IRecordTitle;

	@Input()
	selectedInputList: IEntitySearchDoc[] = [];

	@Output()
	onNewRelRemoved = new EventEmitter<void>();
	isEmpty$: Observable<boolean>;
	selectedCount$: Observable<number>;

	gridChipIdFn = (chip: IGridViewAssocEntityRec) => chip.recordId;
	gridChipDisplayFn = (chip: IGridViewAssocEntityRec) => chip.title;
	inputListChipIdFn = (chip: IRecordTitle) => chip.recordId;
	inputListChipDisplayFn = (chip: IRecordTitle) => chip.title;

	constructor() { }

	ngOnInit(): void {
		if (!this.dataSource) {
			console.warn(`No dataSource supplied.`);
		}
		this.selectedCount$ = this.dataSource.dataSelectStrategy.selectStateChange$
			.pipe(map(state => {
				const newCount = this.newRecordTitle ? 1 : 0;
				let count = 0;
				if (!state.isAllSelected) {
					count = state.selectedIds.size;
				} else {
					const deselectedCount = state.deselectedIds.size;
					const rowCount = this.dataSource.availableRowCount$.value ?? this.dataSource.rowCount$.value;
					count = rowCount - deselectedCount;
				}
				return newCount + count;
			}));
		this.isEmpty$ = this.selectedCount$.pipe(map(count => count === 0));
	}

	deselect(event: IChipDeselectedEvent<number, unknown>) {
		if (event.isNewRow) {
			this.onNewRelRemoved.emit();
		} else if (event.isSelectAll) {
			this.dataSource.dataSelectStrategy.deselectAll();
		} else {
			this.dataSource.dataSelectStrategy.deselectRowById(event.id, event.value);
		}
	}

	deselectInputList(event: IChipDeselectedEvent<number, IEntitySearchDoc>) {
		this.dataSource.dataSelectStrategy.deselectRowById(event.value.recordID, event.value);
		remove(this.selectedInputList, i => i.recordID === event.value.recordID);
	}
}
