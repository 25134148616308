import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { isEqual } from "lodash";
import { SystemIndicators } from "rl-common/consts";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { MultipleIndicator } from "rl-common/models/multiple-indicator";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { ICreateTrigger } from "rl-common/services/accounting-processes/models/i-create-trigger";
import { GrowlerService } from "rl-common/services/growler.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Subscription } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { AccountingOperationDateComponent } from "../accounting-operation-date/accounting-operation-date.component";
import { AddAccountingOperationDateComponent } from "../add-accounting-operation-date/add-accounting-operation-date.component";
import { IBuildAccountingOperationDate } from "../build-accounting-operation/models/i-build-accounting-operation-date";
import { BuildAccountingProcessService } from "../build-accounting-process-modal/build-accounting-process.service";

@Component({
	selector: "rl-add-accounting-operation-dates",
	templateUrl: "./add-accounting-operation-dates.component.html",
	styleUrls: ["./add-accounting-operation-dates.component.scss"],
	imports: [NgIf, NgFor, AddAccountingOperationDateComponent, AccountingOperationDateComponent]
})
export class AddAccountingOperationDatesComponent implements OnInit, OnChanges, OnDestroy {
	private readonly _subs: Subscription[] = [];
	anchorTemplateDates: ICharacteristicMetaData[] = [];
	anchorTemplateDefaultDates: ICharacteristicMetaData[] = [];
	datesDictionary: { [key: number]: string } = {};
	editIndex: number = null;
	isFirst: boolean = false;

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	anchorTemplatesDates: { [key: number]: ICharacteristicMetaData[] } = {};

	@Input()
	datesFormArray: FormArray<FormControl<IBuildAccountingOperationDate>>;

	@Input()
	operationTriggers: ICreateTrigger[];

	@Input()
	operationId: string;

	get dateControls() {
		return this.datesFormArray.controls;
	}

	get otherDates() {
		const dates = this.datesFormArray.controls.map(control => control.value);
		const front = dates.slice(0, this.editIndex);
		const back = dates.slice(this.editIndex + 1, dates.length);
		return front.concat(back);
	}

	get otherOperationDates() {
		return this._buildAccountingProcessService.getOtherAccountingProcessOperationDates(this.operationId);
	}

	get isEditing() {
		return this.editIndex !== null;
	}

	constructor(
		private readonly _promptService: PromptService,
		private readonly _accountingProcessesService: AccountingProcessesService,
		private readonly _buildAccountingProcessService: BuildAccountingProcessService,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit(): void {
		this.updateAnchorTemplateDates();
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.anchorTemplate) {
			this.updateAnchorTemplateDates();
		}

		if (changes.datesFormArray) {
			if (this.editIndex !== null && this.editIndex < changes.datesFormArray?.previousValue?.controls?.length) {
				const previousControl = changes.datesFormArray.previousValue.controls[this.editIndex] as FormControl<IBuildAccountingOperationDate>;
				if (previousControl.value.accountingProcessOperationDateId) {
					this.datesFormArray.controls[this.editIndex].setValue(previousControl.value, { emitEvent: false });
				} else {
					this.datesFormArray.insert(this.editIndex, previousControl, { emitEvent: false });
				}
			}

			if (changes.datesFormArray.currentValue?.controls?.length === 0
				&& changes.datesFormArray.previousValue?.controls?.length > 0) {
				this.addDate(false);
			}
		}

		if (changes.operationTriggers) {
			if (!isEqual(changes.operationTriggers.previousValue, changes.operationTriggers.currentValue)) {
				this.operationTriggers = changes.operationTriggers.currentValue as ICreateTrigger[];
			}
		}
	}

	addDate(emitEvent: boolean = true) {
		const control = new FormControl<IBuildAccountingOperationDate>(({} as IBuildAccountingOperationDate));
		const nextIndex = this.datesFormArray.length;
		this.datesFormArray.insert(nextIndex, control, { emitEvent });
		this.editDate(nextIndex);
	}

	editDate(index: number) {
		this.editIndex = index;
	}

	updateDate(date: IBuildAccountingOperationDate) {
		this.dateControls[this.editIndex].setValue(date);
		this.editIndex = null;
	}

	removeDate(index: number, confirmDeleteDialog: TemplateRef<unknown>) {
		const sub = this._promptService.confirm("Confirmation", confirmDeleteDialog).pipe(
			filter(confirm => !!confirm),
			switchMap(() => {
				const dateControlValue = this.datesFormArray.value[index];
				const dateId = dateControlValue.accountingProcessOperationDateId;
				return this._accountingProcessesService.deleteAccountingProcessOperationDate(dateId)
			})
		).subscribe(() => {
			this.datesFormArray.removeAt(index);
			this._growlerService.success().growl("Date deleted successfully");
		});

		this._subs.push(sub);
	}

	cancelEdit(index: number) {
		this.editIndex = null;
		const isNewDate = !this.datesFormArray.controls[index].value.accountingProcessOperationDateId;

		if (isNewDate) {
			this.datesFormArray.removeAt(index, { emitEvent: false });
		}
	}

	private updateAnchorTemplateDates() {
		if (this.anchorTemplate && this.anchorTemplate.templateID !== undefined) {
			const templateId = this.anchorTemplate.templateID;

			const keys = Object.keys(this.anchorTemplatesDates);
			if (keys.includes(String(templateId))) {
				// Remove transaction date
				this.anchorTemplateDates = [...this.anchorTemplatesDates[templateId]].filter(d => d.systemIndicator !== SystemIndicators.TransactionDate);
				// Remove any date cmds configured as multiple
				this.anchorTemplateDefaultDates = Object.values(this.anchorTemplateDates).filter(d => d.multipleIndicator !== MultipleIndicator.Multiple);

				this.datesDictionary = this.anchorTemplateDates.reduce((dict, date) => {
					dict[date.characteristicID] = date.label;
					return dict;
				}, {} as { [key: number]: string });
			}
		}
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
