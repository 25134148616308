import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectAvatarComponent } from "./select-avatar/select-avatar.component";

@Component({
    selector: "rl-select-avatar-modal",
    templateUrl: "./select-avatar-modal.component.html",
    styleUrls: ["./select-avatar-modal.component.scss"],
    imports: [SelectAvatarComponent]
})
export class SelectAvatarModalComponent implements OnInit {


	@Output()
	onSave = new EventEmitter<string>();

	constructor(private readonly _modal: NgbActiveModal) { }

	ngOnInit() {
	}

	emitSave(avatar: string) {
		this.onSave.emit(avatar);
	}

	close() {
		this._modal.close();
	}

}
