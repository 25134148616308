<div class="modal-body" *ngIf="modalLoaded; else isLoading" [@fadeIn]>
	<div class="modal-header">
		<h2 class="modal-title">{{ isCreate ? 'Add Party to' : 'Edit Party on' }} {{charTypeName$ | async }} >
			{{templateName$ | async}} > {{groupName}}</h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeEditParty">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)">
		<div class="modal-body">
			<div class="char-data__group">
				<div class="char-data__group__element">
					<div class="char-data__label col-form-label">
						<span>Source Party</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-dropdown-single [(formControl)]="form.controls.sourceParty"
								(onValueChange)="updateTemplatePartyName($event)" [options]="allParties"
								[showEmptyOption]="true" [dataLabel]="'selectDropdownSourceParty'">
							</rl-dropdown-single>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="form.controls.templatePartyName">
					<div class="char-data__label col-form-label">
						<span>Template Party Name</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-text-input [formControl]="form.controls.templatePartyName"
								[dataLabel]="'textInputTemplatePartyName'"></rl-text-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label col-form-label">
						<span>Party Section</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-dropdown-single [(formControl)]="form.controls.partyGroup"
								(onValueChange)="updateGroupName($event)" [options]="groups" [showEmptyOption]="true"
								[dataLabel]="'selectDropdownPartySection'">
							</rl-dropdown-single>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label col-form-label">
						<span>Required</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.required"
								[dataLabel]="'checkboxInputRequiredYes'" class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label col-form-label">
						<span>Editable</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.editable"
								[dataLabel]="'checkboxInputEditableYes'" class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label col-form-label">
						<span>Deletable</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.deletable"
								[dataLabel]="'checkboxInputDeletableYes'" class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label col-form-label">
						<span>Can be Multiple</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<rl-checkbox-input [label]="'Yes'" [formControl]="form.controls.multiple"
								[dataLabel]="'checkboxInputMultipleYes'" class="d-block py-1"></rl-checkbox-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="form.controls.validContactTemplates">
					<div class="char-data__label col-form-label">
						<span>Valid Contact Templates</span>
					</div>
					<div class="char-data__control container m-0 w-75">
						<div class="char-data-element-display">
							<label class="ps-1px py-2">
								<input type="checkbox" [formControl]="form.controls.toggleValidContactTemplates"
									class="form-check-input me-2" (change)="toggleContactTemplates($event)"
									data-cy="checkboxInputTemplatesCheckAll" />
								Select All
							</label>
							<div class="scroll-vert border-top py-2 padding-left-1px">
								<div *ngFor="let template of contactTemplates; let i = index"
									class="char-data-element-display">
									<label class="py-1">
										<input type="checkbox"
											[formControl]="form.get('validContactTemplates')?.controls[template.templateName]"
											[value]="template.templateId" class="form-check-input me-2"
											[attr.data-cy]="'checkboxInputRequired' + template.templateName | trimWhitespace" />
										{{ template.templateName }}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelTemplateParty">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveTemplateParty"><i *ngIf="isSaving" class="far fa-spinner fa-spin me-1"></i>Save</button>
		</div>
	</form>
</div>

<ng-template #primaryPartyPrompt let-primaryPartyPrompt="prompt">
	<div>
		<p>
			Setting a Party Primary Indicator to 'Yes' will set any other Party Association's primary indicator to
			'No'.<br />A reindex will also occur which could take several minutes. <b>Are you sure you want to
				continue</b>?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="primaryPartyPrompt.onConfirm.next(false)" data-cy="deleteCancel">
			No
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="primaryPartyPrompt.onConfirm.next(true)"
			data-cy="deleteConfirm">
			Yes
		</button>
	</div>
</ng-template>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>