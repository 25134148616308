import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { AfterViewInit, Component, Injectable, Injector, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { ConfigModalService } from "config/services/config-modal.service";
import _, { every } from "lodash";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { ICharTemplateDataCountRequest } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { SearchService } from "rl-common/services/search/search.service";
import { TokenStorageService } from "rl-common/services/token-storage/token-storage.service";
import { Observable, of, Subscription } from "rxjs";
import { concatMap, debounceTime, filter, map, switchMap, take, tap } from "rxjs/operators";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { NewTabInModalDirective } from "../../../../common/directives/new-tab-in-modal.directive";
import { CharDataTypeNamePipe } from "../../../../common/pipes/char-data-type-name.pipe";
import { SortByPipe } from "../../../../common/pipes/sortBy.pipe";
import { TrimWhitespacePipe } from "../../../../common/pipes/trim-whitespace.pipe";
import { VisibilityIndicatorPipe } from "../../../../common/pipes/visibility-indicator-name.pipe";
import { MultipleIndicatorNamePipe } from "../../../pipes/multiple-indicator-name.pipe";
import { RequiredIndicatorNamePipe } from "../../../pipes/required-indicator-name.pipe";
import { VisibilityIndicatorNamePipe } from "../../../pipes/visibility-indicator-name.pipe";
import { TemplateLandingService } from "../templates-landing/templates-landing.service";

// WHAT IS GOING ON?
@Injectable({ providedIn: "root" })
@Component({
	selector: "rl-template-fields",
	templateUrl: "./template-fields.component.html",
	styleUrls: ["./template-fields.component.scss"],
	imports: [NgbAccordionDirective, NgFor, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, CdkDropList, CdkDrag, CdkDragHandle, NewTabInModalDirective, RouterLink, NgIf, LoaderComponent, CharDataTypeNamePipe, SortByPipe, VisibilityIndicatorPipe, TrimWhitespacePipe, AsyncPipe, VisibilityIndicatorNamePipe, RequiredIndicatorNamePipe, MultipleIndicatorNamePipe]
})
export class TemplateFieldsComponent implements OnInit, OnDestroy, AfterViewInit {
	templateMetaData$: Observable<ICharacteristicMetaDataCollection>;
	groups: ITemplateCharacteristicGroup[];
	groupChars: _.Dictionary<ICharacteristicMetaData[]> = {};
	charTypeId$: Observable<number>;
	templateId$: Observable<number>;
	templateName$: Observable<string>;
	charTypeName$: Observable<string>;

	sortField = "sequenceNumber";
	sortDir: "asc" | "desc" = "asc";
	charTypeId: number;
	templateId: number;
	templateName: string;
	targetNumFound: number;
	charFieldName: string;
	oneConfigRefreshing = false;

	_subs: Subscription[] = [];

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	@ViewChildren("item")
	accordionItemsQueryList: QueryList<NgbAccordionItem>;

	accordionItems: NgbAccordionItem[] = [];

	get isAllCollapsed() {
		return every(this.accordionItems, item => item.collapsed);
	}

	constructor(
		private readonly _templateService: TemplateLandingService,
		private readonly _searchService: SearchService,
		private readonly _promptService: PromptService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _tokenStorageService: TokenStorageService,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _growlerService: GrowlerService,
		private readonly _modalService: ConfigModalService,
		private readonly _injector: Injector
	) { }

	ngOnInit() {
		this.init();
	}

	ngAfterViewInit(): void {
		this.accordionItems = this.accordionItemsQueryList.toArray();
	}

	init() {
		this.mapObs();
		this.buildCmdGroups();
		this.buildCharGroups();

		const sub = this._templateService.templateMetaData$.pipe(
			take(1),
			tap(result => {
				this.charTypeId = result.charTypeID;
				this.templateId = result.templateID;
				this.templateName = result.template.templateName;
			})
		).subscribe();

		this._subs.push(sub);
	}

	private mapObs() {
		this.templateName$ = this._templateService.templateMetaData$.pipe(map(cmd => cmd.template.templateName));
		this.templateId$ = this._templateService.charTypeTemplateIds$.pipe(
			map(([charTypeId, templateId]) => templateId)
		);
		this.charTypeId$ = this._templateService.charTypeTemplateIds$.pipe(
			map(([charTypeId, templateId]) => charTypeId)
		);
		this.charTypeName$ = this._templateService.charData$.pipe(map(cmd => cmd[0].charTypeName));
	}

	private buildCmdGroups() {
		this.templateMetaData$ = this._templateService.templateMetaData$.pipe(map(cmd => cmd));
		const sub = this._templateService.templateCharacteristicMetaDatas$.subscribe((cmds) => {
			this._templateService.characteristicMetaDatas = cmds;
		});

		const sub2 = this._templateService.templateGroups$.subscribe((cmds) => {
			this.groups = cmds;
		});

		this._subs.push(sub, sub2);
	}


	private buildCharGroups() {
		const characteristicMetaDatas = this._templateService.characteristicMetaDatas ?? [];
		this.groupChars = characteristicMetaDatas.reduce((acc, char) => {
			const targetChars: ICharacteristicMetaData[] = characteristicMetaDatas.filter(cmd => cmd.groupID === char.groupID);
			acc[char.groupID] = _.sortBy(targetChars, c => c.sequenceNumber);
			return acc;
		}, {});
	}

	filterCharsByGroup(chars: ICharacteristicMetaData[], groupId: number) {
		return chars.filter(x => x.groupID === groupId).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
	}

	drop(event: CdkDragDrop<string[]>, groupId: number) {
		if (event.previousIndex === event.currentIndex) {
			return;
		}
		moveItemInArray(this.groupChars[groupId], event.previousIndex, event.currentIndex);
		const orderedCharIds = Object.values(this.groupChars[groupId]).map(gc => gc.characteristicID);
		const sub = this._entityConfigService.updateCharacteristicSequenceOrder(this.charTypeId, this.templateId, groupId, orderedCharIds).pipe(
			debounceTime(200)
		).subscribe();

		this._subs.push(sub);
	}

	addOrEditField(group: ITemplateCharacteristicGroup, templateFieldModel: ICharacteristicMetaData = null) {
		if (this.oneConfigRefreshing) {
			this._growlerService.info().growl("Cannot edit field while configuration is refreshing...");
			return;
		}
		const sub = this._modalService.addTemplateField(group, templateFieldModel, this.templateId, this._injector).pipe(
			filter(event => !!event),
			switchMap(event => of(event)),
			tap(() => this.oneConfigRefreshing = true),
			concatMap(() => this._oneConfigService.refresh(this._tokenStorageService.jwt)),
			switchMap(() => this._entityConfigService.getTemplateMetaData(this.charTypeId, this.templateId)),
			tap(metaData => {
				this._templateService.characteristicMetaDatas = [...metaData.characteristicMetaDatas];
				this.buildCharGroups();
				this._subs.forEach(s => s.unsubscribe());
				this.oneConfigRefreshing = false;
			})
		).subscribe();

		this._subs.push(sub);
	}

	deleteField(char: ICharacteristicMetaData, template: TemplateRef<unknown>) {
		this.charFieldName = char.label;
		const model: ICharTemplateDataCountRequest = {
			charTypeId: this.charTypeId,
			templateId: this.templateId,
			characteristicId: char.characteristicID
		};
		const sub = this._entityConfigService.getCharacteristicTemplateDataCount(model).pipe(
			switchMap(results => {
				const title = `Delete Field: ${this.charFieldName}`;
				this.targetNumFound = results;
				return this._promptService.confirm(title, template).pipe(
					filter(confirmDelete => confirmDelete),
					tap(() => this._growlerService.info().growl("Deleting..."))
				);
			}),
			switchMap(() => this._entityConfigService.deleteCharTemplateAssociation(this.charTypeId, this.templateId, char.characteristicID)),
			concatMap(() => this._oneConfigService.refresh(this._tokenStorageService.jwt)),
			switchMap(() => this._entityConfigService.getTemplateMetaData(this.charTypeId, this.templateId)),
			tap(metaData => {
				this._templateService.characteristicMetaDatas = [...metaData.characteristicMetaDatas];
				this.buildCharGroups();
			})
		).subscribe(() => this._growlerService.success().growl("Field deleted successfully"));

		this._subs.push(sub);
	}

	includeOnCopy(copyIndicator: number) {
		return copyIndicator === 1;
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}
}
