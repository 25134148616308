import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISecurityPolicy, IUserRole } from 'rl-common/services/company/company.models';
import { CompanyService } from 'rl-common/services/company/company.service';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'rl-add-edit-role-modal',
    templateUrl: './add-edit-role-modal.component.html',
    styleUrls: ['./add-edit-role-modal.component.scss'],
    imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class AddEditRoleModalComponent implements OnInit {
	@Input()
	policies: ISecurityPolicy[];

	@Input()
	role: IUserRole;

	@Input()
	existingRoles: string[];

	@Input()
	isCopy: boolean = false;

	@Output()
	onComplete = new EventEmitter<boolean>();

	isCreate = false;
	action = "Edit"
	buttonText = "Save"
	roleName = new UntypedFormControl("", [Validators.required]);
	roleXref = new UntypedFormControl("");
	roleActivityPolicy = new UntypedFormControl("");
	roleDataPolicy = new UntypedFormControl("");
	activityPolicies: ISecurityPolicy[];
	dataPolicies: ISecurityPolicy[];
	isSaving = false;
	isDuplicate = false;

	formGroup = new UntypedFormGroup({
		roleName: this.roleName,
		roleXref: this.roleXref,
		roleActivityPolicy: this.roleActivityPolicy,
		roleDataPolicy: this.roleDataPolicy
	});

	constructor(private readonly _modal: NgbActiveModal, private readonly _companyService: CompanyService) { }

	ngOnInit(): void {
		this.activityPolicies = this.policies.filter(x => x.policyType !== 1);
		this.dataPolicies = this.policies.filter(x => x.policyType === 1);
		if (this.isCopy) {
			this.formGroup.markAsDirty();
		}
		if (!this.role) {
			this.isCreate = true;
			this.action = "Create New ";
			this.buttonText = "Create";
		}
		else {
			this.roleName.setValue(this.role.name);
			this.roleXref.setValue(this.role.xref);
			this.roleActivityPolicy.setValue(this.role.activitySecurityPolicyID);
			this.roleDataPolicy.setValue(this.role.dataSecurityPolicyID);
		}
	}

	cancel() {
		this._modal.close();
	}

	canSave() {
		return this.formGroup.valid && this.formGroup.dirty && !this.isDuplicate && !this.isSaving;
	}

	checkForError() {
		if (this.isDuplicate) {
			this.isDuplicate = false;
		}
	}

	save() {
		this.isSaving = true;
		this.isDuplicate = false;
		if (this.role?.name.toLowerCase().trim() !== this.roleName.value.toLowerCase().trim()) {
			const lowerCaseExistingRoles = this.existingRoles.map(v => v.toLowerCase().trim());
			if (lowerCaseExistingRoles.indexOf(this.roleName.value.toLowerCase().trim()) > 0) {
				this.isDuplicate = true;
				this.isSaving = false;
			}
		}
		if (!this.isDuplicate) {
			if (this.isCreate || this.isCopy) {
				this._companyService.createRole(this.roleName.value, this.roleActivityPolicy.value, this.roleDataPolicy.value, this.roleXref.value).subscribe(() => {
					this.onComplete.emit();
					this.isSaving = false;
				});
			} else {
				this._companyService.updateRole(this.role.roleID, this.roleName.value, this.roleActivityPolicy.value, this.roleDataPolicy.value, this.roleXref.value).subscribe(() => {
					this.onComplete.emit();
					this.isSaving = false;
				});
			}
		}
	}
}
