import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { isArray, isEmpty } from "lodash";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridLayoutOptions } from "rl-common/components/grid/models/grid-layout-options";
import { JobService } from "rl-common/services/job.service";
import { ITrackedJobDetail } from "rl-common/services/job.service.models";
import { ProgressService } from "rl-common/services/progress.service";
import { interval, Subscription } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { BusyButtonDirective } from "../../../directives/busy-button.directive";
import { SafePipe } from "../../../pipes/safe.pipe";
import { CellTemplateDirective } from "../../grid/directives/cell-template.directive";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";


interface FileDownload {
	url: string;
	filename: string
}

interface FileDownloadXlsx {
	xlsxUrl: string;
	xlsxFilename: string
}

interface FileDownloadCsv {
	csvUrl: string;
	csvFilename: string
}

interface ResultsDataFiles { files: FileDownload[] }

type ResultData = FileDownload | ResultsDataFiles | FileDownloadXlsx | FileDownloadCsv | (FileDownloadXlsx & FileDownloadCsv);

function getFDs(resultData: ResultData): FileDownload[] {
	let output: FileDownload[] = [];
	if (!resultData) {
		return output;
	}

	const files = (resultData as ResultsDataFiles).files;
	if (isArray(files)) {
		for (const item of files) {
			output = [...output, ...getFDs(item)];
		}
	} else {

		const fd1 = resultData as FileDownload;
		const fd2 = resultData as FileDownloadXlsx;
		const fd3 = resultData as FileDownloadCsv;

		if (!isEmpty(fd1.filename) && !isEmpty(fd1.url)) {
			output.push({
				filename: fd1.filename,
				url: fd1.url
			});
		}
		if (!isEmpty(fd2.xlsxFilename) && !isEmpty(fd2.xlsxUrl)) {
			output.push({
				filename: fd2.xlsxFilename,
				url: fd2.xlsxUrl
			});
		}
		if (!isEmpty(fd3.csvFilename) && !isEmpty(fd3.csvUrl)) {
			output.push({
				filename: fd3.csvFilename,
				url: fd3.csvUrl
			});
		}
	}

	return output;
}


@Component({
    selector: "rl-job-manager-modal",
    templateUrl: "./job-manager-modal.component.html",
    styleUrls: ["./job-manager-modal.component.scss"],
    imports: [GridTableComponent, CellTemplateDirective, NgSwitch, NgSwitchCase, NgIf, BusyButtonDirective, SafePipe]
})
export class JobManagerModalComponent implements OnInit, OnDestroy {
	@Output()
	onComplete = new EventEmitter<void>();

	layoutOptions: GridLayoutOptions = {
		showProgress: false
	};

	dataSource: CommonGridDataSource<ITrackedJobDetail>;
	columns: GridColumn<ITrackedJobDetail>[] = [
		{
			key: "created_at",
			headerName: "Created At",
			renderer: "date_time",
			width: "max-content",
			getCellData: (j) => j.createdAt,
			locked: true
		},
		{
			key: "name",
			headerName: "Name",
			renderer: "name_link",
			width: "2fr",
			getCellData: (j) => [j.jobName, j.reportUrl],
			locked: true
		},
		{
			key: "state",
			headerName: "State",
			renderer: "text",
			width: "1fr",
			getCellData: (j) => {
				switch (j.statusId) {
					case 1:
						return "Enqueued";
					case 2:
						return "In Progress";
					case 3:
						return "Done";
					case 4:
						return "Cancelled";
					case 5:
						return "Failed";
					default:
						return "";
				}
			},
			locked: true
		},
		{
			key: "progress",
			headerName: "Progress",
			renderer: "progress",
			width: "2fr",
			getCellData: (j) => {
				let percent = Math.floor((j.completed / j.total) * 100);
				if (isNaN(percent)) {
					percent = 0;
				}
				if (percent > 100) {
					percent = 100;
				}
				return [j.completed, j.total, percent, j.statusId];
			},
			locked: true
		},
		{
			key: "message",
			headerName: "Message",
			renderer: "text",
			width: "minmax(100px,300px)",
			getCellData: (j) => j.progressMessage,
			locked: true
		},
		{
			key: "results",
			headerName: "Result",
			renderer: "results",
			width: "max-content",
			getCellData: (j) => ({
				type: j.resultData?.["type"],
				files: getFDs(j.resultData as ResultData)
			}),
			locked: true
		},
		{
			key: "ops",
			headerName: "",
			renderer: "ops",
			width: "max-content",
			getCellData: (j) => j,
			locked: true
		}
	];

	private _subs: Subscription[] = [];

	constructor(private _jobService: JobService, private _gridDataSourceBuilder: GridDataSourceBuilder, private _progressService: ProgressService) { }

	ngOnInit(): void {

		this.dataSource = this._gridDataSourceBuilder.commonGridDataSource<ITrackedJobDetail>(t => t.jobId)
			.setColumns(this.columns)
			.withProgress(false)
			.withFetchFn(ds => {
				return this._jobService.listTrackedJobs()
					.pipe(map(jobs => {
						const count = jobs.length;
						jobs = jobs.slice(ds.rowOffset$.value, ds.rowOffset$.value + ds.pageSize$.value);
						return { rowCount: count, rowData: jobs } as IGridFetchResults<ITrackedJobDetail>;
					}));
			});

		const sub = interval(5_000)
			.pipe(
				startWith(1),
				switchMap(() => this.refresh$())
			)
			.subscribe();

		this._subs.push(sub);
	}

	private refresh$() {
		return this.dataSource.fetchRows();
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	close() {
		this.onComplete.emit();
	}

	async cancelJob(job: ITrackedJobDetail) {
		this._progressService.startProgress();
		if (window.confirm("Are you sure you want to cancel this report?")) {
			await this._jobService.cancelTrackedJob(job.jobId).toPromise();
		}
		this._progressService.endProgress();
	}

	async deleteJob(job: ITrackedJobDetail) {
		this._progressService.startProgress();
		if (window.confirm("Are you sure you want to delete this report?")) {
			await this._jobService.deleteTrackedJob(job.jobId).toPromise();
		}
		this._progressService.endProgress();
	}
}
