<rl-form-table-row>
	<ng-template rlFormTableLabel>Title</ng-template>
	<ng-template rlFormTableControl>
		<rl-text-input class="mb-4" [(ngModel)]="title" (ngModelChange)="updateParams()"
			[dataLabel]="'TitleInput'">
		</rl-text-input>
	</ng-template>
</rl-form-table-row>
<rl-form-table-row>
	<ng-template rlFormTableLabel>UDF Name</ng-template>
	<ng-template rlFormTableControl>
		<rl-text-input class="mb-4" [(ngModel)]="collectionName" (ngModelChange)="updateParams()"
			[dataLabel]="'CollectionName'">
		</rl-text-input>
	</ng-template>
</rl-form-table-row>