<div class="upload-profile-picture" [ngClass]="size">
	<button class="btn btn-link p-0 m-0 d-block upload-button" (click)="openUploadModal()">
		<div class="profile-pic">
			<rl-profile-picture [avatar]="session.profileAvatar$ | async" [userName]="session.userName$ | async"
				[size]="size">
			</rl-profile-picture>
		</div>
		<div class="upload-overlay">
			<div class="text">Select Avatar</div>
		</div>
	</button>
</div>