import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { DealService } from "rl-common/services/deal/deal.service";
import { TemplateService } from "rl-common/services/template/template.service";

@Component({
    selector: "rl-calculate-allocation-modal",
    templateUrl: "./calculate-allocation-modal.component.html",
    imports: [ReactiveFormsModule, FormsModule, NgFor]
})
export class CalculateAllocationModalComponent implements OnInit {

	@Input()
	dealId: string;

	@Output()
	onCalculate = new EventEmitter<string>();

	usedTemplates: ICharacteristicTemplate[] = [];
	allocationType = 0;
	selectedTemplates: number[] = [];

	get isEveryTemplateSelected() {
		return this.selectedTemplates.length > 0 && this.selectedTemplates.length === this.usedTemplates.length;
	}

	constructor(private activeModal: NgbActiveModal, private dealService: DealService, private templateService: TemplateService) { }

	ngOnInit() {
		this.templateService.getUsedTemplates(this.dealId, CharTypeId.Property).subscribe(templates => {
			this.usedTemplates = templates;
		});
	}

	calculate() {
		this.dealService.calculateAllocation(this.dealId, this.allocationType, this.selectedTemplates).subscribe(response => {
			this.onCalculate.emit(response.jobId);
		});
	}

	selectAllTemplates() {
		if (this.isEveryTemplateSelected) {
			this.selectedTemplates = [];
		} else {
			this.selectedTemplates = this.usedTemplates.map(x => x.templateID);
		}
	}

	selectTemplate(templateId: number) {
		if (this.selectedTemplates.indexOf(templateId) >= 0) {
			this.selectedTemplates = this.selectedTemplates.filter(x => x !== templateId);
		} else {
			this.selectedTemplates.push(templateId);
		}
	}

	public close() {
		this.activeModal.close(false);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}
}
