import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessToken } from "config/components/config-tools/comparison-tool/comparison-tool.models";
import { ToolsConfigService } from "config/components/config-tools/tools-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { of } from "rxjs";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";

@Component({
    selector: "rl-validate-config-comparison-access-token-modal",
    templateUrl: "./validate-config-comparison-access-token-modal.component.html",
    imports: [ReactiveFormsModule, FormsModule, NgIf]
})
export class ValidateConfigComparisonAccessTokenModalComponent implements OnInit {

	title: string = "Validate your temporary access token to the Configuration Comparison Tool";
	isLoading: boolean = false;
	accessToken: string;

	@Input()
	environmentName: string;

	@Output()
	onComplete = new EventEmitter<AccessToken>();

	constructor(private activeModal: NgbActiveModal, 
		private readonly _toolsConfigService: ToolsConfigService,
		private readonly growlerService: GrowlerService) { }

	ngOnInit() {
	}

	public decline() {
		this.onComplete.emit(null);
		this.activeModal.close();
	}

	public accept() {
		this.isLoading = true;
		this._toolsConfigService.validateAccessToken(this.accessToken).subscribe(s => {
			this.isLoading = false;
			if (s.isValid) {
				this.onComplete.emit(s.accessToken);
				this.activeModal.close();
			} else {
				this.growlerService.error().growl(s.errorMessage);
			}
		},(error) => {
			this.growlerService.error().growl(`User does not have access to generate API keys.`);
			this.isLoading = false;
			return of(error);
		});
	}

	public dismiss() {
		this.onComplete.emit(null);
		this.activeModal.close();
	}
}
