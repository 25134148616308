import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { INotification, INotificationResponse, NotificationSortColumn, NotificationSortDirection, NotificationStatus } from "rl-common/components/notifications/notification.models";
import { GrowlerService } from "rl-common/services/growler.service";
import { SessionService } from "rl-common/services/session.service";
import { StorageUtil } from "rl-common/services/storage.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Observable, of, Subscription } from "rxjs";
import { concatMap, filter, flatMap, map, switchMap } from "rxjs/operators";
import { NewTabInModalDirective } from "../../directives/new-tab-in-modal.directive";
import { HtmlToPlainPipe } from "../../pipes/html-to-plain.pipe";
import { GridDataSourceBuilder } from "../grid/datasource/builders/grid-datasource-builder";
import { IGridDataSelectStrategy } from "../grid/datasource/data-select/grid-data-select.strategy";
import { IGridFetchResults } from "../grid/datasource/grid-datasource.models";
import { SearchGridDataSource } from "../grid/datasource/search-grid.datasource";
import { CellTemplateDirective } from "../grid/directives/cell-template.directive";
import { GridRowHoverOpsDirective } from "../grid/directives/grid-row-hover-ops.directive";
import { GridTableComponent } from "../grid/grid-table/grid-table.component";
import { GridColumn } from "../grid/models/grid-column";
import { GridOptions } from "../grid/models/grid-options";
import { CharTypeId } from "./../../rl-common.consts";
import { LinkHelperService } from "./../../services/link-helper.service";
import { ModalBuilder } from "./../../services/modal-builder/modal-builder";
import { DateTimeFormatType, DateUtil } from "./../../utils/date.util";
import { NotificationModalComponent } from "./notification-modal/notification-modal.component";
import { NotificationService } from "./notification.service";

@Component({
    selector: "rl-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    imports: [NgIf, ReactiveFormsModule, FormsModule, NgFor, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem, GridTableComponent, CellTemplateDirective, NewTabInModalDirective, RouterLink, GridRowHoverOpsDirective, HtmlToPlainPipe]
})
export class NotificationsComponent implements OnInit, OnDestroy {

	@Input()
	displayHeader: boolean = false;

	defaultSortColumn = NotificationSortColumn.Created;
	public subs: Subscription[] = [];
	public pageSize = 10;
	public page = 1;
	public notificationDictionary: { [status: string]: string } = {
		R: "Read",
		S: "Unread"
	};
	public notifications$: Observable<INotificationResponse> = this._notificationService.notifications$;
	public selectedCharType = CharTypeId.All;
	public charTypeOptions = Object.keys(CharTypeIdUtil.CharTypeIdMetaLookup)
		.map(charTypeId => {
			const metaData = CharTypeIdUtil.CharTypeIdMetaLookup[charTypeId];
			return {
				label: metaData.displayNamePlural,
				value: charTypeId
			};
		});
	public selectedStatus = NotificationStatus.Unread;
	public statusOptions = [{
		label: "All Statuses",
		value: NotificationStatus.All
	}, {
		label: "Unread",
		value: NotificationStatus.Unread
	}, {
		label: "Read",
		value: NotificationStatus.Read
	}];
	private readonly columns: GridColumn<INotification>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "link_id",
			width: "max-content",
			getCellData: (not => not),
			sortKey: NotificationSortColumn.Id
		},
		{
			key: "title",
			headerName: "Title",
			renderer: "link_title",
			width: "min-content",
			getCellData: (not => not),
			sortKey: NotificationSortColumn.Title
		},
		{
			key: "date_sent",
			headerName: "Date Sent",
			renderer: "bold",
			width: "min-content",
			getCellData: (not => {
				const localizedDate = DateUtil.formatLocalizeAsDateTime(not.createdAt, DateTimeFormatType.ShortDateOrTodayDateTime);
				return this.getBold(not.status, localizedDate);
			}),
			sortKey: NotificationSortColumn.DateSent
		},
		{
			key: "subject",
			headerName: "Subject",
			renderer: "bold",
			width: "min-content",
			getCellData: (not => this.getBold(not.status, not.subject)),
			sortKey: NotificationSortColumn.Subject
		},
		{
			key: "body",
			headerName: "Body",
			renderer: "html",
			width: "auto",
			getCellData: (not => this.getBold(not.status, not.body)),
			sortKey: NotificationSortColumn.Body
		},
		{
			key: "status",
			headerName: "Status",
			renderer: "bold",
			width: "min-content",
			getCellData: (not => this.getBold(not.status, this.notificationDictionary[not.status])),
			sortKey: NotificationSortColumn.Status
		},
		{
			key: "date_read",
			headerName: "Date Read",
			renderer: "bold",
			width: "min-content",
			getCellData: (not => {
				const localizedDate = DateUtil.formatLocalizeAsDateTime(not.markedReadAt, DateTimeFormatType.ShortDateOrTodayDateTime);
				return this.getBold(not.status, localizedDate);
			}),
			sortKey: NotificationSortColumn.DateRead
		},
	];
	gridOptions: GridOptions<INotification> = {
	};

	@ViewChild(GridTableComponent, { static: true })
	grid: GridTableComponent;

	dataSource: SearchGridDataSource<INotification>;
	dataSelectStrategy: IGridDataSelectStrategy<INotification, number, INotification>;

	constructor(
		private readonly _notificationService: NotificationService,
		private readonly _growlerService: GrowlerService,
		public readonly linkHelper: LinkHelperService,
		private readonly _gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly _sessionService: SessionService,
		private readonly _modalBuilder: ModalBuilder,
		private readonly _router: Router,
	) {
	}

	private getBold(status, value) {
		return {
			isBold: status === NotificationStatus.Unread,
			value
		};
	}

	ngOnInit() {
		this.dataSelectStrategy = this._gridDataServiceBuilder.dataSelectStrategies.commonDataSelectStrategy<INotification, number, INotification>((rowData) => rowData.id, (rowData) => rowData);

		this.dataSource = this._gridDataServiceBuilder.searchGridDataSource<INotification>(row => row.id)
			.withDataSelectStrategy(this.dataSelectStrategy)
			// TODO: we need to figure out why this component is getting initialized multiple times
			// .withProgress(true)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: this.defaultSortColumn, sortDir: 0 })
			.setColumns(this.columns)
			.withFetchFn((ds) => {
				const sortDir = ds.sortDir$.value === 0 ? NotificationSortDirection.Descending : NotificationSortDirection.Ascending;
				const page = Math.floor(ds.rowOffset$.value / ds.pageSize$.value) + 1;
				return this._notificationService.getNotifications(page, ds.pageSize$.value, this.selectedCharType, this.selectedStatus, <string>ds.sortKey$.value, sortDir)
					.pipe(
						map((results) => <IGridFetchResults<INotification>>{ rowCount: results.numFound, rowData: results.data })
					);
			});
		this.reload();
	}

	isCommentNotification(notification: INotification) {
		return notification.subject.indexOf("tagged you in a comment") !== -1;
	}

	reload() {
		const sub = this.reload$().subscribe();
		this.subs.push(sub);
	}

	reload$() {
		this.dataSelectStrategy.deselectAll();
		return this.dataSource.fetchRows();
	}

	markAllAsRead() {
		const sub = this._notificationService.markAllAsRead(this.selectedCharType)
			.pipe(
				concatMap(() => this.reload$())
			)
			.subscribe((notificationsUpdatedCount) => {
				this._growlerService.success().growl(`${notificationsUpdatedCount} notifications updated.`);
			});
		this.subs.push(sub);
	}

	setSelectedRead() {
		this.updateStatus(NotificationStatus.Read);
	}

	setSelectedUnread() {
		this.updateStatus(NotificationStatus.Unread);
	}

	deleteSelected() {
		this.updateStatus(NotificationStatus.Deleted);
	}

	delete(rowData: INotification) {
		this.updateStatus(NotificationStatus.Deleted, [rowData.id]);
	}

	readNotification(rowData: INotification) {
		const sub = this._modalBuilder.build<NotificationModalComponent>(NotificationModalComponent)
			.size("sm")
			.open(comp => {
				comp.notification = rowData;
				return comp.markedAsRead;
			}).pipe(
				filter(markedAsRead => markedAsRead),
				flatMap(() => this.reload$())
			).subscribe();
		this.subs.push(sub);
	}

	markAsRead(rowData: INotification) {
		if (this.isCommentNotification(rowData)) {
			StorageUtil.openSidePanel(this._sessionService.userId, this._sessionService.divId);
		}
		this.updateStatus(NotificationStatus.Read, [rowData.id]);
	}

	updateStatus(status: NotificationStatus, ids: number[] = null) {
		const sub = this.getSelectedNotifications$(ids).pipe(
			switchMap(x => this._notificationService.setNotificationStatus(this.selectedCharType, x, status)),
			switchMap(x => this.reload$())
		).subscribe();
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	getSelectedNotifications$(ids: number[] = null) {
		if (ids) {
			return of(ids);
		}

		const state = this.dataSelectStrategy.selectedState;

		if (!state.isAllSelected) {
			return of(Array.from(state.selectedValues || []).map(x => x.id));
		}

		return this._notificationService.getNotifications(1, this.dataSource.rowCount$.value, this.selectedCharType, this.selectedStatus, this.defaultSortColumn, "asc")
			.pipe(
				map(results => results.data.filter(x => !state.deselectedIds.has(x.id)).map(x => x.id))
			);
	}
}
