import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { first } from "lodash";
import { AdvancedSearch } from "rl-common/components/advanced-search/rl-advanced-search.consts";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { DateLocaleType, DateUtil } from "rl-common/utils";
import { merge, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { TextModeDateInputComponent } from "../../../../text-mode-date-input/text-mode-date-input.component";

@Component({
	selector: "rl-single-value-input",
	templateUrl: "./single-value-input.component.html",
	styleUrls: ["./single-value-input.component.scss"],
	imports: [NgIf, NgSwitch, NgSwitchCase, TextModeDateInputComponent, ReactiveFormsModule, FormsModule, NgSwitchDefault, NgbTypeahead]
})
export class SingleValueInputComponent implements OnInit, OnChanges {

	value: string;

	typeaheadValue: AdvancedSearch.IAllowedValue | string;

	@Output()
	onChange = new EventEmitter<string>();

	@Output()
	onTypeAheadEnter = new EventEmitter();

	@Output()
	onEnter = new EventEmitter<string>();

	hasTypeahead = false;

	@ViewChild(NgbTypeahead)
	typeahead: NgbTypeahead;

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	solrDate = AdvancedSearch.SolrDataTypes.Date;
	solrDateTime = AdvancedSearch.SolrDataTypes.DateTime;

	@Input()
	values: string[];

	@Input()
	solrDataOption: AdvancedSearch.ISolrDataOption;

	get typeaheadHasSelection() {
		return !(typeof this.typeaheadValue === "string" || this.typeaheadValue instanceof String);
	}

	constructor() { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.solrDataOption && changes.solrDataOption.currentValue) {
			this.hasTypeahead = !!this.solrDataOption.searchFn$;
			if (this.hasTypeahead && this.typeahead) {
				this.value = "";
				this.typeahead.writeValue("");
			}
		}

		if (changes.values) {
			const value = this.values && first(this.values) || "";
			this.value = value;
			if (this.hasTypeahead) {
				this.typeaheadValue = { label: this.value, valueId: this.value };
			}
		}
	}

	searchValues = (text$: Observable<string>) => {
		const debounce$ = text$.pipe(debounceTime(100), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeahead || !this.typeahead.isPopupOpen()));
		return merge(debounce$, this.focus$, clicksWithClosedPopup$).pipe(
			switchMap(searchTerm => this.solrDataOption.searchFn$(searchTerm))
		);
	};

	formatter = (result: AdvancedSearch.IAllowedValue) => result.label;

	ngOnInit() { }

	emitDateChange() {
		let value = this.value;

		const modifiedValue = DateUtil.parseToMoment(this.value, DateLocaleType.Storage);
		if (modifiedValue.isValid()) {
			// characteristic dates are stored in solr in UTC, with no offset
			value = modifiedValue.utcOffset(0, true).toISOString();
		}

		this.onChange.emit(value);
	}

	emitDateTimeChange() {
		let value = this.value;

		const modifiedValue = DateUtil.parseToMoment(this.value, DateLocaleType.Storage);
		if (modifiedValue.isValid()) {
			value = modifiedValue.toISOString();
		}

		this.onChange.emit(value);
	}

	emitChange() {
		this.onChange.emit(this.value);
	}

	valueSelected(event: NgbTypeaheadSelectItemEvent) {
		const value = event.item as AdvancedSearch.IAllowedValue;
		this.value = value.valueId;
		this.emitChange();
	}

	backspacePressed(event) {
		// clear out the selected value if nothing is selected by the type ahead and there was something selected before
		if (!!this.value && this.typeaheadHasSelection && !this.solrDataOption.allowAnyValue) {
			this.value = null;
			this.emitChange();
			this.click$.next("");
		}
	}

	typeAheadEntered() {
		if (this.solrDataOption.allowAnyValue && !this.typeaheadHasSelection) {
			const value = this.typeaheadValue as string;
			const isValid = this.isValid(value);
			if (isValid) {
				this.value = value;
				this.emitChange();
			}
		}
	}

	private isValid(val: string) {
		const control = new FormControl<string>(val, this.solrDataOption.validators);
		return control.valid;
	}

	enterKeyPress() {
		this.onEnter.emit(this.value);
	}
}
