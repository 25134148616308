import { EventEmitter, Injectable, Type } from "@angular/core";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
import { AvailsSnapshot } from "../rights-explorer.models";
export interface IAvailsBulkCreateRightsComponent {
	avails: AvailsSnapshot[];
	onComplete: EventEmitter<boolean>;
}

@Injectable({ providedIn: "root" })
export class AvailsBulkCreateRightsModalAdapter extends ModalAdapter<IAvailsBulkCreateRightsComponent> {
	constructor(type: Type<IAvailsBulkCreateRightsComponent>) {
		super(type);
	}

}


