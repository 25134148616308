<div class="d-flex">
	<ng-container *ngIf="!solrDataOption.allowMultipleSelections;else multiInput">
		<rl-single-value-input [solrDataOption]="solrDataOption" (onChange)="addValue($event)">
		</rl-single-value-input>
	</ng-container>
	<div class="flex-grow-1 ms-2">
		<rl-chip *ngFor="let value of values; let i = index" (onRemove)="removeValue(i)">
			{{value}}
		</rl-chip>
	</div>
</div>

<ng-template #multiInput>
	<rl-single-value-input [solrDataOption]="solrDataOption" (onChange)="workingValue = $event"
		[values]="[workingValue]" (onEnter)="addWorkingValue()">
	</rl-single-value-input>
	<button type="button" class="btn btn-sm btn-outline" (click)="addWorkingValue()" [disabled]="!isValid(workingValue)"
		title="Add Working Value">
		<i class="far fa-plus"></i>
	</button>
</ng-template>