import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessToken } from "config/components/config-tools/comparison-tool/comparison-tool.models";
import { ToolsConfigService } from "config/components/config-tools/tools-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { of } from "rxjs";
import { NgIf } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@Component({
    selector: "rl-grant-config-comparison-access-modal",
    templateUrl: "./grant-config-comparison-access-modal.component.html",
    imports: [NgIf, ReactiveFormsModule, FormsModule]
})
export class GrantConfigComparisonAccessModalComponent implements OnInit {

	title: string = "Grant your user temporary access to the Configuration Comparison Tool";
	isLoading: boolean = false;
	accessToken: string = null;
	isCopied: boolean = false;
	
	@Input()
	environmentName: string;

	@Input()
	expirationHours: number;

	@Output()
	onComplete = new EventEmitter();

	constructor(private activeModal: NgbActiveModal, 
		private readonly _toolsConfigService: ToolsConfigService,
		private readonly growlerService: GrowlerService) { }

	ngOnInit() {
	}

	public decline() {
		this.onComplete.emit();
	}

	public accept() {
		this.isLoading = true;
		this._toolsConfigService.getAccessToken().subscribe(s => {
			if (s) {
				this.copyToClipboard(s);
				this.accessToken = s;	
			} else {
				this.growlerService.error().growl(`User does not have access to generate API keys.`);
			}
			
			this.isLoading = false;
		});
	}

	public dismiss() {
		this.onComplete.emit();
	}

	copyToClipboard(accessToken: string) {
		this.isCopied = true;
		navigator.clipboard.writeText(accessToken);
	}
}
