import { EventEmitter, Injectable, Type } from "@angular/core";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
import { IAddContactAccountsResult } from "./models/i-add-contact-accounts-result";
export interface IAddContactAccountsModalComponent {
	existingContacts: IAccountingContact[];
	onComplete: EventEmitter<IAddContactAccountsResult>;
}

@Injectable({ providedIn: "root" })
export class AddContactAccountsModalAdapter extends ModalAdapter<IAddContactAccountsModalComponent> {
	constructor(type: Type<IAddContactAccountsModalComponent>) {
		super(type);
	}

}


