<div class="admin">
	<div class="admin__header align-self-center d-flex justify-content-between">
		<h1><a [routerLink]="['/intelligence-center/']" data-cy="ConfigHeaderHome">Intelligence Center</a></h1>
		<div class="admin__nav">
			<nav subheader class="nav__section">
				<ul class="list-unstyled">
					<li>
						<button class="btn btn-sm btn-link" [routerLink]="['/intelligence-center/extract-pdf']"
								routerLinkActive="active" data-cy="IntelligentDealExtraction">
							Intelligent Deal
							Extraction
						</button>
					</li>
					<li>
						<button class="btn btn-sm btn-link" [routerLink]="['/intelligence-center/deal-insights']"
								routerLinkActive="active" data-cy="DealInsights">
							Deal Insights
						</button>
					</li>
					<li>
						<button class="btn btn-sm btn-link"
								[routerLink]="['/intelligence-center/intelligent-extract-hints']" routerLinkActive="active"
								data-cy="Hints">
							Hints
						</button>
					</li>
					<li>
						<button class="btn btn-sm btn-link" [routerLink]="['/intelligence-center/ai-model-information']"
								routerLinkActive="active" data-cy="AiModelInformation">
							AI Model Information
						</button>
					</li>
				</ul>
			</nav>
		</div>
	</div>

	<div class="admin__body">
		<router-outlet></router-outlet>
	</div>

	<rl-footer class="admin-footer"></rl-footer>
</div>