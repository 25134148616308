import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, DestroyRef, effect, EventEmitter, Injector, Input, OnChanges, OnInit, Output, Provider } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { WorksheetType, IWorksheet, IWorksheetRecords } from "../../../../../../services/clipboard/clipboard.models";
import { ClipboardService } from "../../../../../../services/clipboard/clipboard.service";
import { GrowlerService } from "../../../../../../services/growler.service";
import { ProgressService } from "../../../../../../services/progress.service";
import { GridDataSourceBuilder } from "../../../../../grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "../../../../../grid/datasource/common-grid.datasource";
import { GridTableComponent } from "../../../../../grid/grid-table/grid-table.component";
import { EditWorksheetModalAdapter, IEditWorksheetComponent } from "./edit-worksheet.adapter";
import { QueryUtil } from "../../../../../../utils";
import { SearchFieldNames } from "../../../query.models";
import { CharTypeId } from "../../../../../../rl-common.consts";
import { ISearchRequestOptions } from "../../../../../../models/i-search-request-options";
import { map, tap } from "rxjs/operators";
import { SearchService } from "../../../../../../services/search/search.service";


@Component({
	selector: "rl-edit-worksheet",
	imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgIf, NgbDropdownButtonItem, NgbDropdownItem, GridTableComponent, NgSwitch, NgSwitchCase, ReactiveFormsModule, FormsModule, NgSwitchDefault, NgClass],
	templateUrl: "./edit-worksheet.component.html",
	styleUrl: "./edit-worksheet.component.scss"
})
export class EditWorksheetComponent implements OnInit, OnChanges, IEditWorksheetComponent { 

	@Input()
	items: number[] = [];

	@Input()
	charTypeId: number;

	@Input()
	clipboardType: WorksheetType;

	@Input()
	selectedSnapshot: IWorksheet<IWorksheetRecords>;

	@Output()
	onComplete = new EventEmitter<boolean>();

	dataSource: CommonGridDataSource<unknown>;

	clipboardTitle: string = "";
	clipboardCopyTitle: string = "";
	isSaving = false;
	get isModify() {
		return this.selectedSnapshot ? true : false;
	}

	constructor(private readonly _progressService: ProgressService,
		private readonly _clipboardService: ClipboardService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _injector: Injector,
		private readonly _destroyRef: DestroyRef,
		private readonly _growlerService: GrowlerService,
		private readonly _searchService: SearchService) {
	}

	get clipboardTypeName() {
		switch (this.clipboardType) {
			case WorksheetType.AvailsSnapshot: return "Avails";
			default: return "Not Supported";
		}
	}

	get hasSelections() {
		if (this.dataSource) {
			return this.dataSource.dataSelectStrategy.selectedState.selectedIds.size > 0 || this.dataSource.dataSelectStrategy.selectedState.isAllSelected;
		}
	}

	ngOnInit() {
		if (this.selectedSnapshot) {
			this.clipboardTitle = this.selectedSnapshot.title;
		}

		this.setDataSource();
	}

	ngOnChanges() {
		if (this.selectedSnapshot) {
			this.clipboardTitle = this.selectedSnapshot.title;
		}
		this.setDataSource();
	}

	public setDataSource() {
		if (this.items) {
			switch (this.clipboardType) {
				case WorksheetType.Records: {
					this.setRecordsDatasource();
					break;
				}
				default: {
					console.error("Unsupported WorksheetType");
					break;
				}
			}
		}
	}

	private setRecordsDatasource() {
		const dataSelectStrategy = this._gridDataSourceBuilder.dataSelectStrategies
			.searchDocDataSelectStrategy(row => row.recordID, row => row)
			.setSelectAllEnabled(false)

		const columnStrategy = this._gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy(this.charTypeId);

		this.dataSource = this._gridDataSourceBuilder.entitySearchDataSource(CharTypeId.Property)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: "recordid", sortDir: 0 })
			.withDataSelectStrategy(dataSelectStrategy)
			.withColumnStrategy(columnStrategy)
			.withFetchFn((ds) => {
				const start = ds.rowOffset$.value;
				const pageSize = ds.pageSize$.value;
				const sortKey = ds.sortKey$.value;
				const sortDir = ds.sortDir$.value;

				const query = QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, this.items);

				const options: ISearchRequestOptions = {
					rows: pageSize,
					start,
					sortDir,
					sortField: sortKey as string,
					facetFields: [],
					filterQueries: ds.buildFilterQueries(),
				};

				return this._searchService.search(this.charTypeId, ds.keywords, query, options).pipe(
					tap((results) => {
						ds.setFacetResults(results.facetResults)
							.setExtraGridColumnResults(results.extraGridColumnResults);
					}),
					map((results) => ({
						rowData: results.documents,
						rowCount: results.numFound,
						extraGridColumnResults: results.extraGridColumnResults,
						facetResults: results.facetResults
					}))
				);
			});

		effect(() => {
			this.dataSource.fetchRows()
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe();
		}, { injector: this._injector });
	}

	cancel() {
		this.onComplete.emit(false);
	}

	save() {
		this._progressService.startProgress();
		this.isSaving = true;
		// Create
		if (!this.selectedSnapshot) {
			const cb: IWorksheet<IWorksheetRecords> = {
				clipboardTypeId: this.clipboardType,
				title: this.clipboardTitle,
				data: {
					charTypeId: this.charTypeId,
					recordIds: this.items
				}
			}

			this._clipboardService.createRecordsWorksheet(cb)
				.subscribe((res) => {
					this._progressService.endProgress();
					this.isSaving = false;
					this._clipboardService.getWorksheets(this.clipboardType)
						.subscribe(() => {

						});
					this.onComplete.emit(true);
				});
		} else {
			//Save As (copy)
			if (this.clipboardCopyTitle) {
				this.selectedSnapshot.title = this.clipboardCopyTitle;
				const cb: IWorksheet<IWorksheetRecords> = {
					clipboardTypeId: this.clipboardType,
					title: this.clipboardCopyTitle,
					data: {
						charTypeId: 1,
						recordIds: this.items
					}
				}
				this._clipboardService.createAvailsWorksheet(cb)
					.subscribe((res) => {
						this.isSaving = false;
						this._progressService.endProgress();
						this._clipboardService.getWorksheets(this.clipboardType)
							.subscribe(() => {

							});
						this.onComplete.emit(true);
					});
				//Rename (update)
			} else {
				if (this.clipboardTitle !== this.selectedSnapshot.title) {
					this.selectedSnapshot.title = this.clipboardTitle;
				}
				this._clipboardService.updateRecordsWorksheet(this.selectedSnapshot)
					.subscribe(() => {
						this.isSaving = false;
						this._progressService.endProgress();
						this._clipboardService.getWorksheets(this.clipboardType)
							.subscribe((res) => {

							});
						this.onComplete.emit(true);
					});
			}
		}
	}

	removeSelectedItems() {
		const selectState = this.dataSource.dataSelectStrategy.selectedState;
		this.items = this.items.filter(x => Array.from(selectState.selectedIds).indexOf(x) == -1);

		// If editing
		if (this.selectedSnapshot) {
			this.selectedSnapshot.data.recordIds = this.items;
			this._progressService.startProgress();
			this._clipboardService.updateRecordsWorksheet(this.selectedSnapshot)
				.subscribe((res) => {
					this._progressService.endProgress();
					this.setDataSource();
				})
		}
	}
}

export const EDITWORKSHEET_MODAL_PROVIDER: Provider = {
	provide: EditWorksheetModalAdapter,
	useFactory: () => new EditWorksheetModalAdapter(EditWorksheetComponent)
};