import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "rl-gdpr-modal",
    templateUrl: "./gdpr-modal.component.html",
    styleUrls: ["./gdpr-modal.component.scss"]
})
export class GdprModalComponent implements OnInit {

	constructor(private readonly _modal: NgbActiveModal) { }

	ngOnInit() {
	}

	cancel() {
		this._modal.close();
	}
}
