@if (templateMetaData$ | async; as metaData) {
<div class="p-3 pt-2">
	<div class="d-flex justify-content-end mb-1">
		@if (isAllCollapsed) {
		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="accordion.expandAll()" data-cy="templateFieldsExpandAll">
			Expand All
		</button>
		} @else {
		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="accordion.collapseAll()" data-cy="templateFieldsCollapseAll">
			Collapse All
		</button>
		}
	</div>
	<div ngbAccordion #accordion="ngbAccordion">
		<div *ngFor="let group of metaData.groups | sortBy:sortDir:sortField" ngbAccordionItem="{{ group.groupID }}"
			[collapsed]="groupChars[group.groupID]?.length ? false : true" #item="ngbAccordionItem">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : !accordion.isExpanded(group.groupID.toString())}" ngbAccordionButton
					[attr.data-cy]="'templateFieldsToggle' + group.groupLabel | trimWhitespace">
					<span class="cursor-pointer">
						{{ group.groupLabel }} ({{groupChars[group.groupID]?.length ?? 0}})
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="accordion.isExpanded(group.groupID.toString()) ? 'Collapse' : 'Expand'"
						[attr.data-cy]="accordion.isExpanded(group.groupID.toString()) ? 'ToCollapse' : 'ToExpand'"
						[class.open]="accordion.isExpanded(group.groupID.toString())">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						<div class="p-3">
							<div class="panel__ops d-flex justify-content-end pb-1">
								<button class="btn btn-icon btn-success" title="Add Fields to Section"
									[attr.data-cy]="'addFieldsToSection' + group.groupLabel | trimWhitespace"
									(click)="addOrEditField(group, null)">
									<i class="far fa-plus"></i>
								</button>
							</div>
							<table class="table rl-table table-hover" cdkDropList
								(cdkDropListDropped)="drop($event, group.groupID)">
								<thead>
									<tr>
										<th class="ps-3">ID</th>
										<th class="text-nowrap">Field Name</th>
										<th class="text-nowrap">Tag Label</th>
										<th class="text-nowrap">Data Type</th>
										<th>Visibility</th>
										<th>Required</th>
										<th>Multiple</th>
										<th class="text-nowrap">Conditional Field</th>
										<th>Include on Copy</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr class="no-items-message">
										<td colspan="10" class="border-0">
											<div class="d-flex align-items-center justify-content-center pt-3">
												<i class="far fa-th-list"></i>
												<h5 class="mb-0 ms-3">No items found.</h5>
											</div>
										</td>
									</tr>
									@for (char of groupChars[group.groupID]; track char.characteristicID) {
									<tr cdkDrag cdkDragLockAxis="y" [cdkDragData]="char">
										<td class="text-nowrap">
											<span class="pt-1 drag__handle position-relative" cdkDragHandle
												data-cy="DragDropRow">
												<i class="far fa-ellipsis-v"></i>
											</span>
											{{char.characteristicID}}
										</td>
										<td>
											<a [routerLink]="[]" data-cy="editTemplateField"
												(click)="addOrEditField(group, char)">{{char.label}}</a>
										</td>
										<td>
											{{char.tagLabel}}
										</td>
										<td>
											{{char.dataTypeID | charDataTypeName}}
										</td>
										<td>
											{{char.visibilityIndicator | visibilityIndicatorName}}
										</td>
										<td>
											{{char.requiredIndicator | requiredIndicatorName}}
										</td>
										<td>
											{{char.multipleIndicator | multipleIndicatorName}}
										</td>
										<td>
											{{char.triggerCharID ? "Yes" : "No"}}
										</td>
										<td>
											{{includeOnCopy(char.copyIndicator)}}
										</td>
										<td class="p-1">
											<div class="hover-icons text-end">
												<button *ngIf="char.systemIndicator === 0"
													class="btn btn-icon btn-remove btn-sm me-1"
													(click)="deleteField(char, deleteCharacteristicPrompt)"
													title="Delete" data-cy="deleteField">
													<i class="far fa-trash-alt fa-sm"></i>
												</button>
											</div>
										</td>
									</tr>
									}
								</tbody>
							</table>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
	</div>


</div>
} @else {
<div class="p-4 text-center">
	<rl-loader></rl-loader>
</div>
}

<ng-template #deleteCharacteristicPrompt let-prompt="prompt">
	<div>
		<p>
			<strong>There {{targetNumFound === 1 ? 'is' : 'are'}} {{ targetNumFound }} {{ targetNumFound === 1 ?
				'record' :
				'records' }} associated with this field.</strong><br><br>
			<ng-container *ngIf="targetNumFound">Deleting the {{ charFieldName }} field will remove it and all of its
				data from the {{ templateName }} template.<br><br></ng-container>
			<strong>Are you sure you want to proceed?</strong>
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="cancelDeleteField">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)" data-cy="confirmDeleteField">
			Delete Field
		</button>
	</div>
</ng-template>