<div [hidden]="!dataSourceLoaded">
	<div *ngIf="availableFilters.length > 0" class="d-flex align-items-center justify-content-end my-2">
		<div *ngIf="availableFilters.includes('id')" class="d-flex align-items-center me-4">
			<label class="me-2">ID:</label>
			<input class="form-control w-auto" type="text" [formControl]="containsForm.controls.id">
		</div>
		<div *ngIf="availableFilters.includes('name')" class="d-flex align-items-center me-4">
			<label class="me-2">Name:</label>
			<input class="form-control w-auto" type="text" [formControl]="containsForm.controls.name">
		</div>
		<div *ngIf="availableFilters.includes('formattedName')" class="d-flex align-items-center me-4">
			<label class="text-nowrap me-2">Formatted Name:</label>
			<input class="form-control" type="text" [formControl]="containsForm.controls.formattedName">
		</div>
		<div *ngIf="availableFilters.includes('description')" class="d-flex align-items-center me-4">
			<label class="text-nowrap me-2">Description:</label>
			<input class="form-control" type="text" [formControl]="containsForm.controls.description">
		</div>
		<div *ngIf="availableFilters.includes('bigdescription')" class="d-flex align-items-center me-4">
			<label class="text-nowrap me-2">Big Description:</label>
			<input class="form-control" type="text" [formControl]="containsForm.controls.bigdescription">
		</div>
		<button class="btn btn-info btn-sm" (click)="runSearch()" [disabled]="containsForm.pristine">Filter</button>
	</div>
	<rl-grid [options]="gridOptions" [dataSource]="dataSource">
	</rl-grid>
</div>
<div [hidden]="dataSourceLoaded" class="p-3 text-center">
	<rl-loader></rl-loader>
</div>
<div class="border-top my-3 pt-3">
	<h4>Selected {{ fieldDetail._singularLabel }}</h4>
	<div *ngIf="selectedValues.length === 0">
		No {{ fieldDetail._singularLabel }} selected.
	</div>
	<div class="all-chips">
		<rl-chip *ngFor="let val of selectedValues" (onRemove)="remove(val)">
		{{ val.displayName }}
	</rl-chip>
	</div>
</div>