import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { AccountService } from "rl-common/services/account/account.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { GoogleAppStoreBadgeComponent } from "../../badges/google-app-store-badge/google-app-store-badge.component";
import { AppleAppStoreBadgeComponent } from "../../badges/apple-app-store-badge/apple-app-store-badge.component";
import { NgIf } from "@angular/common";
import { FaIconDirective } from "../../../directives/fa-icon.directive";

@Component({
    selector: "rl-google-authenticator-modal",
    templateUrl: "./google-authenticator-modal.component.html",
    styleUrls: ["./google-authenticator-modal.component.scss"],
    imports: [GoogleAppStoreBadgeComponent, AppleAppStoreBadgeComponent, ReactiveFormsModule, NgIf, FaIconDirective]
})
export class GoogleAuthenticatorModalComponent implements OnInit, OnDestroy {
	@Input()
	username: string;

	@Input()
	otpUrl: string;

	@Input()
	secretKey: string;

	@Input()
	isGoogleAuthenticatorEnabled = false;

	@Output()
	googleAuthenticatorSetupComplete = new EventEmitter<boolean>();

	isVerifying = false;
	public googleAuthForm: UntypedFormGroup;
	public code: UntypedFormControl;
	private codeRegex = /\d{6}/;

	private readonly _subscriptions: Subscription[] = [];

	constructor(private readonly _accountService: AccountService,
		private _formBuilder: UntypedFormBuilder,
		private readonly _growlerService: GrowlerService) {
	}

	ngOnInit() {
		this.code = this._formBuilder.control("", [Validators.required, Validators.pattern(this.codeRegex)]);
		this.googleAuthForm = new UntypedFormGroup({
			code: this.code
		});
	}

	verifyCode() {
		if (this.googleAuthForm.invalid) {
			return;
		}
		this.isVerifying = true;
		const sub = this._accountService.confirmGoogleAuthenticatorKey(this.secretKey, this.code.value).pipe(
			finalize(() => this.isVerifying = false)
		).subscribe((result: any) => {
			if (result.success) {
				this.isGoogleAuthenticatorEnabled = true;
				this._growlerService.success().growl("Google Authenticator Verified");
				this.googleAuthenticatorSetupComplete.emit(true);
			} else {
				this._growlerService.error().growl(result.message);
			}
		});

		this._subscriptions.push(sub);
	}

	cancel() {
		this.googleAuthenticatorSetupComplete.emit(false);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
