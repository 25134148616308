import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, drop, isEmpty, take } from "lodash";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { CommonDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/common-data-select.strategy";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { ISourceField, ISourceFieldSearchOptions } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { GridTableComponent } from "../../../../../common/components/grid/grid-table/grid-table.component";
import { SearchTipsComponent } from "../../../../../common/components/search-form/search-tips/search-tips.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";

@Component({
    selector: "rl-source-field-modal",
    templateUrl: "./source-fields-modal.component.html",
    styleUrls: ["./source-fields-modal.component.scss"],
    imports: [TextInputComponent, ReactiveFormsModule, FormsModule, NgbDropdown, NgbDropdownToggle, SearchTipsComponent, NgbDropdownMenu, GridTableComponent]
})
export class SelectSourceFieldsModalComponent implements OnInit {

	@Input()
	charTypeId: number;

	@Input()
	options: ISourceFieldSearchOptions = { pageSize: 25 }

	@Output()
	onComplete = new EventEmitter<ISourceField[]>();

	private readonly _subs: Subscription[] = [];


	dataSelectStrategy: CommonDataSelectStrategy<ISourceField, number, ISourceField>
	dataSource: CommonGridDataSource<ISourceField>;
	sourceFields: ISourceField[];
	keywords: string;

	private readonly columns: GridColumn<ISourceField>[] = [
		{
			key: "sourceFieldID",
			headerName: "ID",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.sourceFieldID
		},
		{
			key: "sourceFieldName",
			headerName: "Name",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.sourceFieldName
		},
		{
			key: "mergeTag",
			headerName: "Tag Label",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.mergeTag
		},
		{
			key: "multipleIndicator",
			headerName: "Multiple Indicator",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.multipleIndicator === 0 ? "No" : "Yes"
		},
	]

	constructor(private activeModal: NgbActiveModal,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _entityConfigService: EntityConfigService,) {

	}

	ngOnInit(): void {
		this.dataSelectStrategy = this._gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<ISourceField, number, ISourceField>(doc => doc.sourceFieldID, doc => doc);
		this.dataSource = this._gridDataSourceBuilder.searchGridDataSource<ISourceField, unknown>(row => row.sourceFieldID)
			.setSorting({ sortKey: "sourceFieldID", sortDir: 0 })
			.setColumns(this.columns)
			.setPaging({ rowOffset: 0, pageSize: 25 })
			.withDataSelectStrategy(this.dataSelectStrategy)
			.withFetchFn((ds) => {
				return this._entityConfigService.getSourceFields(this.charTypeId, 1, this.keywords).pipe(
					tap(results => {
					}),
					map((results) => {
						const cloned = cloneDeep(results);
						const skipped = drop(cloned, ds.rowOffset$.value);
						const pagedResult = take(skipped, ds.pageSize$.value);
						return <IGridFetchResults<ISourceField>>{
							rowData: pagedResult,
							rowCount: results.length
						}
					})
				)
			});
		const sub = this.dataSource.fetchRows().subscribe();
		const indexedRowDataSub = this.dataSource.indexedRowData$.pipe(
			filter(rows => !!rows),
		).subscribe(rows => {
			const selecteIds = this.options.selectedRecordIds ?? [];
			const selected = rows.filter(row => selecteIds.includes(row.data.sourceFieldID));
			if (!isEmpty(selected)) {
				this.dataSource.dataSelectStrategy.selectRows(selected);
			}
		})
		this._subs.push(sub, indexedRowDataSub);
	}

	public close() {
		this.activeModal.close(false);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}

	submit() {
		let sub = this.dataSource.fetchRows().subscribe();
		this._subs.push(sub)
	}

	clearText() {
		this.keywords = "";
		this.submit();
	}

	public apply() {
		const selectedValues = <ISourceField[]>Array.from(this.dataSource.dataSelectStrategy.selectedState.selectedValues);
		this.onComplete.emit(selectedValues);
		this.close();
	}

	cancel() {
		this.activeModal.close(false);
	}

}
