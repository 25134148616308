<div class="modal-header">
	<h2 class="modal-title">Apply Table Profile</h2>
	<button class="btn btn-close" (click)="cancel()" title="Close">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<div class="d-flex align-items-center">
		<label class="me-2">Select a profile:</label>
		<select class="form-select w-auto" [(ngModel)]="selectedDataDefault">
			<option [ngValue]="default" *ngFor="let default of dataDefaults">
				{{ default.label }}
			</option>
		</select>
	</div>
</div>

<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success ms-3" [disabled]="!isValid || isRunning" (click)="applyDataDefault()">
		<span class="pe-1" *ngIf="isRunning"><i class="far fa-spinner fa-spin"></i></span>
		Apply
	</button>
</div>