
import { transition, trigger, useAnimation } from "@angular/animations";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Provider, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { first } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { EntitySearchComponent } from "rl-common/components/entities/entity-search/entity-search.component";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { FacetType } from "rl-common/models/facet-type";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IAccountingProcess } from "rl-common/services/accounting-processes/models/i-accounting-process";
import { GrowlerService } from "rl-common/services/growler.service";
import { SearchService } from "rl-common/services/search/search.service";
import { QueryUtil } from "rl-common/utils";
import { Subscription } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { ChipComponent } from "../../../../common/components/chip/chip.component";
import { PluralCharTypeNamePipe } from "../../../../common/pipes/plural-char-type-name.pipe";
import { CopyAccountingProcessModalAdapter, ICopyAccountingProcessModalComponent } from "./copy-accounting-process-modal.adapter";
import { ICopyAccountingProcessResult } from "./models/i-copy-accounting-process-result";

interface ICopyAccountingProcessForm {
	name: FormControl<string>;
	contactId: FormControl<number>;
}

@Component({
	selector: "rl-copy-accounting-process-modal",
	templateUrl: "./copy-accounting-process-modal.component.html",
	styleUrls: ["./copy-accounting-process-modal.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [ReactiveFormsModule, NgIf, EntitySearchComponent, NgFor, ChipComponent, PluralCharTypeNamePipe]
})
export class CopyAccountingProcessModalComponent implements OnInit, OnChanges, OnDestroy, ICopyAccountingProcessModalComponent {

	@Input()
	currentContactId: number;

	@Input()
	selectedContact: IAccountingContact = null;

	@Input()
	accountingProcess: IAccountingProcess;

	@Output()
	onComplete = new EventEmitter<ICopyAccountingProcessResult>();

	@ViewChild("entitySearch")
	entitySearchComponent: EntitySearchComponent;

	contactCharTypeId = CharTypeId.User;

	searchOptions: SearchOptions;

	isCopyToSameContact = false;
	isCopying = false;

	form: FormGroup<ICopyAccountingProcessForm>;

	dataSource: SearchGridDataSource<IEntitySearchDoc>;

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _accountingProcessesService: AccountingProcessesService,
		private readonly _formBuilder: FormBuilder,
		private readonly _growler: GrowlerService,
		private readonly _gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly _searchService: SearchService
	) { }

	async ngOnInit() {
		this.isCopyToSameContact = !!this.selectedContact;
		this.searchOptions = this.buildSearchOptions();
		this.form = this._formBuilder.group({
			name: new FormControl<string>(`COPY - ${this.accountingProcess.name}`, { validators: [Validators.required] }),
			contactId: new FormControl<number>(this.selectedContact?.recordId, { validators: [Validators.required] })
		});

		if (!this.isCopyToSameContact) {
			const availableContacts = await this._accountingProcessesService.getContacts().toPromise();
			const columnStrategy = this._gridDataServiceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(CharTypeId.User);

			const dataSelectStrategy = this._gridDataServiceBuilder.dataSelectStrategies
				.searchDocDataSelectStrategy<number, IEntitySearchDoc>(doc => doc.recordID, doc => doc)
				.withSelectType(GridSelectType.Radio);

			this.dataSource = this._gridDataServiceBuilder.entitySearchDataSource(CharTypeId.User)
				.setPaging({ pageSize: 10 })
				.setSorting({ sortKey: "recordid", sortDir: 0 })
				.withDataSelectStrategy(dataSelectStrategy)
				.withColumnStrategy(columnStrategy)
				.withFetchFn((ds) => {
					const start = ds.rowOffset$.value;
					const pageSize = ds.pageSize$.value;
					const sortKey = ds.sortKey$.value;
					const sortDir = ds.sortDir$.value;

					const contactIds = availableContacts.map(x => x.recordId).filter(recId => recId !== this.currentContactId);
					const query = QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, contactIds);

					const options: ISearchRequestOptions = {
						rows: pageSize,
						start,
						sortDir,
						sortField: sortKey as string,
						facetFields: [
							FacetType.Template,
							FacetType.Status
						],
						filterQueries: ds.buildFilterQueries(),
					};

					return this._searchService.search(CharTypeId.User, ds.keywords, query, options).pipe(
						tap((results) => {
							ds.setFacetResults(results.facetResults)
								.setExtraGridColumnResults(results.extraGridColumnResults);
						}),
						map((results) => ({
							rowData: results.innerQueryDocuments,
							rowCount: results.numFound,
							extraGridColumnResults: results.extraGridColumnResults,
							facetResults: results.facetResults
						}))
					);
				});

			const fetchRowsSub = this.dataSource.fetchRows().subscribe();

			const selectChangedSub = this.dataSource.dataSelectStrategy.selectStateChange$.subscribe(state => {
				const contactDoc = first(Array.from(state.selectedValues)) as IEntitySearchDoc;
				this.selectedContact = contactDoc ? { title: contactDoc.title, recordId: contactDoc.recordID } : null;
				this.form.controls.contactId.setValue(this.selectedContact?.recordId);
			});

			this._subs.push(fetchRowsSub, selectChangedSub);
		}
	}

	buildSearchOptions() {
		const searchOptions = SearchOptionsFactory.buildListPageOptions(CharTypeId.User);
		searchOptions.selectAllEnabled = false;
		searchOptions.selectType = SelectType.Radio;
		return searchOptions;
	}

	ngOnChanges(changes: ComponentChanges<this>): void {

	}

	setContact(contact: IAccountingContact) {
		this.selectedContact = contact;
	}

	close() {
		this.onComplete.next({
			contact: this.selectedContact,
			newProcessId: null
		});
	}

	copy() {
		this.isCopying = true;
		const sub = this._accountingProcessesService.copyAccountingProcess(
			this.accountingProcess.id,
			this.form.controls.name.value,
			this.form.controls.contactId.value
		).pipe(
			catchError(err => {
				this._growler.error(`Copy Failed`).growl(err?.message);
				throw err;
			}),
			finalize(() => {
				this.isCopying = false;
			})
		).subscribe(id => {
			this.onComplete.emit({
				contact: this.selectedContact,
				newProcessId: id
			});
		});
		this._subs.push(sub);
	}

	clearSelections() {
		this.entitySearchComponent.dataSourceWrapper.dataSelectStrategy.clearSelected();
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}

export const COPYACCOUNTINGPROCESS_MODAL_PROVIDER: Provider = {
  provide: CopyAccountingProcessModalAdapter,
  useFactory: () => new CopyAccountingProcessModalAdapter(CopyAccountingProcessModalComponent)
};

