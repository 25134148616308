import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { MaxInt } from "rl-common/consts";
import { IWfAction, IWfProcesses, IWfRole, IWfRoleActionAssociation, IWfRoleStepAssociation, IWfStep } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { forkJoin } from "rxjs";
import { map, tap } from "rxjs/operators";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";

@Component({
    selector: "rl-wf-actions-statuses-modal",
    templateUrl: "./wf-actions-statuses-modal.component.html",
    styleUrls: ["./wf-actions-statuses-modal.component.scss"],
    imports: [ReactiveFormsModule, NgFor, NgClass, NgIf, GridTableComponent, AsyncPipe]
})
export class WfActionsStatusesModalComponent implements OnInit {

	@Input()
	role: IWfRole;

	@Output()
	onComplete = new EventEmitter<boolean>();

	isExpanded: _.Dictionary<boolean> = { "0": false }; // close actions
	processes: IWfProcesses[];
	processId: number;
	chooseProcess: UntypedFormControl;
	wfActions: IWfAction[] = [];
	wfRoleActionAssoc: IWfRoleActionAssociation[];
	wfRoleStepAssoc: IWfRoleStepAssociation[];
	wfActionDataSource: CommonGridDataSource<IWfAction>;
	wfStepDataSource: CommonGridDataSource<IWfStep>;
	wfActionGridOptions: GridOptions<IWfAction> = {};
	wfStepGridOptions: GridOptions<IWfStep> = {};

	private readonly wfActionColumns: GridColumn<IWfAction>[] = [
		{
			key: "status",
			headerName: "Action",
			renderer: "text",
			width: "max-content",
			getCellData: (doc => doc.actionName)
		},
		{
			key: "status_by_action",
			headerName: "Status (set by action)",
			renderer: "text",
			width: "max-content",
			getCellData: (doc => doc.stepName)
		},
	];

	private readonly wfStepColumns: GridColumn<IWfStep>[] = [
		{
			key: "status",
			headerName: "Status",
			renderer: "text",
			width: "max-content",
			getCellData: (doc => doc.stepName)
		},
		{
			key: "available_actions",
			headerName: "Actions Available from this Status",
			renderer: "text",
			width: "max-content",
			getCellData: (doc => doc.actionName)
		},
	];

	// TODO: Swap out grid for table since pagination isn't really being used (loading all results into memory)
	constructor(private readonly _modal: NgbActiveModal, private readonly companyService: CompanyService,
		private formBuilder: UntypedFormBuilder,
		private readonly gridDataSourceBuilder: GridDataSourceBuilder) {
		const wfActionDataSelectStrategy = this.gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<IWfAction, unknown, IWfAction>(rowData => rowData.actionID, rowData => rowData);
		const wfStepDataSelectStrategy = this.gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<IWfStep, unknown, IWfStep>(rowData => rowData.stepID, rowData => rowData);
		this.wfActionDataSource = this.gridDataSourceBuilder.commonGridDataSource<IWfAction>(row => row.actionID)
			.setPaging({ pageSize: MaxInt.Num }) // load all results into memory
			.setColumns(this.wfActionColumns)
			.withDataSelectStrategy(wfActionDataSelectStrategy)
			.withFetchFn((ds) => {
				const page = Math.floor(ds.rowOffset$.value / ds.pageSize$.value) + 1;
				return forkJoin([this.companyService.getWfActions(page, ds.pageSize$.value, this.processId), this.companyService.getWfRoleActionAssociations(this.processId, this.role.roleID)])
					.pipe(
						tap((results) => {
							ds.setPaging({ pageSize: results[0].numFound }); // set precise paging
						}),
						map((results) => {
							this.wfRoleActionAssoc = results[1];
							return <IGridFetchResults<IWfAction>>{ rowData: results[0].data, rowCount: results[0].numFound };
						})
					);
			});
		this.wfStepDataSource = this.gridDataSourceBuilder.commonGridDataSource<IWfStep>(row => row.stepID)
			.setPaging({ pageSize: MaxInt.Num }) // load all results into memory
			.setColumns(this.wfStepColumns)
			.withDataSelectStrategy(wfStepDataSelectStrategy)
			.withFetchFn((ds) => {
				const page = Math.floor(ds.rowOffset$.value / ds.pageSize$.value) + 1;
				return forkJoin(this.companyService.getWfSteps(page, ds.pageSize$.value, this.processId), this.companyService.getWfRoleStepAssociations(this.processId, this.role.roleID))
					.pipe(
						tap((results) => {
							ds.setPaging({ pageSize: results[0].numFound }); // set precise paging
						}),
						map((results) => {
							this.wfRoleStepAssoc = results[1];
							return <IGridFetchResults<IWfStep>>{ rowCount: results[0].numFound, rowData: results[0].data };
						})
					);
			});
	}

	ngOnInit(): void {
		this.chooseProcess = this.formBuilder.control(this.processId);
		this.isExpanded[0] = false; // close actions
		this.isExpanded[1] = false;	// close statuses
		this.companyService.getWfProcesses().pipe(
			tap(results => {
				this.processes = results.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
			})
		).subscribe();
	}

	changeProcess() {
		this.switchProcess(+this.chooseProcess.value);
	}

	switchProcess(pid: number) {
		this.processId = pid;
		this.wfActionDataSource.fetchRows().pipe(
			tap(() => {
				const items = this.wfActionDataSource.indexedRowData$.value.filter(rd => this.wfRoleActionAssoc.find(x => x.actionID === rd.data.actionID));
				this.wfActionDataSource.dataSelectStrategy.clearSelected();
				this.wfActionDataSource.dataSelectStrategy.selectRows(items);
			})
		).subscribe();

		this.wfStepDataSource.fetchRows().pipe(
			tap(() => {
				const items = this.wfStepDataSource.indexedRowData$.value.filter(rd => this.wfRoleStepAssoc.find(x => x.stepID === rd.data.stepID));
				this.wfStepDataSource.dataSelectStrategy.clearSelected();
				this.wfStepDataSource.dataSelectStrategy.selectRows(items);
			})
		).subscribe();
	}

	cancel() {
		this._modal.dismiss();
	}

	save() {
		const selectedWfActionAssoc = <number[]>Array.from(this.wfActionDataSource.dataSelectStrategy.selectedState.selectedIds);
		const selectedWfStepAssoc = <number[]>Array.from(this.wfStepDataSource.dataSelectStrategy.selectedState.selectedIds);

		forkJoin([this.companyService.setWfRoleActionAssociations(this.processId, this.role.roleID, selectedWfActionAssoc),
		this.companyService.setWfStepRoleAssociations(this.processId, this.role.roleID, selectedWfStepAssoc)]).subscribe(() => this.cancel());
	}
}
