import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharDataExtDataDateMath } from "rl-common/models/i-char-data-ext-data-date-math";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { DateOptionsComponent } from "../date-options/date-options.component";
import { IDateOptionsChange } from "./date-options-modal.models";


@Component({
	selector: "rl-date-options-modal",
	templateUrl: "./date-options-modal.component.html",
	styleUrls: ["./date-options-modal.component.scss"],
	imports: [DateOptionsComponent]
})
export class DateOptionsModalComponent implements OnInit {

	@Input()
	dateMath: ICharDataExtDataDateMath;

	@Input()
	alerts: ICharDataExtDataAlert[];

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	recCharId: number;

	@Input()
	isRelative: boolean;

	@Input()
	parentEntityId?: string = "";

	@Input()
	isBulkEdit = false;

	@Output()
	onChange = new EventEmitter<IDateOptionsChange>();

	constructor(
		private readonly _activeModal: NgbActiveModal
	) { }

	ngOnInit() {
	}

	dateMathChanged($event: ICharDataExtDataDateMath) {
		this.onChange.emit({ dateMath: $event });
	}

	alertsChanged($event: ICharDataExtDataAlert[]) {
		this.onChange.emit({ alerts: $event });
	}

	closeModal() {
		this._activeModal.close();
	}

}
