import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbActiveModal, NgbCollapse, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { TreeListEditControlComponent } from "rl-common/components/char-data/controls/tree-list-edit-control.component";
import { ITemplateHierarchy } from "rl-common/components/create-workflow/create-entity.models";
import { ITemplateSelectorOptions } from "rl-common/components/template/template-selector/template-selector/template-selector.component";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IAllocatedDimension, IAllocationModel } from "rl-common/services/allocation/allocation.models";
import { AllocationService } from "rl-common/services/allocation/allocation.service";
import { IRightsDimension } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Percent, TreeListService } from "rl-common/services/tree-list.service";
import { forkJoin, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { CatalogHierarchyAllocationComponent } from "../../allocation/catalog-hierarchy-allocation/catalog-hierarchy-allocation.component";
import { FormTableControlDirective } from "../../form-table/form-table-control.directive";
import { FormTableMessageDirective } from "../../form-table/form-table-message.directive";
import { FormTableRowComponent } from "../../form-table/form-table-row/form-table-row.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { TemplateSelectorComponent } from "../../template/template-selector/template-selector/template-selector.component";
import { TextInputComponent } from "../../text/text-input/text-input.component";

@Component({
	selector: "rl-add-edit-allocation-modal",
	templateUrl: "./add-edit-allocation-modal.component.html",
	styleUrls: ["./add-edit-allocation-modal.component.scss"],
	imports: [ReactiveFormsModule, FormTableRowComponent, FormTableControlDirective, TextInputComponent, NgIf, FormTableMessageDirective, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, TreeListEditControlComponent, LoaderComponent, TemplateSelectorComponent, NgFor, CatalogHierarchyAllocationComponent, NgbNavOutlet]
})
export class AddEditAllocationModalComponent implements OnInit {

	@Input()
	allocationModel: IAllocationModel;

	@Input()
	templateId: number;

	@Input()
	isCreate: boolean;

	@Output()
	onComplete = new EventEmitter<boolean>();

	@Output()
	onClose = new EventEmitter<boolean>();

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	get invalid() {
		if (!this.form) {
			return true; // form isn't ready
		}
		return this.form.invalid;
	}

	templateOptions: ITemplateSelectorOptions = {
		blockList: [],
		labelFn: t => `${t.templateName}`,
		defaultTemplateId: -1
	};

	constructor(private readonly _modal: NgbActiveModal, private _companyService: CompanyService, private _oneConfigService: OneConfigService, private treeListService: TreeListService, private allocationService: AllocationService, private readonly formBuilder: UntypedFormBuilder) { }
	allDimensions: IRightsDimension[];
	dimensions: IRightsDimension[];
	lovs: { [key: string]: ICharacteristicMetaDataValue[] } = {};
	placeholder = 0;
	percentage = 0;
	percentageSum: { [key: string]: number } = {};
	topNodePercentages: { [key: string]: number } = {};
	modelName = new UntypedFormControl("", [Validators.required]);
	templateName = "";
	percentageAmounts: Percent[];
	titleText = "";
	openPanelIds: string[] = [];
	form: UntypedFormGroup;
	existingDimensionPercentages: IAllocatedDimension[] = [];
	originalDimensionPercentages: IAllocatedDimension[] = [];
	untouchedPercentages: IAllocatedDimension[] = [];
	updateAllocationModel = false;
	lovIds: number[] = [];
	allTopNodesValid = true;
	needsDimensions = false;
	dimensionsToDelete = [];
	canSave = false;
	dimensionChanged = false;
	charTypeId = CharTypeId.Property;
	catalogTemplates: ITemplateHierarchy[] = [];
	catalogTemplatesToSelect: ICharacteristicTemplate[] = [];
	selectedTemplateId: number = -1;
	hideHierarchy = true;
	catalogHierarchySelectionsValid = true;
	catalogHierarchyUntouched = true;
	suballocationTemplateId = -1;

	formGroup = new UntypedFormGroup({
		modelName: this.modelName,
	});

	ngOnInit(): void {
		if (!this.allocationModel) {
			this.allocationModel = { id: null, name: "", parentCharTemplateId: this.templateId, primaryCatalogCharTemplateId: -1, suballocationCatalogCharTemplateId: -1 };
		} else {
			this.templateOptions.defaultTemplateId = this.allocationModel.primaryCatalogCharTemplateId;
		}
		this.titleText = this.isCreate ? "Create" : "Edit";
		const templates = this._oneConfigService.getTemplates(CharTypeId.Amount);
		this.selectedTemplateId = this.allocationModel.primaryCatalogCharTemplateId;
		this.suballocationTemplateId = this.allocationModel.suballocationCatalogCharTemplateId;
		this.catalogTemplatesToSelect = this._oneConfigService.getTemplates(CharTypeId.Property);
		this.templateName = templates?.find(x => x.templateID === this.templateId)?.templateName;
		this.treeListService.percentageAmount$.pipe(
			tap(result => {
				this.percentageAmounts = _.cloneDeep(result);
			})
		).subscribe();
		this._companyService.getRightsDimensions().pipe(
			tap((results) => {
				this.allDimensions = results;
				results.forEach(element => {
					this.percentageSum[element.charValueSourceID] = 0;
				});
			}),
			switchMap(() => this._companyService.getSelectedRightsDimensions()),
			tap((results) => {
				this.dimensions = this.allDimensions.filter(x => results.find(y => y.charValueSourceID === x.charValueSourceID));
			}),
			switchMap(() => {
				if (this.allocationModel.id) {
					return this.allocationService.getAllocatedDimensionsByModelId(this.allocationModel.id);
				} else {
					return of([]);
				}
			}),
			tap((results) => {
				if (results.length > 0) {
					this.originalDimensionPercentages = _.cloneDeep(results);
					this.untouchedPercentages = results;
					this.existingDimensionPercentages = _.cloneDeep(results).filter(x => x.charValuePercentage > 0);
				}
				this.dimensions.forEach(element => {
					this.percentageSum[element.charValueSourceID] = 0;
					const id = element.charValueSourceID;
					this.lovs[id] = this._oneConfigService.getLovMetaData(id)?.listOfValues;
					const lovCharValueId = this.lovs[id][0]?.characteristicValueID;
					let percentage = 0;
					if (this.lovs[id].length > 1) {
						this.existingDimensionPercentages.filter(x => x.parentAllocatedDimensionId === element.charValueSourceID).map(x => x.charValuePercentage).reduce((a, b) => +a + +b, +0);
						const percentagesInDimension = this.existingDimensionPercentages.filter(x => x.parentAllocatedDimensionId === element.charValueSourceID);
						const uniqueValues = [... new Map(percentagesInDimension.map(item => [item["charValueId"], item])).values()].map(x => x.charValuePercentage);
						percentage = uniqueValues.reduce((a, b) => +a + +b, +0);
					} else {
						percentage = this.existingDimensionPercentages.find(x => x.charValueId === lovCharValueId && x.parentAllocatedDimensionId === element.charValueSourceID)?.charValuePercentage;
					}
					this.topNodePercentages[element.charValueSourceID] = percentage >= 0 ? percentage : 0;
				});
			})
		).subscribe();
		this.form = this.formBuilder.group({
			name: new UntypedFormControl(this.allocationModel.name, [Validators.required])
		});
	}

	checkForError() {
		if (this.form) {
			this.updateAllocationModel = this.allocationModel.name === this.form.controls["name"].value ? false : true;
		}
	}

	isDisabled() {
		return this.invalid || !this.allTopNodesValid || !this.canSave && !this.form.dirty || !this.catalogHierarchySelectionsValid;
	}

	clearAll(sourceId: string, controlListComponent: TreeListEditControlComponent) {
		this.canSave = true;
		controlListComponent.clearAllPercentages();
		//check all existing in dimension and delete
		const dimensionOriginalPercentages = this.originalDimensionPercentages.filter(x => x.parentAllocatedDimensionId === sourceId);
		dimensionOriginalPercentages.forEach(percentage => {
			if (percentage.charValuePercentage > 0) {
				if (percentage.allocationDimensionId) {
					this.dimensionsToDelete.push(percentage.allocationDimensionId);
				}
				this.existingDimensionPercentages = this.existingDimensionPercentages.filter(x => x.charValueId !== percentage.charValueId || x.parentAllocatedDimensionId !== percentage.parentAllocatedDimensionId);
				if (this.topNodePercentages[percentage.allocationDimensionId]) {
					this.topNodePercentageChange(percentage.charValuePercentage, percentage.allocationDimensionId);
				}
			}
		});

	}

	topNodePercentageChange(percentage: number, dimensionId: string) {
		this.canSave = true;
		if (this.percentage != null) {
			this.topNodePercentages[dimensionId] = percentage;
		}
		let allValid = true;
		for (const [key, value] of Object.entries(this.topNodePercentages)) {
			if (value !== 100 && value !== 0) {
				allValid = false;
			}
		}
		this.allTopNodesValid = allValid;
	}

	dimensionValuesChanged(event: Percent[]) {
		this.dimensionChanged = true;
		if (this.needsDimensions) {
			this.needsDimensions = event.filter(x => x.percentage > 0).length < 1;
		}
		const updatedValues = event.filter(x => x.percentage !== null);
		if (!this.percentageAmounts || this.percentageAmounts?.length < 1) {
			this.percentageAmounts = updatedValues;
		} else {
			updatedValues.forEach(updatedValue => {
				const percentageVal = this.percentageAmounts.find(x => x.characteristicID === updatedValue.characteristicID && x.parentDimensionID === updatedValue.parentDimensionID);
				const dimension: IAllocatedDimension = {
					allocationDimensionId: null,
					allocationModelId: this.allocationModel.id,
					charValueId: updatedValue.characteristicID,
					charValuePercentage: updatedValue.percentage,
					parentCharTemplateId: this.templateId,
					name: this.allocationModel.name,
					parentAllocatedDimensionId: updatedValue.parentDimensionID,
					primaryCatalogCharTemplateId: this.allocationModel.primaryCatalogCharTemplateId,
					suballocationCatalogCharTemplateId: this.allocationModel.suballocationCatalogCharTemplateId
				};

				const existingUpdated = this.existingDimensionPercentages.filter(x => x.parentAllocatedDimensionId === updatedValue.parentDimensionID && x.charValueId === updatedValue.characteristicID);
				if (existingUpdated.length > 0) {
					existingUpdated[0].charValuePercentage = updatedValue.percentage;
				} else {
					this.existingDimensionPercentages.push(dimension);
				}

				if (percentageVal) {
					percentageVal.percentage = updatedValue.percentage;
				} else {

					this.percentageAmounts.push({ characteristicID: updatedValue.characteristicID, percentage: updatedValue.percentage, parentDimensionID: updatedValue.parentDimensionID, hasParent: updatedValue.hasParent });
				}
			});
		}
	}

	public cancel() {
		if (!this.invalid && this.allTopNodesValid && this.canSave && (this.form.dirty || this.dimensionChanged)) {
			if (window.confirm("You have unsaved changes.  Are you sure you want to cancel?")) {
				this._modal.close();
			}
		} else {
			this._modal.close();
		}
	}

	trackByFn(i: number,) {
		return `${i}`;
	}

	getLovSourceIds(lovs: ICharacteristicMetaDataValue[] = null) {
		if (lovs) {
			lovs.forEach(lov => {
				if (!this.lovIds.includes(lov.characteristicValueID)) {
					this.lovIds.push(lov.characteristicValueID);
				}
				if (lov.childValues) {
					lov.childValues.forEach(child => {
						if (!this.lovIds.includes(lov.characteristicValueID)) {
							this.lovIds.push(lov.characteristicValueID);
						}
						this.lovIds.push();
						this.getLovSourceIds([child]);
					});
				}
			});
		}
	}

	save(lovs: ICharacteristicMetaDataValue[] = null) {
		let hasOtherDimensions = false; {
			this.dimensions.forEach(dimension => {
				if (this.topNodePercentages[dimension.charValueSourceID] > 0) {
					hasOtherDimensions = true;
				}
			});
		}
		if (!this.percentageAmounts && !hasOtherDimensions || this.percentageAmounts?.filter(x => x.percentage > 0).length < 1 && !hasOtherDimensions) {
			this.needsDimensions = true;
			if (!this.catalogHierarchySelectionsValid || this.catalogHierarchyUntouched) {
				return;
			}
		}
		this.lovIds = [];
		this.getLovSourceIds(lovs);
		this.allocationModel.name = this.form.controls["name"].value;
		this.allocationModel.primaryCatalogCharTemplateId = this.selectedTemplateId;
		this.allocationModel.suballocationCatalogCharTemplateId = this.suballocationTemplateId;
		const dimensions: IAllocatedDimension[] = [];
		const originals = _.cloneDeep(this.untouchedPercentages);

		const filteredPercentages = this.percentageAmounts?.filter(x => x.percentage !== null);
		filteredPercentages?.forEach(percentage => {
			if (percentage.percentage > 0) {
				const currentDimensionId = percentage.parentDimensionID;
				const existingPercentage = originals.find(x => x.parentAllocatedDimensionId === percentage.parentDimensionID && x.charValueId === percentage.characteristicID);
				if ((existingPercentage && +existingPercentage.charValuePercentage !== +percentage.percentage) || !existingPercentage) {
					const dimension: IAllocatedDimension = {
						allocationDimensionId: existingPercentage ? existingPercentage.allocationDimensionId : null,
						allocationModelId: this.allocationModel.id,
						charValueId: percentage.characteristicID,
						charValuePercentage: percentage.percentage,
						parentCharTemplateId: this.templateId,
						name: this.allocationModel.name,
						parentAllocatedDimensionId: currentDimensionId,
						primaryCatalogCharTemplateId: this.selectedTemplateId,
						suballocationCatalogCharTemplateId: this.suballocationTemplateId
					};
					dimensions.push(dimension);
				}
			}
		});

		originals.forEach(originalDimension => {
			const updatedValue = this.existingDimensionPercentages.find(x => x.parentAllocatedDimensionId === originalDimension.parentAllocatedDimensionId && x.charValueId === originalDimension.charValueId);
			if (updatedValue !== null) {
				const updatedDimension = dimensions.filter(x => x.allocationDimensionId === originalDimension.parentAllocatedDimensionId).length;
				if (updatedDimension < 1 && (lovs === null || lovs && this.lovIds.includes(originalDimension.charValueId))) {
					const currentValue = this.existingDimensionPercentages.find(x => x.charValueId === originalDimension.charValueId && x.parentAllocatedDimensionId === originalDimension.parentAllocatedDimensionId)?.charValuePercentage;
					if (originalDimension.allocationDimensionId !== null && currentValue < 1) {
						this.dimensionsToDelete.push(originalDimension.allocationDimensionId);
					}
				}
			}
		});
		const observables = [];
		if (dimensions.length > 0) {
			observables.push(this.allocationService.createUpdateAllocatedDimensions(dimensions));
		}
		if (this.dimensionsToDelete.length > 0) {
			observables.push(this.allocationService.deleteAllocatedDimensions(this.dimensionsToDelete));
		}

		if (this.updateAllocationModel || this.allocationModel.id === null) {
			this.allocationService.createUpdateAllocationModel(this.allocationModel).pipe(
				tap((results) => {
					this.allocationModel.id = results;
					if (dimensions?.length > 0) {
						dimensions.forEach(dimension => {
							dimension.allocationModelId = results;
						});
					} else {
						this.onComplete.emit(true);
					}
				}),
				switchMap(() => forkJoin(observables)),
				switchMap(() => this.allocationService.getAllocatedDimensionsByModelId(this.allocationModel.id))).subscribe(() => {
					this.onComplete.emit(true);
				});
		} else {
			if (observables.length > 0) {
				forkJoin(observables).pipe(
					switchMap(() => this.allocationService.getAllocatedDimensionsByModelId(this.allocationModel.id))
				).subscribe(() => {
					this.onComplete.emit(true);
				});
			} else {
				this.onComplete.emit(true);
			}
		}
	}

	updateValue(event: number) {

		if (event !== this.selectedTemplateId) {
			this.selectedTemplateId = event;
			this.suballocationTemplateId = -1;
		}
		if (event > -1) {
			this.hideHierarchy = false;
			const childTemplates = this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.selectedTemplateId, this.charTypeId);
			this.catalogTemplates = childTemplates.map(t => ({
				directParentTemplates: [this.selectedTemplateId],
				templateID: t.templateID,
				templateLabel: t.templateName,
				childTemplates: null,
				level: 1,
				numberOfRecords: 0
			}));
		}
		this.checkValidity(this.suballocationTemplateId);
	}

	checkValidity(templateID: number) {
		this.updateAllocationModel = true;
		this.suballocationTemplateId = templateID;
		this.form.markAsDirty();
		this.canSave = true;
		this.catalogHierarchyUntouched = this.selectedTemplateId === -1 && templateID === -1;
		if (this.selectedTemplateId > -1 && templateID > -1 || this.catalogHierarchyUntouched) {
			this.catalogHierarchySelectionsValid = true
		} else {
			this.catalogHierarchySelectionsValid = false;
		}
	}

	rsTrackBy(i: number, id: number) {
		return id;
	}
}
