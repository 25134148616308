<div *ngIf="hasResults">
	<div *ngIf="invalidResults.length > 0">
		<span>There was an error with your input.</span>
	</div>
	<div>
		<p>
			<strong>Total Records Validated</strong>: {{totalCount}}
		</p>
		<p>
			<strong>Number of Valid Records</strong>: {{validResults.length}}
		</p>
		<p>
			<strong>Number of Invalid Records</strong>: {{invalidResults.length}}
		</p>
		<div *ngIf="invalidResults.length > 0">
			<p class="divider">
				<strong>Invalid Record IDs</strong>:
			</p>
			<div class="alert alert-dark">
				<div *ngFor="let invalidResult of invalidResults">
					{{invalidResult.recordID === 0 ? invalidResult.title : invalidResult.recordID}}
				</div>
			</div>
		</div>
	</div>
</div>