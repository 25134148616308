import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from "@ng-bootstrap/ng-bootstrap";
import { ISelectRecentDivEvent } from "app/rightsline-app/components/header/profile-dropdown/recent-divs/recent-divs.component";
import { Acl } from "rl-common/rl-common-acl.consts";
import { AccountService } from "rl-common/services/account/account.service";
import { AuthenticationService } from "rl-common/services/authentication/authentication.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { TenantService } from "rl-common/services/tenant/tenant.service";
import { Subscription, throwError } from "rxjs";
import { catchError, filter, finalize, map, tap } from "rxjs/operators";
import { IzendaTokenService } from "../../../../common/services/izenda-token.service";
import { SessionService } from "./../../../../common/services/session.service";
import { IAppOption } from "./profile-dropdown.models";
import { ProfilePictureComponent } from "../../../../common/components/profile-picture/profile-picture.component";
import { NewTabInModalDirective } from "../../../../common/directives/new-tab-in-modal.directive";
import { IsRlAdminDirective } from "../../../../common/directives/is-rl-admin.directive";
import { UploadProfilePictureComponent } from "../../../../common/components/upload-profile-picture/upload-profile-picture.component";
import { HasAnyReadAccessDirective } from "../../../../common/directives/has-any-read-access.directive";
import { HasReadAccessDirective } from "../../../../common/directives/has-read-access.directive";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { SelectDivControlComponent } from "./select-div-control/select-div-control.component";
import { RecentDivsComponent } from "./recent-divs/recent-divs.component";

@Component({
    selector: "rl-profile-dropdown",
    templateUrl: "./profile-dropdown.component.html",
    styleUrls: ["./profile-dropdown.component.scss"],
    imports: [NgbDropdown, NgbDropdownToggle, ProfilePictureComponent, NgbDropdownMenu, NewTabInModalDirective, RouterLink, IsRlAdminDirective, UploadProfilePictureComponent, HasAnyReadAccessDirective, HasReadAccessDirective, NgIf, SelectDivControlComponent, ReactiveFormsModule, FormsModule, NgFor, RecentDivsComponent, AsyncPipe]
})
export class ProfileDropdownComponent implements OnInit, OnDestroy {

	divControl: UntypedFormControl;
	currentAppIndex: number;
	chooseApps: IAppOption[];
	isSwitching = false;
	selectedApp: IAppOption;
	availableDivs: IAppOption[] = [];

	@ViewChild(NgbDropdown, { static: true })
	dropdown: NgbDropdown;

	dataToolsAcl = Acl.Activities.ActivityAdminAcls.DataTools;
	companyAdminAcl = Acl.Activities.ActivityAdminAcls.Administration;
	configAcl = Acl.Activities.ActivityAdminAcls.Configuration;

	hasMultipleDivs$ = this._sessionService.userApps$.pipe(
		// Users with access to only one div won't see the div selector
		map(userApps => userApps?.length > 1)
	);

	userName$ = this._sessionService.userName$;
	userId$ = this._sessionService.userId$;
	profileAvatar$ = this._sessionService.profileAvatar$;
	displayName$ = this._sessionService.displayName$;
	accountExists = false;

	private readonly _subscriptions: Subscription[] = [];

	constructor(
		public readonly _sessionService: SessionService,
		private readonly _router: Router,
		private readonly _tenantService: TenantService,
		private readonly _accountService: AccountService,
		private readonly _growlerService: GrowlerService,
		private readonly _authenticationService: AuthenticationService,
		private readonly _izendaTokenService: IzendaTokenService,
		private readonly _progressService: ProgressService
	) { }

	ngOnInit() {
		const currentDiv = this._sessionService.userApps.find(x => x.divId === this._sessionService.divId);
		this.divControl = new UntypedFormControl(currentDiv);

		const sub = this._sessionService.userApps$.pipe(
			filter(apps => !!apps)
		).subscribe((apps) => {
			this.availableDivs = apps;
			const currentApp: IAppOption = this.divControl.value;
			const found = apps.find(app => app.divId === currentApp?.divId);
			this.divControl.patchValue(found, { emitEvent: false });
		});

		const changesSub = this.divControl.valueChanges.subscribe((div) => {
			this.selectedApp = div;
			this.switchApplications();
		});

		const closeDropdownSub = this._router.events.pipe(
			filter(x => x instanceof NavigationEnd)
		).subscribe(() => {
			this.closeDropdown();
		});
		this._subscriptions.push(sub, changesSub, closeDropdownSub);
	}

	selectDiv() {
		if (!this.selectedApp) {
			return;
		}
		this.divControl.patchValue(this.selectedApp);
	}

	switchApplications() {
		if (!this.selectedApp) {
			return;
		}
		this.isSwitching = true;
		this._tenantService.switchTenant(this.selectedApp.divId, this._sessionService.userApps)
			.subscribe(() => {
				this.isSwitching = false;
				this.selectedApp = null;
				this.closeDropdown();
				this._izendaTokenService.clearIzendaToken();
			});
	}

	supportPortal() {

		this._progressService.startProgress();
		const sub = this._accountService.getSupportToken()
			.pipe(
				tap((jwt) => {
					window.open(`https://rightsline.zendesk.com/access/jwt?jwt=${jwt}&return_to=https://rightsline.zendesk.com/hc`, "_support");
				}),
				finalize(() => this._progressService.endProgress())
			)
			.subscribe();
		this._subscriptions.push(sub);
	}

	switchToApp(event: ISelectRecentDivEvent) {
		this.selectedApp = this._sessionService.userApps.find(x => x.divId === event.recentDiv.divId);
		this.divControl.patchValue(this.selectedApp);
		this.switchApplications();
	}

	logout() {
		const sub = this._authenticationService.logout()
			.pipe(catchError(err => {
				this._sessionService.logout();
				return throwError(err);
			}))
			.subscribe(() => {
				this._sessionService.logout();
				this._izendaTokenService.clearIzendaToken();
			});
		this._subscriptions.push(sub);
	}

	private closeDropdown() {
		this.dropdown.close();
	}

	navigateToConfiguration() {
		this._router.navigateByUrl("config");
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
