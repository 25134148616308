<div *ngIf="hasAvailableSelections" [formGroup]="form">
	<div class="d-flex align-items-center bg-light p-2 border-bottom" formArrayName="queryNodeRows"
		*ngFor="let queryNodeControl of queryNodeFormControls; let i = index" data-cy="AdvancedSearchQueryNodeRows">
		<div class="flex-grow-1" [formGroupName]="i" data-cy="AdvancedSearchQueryNodeRowsInline">
			<rl-query-node-row [form]="form" [queryFormControl]="queryNodeControl" [charTypeId]="charTypeId"
				[queryNodeStrategies]="queryNodeStrategies" data-cy="RlQueryNodeRow">
			</rl-query-node-row>
		</div>
		<!-- The following line is useful for debugging purposes -->
		<!-- <div>{{ queryNodeControl.value | json}}</div> -->
		<button type="button" class="btn btn-icon btn-remove me-2 ms-auto" (click)="removeRow(i)" title="Remove Row"
			data-cy="AdvancedSearchRemoveRowButton"><i class="far fa-times"></i></button>
	</div>
</div>
<div class="d-flex justify-content-between mt-3" data-cy="AdvancedSearchAddRowContainer">
	<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="queryIsEmpty"
		data-cy="AdvancedSearchAddAnotherRowButton">
		<i class="far fa-plus"></i> Add another row
	</button>
	<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="!queryIsEmpty"
		data-cy="AdvancedSearchAddRowButton">
		<i class="far fa-plus"></i> Add row
	</button>
	<button type="button" class="btn btn-sm btn-remove text-grey" (click)="clearRows()" [hidden]="queryIsEmpty"
		data-cy="AdvancedSearchClearAllRowsButton">
		<i class="far fa-times me-1"></i> Clear all
	</button>
</div>