import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { IWfNotificationSaveRequest } from "rl-common/services/company/company.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { IDocTemplateConfig, IWorkflowNotification } from "rl-common/services/workflow/workflow.models";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { NgIf, NgFor } from "@angular/common";
import { FormTableRowComponent } from "../../../../../../../common/components/form-table/form-table-row/form-table-row.component";
import { FormTableControlDirective } from "../../../../../../../common/components/form-table/form-table-control.directive";
import { TextInputComponent } from "../../../../../../../common/components/text/text-input/text-input.component";
import { FormTableMessageDirective } from "../../../../../../../common/components/form-table/form-table-message.directive";
import { CheckboxInputComponent } from "../../../../../../../common/components/checkbox-input/checkbox-input.component";
import { TextAreaComponent } from "../../../../../../../common/components/text/text-area/text-area.component";
import { HtmlEditorComponent } from "../../../../../../../common/components/html-editor/html-editor.component";
import { TrimWhitespacePipe } from "../../../../../../../common/pipes/trim-whitespace.pipe";

interface WorkflowNotificationForm {
	id: FormControl<number>;
	label: FormControl<string>;
	description: FormControl<string>;
	subject: FormControl<string>;
	body: FormControl<string>;
	bodyHtml: FormControl<string>;
	sequenceNumber: FormControl<number>;
	previewIndicator: FormControl<boolean>;
	addActionBtn: FormControl<boolean>;
	documentTypes: FormArray<FormControl<number>>;
	triggerSNSOrSQS: FormControl<boolean>;
}

@Component({
    selector: "rl-workflow-notification-edit",
    styleUrls: ["./workflow-notification-edit.component.scss"],
    templateUrl: "./workflow-notification-edit.component.html",
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: "250ms ease-out"
                    }
                })
            ])
        ])
    ],
    imports: [NgIf, ReactiveFormsModule, FormsModule, FormTableRowComponent, FormTableControlDirective, TextInputComponent, FormTableMessageDirective, CheckboxInputComponent, TextAreaComponent, HtmlEditorComponent, NgFor, TrimWhitespacePipe]
})
export class WorkflowNotificationEditComponent implements OnInit, OnDestroy {
	_subs: Subscription[] = [];
	title: string;
	setNotificationName: string;
	formLoaded = false;
	form: FormGroup<WorkflowNotificationForm>;
	isSaving = false;
	prevIndBool: boolean;

	@Input()
	charTypeId: number;

	@Input()
	processId: number;

	@Input()
	notificationMessagesEnabled: boolean;

	@Input()
	notification: IWorkflowNotification;

	@Input()
	allDocTemplatesForCharType: IDocTemplateConfig[];

	@Output()
	onComplete = new EventEmitter<boolean>();

	get isCreate() {
		return this.notification && this.notification.id > 0 ? false : true;
	}

	constructor(private readonly _fb: FormBuilder,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService) { }

	ngOnInit() {
		if (!this.isCreate) {
			this.setNotificationName = this.notification?.label;
			this.prevIndBool = this.notification?.previewIndicator === 0 ? false : true;
			this.buildForm(this.notification);
		} else {
			this.buildForm();
		}

		this.setTitle();
	}

	setTitle() {
		const charTypeName = CharTypeIdUtil.toReportLabel(this.charTypeId);
		this.title = this.isCreate ? "Create New Workflow Notification" : `Edit ${charTypeName} Workflow Notification: <b>${this.setNotificationName}</b>`;
	}

	buildForm(model: IWorkflowNotification = null) {
		if (model) {
			this.form = this._fb.group<WorkflowNotificationForm>({
				id: this._fb.control(this.notification.id),
				label: this._fb.control(this.notification.label, Validators.required),
				description: this._fb.control(this.notification.description),
				subject: this._fb.control(this.notification.subject, Validators.required),
				body: this._fb.control(this.notification.body, Validators.required),
				bodyHtml: this._fb.control(this.notification.bodyHtml),
				sequenceNumber: this._fb.control(this.notification.sequenceNumber),
				previewIndicator: this._fb.control(this.prevIndBool),
				addActionBtn: this._fb.control(this.notification.addActionBtn),
				documentTypes: this._fb.array(this.notification.documentTypes),
				triggerSNSOrSQS: this._fb.control(this.notification.triggerSNSOrSQS),
			});
			if (!this.notificationMessagesEnabled) {
			this.form.controls.triggerSNSOrSQS.disable();
			}
		} else {
			this.form = this._fb.group<WorkflowNotificationForm>({
				id: this._fb.control(0),
				label: this._fb.control("", Validators.required),
				description: this._fb.control(""),
				subject: this._fb.control("", Validators.required),
				body: this._fb.control("", Validators.required),
				bodyHtml: this._fb.control(""),
				sequenceNumber: this._fb.control(0),
				previewIndicator: this._fb.control(false),
				addActionBtn: this._fb.control(false),
				documentTypes: new FormArray<FormControl<number>>([]),
				triggerSNSOrSQS: this._fb.control(false)
			});
		}

		this.formLoaded = true;
	}

	isDocumentTypeIdChecked(id: number) {
		return this.notification?.documentTypes?.includes(id);
	}

	onDocumentTypesChange(id: number, isChecked: boolean) {
		const documentTypesFormArray = this.form.controls.documentTypes as FormArray<FormControl<number>>;

		if (isChecked) {
			documentTypesFormArray.push(new FormControl<number>(id));
		} else {
			const index = documentTypesFormArray.controls.findIndex(x => x.value === id);
			documentTypesFormArray.removeAt(index);
		}
		this.form.controls.documentTypes.setValue(documentTypesFormArray.value);
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._growlerService.info().growl("Saving...");
			this._progressService.startProgress();
			let success = true;
			try {
				const prevIndNum = this.form.controls.previewIndicator?.value === true ? 1 : 0;
				const model: IWfNotificationSaveRequest = {
					divId: 0,
					processId: this.processId,
					id: this.form.controls.id.value,
					label: this.form.controls.label.value,
					description: this.form.controls.description.value,
					subject: this.form.controls.subject.value,
					body: this.form.controls.body.value,
					bodyHtml: this.form.controls.bodyHtml.value,
					sequenceNumber: this.form.controls.sequenceNumber.value,
					previewIndicator: prevIndNum,
					addActionBtn: this.form.controls.addActionBtn.value,
					documentTypes: this.form.controls.documentTypes.value,
					updatedBy: 0,
					triggerSNSOrSQS: this.form.controls.triggerSNSOrSQS.value
				};

				await this._entityConfigService.saveWFNotification(model).toPromise();
			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				this.isSaving = false;

				if (success) {
					this.onComplete.next(true);
					this._growlerService.success().growl("Your changes were saved.");
				} else {
					this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
				}
			}
		}
	}

	close() {
		this.onComplete.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}
}
