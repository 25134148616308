import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, first, isEmpty, last, partition, some } from "lodash";
import { EditMode } from "rl-common/components/panel-switcher/panel-switcher.models";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { AlertsService } from "rl-common/services/alerts/alerts.service";
import { AwsService } from "rl-common/services/aws/aws.service";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { SessionService } from "rl-common/services/session.service";
import { IWorkflowConfigProcessNotification } from "rl-common/services/workflow-config/workflow-config.models";
import { WorkflowConfigService } from "rl-common/services/workflow-config/workflow-config.service";
import { Observable, of } from "rxjs";
import { finalize, map, switchMap, take, tap } from "rxjs/operators";
import { v4 } from "uuid";
import { PanelContentDirective } from "../../panel-switcher/panel-content.directive";
import { PanelSwitcherComponent } from "../../panel-switcher/panel-switcher.component";
import { PanelComponent } from "../../panel-switcher/panel/panel.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { AlertFormComponent } from "../alert-form/alert-form.component";
import { AlertTitleComponent } from "../alert-title/alert-title.component";
import { DateAlerts } from "../date-alerts-common.consts";

@Component({
    selector: "rl-edit-alerts",
    templateUrl: "./edit-alerts.component.html",
    styleUrls: ["./edit-alerts.component.scss"],
    imports: [PanelSwitcherComponent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem, NgFor, PanelComponent, PanelContentDirective, NgIf, AlertTitleComponent, AlertFormComponent, LoaderComponent, AsyncPipe]
})
export class EditAlertsComponent implements OnInit {

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	templateId: number;

	private _alerts: ICharDataExtDataAlert[] = [];

	@Output()
	alertsChange = new EventEmitter<ICharDataExtDataAlert[]>();

	configAlerts: ICharDataExtDataAlert[];

	editMode = EditMode.Read;
	editingAlert: ICharDataExtDataAlert;
	editingLabel: string;
	notifications$: Observable<IWorkflowConfigProcessNotification[]>;

	isLoading = false;
	isSubmitting = false;
	recordId: number;
	parentEntityId: string;
	template: ICharacteristicMetaDataCollection;

	characteristicMetaDatas: ICharacteristicMetaData[] = [];
	notificationMessagesEnabled: boolean;

	@Input()
	set alerts(alerts: ICharDataExtDataAlert[]) {
		const alertGroups = partition(alerts, alert => alert.recordID === 0);
		this.configAlerts = first(alertGroups);
		this._alerts = last(alertGroups);
	}

	get alerts() {
		return this._alerts;
	}

	get hasAlerts() {
		return some(this.alerts);
	}

	get isLocal() {
		return this.recordId <= -1;
	}

	get localAlerts$() {
		return this._charDataTableService.localAlerts$.pipe(take(1));
	}

	constructor(
		private readonly _charDataTableService: CharDataTableService,
		private readonly _alertService: AlertsService,
		private readonly _workflowConfigService: WorkflowConfigService,
		private readonly _session: SessionService,
		private readonly _awsService: AwsService
	) { }

	ngOnInit() {
		this.characteristicMetaDatas = this._charDataTableService.template.characteristicMetaDatas;
		this.recordId = this._charDataTableService.recordID$.value;
		this.template = this._charDataTableService.template;
		this.notifications$ = this._workflowConfigService.notifications(this.template.charTypeID, this.template.templateID);
		this.parentEntityId = this._charDataTableService.entityId;
		this.templateId = this._charDataTableService.template.templateID;
		this._awsService.getMessagingDiv(this._session.divId)
			.pipe(
				tap(result => {
					this.notificationMessagesEnabled = result.notificationMessages
				})
			).subscribe();

		// Okay so it appears alerts don't get initialized if there aren't Configuration-based alerts on a characteristic. 
		// It makes sense to not call `GetAlerts` for every single date characteristic so just going to fetch them here
		// when not provided. We should probably create an endpoint to get all alerts for an entire entity and not by characteristic.
		if (isEmpty(this.alerts)) {
			this.fetchAlerts().subscribe();
		}

	}

	addCustomNotification() {
		this.addAlertFromDraft(undefined);
	}

	addAlertFromDraft(draft: IWorkflowConfigProcessNotification) {
		const recordTitle = this._charDataTableService.entityTitle$.value;
		const defaultSubject = DateAlerts.defaultSubject(this.recordId, recordTitle);
		const alert: ICharDataExtDataAlert = {
			entityID: null,
			divisionID: this._session.divId,
			charTypeID: this._charDataTableService.template.charTypeID,
			templateID: this._charDataTableService.template.templateID,
			charID: this.cmd.characteristicID,
			recordID: this.recordId,
			id: "",
			fromID: null,
			body: "",
			attachment: "",
			sequenceNumber: null,
			createdBy: null,
			createdAt: "",
			updatedBy: null,
			updatedAt: "",
			status: "",
			processID: null,
			messageID: null,
			quantity: 1,
			frequencyID: 1,
			frequencyLabel: "",
			timeframeID: 1,
			timeframeLabel: "",
			selectedPartyIDs: "",
			selectedRoleIDs: "",
			notifyChildrenPartyIds: "",
			subject: draft && draft.subject || defaultSubject,
			bodyHTML: draft && draft.bodyHTML || "",
			toEmail: this._session.userName,
			fromEmail: "",
			alertWhenSetOrUpdated: true,
			createCustomAlert: true,
			createEmailInvite: false,
			emailInviteUID: null,
			emailUpdateSequence: 0,
			templateDateCharId: null,
			templateDateContainsType: null,
			doNotSendIfRecordInactive: false,
			toID: null,
			triggerSNSOrSQS: false,
			addActionBtn: draft && draft.addActionBtn
		};

		this.editingAlert = alert;
		this.editingLabel = draft && draft.label || "Custom";
		this.editMode = EditMode.Create;
	}

	deleteAlert(alert: ICharDataExtDataAlert) {
		let deleteAlert$: Observable<void>;
		if (this.isLocal) {
			deleteAlert$ = this._charDataTableService.localAlerts$.pipe(take(1)).pipe(
				map(alerts => {
					const nextAlerts = alerts.filter(x => x.id !== alert.id);
					return nextAlerts;
				}),
				tap(alerts => this._charDataTableService.localAlerts$.next(alerts)),
				switchMap(() => of<void>(null))
			);
		} else {
			deleteAlert$ = this._alertService.deleteAlert(alert.id);
		}
		deleteAlert$.pipe(
			switchMap(() => this.fetchAlerts())
		).subscribe(() => {
			this.emitAlertsChange();
		});
	}

	editAlert(alert: ICharDataExtDataAlert) {
		this.editingAlert = cloneDeep(alert);
		this.editMode = EditMode.Edit;
	}

	backToReadView() {
		this.editMode = EditMode.Read;
	}

	addNewAlert(alert: ICharDataExtDataAlert) {
		this.isSubmitting = true;
		let createAlert$: Observable<void>;
		if (this.isLocal) {
			createAlert$ = this._charDataTableService.localAlerts$.pipe(take(1)).pipe(
				map(alerts => {
					// create a temp id and push the alert to local array
					alert.id = v4();
					alerts.push(alert);
					return alerts;
				}),
				tap(alerts => this._charDataTableService.localAlerts$.next(alerts)),
				switchMap(() => of<void>(null))
			);
		} else {
			createAlert$ = this._alertService.createAlert(alert);
		}
		createAlert$.pipe(
			finalize(() => {
				this.isSubmitting = false;
				this.editMode = EditMode.Read;
			}),
			switchMap(() => this.fetchAlerts())
		).subscribe(() => {
			this.emitAlertsChange();
		});
	}

	updateAlert(alert: ICharDataExtDataAlert) {
		this.isSubmitting = true;
		let updateAlert$: Observable<void>;
		if (this.isLocal) {
			updateAlert$ = this._charDataTableService.localAlerts$.pipe(take(1)).pipe(
				map(alerts => {
					const nextAlerts = alerts.filter(x => x.id !== alert.id);
					nextAlerts.push(alert);
					return nextAlerts;
				}),
				tap(alerts => this._charDataTableService.localAlerts$.next(alerts)),
				switchMap(() => of<void>(null))
			);
		} else {
			updateAlert$ = this._alertService.updateAlert(alert);
		}
		updateAlert$.pipe(
			finalize(() => {
				this.isSubmitting = false;
				this.editMode = EditMode.Read;
			}),
			switchMap(() => this.fetchAlerts())
		).subscribe(() => {
			this.emitAlertsChange();
		});
	}

	private fetchAlerts() {
		this.isLoading = true;
		let obs: Observable<ICharDataExtDataAlert[]>;
		if (this.isLocal) {
			// fetch the local alerts from the local char data alerts
			obs = this._charDataTableService.localAlerts$.pipe(take(1));
		} else {
			obs = this._alertService.getAlerts(this.template.charTypeID, this.template.templateID, this.cmd.characteristicID, this.recordId);
		}
		return obs.pipe(
			tap(alerts => {
				this._alerts = alerts;
			}),
			finalize(() => {
				this.isLoading = false;
			})
		);
	}

	private emitAlertsChange() {
		const alerts = this.configAlerts.concat(this._alerts);
		this.alertsChange.emit(alerts);
	}
}
