<div class="modal-header">
	<h2 class="modal-title">Associated Users</h2>
	<button type="button" class="btn btn-close" (click)="close()" title="Close">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<ul>
		<ng-container *ngFor="let associateUserDoc of associatedUsers$ | async">
			<li *ngIf="!removedCotactRecIds[associateUserDoc.recordID]">
				<div class="d-flex justify-content-between">
					<span class="flex-grow-1">{{associateUserDoc.title}}</span>
					<button class="btn btn-sm btn-remove" (click)="removeAssociatedUser(associateUserDoc.recordID)"
						title="Remove">
						<i class="far fa-times"></i>
					</button>
				</div>
			</li>
		</ng-container>
	</ul>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-info" (click)="close()">Close</button>
</div>