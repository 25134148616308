import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, FormsModule } from "@angular/forms";

@Component({
    selector: "rl-radio-input",
    templateUrl: "./radio-input.component.html",
    styleUrls: ["./radio-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioInputComponent),
            multi: true
        }
    ],
    imports: [ReactiveFormsModule, FormsModule]
})
export class RadioInputComponent implements ControlValueAccessor {

	@Input()
	label: string;

	@Input()
	name: string;

	@Input("value")
	_value;

	@Input()
	dataLabel: string = "radioInput";

	@Input()
	isDisabled = false;

	modelValue: any;

	get value() {
		return this._value;
	}

	set value(value) {
		if (value) {
			this._value = value;
			this.onChange(value);
			this.onTouched();
		}
	}

	onChange: any = (onChanges => { });

	onTouched: any = () => { };

	constructor() { }

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	writeValue(value) {
		this.modelValue = value;
	}

	valueChanged(event) {
		this.onChange(this.modelValue);
	}
}
