import { Injectable, Injector } from "@angular/core";
import { SourceFieldSectionEditComponent } from "config/components/source/source-fields/source-field-sections/source-field-section-edit/source-field-section-edit.component";
import { SourceFieldEditComponent } from "config/components/source/source-fields/source-fields-list/source-field-edit/source-field-edit.component";
import { SourcePartyEditComponent } from "config/components/source/source-parties/source-parties-list/source-party-edit/source-party-edit.component";
import { SourcePartySectionEditComponent } from "config/components/source/source-parties/source-party-sections/source-party-section-edit/source-party-section-edit.component";
import { BuildPanelElementModalAdapter } from "config/components/templates/layouts/build-panel-element-modal/build-panel-element-modal.adapter";
import { EditPanelGroupsModalComponent } from "config/components/templates/layouts/edit-panel-groups-modal/edit-panel-groups-modal.component";
import { EditPanelModalComponent } from "config/components/templates/layouts/edit-panel-modal/edit-panel-modal.component";
import { EditPanelSubTabsModalComponent } from "config/components/templates/layouts/edit-panel-sub-tabs-modal/edit-panel-sub-tabs-modal.component";
import { FieldSectionSelectCharsModalComponent } from "config/components/templates/layouts/field-section-select-chars-modal/field-section-select-chars-modal.component";
import { TemplateFieldAddModalAdapter } from "config/components/templates/template-fields/template-field-add/template-field-add.adapter";
import { TemplatePartyEditComponent } from "config/components/templates/template-parties/template-party-edit/template-party-edit.component";
import { TemplateEditComponent } from "config/components/templates/templates-landing/template-edit/template-edit.component";
import { DraftTemplateEditComponent } from "config/components/workflow/draft-templates/draft-template-edit/draft-template-edit.component";
import { WorkflowActionEditComponent } from "config/components/workflow/workflow-processes/workflow-details/workflow-details-actions/workflow-action-edit/workflow-action-edit.component";
import { WorkflowNotificationEditComponent } from "config/components/workflow/workflow-processes/workflow-details/workflow-details-notifications/workflow-notification-edit/workflow-notification-edit.component";
import { WorkflowStatusEditComponent } from "config/components/workflow/workflow-processes/workflow-details/workflow-details-statuses/workflow-status-edit/workflow-status-edit.component";
import { WorkflowEditComponent } from "config/components/workflow/workflow-processes/workflow-edit/workflow-edit.component";
import { ComponentKey } from "rl-common/components/mod-details-layout/mod-layout.consts";
import { IModLayout, IPanelGroup, IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { IWfStep } from "rl-common/services/company/company.models";
import { IParty, IPartyCollection, IPartyGroup, ISourceField, ISourceFieldSection, IWFActionConfig, IWFProcessConfig } from "rl-common/services/entity-config/entity-config.models";
import { ModalBuilder } from "rl-common/services/modal-builder/modal-builder";
import { IDocTemplateConfig, IWorkflowNotification } from "rl-common/services/workflow/workflow.models";
@Injectable({ providedIn: "root" })
export class ConfigModalService {

	constructor(
		private readonly _modalBuilder: ModalBuilder,
		private readonly _buildPanelElementModalAdapter: BuildPanelElementModalAdapter,
		private readonly _templateFieldAddModalAdapter: TemplateFieldAddModalAdapter

	) { }

	editTemplate(charTypeId: number, templateId: number = null, charTypeName = "", templates: ICharacteristicTemplate[], injector: Injector) {
		return this._modalBuilder.build<TemplateEditComponent>(TemplateEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component._charTypeId = charTypeId;
				component._templateId = templateId;
				component._charTypeName = charTypeName;
				component.templates = templates;
				return component.onComplete;
			});
	}

	addTemplateField(group: ITemplateCharacteristicGroup, templateFieldModel: ICharacteristicMetaData, templateId: number = null, injector: Injector) {
		return this._templateFieldAddModalAdapter.openModal(component => {
			component.group = group;
			component.templateFieldModel = templateFieldModel;
			component.templateId = templateId;
			return component.onComplete;
		}, { injector, backdrop: "static" });
	}

	editTemplateParty(partyCollection: IPartyCollection, groupID: number, groupName: string, partyID: number = null, injector: Injector) {
		return this._modalBuilder.build<TemplatePartyEditComponent>(TemplatePartyEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component._partyCollection = partyCollection;
				component._groupID = groupID;
				component.groupName = groupName;
				component._partyID = partyID;
				return component.onComplete;
			});
	}

	addOrEditWorkflow(processId: number = 0, wfProcessModel: IWFProcessConfig = null, injector: Injector) {
		return this._modalBuilder.build<WorkflowEditComponent>(WorkflowEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.processId = processId;
				component.wfProcessModel = wfProcessModel;
				return component.onComplete;
			});
	}

	addEditDocTemplate(docTemplateConfig: IDocTemplateConfig = null, charTypeId: number, selectedTemplateIds: number[], docTemplates: IDocTemplateConfig[], injector: Injector) {
		return this._modalBuilder.build<DraftTemplateEditComponent>(DraftTemplateEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.docTemplateConfig = docTemplateConfig;
				component.charTypeId = charTypeId;
				component.selectedTemplateIds = selectedTemplateIds;
				component.docTemplates = docTemplates;
				return component.onComplete;
			});
	}

	editSourceField(sourceField: ISourceField, charTypeId: number, charDataCount: number, hasCharValueSourceId: boolean, injector: Injector) {
		return this._modalBuilder.build<SourceFieldEditComponent>(SourceFieldEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.sourceField = sourceField;
				component.charTypeId = charTypeId;
				component.charDataCount = charDataCount;
				component.hasCharValueSourceId = hasCharValueSourceId;
				return component.onComplete;
			});
	}

	editSourceFieldSection(charTypeId: number, sourceFieldSection: ISourceFieldSection, injector: Injector) {
		return this._modalBuilder.build<SourceFieldSectionEditComponent>(SourceFieldSectionEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.charTypeId = charTypeId;
				component.sourceFieldSection = sourceFieldSection;
				return component.onComplete;
			});
	}

	editSourceParty(party: IParty, injector: Injector) {
		return this._modalBuilder.build<SourcePartyEditComponent>(SourcePartyEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.party = party;
				return component.onComplete;
			});
	}

	editSourcePartySection(sourcePartySection: IPartyGroup, injector: Injector) {
		return this._modalBuilder.build<SourcePartySectionEditComponent>(SourcePartySectionEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.sourcePartySection = sourcePartySection;
				return component.onComplete;
			});
	}

	addOrEditWorkflowStatus(statusId: number, wfStatusModel: IWfStep, injector: Injector) {
		return this._modalBuilder.build<WorkflowStatusEditComponent>(WorkflowStatusEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.statusId = statusId;
				component.wfStatusModel = wfStatusModel;
				return component.onComplete;
			});
	}

	addOrEditWorkflowAction(wfActionModel: IWFActionConfig, injector: Injector) {
		return this._modalBuilder.build<WorkflowActionEditComponent>(WorkflowActionEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.wfActionModel = wfActionModel;
				return component.onComplete;
			});
	}

	editWorkflowNotification(charTypeId: number, processId: number, notificationMessagesEnabled: boolean, notification: IWorkflowNotification, allDocTemplatesForCharType: IDocTemplateConfig[], injector: Injector) {
		return this._modalBuilder.build<WorkflowNotificationEditComponent>(WorkflowNotificationEditComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.charTypeId = charTypeId;
				component.processId = processId;
				component.notificationMessagesEnabled = notificationMessagesEnabled;
				component.notification = notification;
				component.allDocTemplatesForCharType = allDocTemplatesForCharType;
				return component.onComplete;
			});
	}

	buildPanelElement(templateMetaData: ICharacteristicMetaDataCollection, section: IPanelSection<unknown>, componentKey: ComponentKey) {
		return this._buildPanelElementModalAdapter.openModal(component => {
			component.section = section;
			component.templateMetaData = templateMetaData;
			component.componentKey = componentKey;
			return component.onComplete;
		}, { backdrop: "static" });
	}

	editPanelGroups(layout: IModLayout) {
		return this._modalBuilder.build<EditPanelGroupsModalComponent>(EditPanelGroupsModalComponent)
			.backdrop("static")
			.open(component => {
				component.layout = layout;
				return component.onComplete;
			});
	}

	editPanel(index: number, panels: IPanelGroup[]) {
		return this._modalBuilder.build<EditPanelModalComponent>(EditPanelModalComponent)
			.backdrop("static")
			.open(component => {
				component.index = index;
				component.panels = panels;
				return component.onComplete;
			});
	}

	editSubTabs(panel: IPanelGroup) {
		return this._modalBuilder.build<EditPanelSubTabsModalComponent>(EditPanelSubTabsModalComponent)
			.backdrop("static")
			.open(component => {
				component.panel = panel;
				return component.onComplete;
			});
	}

	selectFieldsForSection(templateMetaData: ICharacteristicMetaDataCollection, selectedChars: number[], injector: Injector) {
		return this._modalBuilder.build<FieldSectionSelectCharsModalComponent>(FieldSectionSelectCharsModalComponent)
			.injector(injector)
			.backdrop("static")
			.open(component => {
				component.templateMetaData = templateMetaData;
				component.selectedChars = selectedChars;
				return component.onComplete;
			});
	}
}
