import { EventEmitter, Injectable, Type } from "@angular/core";
import { WorksheetType, IWorksheet } from "rl-common/services/clipboard/clipboard.models";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
export interface IEditClipboardComponent {
	items: unknown | unknown[];
	clipboardType: WorksheetType;
	selectedSnapshot: IWorksheet<unknown>;
	isModify: boolean;
	onComplete: EventEmitter<boolean>;
	setDataSource(): void;
	clipboardTitle: string;
	showModalHeader: boolean;
}

@Injectable({ providedIn: "root" })
export class EditClipboardModalAdapter extends ModalAdapter<IEditClipboardComponent> {
	constructor(type: Type<IEditClipboardComponent>) {
		super(type);
	}

}


