

export enum TriggerEventOperator {
	EqualTo = 0,
	Between = 1,
	GreaterThan = 2,
	GreaterThanOrEqual = 3,
	LessThan = 4,
	LessThanOrEqual = 5,
	NotEmpty = 6,
	IsEmpty = 7
}