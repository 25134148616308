<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	@if (formLoaded) {
	<form (ngSubmit)="submit($event)" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<rl-form-table-row [labelText]="'Name'" [required]="true"
					[class.has-error]="form.controls.templateLabel.errors && form.controls.templateLabel.touched">
					<ng-template rlFormTableControl>
						<rl-text-input [(formControl)]="form.controls.templateLabel"
							[dataLabel]="'templatelabelInput'"></rl-text-input>
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.controls.templateLabel.errors?.required && form.controls.templateLabel.touched">
						Name is required.
					</ng-template>
					<ng-template rlFormTableMessage
						*ngIf="form.controls.templateLabel.errors?.draftTemplateLabelDuplicate && form.controls.templateLabel.touched">
						Duplicate Draft Template Name is not allowed.
					</ng-template>
				</rl-form-table-row>
				<rl-form-table-row [labelText]="'File'">
					<ng-template rlFormTableControl>
						<div class="d-flex align-items-center">
							<div class="flex-grow-1">
								{{ form.controls.filePath.value }}
								<button *ngIf="form.controls.filePath.value" type="button"
									class="btn btn-icon btn-remove btn-sm" (click)="removeFile()"
									data-cy="removeAttachment"><i class="far fa-times fa-sm"
										title="Remove Attachment"></i></button>
							</div>
							<button type="button" class="btn btn-sm btn-link" (click)="chooseFile($event)"
								data-cy="ChooseFileButton"><i class="far fa-cloud-upload"></i>
								Choose File</button>
						</div>
					</ng-template>
				</rl-form-table-row>
				<rl-form-table-row [labelText]="'Available on selected Templates'">
					<ng-template rlFormTableControl>
						<div class="scroll-vert border-top py-2 padding-left-1px">
							<div *ngFor="let template of templates; let i = index" class="char-data-element-display">
								<label class="py-1">
									<input type="checkbox" [value]="template.templateID" class="form-check-input me-2"
										[checked]="isTemplateIdChecked(template.templateID)"
										(change)="onSelectedTemplateIdsChange(template.templateID, $event.target.checked)"
										[attr.data-cy]="'checkboxInputAvailableTemplate' + template.templateName | trimWhitespace" />
									{{
									template.templateName }}
								</label>
							</div>
						</div>
					</ng-template>
				</rl-form-table-row>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="closeButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton">Save</button>
		</div>
	</form>
	} @else {
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
	}
</div>