@if( isLoading ) {
<div class="py-3 d-flex justify-content-center">
	<rl-loader></rl-loader>
</div>
} @else {
<form [formGroup]="formGroup">
	<rl-form-table-row [labelText]="'Choose When to Send'">
		<ng-template rlFormTableControl>
			<div class="mb-2 mt-1">
				<label class="py-0">
					<input type="radio" class="form-check-input me-2" [value]="whenSetOrUpdatedOption"
						formControlName="alertWhen" />
					When <span class="px-1"><strong>{{ cmd.label }}</strong></span> is first set or updated.
				</label>
			</div>
			<div class="mb-2 d-flex align-items-center">
				<div class="d-flex align-items-center me-2">
					<input type="radio" class="me-2" [value]="onDayBeforeOrAfterOption" formControlName="alertWhen" />
					<div class="input-group" [class.has-error]="quantityControl.invalid">
						<div class="input-group-prepend">
							<span class="input-group-text" aria-label="#" role="img">
								<i class="far fa-hashtag"></i>
							</span>
						</div>
						<input type="number" class="form-control" style="width:80px;" formControlName="quantity">
					</div>
				</div>
				<div class="d-flex align-items-center me-2">
					<select class="form-select" formControlName="frequencyId">
						<option *ngFor="let result of frequencyList$ | async" [ngValue]="result.value">
							{{ result.label }}
						</option>
					</select>
				</div>
				<div class="d-flex align-items-center">
					<select class="form-select" formControlName="timeframeID">
						<option *ngFor="let result of timeFrameList$ | async" [ngValue]="result.value">
							{{ result.label }}
						</option>
					</select>
					<strong class="ps-3 text-nowrap">{{ cmd.label }}</strong>
				</div>
			</div>
			<div class="d-flex mb-2 align-items-center">
				<span class="text-medium ms-4 me-2 py-3"><strong>AND</strong></span>
				@if ( !hasAnotherDate ) {
				<button class="btn btn-sm btn-link" (click)="addAnotherDate()"
					[disabled]="alertWhenSetOrUpdated || hasNoOtherDates">
					<i class="far fa-plus mx-1"></i> Add another date
				</button>
				} @else {
				<select class="form-select w-auto me-2" formControlName="templateDateCharId">
					<option *ngFor="let date of otherTemplateDates" [ngValue]="date.characteristicID">
						{{ date.label }}
					</option>
				</select>
				<select class="form-select w-auto me-2" formControlName="templateDateContainsType">
					<option *ngFor="let containsType of alertContainsTypes$ | async" [ngValue]="containsType.value">
						{{ containsType.label }}
					</option>
				</select>
				<button class="btn btn-sm btn-link btn-remove me-2" (click)="removeOtherDate()"
					[disabled]="alertWhenSetOrUpdated">
					<i class="far fa-times mx-1"></i> Remove
				</button>
				}
			</div>
		</ng-template>
	</rl-form-table-row>

	<rl-form-table-row [labelText]="'Choose How to Send'">
		<ng-template rlFormTableControl>
			<div class="my-1 d-flex align-items-center">
				<label class="me-3 py-0" for="emailNotification">
					<input type="checkbox" (change)="updateAddBtn()" class="me-2 form-check-input"
						formControlName="createCustomAlert" name="emailNotification" />Email Notification
				</label>
				<label class="me-3 py-0" for="calendarInvite">
					<input type="checkbox" class="me-2 form-check-input" formControlName="createEmailInvite"
						name="calendarInvite" />Calendar Invite
				</label>
				<label class="me-3 py-0" for="doNotSend">
					<input type="checkbox" class="me-2 form-check-input" formControlName="doNotSendIfRecordInactive"
						name="doNotSend" />Do not send if Record is inactive
				</label>
				<label class="py-0" for="triggerMessage">
					<input type="checkbox" class="me-2 form-check-input" formControlName="triggerSNSOrSQS"
						name="triggerMessage" />Trigger SNS/SQS Message
					<span class="btn-icon btn-info btn-sm cursor-pointer text-medium"
						*ngIf="!notificationMessagesEnabled">
						<i aria-label="Tooltip" role="button" data-pendo="enable-notification-messages"
							class="far fa-question-circle"></i>
					</span>
				</label>
			</div>
		</ng-template>
	</rl-form-table-row>

	<rl-form-table-row [labelText]="'Add Action Button'">
		<ng-template rlFormTableControl>
			<label class="py-1">
				<input type="checkbox" formControlName="addActionBtn" [value]="1" class="form-check-input me-2"
					data-cy="checkboxInputAddActionButtonYes" />
				Yes
			</label>
		</ng-template>
	</rl-form-table-row>


	<rl-form-table-row [labelText]="'Subject'" class="mt-3">
		<ng-template rlFormTableControl>
			<input class="form-control" type="text" formControlName="subject" placeholder="Subject" />
		</ng-template>
	</rl-form-table-row>

	<rl-form-table-row [labelText]="'Body'">
		<ng-template rlFormTableControl>
			<rl-html-editor formControlName="bodyHtml"></rl-html-editor>
		</ng-template>
	</rl-form-table-row>

	<rl-form-table-row [labelText]="'Send To'">
		<ng-template rlFormTableControl>
			<div class="d-flex align-items-start">
				<div class="input-group w-auto">
					<input type="text" class="form-control" formControlName="email"
						placeholder="john.smith@example.com">
					<div class="input-group-append">
						<button class="btn btn-success btn-icon btn-create" (click)="addEmail()"
							[disabled]="emailIsEmpty || !emailFormControl.valid" title="Add Email">
							<i class="far fa-plus"></i>
						</button>
					</div>
				</div>
				<div class="col px-2">
					<rl-chip (onRemove)="removeEmail(i)" [disabled]="!canRemoveRecipients"
						*ngFor="let email of emails;let i = index">
						{{ email }}
					</rl-chip>
				</div>
			</div>
			<div class="d-flex align-items-center mt-1">
				<div class="me-2 w-100" ngbDropdown autoClose="outside" placement="top-left"
					#notificationRolesDropdown="ngbDropdown">
					<button class="form-select text-start hide-caret" ngbDropdownToggle role="button">Select Roles
						({{ selectedRoleCount }})</button>
					<div ngbDropdownMenu>
						<div class="scroll-vert position-relative mini-table-container mx-2">
							<table class="table table-hover mini-table w-100">
								<thead>
									<tr>
										<th class="col-id">
											<input type="checkbox" class="form-check-input me-1"
												(click)="selectAllRoles()" [checked]="allRolesSelected" />
											ID
										</th>
										<th class="col-title">ROLE</th>
										<th class="col-members">#</th>
									</tr>
								</thead>
								<tbody>
									@for (role of roles; track role.roleId) {
									<tr>
										<td class="col-id">
											<input type="checkbox" [checked]="selectedRoleIds.includes(role.roleId)"
												class="form-check-input me-1" (click)="toggleRole(role)" />
											{{ role.roleId }}
										</td>
										<td class="col-title">{{ role.roleName }}</td>
										<td class="col-members">{{ role.memberCount }}</td>
									</tr>
									}
								</tbody>
							</table>
						</div>
						<hr class="m-0">
						<div class="position-relative mini-table-container mx-2">
							<table class="table table-hover mini-table w-100">
								<thead>
									<tr>
										<th class="col-id bg-white"></th>
										<th class="col-title bg-white">Total Selected Members:</th>
										<th class="col-members bg-white"
											[ngClass]="{'text-success' : selectedRoleMemberCount <= 99, 'text-danger' : selectedRoleMemberCount > 99}">
											{{ selectedRoleMemberCount }}
										</th>
									</tr>
								</thead>
							</table>
						</div>
					</div>
				</div>
				<div class="me-2 w-100" ngbDropdown autoClose="outside" placement="top-left"
					#notificationRolesDropdown="ngbDropdown">
					<button class="form-select text-start hide-caret" ngbDropdownToggle role="button">Select Parties ({{
						selectedPartyCount }})</button>
					<div ngbDropdownMenu>
						<div class="scroll-vert position-relative mini-table-container showNotifyChild mx-2">
							<table class="table table-hover mini-table w-100">
								<thead>
									<tr>
										<th class="col-id">
											<input type="checkbox" class="form-check-input me-1"
												(click)="selectAllParties()" [checked]="allPartiesSelected" />
											ID
										</th>
										<th class="col-title">PARTY</th>
										<th class="col-notify-child">
											<span class="form-check form-switch form-switch-inline position-relative">
												<input type="checkbox" class="form-check-input me-1"
													[disabled]="!selectedPartyIds.length"
													[(ngModel)]="selectAllNotifyChildContactsChecked"
													[ngModelOptions]="{standalone: true}"
													(click)="selectAllNotifyChildContacts($event.target.checked)"
													data-cy="NotifyChildContactsSwitch" />
												<label class="position-relative">NOTIFY CHILD CONTACTS</label>
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									@for (party of parties; track party.partyID) {
									<tr>
										<td class="col-id">
											<input type="checkbox" class="form-check-input me-1"
												[checked]="selectedPartyIds.includes(party.partyID)"
												(click)="toggleParty(party)" />
											{{ party.partyID }}
										</td>
										<td class="col-title">{{ party.partyName }}</td>
										<td class="col-notify-child">
											<span class="form-check form-switch form-switch-inline">
												<input class="form-check-input" type="checkbox"
													[(ngModel)]="selectedPartyNotifyChildren[party.partyID]"
													[ngModelOptions]="{standalone: true}"
													(change)="allPartyNotifyChildrenIdsSelected()"
													[disabled]="selectedPartyIds.indexOf(party.partyID) === -1"
													[attr.data-cy]="'NotifyParty' + party.partyID" />
											</span>
										</td>
									</tr>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<em class="d-block mt-4 mb-1">Note: To prevent spam designation and delivery delays, we recommend a limit of
				100 recipients (emails, Roles & Parties combined).</em>
		</ng-template>
	</rl-form-table-row>

	<div class="d-flex mt-4 pt-3 border-top justify-content-end">
		<button type="button" class="btn btn-light" (click)="cancel()" [disabled]="isSubmitting">Cancel</button>
		<button type="submit" class="btn btn-success ms-3" (click)="save()"
			[disabled]="!formGroup.valid || isSubmitting">
			<i *ngIf="isSubmitting" class="far fa-spinner fa-spin me-2"></i> Save
		</button>
	</div>

</form>
}