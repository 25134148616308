import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { isEmpty } from "lodash";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { CharTypeId, ConstUtils } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { AssociationService } from "rl-common/services/associations/association.service";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { QueryUtil } from "rl-common/utils";
import { Subscription } from "rxjs";
import { InputListResultsComponent } from "./input-list-results/input-list-results.component";
import { IValidateInputListResult } from "./input-list.models";

export enum ValidateMode {
	RecordIds = "recordIds",
	Titles = "titles"
}

export interface IValidateEvent {
	validRecordsDeprecated: IRecordTitle[];
	validRecords: IEntitySearchDoc[];
	deselectedRecords?: IEntitySearchDoc[];
}

@Component({
    selector: "rl-input-list",
    templateUrl: "./input-list.component.html",
    styleUrls: ["./input-list.component.scss"],
    imports: [ReactiveFormsModule, NgFor, InputListResultsComponent]
})
export class InputListComponent implements OnInit, OnDestroy {

	@Input()
	childCharTypeId: number;

	@Input()
	charTypeId: number;

	@Input()
	matchesParentQuery: IQueryNode;

	@Input()
	includeParentRecordId = false;

	@Input()
	isAssociatingChild = true;

	@Output()
	onValidate = new EventEmitter<IValidateEvent>();

	results: IValidateInputListResult;
	previousResults: IEntitySearchDoc[];
	toBeRemoved: IEntitySearchDoc[] = [];

	inputTypeList: { text: string, value: ValidateMode }[] = [
		{ text: "Input a List of IDs", value: ValidateMode.RecordIds },
		{ text: "Input a List of Exact Record Names", value: ValidateMode.Titles }
	];

	inputListForm: UntypedFormGroup;
	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _associationService: AssociationService,
		private readonly _parentEntityService: ParentEntityService,
		@Optional() private readonly _modDetailService: ModDetailService,
		private fb: UntypedFormBuilder
	) { }

	get rawInput() {
		return this.inputListForm.get("rawInputControl").value;
	}

	get validateMode() {
		return this.inputListForm.get("inputTypeControl").value;
	}

	ngOnInit() {
		this.inputListForm = this.fb.group({
			inputTypeControl: [ValidateMode.RecordIds],
			rawInputControl: [""]
		});
	}

	private splitRows() {
		return this.rawInput.split(/\r\n|\r|\n/g).filter(x => !isEmpty(x));
	}

	validate() {
		const recordIds = this.validateMode === ValidateMode.RecordIds ? this.splitRows().filter(Number).map(i => +i) : [];
		const titles = this.validateMode === ValidateMode.Titles ? this.splitRows() : [];
		const parentParams = this.parentParams();
		const parentCharTypeId = parentParams[0];
		const parentTemplateId = parentParams[1];
		const parentRecordId = parentParams[2];
		let query = {};
		if (recordIds.length > 0) {
			query = QueryUtil.$or(QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, recordIds))
		}

		if (this.charTypeId === CharTypeId.Right && this.childCharTypeId === CharTypeId.Property) {
			query = QueryUtil.$and(this.matchesParentQuery, query);
		}

		const sub = this._associationService.validateInputList(parentCharTypeId, parentTemplateId, this.childCharTypeId, recordIds, titles, parentRecordId, !this.isAssociatingChild, query)
			.subscribe(results => {
				this.results = results;
				const validRecordsDeprecated: IRecordTitle[] = results.valid.map(r => {
					return { recordId: r.recordID, title: r.title };
				});
				if (!this.previousResults) {
					this.previousResults = results.valid;
				} else {
					const prevId = this.previousResults.map(x => x.recordID);
					const currentId = results.valid.map(x => x.recordID);
					const remove = prevId.filter(x => currentId.indexOf(x) < 0);
					this.toBeRemoved = this.previousResults.filter(x => remove.indexOf(x.recordID) >= 0);
					this.previousResults = results.valid;
				}
				this.onValidate.emit({
					validRecordsDeprecated: validRecordsDeprecated,
					validRecords: results.valid,
					deselectedRecords: this.toBeRemoved
				});
			});

		this._subs.push(sub);
	}

	/**
	 * Builds a tuple that contains all the parent parameters for the validation sproc
	 * @returns [parentCharTypeId, parentTemplateId, parentRecordId]
	 */
	private parentParams(): [number, number, number] {
		let tuple: [number, number, number];

		// pull the parent params from the parent entity service or the mod detail service
		if (ConstUtils.isComponentLevelCharType(this.charTypeId) && this._parentEntityService) {
			tuple = [this._parentEntityService?.charTypeId ?? 0, this._parentEntityService?.templateId ?? 0, this._parentEntityService?.recordId ?? null];
		} else {
			tuple = [this._modDetailService?.charTypeId ?? 0, this._modDetailService?.entity?.templateId ?? 0, this._modDetailService?.recordId ?? null];
		}

		// set the parent record id to null if we're not meant to send it or it doesn't exist
		const parentExists = tuple[2] > 0;
		if (!this.includeParentRecordId || !parentExists) {
			tuple[2] = null;
		}

		return tuple;
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}
}
