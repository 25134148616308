<div [ngSwitch]="overwriteSyncMode" class="modal-header mt-4">
	<div *ngSwitchCase="'overwrite'">
		<h4>Overwrite Parties on all Associated Module Records</h4>
		The selected Parties' Contacts will be added and/or removed on all directly associated module records. Any
		matching Parties will be replaced with the Contacts from this record's Parties.
	</div>
	<div *ngSwitchCase="'sync'">
		<h4>Resync Contact to Master</h4>
	</div>
	<div *ngSwitchCase="'overwriteCatalog'">
		<h4>Sync Parties Down Catalog Hierarchy</h4>
		The selected Parties' Contacts will only be added to child Catalog Items. No Contacts will be removed.
	</div>
</div>
<div class=" modal-body">
	<rl-party-selector [partyDictionary]="partyDictionary" (onChange)="updateList($event)">
	</rl-party-selector>
</div>

<div class=" modal-footer d-flex justify-content-end border-top pt-3">
	<button class="btn btn-light" (click)="cancel()">
		Cancel
	</button>
	<button class="btn btn-info ms-3" (click)="apply()">
		<span [ngSwitch]="overwriteSyncMode">
			<span *ngSwitchCase="'overwrite'">
				Overwrite
			</span>
			<span *ngSwitchCase="'sync'">
				Resync
			</span>
			<span *ngSwitchCase="'overwriteCatalog'">
				Sync
			</span>
		</span>
	</button>
</div>