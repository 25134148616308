import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { first } from "lodash";
import { ICharDataChangedEvent } from "rl-common/components/char-data/char-data.models";
import { CmdVisibilityService } from "rl-common/components/char-data/cmd-visibility.service";
import { CreateEntityCmdVisibilityService } from "rl-common/components/create-workflow/create-entity-cmd-visibility.service";
import { TemplateGroupIds } from "rl-common/components/execute-workflow-modal/execute-workflow-wizard/preview-document/preview-document.models";
import { AssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { IModLayout } from "rl-common/components/mod-details-layout/mod-layout.models";
import { CharTypeId, ConstUtils } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { ContactService } from "rl-common/services/contact/contact.service";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { LayoutsService } from "rl-common/services/layouts/layouts.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { AclUtil } from "rl-common/utils/acl.util";
import { of, Subscription } from "rxjs";
import { delay, map, switchMap, take, tap } from "rxjs/operators";
import { CharTypeNamePipe } from "../../../../pipes/char-type-name.pipe";
import { CharDataTableComponent } from "../../../char-data/char-data-table.component";
import { ShowOnlyDropdownComponent } from "../../../create-workflow/show-only-dropdown/show-only-dropdown.component";
import { ModLayoutCharDataTableComponent } from "../../../mod-layout-char-data-table/mod-layout-char-data-table.component";
import { LoaderComponent } from "../../../panel/loader/loader.component";
import { IDirectionChangedEvent } from "../../new-association.models";
import { RelationshipTypeSelectComponent } from "../relationship-type-select/relationship-type-select.component";
import { UploadFolderRequest } from "./../../../../services/document/document.models";
import { DocumentService } from "./../../../../services/document/document.service";
import { FileStackService } from "./../../../../services/file-stack/file-stack.service";
import { IAssociatedTemplateOption } from "./create-new-association.models";

export interface INewAssociationChangedEvent {
	template: ICharacteristicTemplate;
	charData: ICharacteristicData[];
}

@Component({
    selector: "rl-create-new-association",
    templateUrl: "./create-new-association.component.html",
    styleUrls: ["./create-new-association.component.scss"],
    providers: [CmdVisibilityService, CreateEntityCmdVisibilityService],
    imports: [NgIf, RelationshipTypeSelectComponent, ReactiveFormsModule, FormsModule, NgFor, ShowOnlyDropdownComponent, ModLayoutCharDataTableComponent, LoaderComponent, CharDataTableComponent, AsyncPipe, CharTypeNamePipe]
})
export class CreateNewAssociationComponent implements OnInit, OnDestroy {

	@Input()
	form: UntypedFormGroup;

	@Input()
	parentCharTypeId: CharTypeId;

	@Input()
	parentTitle: string;

	@Input()
	charTypeId: CharTypeId;

	@Input()
	parentTemplateId: number;

	@Input()
	partyId?: number;

	@Input()
	relationshipDirectionTypes: RelationshipTypes[];

	@Input()
	direction = RelationshipTypes.Child;

	@Input()
	dataSource: AssocModuleSelectDataSource<IEntitySearchDoc, IEntitySearchDoc>;

	@Output()
	onDirectionChange = new EventEmitter<IDirectionChangedEvent>();

	@Output()
	onFolderUploaded = new EventEmitter<string>();

	defaultAssociatedTemplateOption: IAssociatedTemplateOption = {
		label: "",
		template: null
	};

	associatedTemplateOptions: IAssociatedTemplateOption[] = [this.defaultAssociatedTemplateOption];
	selectedAssociatedTemplateIndex = 0;
	associatedTemplate: ICharacteristicTemplate;
	showUploadFolderMenu = false;

	documentFileTemplates: IAssociatedTemplateOption[] = [];
	documentFolderTemplates: IAssociatedTemplateOption[] = [];
	documentTypeSelectionIndex: number;

	templateMetaData: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];

	editMode = true;
	isLoading = false;

	useFieldSections$ = this._featureService.isEnabled$(FeatureKeys.ConfigurableLayouts).pipe(
		take(1),
		map(isEnabled => isEnabled && ConstUtils.isModuleLevelCharType(this.charTypeId))
	);

	layout: IModLayout;

	private readonly _subscriptions: Subscription[] = [];

	get charDataTableReady() {
		return this.associatedTemplate && this.templateMetaData && this.charData;
	}

	get isIntraCharExcludingDocument() {
		return this.charTypeId === this._parentEntityService.charTypeId && this.charTypeId !== CharTypeId.Document;
	}

	get newTemplateControl() {
		return this.form.get("newAssociationTemplate");
	}

	get charDataControl(): FormControl<ICharDataChangedEvent> {
		return this.form.get("newAssociationCharData") as FormControl<ICharDataChangedEvent>;
	}

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly _contactService: ContactService,
		private readonly _session: SessionService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _fileStackService: FileStackService,
		private readonly _documentService: DocumentService,
		private readonly _featureService: FeatureService,
		private readonly _layoutService: LayoutsService,
		cmdVisibilityService: CmdVisibilityService,
		readonly createCmdVisibilityService: CreateEntityCmdVisibilityService
	) {
		cmdVisibilityService.setStrategy(createCmdVisibilityService);
	}

	ngOnInit() {
		const templatesSub = this.loadTemplates$().subscribe();
		this._subscriptions.push(templatesSub);
		const newAssociationTemplateControl = this.form.get("newAssociationTemplate");
		const sub = newAssociationTemplateControl.valueChanges.pipe(delay(0)).subscribe((value) => {
			if (!value) {
				this.selectedAssociatedTemplateIndex = 0;
				this.templateMetaData = null;
			} else {
				const template = value as ICharacteristicTemplate;
				this.selectedAssociatedTemplateIndex = this.associatedTemplateOptions.findIndex(x => x.template && x.template.templateID === template.templateID);
			}
		});

		if (this.dataSource?.isLocked) {
			this.direction = RelationshipTypes.Parent;
			this.directionChanged();
		}

		this._subscriptions.push(sub);
	}

	private loadTemplates$() {
		this.selectedAssociatedTemplateIndex = 0;
		this.templateMetaData = null;
		this.charData = null;
		this.charDataControl.setValue({ charData: [], isValid: false, alerts: [] });

		let assocTemplates: ICharacteristicTemplate[] = [];
		switch (this.direction) {
			case RelationshipTypes.Child:
				assocTemplates = this._oneConfigService.getChildAssocTemplates(this.parentCharTypeId, this.parentTemplateId, this.charTypeId);
				break;
			case RelationshipTypes.Parent:
				assocTemplates = this._oneConfigService.getParentAssocTemplates(this.parentCharTypeId, this.parentTemplateId, this.charTypeId);
				break;
			default:
				break;
		}

		let templates$ = of(assocTemplates);

		if (this.partyId) {
			templates$ = this._contactService.getPartyTemplates(this.parentCharTypeId, this.parentTemplateId, [this.partyId])
				.pipe(map(dictionary => dictionary[this.partyId]));
		}

		return templates$.pipe(
			tap(result => {
				const acls = this._session.acls;
				const templates = result
					.filter(x => AclUtil.hasCreateAccess(acls, x.acl))
					.filter(x => AclUtil.hasWriteAccess(acls, x.acl))
					.map<IAssociatedTemplateOption>(x => ({ label: x.templateName, template: x }));
				this.associatedTemplateOptions = [this.defaultAssociatedTemplateOption, ...templates];
				this.selectedAssociatedTemplateChanged();
				this.documentFileTemplates = templates.filter(x => x.template.templateGroupID !== TemplateGroupIds.DocumentFolderTemplateGroupID);
				this.documentFolderTemplates = templates.filter(x => x.template.templateGroupID === TemplateGroupIds.DocumentFolderTemplateGroupID);
			})
		);
	}

	selectedAssociatedTemplateChanged() {
		if (+this.selectedAssociatedTemplateIndex === this.associatedTemplateOptions.length) {
			this.showUploadFolderMenu = true;
		} else {
			this.layout = null;
			this.associatedTemplate = this.associatedTemplateOptions[this.selectedAssociatedTemplateIndex].template;
			this.templateMetaData = null;
			this.showUploadFolderMenu = false;
			if (this.associatedTemplate) {
				this.templateMetaData = this._oneConfigService.getTemplateMetaData(this.charTypeId, this.associatedTemplate.templateID);
				const sub = this.useFieldSections$.pipe(
					switchMap(useFieldSections => useFieldSections ? this._layoutService.getLayout(this.charTypeId, this.associatedTemplate.templateID) : of(null)),
					map(x => x?.layout)
				).subscribe((layout) => {
					this.layout = layout;
				});
				this._subscriptions.push(sub);
			}
			this.newTemplateControl.setValue(this.associatedTemplate);
			this.charData = [];
			this.charDataControl.markAsPristine();
		}
	}

	directionChanged() {
		const templatesSub = this.loadTemplates$().subscribe();
		this._subscriptions.push(templatesSub);
		this.onDirectionChange.emit({ direction: this.direction });
	}

	chooseFile() {
		const options = this._fileStackService.getBasicFileStackOptions();
		options.maxFiles = 100;
		options.minFiles = 1;
		options.onUploadDone = (response) => {
			const request: UploadFolderRequest = {
				entityId: this._parentEntityService.entityId,
				folderTemplateId: first(this.documentFolderTemplates).template.templateID,
				fileTemplateId: this.documentFileTemplates[this.documentTypeSelectionIndex].template.templateID,
				originalFilePaths: response.filesUploaded.map(x => x.originalPath),
				filePickerPaths: response.filesUploaded.map(x => x.key)
			};
			this._documentService.uploadFolder(request).pipe(
				tap((job) => this.onFolderUploaded.emit(job.jobId)),
			).subscribe();
		};
		this._fileStackService.upload(options);
	}

	setCharData(event: ICharDataChangedEvent) {
		// TODO: Figure out why we can't use the char data table in a reactive form
		this.charDataControl.markAsDirty();
		this.charData = event.charData;
		if (event.isValid) {
			this.charDataControl.setValue(event);

		} else {
			this.charDataControl.setValue(null);
		}

	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
