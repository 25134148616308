import { NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { chain, flatMap, isEmpty, isEqual, some } from "lodash";
import { AdvancedSearch } from "rl-common/components/advanced-search/rl-advanced-search.consts";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { CharDataType, CharTypeId, VirtualCharTagLabels } from "rl-common/consts";
import { ICharacteristic } from "rl-common/models/i-characteristic";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IQueryNode } from "rl-common/models/i-query-node";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SearchService } from "rl-common/services/search/search.service";
import { QueryUtil } from "rl-common/utils";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { of, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { MultiValueInputComponent } from "../multi-value-input/multi-value-input.component";
import { IRangeValueChangedEvent, RangeValueInputComponent } from "../range-value-input/range-value-input.component";
import { SingleValueInputComponent } from "../single-value-input/single-value-input.component";

@Component({
	selector: "rl-query-node-value-entry",
	templateUrl: "./query-node-value-entry.component.html",
	styleUrls: ["./query-node-value-entry.component.scss"],
	imports: [NgIf, NgTemplateOutlet, RangeValueInputComponent, SingleValueInputComponent, MultiValueInputComponent]
})
export class QueryNodeValueEntryComponent implements OnInit, OnDestroy, OnChanges {

	@Input()
	comparator: AdvancedSearch.IComparatorOption;

	@Output()
	valuesEntered = new EventEmitter<string[]>();

	@Input()
	values: string[];

	@Input()
	characteristic: ICharacteristic;

	form: UntypedFormGroup;
	solrDataOption: AdvancedSearch.ISolrDataOption;
	isLoading = false;

	private readonly _subscriptions: Subscription[] = [];

	get isRange() {
		return this.comparator.value === AdvancedSearch.Comparator.Between;
	}

	constructor(private readonly _oneConfigService: OneConfigService, private readonly _searchService: SearchService) { }

	ngOnInit() {

	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.characteristic && !isEqual(changes.characteristic.previousValue, changes.characteristic.currentValue)) {
			this.buildSolrDataOption();
		}

		if (changes.comparator && !isEqual(changes.comparator.previousValue, changes.comparator.currentValue)) {
			this.buildSolrDataOption();
		}

		if (changes.values && !isEqual(changes.values.previousValue, changes.values.currentValue)) {
			this.buildSolrDataOption();
		}
	}

	buildSolrDataOption() {
		if (this.characteristic) {
			this.solrDataOption = AdvancedSearch.buildSolrDataOption(this.characteristic);
			this.solrDataOption.allowMultipleSelections = this.comparator.isSetQuery;
			this.setSearchFn();
		}
	}

	private setSearchFn() {
		if (this.characteristic.tagLabel === VirtualCharTagLabels.CreatedBy) {
			this.solrDataOption.searchFn$ = (searchTerm) => {
				let query: IQueryNode = {};
				if (!isEmpty(this.values)) {
					query = QueryUtil.$eq_none(SearchFieldNames.Entity.recordID, this.values);
				}
				return this._searchService.search(CharTypeId.User, searchTerm ?? "", query, { sortField: "score", rows: 10, }).pipe(
					map(results => results.documents.map(doc => ({ label: `${doc.title} (${doc.recordID})`, valueId: doc.recordID.toString() } as AdvancedSearch.IAllowedValue)))
				);
			};
			this.solrDataOption.placeholder = `Search ${CharTypeIdUtil.toDisplayNamePlural(CharTypeId.User)}`;
			this.solrDataOption.allowAnyValue = true;
			return;
		}

		let allowedValues: AdvancedSearch.IAllowedValue[] = null;
		if (!isEmpty(this.characteristic.charValueSetID) && this.characteristic.dataTypeID !== CharDataType.Money) {
			const lovMetaData = this._oneConfigService.getCharacteristicLovMetaData(this.characteristic.charTypeID, this.characteristic.characteristicID);
			if (lovMetaData) {
				const allLovValues = flatMap([...lovMetaData], (x) => x[1].listOfValues);
				const distinctValues = allLovValues.reduce<Map<number, AdvancedSearch.IAllowedValue>>((labels, next) => {
					this.pushLabels(labels, next);
					return labels;
				}, new Map<number, AdvancedSearch.IAllowedValue>());
				allowedValues = [...distinctValues].map(x => x[1]).sort((a, b) => a.label.localeCompare(b.label));
			}
		} else if (this.characteristic.tagLabel === VirtualCharTagLabels.Template) {
			allowedValues = this._oneConfigService.getTemplates(this.characteristic.charTypeID)
				.map(template => {
					const value: AdvancedSearch.IAllowedValue = { label: template.templateName, valueId: template.templateName };
					return value;
				});
		} else if (this.characteristic.tagLabel === VirtualCharTagLabels.Status) {
			const processes = this._oneConfigService.getCharTypeProcesses(this.characteristic.charTypeID);
			allowedValues = chain(processes)
				.flatMap(x => x.workflowSteps)
				.uniqBy(x => x.stepName)
				.orderBy(x => x.stepName)
				.value()
				.map(step => {
					const value: AdvancedSearch.IAllowedValue = { label: step.stepName, valueId: step.stepName };
					return value;
				});
		}

		if (allowedValues) {
			this.solrDataOption.searchFn$ = (searchTerm) => {
				const remainingValues = allowedValues.filter(x => !this.values.includes(x.valueId));
				const searchLower = searchTerm?.toLowerCase() ?? "";
				const results = remainingValues.filter(x => x.label.toLowerCase().indexOf(searchLower) > -1);
				return of(results);
			};
		}
	}

	emitValues() {
		this.valuesEntered.emit(this.values);
	}

	emitSingle(value: string) {
		this.values = value ? [value] : [];
		this.emitValues();
	}

	emitMultiValues(values: string[]) {
		this.values = values;
		this.emitValues();
	}

	emitRange(event: IRangeValueChangedEvent) {
		this.values = [event.start, event.end];
		this.emitValues();
	}

	private pushLabels(labels: Map<number, AdvancedSearch.IAllowedValue>, cmd: ICharacteristicMetaDataValue) {
		if (!labels.has(cmd.characteristicValueID)) {
			labels.set(cmd.characteristicValueID, { label: cmd.characteristicValueLabel, valueId: cmd.characteristicValueLabel });
		}
		if (cmd.childValues && some(cmd.childValues)) {
			cmd.childValues.forEach(child => this.pushLabels(labels, child));
		}
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
