<div class="modal-header">
	<h2 class="modal-title">Give Rightsline Access to an Existing Contact</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<aw-wizard [awNavigationMode] class="rl-wizard mt-3" navBarLayout="small">
	<aw-wizard-step [stepTitle]="'Select Contact'">
		<div class="modal-body">
			<rl-entity-search *ngIf="!loadingUserApps && dataSource" [dataSource]="dataSource"
				[searchOptions]="searchOptions" target="_blank" [alwaysShowHeader]="true"
				[previousSearch]="searchParams">
			</rl-entity-search>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()">Cancel</button>
			<button class="btn btn-info ms-3" type="button"
				[disabled]="!selectedRecordIdControl.valid || !this.username" awNextStep
				(click)="checkUserExistsInOtherDiv()">
				<span>Continue</span>
			</button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Add Details'">
		<div [hidden]="loadingEntity">
			<div class="container-fluid bg-light p-3 mt-3">
				<div class="row">
					<div class="col">
						<form>
							<div class="row mb-0">
								<label class="col-sm-4 col-form-label text-end">
									Username (email address):
								</label>
								<div class="col-sm-8 col-form-label">
									<strong>{{ username }}</strong>
								</div>
							</div>
							<div class="row mb-0">
								<label class="col-sm-4 col-form-label text-end">
									Full Name:
								</label>
								<div class="col-sm-8 col-form-label">
									<strong>{{ selectedRecord?.title }}</strong>
								</div>
							</div>
							<div class="row mb-0">
								<label class="col-sm-4 col-form-label text-end">
									Template:
								</label>
								<div class="col-sm-8 col-form-label">
									{{ template?.template?.templateName }}
								</div>
							</div>
						</form>
					</div>
					<div class="col">
						<form>
							<div class="row">
								<label class="col-sm-4 col-form-label text-end">
									<span class="me-1" [class.text-danger]="!appUrlIdsControl.valid">*</span>
									Grant Access to:
								</label>
								<div class="col-sm-8 col-form-label ps-0">
									<rl-select-apps (onChange)="selectAppUrlIds($event)"></rl-select-apps>
								</div>
							</div>
							<div class="mb-2 row">
								<label class="col-sm-4 col-form-label text-end">
									Disable Event Messages:
								</label>
								<div class="col-sm-8 col-form-label form-check">
									<label class="form-check-label">
										<input type="checkbox" class="me-2 form-check-input" id="audit-disable"
											(change)="selectDisableAuditMessages($event)"
											data-cy="DisableMessagesCheckbox" />
										SNS/SQS/EventBridge messages will not be generated for this user's activity
									</label>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="modal-body">
				<div class="d-flex justify-content-between align-items-center mt-3">
					<rl-show-only-dropdown></rl-show-only-dropdown>
					<div class="has-error"
						*ngIf="charDataTable?.tableFormGroup?.disabled && !appUrlIdsControl.valid || !charDataTable.valid">
						<p class="help-block">Contact your administrator to provide
							values in required fields.
						</p>
					</div>
				</div>
				<rl-char-data-table [charData]="charData" [template]="template"
					[editMode]="true" (tableCharDataChange)="updateCharData($event.charData)" #charDataTable>
				</rl-char-data-table>
			</div>
		</div>
		<div [hidden]="!loadingEntity" class="modal-body">
			<rl-loader></rl-loader>
		</div>
		<div class="modal-footer">
			<button class="btn btn-info ms-5" awPreviousStep>
				<i class="far fa-chevron-left me-2"></i>Back
			</button>
			<button type="button" class="btn btn-light ms-3" (click)="close()">Cancel</button>
			<button class="btn btn-info ms-3" type="button" [disabled]="!appUrlIdsControl.valid || !charDataTable.valid"
				awNextStep>
				Next <i class="far fa-chevron-right ms-2"></i>
			</button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Select Roles'">
		<div class="container-fluid bg-light p-3 mt-3">
			<div class="row">
				<div class="col">
					<form>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								Username (email address):
							</label>
							<div class="col-sm-8 col-form-label">
								<strong>{{ username }}</strong>
							</div>
						</div>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								Full Name:
							</label>
							<div class="col-sm-8 col-form-label">
								<strong>{{ selectedRecord?.title }}</strong>
							</div>
						</div>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								Template:
							</label>
							<div class="col-sm-8 col-form-label">
								{{ template?.template?.templateName }}
							</div>
						</div>
					</form>
				</div>
				<div class="col">
					<form>
						<div class="row mb-2">
							<label class="col-sm-4 col-form-label text-end">
								Has Access to:
							</label>
							<div class="col-sm-8 col-form-label">
								<div *ngFor="let app of selectedUserApps">{{ app.label }}</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal-body">
			<div class="form">
				<div [class.text-danger]="!identityRolesControl.pristine && !identityRolesControl.valid">
					<h4><span class="me-1 text-danger">*</span>Select at least one Identity Role:</h4>
				</div>
				<rl-select-identity-roles (onChange)="updateIdentityRoles($event)"></rl-select-identity-roles>
				<div class="mt-5" [class.text-danger]="!wfRolesControl.pristine && !wfRolesControl.valid">
					<h4><span class="me-1 text-danger">*</span>Select at least one Workflow Role:</h4>
				</div>
				<rl-select-wf-roles (onChange)="updateWfRoles($event)"></rl-select-wf-roles>
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-info" awPreviousStep>
				<i class="far fa-chevron-left me-2"></i>Back
			</button>
			<button type="button" class="btn btn-light ms-3" (click)="close()">Cancel</button>
			<button class="btn btn-success ms-3" (click)="save()" type="button"
				[disabled]="isSaving || !formGroup.valid">
				<i *ngIf="isSaving" class="far fa-spinner fa-spin me-1"></i>Save
			</button>
		</div>
	</aw-wizard-step>
</aw-wizard>