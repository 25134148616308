import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { first, isEmpty } from "lodash";
import { AdvancedSearch } from "rl-common/components/advanced-search/rl-advanced-search.consts";
import { NgIf, NgFor } from "@angular/common";
import { SingleValueInputComponent } from "../single-value-input/single-value-input.component";
import { ChipComponent } from "../../../../chip/chip.component";

@Component({
    selector: "rl-multi-value-input",
    templateUrl: "./multi-value-input.component.html",
    styleUrls: ["./multi-value-input.component.scss"],
    imports: [NgIf, SingleValueInputComponent, NgFor, ChipComponent]
})
export class MultiValueInputComponent implements OnInit {

	@Input()
	solrDataOption: AdvancedSearch.ISolrDataOption;

	private _values: string[];

	workingValue: string;

	@Output()
	onChange = new EventEmitter<string[]>();

	private readonly _commaDelimitedDataTypes: Set<AdvancedSearch.SolrDataTypes> = new Set([AdvancedSearch.SolrDataTypes.Double, AdvancedSearch.SolrDataTypes.Int]);


	get hasTypeahead() {
		return !!this.solrDataOption.searchFn$;
	}

	@Input()
	set values(values: string[]) {
		this._values = values;
	}

	get values() {
		return this._values;
	}

	constructor() { }

	ngOnInit() { }

	removeValue(index: number) {
		this.values = this.values.filter((x, i) => i !== index);
		this.emitChange();
	}

	addValue(value: string) {
		if (this.isValid(value)) {
			this.values = this.values.concat(value);
			this.emitChange();
		}
	}

	addWorkingValue() {
		let values = [this.workingValue];
		this.workingValue = "";

		if (this._commaDelimitedDataTypes.has(this.solrDataOption.dataType)) {
			values = first(values).split(",")
				.map(x => x.trim())
				.filter(x => !!x && !isEmpty(x));
		}
		const validValues = values.filter(val => this.isValid(val));
		if (!isEmpty(validValues)) {
			this.values = this.values.concat(validValues);
			this.emitChange();
		}
	}

	isValid(value: string) {
		return value && !isEmpty(value) && !this.values.find(x => x.toLowerCase() === value.toLowerCase());
	}

	private emitChange() {
		this.onChange.emit(this.values);
	}

}
