import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IFieldSectionParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { Subscription } from "rxjs";
import { CheckboxInputComponent } from "../../../../../common/components/checkbox-input/checkbox-input.component";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";
import { RadioInputComponent } from "../../../../../common/components/radio-input/radio-input.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";
import { FieldSectionCharPickerComponent } from "../field-section-char-picker/field-section-char-picker.component";


@Component({
	selector: "rl-build-panel-field-section-element",
	templateUrl: "./build-panel-field-section-element.component.html",
	styleUrls: ["./build-panel-field-section-element.component.scss"],
	imports: [FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, TextInputComponent, ReactiveFormsModule, FormsModule, CheckboxInputComponent, RadioInputComponent, FieldSectionCharPickerComponent]
})
export class BuildPanelFieldSectionElementComponent implements OnDestroy, OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<IFieldSectionParams>;

	@Output()
	paramsChange = new EventEmitter<IFieldSectionParams>();

	params: IFieldSectionParams;

	groupName: string = ``;
	expandOnLoad = true;
	numColumns: number;
	characteristicIds: number[] = [];

	_subs: Subscription[] = [];

	constructor() { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section && this.section?.params) {
			this.groupName = this.section.params.groupTitle;
			this.expandOnLoad = this.section.params.expandIndicator === 1;
			this.numColumns = this.section.params.numColumns;
			this.characteristicIds = this.section.params.characteristicIds;
		}
	}

	updateElements(elements: number[]) {
		this.characteristicIds = elements;
		this.updateParams();
	}

	updateParams() {
		const fieldSectionParams: IFieldSectionParams = {
			groupTitle: this.groupName,
			expandIndicator: this.expandOnLoad ? 1 : 0,
			characteristicIds: this.characteristicIds,
			numColumns: +this.numColumns
		};

		this.params = fieldSectionParams;
		this.paramsChange.emit(fieldSectionParams);
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}
}
