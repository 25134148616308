import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IEntity } from "rl-common/models/i-entity";
import { IEntityStatus } from "rl-common/models/i-entity-status";

export enum RelativeRightsProfilesOptions {
	Remove = 1,
	Default = 2,
	DeleteAndRecreate = 3,
	ApplyChanges = 4
}

export interface IGroup {
	templateId: number;
	templateName: string;
	numFound: number;
	templateMetaData: ICharacteristicMetaDataCollection;
	items: IGroupItem[];
}

export interface IGroupItem {
	assetId?: number;
	assets: number[];
	record: IEntity;
	status: IEntityStatus;
	charData: ICharacteristicData[];
	relRecId: number;
}
