import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { IWorkflowActionRequest } from "rl-common/services/workflow/workflow.models";

export interface ISelectedRecord extends IWorkflowActionRequest {
	title: string;
	charTypeId: number;
	recordId: number;
	templateGroupId: number;
	isLocked: boolean;
	isDeleteable: boolean;
	isActive: boolean;
	characteristics: ICharacteristicData[];
}

export enum AssociationType {
	Existing,
	New,
	NewMultiple
}
