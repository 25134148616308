import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JobProgressComponent } from "./job-progress/job-progress.component";
import { IJobOptions, IJobProgressResult } from "./job-progress/job-progress.component.models";


@Component({
	selector: "rl-job-progress-modal",
	templateUrl: "./job-progress-modal.component.html",
	imports: [JobProgressComponent]
})
export class JobProgressModalComponent {

	@Input()
	jobId: string;

	@Input()
	jobOptions: IJobOptions;

	@Input()
	allowCloseBeforeComplete = false;

	@Input()
	warningMessage: string[];

	@Input()
	titleOverride: string;

	@Output()
	onComplete = new EventEmitter<IJobProgressResult>();

	constructor() { }


	public emitComplete(result: IJobProgressResult) {
		this.onComplete.emit(result);
	}

	public dismiss() {
		this.onComplete.emit(null);
	}

}
