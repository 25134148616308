import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import moment from "moment";
import { VersionService } from "rl-common/services/version/version.service";
import { NgIf } from "@angular/common";

@Component({
    selector: "rl-refresh-prompt",
    templateUrl: "./refresh-prompt.component.html",
    styleUrls: ["./refresh-prompt.component.scss"],
    imports: [NgIf]
})
export class RefreshPromptComponent implements OnInit {
	
	@Input()
	isEntityRefresh: boolean;

	@Output()
	onClose = new EventEmitter<void>();
	browserSupportsBroadcasting: boolean;

	constructor(private readonly _versionService: VersionService) { }

	ngOnInit(): void {
		this.browserSupportsBroadcasting = window.BroadcastChannel !== undefined;
	}

	remindMeLater() {
		this._versionService.dismissUntil(moment().add(5, "minute"));
		this.onClose.next();
	}

	refresh() {
		window.location.reload();
	}

	refreshAll() {
		if (this.browserSupportsBroadcasting) {
			const refreshChannel = new BroadcastChannel("refresh");
			refreshChannel.postMessage("refreshed_tabs");
		}
	}
}
