<ng-container [ngSwitch]="entryType">
	<ng-container *ngSwitchCase="'char-data'">
		<rl-char-data-element [cmd]="cmd" [editMode]="editMode" [elementUsage]="elementUsage" [formControl]="control"
			class="d-inline-block">
		</rl-char-data-element>
	</ng-container>
	<ng-container *ngSwitchCase="'dropdown'">
		<ng-container *ngIf="editMode">
			<rl-dropdown-edit-control [selectedValue]="selectedValueIds" [lovs]="lovs"
				(selectedValueChange)="selectedValueChanged($event)" [showEmptyOption]="true" [focused$]="focused$"
				[isMultiple]="false" [isDisabled]="false">
			</rl-dropdown-edit-control>
		</ng-container>
		<ng-container *ngIf="!editMode">
			<span>{{ selectedValuesLabel }}</span>
		</ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'date'">
		<ng-container *ngIf="editMode">
			<rl-text-mode-date-input [(ngModel)]="dateValue" (ngModelChange)="dateUpdated()"></rl-text-mode-date-input>
		</ng-container>
		<ng-container *ngIf="!editMode">
			<span>{{ dateValue | localeDate }}</span>
		</ng-container>
	</ng-container>
</ng-container>