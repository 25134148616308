import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { IValidateEvent } from "rl-common/components/associations/entity-relationships/entity-relationship/input-list/input-list.component";
import { CreateEntityBasicComponent } from "rl-common/components/create-entity-basic/create-entity-basic.component";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { ConstUtils } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ProgressService } from "rl-common/services/progress.service";
import { Subscription } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { CharTypeNamePipe } from "../../../../pipes/char-type-name.pipe";
import { PluralCharTypeNamePipe } from "../../../../pipes/plural-char-type-name.pipe";
import { InputListComponent } from "../../../associations/entity-relationships/entity-relationship/input-list/input-list.component";
import { EntitySearchComponent } from "../entity-search.component";
import { IRecordTitleInfo, SearchOptions, SelectType } from "../entity-search.models";
import { ISelectStateChangeEvent } from "../select-state-net-changes";


@Component({
	selector: "rl-entity-search-modal",
	templateUrl: "./entity-search-modal.component.html",
	styleUrls: ["./entity-search-modal.component.scss"],
	imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, EntitySearchComponent, NgIf, InputListComponent, CreateEntityBasicComponent, NgbNavOutlet, CharTypeNamePipe, PluralCharTypeNamePipe]
})
export class EntitySearchModalComponent implements OnInit, OnDestroy {
	@Input()
	searchOptions: SearchOptions;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onApply = new EventEmitter<IRecordTitleInfo>();

	@ViewChild("entitySearch")
	entitySearch: EntitySearchComponent;

	@ViewChild("entityCreate")
	entityCreate: CreateEntityBasicComponent;

	selectData: GridSelectState<number, IEntitySearchDoc>;
	manualTitles: IRecordTitle[] = [];

	activeTabId = 1;

	private _subs: Subscription[] = [];
	busy = false;

	allowCreate = false;
	showInputList = false;

	get canComplete() {
		switch (this.activeTabId) {
			case 3:
				return !this.busy && (this.entityCreate?.valid ?? false);
			default:
				return !this.busy && (this.areRecordsSelected() || this.manualTitles?.length > 0);
		}
	}

	get saveButtonText() {
		switch (this.activeTabId) {
			case 3:
				return "Create";
			default:
				return "Add";
		}
	}

	constructor(
		private readonly _modal: NgbActiveModal,
		private readonly _entityService: EntityService,
		private readonly _progressService: ProgressService) { }

	ngOnInit() {
		this.allowCreate = this.searchOptions?.allowCreate && ConstUtils.isModuleLevelCharType(this.searchOptions.charTypeId);
		this.showInputList = this.searchOptions?.selectType !== SelectType.Radio;
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	areRecordsSelected() {
		const state = this.getSelectedState();
		return !!state && (state.isAllSelected || state.selectedValues.size > 0);
	}

	selectedStateChange($event: ISelectStateChangeEvent<number, IEntitySearchDoc>) {
		this.selectData = $event.selected;
	}

	apply() {
		switch (this.activeTabId) {
			case 3:
				this._progressService.startProgress();
				this.busy = true;
				const sub2 = this.entityCreate.saveEntity$()
					.pipe(
						switchMap(result => this._entityService.getEntity(result.entityId)),
						tap((result) => {
							this.onApply.next({ recordTitles: [{ recordId: result.entity.recordID, title: result.entity.title }], isNewEntity: true });
							this.busy = false;
						}),
						finalize(() => this._progressService.endProgress())
					)
					.subscribe();
				this._subs.push(sub2);
				break;
			default:
				const state = this.getSelectedState();
				if (!state.isAllSelected) {
					const selectedTitles = Array.from(state.selectedValues).map(rec => ({ recordId: rec.recordID, title: rec.title } as IRecordTitle));
					const titles = [...selectedTitles, ...this.manualTitles];
					this.onApply.next({ recordTitles: titles, isNewEntity: false });
				} else {
					this.busy = true;
					const sub = this.entitySearch.getSelectedRecords$()
						.pipe(
							tap(records => {
								const selectedTitles = records.documents.map(rec => ({ recordId: rec.recordID, title: rec.title } as IRecordTitle));
								const titles = [...selectedTitles, ...this.manualTitles];
								this.busy = false;
								this.onApply.next({ recordTitles: titles, isNewEntity: false });
							})
						)
						.subscribe();
					this._subs.push(sub);
				}
				break;
		}

	}

	getSelectedState() {
		return this.entitySearch?.dataSourceWrapper.dataSelectStrategy.selectedState as GridSelectState<number, IEntitySearchDoc>;
	}

	cancel() {
		this._modal.dismiss();
	}

	updateValidated($event: IValidateEvent) {
		this.manualTitles = $event.validRecordsDeprecated;
	}

}
