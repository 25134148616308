<div *ngIf="resetMode">
	<div class="login-brand text-center">
		<img src="/assets/img/rightsline-logo_prpl_180w.png" />
	</div>

	<div class="card">
		<div class="card-header">
			<h1 class="text-center">Reset Password</h1>
		</div>
		<div class="card-body">
			<ngb-alert type="danger" *ngIf="errorMessage && !errorAlertClosed" [dismissible]="true"
				(close)="errorAlertClosed=true">
				<span [innerText]="errorMessage"></span>
			</ngb-alert>
			<ngb-alert type="success" *ngIf="showSuccessMessage && !successAlertClosed" [dismissible]="true"
				(close)="successAlertClosed=true">
				<span *ngIf="showSuccessMessage">
					Your password has been reset. Please <a *ngIf="!portalOnlyUser" routerLink="/login">login</a><a
						*ngIf="portalOnlyUser" [href]=portalLink>login</a>.
				</span>
			</ngb-alert>
			<ngb-alert type="info" [dismissible]="false" class="password-requirements">
				<span>
					<h6>Password Requirements</h6>
					<ul class="list-unstyled mb-0">
						<li [class.text-error]="password.errors && password.errors.invalidPasswordLength">
							<i class="me-1 far"
								[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordLength)"
								[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordLength"></i>
							At least 8 characters in length
						</li>
						<li [class.text-error]="password.errors && password.errors.invalidPasswordUpperCase">
							<i class="me-1 far"
								[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordUpperCase)"
								[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordUpperCase"></i>
							At least 1 uppercase letter
						</li>
						<li [class.text-error]="password.errors && password.errors.invalidPasswordLowerCase">
							<i class="me-1 far"
								[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordLowerCase)"
								[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordLowerCase"></i>
							At least 1 lowercase letter
						</li>
						<li [class.text-error]="password.errors && password.errors.invalidPasswordNumber">
							<i class="me-1 far"
								[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordNumber)"
								[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordNumber"></i>
							At least 1 number
						</li>
						<li [class.text-error]="password.errors && password.errors.invalidPasswordSpecialCharacter">
							<i class="me-1 far"
								[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordSpecialCharacter)"
								[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordSpecialCharacter"></i>
							At least 1 special character (ex !&#64;#$%^&*()-_=+&#123;&#125;[]:";'<>,./?)
						</li>
						<li
							[class.text-error]="password.value && confirmPassword.value && resetPasswordForm.errors && resetPasswordForm.errors.passwordMismatch">
							<i class="me-1 far"
								[class.fa-square]="!password.value || !confirmPassword.value || (resetPasswordForm.errors && resetPasswordForm.errors.passwordMismatch)"
								[class.fa-check-square]="!resetPasswordForm.errors || !resetPasswordForm.errors.passwordMismatch"></i>
							Passwords must match
						</li>
					</ul>
				</span>
			</ngb-alert>

			<form (ngSubmit)="submit()">
				<div class="mb-3" hasError>
					<label class="form-control-label">New Password</label>
					<input type="password" class="form-control" name="password" [formControl]="password" />
				</div>
				<div class="mb-2" hasError>
					<label class="form-control-label">Re-enter New Password</label>
					<input type="password" class="form-control" name="confirmPassword"
						[formControl]="confirmPassword" />
				</div>
				<div class="mb-2 text-end">
					<button type="submit" class="btn btn-success" [disabled]="!resetPasswordForm.valid" rlBusyButton>
						Reset Password
					</button>
				</div>
			</form>
		</div>
		<div class="card-footer text-center">
			<button class="btn btn-sm btn-link" (click)="showGdpr()">GDPR &amp; PII Information</button> |
			<a class="btn btn-sm btn-link" href="https://www.rightsline.com/privacy-policy/" target="_blank"
				rel="noopener">Privacy Policy</a> |
			<a class="btn btn-sm btn-link" href="https://www.rightsline.com/terms-conditions/" target="_blank"
				rel="noopener">Terms &amp; Conditions</a>
		</div>
	</div>
</div>

<div *ngIf="!resetMode" class="d-flex justify-content-center my-4">
	<div class="col-lg-6">
		<h1 class="mb-3">Change Password</h1>
		<ngb-alert type="danger" *ngIf="errorMessage && !errorAlertClosed" [dismissible]="true"
			(close)="errorAlertClosed=true">
			<span [innerText]="errorMessage"></span>
		</ngb-alert>
		<ngb-alert type="success" *ngIf="showSuccessMessage && !successAlertClosed" [dismissible]="true"
			(close)="successAlertClosed=true">
			<span *ngIf="showSuccessMessage">
				Your password has been reset. Please <a routerLink="/login">login</a>.
			</span>
		</ngb-alert>
		<ngb-alert type="info" [dismissible]="false" class="password-requirements">
			<span>
				<h6>Password Requirements</h6>
				<ul class="list-unstyled mb-0">
					<li [class.text-error]="password.errors && password.errors.invalidPasswordLength">
						<i class="me-1 far"
							[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordLength)"
							[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordLength"></i>
						At least 8 characters in length
					</li>
					<li [class.text-error]="password.errors && password.errors.invalidPasswordUpperCase">
						<i class="me-1 far"
							[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordUpperCase)"
							[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordUpperCase"></i>
						At least 1 uppercase letter
					</li>
					<li [class.text-error]="password.errors && password.errors.invalidPasswordLowerCase">
						<i class="me-1 far"
							[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordLowerCase)"
							[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordLowerCase"></i>
						At least 1 lowercase letter
					</li>
					<li [class.text-error]="password.errors && password.errors.invalidPasswordNumber">
						<i class="me-1 far"
							[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordNumber)"
							[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordNumber"></i>
						At least 1 number
					</li>
					<li [class.text-error]="password.errors && password.errors.invalidPasswordSpecialCharacter">
						<i class="me-1 far"
							[class.fa-square]="!password.value || (password.errors && password.errors.invalidPasswordSpecialCharacter)"
							[class.fa-check-square]="!password.errors || !password.errors.invalidPasswordSpecialCharacter"></i>
						At least 1 special character (ex !&#64;#$%^&*()-_=+&#123;&#125;[]:";'<>,./?)
					</li>
					<li
						[class.text-error]="password.value && confirmPassword.value && resetPasswordForm.errors && resetPasswordForm.errors.passwordMismatch">
						<i class="me-1 far"
							[class.fa-square]="!password.value || !confirmPassword.value || (resetPasswordForm.errors && resetPasswordForm.errors.passwordMismatch)"
							[class.fa-check-square]="!resetPasswordForm.errors || !resetPasswordForm.errors.passwordMismatch"></i>
						Passwords must match
					</li>
				</ul>
			</span>
		</ngb-alert>

		<form (ngSubmit)="submit()">
			<div class="mb-2" hasError>
				<label class="form-control-label">Current Password</label>
				<input type="password" class="form-control" name="currentPassword" [formControl]="currentPassword" />
			</div>
			<div class="mb-2" hasError>
				<label class="form-control-label">New Password</label>
				<input type="password" class="form-control" name="password" [formControl]="password" />
			</div>
			<div class="mb-2" hasError>
				<label class="form-control-label">Re-enter New Password</label>
				<input type="password" class="form-control" name="confirmPassword" [formControl]="confirmPassword" />
			</div>
			<div class="mb-2 text-end">
				<button class="btn btn-light me-3" (click)="close()">Cancel</button>
				<button (click)="submitChange()" class="btn btn-success" [disabled]="!resetPasswordForm.valid"
					rlBusyButton>
					Change Password
				</button>
			</div>
		</form>
	</div>
</div>