import { Component, OnInit } from "@angular/core";
import { EntityAuditHistoryService } from "rl-common/services/entity-audit-history/entity-audit-history.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { NgFor, AsyncPipe } from "@angular/common";
import { RecentActivityItemComponent } from "./recent-activity-item/recent-activity-item.component";

@Component({
    selector: "rl-recent-activity-widget",
    templateUrl: "./recent-activity-widget.component.html",
    styleUrls: ["./recent-activity-widget.component.scss"],
    imports: [NgFor, RecentActivityItemComponent, AsyncPipe]
})
export class RecentActivityWidgetComponent implements OnInit {

	recentActivities$ = this._auditHistoryService.recentActivity(5);

	constructor(
		private readonly _auditHistoryService: EntityAuditHistoryService,
		public readonly _linkHelper: LinkHelperService
	) { }

	ngOnInit() {
	}
}
