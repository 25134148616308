import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "rl-common/services/authentication/authentication.service";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { TokenService } from "rl-common/services/token.service";
import { FaIconDirective } from "../../../common/directives/fa-icon.directive";

@Component({
    selector: "rl-sso-token",
    templateUrl: "./sso-token.component.html",
    styleUrls: ["./sso-token.component.scss"],
    imports: [FaIconDirective]
})
export class SsoTokenComponent implements OnInit {

	constructor(
		private readonly _tokenService: TokenService,
		private readonly sessionService: SessionService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly progressService: ProgressService,
		private readonly router: Router,
		private readonly _authenticationService: AuthenticationService) {

	}

	ngOnInit(): void {
		const refresh_token = this.activatedRoute.snapshot.params["token"];
		this.progressService.startProgress();
		this._tokenService.refreshToken(refresh_token)
			.subscribe((response) => {
				this.progressService.endProgress();
				let returnUrl = this.activatedRoute.snapshot.queryParams["returnUrl"] as string ?? "";
				returnUrl = decodeURIComponent(returnUrl);
				if (returnUrl.startsWith("/")) {
					this.router.navigateByUrl(returnUrl);
				} else if (returnUrl.startsWith("http")) {
					window.location.href = returnUrl;
				} else {
					this.router.navigate([""]);
				}
			}, () => {
				this.progressService.clearProgress();
				this.router.navigate(["/login"]);
			});
	}

}
