<div class="modal-body" *ngIf="modalLoaded; else isLoading" [@fadeIn]>
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="cancel()"
			data-cy="closeTemplateFieldAdd">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div *ngIf="isCreate">
		<ul ngbNav #nav="ngbNav" class="nav-tabs tabset--rl grey nav m-3" [destroyOnHide]="false"
			[(activeId)]="activeTabId">
			<li [ngbNavItem]="1">
				<a ngbNavLink data-cy="addTemplateFieldSelectExistingTab">Select Existing {{charTypeName$ | async }}
					Source Fields</a>
				<ng-template ngbNavContent>
					<rl-select-template-source-field [cmd]="notAssigned" [sourceFieldsLoaded]="sourceFieldsLoaded"
						[charTypeName]="charTypeName" (onComplete)="save($event)">
						<button type="button" class="btn btn-light" (click)="cancel()"
							data-cy="selectExistingCancel">Cancel</button>
					</rl-select-template-source-field>
				</ng-template>
			</li>
			<li [ngbNavItem]="2">
				<a ngbNavLink data-cy="addTemplateFieldCreateNewTab">Create New {{charTypeName$ | async }} Field</a>
				<ng-template ngbNavContent>
					<rl-create-template-field-wizard [group]="group" [isCreate]="isCreate" [charTypeId]="charTypeId"
						[templateId]="templateId" [templateFieldModel]="templateFieldModel"
						[charMetaData]="charMetaData" (onCharValueSourceIdChanged)="onCharValueSourceIdChanged($event)"
						(onCharValueSetIdChanged)="onCharValueSetIdChanged($event)" (onComplete)="saveWizard()">
						<div step-one-footer class="ms-3">
							<button type="button" class="btn btn-light" (click)="cancel()"
								data-cy="createStepOneCancel">Cancel</button>
						</div>
						<div step-two-footer class="ms-3">
							<button type="button" class="btn btn-light" (click)="cancel()"
								data-cy="createStepTwoCancel">Cancel</button>
						</div>
						<div step-three-footer class="ms-3">
							<button type="button" class="btn btn-light" (click)="cancel()"
								data-cy="createStepThreeCancel">Cancel</button>
						</div>
					</rl-create-template-field-wizard>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="mx-3"></div>
	</div>
	<div *ngIf="isEdit">
		<rl-create-template-field-wizard [group]="group" [isCreate]="isCreate" [charMetaData]="charMetaData"
			[templateFieldModel]="templateFieldModel" [charTypeId]="charTypeId" [templateId]="templateId"
			(onCharValueSourceIdChanged)="onCharValueSourceIdChanged($event)"
			(onCharValueSetIdChanged)="onCharValueSetIdChanged($event)" (onComplete)="saveWizard()">
			<div step-one-footer class="ms-3">
				<button type="button" class="btn btn-light" (click)="cancel()"
					data-cy="editStepOneCancel">Cancel</button>
			</div>
			<div step-two-footer class="ms-3">
				<button type="button" class="btn btn-light" (click)="cancel()"
					data-cy="editStepTwoCancel">Cancel</button>
			</div>
			<div step-three-footer class="ms-3">
				<button type="button" class="btn btn-light" (click)="cancel()"
					data-cy="editStepThreeCancel">Cancel</button>
			</div>
		</rl-create-template-field-wizard>
	</div>
</div>

<ng-template #isLoading>
	<div class="p-4 text-center">
		<rl-loader></rl-loader>
	</div>
</ng-template>