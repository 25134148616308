import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: "rl-collapsing-side-panel",
    templateUrl: "./collapsing-side-panel.component.html",
    styleUrls: ["./collapsing-side-panel.component.scss"],
    imports: [NgClass, NgStyle]
})
export class CollapsingSidePanelComponent implements OnInit {

	@Input()
	isCollapsed: boolean = false;

	@Input()
	openTo: string;

	@Output() sidePanelWidth = new EventEmitter<number>();

	@Input()
	sidePanelWidthOpen: number;

	@Input()
	sidePanelWidthClosed: number;

	openClass: string = '';

	constructor() { }

	ngOnInit(): void {
		this.openClass = this.isCollapsed ? 'closed' : 'open';
	}

	togglePanel(collapse: boolean) {
		this.isCollapsed = collapse ? true : false;
		this.sidePanelWidth.emit(collapse ? this.sidePanelWidthClosed : this.sidePanelWidthOpen);
		this.openClass = collapse ? 'closed' : 'open';
	}
}
