<div *ngIf="!isLoading">
	<ng-container *ngIf="!isRange;else rangeInput">
		<ng-container *ngIf="!solrDataOption.allowMultipleSelections; else multiInput">
			<ng-template [ngTemplateOutlet]="singleInput"></ng-template>
		</ng-container>
	</ng-container>

	<ng-template #rangeInput>
		<rl-range-value-input *ngIf="solrDataOption" [solrDataOption]="solrDataOption" [values]="values"
			(onChange)="emitRange($event)"></rl-range-value-input>
	</ng-template>

	<ng-template #singleInput>
		<rl-single-value-input *ngIf="solrDataOption" [solrDataOption]="solrDataOption" [values]="values"
			(onChange)="emitSingle($event)"></rl-single-value-input>
	</ng-template>

	<ng-template #multiInput>
		<rl-multi-value-input *ngIf="solrDataOption" [solrDataOption]="solrDataOption" [values]="values"
			(onChange)="emitMultiValues($event)"></rl-multi-value-input>
	</ng-template>
</div>