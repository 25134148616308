<div>
	Associating to an Accounting Document is not available because...
</div>

<div>
	The following amounts have invoices associated with them: 
	
	<rl-grid *ngIf="dataSource" [dataSource]="dataSource">

	</rl-grid>
</div>