import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbAccordionDirective, NgbActiveModal, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody } from "@ng-bootstrap/ng-bootstrap";
import { groupBy } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { IAvailableWorkflowActions } from "rl-common/services/workflow/workflow.messages";
import { IDUtil } from "rl-common/utils";
import { OneConfigService } from "./../../../../services/one-config/one-config.service";
import { NgClass, NgFor, KeyValuePipe } from "@angular/common";
import { FuncPipe } from "../../../../pipes/func.pipe";

@Component({
    selector: "rl-workflow-warning-modal",
    templateUrl: "./workflow-warning-modal.component.html",
    styleUrls: ["./workflow-warning-modal.component.scss"],
    imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, NgFor, KeyValuePipe, FuncPipe]
})
export class WorkflowWarningModalComponent implements OnInit {
	@Input()
	availableWorkflowActions: IAvailableWorkflowActions;
	templateStatusDictionary: { [templateId: number]: { statusIds: number[] } };
	charTypeId: CharTypeId;

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly activeModal: NgbActiveModal
	) { }

	ngOnInit(): void {
		const splits = Object.keys(this.availableWorkflowActions.missingActionsDictionary).map(key => IDUtil.splitTemplateStatusId(key));
		this.charTypeId = splits[0].charTypeId;
		const grouped = groupBy(splits, split => split.templateId);
		this.templateStatusDictionary = Object.keys(grouped).reduce((acc, key) => {
			acc[key] = grouped[key].map(split => split.statusId);
			return acc;
		}, {});
	}

	public dismiss() {
		this.activeModal.dismiss();
	}

	getStatusName = (templateId: string, statusId: string) => {
		const status = this._oneConfigService.getTemplateStatus(this.charTypeId, +templateId, +statusId);
		return status?.stepName;
	}

	getTemplateName = (templateId: string) => {
		const template = this._oneConfigService.getTemplate(this.charTypeId, +templateId);
		return template?.templateName;
	}

	getActions = (templateId: string, statusId: number) => {
		return this.availableWorkflowActions.missingActionsDictionary[`c${this.charTypeId}t${templateId}s${statusId}`];
	}

	getAccordionHeader = (templateStatusKey: string) => {
		const split = IDUtil.splitTemplateStatusId(templateStatusKey);
		const status = this._oneConfigService.getTemplateStatus(split.charTypeId, split.templateId, split.statusId);
		const template = this._oneConfigService.getTemplate(split.charTypeId, split.templateId);
		return `Template (${template.templateName}), Status (${status.stepName})`;
	}
}
