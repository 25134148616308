import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import { IWfRole } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { RoleAction } from "./add-edit-wf-role-modal.models";
import { NgSwitch, NgSwitchCase, NgIf } from "@angular/common";

@Component({
    selector: "rl-add-edit-wf-role-modal",
    templateUrl: "./add-edit-wf-role-modal.component.html",
    styleUrls: ["./add-edit-wf-role-modal.component.scss"],
    imports: [ReactiveFormsModule, NgSwitch, NgSwitchCase, NgIf]
})
export class AddEditWfRoleModalComponent implements OnInit {
	@Input()
	set role(role: IWfRole) {
		this._role = cloneDeep(role);
	}

	private _role: IWfRole;

	get role() {
		return this._role;
	}

	@Input()
	action = RoleAction.Create;

	@Input()
	existingNames: string[];

	@Output()
	onComplete = new EventEmitter<boolean>();

	roleName = new UntypedFormControl("", [Validators.required]);
	roleDescription = new UntypedFormControl("");

	formGroup = new UntypedFormGroup({
		roleName: this.roleName,
		roleDescription: this.roleDescription,
	});

	isSaving = false;
	isDuplicate = false;

	get canSave() {
		return this.formGroup.valid && this.formGroup.dirty && !this.isSaving && !this.isDuplicate;
	}

	constructor(
		private readonly _modal: NgbActiveModal,
		private readonly _companyService: CompanyService,
		private readonly _growler: GrowlerService
	) { }

	ngOnInit(): void {
		if (this.action === RoleAction.Copy) {
			this.role.roleName = `COPY -- ${this.role.roleName}`;
		}

		if (this.role) {
			this.roleName.setValue(this.role.roleName);
			this.roleDescription.setValue(this.role.roleDescription);
		}
	}

	cancel() {
		this._modal.close();
	}

	checkForError() {
		if (this.isDuplicate) {
			this.isDuplicate = false;
		}
	}

	save() {
		this.isSaving = true;
		const isSuperAdmin = 0
		let saveRole$: Observable<string>;
		this.isDuplicate = false;
		if (this.role?.roleName !== this.roleName.value) {
			const lowerCaseExistingRoles = this.existingNames.map(v => v.toLowerCase());
			if (lowerCaseExistingRoles.includes(this.roleName.value.toLowerCase())) {
				this.isDuplicate = true;
				this.isSaving = false;
			}
		}
		if (!this.isDuplicate) {
			if (this.action === RoleAction.Edit) {
				saveRole$ = this._companyService.updateWfRole(this.role.roleID, this.roleName.value, this.roleDescription.value, isSuperAdmin)
			} else {
				const roleId = this.action === RoleAction.Create ? 0 : this.role.roleID;
				saveRole$ = this._companyService.createWfRole(this.roleName.value, this.roleDescription.value, isSuperAdmin, roleId)
			}
			saveRole$.pipe(
				tap(() => {
					this._growler.success().growl("Workflow Role Saved");
				}),
				finalize(() => {
					this.isSaving = false;
				})
			).subscribe(() => {
				this.onComplete.emit();
			})
		}
	}
}
