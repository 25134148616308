import { ArchwizardModule } from "@achimha/angular-archwizard";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { first, isEmpty } from "lodash";
import { CmdVisibilityService } from "rl-common/components/char-data/cmd-visibility.service";
import { CreateEntityCmdVisibilityService } from "rl-common/components/create-workflow/create-entity-cmd-visibility.service";
import { SearchOptions } from "rl-common/components/entities/entity-search/entity-search.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { ISearchFormParams } from "rl-common/components/search-form/search-form.models";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { FacetType } from "rl-common/models/facet-type";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { ISavedSearch } from "rl-common/services/advanced-search/advanced-search.models";
import { IApplicationInfo, IInviteExistingUserRequest } from "rl-common/services/company/company.models";
import { EntityService } from "rl-common/services/entity/entity.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { UserService } from "rl-common/services/user/user.service";
import { QueryUtil } from "rl-common/utils";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { of, Subscription } from "rxjs";
import { catchError, delay, distinctUntilChanged, filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { CharDataTableComponent } from "../../../../common/components/char-data/char-data-table.component";
import { ShowOnlyDropdownComponent } from "../../../../common/components/create-workflow/show-only-dropdown/show-only-dropdown.component";
import { EntitySearchComponent } from "../../../../common/components/entities/entity-search/entity-search.component";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { SelectAppsComponent } from "../select-apps/select-apps.component";
import { SelectIdentityRolesComponent } from "../select-identity-roles/select-identity-roles.component";
import { SelectWfRolesComponent } from "../select-wf-roles/select-wf-roles.component";

@Component({
    selector: "rl-add-existing-user-wizard",
    templateUrl: "./add-existing-user-wizard.component.html",
    styleUrls: ["./add-existing-user-wizard.component.scss"],
    providers: [CmdVisibilityService, CreateEntityCmdVisibilityService],
    imports: [ArchwizardModule, NgIf, EntitySearchComponent, ReactiveFormsModule, FormsModule, SelectAppsComponent, ShowOnlyDropdownComponent, CharDataTableComponent, LoaderComponent, NgFor, SelectIdentityRolesComponent, SelectWfRolesComponent]
})
export class AddExistingUserWizardComponent implements OnInit, OnDestroy {

	@Input()
	email: string = null;

	@Output()
	onComplete = new EventEmitter<number>();

	dataSource: SearchGridDataSource<IEntitySearchDoc>;

	private readonly columns: GridColumn<IEntitySearchDoc>[] = [
		{
			key: "username",
			headerName: "Username",
			renderer: "text",
			width: "auto",
			getCellData: (user => user.characteristics[SearchFieldNames.User.email])
		},
		{
			key: "name",
			headerName: "Name",
			renderer: "text",
			width: "auto",
			getCellData: (user => user.title)
		},
		{
			key: "userId",
			headerName: "User ID",
			renderer: "text",
			width: "auto",
			getCellData: (user => user.recordID)
		},
		{
			key: "template",
			headerName: "Template",
			renderer: "text",
			width: "min-content",
			getCellData: (user => user.template)
		},
		{
			key: "lastUpdated",
			headerName: "Last Updated",
			renderer: "date",
			width: "min-content",
			getCellData: (user => user.lastUpdated)
		}
	];
	gridOptions: GridOptions<any> = {
		defaultGetCellDataFn: (column, doc) => {
			switch (column.key) {
				case "id":
					return doc.recordID;
				default:
					return doc.characteristics[column.key];
			}
		},
	};
	searchOptions: SearchOptions = {} as SearchOptions;
	searchParams: ISearchFormParams = {
		gridColumnFilters: {},
		keywords: "",
		allAssociatedToParent: false,
		isMineOnly: false,
		isRelatedChild: false,
		notRelatedToEntity: false,
		notRelatedToComponent: false,
		savedSearch: null
	};
	templates: ICharacteristicTemplate[] = [];
	formGroup: UntypedFormGroup;
	loadingUserApps = true;
	userApps: IApplicationInfo[] = [];

	loadingEntity = false;
	template: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];

	isSaving = false;

	get selectedUserApps() {
		const selectedAppUrlIds = this.appUrlIdsControl.value as number[] ?? [];
		return this.userApps.filter(x => selectedAppUrlIds.includes(x.appUrlID));
	}

	get appUrlIdsControl() {
		return this.formGroup.get("appUrlIds");
	}

	get identityRolesControl() {
		return this.formGroup.get("identityRoles");
	}

	get wfRolesControl() {
		return this.formGroup.get("wfRoles");
	}

	get selectedRecord() {
		return this.formGroup.get("selectedRecord").value as IEntitySearchDoc;
	}

	get selectedRecordIdControl() {
		return this.formGroup.get("selectedRecord");
	}

	get username() {
		return this.usernameControl?.value as string;
	}

	get usernameControl() {
		return this.formGroup?.get("username");
	}

	get disableMessages() {
		return this.disableMessagesControl.value as boolean;
	}

	get disableMessagesControl() {
		return this.formGroup.get("disableMessages");
	}

	private readonly _subscriptions: Subscription[] = [];

	constructor(
		private readonly _gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly _userService: UserService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _entityService: EntityService,
		private readonly _growler: GrowlerService,
		private readonly _promptService: PromptService,
		cmdVisibilityService: CmdVisibilityService,
		readonly createCmdVisibilityService: CreateEntityCmdVisibilityService
	) {
		cmdVisibilityService.setStrategy(createCmdVisibilityService);
	}

	ngOnInit(): void {
		if (this.email && !isEmpty(this.email)) {
			const emailQuery = QueryUtil.$eq(SearchFieldNames.User.email, this.email, false);
			emailQuery.description = `${CharTypeIdUtil.toDisplayName(CharTypeId.User)} Email is equal to ${this.email}`;
			const emailSearch: ISavedSearch = {
				id: null,
				name: "",
				userId: 0,
				divId: 0,
				charTypeId: CharTypeId.User,
				displayOnDashboard: false,
				query: QueryUtil.$and(emailQuery),
				global: false
			};
			this.searchParams.savedSearch = emailSearch;
		}

		this.buildForm();

		this.templates = this._oneConfigService.getTemplatesWhere(CharTypeId.User, cmd => cmd.tagLabel === "email");
		this.searchOptions = SearchOptionsFactory.buildListPageOptions(CharTypeId.User);
		this.searchOptions.isRelatedDropdownEnabled = false;
		this.searchOptions.selectAllEnabled = false;
		this.searchOptions.areActionsEnabled = false;

		const columnStrategy = this._gridDataServiceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(CharTypeId.User);

		const dataSelectStrategy = this._gridDataServiceBuilder.dataSelectStrategies
			.searchDocDataSelectStrategy<number, IEntitySearchDoc>(doc => doc.recordID, doc => doc)
			.withSelectType(GridSelectType.Radio);

		this.dataSource = this._gridDataServiceBuilder.entitySearchDataSource(CharTypeId.User)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: "recordid", sortDir: 0 })
			.setColumns(this.columns)
			.withDataSelectStrategy(dataSelectStrategy)
			.withColumnStrategy(columnStrategy)
			.withFetchFn((ds) => {
				const start = ds.rowOffset$.value;
				const pageSize = ds.pageSize$.value;
				const sortKey = ds.sortKey$.value;
				const sortDir = ds.sortDir$.value;

				const contactsWithRlAccess = QueryUtil.$join(
					CharTypeId.User,
					SearchFieldNames.User.email,
					SearchFieldNames.User.email,
					QueryUtil.$eq_any(SearchFieldNames.User.appUrlIds, this.userApps.map(x => x.appUrlID))
				);
				const query = QueryUtil.$and(
					QueryUtil.$eq(SearchFieldNames.Entity.charTypeID, CharTypeId.User),
					QueryUtil.$neq(SearchFieldNames.User.email, ""),
					QueryUtil.$not(contactsWithRlAccess),
					QueryUtil.$eq_any(SearchFieldNames.Entity.templateID, this.templates.map(x => x.templateID)));

				const options: ISearchRequestOptions = {
					rows: pageSize,
					start,
					sortDir,
					sortField: sortKey as string,
					facetFields: [
						FacetType.Template,
						FacetType.Status
					],
					filterQueries: ds.buildFilterQueries(),
				};

				const model: ISearchRequestModel = {
					keywords: ds.keywords,
					query,
					...options,
				};

				return this._userService.getUsers(model).pipe(
					tap((results) => {
						ds.setFacetResults(results.facetResults)
							.setExtraGridColumnResults(results.extraGridColumnResults);
					}),
					map((results) => ({
						rowData: results.documents.map(x => x.document),
						rowCount: results.numFound,
						extraGridColumnResults: results.extraGridColumnResults,
						facetResults: results.facetResults
					}))
				);
			});

		const appsSub = this._userService.getApps().pipe(
			tap(apps => {
				this.userApps = apps;
				this.loadingUserApps = false;
			}),
			delay(0),
			switchMap(() => this.dataSource.fetchRows())
		).subscribe(() => {
			if (this.email && this.dataSource.indexedRowData$.value?.length == 1) {
				this.dataSource.dataSelectStrategy.selectRow(this.dataSource.indexedRowData$.value[0]);
				this.email = null;
			}
		});

		const selectChangedSub = this.dataSource.dataSelectStrategy.selectStateChange$.subscribe(state => {
			const selected = first(Array.from(state.selectedValues)) as number;
			this.selectedRecordIdControl.setValue(selected);
		});

		this._subscriptions.push(appsSub, selectChangedSub);
	}

	private buildForm() {
		const usernameControl = new UntypedFormControl("", [Validators.required, Validators.email]);
		const appUrlIdsControl = new UntypedFormControl([], Validators.required);
		const selectedRecordControl = new UntypedFormControl(null, [Validators.required]);
		this.formGroup = this._formBuilder.group({
			username: usernameControl,
			selectedRecord: selectedRecordControl,
			appUrlIds: appUrlIdsControl,
			identityRoles: new UntypedFormControl([], [Validators.required]),
			wfRoles: new UntypedFormControl([], [Validators.required]),
			disableMessages: new UntypedFormControl("")
		});
		const sub = selectedRecordControl.valueChanges.pipe(
			filter(x => !!x),
			distinctUntilChanged(),
			tap(() => this.loadingEntity = true),
			map(event => event.entityID),
			switchMap(entityId => this._entityService.getEntity(entityId))
		).subscribe(entity => {
			this.template = this._oneConfigService.getTemplateMetaData(CharTypeId.User, entity.entity.templateID);
			this.charData = entity.characteristicData;
			this.loadingEntity = false;
		});
		this._subscriptions.push(sub);
	}

	updateCharData(charData: ICharacteristicData[]) {
		this.charData = charData;
		const username = this.extractUsername();
		this.usernameControl.setValue(username);
	}

	private extractUsername() {
		const cmd = this.template.characteristicMetaDatas.find(x => x.tagLabel === "email");
		if (!cmd) {
			this._growler.error().growl(`Email field not found in ${this.template.template.templateName} template.`);
			return null;
		}
		const cd = this.charData.find(x => x.charactersticID == cmd.characteristicID);
		if (!cd) {
			this._growler.error().growl(`Email is required.`);
			return null;
		}
		return cd.value;
	}

	selectAppUrlIds(appUrlIds: number[]) {
		this.appUrlIdsControl.setValue(appUrlIds);
	}

	updateIdentityRoles(identityRoles: string[]) {
		this.identityRolesControl.setValue(identityRoles);
	}

	updateWfRoles(wfRoles: number[]) {
		this.wfRolesControl.setValue(wfRoles);
	}

	selectDisableAuditMessages(event) {
		this.disableMessagesControl.setValue(event.target.checked);
	}

	checkUserExistsInOtherDiv() {
		if (this.username) {
			this.loadingEntity = true;
			this._userService.existsInOtherDiv(this.username)
				.subscribe(userExistsInOtherDiv => {
					if (userExistsInOtherDiv) {
						this._promptService.alert("Login User Exists", "A login user with the same email address already exists in another Rightsline application. Please reach out to Rightsline Support.");
						this.close();
					}
					this.loadingEntity = false;
				});
		}
	}

	save() {
		this.isSaving = true;
		const request: IInviteExistingUserRequest = {
			recordId: this.selectedRecord.recordID,
			email: this.formGroup.controls["username"].value,
			applicationUrlIds: this.appUrlIdsControl.value as number[],
			identityRoleIds: this.identityRolesControl.value as string[],
			workflowRoleIds: this.wfRolesControl.value as number[],
			charData: this.charData,
			disableMessages: this.disableMessages
		};
		const sub = this._userService.inviteExistingUser(request).pipe(
			catchError(error => {
				this._growler.error().growl(error?.error?.message);
				return of(null);
			}),
			finalize(() => {
				this.isSaving = false;
			}),
			filter(userId => !!userId),
		).subscribe(userId => this.onComplete.next(userId));
		this._subscriptions.push(sub);
	}

	close() {
		this.onComplete.next(null);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
