<div class="d-flex flex-row">
	<div>
		<select class="form-select" [(ngModel)]="selectedQueryNodeStrategyIndex" (change)="loadCharMetaData()"
			data-cy="CharTypeDropdown">
			<optgroup *ngFor="let grp of queryNodeStrategyGroups | keyvalue" [label]="selectionTypeName(grp.key)">
				<option *ngFor="let selection of grp.value" [value]="selection.index">
					{{selection.name}}
				</option>
			</optgroup>
		</select>
	</div>
	<div class="char-data-field">
		<select class="form-select" [(ngModel)]="selectedCharDataTagLabel" (change)="loadComparators()"
			[disabled]="isLoadingCharMeta" data-cy="CharDataDropdown">
			<optgroup label="System Fields">
				<option *ngFor="let field of systemFields" [value]="field.tagLabel" [attr.data-cy]="field.label">
					{{field.label}}</option>
			</optgroup>
			<optgroup label="Characteristics">
				<option *ngFor="let charData of charDatas; let i = index" [value]="charData.tagLabel"
					[attr.data-cy]="charData.label">
					{{ charData.label }}</option>
			</optgroup>
		</select>
	</div>
	<div>
		<select class="form-select" [(ngModel)]="selectedComparatorIndex" [disabled]="isLoadingCharMeta"
			(change)="softResetValues()" data-cy="ComparatorDropdown">
			<option *ngFor="let comparator of comparators; let i = index" [value]="i" [attr.data-cy]="comparator.value">
				{{ comparator.label }}</option>
		</select>
	</div>
	<div *ngIf="selectedCharData && hasParameters">
		<rl-query-node-value-entry [comparator]="selectedComparator" [characteristic]="selectedCharData"
			[values]="values" (valuesEntered)="updateValues($event)"></rl-query-node-value-entry>
	</div>
</div>