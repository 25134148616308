import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ChangePasswordComponent } from "../../change-password/change-password.component";

@Component({
    selector: "rl-change-password-modal",
    templateUrl: "./change-password-modal.component.html",
    styleUrls: ["./change-password-modal.component.scss"],
    imports: [ChangePasswordComponent]
})
export class ChangePasswordModalComponent implements OnInit {

	constructor(private readonly _modal: NgbActiveModal) { }

	ngOnInit() {
	}

	close() {
		this._modal.close();
	}
}
