import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IWfRole } from "rl-common/services/company/company.models";
import { IWorkflowActionForm } from "../workflow-action-edit.models";
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from "@ng-bootstrap/ng-bootstrap";
import { NgFor } from "@angular/common";
import { TrimWhitespacePipe } from "../../../../../../../../common/pipes/trim-whitespace.pipe";

@Component({
    selector: "rl-workflow-associated-roles",
    templateUrl: "./workflow-associated-roles.component.html",
    styleUrls: ["./workflow-associated-roles.component.scss"],
    imports: [ReactiveFormsModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgFor, TrimWhitespacePipe]
})

export class WorkflowAssociatedRolesComponent implements OnInit {
	selectedRoleCount = 0;
	checkedRoleIds: number[] = [];

	@Input()
	form: FormGroup<IWorkflowActionForm>;

	@Input()
	allRoles: IWfRole[];

	@Input()
	isCreate: boolean;

	associatedRolesToggleWidth = 0;

	get elementWidth() {
		return document.querySelector(".associated-roles-toggle").getBoundingClientRect().width;
	}

	@HostListener("window:resize")
	onResize() {
		this.associatedRolesToggleWidth = this.elementWidth;
	}

	constructor() { }

	ngOnInit() {
		if (!this.isCreate) {
			const roleIds = this.form.controls.associatedRoles.value.map(r => r.roleID);
			this.setRoleIdsToChecked(roleIds);
		} else {
			const allRoleIds = this.allRoles.map(r => r.roleID);
			this.form.controls.associatedRoles.setValue(this.allRoles);
			this.setRoleIdsToChecked(allRoleIds);
		}
		this.associatedRolesToggleWidth = this.elementWidth;
	}

	isRoleIdChecked(roleId: number) {
		return this.checkedRoleIds.includes(roleId);
	}

	setRoleIdsToChecked(roleIds: number[]) {
		const ids = this.allRoles.map(r => r.roleID);
		this.checkedRoleIds = ids.filter(id => roleIds.includes(id));
		this.selectedRoleCount = this.checkedRoleIds.length;
		return this.checkedRoleIds;
	}

	selectAllRoles(isChecked: boolean) {
		const associatedRolesFormControl = this.form.controls.associatedRoles;

		if (isChecked) {
			associatedRolesFormControl.setValue([]);
			this.allRoles.forEach(r => {
				associatedRolesFormControl.value.push(r);
			});
			associatedRolesFormControl.setValue(associatedRolesFormControl.value);
			this.checkedRoleIds = associatedRolesFormControl.value.map(r => r.roleID);
			this.selectedRoleCount = this.allRoles.length;
		} else {
			associatedRolesFormControl.setValue([]);
			this.checkedRoleIds = [];
			this.selectedRoleCount = 0;
		}
	}

	onRoleChange(role: IWfRole, isChecked: boolean) {
		const associatedRolesFormControl = this.form.get("associatedRoles") as FormControl<IWfRole[]>;
		const roles = [...associatedRolesFormControl.value];

		if (isChecked) {
			roles.push(role);
			++this.selectedRoleCount;
		} else {
			const index = roles.findIndex((r) => r.roleID === role.roleID);

			if (index !== -1) {
				roles.splice(index, 1);
				--this.selectedRoleCount;
			}
		}

		associatedRolesFormControl.setValue(roles);
	}
}
