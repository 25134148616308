import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, ValidatorFn, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { isEmpty, isString } from "lodash";
import { DateUtil } from "rl-common/utils";
import { NgIf } from "@angular/common";
import { DateInputComponent } from "../date-input/date-input.component";

@Component({
    selector: "rl-text-mode-date-input",
    templateUrl: "./text-mode-date-input.component.html",
    styleUrls: ["./text-mode-date-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextModeDateInputComponent),
            multi: true
        }
    ],
    imports: [NgIf, ReactiveFormsModule, DateInputComponent, FormsModule]
})
export class TextModeDateInputComponent implements ControlValueAccessor, OnInit {

	@Input()
	disabled = false;

	@Input()
	placeholder: string;

	dateValue: string;
	textDateControl: UntypedFormControl;

	textModeEnabled = false;

	onChange = (date: string) => { };
	onTouched = () => { };

	constructor() { }

	private validateTextModeDate: ValidatorFn = (c: AbstractControl) => {
		const textDate = c.value;
		if (!textDate || isEmpty(textDate)) {
			return null;
		}
		const datesRegex = /(([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z)|(NOW))/gi;
		const dateMatches = textDate.match(datesRegex);

		// There should be at least one 'NOW' or UTC date
		if (!dateMatches) {
			return {
				"missingDate": true
			};
		}

		// Basic syntax check
		// https://lucene.apache.org/solr/guide/6_6/working-with-dates.html
		let remaining = textDate.replace(datesRegex, "");
		const dateMathRegex = /([+-]{1}\d+((MONTHS?)|(DAYS?)|(HOURS?)|(YEARS?)){1})/gi;
		remaining = remaining.replace(dateMathRegex, "");

		const dateRoundingRegex = /\/(YEAR|MONTH|DAY|HOUR)?$/gi;
		remaining = remaining.replace(dateRoundingRegex, "");

		if (remaining.length > 0) {
			return {
				"invalidSyntax": true
			};
		}

		return null;
	}

	writeValue(dateValue: string): void {
		if (!isEmpty(dateValue) && !isString(dateValue)) {
			throw Error("Expected a string type, received " + typeof dateValue + " instead");
		}
		if (dateValue) {
			this.textModeEnabled = DateUtil.isTextModeDate(dateValue);
			if (this.textModeEnabled) {
				this.textDateControl.patchValue(dateValue, { emitEvent: false });
			} else {
				this.dateValue = dateValue;
			}
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	enableTextMode() {
		this.resetValues();
		this.textModeEnabled = true;
		this.textDateUpdated();
	}

	disableTextMode() {
		this.resetValues();
		this.textModeEnabled = false;
		this.dateUpdated();
	}

	private resetValues() {
		this.textDateControl.patchValue(null, { emitEvent: false });
		this.dateValue = null;
	}

	textDateUpdated() {
		if (this.textDateControl.valid) {
			// Solr date math requires uppercase
			const textDate = this.textDateControl.value?.toUpperCase();
			this.onChange(textDate);
		} else {
			this.onChange(null);
		}
	}

	dateUpdated() {
		const dateValue = this.textModeEnabled ? this.textDateControl.value : this.dateValue;
		this.onChange(dateValue);
	}

	ngOnInit() {
		this.textDateControl = new UntypedFormControl(null, this.validateTextModeDate);
	}

}
