<td class="ps-1">
	<span class="pt-1 drag__handle position-relative" cdkDragHandle>
		<i class="far fa-ellipsis-v"></i>
	</span>
	{{getContactRuleTypeName}}
</td>
<td>
	@if (rule.contactRuleType === contactRuleType.FieldValue) {
		{{getPicklistLabel}}
	} @else if (rule.contactRuleType === contactRuleType.Record) {
		{{contactName$ | async}}
	} @else if (rule.contactRuleType === contactRuleType.Template) {
		{{getTemplateName}}
	} @else if (rule.contactRuleType === contactRuleType.TemplateGroup) {
		{{getTemplateGroupName}}
	}
</td>
<td>
	@if (rule.contactRuleType === 1 && rule.characteristicValueId !== 0) {
		{{getPicklistValueLabel}}
	}
</td>
<td>
	{{getDefaultAccountName}}
</td>
<td class="p-1">
	<div class="hover-icons text-end">
		<button class="btn btn-icon btn-info btn-sm me-2"
			(click)="editRule()" data-cy="editTemplate"
			title="Edit Template"><i class="far fa-pencil fa-sm"></i></button>
		<button class="btn btn-icon btn-remove btn-sm me-1" data-cy="deleteTemplate"
			title="Delete Template"
			(click)="deleteRule()"><i
				class="far fa-trash-alt fa-sm"></i></button>
	</div>
</td>