import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IUdfLookupSectionParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";


@Component({
	selector: "rl-build-panel-udf-grid-element",
	templateUrl: "./build-panel-udf-grid-element.component.html",
	styleUrls: ["./build-panel-udf-grid-element.component.scss"],
	imports: [FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, TextInputComponent, ReactiveFormsModule, FormsModule]
})
export class BuildPanelUdfGridElementComponent implements OnChanges {

	@Input()
	section: IPanelSection<IUdfLookupSectionParams>;

	@Output()
	paramsChange = new EventEmitter<IUdfLookupSectionParams>();

	params: IUdfLookupSectionParams;

	collectionName: string;
	title: string;

	constructor() { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section) {
			this.collectionName = this.section?.params?.collectionName;
			this.title = this.section?.params?.collectionTitle;
		}
	}

	updateParams() {
		const fieldSectionParams: IUdfLookupSectionParams = {
			collectionName: this.collectionName,
			collectionTitle: this.title
		};

		this.params = fieldSectionParams;
		this.paramsChange.emit(fieldSectionParams);
	}

}
