<div class="modal-header" data-cy="jobProgressModalHeader" *ngIf="!hideModalHeading">
	<h2 *ngIf="showProgressMessage" class="modal-title">{{ progressMessage }}</h2>
	<button *ngIf="showCloseIcon" type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" data-cy="jobProgressModalBody">
	<div class="container">
		<h4 data-cy="jobProgressStatus">{{ status }}</h4>
		<div *ngIf="total > 0">
			<div class="progress" style="height: 32px;">
				<div class="progress-bar progress-bar-striped progress-bar-animated" [class.bg-success]="!hasFailure"
					[class.bg-warning]="hasFailure" [style.width.%]="completedPercent">
				</div>
				<div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
					[style.width.%]="failedPercent"></div>
			</div>

			<div class="text-muted">{{ completedPercent }}%</div>
			<div class="text-muted" *ngIf="failed > 0">Failure count: {{ failed }}</div>
		</div>

		<div *ngIf="showResultMessage" [innerHTML]="resultMessage" data-cy="jobProgressResultMessage"></div>
		<div *ngFor="let message of warningMessage;" class="text-danger" [innerHtml]="message"></div>

		<a *ngIf="showRouterLink" [routerLink]="jobOptions.routerLink" target="_blank"
			[innerText]="jobOptions.routerLinkText" [title]="jobOptions.routerLinkText"></a>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="cancel()" *ngIf="!isComplete && jobOptions?.isCancelable"
		data-cy="jobProgressCancel">
		{{jobOptions.cancelButtonText}}
	</button>
	<button type="button" class="btn btn-info" (click)="close()"
		*ngIf="isComplete && jobOptions?.isManualClose || allowCloseBeforeComplete || hasFailure"
		data-cy="jobProgressClose">
		{{ hasNextView ? 'Next' : 'Close' }}
	</button>
</div>