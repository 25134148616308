import { EventEmitter, Injectable, Type } from "@angular/core";
import { ComponentKey } from "rl-common/components/mod-details-layout/mod-layout.consts";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
export interface IBuildPanelElementModalComponent {
	templateMetaData: ICharacteristicMetaDataCollection;
	section: IPanelSection<unknown>;
	componentKey: ComponentKey;
	onComplete: EventEmitter<IPanelSection<unknown>>;
}

@Injectable({ providedIn: "root" })
export class BuildPanelElementModalAdapter extends ModalAdapter<IBuildPanelElementModalComponent> {
	constructor(type: Type<IBuildPanelElementModalComponent>) {
		super(type);
	}

}


