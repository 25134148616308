import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IPartyGroup } from "rl-common/components/contacts/models/i-party-group";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { IContactPartyParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { DropdownSingleComponent } from "../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";


@Component({
	selector: "rl-build-contact-party-group-element",
	templateUrl: "./build-contact-party-group-element.component.html",
	styleUrls: ["./build-contact-party-group-element.component.scss"],
	imports: [FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, DropdownSingleComponent, ReactiveFormsModule, FormsModule]
})
export class BuildContactPartyGroupElementComponent implements OnInit, OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<IContactPartyParams>;

	@Output()
	paramsChange = new EventEmitter<IContactPartyParams>();

	params: IContactPartyParams;

	partyGroups: DropdownOptions<IPartyGroup> = {
		items: [],
		rowKeyFn: (type: IPartyGroup) => type.groupID,
		rowLabelFn: (type: IPartyGroup) => type.groupLabel
	};

	defaultType: IPartyGroup = {
		divisionID: null,
		groupID: null,
		groupLabel: "Select",
		groupDescription: null,
		expandIndicator: null,
		expandGroupIndicator: null,
		systemIndicator: null,
		visibilityIndicator: null,
		sequenceNumber: null,
		userID: null
	};
	selectedPartyGroup: IPartyGroup = this.defaultType;

	constructor(private readonly _oneConfig: OneConfigService) { }

	ngOnInit() {
		const groupIds = this._oneConfig.getParties(this.templateMetaData.charTypeID, this.templateMetaData.templateID).map(x => x.partyGroupID);
		this.partyGroups.items = this._oneConfig.getPartyGroups().filter(grp => groupIds.includes(grp.groupID));
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section) {
			this.selectedPartyGroup = this.defaultType;
			if (!!this.section.params?.groupId) {
				this.selectedPartyGroup.groupID = this.section.params?.groupId;
			}
		}
	}

	updateParams() {
		const fieldSectionParams: IContactPartyParams = {
			groupId: this.selectedPartyGroup.groupID
		};

		this.params = fieldSectionParams;
		this.paramsChange.emit(fieldSectionParams);
	}

}
