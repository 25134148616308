import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { CharTypeId } from "rl-common/consts";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { IWorkflowAction } from "rl-common/services/workflow/workflow.models";
import { WorkflowService } from "rl-common/services/workflow/workflow.service";
import { QueryUtil } from "rl-common/utils";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { LoaderComponent } from "../../../common/components/panel/loader/loader.component";

@Component({
	selector: "rl-workflow-actions-execute",
	templateUrl: "./workflow-actions-execute.component.html",
	styleUrls: ["./workflow-actions-execute.component.scss"],
	imports: [NgIf, NgFor, LoaderComponent]
})
export class WorkflowActionsExecuteComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	templateId: number;

	@Output()
	onWorkflowExecuted = new EventEmitter<void>();

	availableWorkflowActions: IWorkflowAction[];
	subs: Subscription[] = [];
	showWf = false;
	charTypeName: string;
	charStatus: string;
	charTitle: string;
	recordId: number;
	actionsLoading = true;
	validationError = "";
	encryptedString = "";
	selectedActionId = -1;
	executeDisabled = true;
	workflowUnsuccessful = false;
	pageHeader = "Take Workflow Action";
	workflowExecuteError = "";
	showWorkflowActions = true;
	isLoading = true;
	workflowActionSuccess = false;

	constructor(private readonly _workflowService: WorkflowService,
		private readonly _growlerService: GrowlerService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly activatedRoute: ActivatedRoute) { }

	ngOnInit() {

		this.encryptedString = (this.activatedRoute.snapshot.queryParams["executeWorkflow"]);
		const sub = this._workflowService.getWorkflowActionsForEncryptedString(this.encryptedString).subscribe(response => {
			this.validationError = response.wfRequestValidationDetails.validationResult;
			this.charTypeName = CharTypeIdUtil.getCharTypeIdMetaData(response.wfRequestValidationDetails.charTypeID).displayName + " ID";
			this.recordId = response.wfRequestValidationDetails.recordID;
			this.charTitle = response.wfRequestValidationDetails.title;
			this.charTypeId = response.wfRequestValidationDetails.charTypeID;
			this.charStatus = response.wfRequestValidationDetails.status;
			this.actionsLoading = false;
			if (this.validationError === "") {
				this.availableWorkflowActions = response.actions;
			}
			this.isLoading = false;
		});
		this.subs.push(sub);
	}

	changeWorkflowAction(actionId: number) {
		this.selectedActionId = actionId;
		this.executeDisabled = false;
	}

	getSearchModel(): ISearchRequestModel[] {
		const searchModels: ISearchRequestModel[] = [];
		const charTypeIdQuery = QueryUtil.$eq(SearchFieldNames.Entity.charTypeID, this.charTypeId);
		const recordIdQuery = QueryUtil.$eq(SearchFieldNames.Entity.recordID, this.recordId);
		const searchModel: ISearchRequestModel = {
			query: QueryUtil.$and(charTypeIdQuery, recordIdQuery)
		};
		searchModels.push(searchModel);
		return searchModels;
	}

	executeWorkflow() {
		const message = "Are you sure you want to take this workflow action?";
		const sub = this._modalService.confirm("Confirm Workflow Action", message, "Yes").pipe(
			filter(result => !!result),
			switchMap(() => {
				this.showWorkflowActions = false;
				this.isLoading = true;
				return this._workflowService.executeWorkflow(this.selectedActionId, this.encryptedString);
			})
		).subscribe(response => {
			this.validationError = "error";
			if (!response.success) {
				this.workflowUnsuccessful = true;
				this.workflowExecuteError = response.errorStep;
				this.pageHeader = "Workflow Action Failed";
				this._growlerService.error().growl("Workflow Action Failed.");
			} else {
				this.isLoading = false;
				this.pageHeader = "Workflow Action Successful";
				this._growlerService.success().growl("Workflow Action Successful.");
				this.charStatus = response.newStatusLabel;
				this.validationError = "";
				this.workflowActionSuccess = true;
			}
		});
		this.subs.push(sub);
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
