<table class="table rl-table w-auto mb-0 nested-allocation-table">
	<tbody>
		<tr *ngFor="let amount of allocatedAmounts">
			<td class="nested-allocation-table__title">
				{{ amount.title }}
			</td>
			<td class="nested-allocation-table__amount">
				<input type="text" class="form-control text-end" [ngModel]="amount.amount"
					(ngModelChange)="updatePercentage($event, amount)" />

			</td>
			<td class="nested-allocation-table__amount">
				<input type="text" class="form-control" [ngModel]="amount.percentage"
					(ngModelChange)="updateFee($event, amount)" />

			</td>
			<td class="nested-allocation-table__last-updated">
				<span *ngIf="amount.dueDate">{{ amount.dueDate | localeDate }}</span>
			</td>
		</tr>
	</tbody>
</table>