import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { sortBy } from "lodash";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { AclUtil } from "rl-common/utils/acl.util";
import { v4 } from "uuid";

@Component({
    selector: "rl-template-selector",
    templateUrl: "./template-selector.component.html",
    styleUrls: ["./template-selector.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TemplateSelectorComponent),
            multi: true
        }
    ],
    imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class TemplateSelectorComponent implements OnInit, ControlValueAccessor {

	@Input()
	charTypeId: number;

	@Input()
	options: ITemplateSelectorOptions;

	@Input()
	includeSelect: boolean;

	templates: ICharacteristicTemplate[] = [];
	_selectedTemplate: ICharacteristicTemplate;
	templateControl: UntypedFormControl;

	@Output()
	onChange = new EventEmitter<number>();

	onInnerChange: any = () => { };
	onTouched: any = () => { };
	labelFn = (t: ICharacteristicTemplate) => t.templateName;

	id = v4();
	templateSelectorId: string;

	get selectedTemplate() {
		return this._selectedTemplate;
	}

	set selectedTemplate(val) {
		this._selectedTemplate = val;
		this.onChange.emit(this.selectedTemplate.templateID);
		this.onInnerChange(val);
		this.onTouched();
	}

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly _session: SessionService
	) { }

	ngOnInit() {
		this.templateSelectorId = `template-selector-${this.id}`;
		const acls = this._session.acls;
		const blockList = this.options?.blockList ?? [];
		const templates = sortBy(this._oneConfigService.getTemplates(this.charTypeId)
			.filter(t => AclUtil.hasCreateAccess(acls, t.acl) && !blockList.includes(t.templateID)), t => t.sequenceNumber);

		if (this.options?.labelFn) {
			this.labelFn = this.options.labelFn;
		}

		this.templates = templates;
		if (this.options.defaultTemplateId) {
			if (this.options.defaultTemplateId === -1) {
				const newTemplate: ICharacteristicTemplate = {
							templateID: -1,
							templateName: "Please Select a template",
							description: "Select a template",
							templateGroupID: 0,
							templateGroup: "none",
							systemIndicator: 0,
							visibilityIndicator: 1,
							sequenceNumber: 1,
							acl: "none",
							visualization: "none",
							calcSequence: 0,
						};
						this.templates.push(newTemplate);
			}
			this.selectedTemplate = templates.find(t => t.templateID === this.options.defaultTemplateId);
		} else {
			this.selectedTemplate = templates[0];
		}
		this.templateControl = new UntypedFormControl(this.selectedTemplate.templateID);
	}
	changeTemplate() {
		this.selectedTemplate = this.templates.find(t => t.templateID === +this.templateControl.value);
	}

	writeValue(value: any): void {
		if (value) {
			this.selectedTemplate = value;
			this.templateControl.patchValue(this.selectedTemplate.templateID);
		}
	}

	registerOnChange(fn: any): void {
		this.onInnerChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
	}
}

export interface ITemplateSelectorOptions {
	blockList: number[];
	labelFn: (template: ICharacteristicTemplate) => string;
	defaultTemplateId?: number;
}
