import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { first, last } from "lodash";
import { AdvancedSearch } from "rl-common/components/advanced-search/rl-advanced-search.consts";
import { DateLocaleType, DateUtil } from "rl-common/utils";
import { NgIf } from "@angular/common";
import { SingleValueInputComponent } from "../single-value-input/single-value-input.component";

export interface IRangeValueChangedEvent {
	start: string;
	end: string;
}
@Component({
    selector: "rl-range-value-input",
    templateUrl: "./range-value-input.component.html",
    styleUrls: ["./range-value-input.component.scss"],
    imports: [NgIf, SingleValueInputComponent]
})
export class RangeValueInputComponent implements OnInit {

	startValue: string;
	endValue: string;

	@Input()
	solrDataOption: AdvancedSearch.ISolrDataOption;

	@Output()
	onChange = new EventEmitter<IRangeValueChangedEvent>();

	@Input()
	isDate: boolean;

	@Input()
	set values(values: string[]) {
		this.startValue = first(values);
		this.endValue = last(values);
	}

	constructor() { }

	ngOnInit() {
	}

	setStart(value: string) {
		this.startValue = value;
		this.emitChange();
	}

	setEnd(value: string) {
		this.endValue = value;
		this.emitChange();
	}

	private emitChange() {
		if (this.startValue && this.endValue) {
			let startValue = this.startValue;
			let endValue = this.endValue;

			// adjust start date to be beginning of day in user's local time and adjust end date to be end of day in user's local time
			if (this.isDate) {
				const modifiedStartValue = DateUtil.parseToMoment(this.startValue, DateLocaleType.Storage);
				if (modifiedStartValue.isValid()) {
					startValue = modifiedStartValue.toISOString();
				}

				const modifiedEndValue = DateUtil.parseToMoment(this.endValue, DateLocaleType.Storage);
				if (modifiedEndValue.isValid()) {
					endValue = modifiedEndValue.endOf("day").toISOString();
				}
			}

			this.onChange.emit({ start: startValue, end: endValue });
		}
	}

}
