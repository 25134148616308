<div class="d-flex flex-row col-md-12">
	<!-- TODO: Implement <rl-dropdown-single> -->
	<select class="form-select me-2" [(ngModel)]="notificationControl.value.notificationId"
		(ngModelChange)="onNotificationChange()" data-cy="selectNotification">
		<option [ngValue]="null">Select Notification</option>
		<option *ngFor="let notif of notifications" [ngValue]="notif.id"
			[class.has-error]="form.hasError('invalidNotificationId')">{{ notif.label }}</option>
	</select>
	<div class="me-2 w-100" ngbDropdown autoClose="outside" placement="bottom-left"
		#notificationRolesDropdown="ngbDropdown">
		<button class="form-select text-start" ngbDropdownToggle role="button" data-cy="selectRolesDropdown">Select
			Roles
			({{ selectedRoleCount }})</button>
		<div ngbDropdownMenu>
			<div class="scroll-vert position-relative mini-table-container mx-2">
				<table class="table table-hover mini-table w-100">
					<thead>
						<tr>
							<th class="col-id">
								<input type="checkbox" class="form-check-input me-1" (click)="selectAllRoles()"
									[checked]="allRowsSelected" data-cy="selectAllRoles" />
								ID
							</th>
							<th class="col-title">ROLE</th>
							<th class="col-members">#</th>
						</tr>
					</thead>
					<tbody>
						@for (role of allRoles;track role.roleID) {
						<tr [class.is-disabled]="isRoleDisabled(role.roleID)">
							<td class="col-id">
								<input type="checkbox" class="form-check-input me-1"
									[checked]="isRoleIdChecked(role.roleID)"
									(change)="onRoleChange(role.roleID, $event.target.checked)"
									[disabled]="isRoleDisabled(role.roleID)"
									[attr.data-cy]="'selectRole' + role.roleName" />
								{{ role.roleID }}
							</td>
							<td class="col-title">{{ role.roleName }}</td>
							<td class="col-members">{{ role.memberCount }}</td>
						</tr>
						}
					</tbody>
				</table>
			</div>
			<hr class="m-0">
			<div class="position-relative mini-table-container mx-2">
				<table class="table table-hover mini-table w-100">
					<thead>
						<tr>
							<th class="col-id bg-white"></th>
							<th class="col-title bg-white">Total Selected Members:</th>
							<th class="col-members bg-white"
								[ngClass]="{'text-success' : selectedRoleMemberCount <= 99, 'text-danger' : selectedRoleMemberCount > 99}">
								{{ selectedRoleMemberCount }}</th>
						</tr>
					</thead>
				</table>
			</div>
		</div>
	</div>
	<div class="me-2 w-100" ngbDropdown autoClose="outside" placement="bottom-right"
		#notificationRolesDropdown="ngbDropdown">
		<button class="form-select text-start" ngbDropdownToggle role="button" data-cy="selectPartiesDropdown">Select
			Parties
			({{ selectedPartyCount }})</button>
		<div ngbDropdownMenu>
			<div class="scroll-vert position-relative mini-table-container showNotifyChild mx-2">
				<table class="table table-hover mini-table w-100">
					<thead>
						<tr>
							<th class="col-id">
								<input type="checkbox" class="form-check-input me-1"
									(click)="selectAllParties($event.target.checked)" [checked]="allPartiesSelected"
									data-cy="selectAllParties" />
								ID
							</th>
							<th class="col-title">PARTY</th>
							<th class="col-notify-child">
								<span class="form-check form-switch form-switch-inline position-relative">
									<input type="checkbox" class="form-check-input me-1"
										[disabled]="!checkedPartyIds.length"
										[checked]="selectAllNotifyChildContactsChecked"
										(click)="selectAllNotifyChildContacts($event.target.checked)"
										data-cy="notifyAllChildContacts" />
									<label class="position-relative">NOTIFY CHILD CONTACTS</label>
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						@for (party of allParties; track party.partyID) {
						<tr [class.is-disabled]="isPartyDisabled(party.partyID)">
							<td class="col-id">
								<input type="checkbox" class="form-check-input me-1"
									[checked]="isPartyIdChecked(party.partyID)"
									(change)="onPartyChange(party.partyID, $event.target.checked)"
									[disabled]="isPartyDisabled(party.partyID)"
									[attr.data-cy]="'selectParty' + party.partyName" />
								{{ party.partyID }}
							</td>
							<td class="col-title">{{ party.partyName }}</td>
							<td class="col-notify-child">
								<span class="form-check form-switch form-switch-inline position-relative">
									<input class="form-check-input" type="checkbox"
										(change)="onNotifyChildContactChange(party.partyID, $event.target.checked)"
										[checked]="isPartyNotifyChildrenIdChecked(party.partyID)"
										[disabled]="checkedPartyIds.indexOf(party.partyID) === -1"
										[attr.data-cy]="'notifyChildren' + party.partyName" />
								</span>
							</td>
						</tr>
						}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>