import { DealScopedOptions } from "rl-common/services/entity-config/entity-config.models";

export interface DealScopedOptionsMeta {
	displayName: string;
	value: number;
}

export namespace DealScopedOptionsUtil {
	export const DealScopedOptionsLookup: { [key in DealScopedOptions]: DealScopedOptionsMeta } = {
		[DealScopedOptions.Disabled]: {
			displayName: "Disabled",
			value: 0
		},
		[DealScopedOptions.Enabled]: {
			displayName: "Enabled",
			value: 1
		},
		[DealScopedOptions.OneToOne]: {
			displayName: "One-to-One",
			value: 2
		}
	};

	export function toDisplayName(dealScopedOptions: DealScopedOptions) {
		return DealScopedOptionsLookup[dealScopedOptions].displayName;
	}
}
