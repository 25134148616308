import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { isEmpty, some } from "lodash";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AccountOperationEventComponent } from "../account-operation-event/account-operation-event.component";
import { AddAccountingOperationEventComponent } from "../add-accounting-operation-event/add-accounting-operation-event.component";
import { IBuildAccountingOperationEvent } from "../build-accounting-operation/models/i-build-accounting-operation-event";
import { IBuildAccountingOperationTrigger } from "../build-accounting-operation/models/i-build-accounting-operation-trigger";

@Component({
	selector: "rl-add-accounting-operation-events",
	templateUrl: "./add-accounting-operation-events.component.html",
	styleUrls: ["./add-accounting-operation-events.component.scss"],
	imports: [NgIf, NgFor, AddAccountingOperationEventComponent, AccountOperationEventComponent]
})
export class AddAccountingOperationEventsComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	eventsFormArray: FormArray<FormControl<IBuildAccountingOperationEvent>>;

	@Input()
	disabled = false;

	@Input()
	accounts: IAccountingProcessAccount[] = [];

	@Input()
	accountGroups: IAccountingProcessAccountGroup[] = [];

	@Input()
	workflowTrigger: IBuildAccountingOperationTrigger;

	editingIndex: number = null;

	get eventControls() {
		return this.eventsFormArray.controls;
	}

	get canAddAnother() {
		if (this.editingIndex != null) {
			return false;
		}
		const controls = this.eventControls;
		if (isEmpty(controls)) {
			return true;
		}
		const hasSavedEvents = some(controls, control => !!control.value.id);
		return hasSavedEvents;
	}

	private readonly _subscriptions: Subscription[] = [];

	constructor(private readonly _promptService: PromptService) { }

	ngOnInit(): void {
		if (this.eventsFormArray.length == 0) {
			this.addEvent(false);
		}
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.eventsFormArray && changes.eventsFormArray.currentValue?.controls?.length == 0 && changes.eventsFormArray.previousValue?.controls?.length > 0) {
			this.addEvent(false);
		}
	}

	editEvent(index: number) {
		this.editingIndex = index;
	}

	cancelEdit(index: number) {
		const control = this.eventControls[index];
		if (!control.value.id) {
			this.eventsFormArray.removeAt(index, { emitEvent: false });
		}
		this.editingIndex = null;
	}

	updateEvent(event: IBuildAccountingOperationEvent, index: number) {
		this.eventControls[index].setValue(event);
		this.editingIndex = null;
	}

	addEvent(emitEvent: boolean = true) {
		const control = new FormControl<IBuildAccountingOperationEvent>(({} as IBuildAccountingOperationEvent));
		const nextIndex = this.eventsFormArray.length;
		this.eventsFormArray.insert(nextIndex, control, { emitEvent });
		this.editEvent(nextIndex);
	}

	removeEvent(index: number, confirmDeleteDialog: TemplateRef<unknown>) {
		const sub = this._promptService.confirm("Confirmation", confirmDeleteDialog,).pipe(
			filter(confirm => confirm),
		).subscribe(() => {
			this.eventsFormArray.removeAt(index);
		});

		this._subscriptions.push(sub);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

}
