@if (availableWorkflowActions) {
<div class="modal-header">
	<h2 class="modal-title">Reduced Workflow Actions: {{availableWorkflowActions.commonActions.length}} /
		{{availableWorkflowActions.totalActions}}</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="dismiss()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div ngbAccordion #accordion="ngbAccordion">
		<div ngbAccordionItem="common-actions" #commonActions="ngbAccordionItem" [collapsed]="false">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : commonActions.collapsed}" ngbAccordionButton>
					<span class="cursor-pointer">
						Common Actions ({{availableWorkflowActions.commonActions.length}})
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="commonActions.collapsed ? 'Expand' : 'Collapse'"
						[attr.data-cy]="commonActions.collapsed? 'ToExpand' : 'ToCollapse'"
						[class.open]="!commonActions.collapsed">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						@for (commonAction of availableWorkflowActions?.commonActions; track $index) {
						<div>{{commonAction.actionName}}</div>
						}
					</ng-template>
				</div>
			</div>
		</div>
		@for (kvp of templateStatusDictionary | keyvalue; track $index) {
		<div ngbAccordionItem="template-status-{{ $index }}">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : !accordion.isExpanded('template-status-' + $index)}" ngbAccordionButton>
					<span class="cursor-pointer">
						{{kvp.key | func:getTemplateName}}
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="accordion.isExpanded('template-status-' + $index ) ? 'Collapse' : 'Expand'"
						[attr.data-cy]="accordion.isExpanded('template-status-' + $index ) ? 'ToCollapse' : 'ToExpand'"
						[class.open]="accordion.isExpanded('template-status-' + $index )">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						<div ngbAccordion #accordion="ngbAccordion">
							<div ngbAccordionItem="{{ statusId }}" *ngFor="let statusId of kvp.value">
								<div ngbAccordionHeader>
									<button ngbAccordionButton class="accordion-button">
										{{getStatusName(kvp.key, statusId)}} ({{getActions(kvp.key, statusId)?.length}})
									</button>
								</div>
								<div ngbAccordionCollapse>
									<div ngbAccordionBody>
										<ng-template>
											@for (action of getActions(kvp.key, statusId); track action.actionId) {
											<div>{{action.actionName}} - {{action.actionId}}</div>
											}
										</ng-template>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
		}
	</div>
</div>
<div class="modal-footer">
</div>
}