import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from "@angular/core";
import { flatten, max, uniqBy } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { APKeyValueOption } from "rl-common/models/ap-key-value-option";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { IAccountingProcessAccountGroupRule } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group-rule";
import { IGroupSequenceNumber } from "rl-common/services/accounting-processes/models/i-group-sequence-number";
import { IUpdateGroupRuleSequenceNumberOrder } from "rl-common/services/accounting-processes/models/i-update-group-rule-sequence-number-order";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Observable, Subscription } from "rxjs";
import { debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { AccountGroupRuleComponent } from "../account-group-rule/account-group-rule.component";
import { AccountingProcesses } from "../accounting-processes.consts";

@Component({
    selector: "rl-account-group-rules",
    templateUrl: "./account-group-rules.component.html",
    styleUrls: ["./account-group-rules.component.scss"],
    imports: [CdkDropList, NgFor, CdkDrag, AccountGroupRuleComponent]
})

export class AccountGroupRulesComponent implements OnInit, OnDestroy {
	@Input()
	accountGroup: IAccountingProcessAccountGroup;

	@Input()
	defaultAccounts: [string, string][] = []; //[ID, NAME]

	@Output()
	maxSequenceNumber = new EventEmitter<IGroupSequenceNumber>();

	@Output()
	edit = new EventEmitter<{ group: IAccountingProcessAccountGroup, rule: IAccountingProcessAccountGroupRule }>();

	groupId: string;
	rules: IAccountingProcessAccountGroupRule[] = [];
	picklistItems: ICharacteristicMetaData[] = [];
	templateItems: APKeyValueOption[] = [];
	templateGroupItems: APKeyValueOption[] = [];

	loadRules$: Observable<IAccountingProcessAccountGroupRule[]>;
	_subs: Subscription[] = [];

	constructor(
		private readonly _accountingProcessesService: AccountingProcessesService,
		private readonly _oneConfig: OneConfigService,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _promptService: PromptService,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit(): void {
		this.groupId = this.accountGroup.id;
		this.loadTemplateItems();
		this.loadRules();
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	loadRules(): void {
		this.loadRules$ = this._accountingProcessesService.getAccountGroupRules(this.groupId).pipe(
			tap(rules => {
				this.rules = rules.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
				const maxGroupSequenceNumber = {
					groupId: this.groupId,
					maxSequenceNumber: max(rules.map(r => r.sequenceNumber)) ?? 0
				} as IGroupSequenceNumber;
				this.maxSequenceNumber.emit(maxGroupSequenceNumber);
			})
		);

		this._subs.push(this.loadRules$.subscribe());
	}

	loadTemplateItems() {
		const templateItemsSub = this._entityConfigService.getPartyTemplates(CharTypeId.Transaction, this.accountGroup.dealTemplateId, this.accountGroup.partyId).pipe(
			map(entities => {
				return entities.map(e => this._oneConfig.getTemplate(e.charTypeId, e.templateId));
			}),
			map(templates => {
				this.templateItems = templates.map(t => AccountingProcesses.KeyValueOptionMapping.fromTemplate(t));
				return templates;
			}),
			tap(templateItems => {
				const charCollection = templateItems.map(ti => this._oneConfig.getTemplateMetaData(CharTypeId.User, ti.templateID));
				this.picklistItems = uniqBy(flatten(charCollection.map(c => c.characteristicMetaDatas.filter(cmd => AccountingProcesses.CharDataValueMapping.isLov(cmd)))), "characteristicID");
			})
		).subscribe();

		const templateGroupItemsSub = this._entityConfigService.getTemplateGroupList(CharTypeId.User)
			.subscribe(contactTemplateGroups => {
				this.templateGroupItems = contactTemplateGroups.map(ctg => AccountingProcesses.KeyValueOptionMapping.fromTemplateGroup(ctg));
			});

		this._subs.push(templateItemsSub, templateGroupItemsSub);
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousIndex === event.currentIndex) {
			return;
		}
		moveItemInArray(this.rules, event.previousIndex, event.currentIndex);
		const orderedGroupRuleIds = Object.values(this.rules).map(r => r.groupRuleId).join("|");
		const seqNumOrder = {
			groupId: this.groupId,
			groupRuleIds: orderedGroupRuleIds
		} as IUpdateGroupRuleSequenceNumberOrder;
		const sub = this._accountingProcessesService.updateGroupRuleSequenceNumberOrder(seqNumOrder).pipe(
			debounceTime(200)
		).subscribe();

		this._subs.push(sub);
	}

	editRule(rule: IAccountingProcessAccountGroupRule) {
		this.edit.emit({ group: this.accountGroup, rule });
	}

	deleteRule(rule: IAccountingProcessAccountGroupRule, deleteTemplate: TemplateRef<unknown>) {
		if (this.accountGroup.isAssociated && this.rules.length === 1) {
			const alertText = `${this.accountGroup.groupName} is referenced by one or more Accounting Processes. At least one Rule must exist.`;
			this._promptService.alert("Cannot Delete Rule", alertText);
		} else {
			const sub = this._promptService.confirm("Confirmation", deleteTemplate).pipe(
				filter(confirmDelete => confirmDelete),
				switchMap(() => this._accountingProcessesService.deleteAccountGroupRule(rule.groupRuleId)),
				switchMap(() => this.loadRules$)
			).subscribe(() => this._growlerService.success().growl("Rule deleted successfully"));

			this._subs.push(sub);
		}
	}
}
