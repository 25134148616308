
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { CurrencyConversionType } from "company/components/currency/currency.models";
import { Subscription } from "dexie";
import { ICurrencyConversion, ICurrencySettings, ICurrencyValue } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NgIf } from "@angular/common";
import { CurrencySelectorComponent } from "./currency-selector/currency-selector.component";
import { DateInputComponent } from "../../date-input/date-input.component";
import { LoaderComponent } from "../../panel/loader/loader.component";

@Component({
    selector: "rl-add-edit-currency-conversion-modal",
    templateUrl: "./add-edit-currency-conversion-modal.component.html",
    styleUrls: ["./add-edit-currency-conversion-modal.component.scss"],
    imports: [ReactiveFormsModule, NgIf, CurrencySelectorComponent, DateInputComponent, LoaderComponent]
})
export class AddEditCurrencyConversionModalComponent implements OnInit {

	@Input()
	currencyConversion: ICurrencyConversion;

	@Input()
	conversionType: CurrencyConversionType;

	@Input()
	fromLabel: string;

	@Input()
	toLabel: string;

	@Input()
	currencySettings: ICurrencySettings;

	@Output()
	onComplete = new EventEmitter<boolean>();

	isCreate = false;
	action = "Edit";
	buttonText = "Save";
	fromCurrency = new UntypedFormControl("", [Validators.required]);
	toCurrency = new UntypedFormControl("", [Validators.required]);
	rate = new UntypedFormControl("", [Validators.required]);
	effectiveDate = new UntypedFormControl("", [Validators.required]);
	rateSource = new UntypedFormControl("", []);
	currencyValues: ICurrencyValue[] = [];
	rateSourceLabel = "Exchange Rate Source";
	rateSourceValues: ICurrencyValue[] = [];
	currency = "";
	isLoading = true;
	errorMessage: string = null;
	subs: Subscription[] = [];
	isDeprecated = true;

	formGroup = new UntypedFormGroup({
		// fromCurrency: this.fromCurrency,
		toCurrency: this.toCurrency,
		rate: this.rate,
		effectiveDate: this.effectiveDate,
		rateSource: this.rateSource
	});

	constructor(private readonly _modal: NgbActiveModal,
		private readonly _companyService: CompanyService,
		private readonly _featureService: FeatureService) {

	}

	ngOnInit(): void {
		if (this.fromLabel !== "Group") {
			this.formGroup.addControl("fromCurrency", this.fromCurrency);
		}
		this.currencyValues = this.currencySettings.currencyLov?.sort((a, b) => a.charValueLabel.localeCompare(b.charValueLabel)) ?? [];
		this.rateSourceValues = this.currencySettings.exchangeRateSourceLov.sort((a, b) => a.charValueLabel.localeCompare(b.charValueLabel));
		this.currency = this.currencySettings.companyCurrency;
		this.isLoading = false;
		const sub = this._featureService.isEnabled$(FeatureKeys.NewCurrencyTables)
			.subscribe(isEnabled => {
				this.isDeprecated = !isEnabled;
				if (!this.isDeprecated) {
					this.rateSource.addValidators(Validators.required);
				}
			});
		this.subs.push(sub);

		if (!this.currencyConversion) {
			this.isCreate = true;
			this.action = "Create New ";
			this.buttonText = "Create";
			if (this.fromLabel === "Group") {
				this.fromCurrency.setValue(this.currencySettings.companyCurrency);
			}
		} else {
			this.fromCurrency.setValue(this.currencyConversion.fromCurrency);
			this.toCurrency.setValue(this.currencyConversion.toCurrency);
			this.rate.setValue(this.currencyConversion.rate);
			this.effectiveDate.setValue(this.currencyConversion.effectiveDate);
			if (!this.isDeprecated) {
				const matchingRateSource = this.rateSourceValues.find(rs => rs.charValueID === this.currencyConversion.rateSource);
				this.rateSource.setValue(matchingRateSource.charValueLabel);
			}
		}
	}

	hasError(control: UntypedFormControl) {
		return control.invalid && control.touched;
	}

	cancel() {
		this._modal.close();
	}

	canSave() {
		return this.formGroup.valid && this.formGroup.dirty;
	}

	save() {
		const fromCurrency = this.fromLabel === "Group" ? this.currency : this.formGroup.controls["fromCurrency"].value;
		const toCurrency = this.formGroup.controls["toCurrency"].value;
		const rate = this.formGroup.controls["rate"].value;
		const effectiveDate = this.formGroup.controls["effectiveDate"].value;

		let rateSourceValueId = 1;
		let upsert$ = null;
		if (!this.isDeprecated) {
			const rateSource = this.formGroup.controls["rateSource"].value;
			const rateSourceLov = this.currencySettings.exchangeRateSourceLov.find(ers => ers.charValueLabel === rateSource);
			rateSourceValueId = rateSourceLov.charValueID;
		}
		if (this.isCreate) {
			upsert$ = this._companyService.createCurrencyConversion(fromCurrency, toCurrency, rate, effectiveDate, this.conversionType, rateSourceValueId, this.isDeprecated);
		} else {
			if (this.isDeprecated) {
				upsert$ = this._companyService.updateCurrencyConversionLegacy(this.currencyConversion.currencyConversionID, this.conversionType, fromCurrency, toCurrency, rate, effectiveDate, rateSourceValueId);
			} else {
				upsert$ = this._companyService.updateCurrencyConversion(this.currencyConversion.conversionId, this.conversionType, fromCurrency, toCurrency, rate, effectiveDate, rateSourceValueId);
			}
		}
		const sub = upsert$.pipe(
			catchError(err => {
				this.errorMessage = "Existing currency pair exists with matching parameters.";
				return throwError(err);
			})
		).subscribe(() => this.onComplete.emit());
		this.subs.push(sub);
	}
}
