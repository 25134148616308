import { Injectable } from "@angular/core";
import { CharTypeId } from "rl-common/consts";
import { AssociationService } from "rl-common/services/associations/association.service";
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { ICharTypeAssociations } from "./transfer-associations.model";

@Injectable()
export class TransferAssociationsService {

	public isLoading$ = new BehaviorSubject<boolean>(false);
	public charTypes$ = new BehaviorSubject<ICharTypeAssociations[]>(null);

	constructor(
		private readonly _associationService: AssociationService
	) { }

	loadAssociations(sourceCharTypeId: number, sourceRecordId: number, destCharTypeId: CharTypeId, destTemplateId: number) {
		this.isLoading$.next(true);
		return this._associationService.getGroupedAssociations(sourceCharTypeId, sourceRecordId, destCharTypeId, destTemplateId).pipe(
			tap(r => {
				this.isLoading$.next(false);
				this.charTypes$.next(r.charTypes);
			})
		);
	}
}
