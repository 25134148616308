<div [formGroup]="formGroup">
	<div class="modal-header">
		<h2 class="modal-title">
			<ng-container [ngSwitch]="action">
				<ng-container *ngSwitchCase="'create'">
					Create New Workflow Role
				</ng-container>
				<ng-container *ngSwitchCase="'edit'">
					Edit Workflow Role
				</ng-container>
				<ng-container *ngSwitchCase="'copy'">
					Copy Workflow Role
				</ng-container>
			</ng-container>
		</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body scroll-vert">
		<div class="alert alert-danger" *ngIf="isDuplicate">
			A Workflow Role with this name already exists.
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="char-data__label pt-2">
					<span>Role Name</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<input type="text" [formControl]="roleName" class="form-control" (keyup)="checkForError()" />
					</div>
				</div>
			</div>
			<div class="char-data__group__element">
				<div class="char-data__label pt-2">
					<span>Description</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<input type="text" [formControl]="roleDescription" class="form-control" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" class="btn btn-light me-3">Cancel</button>
		<button (click)="save()" [disabled]="!canSave" class="btn btn-success">
			<i *ngIf="isSaving" class="far fa-spinner fa-spin"></i>
			<ng-container *ngIf="action == 'create'">
				Create
			</ng-container>
			<ng-container *ngIf="action != 'create'">
				Save
			</ng-container>
		</button>
	</div>
</div>