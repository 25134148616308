<ng-container *ngIf="textModeEnabled; else dateInput">
	<div class="input-group" [class.has-error]="!textDateControl.valid">
		<input class="form-control text-mode-input text-uppercase" type="text" placeholder="NOW-14DAYS"
			[formControl]="textDateControl" (ngModelChange)="textDateUpdated()" [disabled]="disabled"
			data-cy="DateInput">
		<div class="input-group-append">
			<button type="button" class="btn btn-light btn-sm" (click)="disableTextMode()" title="Enter date selection">
				<i class="far fa-calendar"></i>
			</button>
			<button type="button" class="btn btn-info btn-sm" [disabled]="true" title="Enter textual dates">
				<i class="far fa-i-cursor text-white"></i>
			</button>
			<small class="mx-2 my-1 text-muted">
				Ex.
				<code class="me-1">NOW</code>
				<code class="me-1">NOW-14DAYS</code>
				<code class="me-1">NOW/MONTH+1MONTH</code>
			</small>
		</div>
	</div>
</ng-container>
<ng-template #dateInput>
	<rl-date-input [(ngModel)]="dateValue" (ngModelChange)="dateUpdated()" [onlyValidDates]="true">
		<div class="input-group-append">
			<button type="button" class="btn btn-info btn-sm" [disabled]="true" title="Enter date selection">
				<i class="far fa-calendar text-white"></i>
			</button>
			<button type="button" class="btn btn-light btn-sm" (click)="enableTextMode()" title="Enter textual dates">
				<i class="far fa-i-cursor"></i>
			</button>
		</div>
	</rl-date-input>
</ng-template>