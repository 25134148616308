import { Provider } from "@angular/core";
import { ADDCONTACTACCOUNTS_MODAL_PROVIDER } from "./components/accounting-processes/add-contact-accounts-modal/add-contact-accounts-modal.component";
import { BUILDACCOUNTGROUPRULE_MODAL_PROVIDER } from "./components/accounting-processes/build-account-group-rule-modal/build-account-group-rule-modal.component";
import { BUILDACCOUNTINGPROCESS_MODAL_PROVIDER } from "./components/accounting-processes/build-accounting-process-modal/build-accounting-process-modal.component";
import { COPYACCOUNTINGPROCESS_MODAL_PROVIDER } from "./components/accounting-processes/copy-accounting-process-modal/copy-accounting-process-modal.component";

export const COMPANY_MODAL_PROVIDERS: Provider[] = [
	ADDCONTACTACCOUNTS_MODAL_PROVIDER,
	BUILDACCOUNTINGPROCESS_MODAL_PROVIDER,
	COPYACCOUNTINGPROCESS_MODAL_PROVIDER,
	BUILDACCOUNTGROUPRULE_MODAL_PROVIDER
]