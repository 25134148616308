<div *ngFor="let history of nestedDataSource.rowData$ | async">
	<div [ngSwitch]="history.historyType">
		<!--Status Change-->
		<div *ngSwitchCase="1">
			<strong>Set to {{ statusLabels()?.get(history.ignored) }}</strong>
			by <a [routerLink]="modLinkHelper.details(history.createdById)">{{ history.createdByName }}</a>
			&middot;
			<span>{{ history.createdTimestamp | date:'medium' }}</span>
			<p>{{ history.actionNote }}</p>
		</div>
		<!--Assigned-->
		<div *ngSwitchCase="2">
			<a [routerLink]="modLinkHelper.details(history.createdById)">{{ history.createdByName }}</a> <strong>
				assigned to </strong>
			<a [routerLink]="modLinkHelper.details(history.assigneeUserId)">{{ history.assigneeUserName }}</a>
			&middot;
			<span>{{ history.createdTimestamp | date:'medium' }}</span>
		</div>
	</div>
</div>