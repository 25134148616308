import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { LoginOptionsResponse } from "../login.models";
import { NgIf } from "@angular/common";
import { BusyButtonDirective } from "../../../../common/directives/busy-button.directive";

@Component({
    selector: "rl-sso-login-button",
    templateUrl: "./sso-login-button.component.html",
    styleUrls: ["./sso-login-button.component.scss"],
    imports: [NgIf, BusyButtonDirective]
})
export class SsoLoginButtonComponent implements OnInit {

	@Input()
	loginOptions: LoginOptionsResponse;

	@ViewChild("ssoLoginForm", { static: true })
	ssoLoginForm: ElementRef;

	constructor() { }

	ngOnInit(): void {
	}

	buildRelayState() {
		const state = {
			appUrlID: this.loginOptions.ssoAppUrlID,
			returnURL: "/"
		};

		return JSON.stringify(state);
	}

	ssoLogin() {
		this.ssoLoginForm.nativeElement.submit();
	}

}
