import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IContactsDictionaries } from "rl-common/components/contacts/models/i-contacts-dictionaries";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { EditContactCharDataComponent } from "../../contacts/edit-contacts/edit-contact-char-data/edit-contact-char-data.component";

@Component({
    selector: "rl-edit-contact-modal",
    templateUrl: "./edit-contact-modal.component.html",
    styleUrls: ["./edit-contact-modal.component.scss"],
    imports: [EditContactCharDataComponent]
})
export class EditContactModalComponent {
	@Input()
	recordId: number;

	@Input()
	dictionaries: IContactsDictionaries;

	@Input()
	dealId: number;

	@Input()
	contactNameChar: ICharacteristicData;

	@Output()
	onComplete = new EventEmitter<boolean>();

	constructor(private readonly _modal: NgbActiveModal) { }

	onSave() {
		this.onComplete.emit(true);
	}

	cancel() {
		this.onComplete.emit(false);
		this._modal.close();
	}
}
