import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AssocModuleSelectDataSource, isAssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { Subscription } from "rxjs";
import { ComponentRelationshipComponent } from "../../../associations/entity-relationships/entity-relationship/component-relationship.component";
import { RelationshipTypeSelectComponent } from "../relationship-type-select/relationship-type-select.component";

@Component({
    selector: "rl-module-association",
    templateUrl: "./module-association.component.html",
    styleUrls: ["./module-association.component.scss"],
    imports: [NgIf, RelationshipTypeSelectComponent, ReactiveFormsModule, FormsModule, ComponentRelationshipComponent]
})
export class ModuleAssociationComponent implements OnInit, OnDestroy {
	@Input()
	dataSource: AssocModuleSelectDataSource<IEntitySearchDoc, IEntitySearchDoc>;

	@Input()
	charTypeId: CharTypeId;

	@Output()
	onEntityRelationshipChange = new EventEmitter<IEntityRelationshipState<number, IEntitySearchDoc>>();

	isIntraChar = false;
	subs: Subscription[] = [];
	relationships: RelationshipTypes[] = [RelationshipTypes.Child, RelationshipTypes.Parent];
	selectedDirection: RelationshipTypes;
	constructor() { }

	ngOnInit(): void {
		this.selectedDirection = this.dataSource.direction;
		if (!isAssocModuleSelectDataSource(this.dataSource)) {
			console.warn(`ModuleAssociationComponent: 'this.dataSource' is not of type 'AssocModuleSelectDataSource.'`);
		}
		this.isIntraChar = this.charTypeId === this.dataSource.parent?.charTypeId;
		if (this.dataSource.isLocked) {
			this.relationships = [RelationshipTypes.Parent];
			this.selectedDirection = RelationshipTypes.Parent;
			this.setDirection();
		}
	}

	directionChanged() {
		this.setDirection();
	}

	setDirection() {
		this.dataSource.dataSelectStrategy.clearSelected();
		this.dataSource.setDirection(this.selectedDirection);
		const sub = this.dataSource.fetchRows().subscribe();
		this.subs.push(sub);
	}

	buildSearchOptions() {
		let searchOptions = null;
		if (this.dataSource.direction === RelationshipTypes.Parent) {
			searchOptions = SearchOptionsFactory.buildNewAvailableParentAssociationOptions(this.charTypeId, this.dataSource.parent.charTypeId);
			searchOptions.isNewAssociation = true;
		} else {
			if (this.dataSource.includeCurrentAssociations) {
				searchOptions = SearchOptionsFactory.buildNewAvailableChildAssociationOptionsWithCurrentAssociations(this.dataSource.parent.charTypeId, this.charTypeId);
			} else {
				searchOptions = SearchOptionsFactory.buildNewAvailableChildAssociationOptions(this.dataSource.parent.charTypeId, this.charTypeId);
			}
			searchOptions.isNewAssociation = true;
		}
	}

	updateState(state: IEntityRelationshipState<number, IEntitySearchDoc>) {
		this.onEntityRelationshipChange.emit(state);
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
