
export enum ExecuteWorkFlowStep {
	GenerateDocument = "generate-document",
	SendForSignature = "send-for-signature",
	SendForSignaturePartiesOnly = "send-for-signature-parties-only",
	PreviewNotifications = "preview-notifications",
	InProgress = "in-progress",
	PreviewDocument = "preview-document",
	PreviewChained = "preview-chained",
	Loading = "loading"
}
