import { DealScopedOptions, DocumentOptions, RevisionStatus } from "rl-common/services/entity-config/entity-config.models";

export class EntityConfigUtil {
	private static documentOptions: number[] = [
		DocumentOptions.none,
		DocumentOptions.generatesDoc,
		DocumentOptions.sendForEsig,
		DocumentOptions.sendForEsigPartiesOnly,
		DocumentOptions.esigComplete
	];

	private static dealScopedOptions: number[] = [
		DealScopedOptions.Disabled,
		DealScopedOptions.Enabled,
		DealScopedOptions.OneToOne
	];

	private static revisionStatusOptions: number[] = [
		RevisionStatus.InSetup,
		RevisionStatus.Complete,
		RevisionStatus.Approved,
		RevisionStatus.InRevision
	]

	static getDocumentOptions() {
		return this.documentOptions;
	}

	static getDealScopedOptions() {
		return this.dealScopedOptions;
	}

	static getRevisionStatus() {
		return this.revisionStatusOptions;
	}
}