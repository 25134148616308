import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { IApplicationInfo } from "rl-common/services/company/company.models";
import { UserService } from "rl-common/services/user/user.service";
import { Subscription } from "rxjs";
import { NgFor } from "@angular/common";

@Component({
    selector: "rl-select-apps",
    templateUrl: "./select-apps.component.html",
    styleUrls: ["./select-apps.component.scss"],
    imports: [NgFor]
})
export class SelectAppsComponent implements OnInit, OnDestroy {

	@Output()
	onChange = new EventEmitter<number[]>();

	apps: IApplicationInfo[] = [];
	selectedApps = new Set<number>();

	private readonly _subscriptions: Subscription[] = [];

	constructor(
		private readonly _userService: UserService
	) { }

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	ngOnInit(): void {
		const sub = this._userService.getApps().subscribe(apps => this.apps = apps);
		this._subscriptions.push(sub);
	}

	toggleAppUrlId(appUrlId: number) {
		if (this.selectedApps.has(appUrlId)) {
			this.selectedApps.delete(appUrlId);
		} else {
			this.selectedApps.add(appUrlId);
		}
		const selected = Array.from(this.selectedApps);
		this.onChange.next(selected);
	}

}
