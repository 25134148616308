@if (hasRels) {
<h4>Select Associations</h4>
<div data-cy="PanelAccordion" class="mb-3">
	<div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true" [destroyOnHide]="false">
		@for (relCharTypeId of visibleRelCharTypeIds; track relCharTypeId) {
		<div ngbAccordionItem="{{ relCharTypeId }}">
			<div ngbAccordionHeader [attr.data-cy]="(relCharTypeId | charTypeName) + 'Accordion'">
				<button ngbAccordionButton
					class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : !accordion.isExpanded(relCharTypeId.toString())}">
					<span [class.is-required-invalid]="isRequiredInvalid(relCharTypeId.toString())">
						{{relCharTypeId | charTypeName}}
						<span class="text-medium text-muted">({{selectedStateDictionary[relCharTypeId] ?
							selectedStateDictionary[relCharTypeId].selectedCount : 0}})</span>
						<span *ngIf="isRequiredInvalid(relCharTypeId.toString())">*</span>
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="accordion.isExpanded(relCharTypeId.toString()) ? 'Collapse' : 'Expand'"
						[attr.data-cy]="accordion.isExpanded(relCharTypeId.toString()) ? 'ToCollapse' : 'ToExpand'"
						[class.open]="accordion.isExpanded(relCharTypeId.toString())">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						@if (useSqlServerDatasources && isCatalogsOnRights(relCharTypeId)) {
						<rl-sql-component-relationship [dataSource]="dataSourceDict[relCharTypeId]"
							[assocCharTypeId]="relCharTypeId" [charTypeId]="charTypeId"
							(onEntityRelationshipChange)="updateState($event, relCharTypeId)">
							<div>
								<span [class.is-required-invalid]="isRequiredInvalid(relCharTypeId.toString())"
									*ngIf="isRequiredInvalid(relCharTypeId.toString())">
									At least one {{relCharTypeId | charTypeName}} is required.
								</span>
							</div>
						</rl-sql-component-relationship>
						} @else {
						<rl-component-relationship [dataSource]="dataSourceDict[relCharTypeId]"
							[assocCharTypeId]="relCharTypeId" [charTypeId]="charTypeId" [templateId]="templateId"
							(onEntityRelationshipChange)="updateState($event, relCharTypeId)">
							<div>
								<span [class.is-required-invalid]="isRequiredInvalid(relCharTypeId.toString())"
									*ngIf="isRequiredInvalid(relCharTypeId.toString())">
									At least one {{relCharTypeId | charTypeName}} is required.
								</span>
							</div>
						</rl-component-relationship>
						}
					</ng-template>
				</div>
			</div>
		</div>
		}
	</div>
</div>
}