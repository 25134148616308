import { EventEmitter, Injectable, Type } from "@angular/core";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { ICreateAccountingProcessAccountGroupRule } from "rl-common/services/accounting-processes/models/i-create-accounting-process-account-group-rule";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
import { IBuildAccountGroupRuleResult } from "./build-account-group-rule-modal.models";
export interface IBuildAccountGroupRuleModalComponent {
	accounts: IAccountingProcessAccount[];
	accountGroup: IAccountingProcessAccountGroup;
	accountGroupRule: ICreateAccountingProcessAccountGroupRule;
	maxSequenceNumber: number;
	onClose: EventEmitter<IBuildAccountGroupRuleResult>;
}

@Injectable({ providedIn: "root" })
export class BuildAccountGroupRuleModalAdapter extends ModalAdapter<IBuildAccountGroupRuleModalComponent> {
	constructor(type: Type<IBuildAccountGroupRuleModalComponent>) {
		super(type);
	}

}


