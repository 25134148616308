import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ICharDataChangeEvent } from "rl-common/models/i-char-data-change-event";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { IDUtil } from "rl-common/utils/id.util";
import { CreateEntityComponent } from "./create-entity.component";
import { ICanDeactivateComponent, OnEntityCreatedEvent } from "./create-entity.models";

@Component({
    selector: "rl-create-entity-container",
    templateUrl: "./create-entity-container.component.html",
    imports: [CreateEntityComponent]
})
export class CreateEntityContainerComponent implements ICanDeactivateComponent, OnInit {
	charTypeId: number;
	templateId: number;
	parentId: string;
	isParentAssociation: number;
	sourceId: string;
	canDeactivate: boolean = true;

	constructor(private sessionService: SessionService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private progressService: ProgressService,
		private titleService: Title) {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.charTypeId = +params["charTypeId"];
			this.templateId = +params["templateId"];
			this.parentId = params["parentId"];
			this.isParentAssociation = +params["isParentAssociation"];
			this.sourceId = params["sourceId"]
		});
	}

	ngOnInit(): void {
		this.titleService.setTitle("Create Entity");
	}

	nextPageLogic(event: OnEntityCreatedEvent) {
		this.canDeactivate = true;
		this.progressService.endProgress();
		const { newEntityId } = event;

		const newRecId = IDUtil.splitEntityID(newEntityId).recID;

		let nextPage = "";
		nextPage = `mod${this.charTypeId}/${newRecId}`;
		this.router.navigateByUrl(nextPage);
	}

	cancel() {
		this.canDeactivate = true;
		let cancelUrl = `mod${this.charTypeId}`;

		if (this.parentId) {
			const id = IDUtil.splitEntityID(this.parentId);
			cancelUrl = `mod${id.charTypeID}/${id.recID}`;
		}

		this.router.navigateByUrl(cancelUrl);
	}

	charDataUpdated(event: ICharDataChangeEvent) {
		this.canDeactivate = this.hasUnsavedChanges(event);
	}

	private hasUnsavedChanges(event: ICharDataChangeEvent) {
		const hasInitialChanges = event.charData.length;
		const charData = event.charData;

		// Edge case: rather than null, deleting a value replaces it with an empty string
		const hasEmptyString = charData.some(data => data?.value === "");

		if (!hasInitialChanges || (hasInitialChanges && hasEmptyString)) {
			return true;
		}

		return false;
	}
}
