import { FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { isEmpty } from "lodash";
import { IWfAction, IWfRole, IWfStep } from "rl-common/services/company/company.models";
import { ISelectedChainedAction, ISelectedNotification } from "rl-common/services/entity-config/entity-config.models";

export interface IWorkflowActionForm {
	actionId: FormControl<number>;
	actionName: FormControl<string>;
	actionDescription: FormControl<string>;
	initialAction: FormControl<boolean>;
	nextStatus: FormControl<IWfStep>;
	docIndicator: FormControl<IKeyValueOption>;
	associatedRoles: FormControl<IWfRole[]>;
	chainedActions: FormArray<FormControl<ISelectedChainedAction>>;
	notifications: FormArray<FormControl<ISelectedNotification>>;
	allowActionDuringValidations: FormControl<boolean>;
	allowActionWithBlockers: FormControl<boolean>;
	updateCurrencyConversion?: FormControl<boolean>;
}

export interface IKeyValueOption {
	key: number;
	value: string;
}


export interface IWfProcessAndActions {
	processID: number;
	charTypeID: number;
	processName: string;
	processDescription: string;
	actions: IWfAction[];
	divisionID?: number;
	sequenceNumber?: number;
	createdBy?: number;
	createdAt?: Date;
	updatedBy?: number;
	updatedAt?: Date;
}

export enum PointProcIds {
	middleOrFinalAction = 0,
	initialAction = 1
}

export const workflowActionRequiredFieldsValidator: ValidatorFn = (form: FormGroup<IWorkflowActionForm>): ValidationErrors | null => {
	const errors: ValidationErrors = {};

	if (!form.controls.actionName.value) {
		const requiredResult = Validators.required(form.controls.actionName);
		if (requiredResult !== null) {
			errors["actionNameRequired"] = true;
		}
	}

	if (!form.controls.nextStatus.value) {
		const requiredResult = Validators.required(form.controls.nextStatus);
		if (requiredResult !== null) {
			errors["nextStatusRequired"] = true;
		}
	}

	if (!form.controls.docIndicator.value) {
		const requiredResult = Validators.required(form.controls.docIndicator);
		if (requiredResult !== null) {
			errors["docIndicatorRequired"] = true;
		}
	}

	if (isEmpty(errors)) {
		return null;
	}

	return errors;
};

export const notificationControlValidator: ValidatorFn = (notif: FormControl<ISelectedNotification>): ValidationErrors | null => {
	const errors: ValidationErrors = {};

	if (notif.value.notificationId === null && (notif.value.selectedRoleIds.length || notif.value.selectedParties.length)) {
		errors["invalidNotificationId"] = true;
	}

	if (notif.value.notificationId && !notif.value.selectedRoleIds.length && !notif.value.selectedParties.length) {
		errors["invalidRolesAndParties"] = true;
	}

	if (isEmpty(errors)) {
		return null;
	}

	return errors;
};

export const duplicateNotificationsControlValidator: ValidatorFn = (form: FormGroup<IWorkflowActionForm>): ValidationErrors | null => {
	const errors: ValidationErrors = {};
	const notificationsFormArray = form.controls.notifications;
	const notifications = notificationsFormArray.controls.map(n => n.value);
	const allNotifIds = notifications.map(n => n.notificationId);
	const duplicateNotifIds = new Set(allNotifIds).size !== allNotifIds.length;
	const duplicatesAreNull = allNotifIds.some(id => id === null);

	if (duplicateNotifIds && !duplicatesAreNull) {
		errors["duplicateNotificationId"] = true;
	}

	if (isEmpty(errors)) {
		return null;
	}

	return errors;
};
