<div [formGroup]="form">
	<div class="d-flex align-items-center bg-light p-2 border-bottom" formArrayName="chainedActions"
		*ngFor="let chainedActionControl of chainedActionsFormControls; let i = index; let last = last"
		[class.mb-3]="last">
		<div [formGroupName]="i" class="flex-grow-1">
			<rl-chained-action-row [form]="form" [actions]="actions" [chainedActionControl]="chainedActionControl"
				[processes]="processes"></rl-chained-action-row>
		</div>
		<button type="button" class="btn btn-icon btn-remove me-2 ms-auto" (click)="removeRow(i)" title="Remove Row"
			data-cy="deleteRow"><i class="far fa-times"></i></button>
	</div>
	<div class="d-flex justify-content-between">
		<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" [hidden]="chainedActionsIsEmpty"
			[disabled]="chainedActionsAllInUse" data-cy="addAnotherRow">
			<i class="far fa-plus"></i> Add another row
		</button>
		<button type="button" class="btn btn-sm btn-link" (click)="addNewRow()" data-cy="addNewRow"
			[hidden]="!chainedActionsIsEmpty">
			<i class="far fa-plus"></i> Add row
		</button>
		<button type="button" class="btn btn-sm btn-remove text-grey" (click)="clearRows()"
			[hidden]="chainedActionsIsEmpty" data-cy="clearRows">
			<i class="far fa-times me-1"></i> Clear all
		</button>
	</div>
</div>