import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "rl-confirmation-modal",
    templateUrl: "./confirmation-modal.component.html",
    imports: [NgIf]
})
export class ConfirmationModalComponent implements OnInit {

	@Input()
	title: string;

	@Input()
	message: string;

	@Input()
	btnOkText: string;

	@Input()
	btnCancelText: string;

	@Output()
	onConfirm = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit() {
	}

	public decline() {
		this.onConfirm.emit(false);
	}

	public accept() {
		this.onConfirm.emit(true);
	}

	public dismiss() {
		this.onConfirm.emit(false);
	}
}
