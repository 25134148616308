import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { FormControlWarn } from "rl-common/components/char-data/elements/element-validators";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { CharTypeId, SystemIndicators } from "rl-common/consts";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IRelSearchDoc } from "rl-common/models/i-rel-search-doc";
import { Subscription } from "rxjs";
import { DateInputComponent } from "../../../date-input/date-input.component";
import { ChildTemplateSelectorComponent } from "../../../template/template-selector/child-template-selector.component";
import { BulkConfig } from "../../bulk-config.strategy";
import { INewEntityTemplateCharData } from "../../bulk-grid.models";
import { DueDateSplitType, RecurrenceType } from "../bulk-create-amounts.models";
import { UnevenAmountControlComponent } from "../uneven-amount-control/uneven-amount-control.component";
import { UnevenDateControlComponent } from "../uneven-date-control/uneven-date-control.component";
import { OneConfigService } from "./../../../../services/one-config/one-config.service";
import { AmountSplitType } from "./../bulk-create-amounts.models";
import { BulkCreateAmountsFormErrorsComponent } from "./bulk-create-amounts-form-errors/bulk-create-amounts-form-errors.component";
import { Warnings } from "./bulk-create-amounts-form-errors/bulk-create-amounts-form-errors.model";
import { IBulkCreateAmountsFormData } from "./bulk-create-amounts-form.models";

@Component({
	selector: "rl-bulk-create-amounts-form",
	templateUrl: "./bulk-create-amounts-form.component.html",
	styleUrls: ["./bulk-create-amounts-form.component.scss"],
	imports: [NgIf, ReactiveFormsModule, BulkCreateAmountsFormErrorsComponent, ChildTemplateSelectorComponent, NgbPopover, UnevenAmountControlComponent, UnevenDateControlComponent, NgFor, DateInputComponent]
})
export class BulkCreateAmountsFormComponent implements OnInit, OnDestroy, OnChanges {
	@Input()
	templateId: number;

	@Input()
	dataSource: SearchGridDataSource<IRelSearchDoc, INewEntityTemplateCharData>;

	@Input()
	bulkConfig: BulkConfig;

	@Output()
	onUpdated = new EventEmitter<IBulkCreateAmountsFormData>();

	amountTemplateId: number;
	amountCharTypeId: CharTypeId = CharTypeId.Amount;
	bulkCreateAmountsForm: UntypedFormGroup;
	subs: Subscription[] = [];

	recurrenceOptions = [
		{
			label: "Daily",
			value: RecurrenceType.Daily,
		},
		{
			label: "Weekly",
			value: RecurrenceType.Weekly,
		},
		{
			label: "Monthly",
			value: RecurrenceType.Monthly,
		},
		{
			label: "Quarterly",
			value: RecurrenceType.Quarterly,
		},
		{
			label: "Annually",
			value: RecurrenceType.Annually,
		},
	];
	constructor(
		private readonly _oneConfigService: OneConfigService
	) { }

	get templateName() {
		const t = this.bulkCreateAmountsForm.controls.template.value;
		return t && t.templateName;
	}

	get splitType() {
		return this.bulkCreateAmountsForm.controls.splitType;
	}

	get dueDateType() {
		return this.bulkCreateAmountsForm.controls.dueDateType.value;
	}

	get numRows() {
		return this.bulkCreateAmountsForm.controls.numRows.value;
	}

	get unevenAmount() {
		return this.bulkCreateAmountsForm.controls.unevenAmount.value;
	}

	get unevenPercentage() {
		return this.bulkCreateAmountsForm.controls.unevenPercentage.value;
	}

	get unevenDate() {
		return this.bulkCreateAmountsForm.controls.unevenDate.value;
	}

	get recurrence() {
		return this.bulkCreateAmountsForm.controls.recurrence.value;
	}

	get recurrenceDate() {
		return this.bulkCreateAmountsForm.controls.recurrenceDate.value;
	}

	get perCatalogItem() {
		return this.bulkCreateAmountsForm.controls.perCatalogItem.value;
	}

	get copyFromTables() {
		return this.bulkCreateAmountsForm.controls.copyFromTables.value;
	}

	get isAmountDisabled() {
		const warnings = (this.bulkCreateAmountsForm?.controls?.config as FormControlWarn)?.warnings;
		return warnings && warnings[Warnings.TableTotalFee];
	}

	get hasNoFee() {
		const warnings = (this.bulkCreateAmountsForm?.controls?.config as FormControlWarn)?.warnings;
		return warnings && warnings[Warnings.TablePercent];
	}

	get isDueDateHidden() {
		const warnings = (this.bulkCreateAmountsForm?.controls?.config as FormControlWarn)?.warnings;
		return warnings && warnings[Warnings.AmountDueDate];
	}

	totalFeeConfigured = false;

	ngOnInit() {
		this.bulkCreateAmountsForm = new UntypedFormGroup({
			template: new UntypedFormControl(null),
			templateId: new UntypedFormControl(null),
			numRows: new UntypedFormControl(1),
			perCatalogItem: new UntypedFormControl(false),
			copyFromTables: new UntypedFormControl(true),
			dueDateType: new UntypedFormControl(DueDateSplitType.ForEachRow),
			splitType: new UntypedFormControl(AmountSplitType.Evenly),
			unevenAmount: new UntypedFormControl([]),
			unevenPercentage: new UntypedFormControl({ value: [], disabled: true }),
			unevenDate: new UntypedFormControl([]),
			recurrence: new UntypedFormControl(null),
			recurrenceDate: new UntypedFormControl(""),
		});
		const sub = this.bulkCreateAmountsForm.valueChanges.subscribe(changes => {
			this.emitEvent();
		});
		this.subs.push(sub);
		this.updateDefaultSplitTypeOptions();
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.templateId && changes.templateId.currentValue !== changes.templateId.previousValue) {
			const template = this._oneConfigService.getTemplateMetaData(CharTypeId.Usage, this.templateId);
			this.totalFeeConfigured = !!template.characteristicMetaDatas.find(x => x.systemIndicator === SystemIndicators.UsageTotalFee);
			this.updateDefaultSplitTypeOptions();
		}
	}

	private updateDefaultSplitTypeOptions() {
		if (!this.bulkCreateAmountsForm) {
			return;
		}
		const splitType = this.totalFeeConfigured ? AmountSplitType.Evenly : AmountSplitType.LeaveBlank;
		this.bulkCreateAmountsForm.controls.splitType.setValue(splitType);
	}

	updateMetaData(templateId: number) {
		this.amountTemplateId = templateId;
		if (this.shouldTurnOffPerCatalogItem(templateId)) {
			this.bulkCreateAmountsForm.controls.perCatalogItem.setValue(false);
			this.bulkCreateAmountsForm.controls.perCatalogItem.disable();
		} else if (this.bulkCreateAmountsForm?.controls?.perCatalogItem?.disabled) {
			this.bulkCreateAmountsForm.controls.perCatalogItem.enable();
		}
	}

	shouldTurnOffPerCatalogItem(templateId: number) {
		return this._oneConfigService.getChildAssocTemplateIds(CharTypeId.Amount, templateId, CharTypeId.Property).length === 0
			&& this.bulkCreateAmountsForm?.controls?.perCatalogItem?.value;
	}

	emitEvent() {
		if (this.unevenPercentage) {
			const control = this.bulkCreateAmountsForm.controls.unevenPercentage;
			if (this.splitType.value === AmountSplitType.UnevenlyByPercentage) {
				if (control.disabled) {
					control.enable();
				}
			} else {
				if (control.enabled) {
					control.disable();
				}
			}
		}
		const formData = {
			templateId: this.amountTemplateId,
			numRows: this.numRows,
			perCatalogItem: this.perCatalogItem,
			copyFromTables: this.copyFromTables,
			dueDateType: this.dueDateType,
			splitType: this.splitType.value,
			unevenAmount: this.unevenAmount,
			unevenPercentage: this.unevenPercentage,
			unevenDate: this.unevenDate,
			recurrence: this.recurrence,
			recurrenceDate: this.recurrenceDate,
			valid: this.bulkCreateAmountsForm.valid
		};
		this.onUpdated.emit(formData);
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
