import { KeyValuePipe, NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import _, { first, isEmpty } from "lodash";
import { AdvancedSearch } from "rl-common/components/advanced-search/rl-advanced-search.consts";
import { CharDataType, CharTypeId, ConstUtils, VirtualCharTagLabels } from "rl-common/consts";
import { ICharacteristic } from "rl-common/models/i-characteristic";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IQueryNode, QueryNodeValue } from "rl-common/models/i-query-node";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { DateLocaleType, DateUtil } from "rl-common/utils";
import { CharacteristicUtil } from "rl-common/utils/characteristic.util";
import { IAdvancedSearchForm } from "../../advanced-search-modal/advanced-search-models";
import { IPreviousSelection } from "./query-node-row.models";
import { QueryNodeValueEntryComponent } from "./query-node-value-entry/query-node-value-entry.component";

export interface IQueryNodeRowChangedEvent {
	queryNode: IQueryNode;
}

@Component({
	selector: "rl-query-node-row",
	templateUrl: "./query-node-row.component.html",
	styleUrls: ["./query-node-row.component.scss"],
	imports: [ReactiveFormsModule, FormsModule, NgFor, NgIf, QueryNodeValueEntryComponent, KeyValuePipe]
})
export class QueryNodeRowComponent implements OnInit, OnChanges {

	@Input()
	form: FormGroup<IAdvancedSearchForm>;

	@Input()
	queryFormControl: FormControl<IQueryNode>;

	@Input()
	charTypeId: number;

	@Input()
	queryNodeStrategies: AdvancedSearch.IQueryNodeJoinStrategy[];
	queryNodeStrategyGroups: _.Dictionary<AdvancedSearch.IQueryNodeJoinStrategy[]> = {};
	selectedQueryNodeStrategyIndex = 0;
	hasParameters = true;

	isLoadingCharMeta = false;

	charDatas: ICharacteristic[];
	systemFields: ICharacteristic[] = [];
	selectedCharDataTagLabel: string;

	comparators: AdvancedSearch.IComparatorOption[] = [];
	selectedComparatorIndex = 0;

	values: string[] = [];
	queryNodeError: string;

	get selectedQueryNodeStrategy() {
		return this.queryNodeStrategies[this.selectedQueryNodeStrategyIndex];
	}

	get selectedCharData() {
		const tagLabel = this.selectedCharDataTagLabel;
		const charDatas = this.systemFields.concat(this.charDatas || []);
		return tagLabel && charDatas.find(x => x.tagLabel === tagLabel);
	}

	get selectedComparator() {
		return this.comparators && !isEmpty(this.comparators) && this.comparators[this.selectedComparatorIndex];
	}

	get dateVirtualCharTagLabels(): string[] {
		return [VirtualCharTagLabels["Created"], VirtualCharTagLabels["LastUpdated"], VirtualCharTagLabels["StatusUpdated"]];
	}

	constructor(private readonly _oneConfig: OneConfigService) { }

	ngOnInit() {
		const queryNode = this.queryFormControl.value;
		const previousSelection = this.mapPreviousSelectionsFromQueryNode(queryNode);
		this.selectedQueryNodeStrategyIndex = previousSelection?.strategy?.index ?? 0;
		this.loadCharMetaData(previousSelection);
	}

	private mapPreviousSelectionsFromQueryNode(queryNode: IQueryNode): IPreviousSelection {
		// constructs the previous selections from the query node
		if (isEmpty(queryNode)) {
			return null;
		}

		const strategy = this.queryNodeStrategies.find(x => x.unwrapQueryNodeFn(queryNode) !== null);
		if (!strategy) {
			console.warn(`No query node strategy found for query node!`);
			return null;
		}

		const unwrappedQueryNode = strategy.unwrapQueryNodeFn(queryNode);
		const selectedComparator = AdvancedSearch.getComparatorFromQueryNode(unwrappedQueryNode);
		if (!selectedComparator) {
			console.warn(`No comparators mapped from query node!`);
			return null;
		}

		let previousValues = selectedComparator.fromQueryNode(unwrappedQueryNode);
		if (this.dateVirtualCharTagLabels.includes(previousValues.fieldName)) {
			previousValues = this.getLocalDateValues(previousValues);
		}
		const previousSelection: IPreviousSelection = {
			strategy,
			fieldName: AdvancedSearch.fromFieldName(previousValues.fieldName),
			comparator: selectedComparator,
			values: previousValues.values
		};

		return previousSelection;
	}

	private getLocalDateValues(previousValues: AdvancedSearch.IQueryNodeComparatorSelection) {
		const localValues: QueryNodeValue[] = [];
		// get local date from previous UTC values
		previousValues.values.forEach(dateValue => {
			const localMoment = DateUtil.parseToLocalMoment(dateValue.toString(), DateLocaleType.Storage);
			const dateOnly = DateUtil.formatUTCMomentAsDate(localMoment, DateLocaleType.Storage);
			localValues.push(dateOnly);
		});
		previousValues.values = localValues;

		return previousValues;
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.queryNodeStrategies) {
			this.queryNodeStrategyGroups = _(this.queryNodeStrategies)
				.groupBy(x => x.type)
				.value();
		}
	}

	loadCharMetaData(previousSelection: IPreviousSelection = null) {
		this.charDatas = [];
		this.isLoadingCharMeta = true;
		this.selectedCharDataTagLabel = VirtualCharTagLabels.Id;
		this.isLoadingCharMeta = false;

		if (this.selectedQueryNodeStrategy.type === AdvancedSearch.JoinStrategySelectionType.Party) {
			this.charDatas = this._oneConfig.getCharTypeCmds(CharTypeId.User);
			this.systemFields = this.getSystemCharacteristics(CharTypeId.User);
		} else {
			const chars = this._oneConfig.getCharTypeChars(this.selectedQueryNodeStrategy.key) ?? [];
			this.charDatas = chars.filter(x => x.systemIndicator >= 0);
			this.systemFields = this.getSystemCharacteristics(this.selectedQueryNodeStrategy.key);
		}

		this.charDatas = this.charDatas.filter(x => !AdvancedSearch.unsupportedDataTypes.has(x.dataTypeID));
		this.charDatas.sort((a, b) => a.label.localeCompare(b.label));

		if (previousSelection) {
			const found = this.systemFields.concat(this.charDatas).find(x => x.tagLabel === previousSelection.fieldName || this.getSolrFieldName(x) === previousSelection.fieldName);
			if (found) {
				this.selectedCharDataTagLabel = found?.tagLabel;
			} else {
				console.warn(`previousSelection.fieldName: ${previousSelection.fieldName} not found in charDatas!`);
			}
		}

		this.loadComparators(previousSelection);
	}

	loadComparators(previousSelection: IPreviousSelection = null) {
		this.selectedComparatorIndex = 0;
		this.comparators = AdvancedSearch.getComparatorsForCharacteristic(this.selectedCharData);

		if (previousSelection) {
			let comparatorIndex = this.comparators.findIndex(x => x.value === previousSelection.comparator.value);
			if (comparatorIndex < 0 && previousSelection.comparator.value in AdvancedSearch.DeprecatedComparatorLookup) {
				const comparator = AdvancedSearch.DeprecatedComparatorLookup[previousSelection.comparator.value];
				comparatorIndex = this.comparators.findIndex(x => x.value === comparator.value);
			}
			this.selectedComparatorIndex = comparatorIndex === -1 ? 0 : comparatorIndex;
			const queryNodeValues: QueryNodeValue[] = previousSelection.values;
			this.values = queryNodeValues.map(x => x.toString());
			this.updateFormControl();
		} else {
			this.resetValues();
		}
		const matchingComparator = this.comparators[this.selectedComparatorIndex];
		this.hasParameters = !AdvancedSearch.ComparatorsWithoutParameters.includes(matchingComparator.value);
	}

	resetValues() {
		this.values = [];
		this.updateFormControl();
	}

	softResetValues() {
		const comparator = this.selectedComparator;
		this.hasParameters = !AdvancedSearch.ComparatorsWithoutParameters.includes(comparator.value);
		if (!this.hasParameters) {
			this.values = [];
		} else if (!comparator.isSetQuery && this.values?.length > 1) {
			this.values = [first(this.values)];
		}
		this.updateFormControl();
	}

	private updateFormControl() {
		const result = this.tryBuildQueryNode();
		if (result && result.success) {
			result.queryNode.description = this.buildDescription();
			this.queryFormControl.setValue(result.queryNode);
		} else {
			this.queryFormControl.setValue(null);
		}
	}

	private tryBuildQueryNode() {
		try {
			const fieldName = AdvancedSearch.toFieldName(this.selectedCharData, this._oneConfig);
			const queryNode = this.selectedComparator.toQueryNode(fieldName, [...this.values]);
			const joinedQueryNode = this.selectedQueryNodeStrategy.joinQueryNodeFn(queryNode);
			return { queryNode: joinedQueryNode, success: true, message: "" };
		} catch (e) {
			return { queryNode: null, success: false, message: e.message };
		}
	}

	public buildDescription() {
		const name = this.selectedQueryNodeStrategy.name;
		const charMetaDataName = this.selectedCharData.label;
		const comparator = this.selectedComparator.label;
		const values = this.formatValues(this.values);
		return `${name} ${charMetaDataName} ${comparator} ${values}`;
	}

	public formatValues(values: string[]) {
		if (this.selectedCharData?.dataTypeID === CharDataType.Date) {
			const dateTimeTagLabels: string[] = [VirtualCharTagLabels.LastUpdated, VirtualCharTagLabels.Created, VirtualCharTagLabels.StatusUpdated];
			const isDateTime = dateTimeTagLabels.includes(this.selectedCharData.tagLabel);
			if (!isDateTime) {
				// characteristic dates are queried by UTC (with no offset)
				const formattedDates = (values ?? []).map(value => {
					const isTextModeDate = DateUtil.isTextModeDate(value);
					if (isTextModeDate) {
						return value;
					}
					return DateUtil.formatAsDate(value, DateLocaleType.Browser);
				});
				return formattedDates.join(", ");
			}
		}
		return values && values.join(", ");
	}

	private getSolrFieldName(characteristic: ICharacteristic) {
		return CharacteristicUtil.GetCharSolrLabel(
			characteristic.dataTypeID,
			characteristic.multipleIndicator,
			characteristic.tagLabel);
	}

	updateValues(values: string[]) {
		this.values = values;
		this.updateFormControl();
	}

	private getSystemCharacteristics(charTypeId: CharTypeId) {
		const systemCharacteristics: ICharacteristic[] = [
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "ID",
				tagLabel: VirtualCharTagLabels.Id,
				maxLength: 0,
				dataTypeID: CharDataType.Number,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Name",
				tagLabel: VirtualCharTagLabels.Title,
				maxLength: 0,
				dataTypeID: CharDataType.Alphanumeric,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Status",
				tagLabel: VirtualCharTagLabels.Status,
				maxLength: 0,
				dataTypeID: CharDataType.Alphanumeric,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Template",
				tagLabel: VirtualCharTagLabels.Template,
				maxLength: 0,
				dataTypeID: CharDataType.Alphanumeric,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Last Updated",
				tagLabel: VirtualCharTagLabels.LastUpdated,
				maxLength: 0,
				dataTypeID: CharDataType.Date,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Created",
				tagLabel: VirtualCharTagLabels.Created,
				maxLength: 0,
				dataTypeID: CharDataType.Date,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Created By",
				tagLabel: VirtualCharTagLabels.CreatedBy,
				maxLength: 0,
				dataTypeID: CharDataType.Number,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
			{
				charTypeID: charTypeId,
				characteristicID: 0,
				label: "Status Updated",
				tagLabel: VirtualCharTagLabels.StatusUpdated,
				maxLength: 0,
				dataTypeID: CharDataType.Date,
				systemIndicator: -1,
				multipleIndicator: 0,
				extractable: false,
				charValueSetID: null
			},
		];

		return systemCharacteristics;
	}

	selectionTypeName(selectionType: AdvancedSearch.JoinStrategySelectionType) {
		switch (+selectionType) {
			case AdvancedSearch.JoinStrategySelectionType.Party:
				return `Associated Parties`;
			case AdvancedSearch.JoinStrategySelectionType.ParentAndChild:
				return `Associations`;
			case AdvancedSearch.JoinStrategySelectionType.CurrentCharType:
				return ConstUtils.isModuleLevelCharType(this.charTypeId) ? `Current Module` : `Current Component`;
			case AdvancedSearch.JoinStrategySelectionType.DirectParentModule:
				return `Direct Parent Module`;
			case AdvancedSearch.JoinStrategySelectionType.DirectParentComponent:
				return `Direct Parent Component`;
			case AdvancedSearch.JoinStrategySelectionType.DirectChild:
				return `Child Associations`;
		}
	}
}

