import { Component, OnInit } from "@angular/core";

@Component({
    selector: "rl-docusign-grant",
    templateUrl: "./docusign-grant.component.html"
})
export class DocusignGrantComponent implements OnInit {
	constructor() { }

	ngOnInit() {
	}
}