

export enum AlliantFilterVerb {
	contains = "contains",
	endswith = "endswith",
	startswith = "startswith",
	eq = "eq",
	ne = "ne",
	le = "le",
	lt = "lt",
	ge = "ge",
	gt = "gt"
}