import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { IzendaTokenService } from "../../common/services/izenda-token.service";
import { IIzendaIntegrationStyle, IzendaIntegrateService } from "../../common/services/izenda/izenda-integrate.service";
import { ProgressService } from "../../common/services/progress.service";
import { IzendaUrlChangeService } from "../services/url-change/izenda-url-change.service";

@Directive({ selector: "[rlIzendaReport]" })
export class IzendaReportDirective implements OnInit, OnDestroy {

	@Input()
	reportId: string;

	@Input()
	hideToolbar = false;

	@Input()
	hideFilter = false;

	@Input()
	hideReportName = false;

	@Input()
	hidePreviewRecords = false;

	private readonly _subscriptions: Subscription[] = [];

	constructor(
		private readonly _integrateService: IzendaIntegrateService,
		private readonly _izendaUrlChangeService: IzendaUrlChangeService,
		private el: ElementRef,
		private readonly _izendaTokenService: IzendaTokenService,
		private readonly _progressService: ProgressService
	) {
		this._subscriptions.push(this._izendaTokenService.initialize().subscribe());
	}

	ngOnInit(): void {
		var sub1 = this._izendaTokenService.isLoading$.pipe(
			map(loading => {
				if (!loading) {
					var sub2 = this._izendaTokenService.izendaToken$.pipe(
						map(token => {
							if (token) {
								const random = Math.floor(Math.random() * 500);
								this._integrateService.setContext(token);
								this._integrateService.DoIzendaConfig();
								const integrationStyle: IIzendaIntegrationStyle = {
									hideToolbar: this.hideToolbar,
									hideFilter: this.hideFilter,
									hideReportName: this.hideReportName,
									hidePreviewRecords: this.hidePreviewRecords
								};
								// Any trivial change to how Izenda is initialized could cause random and unpredictable failures
								// Stagger rendering to hopefully prevent Izenda from getting confused by the routing when there are multiple reports presented
								// This works in conjunction with the routing delay in izenda-url-change.service.ts
								setTimeout(() => {
									this.el.nativeElement = this._integrateService.RenderReportViewerById(this.reportId, integrationStyle);
								}, random);
							}
						})
					).subscribe();

					this._subscriptions.push(sub2);
				}
			})
		).subscribe();

		this._subscriptions.push(sub1);
		this._izendaUrlChangeService.registerListener();
	}

	ngOnDestroy(): void {
		this._integrateService.DestroyDom(this.el.nativeElement);
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
