<div class="modal-header">
	<h2 class="modal-title" [innerHTML]="title"></h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()" data-cy="closeModal">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<p class="mb-2">In order to leverage the Configuration Comparison Tool, <strong>you must be granted temporary access
			to the API
			in {{environmentName}}</strong> (the Compare To environment you selected).</p>
	<ul class="mb-4">
		<li>In your Activity Policy in {{environmentName}}, ensure that the "Generate API Keys" toggle is On.</li>
		<li>Click the button below to grant access to the Configuration Comparison Tool for the next {{expirationHours}}
			hours.</li>
		<li>After the temporary access token is generated, it will be automatically copied to your clipboard. Paste it into the validation modal on your other screen.</li>
	</ul>
	<button type="button" class="btn btn-success" [disabled]="isLoading" 
		(click)="accept()" *ngIf="!accessToken" data-cy="GrantAccessBtn">
		<i *ngIf="isLoading" class="far fa-spinner fa-spin me-1"></i>
		Grant Temporary Access
	</button>
	<div class="mb-3 d-flex align-items-center" *ngIf="accessToken">
		<div class="d-flex-vertical flex-fill">
			<strong>Access Token</strong>
			<input type="text" class="form-control mb-2" readonly [(ngModel)]="accessToken" />
			<strong class="text-success" *ngIf="isCopied">Copied to Clipboard!</strong>
		</div>
		<button class="btn btn-sm btn-info ms-3" (click)="copyToClipboard(accessToken)">
			Copy
			<i class="far fa-copy ms-1"></i>
		</button>
	</div>
</div>
<div class="modal-footer" data-cy="ModalFooter">
	<button type="button" class="btn btn-light" (click)="decline()" data-cy="CancelButton">Close</button>
</div>