import { KeyValuePipe, NgFor } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BulkConfig } from "rl-common/components/bulk-grid/bulk-config.strategy";
import { INewEntityTemplateCharData } from "rl-common/components/bulk-grid/bulk-grid.models";
import { FormControlWarn } from "rl-common/components/char-data/elements/element-validators";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { CharTypeId } from "./../../../../../rl-common.consts";
import { OneConfigService } from "./../../../../../services/one-config/one-config.service";
import { BulkCreateAmountsDataSource } from "./../../../../grid/datasource/bulk/bulk-create/bulk-create-amounts-datasource";
import { AmountSplitType } from "./../../bulk-create-amounts.models";
import { BulkCreateCustomErrorsUtil, Warnings } from "./bulk-create-amounts-form-errors.model";

@Component({
    selector: "rl-bulk-create-amounts-form-errors",
    templateUrl: "./bulk-create-amounts-form-errors.component.html",
    styleUrls: ["./bulk-create-amounts-form-errors.component.scss"],
    imports: [NgFor, KeyValuePipe]
})
export class BulkCreateAmountsFormErrorsComponent implements OnInit, OnDestroy {
	@Input()
	form: UntypedFormGroup;

	@Input()
	bulkConfig: BulkConfig;

	@Input()
	dataSource: BulkCreateAmountsDataSource<INewEntityTemplateCharData>;

	catalogMismatchWarning = "The 'Per Catalog' option has been disabled due to Catalog Items of the following template(s) not being allowed on this Amount type: ";
	warningsLookup = {
		"table_total_fee": "The selected Tables have differing Amounts.",
		"table_percent": "One or more of the selected Tables does not have a Fee specified. Percent-based Amounts cannot be created and Amounts cannot be validated.",
		"amount_due_date": "The selected Amount type does not have a Due Date Characteristic.",
		"catalogs_missing": "The 'Per Catalog' option has been disabled due to Catalog items not being allowed on this Amount type",
		"catalog_mismatch": this.catalogMismatchWarning,
	};
	errorsLookup = BulkCreateCustomErrorsUtil.errorsLookup;
	readonly subs: Subscription[] = [];
	constructor(
		private readonly _oneConfigService: OneConfigService,
	) { }

	ngOnInit() {
		this.form.controls.templateId.statusChanges.subscribe(() => {
			if (this.config) {
				this.clearCustomErrors();
			}
			this.addControl(this.form.controls.templateId.value);
		});
	}

	get warnings() {
		return this.config && this.config.warnings;
	}
	get errors() {
		return this.config && this.config.errors;
	}
	get config(): FormControlWarn {
		return this.form?.controls && <FormControlWarn>this.form?.controls["config"];
	}

	clearCustomErrors() {
		this.form.removeControl("config");
	}
	addControl(amountTemplateId: number) {
		const sub = this.dataSource.rowData$
			.pipe(
				filter((rowData) => !!rowData),
				take(1)
			)
			.subscribe((rowData) => {
				const extraGridColumnResults = this.dataSource.extraGridColumnResults$.value;;
				const val = BulkCreateCustomErrorsUtil.bulkCreateConfigValidator(this._oneConfigService, this.bulkConfig, amountTemplateId, rowData, extraGridColumnResults);
				const tmds = BulkCreateCustomErrorsUtil.getMismatchedTemplates(this._oneConfigService, amountTemplateId, extraGridColumnResults)
					.map(templateId => this._oneConfigService.getTemplateMetaData(CharTypeId.Property, templateId));

				this.warningsLookup.catalog_mismatch = this.catalogMismatchWarning + tmds.map(tmd => tmd.template.templateName).join(",");
				const ctrl = new FormControlWarn(null, [val]);
				if (ctrl.warnings) {
					if (ctrl.warnings[Warnings.TablePercent]) {
						if (ctrl.warnings[Warnings.TableTotalFee]) {
							this.form.controls.splitType.setValue(AmountSplitType.LeaveBlank);
						} else {
							this.form.controls.splitType.setValue(AmountSplitType.UnevenlyByAmount);
						}
					}
					if (ctrl.warnings[Warnings.CatalogMismatch] || ctrl.warnings[Warnings.CatalogsMissing]) {
						this.form.controls.perCatalogItem.disable();
					}
				}
				this.form.addControl("config", ctrl);
			});
		this.subs.push(sub);
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
