import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { isEmpty } from "lodash";
import { ISelectEntityRelationshipStateEvent } from "rl-common/components/associations/entity-relationships/entity-relationship/models/i-select-entity-relationship-state-event";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { AssocEntitiesBitmapDataSource } from "rl-common/components/grid/datasource/search/assoc-entities-bitmap.datasource";
import { AssocEntitySelectDataSource } from "rl-common/components/grid/datasource/search/assoc-entity-select.datasource";
import { CharTypeId } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { BitmapAllocatorService } from "rl-common/services/bitmap-allocator/bitmap-allocator.service";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { RoaringBitmapUtil } from "rl-common/utils/roaring-bitmap.util";
import { ComponentRelationshipBitmap } from "../../associations/entity-relationships/entity-relationship/component-relationship-bitmap.component";
import { ComponentRelationshipComponent } from "../../associations/entity-relationships/entity-relationship/component-relationship.component";
import { FeatureFlagComponent } from "../../feature-flag/feature-flag.component";

@Component({
	selector: "rl-bulk-grid-select-associations",
	templateUrl: "./bulk-grid-select-associations.component.html",
	styleUrls: ["./bulk-grid-select-associations.component.scss"],
	providers: [BitmapAllocatorService],
	imports: [FeatureFlagComponent, ComponentRelationshipBitmap, ComponentRelationshipComponent]
})
export class BulkGridSelectAssociationsComponent implements OnInit {
	@Input()
	charTypeId: CharTypeId;

	@Input()
	assocCharTypeId: CharTypeId;

	@Input()
	parentCharTypeId: CharTypeId;

	@Input()
	parentRecordId: number;

	@Input()
	templateId: number;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onApply = new EventEmitter<ISelectEntityRelationshipStateEvent<number, IGridViewAssocEntityRec>>();

	dataSource: AssocEntitiesBitmapDataSource<IEntitySearchDoc, unknown>;

	/* ceprecated */
	assocDataSource: AssocEntitySelectDataSource<IEntitySearchDoc, unknown>;

	entityRelationshipState: ISelectEntityRelationshipStateEvent<number, IGridViewAssocEntityRec>;
	constructor(
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _featureService: FeatureService,
		private readonly _bitmapAllocator: BitmapAllocatorService
	) { }

	ngOnInit() {
		const useBitmapStrategy = this._featureService.isFeatureEnabled(FeatureKeys.UseRoaringBitmaps)
		if (useBitmapStrategy) {
			this.dataSource = this._gridDataSourceBuilder.assocEntitiesSearchBitmapDataSource(this._bitmapAllocator, this.assocCharTypeId, this.charTypeId, this.templateId, null, [], this.parentCharTypeId, this.parentRecordId)
				.withInheritedRels(true)
				.withSelectableIdsBitmap(true, null);
		} else {
			this.assocDataSource = this._gridDataSourceBuilder.assocEntitySearchDataSource(this.assocCharTypeId, this.charTypeId, this.templateId, null, [], this.parentCharTypeId, this.parentRecordId)
				.withInheritedRels(true);
		}
	}

	apply() {
		this.onApply.emit(this.entityRelationshipState);
	}

	updateEntityRelationship(event: ISelectEntityRelationshipStateEvent<number, IGridViewAssocEntityRec>) {
		this.entityRelationshipState = event;
	}

	updateEntityRelationshipDeprecated(event: IEntityRelationshipState<number, IGridViewAssocEntityRec>) {
		let serialized = "";
		if (!isEmpty(event.selectedIds)) {
			const bitmap = this._bitmapAllocator.allocateBitmap(event.selectedIds);
			serialized = RoaringBitmapUtil.serializeBitmapToBase64(bitmap);
			bitmap.dispose();
		}

		this.entityRelationshipState = {
			inputListSelected: event.inputListSelected,
			selectedBitmap: serialized,
			selectedCount: event.selectedCount,
			model: event.model,
			isValid: event.isValid,
			labelFn: event.labelFn
		};
	}

}
