<div class="d-inline-flex align-items-center" *ngIf="availableTemplates.length > 1">
	<label class="title--overline mb-0" for="templateSelector">Template:</label>
	<select id="templateSelector" class="form-select" [formControl]="templateFC">
		<option *ngFor="let t of availableTemplates" [ngValue]="t.templateID">
			{{t.templateName}}
		</option>
	</select>
</div>
<rl-char-data-table *ngIf="templateMetaData != null && charData != null" #charDataTable [charData]="charData"
	[template]="templateMetaData" [editMode]="true" (tableCharDataChange)="charData=$event.charData">
</rl-char-data-table>