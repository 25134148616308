import { NgFor } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { PickerFileMetadata } from "filestack-js";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { FileStackService } from "rl-common/services/file-stack/file-stack.service";
import { MachineLearningService } from "rl-common/services/ml/ml.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { GrowlerService } from "rl-common/services/growler.service";
import { FaIconDirective } from "../../../directives/fa-icon.directive";

@Component({
    selector: "rl-extract-pdf",
    templateUrl: "./extract-pdf.component.html",
    styleUrls: ["./extract-pdf.component.scss"],
    imports: [ReactiveFormsModule, FormsModule, NgFor, FaIconDirective]
})

export class ExtractPdfComponent implements OnInit {

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	isUploading: boolean = false;
	isUploaded: boolean = false;
	fileExists: boolean = false;

	requiredFileType = ".pdf";

	templates: ICharacteristicTemplate[] = [];
	fileName: string;
	file: PickerFileMetadata;

	_subscriptions: Subscription[];

	constructor(
		private router: Router,
		private readonly _mlService: MachineLearningService,
		private readonly _fileStackService: FileStackService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _growler: GrowlerService
	) {
		
	}

	ngOnInit(): void {
		this.templates = [];
		this.templates = this._oneConfigService.getTemplates(4);
		this.charTypeId = 4;//wasn't getting passed in for some reason
		console.log('templates', this.templates);
	}

	upload() {
		this.isUploading = true;
		const options = this._fileStackService.getBasicFileStackOptions();
		options.accept = "application/pdf";
		options.onUploadDone = (response) => {
			this.file = response.filesUploaded[0];
			this.fileName = this.file.filename;
			this.saveAttachment(this.file);
		};
		options.onFileUploadFinished = () => {
			this.fileExists = true;
		};
		options.onClose = () => {
			if (!this.fileExists) {
				this.isUploading = false;
			}
		};
		this._fileStackService.upload(options);
	}

	private saveAttachment(file: PickerFileMetadata) {
		const sub = this._mlService.uploadContract(4, this.templateId, file.originalPath, file.key).subscribe(() => {
			this.isUploaded = true;
			this.isUploading = false;
		},() => {
			this.isUploading = false;
			this._growler.error().growl("Could not run extraction at this time.");
		}
		);
		this._subscriptions.push(sub);
	}

	cancel() {
		const cancelUrl = `mod${this.charTypeId}`;

		this.router.navigateByUrl(cancelUrl);
	}


	clear() {
		this.isUploaded = false;
	}
}
