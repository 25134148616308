import { Component, EventEmitter, Input, OnInit, Output, Provider } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { CreateNewCharDataModalAdapter, ICreateNewCharDataModalComponent } from "./create-new-char-data-modal.adapter";
import { CreateNewCharDataComponent } from "./create-new-char-data/create-new-char-data.component";
import { IEntityCreatedEvent } from "./create-new-char-data/models/i-entity-create-event";

@Component({
	selector: "rl-create-new-char-data-modal",
	templateUrl: "./create-new-char-data-modal.component.html",
	styleUrls: ["./create-new-char-data-modal.component.scss"],
	imports: [CreateNewCharDataComponent]
})
export class CreateNewCharDataModalComponent implements OnInit, ICreateNewCharDataModalComponent {

	@Input()
	template: ICharacteristicTemplate;

	@Input()
	availableTemplates: ICharacteristicTemplate[];

	@Input()
	parentEntityId: string;

	@Input()
	baseEntityId: string;

	@Input()
	charTypeId: number;

	@Input()
	isVariesByCatalog: boolean;

	@Input()
	isRelative: boolean;

	@Output()
	onCreate = new EventEmitter<IEntityCreatedEvent>();

	constructor(private readonly _modal: NgbActiveModal) { }

	ngOnInit() {

	}

	cancel() {
		this._modal.dismiss();
	}

	emitCreate(event: IEntityCreatedEvent) {
		this.onCreate.emit(event);
		this._modal.close();
	}

}

// export const CREATE_NEW_CHAR_DATA_MODAL_TOKEN = new InjectionToken("CreateNewCharDataModalComponent");

// export const CREATE_NEW_CHAR_DATA_MODAL_PROVIDER: Provider = {
// 	provide: ModalAdapter <	
// };

export const CREATENEWCHARDATA_MODAL_PROVIDER: Provider = {
	provide: CreateNewCharDataModalAdapter,
	useFactory: () => new CreateNewCharDataModalAdapter(CreateNewCharDataModalComponent)
};
