<div class="modal-header">
	<h2 class="modal-title" [innerHtml]="pageTitle"></h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="CloseButton">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	@if (formReady || isNewAccountGroupRule) {
	<rl-form-table-row [labelText]="'Contact Rule Type'">
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.contactRuleType" [options]="contactRuleTypeOptions"
				[showEmptyOption]="false" [dataLabel]="'ContactRuleTypeDropdown'" [showEmptyOption]="true">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	@if(form.controls.contactRuleType?.value?.id === 1) {
	<rl-form-table-row [labelText]="'Trigger Value - Select Picklist'">
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.picklist" [options]="triggerPicklistOptions"
				[showEmptyOption]="false" [dataLabel]="'triggerPicklistDropdown'">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	}
	@if(form.controls.contactRuleType?.value?.id === 1 && form.controls.picklist.value?.charValueSetID !== null) {
	<rl-form-table-row [labelText]="'Select Picklist Value'">
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.picklistValue" [options]="triggerPicklistValueOptions"
				[showEmptyOption]="false" [dataLabel]="'triggerPicklistValueDropdown'">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	}
	@if(form.controls.contactRuleType.value?.id === 2) {
	<rl-form-table-row [labelText]="'Trigger Value - Select Contact Record'">
		<ng-template rlFormTableControl>
			@if(form.controls.contactRecord.value === null) {
			<rl-ap-contact-type-ahead class="flex-grow-1" [partyId]="accountGroup.partyId"
				[validTemplates]="triggerTemplateItems" (onTypeAheadSelected)="contactRecordTypeAheadAdd($event)"
				#typeAhead>
			</rl-ap-contact-type-ahead>
			} @else {
			<span [innerHTML]="form.controls.contactRecord.value.value"></span>
			<button data-cy="RemoveContactRecordButton" class="btn btn-icon btn-remove ng-star-inserted"
				[title]="'Remove ' + form.controls.contactRecord.value.value" (click)="clearContactRecord()">
				<i class="far fa-times"></i>
			</button>
			}
		</ng-template>
	</rl-form-table-row>
	}
	@if(form.controls.contactRuleType.value?.id === 3) {
	<rl-form-table-row [labelText]="'Trigger Value - Select Template'">
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.template" [options]="triggerTemplateOptions"
				[showEmptyOption]="false" [dataLabel]="'triggerTemplateDropdown'">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	}
	@if(form.controls.contactRuleType.value?.id === 4) {
	<rl-form-table-row [labelText]="'Trigger Value - Select Template Group'">
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.templateGroup" [options]="triggerTemplateGroupOptions"
				[showEmptyOption]="false" [dataLabel]="'triggerTemplateGroupDropdown'">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	}
	@if(form.controls.contactRuleType.value?.id > 0) {
	<rl-form-table-row [labelText]="'Account'">
		<ng-template rlFormTableControl>
			<rl-dropdown-single [formControl]="form.controls.account" [options]="accountOptions"
				[showEmptyOption]="false" [dataLabel]="'AccountDropdown'">
			</rl-dropdown-single>
		</ng-template>
	</rl-form-table-row>
	}
	} @else {
	<div class="p-4 text-center">
		<rl-loader></rl-loader>
	</div>
	}
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-light ms-auto" (click)="close()" data-cy="CancelButton">
		Cancel
	</button>
	<button (click)="save()" class="btn btn-success" [disabled]="!form?.valid || form?.pristine" data-cy="SaveButton">
		Save
	</button>
</div>