import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ISyncedTemplate } from "company/components/data-bridge/models/alliant-mappings.models";
import { first, isEmpty } from "lodash";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridRowStyles } from "rl-common/components/grid/models/grid-row-styles";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { CharTypeId, SystemIndicators } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { FacetType } from "rl-common/models/facet-type";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { ICollectionFieldDetail } from "rl-common/services/alliant/models/i-collection-field-detail";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SearchService } from "rl-common/services/search/search.service";
import { QueryUtil } from "rl-common/utils";
import { CharacteristicUtil } from "rl-common/utils/characteristic.util";
import { of, Subscription } from "rxjs";
import { filter, map, skip, tap } from "rxjs/operators";
import { ChipComponent } from "../../chip/chip.component";
import { EntitySearchComponent } from "../../entities/entity-search/entity-search.component";

@Component({
    selector: "rl-find-rl-entities",
    templateUrl: "./find-rl-entities.component.html",
    styleUrls: ["./find-rl-entities.component.scss"],
    imports: [NgIf, EntitySearchComponent, NgFor, ChipComponent]
})
export class FindRlEntitiesComponent implements OnInit, OnDestroy {

	@Input()
	fieldDetail: ICollectionFieldDetail;

	@Input()
	selectedValues: IEntitySearchDoc[];

	@Input()
	mappedTemplates: ISyncedTemplate[] = [];

	@Output()
	onSelect = new EventEmitter<IEntitySearchDoc[]>();

	charTypeId: CharTypeId;
	templateCmds: ICharacteristicMetaDataCollection[] = [];
	dataSource: SearchGridDataSource<IEntitySearchDoc>;
	searchOptions: SearchOptions;
	selectChangedSub: Subscription;
	indexedRowDataSub: Subscription;

	alliantIdSolrLabels: { [templateId: number]: string } = {};

	selectedRows: IEntitySearchDoc[];

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _oneConfig: OneConfigService,
		private readonly _gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly _searchService: SearchService,
		private readonly _growler: GrowlerService,
	) { }

	ngOnInit(): void {
		this.charTypeId = first(this.mappedTemplates).charTypeId;
		const templatesWithAlliantIds = this.mappedTemplates.map(x => {
				const template = this._oneConfig.getTemplateMetaData(x.charTypeId, x.templateId);
				const alliantIdCmd = template.characteristicMetaDatas.find(cmd => cmd.systemIndicator === SystemIndicators.AlliantId);
				return [template, alliantIdCmd] as [ICharacteristicMetaDataCollection, ICharacteristicMetaData];
			})
			.filter(tuple => tuple[0] && tuple[1]);

		// only query templates that have an alliant id characteristic
		this.templateCmds = templatesWithAlliantIds.map(tuple => tuple[0]);

		// builds a lookup for each templates solr label that maps to the alliant id
		this.alliantIdSolrLabels = templatesWithAlliantIds.reduce((acc, tuple) => {
			const template = tuple[0];
			const cmd = tuple[1];
			acc[template.templateID] = CharacteristicUtil.GetCharSolrLabel(cmd.dataTypeID, cmd.multipleIndicator, cmd.tagLabel);
			return acc;
		}, {});

		this.searchOptions = this.buildSearchOptions();

		this.buildDataSource();
		const sub = this.dataSource.fetchRows().subscribe();

		this._subs.push(sub);
	}

	buildSearchOptions() {
		const searchOptions = SearchOptionsFactory.buildListPageOptions(this.charTypeId);
		searchOptions.selectAllEnabled = false;
		searchOptions.selectType = SelectType.Radio;
		return searchOptions;
	}

	buildDataSource() {
		this.selectChangedSub?.unsubscribe();
		this.indexedRowDataSub?.unsubscribe();

		const columnStrategy = this._gridDataServiceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(this.charTypeId);

		const dataSelectStrategy = this._gridDataServiceBuilder.dataSelectStrategies
			.searchDocDataSelectStrategy<number, IEntitySearchDoc>(doc => doc.recordID, doc => doc)
			.withSelectType(GridSelectType.Radio)
			.withCanSelectFn(row => {
				// rows with no alliant id set cannot be selected
				const hasAlliantId = this.hasAlliantId(row);
				return of(hasAlliantId);
			});

		this.dataSource = this._gridDataServiceBuilder.entitySearchDataSource(this.charTypeId)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: "recordid", sortDir: 0 })
			.withDataSelectStrategy(dataSelectStrategy)
			.withColumnStrategy(columnStrategy)
			.withGetRowStylesFn(row => {
				// greys out rows with no alliant id values
				const hasAlliantId = this.hasAlliantId(row);
				if (!hasAlliantId) {
					return of([GridRowStyles.Invalid]);
				}
				return of([]);
			})
			.withFetchFn((ds) => {
				const start = ds.rowOffset$.value;
				const pageSize = ds.pageSize$.value;
				const sortKey = ds.sortKey$.value;
				const sortDir = ds.sortDir$.value;

				const templateIds = this.templateCmds.map(x => x.templateID);
				const query = QueryUtil.$eq_any(SearchFieldNames.Entity.templateID, templateIds);

				const options: ISearchRequestOptions = {
					rows: pageSize,
					start,
					sortDir,
					sortField: sortKey as string,
					facetFields: [
						FacetType.Template,
						FacetType.Status
					],
					filterQueries: ds.buildFilterQueries()
				};

				if (this.selectedValues && !isEmpty(this.selectedValues)) {
					options.elevateRecIds = this.selectedValues.map(x => x.recordID);
				}

				return this._searchService.search(this.charTypeId, ds.keywords, query, options).pipe(
					tap((results) => {
						ds.setFacetResults(results.facetResults)
							.setExtraGridColumnResults(results.extraGridColumnResults);
					}),
					map((results) => ({
						rowData: results.innerQueryDocuments,
						rowCount: results.numFound,
						extraGridColumnResults: results.extraGridColumnResults,
						facetResults: results.facetResults
					}))
				);
			});

		this.selectChangedSub = this.dataSource.dataSelectStrategy.selectStateChange$.pipe(
			skip(1), // skip the first initial value,
		).subscribe(state => {
			this.selectedRows = Array.from(state.selectedValues) as IEntitySearchDoc[];
			this.onSelect.next(this.selectedRows);
		});

		// sets the selected values in the grid on each page
		this.indexedRowDataSub = this.dataSource.indexedRowData$.pipe(
			filter(rows => !!rows),
		).subscribe(rows => {
			if (!isEmpty(this.selectedValues)) {
				const selectedIds = (this.selectedValues ?? []).map(x => x.recordID);
				const selected = rows.filter(row => selectedIds.includes(row.data.recordID));

				// only select rows if they exist on the current page
				if (!isEmpty(selected)) {
					this.dataSource.dataSelectStrategy.selectRows(selected);
				}
			}
		});

		this._subs.push(this.selectChangedSub, this.indexedRowDataSub);
	}

	private hasAlliantId(doc: IEntitySearchDoc): boolean {
		const solrLabel = this.alliantIdSolrLabels[doc.templateID];
		const alliantId = doc.characteristics[solrLabel];
		const hasAlliantId = !isEmpty(alliantId);
		return hasAlliantId
	}

	remove(row: IEntitySearchDoc) {
		// update the selected values
		this.selectedRows = this.selectedRows.filter(x => x.recordID !== row.recordID);

		// update the grid
		this.dataSource.dataSelectStrategy.deselectRowById(row.recordID, row);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
