import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, first, flatten } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { ICharDataExtDataDateMathParentRelInfo } from "rl-common/models/i-char-data-ext-data-date-math-parent-rel-info";
import { ICharDataExtDataDateMathRel } from "rl-common/models/i-char-data-ext-data-date-math-rel";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { DateMathSearchTableSelectEvent } from "rl-common/services/datemath/datemath.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { DateUtil } from "rl-common/utils";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { BLANK_DATE as RL_BLANK_DATE, DayTypes, PriorTo } from "../date-edit.models";
import { DatemathSearchTableComponent } from "../datemath-search-table/datemath-search-table.component";

@Component({
    selector: "rl-datemath-edit-row",
    templateUrl: "./datemath-edit-row.component.html",
    styleUrls: ["./datemath-edit-row.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgbPopover, NgIf, DatemathSearchTableComponent, ReactiveFormsModule, FormsModule, NgFor]
})
export class DatemathEditRowComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	relationship: ICharDataExtDataDateMathRel;

	@Input()
	contextEntityId: string;

	@Input()
	contextCharId: number;

	@Input()
	parentRelInfo: ICharDataExtDataDateMathParentRelInfo;

	@Input()
	closeSearch$: Subject<number>;

	@Output()
	remove = new EventEmitter<ICharDataExtDataDateMathRel>();

	@Output()
	dateSelect = new EventEmitter<DateMathSearchTableSelectEvent>();

	@Output()
	relChange = new EventEmitter<ICharDataExtDataDateMathRel>();

	isSelectOpen = false;

	offsetDescription: string;

	pathDescription: string;

	daysList: [number, string][] = [
		[DayTypes.Days, "Days"],
		[DayTypes.BusinessDays, "Business Days"]
	];

	priorToList: [boolean, string][] = [
		[PriorTo.Before, "Before"],
		[PriorTo.After, "After"]
	];

	yearsFC: UntypedFormControl;
	monthsFC: UntypedFormControl;
	weeksFC: UntypedFormControl;
	daysFC: UntypedFormControl;
	daysTypeFC: UntypedFormControl;
	priorToFC: UntypedFormControl;

	patchQuiet = { emitEvent: false };

	@ViewChild(NgbPopover, { static: true })
	popover: NgbPopover;

	private readonly _subscriptions: Subscription[] = [];

	constructor(fb: UntypedFormBuilder, private _charDataTableService: CharDataTableService, private _oneConfig: OneConfigService) {
		this.yearsFC = fb.control(null);
		this.monthsFC = fb.control(null);
		this.weeksFC = fb.control(null);
		this.daysFC = fb.control(null);
		this.daysTypeFC = fb.control(DayTypes.Days);
		this.priorToFC = fb.control(PriorTo.After);
	}

	ngOnInit() {
		if (this.closeSearch$) {
			const sub = this.closeSearch$
				.pipe(filter(dateMathRelId => dateMathRelId !== this.relationship.dateMathRelID))
				.subscribe(() => {
					this.isSelectOpen = false;
				});
			this._subscriptions.push(sub);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["relationship"] || changes["parentRelInfo"]) {
			this.formatOffset(this.relationship);
			this.formatDescription();
		}
		if (changes["relationship"]) {
			this.setFromRelationship();
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(s => s.unsubscribe());
	}

	applyOptions() {
		const rel = cloneDeep(this.relationship);

		rel.yearQuantity = this.yearsFC.value;
		rel.monthQuantity = this.monthsFC.value;
		rel.weekQuantity = this.weeksFC.value;
		rel.dayQuantity = this.daysFC.value;
		rel.frequencyID = this.daysTypeFC.value;
		rel.isPriorTo = this.priorToFC.value;

		this.popover.close();
		this.relChange.emit(rel);
		this.formatOffset(rel);
	}

	cancelOptions() {
		this.popover.close();
		this.setFromRelationship();
	}

	private setFromRelationship() {
		this.yearsFC.patchValue(this.relationship.yearQuantity, this.patchQuiet);
		this.monthsFC.patchValue(this.relationship.monthQuantity, this.patchQuiet);
		this.weeksFC.patchValue(this.relationship.weekQuantity, this.patchQuiet);
		this.daysFC.patchValue(this.relationship.dayQuantity, this.patchQuiet);
		this.daysTypeFC.patchValue(this.relationship.frequencyID || DayTypes.Days, this.patchQuiet);
		this.priorToFC.patchValue(this.relationship.isPriorTo || PriorTo.After, this.patchQuiet);
	}

	formatOffset(rel: ICharDataExtDataDateMathRel) {
		const pl = (count: number, unit: string) => {
			if (count === 1) {
				return `${count} ${unit}`;
			} else {
				return `${count} ${unit}s`;
			}
		};

		const segments: string[] = [];
		if (rel.yearQuantity) {
			segments.push(pl(rel.yearQuantity, "year"));
		}
		if (rel.monthQuantity) {
			segments.push(pl(rel.monthQuantity, "month"));
		}
		if (rel.weekQuantity) {
			segments.push(pl(rel.weekQuantity, "week"));
		}
		if (rel.dayQuantity) {
			let dayLabel = "day";
			if (rel.frequencyID === DayTypes.BusinessDays) {
				dayLabel = "business day";
			}
			segments.push(pl(rel.dayQuantity, dayLabel));
		}
		if (segments.length > 0) {
			if (rel.isPriorTo) {
				segments.push("before ");
			} else {
				segments.push("after ");
			}
		}

		this.offsetDescription = segments.join(" ");
	}

	private formatDescription() {
		// TODO: Somehow, this.relationship.parentCharTypeId is the right, but the record is the catalog.....
		const catalogCharTypeId = CharTypeId.Property;
		if (this.parentRelInfo && this.relationship.parentRecID > -1000) {
			let currentValue = this.parentRelInfo.currentValue;
			if (currentValue === RL_BLANK_DATE) {
				currentValue = "[no date value set]";
			}
			this.pathDescription = `${this.parentRelInfo.path}: ${DateUtil.formatStorageDateAsBrowserDateOrLabel(currentValue)}`;
		} else if (this.relationship.parentRecID <= -1) {
			const parentRecCharId = this.relationship.parentRecCharID > 0 ? this.relationship.parentRecCharID : this.relationship.parentRecCharID / -1000;
			const cd = flatten(Object.values(this._charDataTableService.charDatas)).find(charData => charData.recordCharacteristicID === parentRecCharId);
			const cmd = cd && first(this._charDataTableService.template.characteristicMetaDatas.filter(charMetaData => charMetaData.characteristicID === cd.charactersticID));
			let entityTitle = this._charDataTableService.entityTitle$.value;

			if (cmd) {
				this.pathDescription = `${entityTitle} > Details > ${cmd && cmd.label}`;
			} else {
				let matchingCmd = this._oneConfig.getCharTypeChars(this.relationship.parentCharTypeID).find(x => x.characteristicID == parentRecCharId);
				if (!matchingCmd) { // This happens when  bulk create rights, varies by, column editing datemath.
					matchingCmd = this._oneConfig.getCharTypeChars(CharTypeId.Property).find(x => x.characteristicID == parentRecCharId);
					entityTitle = `Current ${CharTypeIdUtil.toDisplayName(catalogCharTypeId)}`;
				}
				const label = matchingCmd?.label;
				this.pathDescription = `${entityTitle} > Details > ${label}`;
			}
		} else {
			// TODO: Somehow, this.relationship.parentCharTypeId is the right, but the record is the catalog.....
			const charTypeId = CharTypeId.Property;
			const parentRecCharId = this.relationship.parentRecCharID > 0 ? this.relationship.parentRecCharID : this.relationship.parentRecCharID / -1000;
			const parentRecordId = this.relationship.parentRecID > 0 ? this.relationship.parentRecID : this.relationship.parentRecID / -1;
			const label = this._oneConfig.getCharTypeChars(charTypeId).find(x => x.characteristicID == parentRecCharId)?.label;
			this.pathDescription = `${CharTypeIdUtil.toDisplayName(charTypeId)} Record ${parentRecordId}> Details > ${label}`;
		}
	}

	toggleSelectOpen($event: Event) {
		$event.preventDefault();
		if (this.closeSearch$) {
			this.closeSearch$.next(this.relationship.dateMathRelID);
		}
		this.isSelectOpen = !this.isSelectOpen;
	}

	removeRel() {
		this.remove.emit(this.relationship);
	}

	dateSelected($event: DateMathSearchTableSelectEvent) {
		this.isSelectOpen = false;
		this.dateSelect.emit($event);
	}

}
