<div class="modal-header">
	<h2 class="modal-title">Set Allocation Options</h2>
</div>
<div class="modal-body set-allocation-options">
	<h4>Allocating {{totalFeesSym}} {{totalFees | currency:'':''}}</h4>
	<div class="row align-items-stretch g-0 mt-3 p-3 mb-1 bg-light">
		<div class="col p-3 border-end d-flex flex-column justify-content-center">
			<div class="form-check my-1">
				<input class="form-check-input" type="radio" name="allocationTypeGroup" id="evenlyByCatalogItem"
					[(ngModel)]="allocationType" [value]="1" data-cy="evenlyByCatalogItem"
					(change)="onSelectionChange()" [disabled]="isLoading">
				<label class="form-check-label" name="allocationTypeGroup" for="evenlyByCatalogItem">
					Evenly by Catalog Item
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="allocationTypeGroup" id="durationOfRightsGrant"
					[(ngModel)]="allocationType" [value]="0" data-cy="durationOfRightsGrant" name="allocationTypeGroup"
					(change)="onSelectionChange()" [disabled]="isLoading">
				<label class="form-check-label" for="durationOfRightsGrant">
					By Catalog Rights Duration
				</label>
			</div>
		</div>
		<div class="col p-3 border-end d-flex flex-column justify-content-center">
			<div class="form-check my-1">
				<input class="form-check-input" name="allocationSelectionGroup" type="radio" id="selectedCatalogs"
					[(ngModel)]="allocationSelection" [value]="1" data-cy="allocateToSelectedCatalogItems"
					(change)="onSelectionChange()" [disabled]="isLoading">
				<label class="form-check-label" name="allocationSelectionGroup" for="selectedCatalogs">
					Allocate to selected Catalog items
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" name="allocationSelectionGroup" type="radio" id="immediateChildren"
					[(ngModel)]="allocationSelection" [value]="0" data-cy="allocateToImmediateChildren"
					(change)="onSelectionChange()" [disabled]="isLoading">
				<label class="form-check-label" name="allocationSelectionGroup" for="immediateChildren">
					Allocate to immediate children
				</label>
			</div>
		</div>
		<div class="col p-3 border-end d-flex flex-column justify-content-center">
			<label>Rights Dimension Model:</label>
			<select class="form-select mt-1" [disabled]="isLoading || !allocationOptions"
				[(ngModel)]="selectedAllocationModel" (change)="allocationModelChanged()">
				<option [value]="default">Default</option>
				<option *ngFor="let model of allocationOptions" [ngValue]="model">
					{{model.name}}
				</option>
			</select>
		</div>
		<div class="col-5 p-3 d-flex justify-content-between align-items-center text-nowrap">
			<div class="form-check form-switch">
				<rl-checkbox-input [label]="showAllocation" [ngModel]="expandAllocationAmounts" (ngModelChange)="toggle()"
					[disabled]="isLoading" [dataLabel]="'toggleShowAllocationCheckbox'">
				</rl-checkbox-input>
			</div>
			<div>
				<div class="mb-2">
					<div class="form-check">
						<input class="form-check-input" name="viewSelection" type="radio" id="defaultView"
							[(ngModel)]="viewSelection" [value]="0" data-cy="defaultView" (change)="onItemChange(true)"
							[disabled]="isLoading">
						<label class="form-check-label" for="defaultView">
							Default View
						</label>
					</div>
				</div>
				<div class="form-check">
					<input class="form-check-input" name="viewSelection" type="radio" id="firstInFirstOut"
						[(ngModel)]="viewSelection" [value]="1" data-cy="firstInFirstOut" (change)="onItemChange(true)"
						[disabled]="isLoading">
					<label class="form-check-label" for="firstInFirstOut">
						First in, First out view
					</label>
				</div>
			</div>
			<div class="form-check align-self-top">
				<div class="form-check">
					<input class="form-check-input" name="rollUpAmounts" type="checkbox" id="rollUpField"
						[(ngModel)]="rollUp" [value]="1" data-cy="rollUpCheckbox"
						[disabled]="viewSelection === ViewSelection.FirstInFirstOut || isLoading"
						(change)="onItemChange(true)">
					<label class="form-check-label" name="rollUpAmounts" for="rolUpField">
						Rollup to one amount
					</label>
				</div>
			</div>
		</div>
	</div>

	<rl-grid *ngIf="dataSource" #grid [dataSource]="dataSource" [options]="gridOptions" data-cy="BulkGridRLGrid">
		<div *rlCellTemplate="'allocated_fee_amount'; let cellData" class="allocation-col"
			[class.has-error]="checkForError(cellData.rowId)">
			<input type="text" class="form-control text-end" (keyup)="valueChanged(cellData.rowId,$event.target.value)"
				(change)="valueChanged(cellData.rowId, $event.target.value)"
				[ngModel]="allocationFeeChangeLocale[cellData.rowId]" />
		</div>

		<div *rlCellTemplate="'allocated_fee_percent'; let cellData" class="allocation-col"
			[class.has-error]="hasPercentageErrors[cellData.rowId]">
			<input type="text" class="form-control" (ngModelChange)="calculateFee(cellData.rowId, $event)"
				placeholder="{{ bulkAllocatedFeePercentage || '0' }}"
				[(ngModel)]="allocationFeeChangePercent[cellData.rowId]" />
		</div>
		<ng-container
			*rlGridNestedTemplate="let nestedDataSource;let rowPath = rowPath; let parentRowData = parentRowData; let rowData = rowData">
			<rl-allocation-spread [allocatedAmounts]="catalogSpreads[parentRowData.rowId]"
				[parentAmount]="allocationFeeChangeLocale[parentRowData.rowId]"
				(onAmountChange)="updateAmounts($event, parentRowData.rowId)">
			</rl-allocation-spread>
		</ng-container>
	</rl-grid>

	<div class="d-flex justify-content-start">
		<span class="summed-amounts d-flex p-3">
			<strong class="w-50 px-3 text-end">{{totalFeesSym}} {{allocationFeeTotal | currency:'':''}}</strong>
			<strong class="w-50 px-3">{{allocationFeePercentTotal}} %</strong>
		</span>
		<div class="mt-2 px-3" *ngIf="hasError || hasAnyAmountError">
			<p *ngFor="let error of errors" class="text-danger">{{error}}</p>
			<p *ngIf="hasAnyAmountError" class="text-danger">{{displayAmountErrorMessage}}</p>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()" data-cy="closeButton">Close</button>
	<button type="button" class="btn btn-info"
		[disabled]="allocationFeeTotal !== totalFees || hasAnyAmountError || isSaving" (click)="calculateAllocations()"
		data-cy="calculateButton">Calculate</button>
</div>