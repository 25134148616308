<ng-container *ngIf="categories$ | async as categories; else loading">
	<div *ngFor="let category of categories">
		<rl-select-izenda-report [category]="category" (onReportSelected)="reportSelected($event)"
			[selectedReports]="selectedReports" [unselectableReports]="unselectableReports">
		</rl-select-izenda-report>
	</div>
</ng-container>

<ng-template #loading>
	<rl-loader></rl-loader>
</ng-template>