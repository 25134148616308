import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { values } from "lodash";
import { IListChangedEvent } from "rl-common/components/list-selector/list-selector.component";
import { ListSelectorComponent } from "../../list-selector/list-selector.component";
import { IParty } from "../models/i-party";

@Component({
    selector: "rl-party-selector",
    templateUrl: "./party-selector.component.html",
    styleUrls: ["./party-selector.component.scss"],
    imports: [ListSelectorComponent]
})
export class PartySelectorComponent implements OnChanges {

	@Input()
	partyDictionary: { [partyId: number]: IParty };

	@Output()
	onChange = new EventEmitter<IParty[]>();


	unSelectedHeader = "Choose Parties";
	selectedHeader = "Selected Parties";

	partyList: IParty[];
	constructor() { }

	ngOnChanges() {

		if (!this.partyDictionary) {
			return;
		}
		this.partyList = values(this.partyDictionary);
	}


	updateList(event: IListChangedEvent<IParty>) {
		const selectedList = event.selectedList;
		this.onChange.emit(selectedList);
	}

	keyFn(item: IParty) {
		return item.partyID;
	}

	labelFn(item: IParty) {
		return item.partyName;
	}
}
