<div class="modal-header">
	<h2 class="modal-title">
		Add Report To Dashboard
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="container">
		<div class="row">
			<div class="col">
				<h5>Reports</h5>
				<rl-select-widget [currentWidgets]="currentWidgets" [selectedWidgets]="selectedWidgets"
					(onWidgetSelected)="widgetSelected($event)">
				</rl-select-widget>
			</div>
			<div class="col">
				<h5>Selected Reports</h5>
				<div>
					<rl-chip *ngFor="let selected of selectedWidgets"
						(onRemove)="removeReportWidget(selected.widget.params.reportId)">
						{{selected.name}}</rl-chip>
					<p *ngIf="selectedWidgets.length == 0" class="text-muted">No Reports Selected</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="cancel()">Cancel</button>
	<button type="button" class="btn btn-info" (click)="addWidgets()" [disabled]="selectedWidgets.length === 0">Add To
		Dashboard</button>
</div>