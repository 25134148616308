import { Component } from "@angular/core";
import { NotificationsComponent } from "../notifications/notifications.component";

@Component({
    selector: "rl-notifications-widget",
    templateUrl: "./notifications-widget.component.html",
    styleUrls: ["./notifications-widget.component.scss"],
    imports: [NotificationsComponent]
})
export class NotificationsWidgetComponent {

}
