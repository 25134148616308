<div class="row">
	<div class="col-md-6 col-xl-4">
		<form [formGroup]="inputListForm">
			<select class="form-select" formControlName="inputTypeControl" data-cy="IdListInputDropdown">
				<option [value]="inputType.value" *ngFor="let inputType of inputTypeList"
					[attr.data-cy]="inputType.text">
					{{inputType.text}}
				</option>
			</select>
			<textarea class="form-control mt-3" style="height:10rem;" formControlName="rawInputControl"
				placeholder="Input one item per line, then click Validate">
			</textarea>
			<div class="mt-3 text-end">
				<button class="btn btn-info btn-sm" (click)="validate()" data-cy="ValidateButton">
					Validate
				</button>
			</div>
		</form>
	</div>
	<div class="col-md-6 col-xl-8">
		<rl-input-list-results [results]="results"></rl-input-list-results>
	</div>
</div>