import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { isEmpty } from "lodash";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { IAccountingTablesParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { CharTypeId, Tables } from "rl-common/consts";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { DropdownMenuComponent } from "../../../../../common/components/dropdown/dropdown-menu/dropdown-menu.component";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";


@Component({
	selector: "rl-build-accounting-tables-element",
	templateUrl: "./build-accounting-tables-element.component.html",
	styleUrls: ["./build-accounting-tables-element.component.scss"],
	imports: [NgIf, FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, DropdownMenuComponent, ReactiveFormsModule, FormsModule]
})
export class BuildAccountingTablesElementComponent implements OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<IAccountingTablesParams>;

	@Output()
	paramsChange = new EventEmitter<IAccountingTablesParams>();

	params: IAccountingTablesParams;

	templateOptions: DropdownOptions<ICharacteristicTemplate>;
	selectedTemplates: ICharacteristicTemplate[] = [];


	constructor(private readonly _oneConfig: OneConfigService) { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section) {
			const params = this.section.params ?? { templateIds: [] };
			this.params = params;
			this.section.params = params;
			this.buildTemplateOptions(this.section.params.templateIds);
		}
	}

	private buildTemplateOptions(templateIds: number[] = null) {
		const childTemplateIds = this._oneConfig.getChildAssocTemplateIds(
			this.templateMetaData.charTypeID,
			this.templateMetaData.templateID,
			CharTypeId.Usage
		);

		const templates = this._oneConfig.getTemplates(CharTypeId.Usage)
			.filter(x => Tables.financialSystemIndicators.includes(x.systemIndicator))
			.filter(x => childTemplateIds.includes(x.templateID));

		this.templateOptions = {
			items: templates,
			rowKeyFn: (tmd) => tmd.templateID,
			rowLabelFn: (tmd) => tmd.templateName,
			selectedLabelFn: (count) => {
				if (count === 0) {
					return `All Templates`
				}
				return `Select Templates (${count})`;
			}
		};

		if (templateIds) {
			this.selectedTemplates = templates.filter(x => templateIds.includes(x.templateID));
		}
	}

	updateTemplates() {
		const templateIds = this.selectedTemplates.map(x => x.templateID);
		if (isEmpty(templateIds)) {
			this.params.templateIds = undefined;
		} else {
			this.params.templateIds = templateIds;
		}
		this.paramsChange.emit(this.params);
	}

}
