<div class="modal-header">
	<h2 class="modal-title">Find {{ fieldDetail._singularLabel }}</h2>
	<button class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<rl-find-alliant-entities [fieldDetail]="fieldDetail" [metadata]="metadata" [selectedValues]="selected" (onSelect)="select($event)">
	</rl-find-alliant-entities>
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="close()">Cancel</button>
	<button class="btn btn-info ms-3" (click)="done()" >Done</button>
</div>