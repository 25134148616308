import { KeyValuePipe, NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { chain, Dictionary } from "lodash";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { CharTypeId } from "../../../../../common/rl-common.consts";
import { OneConfigService } from "../../../../../common/services/one-config/one-config.service";

interface ISelectedRightsTemplateForm {
	templateId: FormControl<number>;
}

@Component({
    selector: "rl-select-rights-template",
    templateUrl: "./select-rights-template.component.html",
    styleUrl: "./select-rights-template.component.scss",
    imports: [NgFor, ReactiveFormsModule, KeyValuePipe]
})
export class SelectRightsTemplateComponent implements OnInit {

	templates: ICharacteristicTemplate[];
	groupedTemplates: Dictionary<ICharacteristicTemplate[]> = {};
	selectedTemplateForm: FormGroup<ISelectedRightsTemplateForm>;

	@Output()
	templateSelected: EventEmitter<ICharacteristicTemplate> = new EventEmitter<ICharacteristicTemplate>();

	@Output()
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	onPrevious: EventEmitter<void> = new EventEmitter<void>();

	@Input()
	dealTemplateId: number;

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly _formBuilder: FormBuilder) {

	}

	ngOnInit(): void {
		const templateIds = this._oneConfigService.getChildAssocTemplateIds(CharTypeId.Transaction, this.dealTemplateId, CharTypeId.Right);

		this.templates = this._oneConfigService.getTemplates(CharTypeId.Right).filter(x => templateIds.indexOf(x.templateID) > -1);
		const rightsetTemplateGroups = chain(this.templates.map(t => [t.templateGroupID, t.templateGroup] as [number, string]))
			.sortBy(t => t[0])
			.uniqBy(t => t[0])
			.value();

		rightsetTemplateGroups.forEach((g) => {
			this.groupedTemplates[g[1]] = this.templates.filter(x => x.templateGroupID === g[0]);
		});

		this.selectedTemplateForm = this._formBuilder.group<ISelectedRightsTemplateForm>({
			templateId: new FormControl<number>(null)
		});
	}

	apply() {
		const templateId = this.selectedTemplateForm.controls.templateId.value;

		this.templateSelected.next(this.templates.find(x => x.templateID === templateId));
	}

	cancel() {
		this.onCancel.next();
	}

	previous() {
		this.onPrevious.next();
	}
}
