import { transition, trigger, useAnimation } from "@angular/animations";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { ConstUtils } from "rl-common/consts";
import { IWfProcesses } from "rl-common/services/company/company.models";
import { IWFProcessConfig } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { DropdownSingleComponent } from "../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { TextAreaComponent } from "../../../../../common/components/text/text-area/text-area.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";
import { WorkflowProcessesService } from "../workflow-processes.service";

interface IWorkflowProcessForm {
	processId: FormControl<number>;
	charType: FormControl<KeyValueOption>;
	processName: FormControl<string>;
	processDescription: FormControl<string>;
}

interface KeyValueOption {
	key: number;
	value: string;
}


@Component({
	selector: "rl-workflow-edit",
	styleUrls: ["./workflow-edit.component.scss"],
	templateUrl: "./workflow-edit.component.html",
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, ReactiveFormsModule, FormsModule, DropdownSingleComponent, TextInputComponent, TextAreaComponent]
})
export class WorkflowEditComponent implements OnInit, OnDestroy {
	_subs: Subscription[] = [];
	form: FormGroup<IWorkflowProcessForm>;
	charTypeList: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};
	setProcessName: string;
	title: string;
	formLoaded = false;
	isSaving = false;

	@Input()
	processId: number;

	@Input()
	wfProcessModel: IWFProcessConfig;

	@Output()
	onComplete = new EventEmitter<boolean>();

	get isCreate() {
		return this.processId === 0;
	}

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService,
		private readonly _workflowProcessesService: WorkflowProcessesService) {
	}

	ngOnInit(): void {
		this.getCharTypeList();

		if (!this.isCreate) {
			this.processId = this.wfProcessModel.processID;
			this.setProcessName = this.wfProcessModel.processName;
			this.buildForm(this.wfProcessModel);
		} else {
			this.buildForm();
		}

		this.setTitle();
	}

	setTitle() {
		this.title = this.isCreate ? "Create New Workflow Process" : `Edit Workflow Process - ${this.setProcessName}`;
	}

	buildForm(model: IWFProcessConfig = null) {
		if (model) {
			this.form = this._fb.group<IWorkflowProcessForm>({
				processId: new FormControl(model.processID),
				charType: new FormControl({ value: { key: model.charTypeID, value: null }, disabled: true }),
				processName: new FormControl(model.processName),
				processDescription: new FormControl(model.processDescription)
			}, { validators: [this.requiredFieldsValidator] });
		} else {
			this.form = this._fb.group<IWorkflowProcessForm>({
				processId: new FormControl(0),
				charType: new FormControl({ key: null, value: null }),
				processName: new FormControl(null),
				processDescription: new FormControl("", {})
			}, { validators: [this.requiredFieldsValidator] });
		}

		this.formLoaded = true;
	}

	getCharTypeList() {
		for (const ct of ConstUtils.getCharTypeIdValues()) {
			if (ct) {
				this.charTypeList.items.push({ key: ct, value: CharTypeIdUtil.toDisplayName(ct) });
			}
		}
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._progressService.startProgress();
			let success = true;
			try {
				const model: IWfProcesses = {
					processID: this.form.controls.processId.value,
					charTypeID: this.form.controls.charType.value.key,
					processName: this.form.controls.processName.value,
					processDescription: this.form.controls.processDescription.value,
					sequenceNumber: this.wfProcessModel ? this.wfProcessModel.sequenceNumber : 0
				};

				await this._entityConfigService.saveWFProcess(model).toPromise();
			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				this.isSaving = false;
				this.buildForm();

				if (success) {
					this.onComplete.next(true);
					this._workflowProcessesService.wfProcessOnComplete$.next(true);
					this._growlerService.success().growl("Your changes were saved.");
				} else {
					this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
				}
			}
		}
	}

	close() {
		this.onComplete.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	private requiredFieldsValidator: ValidatorFn = (form: FormGroup<IWorkflowProcessForm>): ValidationErrors | null => {
		const errors: ValidationErrors = {};

		if (!form.controls.processName.value) {
			const requiredResult = Validators.required(form.controls.processName);
			if (requiredResult !== null) {
				errors["processNameRequired"] = true;
			}
		}

		if (!form.controls.charType.value.key) {
			const requiredResult = Validators.required(form.controls.charType);
			if (requiredResult !== null) {
				errors["charTypeIdRequired"] = true;
			}
		}

		return errors;
	};
}
