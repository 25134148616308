import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cloneDeep, isEqual } from "lodash";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { IAssociationGridParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { DropdownSingleComponent } from "../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";

export enum AssociationGridType {
	AssociatedContacts,
	Projects,
	Catalog,
	Deals,
	Inventory,
	Jobs,
	Accounting,
	Files,
	ParentProjects,
	ParentCatalogItems,
	ParentDeals,
	ParentInventory,
	ParentJobs,
	ParentAccounting
}

const availableAssociationTypes: { [key in CharTypeId]?: AssociationGridType[] } = {
	[CharTypeId.User]: [
		AssociationGridType.ParentProjects,
		AssociationGridType.ParentCatalogItems,
		AssociationGridType.ParentDeals,
		AssociationGridType.ParentAccounting,
		AssociationGridType.ParentInventory,
		AssociationGridType.ParentJobs,
		AssociationGridType.Files,
		AssociationGridType.AssociatedContacts
	],
	[CharTypeId.Project]: [
		AssociationGridType.Catalog,
		AssociationGridType.ParentDeals,
		AssociationGridType.ParentAccounting,
		AssociationGridType.Inventory,
		AssociationGridType.Jobs,
		AssociationGridType.Files,
		AssociationGridType.Projects
	],
	[CharTypeId.Property]: [
		AssociationGridType.ParentProjects,
		AssociationGridType.ParentDeals,
		AssociationGridType.ParentAccounting,
		AssociationGridType.Inventory,
		AssociationGridType.ParentJobs,
		AssociationGridType.Files,
		AssociationGridType.Catalog
	],
	[CharTypeId.Transaction]: [
		AssociationGridType.Projects,
		AssociationGridType.Catalog,
		AssociationGridType.Inventory,
		AssociationGridType.Jobs,
		AssociationGridType.Files,
		AssociationGridType.Deals,
		AssociationGridType.Accounting,
	],
	[CharTypeId.Inventory]: [
		AssociationGridType.ParentProjects,
		AssociationGridType.ParentCatalogItems,
		AssociationGridType.ParentDeals,
		AssociationGridType.ParentAccounting,
		AssociationGridType.ParentJobs,
		AssociationGridType.Files,
		AssociationGridType.Inventory
	],
	[CharTypeId.Job]: [
		AssociationGridType.ParentProjects,
		AssociationGridType.Catalog,
		AssociationGridType.ParentDeals,
		AssociationGridType.Accounting,
		AssociationGridType.Inventory,
		AssociationGridType.Files,
		AssociationGridType.ParentJobs
	],
	[CharTypeId.Invoice]: [
		AssociationGridType.Projects,
		AssociationGridType.Catalog,
		AssociationGridType.ParentDeals,
		AssociationGridType.Inventory,
		AssociationGridType.Files,
		AssociationGridType.Accounting
	],
};

export interface IAssocOption {
	type: AssociationGridType;
	label: string;
	params: IAssociationGridParams;
}
@Component({
	selector: "rl-build-association-grid-element",
	templateUrl: "./build-association-grid-element.component.html",
	styleUrls: ["./build-association-grid-element.component.scss"],
	imports: [FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, DropdownSingleComponent, ReactiveFormsModule, FormsModule]
})
export class BuildAssociationGridElementComponent implements OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<IAssociationGridParams>;

	@Output()
	paramsChange = new EventEmitter<IAssociationGridParams>();

	options: DropdownOptions<IAssocOption> = {
		items: [],
		rowKeyFn: (type: IAssocOption) => type.type,
		rowLabelFn: (type: IAssocOption) => type.label
	};
	params: IAssociationGridParams;

	defaultType: IAssocOption = { type: null, label: 'Select', params: null };
	selectedType: IAssocOption = this.defaultType;

	constructor(private readonly _oneConfig: OneConfigService) { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.templateMetaData) {
			this.buildOptions();
		}
		if (changes.section) {
			const found = this.options?.items?.find(x => isEqual(x.params, this.section.params));
			this.selectedType = found ?? this.defaultType;
		}
	}

	updateParams() {
		const option = this.options.items.find(x => x.type === this.selectedType.type);
		this.params = cloneDeep(option.params);
		this.paramsChange.emit(this.params);
	}

	buildOptions() {
		const charTypeId = this.templateMetaData.charTypeID;
		const types = availableAssociationTypes[charTypeId];
		this.options.items = types.map((assocType) => this.buildOption(assocType, charTypeId));
	}

	buildOption(assocType: AssociationGridType, charTypeId: CharTypeId): IAssocOption {
		let title: string;
		let params: IAssociationGridParams;
		switch (assocType) {
			case AssociationGridType.AssociatedContacts:
				title = `Contacts`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.User };
				break;
			case AssociationGridType.Projects:
				title = `Projects`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Project };
				break;
			case AssociationGridType.Catalog:
				title = `Catalogs`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Property };
				break;
			case AssociationGridType.Deals:
				title = `Deals`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Transaction };
				break;
			case AssociationGridType.Inventory:
				title = `Inventory`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Inventory };
				break;
			case AssociationGridType.Jobs:
				title = `Jobs`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Job };
				break;
			case AssociationGridType.Accounting:
				title = `Accounting`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Invoice };
				break;
			case AssociationGridType.Files:
				title = `Files`;
				params = { parentCharTypeId: charTypeId, childCharTypeId: CharTypeId.Document };
				break;
			case AssociationGridType.ParentProjects:
				title = `Projects`;
				params = { parentCharTypeId: CharTypeId.Project, childCharTypeId: charTypeId };
				break;
			case AssociationGridType.ParentCatalogItems:
				title = `Catalogs`;
				params = { parentCharTypeId: CharTypeId.Property, childCharTypeId: charTypeId };
				break;
			case AssociationGridType.ParentDeals:
				title = `Deals`;
				params = { parentCharTypeId: CharTypeId.Transaction, childCharTypeId: charTypeId };
				break;
			case AssociationGridType.ParentInventory:
				title = `Inventory`;
				params = { parentCharTypeId: CharTypeId.Inventory, childCharTypeId: charTypeId };
				break;
			case AssociationGridType.ParentJobs:
				title = `Jobs`;
				params = { parentCharTypeId: CharTypeId.Job, childCharTypeId: charTypeId };
				break;
			case AssociationGridType.ParentAccounting:
				title = `Accounting`;
				params = { parentCharTypeId: CharTypeId.Invoice, childCharTypeId: charTypeId };
				break;

		}

		const isIntraChar = params.childCharTypeId === params.parentCharTypeId;
		if (isIntraChar) {
			title = `Related ${title}`;
		}

		return {
			type: assocType,
			label: title,
			params: params
		};
	}

}
