<div class="modal-header">
	<h2 class="modal-title">Create New Royalty Set</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()"
		data-cy="CloseCreateRoyaltyButton">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="rl-wizard">
	<aw-wizard [awNavigationMode] navigateBackward="deny" navigateForward="deny" navBarLayout="small">
		<aw-wizard-step [stepTitle]="'Create Royalty'">
			<div class="modal-body" data-cy="CreateRoyaltyModalBody">
				<rl-char-data-table *ngIf="charDataLoaded;else charDataLoading" #table [charData]="charData"
					[template]="templateMetaData" [editMode]="editMode"
					(tableCharDataChange)="charData=$event.charData;charDataIsValid=$event.isValid">
				</rl-char-data-table>
				<div *ngIf="charDataIsValid && !hasIncomeGroups" class="alert alert-warning">
					You must select one or more income groups in order to continue
				</div>
			</div>
			<div class="modal-footer" data-cy="CreateRoyaltyModalFooter">
				<button class="btn btn-light" (click)="cancel()" data-cy="CancelCreateRoyaltyButton">Cancel</button>
				<button class="btn btn-info ms-3" type="button" awNextStep
					[disabled]="!charDataIsValid || !hasIncomeGroups" data-cy="NextCreateRoyaltyButton">
					Next <i class="far fa-chevron-right ms-2"></i>
				</button>
			</div>
		</aw-wizard-step>
		<aw-wizard-step [stepTitle]="'Select Catalog Items'">
			<div class="modal-body" data-cy="SelectCatalogModalBody">
				<div class="panel__title--sub">
					<h4>Select Associated Catalog Items</h4>
				</div>
				<rl-entity-relationship *ngIf="charDataLoaded" [charTypeId]="catalogCharType"
					[validTemplates]="validTemplates"
					(onSelectedChange)="setSelectedCatalogItems($event, catalogCharType)">
				</rl-entity-relationship>
			</div>
			<div class="modal-footer" data-cy="SelectCatalogModalFooter">
				<button class="btn btn-info" awPreviousStep data-cy="PreviousSelectCatalogButton">
					<i class="far fa-chevron-left me-2"></i> Previous
				</button>
				<button class="btn btn-light" (click)="cancel()" data-cy="CancelSelectCatalogButton">Cancel</button>
				<button class="btn btn-success ms-3" (click)="saveRoyalty()" [disabled]="!canSave"
					data-cy="SaveSelectCatalogButton">
					<i class="far fa-spinner fa-spin" *ngIf="isApplying"></i> Save
				</button>
				<button class="btn btn-info ms-3" type="button" awNextStep
					[disabled]="selectedCatalogItems.length == 0 " data-cy="NextSelectCatalogButton">
					Next <i class="far fa-chevron-right ms-2"></i>
				</button>
			</div>
		</aw-wizard-step>
		<aw-wizard-step [stepTitle]="'Add Royalty Splits (Optional)'" (stepEnter)="setupRoyaltySplits()">
			<div class="modal-body" data-cy="AddSplitsModalBody">
				<rl-edit-royalty-split *ngIf=" tempCharDataCollection" editMode="true"
					[royaltyCharacteristics]="tempCharDataCollection" [royaltyRelRecordId]="royaltyRelRecId"
					[royaltySplits]="royaltySplits" (splitsChanged)="setSplits($event)">
				</rl-edit-royalty-split>
			</div>
			<div class="modal-footer" data-cy="AddSplitsModalFooter">
				<button class="btn btn-info" [disabled]="isApplying" awPreviousStep data-cy="PreviousAddSplitsButton">
					<i class="far fa-chevron-left me-2"></i> Previous
				</button>
				<button class="btn btn-light ms-3" (click)="cancel()" [disabled]="isApplying"
					data-cy="CancelAddSplitsButton">Cancel</button>
				<button class="btn btn-success ms-3" (click)="saveRoyalty()" [disabled]="!canSave"
					data-cy="SaveAddSplitsButton">
					<i class="far fa-spinner fa-spin" *ngIf="isApplying"></i> Save
				</button>
			</div>
		</aw-wizard-step>
	</aw-wizard>
</div>

<ng-template #charDataLoading>
	<rl-loader></rl-loader>
</ng-template>