<div class="modal-header">
	<h2 class="modal-title">Set Actions & Statuses Allowed by Role : <b>{{role.roleName}}</b></h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="d-inline-flex mb-3">
		<h4 class="mb-0 text-nowrap">Workflow Process:</h4>
		<select id="processSelector" class="form-select" style="font-size: 16px;" [formControl]="chooseProcess"
			(change)="changeProcess()">
			<option *ngFor="let p of processes" [ngValue]="p.processID" [attr.data-cy]="p.processID">
				{{p.processName}}
			</option>
		</select>
	</div>
	<div class="card">
		<div class="card-header p-0">
			<div class="d-flex justify-content-between">
				<button class="btn btn-link" (click)="isExpanded[0] = !isExpanded[0]">
					<span class="text-larger">Actions</span>
					({{wfActionDataSource.dataSelectStrategy.selectedState.selectedIds.size }})
				</button>
				<button class="btn btn-link" (click)="isExpanded[0] = !isExpanded[0]"
					[title]="isExpanded[0] ? 'Collapse' : 'Expand'">
					<i class="far fa-chevron-down"
						[ngClass]="{'fa-chevron-down' : !isExpanded[0], 'fa-chevron-up':isExpanded[0]}"></i>
				</button>
			</div>
		</div>
		<div class="card-body" *ngIf="(wfActionDataSource.rowCount$ | async) > 0" [hidden]="!isExpanded[0]">
			<p>On a Record, this Role is allowed to take the Workflow Actions selected below.</p>
			<rl-grid [dataSource]="wfActionDataSource" [options]="wfActionGridOptions">
			</rl-grid>
		</div>
		<div class="card-header p-0">
			<div class="d-flex justify-content-between">
				<button class="btn btn-link" (click)="isExpanded[1] = !isExpanded[1]">
					<span class="text-larger">Statuses</span>
					({{wfStepDataSource.dataSelectStrategy.selectedState.selectedIds.size }})
				</button>
				<button class="btn btn-link" (click)="isExpanded[1] = !isExpanded[1]"
					[title]="isExpanded[1] ? 'Collapse' : 'Expand'">
					<i class="far fa-chevron-down"
						[ngClass]="{'fa-chevron-down' : !isExpanded[1], 'fa-chevron-up':isExpanded[1]}"></i>
				</button>
			</div>
		</div>
		<div class="card-body" *ngIf="(wfStepDataSource.rowCount$ | async) > 0" [hidden]="!isExpanded[1]">
			<p>In the Status Update tool, this Role is allowed to set Records to the Workflow Statuses selected below.
				Status selections also affect SSRS Report filtering for this Role.</p>
			<rl-grid [dataSource]="wfStepDataSource" [options]="wfStepGridOptions">
			</rl-grid>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success ms-3" (click)="save()">Save</button>
</div>