<div>
	<table class="table rl-table table-hover" cdkDropList (cdkDropListDropped)="drop($event)">
		<thead>
			<tr>
				<th class="ps-4 text-nowrap">Contact Rule Type</th>
				<th class="text-nowrap">Trigger Value</th>
				<th class="text-nowrap">Picklist Value</th>
				<th>Account</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr class="no-items-message">
				<td colspan="5" class="border-0">
					<div class="d-flex align-items-center justify-content-center pt-3">
						<i class="far fa-th-list"></i>
						<h5 class="mb-0 ms-3">No Rules found.</h5>
					</div>
				</td>
			</tr>
			<tr *ngFor="let rule of rules" cdkDrag cdkDragLockAxis="y">
				<rl-account-group-rule class="table-line" [accountGroup]="accountGroup" [rule]="rule"
					[picklistItems]="picklistItems" [templateItems]="templateItems"
					[templateGroupItems]="templateGroupItems" [defaultAccounts]="defaultAccounts"
					(edit)="editRule(rule)" (delete)="deleteRule(rule, deleteRulePrompt)"></rl-account-group-rule>
			</tr>
		</tbody>
	</table>
</div>

<ng-template #deleteRulePrompt let-prompt="prompt">
	<div>
		<p>
			Are you sure you want to delete this rule?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="cancelDeleteTemplate">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)"
			data-cy="confirmDeleteTemplate">
			Delete Rule
		</button>
	</div>
</ng-template>