@if(jobId) {
<rl-job-progress [jobId]="jobId" (onComplete)="createComplete()" [hideModalHeading]="true"></rl-job-progress>
} @else {
<rl-bulk-grid [config]="config" [gridView]="gridView" [template]="templateId" [initialData]="initialData"
	(onDone)="done($event)" (onComplete)="cancel($event)">
</rl-bulk-grid>
<div class="form-check form-control-static position-relative d-inline-block">
	<label class="form-label" for="goToDeal">
		<input type="checkbox" class="form-check-input" [(ngModel)]="goToDeal" id="goToDeal" />
		Go to associated Deal on Save
	</label>
</div>
}