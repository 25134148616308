<div class="modal-header">
	<h2 class="modal-title">Generate Rights from Avails</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="CloseModal">
		<i class="far fa-times"></i>
	</button>
</div>
<aw-wizard class="rl-wizard mt-3" navBarLayout="small" [disableNavigationBar]="true">
	<aw-wizard-step stepTitle="Associate To Deal">
		<rl-existing-or-new-deal (onApply)="apply($event)" (onCreate)="create($event)"
			(onCancel)="cancel()"></rl-existing-or-new-deal>
	</aw-wizard-step>
	<aw-wizard-step stepTitle="Select Rights Template">
		<rl-select-rights-template *ngIf="deal" (onCancel)="cancel()" (onPrevious)="cancelRightsTemplate()"
			(templateSelected)="selectRightsTemplate($event)"
			[dealTemplateId]="deal.templateId"></rl-select-rights-template>
	</aw-wizard-step>
</aw-wizard>