import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { TemplateLandingService } from "config/components/templates/templates-landing/templates-landing.service";
import { Dictionary, cloneDeep, isNull } from "lodash";
import { DateAlerts } from "rl-common/components/date-alerts/date-alerts-common.consts";
import { IDateOptionsChange } from "rl-common/components/date-edit/date-options-modal/date-options-modal.models";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { CharDataType, ConstUtils, SystemIndicators, VisibilityIndicator } from "rl-common/consts";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ICharacteristicValueGroup } from "rl-common/models/i-characteristic-value-group";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { ITreeListChangeEvent } from "rl-common/models/i-tree-list-change-event";
import { AlertsService } from "rl-common/services/alerts/alerts.service";
import { AwsService } from "rl-common/services/aws/aws.service";
import { BulkGridService } from "rl-common/services/bulk-grid/bulk-grid.service";
import { ISecurityPolicy, ISecurityPolicyPermission, PolicyType } from "rl-common/services/company/company.models";
import { IAddAndAssociateCharacteristic, IConditionalField, IConditionalFieldType, IPickListSource, IPickListSourceList, ITemplateFieldPolicySummaryModel } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { IWorkflowConfigProcessNotification } from "rl-common/services/workflow-config/workflow-config.models";
import { WorkflowConfigService } from "rl-common/services/workflow-config/workflow-config.service";
import { CharDataTypeUtil } from "rl-common/utils/char-data-type.util";
import { Observable, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, take, tap } from "rxjs/operators";
import { NIL as EMPTY_UUID } from "uuid";
import { TreeListEditControlComponent } from "../../../../../../common/components/char-data/controls/tree-list-edit-control.component";
import { CheckboxInputComponent } from "../../../../../../common/components/checkbox-input/checkbox-input.component";
import { AlertTitleComponent } from "../../../../../../common/components/date-alerts/alert-title/alert-title.component";
import { DropdownSingleComponent } from "../../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { NumberInputComponent } from "../../../../../../common/components/number-input/number-input.component";
import { LoaderComponent } from "../../../../../../common/components/panel/loader/loader.component";
import { TextInputComponent } from "../../../../../../common/components/text/text-input/text-input.component";
import { FeatureEnabledDirective } from "../../../../../../common/directives/feature-enabled.directive";
import { NewTabInModalDirective } from "../../../../../../common/directives/new-tab-in-modal.directive";
import { ICreateTemplateFormData, KeyValueOption } from "./template-field-policy-editor/template-field-policy-editor.models";

@Component({
	selector: "rl-create-template-field-wizard",
	templateUrl: "./create-template-field-wizard.component.html",
	styleUrls: ["./create-template-field-wizard.component.scss"],
	imports: [ArchwizardModule, NgIf, TextInputComponent, ReactiveFormsModule, DropdownSingleComponent, CheckboxInputComponent, NumberInputComponent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem, NgFor, FormsModule, TreeListEditControlComponent, NewTabInModalDirective, RouterLink, AlertTitleComponent, LoaderComponent, AsyncPipe]
})
export class CreateTemplateFieldWizardComponent implements OnInit, OnDestroy {
	private _subs: Subscription[] = [];
	picklistDataTypeIds = [CharDataType.Money, CharDataType.Alphanumeric, CharDataType.Checkbox];
	pickListSources: DropdownOptions<IPickListSource> = {
		items: [],
		rowKeyFn: (type: IPickListSource) => type.pickListSourceID,
		rowLabelFn: (type: IPickListSource) => type.pickListSourceLabel
	};
	pickListSourceListFiltered: DropdownOptions<IPickListSourceList> = {
		items: [],
		rowKeyFn: (type: IPickListSourceList) => type.charValueSourceID,
		rowLabelFn: (type: IPickListSourceList) => type.charValueSourceLabel
	};
	emptyUUID = EMPTY_UUID;
	pickListSourceListFilteredDefault: IPickListSourceList = {
		divID: null,
		charValueSourceID: null,
		charValueSetID: null,
		charValueSourceLabel: "Select Picklist",
		charValueSourceDescription: null,
		createdBy: null,
		createdAt: null,
		updatedBy: null,
		updatedAt: null
	};
	pickListSourceListFilteredNoItems: IPickListSourceList = {
		divID: null,
		charValueSourceID: this.emptyUUID,
		charValueSetID: null,
		charValueSourceLabel: "No Picklist Selected",
		charValueSourceDescription: null,
		createdBy: null,
		createdAt: null,
		updatedBy: null,
		updatedAt: null
	};

	pickListSourceListUnfiltered: IPickListSourceList[];
	conditionalFieldTypes: IConditionalFieldType[];
	templateSections: DropdownOptions<ITemplateCharacteristicGroup> = {
		items: [],
		rowKeyFn: (type: ITemplateCharacteristicGroup) => type.groupID,
		rowLabelFn: (type: ITemplateCharacteristicGroup) => type.groupLabel
	};
	templateSectionsUnfiltered: ITemplateCharacteristicGroup[];
	characteristicMetaData: ICharacteristicMetaData[] = [];
	triggerFields: DropdownOptions<ICharacteristicMetaData> = {
		items: [],
		rowKeyFn: (type: ICharacteristicMetaData) => type.characteristicID,
		rowLabelFn: (type: ICharacteristicMetaData) => type.label
	};
	charValueGroups: ICharacteristicValueGroup[];
	lovs: ICharacteristicMetaDataValue[] = [];
	templates: ICharacteristicTemplate[];
	policyType = PolicyType.dataPolicy;
	charValueSetId: string;
	charValueSourceId: string;
	conditionalValue: string;
	conditionalTriggerCharValueSourceId: string;
	characteristicId = 0;
	conditionalTriggerFieldId: ICharacteristicMetaData;
	dataTypeList: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};
	requiredOptionsList: DropdownOptions<KeyValueOption> = {
		items: [
			{ key: 0, value: "No" },
			{ key: 1, value: "Required" },
			{ key: 2, value: "Strongly Suggested" }
		],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};

	selectedValueIds: number[] = [];
	seqNum: number;

	isDisabled = false;
	formLoaded = false;
	isSaving = false;
	lovMetaDataLoaded = false;
	legendDataLoaded = false;
	anyValueSelected = false;
	isDateType = false;
	isAlphaNumType = false;
	showDateAlertButton = false;
	disableMultiple: boolean;
	isEditAlert = false;
	isDateAlertsBlockLoaded = false;
	isConfig = true;
	systemSourceField = false;

	legendSourceFieldName: string;
	legendDescription: string;
	legendDataTypeLabel: string;
	legendPickListSource: string;
	editingAlert: ICharDataExtDataAlert;
	dateAlertEditingLabel: string;

	formGroup: FormGroup<ICreateTemplateFormData>;

	notifications$: Observable<IWorkflowConfigProcessNotification[]>;

	isSuperAdmin: boolean;

	notificationMessagesEnabled: boolean;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	group: ITemplateCharacteristicGroup;

	@Input()
	isCreate: boolean;

	@Input()
	templateFieldModel: ICharacteristicMetaData;

	@Input()
	alerts: ICharDataExtDataAlert[] = [];

	@Input()
	contextPermissions: Dictionary<ISecurityPolicyPermission>;

	@Input()
	charMetaData: ICharacteristicMetaData[] = [];

	@Output()
	policies: ISecurityPolicy[];

	@Output()
	permissions: { [key: string]: ISecurityPolicyPermission[] };

	@Output()
	policiesLoaded: boolean;

	@Output()
	policyModel: ITemplateFieldPolicySummaryModel;

	@Output()
	systemIndicator: number;

	@Output()
	tagLabel: string;

	@Output()
	onCharValueSetIdChanged = new EventEmitter<string>();

	@Output()
	onCharValueSourceIdChanged = new EventEmitter<string>();

	@Output()
	onComplete = new EventEmitter<boolean>();

	@Output()
	onChange = new EventEmitter<IDateOptionsChange>();

	@ViewChild(WizardComponent)
	public wizard: WizardComponent;

	constructor(
		private readonly _sessionService: SessionService,
		private readonly _formBuilder: FormBuilder,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _templateService: TemplateLandingService,
		private readonly _alertService: AlertsService,
		private readonly _progressService: ProgressService,
		private readonly _growlerService: GrowlerService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _injector: Injector,
		@Optional() private readonly _bulkGridService: BulkGridService,
		private readonly _workflowConfigService: WorkflowConfigService,
		private readonly _session: SessionService,
		private readonly _awsService: AwsService
	) { }

	ngOnInit(): void {

		const subSA = this._sessionService.isRlAdmin$.pipe(
			take(1),
			tap(result => {
				this.isSuperAdmin = result;

				if (!this.isCreate) {
					const model = this.templateFieldModel || {} as ICharacteristicMetaData;
					this.charValueSourceId = model.charValueSourceID ?? EMPTY_UUID;
					this.characteristicId = model.characteristicID;
					this.showDateAlertButton = model.dataTypeID === CharDataType.Date;
					this.systemIndicator = model.systemIndicator;
					this.tagLabel = model.tagLabel;
					this.systemSourceField = model.systemIndicator === SystemIndicators.System;
					this.buildForm(model);
				} else {
					this.buildForm();
				}
				this.getDataTypeList();

			})
		).subscribe();

		this._subs.push(subSA);


		const sub = this._workflowConfigService.notifications(this.charTypeId, this.templateId).pipe(
			switchMap(() => this._templateService.fetchCharTemplates(this.charTypeId)),
			tap(templates => {
				// Remove if not necessary for policies component
				this.templates = templates;
				this.policyModel = {
					charTypeId: this.charTypeId,
					templateGroupId: templates.find(t => t.templateID === this.templateId).templateGroupID,
					templateId: this.templateId,
					charGroupId: this.group.groupID,
					charId: this.characteristicId
				}
			}),
			switchMap(() => this._entityConfigService.getTemplateMetaData(this.charTypeId, this.templateId)),
			tap(results => {
				// CharMetaData
				this.characteristicMetaData = results.characteristicMetaDatas;
				// Template Sections (aka Groups a la Classic)
				// Remove the current group from options
				this.templateSectionsUnfiltered = results.groups;
				this.templateSections.items = results.groups.filter(g => g.groupID !== this.group.groupID);
				// Add group option to beginning of list
				this.templateSections.items.splice(0, 0, this.group);
				// Conditional Fields
				this.triggerFields.items = [];
				const triggerFieldCmds = cloneDeep(results.characteristicMetaDatas);
				// Remove the current field
				const currentField = triggerFieldCmds.find(cmd => cmd.characteristicID === this.characteristicId);
				const index = triggerFieldCmds.indexOf(currentField);
				Object.values(triggerFieldCmds).splice(index, 1);
				// Remove all fields that aren't LOVs/don't have charValueSourceIDs
				const onlyLovs = Object.values(triggerFieldCmds).filter(cmd => cmd.dataTypeID === CharDataType.Alphanumeric && !isNull(cmd.charValueSourceID) && cmd.charValueSourceID !== undefined);
				// Sort by label and set
				const sorted = onlyLovs.sort((a, b) => a.label.toLowerCase().trim() > b.label.toLowerCase().trim() ? 1 : -1);
				this.triggerFields.items = sorted;
			}),
			switchMap(() => this._entityConfigService.getConditionalFieldTypes()),
			tap(results => this.conditionalFieldTypes = results),
			switchMap(() => this._entityConfigService.getPickListSources()),
			tap(results => this.pickListSources.items = results),
			switchMap(() => this._entityConfigService.getPickListSourceList()),
			tap(results => {
				this.pickListSourceListUnfiltered = results;

				// Use triggerFields list to get picklist and conditional field data in edit mode
				if (!this.isCreate) {
					this.pickListSourceListFiltered.items = Object.values(this.pickListSourceListUnfiltered).filter(pls => pls.charValueSetID === this.templateFieldModel.charValueSetID);
					this.formGroup.controls.pickListNameId.setValue({
						divID: null,
						charValueSourceID: this.charValueSourceId,
						charValueSetID: null,
						charValueSourceLabel: "",
						charValueSourceDescription: null,
						createdBy: null,
						createdAt: null,
						updatedBy: null,
						updatedAt: null
					});

					// Get conditional trigger value lovs
					const charValueSourceIdTrigger = Object.values(this.triggerFields.items).find(tf => tf.characteristicID === this.templateFieldModel.triggerCharID)?.charValueSourceID;
					if (charValueSourceIdTrigger) {
						this.getLovMetaData(charValueSourceIdTrigger);
					}

					const anyValueString = "-999";
					if (this.templateFieldModel.triggerValue && this.templateFieldModel.triggerValue === anyValueString) {
						this.anyValueSelected = true;
						this.conditionalValue = anyValueString;
						this.formGroup.controls.conditionalTriggerValue.setValue(this.conditionalValue);
					} else if (this.templateFieldModel.triggerValue) {
						this.selectedValueIds = this.templateFieldModel.triggerValue.split("|").map(x => parseInt(x));
						this.updateConditionalFieldValue();
					}
				}
				this.pickListSourceListFiltered.items.splice(0, 0, this.pickListSourceListFilteredNoItems);
			}),
			switchMap(() => this._entityConfigService.getTemplateFieldPolicySummary(this.policyModel)),
			tap((result) => {
				this.policies = result.policies;
				this.permissions = result.permissions;
				this.policiesLoaded = true;
			}),
			tap(() => {
				if (!this.isCreate && this.templateFieldModel) {
					this.getTemplateFieldLegendData(this.templateFieldModel.characteristicID);
				}
			}),
			switchMap(() => this._alertService.getAlerts(this.charTypeId, this.templateId, this.characteristicId, null)),
			tap(results => {
				this.alerts = results;
				this.isDateAlertsBlockLoaded = true;
			})
		).subscribe();

		const sub2 = this.formGroup?.controls?.fieldName?.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(() => {
			const mergeFieldLabel = this.formGroup.controls.fieldName.value.trim()
				// Non-word and non-space characters
				.replace(/[^\w\s]/g, "")
				// Spaces with underscore
				.replace(/\s+/g, "_")
				.toLowerCase();
			this.formGroup.controls.mergeFieldLabel.setValue(mergeFieldLabel);
			this.tagLabel = mergeFieldLabel;
		});

		const sub3 = this.formGroup?.controls?.dataType?.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(result => {
			if (this.picklistDataTypeIds.includes(result.key)) {
				this.formGroup.controls.pickListSource.reset();
				this.formGroup.controls.pickListNameId.reset();
			}

			this.showDateAlertButton = result.key === CharDataType.Date;
			this.disableMultiple = result.key !== CharDataType.Alphanumeric;

			const canBeMultipleControl = this.formGroup.get("canBeMultiple");
			if (canBeMultipleControl) {
				if (this.disableMultiple) {
					canBeMultipleControl.setValue(0);
					canBeMultipleControl.disable();
				} else {
					canBeMultipleControl.enable();
				}
			}
		});

		const sub4 = this.formGroup?.controls?.pickListSource?.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(result => {
			if (result === null || undefined) {
				this.formGroup.controls.pickListNameId.reset();
				this.formGroup.controls.pickListNameId.clearValidators();
			}
			if (!this.formGroup.controls.pickListSource.value) {
				this.charValueSetId = null;
			} else {
				this.charValueSetId = result.pickListSourceID;
				if (this.pickListSourceListUnfiltered) {
					this.pickListSourceListFiltered.items = Object.values(this.pickListSourceListUnfiltered).filter(pls => pls.charValueSetID === result.pickListSourceID);
					this.pickListSourceListFiltered.items.splice(0, 0, this.pickListSourceListFilteredDefault);
					// Set the initial charValueSourceId to the first option after charValueSetId is set
					this.charValueSourceId = this.pickListSourceListFiltered[0]?.charValueSourceID;
					this.emitCharValueSetId(this.charValueSetId);
					this.emitCharValueSourceId(this.charValueSourceId);
				}
			}
		});

		const sub5 = this.formGroup?.controls?.conditionalTriggerFieldId?.valueChanges.pipe(
			distinctUntilChanged(),
			tap(() => {
				this.lovMetaDataLoaded = false;
				this.anyValueSelected = false;
				this.selectedValueIds = [];
			})
		).subscribe(result => {
			this.conditionalTriggerFieldId = {
				charTypeID: null,
				characteristicID: result?.characteristicID,
				label: null,
				tagLabel: null,
				maxLength: null,
				dataTypeID: null,
				systemIndicator: null,
				multipleIndicator: null,
				extractable: null,
				charValueSetID: null,
				groupID: null,
				sequenceNumber: null,
				requiredIndicator: null,
				charValueSourceID: null,
				acl: null,
				triggerValue: null
			};
			const conditionalTriggerCharValueSourceId = this.triggerFields.items.find(tf => tf.characteristicID === result?.characteristicID)?.charValueSourceID;

			if (conditionalTriggerCharValueSourceId) {
				this.getLovMetaData(conditionalTriggerCharValueSourceId);
			}
		});

		const sub6 = this.formGroup?.controls?.systemIndicator.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(result => {
			this.systemIndicator = result;
			this.systemSourceField = (result === SystemIndicators.System);
		})

		let lastValue: IPickListSourceList = this.pickListSourceListFilteredDefault;
		const sub7 = this.formGroup?.controls?.pickListNameId?.valueChanges.pipe(
			debounceTime(100),
			tap(newValue => {
				if (newValue.charValueSourceID !== EMPTY_UUID) {
					lastValue = newValue;
				}
			}),
			distinctUntilChanged(),
			tap(() => {
				if (this.formGroup?.controls?.pickListNameId.value.charValueSourceID === EMPTY_UUID && this.formGroup?.controls?.pickListNameId.dirty) {
					this._modalService.confirm("Are you sure?", "Selecting the \"No Picklist Selected\" option will result in invalid data for existing templates. Are you sure you want to continue?", "Yes", "Cancel")
						.subscribe(confirm => {
							if (!confirm && lastValue !== null) {
								this.formGroup.controls.pickListNameId.setValue(lastValue);
							}
						});
				}
			})
		).subscribe();

		const sub8 = this._awsService.getMessagingDiv(this._sessionService.divId).pipe(
			tap(result => {
				this.notificationMessagesEnabled = result.notificationMessages;
			})
		).subscribe();

		this._subs.push(sub, sub2, sub3, sub4, sub5, sub6, sub7, sub8);
	}

	updateContextPermissions(permissions: Dictionary<ISecurityPolicyPermission>) {
		this.contextPermissions = permissions;
	}

	emitCharValueSetId(charValueSetId: string) {
		this.onCharValueSetIdChanged.emit(charValueSetId);
	}

	emitCharValueSourceId(charValueSourceId: string) {
		this.onCharValueSourceIdChanged.emit(charValueSourceId);
	}

	buildForm(model: ICharacteristicMetaData = null) {
		if (model) {
			this.formGroup = this._formBuilder.group({
				fieldName: new FormControl(model.label),
				mergeFieldLabel: new FormControl(model.tagLabel),
				mergeFieldDescription: new FormControl(model.description),
				dataType: new FormControl({ key: model.dataTypeID, value: null }),
				canBeMultiple: new FormControl({ value: model.multipleIndicator, disabled: this.disableMultiple }),
				extractable: new FormControl(model.extractable),
				maxLength: new FormControl<number>(model.maxLength),
				pickListSource: new FormControl({ divID: null, pickListSourceID: model.charValueSetID, pickListSourceLabel: null, pickListSourceDescription: null }),
				systemIndicator: new FormControl({ value: model.systemIndicator, disabled: !this.isSuperAdmin }),
				reportIndicator: new FormControl(model.reportIndicator),
				templateSection: new FormControl({
					groupID: model.groupID, groupLabel: null, systemIndicator: null, expGroupIndicator: null,
					visibilityIndicator: null, sequenceNumber: null, acl: null
				}),
				pickListNameId: new FormControl({
					divID: null, charValueSourceID: model.charValueSourceID, charValueSetID: null, charValueSourceLabel: "",
					charValueSourceDescription: null, createdBy: null, createdAt: null, updatedBy: null, updatedAt: null
				}),
				required: new FormControl({ key: model.requiredIndicator, value: null }),
				conditionalTriggerFieldId: new FormControl({
					charTypeID: null,
					characteristicID: model.triggerCharID,
					label: null,
					tagLabel: null,
					maxLength: null,
					dataTypeID: null,
					systemIndicator: null,
					multipleIndicator: null,
					extractable: null,
					charValueSetID: null,
					groupID: null,
					sequenceNumber: null,
					requiredIndicator: null,
					charValueSourceID: null,
					acl: null,
					triggerValue: null
				}),
				conditionalTriggerValue: new FormControl(model.triggerValue),
				copyIndicator: new FormControl(model.copyIndicator)
			}, { validators: [this.requiredFieldsValidator, this.patternValidator] });
		} else {
			this.formGroup = this._formBuilder.group({
				fieldName: new FormControl(""),
				mergeFieldLabel: new FormControl(""),
				mergeFieldDescription: new FormControl(""),
				dataType: new FormControl({ key: CharDataType.Alphanumeric, value: null }),
				canBeMultiple: new FormControl({ value: 0, disabled: this.disableMultiple }),
				extractable: new FormControl(false),
				maxLength: new FormControl(10000),
				pickListSource: new FormControl({ divID: null, pickListSourceID: null, pickListSourceLabel: null, pickListSourceDescription: null }),
				systemIndicator: new FormControl({ value: 0, disabled: !this.isSuperAdmin }),
				reportIndicator: new FormControl(1),
				templateSection: new FormControl({ groupID: this.group.groupID, groupLabel: null, systemIndicator: null, expGroupIndicator: null, visibilityIndicator: null, sequenceNumber: null, acl: null }),
				pickListNameId: new FormControl(this.pickListSourceListFilteredDefault),
				required: new FormControl({ key: 0, value: null }),
				conditionalTriggerFieldId: new FormControl({
					charTypeID: null,
					characteristicID: null,
					label: null,
					tagLabel: null,
					maxLength: null,
					dataTypeID: null,
					systemIndicator: null,
					multipleIndicator: null,
					extractable: null,
					charValueSetID: null,
					groupID: null,
					sequenceNumber: null,
					requiredIndicator: null,
					charValueSourceID: null,
					acl: null,
					triggerValue: null
				}),
				conditionalTriggerValue: new FormControl(""),
				copyIndicator: new FormControl(1)
			}, { validators: [this.requiredFieldsValidator, this.patternValidator] });
		}

		this.formLoaded = true;

	}

	private getLovMetaData(charValueSourceId: string) {
		const sub = this._entityConfigService.getLovMetaData(this.charTypeId, this.templateId, charValueSourceId).pipe(
			tap(results => {
				if (results.listOfValues) {
					this.charValueGroups = results.charValueGroups;
					this.lovs = results.listOfValues;
					this.lovMetaDataLoaded = true;
				}
			})
		).subscribe();

		this._subs.push(sub);
	}

	setSelected(event: ITreeListChangeEvent) {
		this.selectedValueIds = event.branchValueIDs;
		if (this.selectedValueIds.length) {
			this.anyValueSelected = false;
		}
		this.updateConditionalFieldValue();
	}

	private updateConditionalFieldValue() {
		const branchValueIds = this.selectedValueIds.join("|");
		this.conditionalValue = this.anyValueSelected ? "-999" : branchValueIds;
		this.formGroup.controls.conditionalTriggerValue.setValue(this.conditionalValue);
	}

	getDataTypeList() {
		for (const dt of ConstUtils.getCharDataTypeValues()) {
			if (dt) {
				this.dataTypeList.items.push({ key: dt, value: CharDataTypeUtil.toDisplayName(dt) });
			}
		}
	}

	emitAnyValueChange() {
		if (this.anyValueSelected) {
			this.selectedValueIds = [];
		}
		this.updateConditionalFieldValue();
	}

	clearConditionalFields() {
		this.formGroup.controls.conditionalTriggerFieldId.reset();
		this.selectedValueIds = [];
		this.anyValueSelected = false;
		this.updateConditionalFieldValue();
	}

	isFirstStepFormValid(form: FormGroup<ICreateTemplateFormData>) {
		const fieldNameRequired = form.hasError("fieldNameRequired");
		const mergeFieldLabelRequired = form.hasError("mergeFieldLabelRequired");
		const dataTypeRequired = form.hasError("dataTypeRequired");
		const systemIndicatorRequired = form.hasError("systemIndicatorRequired");

		if (form && (fieldNameRequired || mergeFieldLabelRequired || dataTypeRequired || systemIndicatorRequired)) {
			return true;
		}

		return false;
	}

	getTemplateFieldLegendData(characteristicId: number) {
		const currentField = this.characteristicMetaData.find(cmd => cmd.characteristicID === characteristicId);
		this.legendSourceFieldName = currentField.label === "" ? "N/A" : currentField.label;
		this.legendDescription = currentField.description === "" ? "N/A" : currentField.description;
		this.legendDataTypeLabel = currentField.dataTypeID ? CharDataTypeUtil.toDisplayName(currentField.dataTypeID) : "N/A";
		this.isAlphaNumType = currentField.dataTypeID === CharDataType.Alphanumeric;
		this.isDateType = currentField.dataTypeID === CharDataType.Date;

		const pickListSource = this.pickListSources.items.filter(x => x.pickListSourceID === currentField.charValueSetID);
		this.legendPickListSource = pickListSource.length && pickListSource[0].pickListSourceLabel ? pickListSource[0].pickListSourceLabel : null;
		this.legendDataLoaded = true;
	}

	addCustomNotification() {
		this.addAlertFromDraft(undefined);
	}

	addAlertFromDraft(draft: IWorkflowConfigProcessNotification) {
		const parentEntityId = "";
		const recCharId = null;
		const cmd = this.characteristicMetaData.find(cmd => cmd.characteristicID === this.characteristicId);
		const isBulkEdit = this._bulkGridService !== null;
		const isEditAlert = false;

		const defaultSubject = DateAlerts.defaultSubject();
		const alert: ICharDataExtDataAlert = {
			entityID: null,
			divisionID: this._session.divId,
			charTypeID: this.charTypeId,
			templateID: this.templateId,
			charID: this.characteristicId,
			recordID: null,
			id: "",
			fromID: null,
			body: "",
			attachment: "",
			sequenceNumber: null,
			createdBy: null,
			createdAt: "",
			updatedBy: null,
			updatedAt: "",
			status: "",
			processID: null,
			messageID: null,
			quantity: 1,
			frequencyID: 1,
			frequencyLabel: "",
			timeframeID: 1,
			timeframeLabel: "",
			selectedPartyIDs: "",
			selectedRoleIDs: "",
			notifyChildrenPartyIds: "",
			subject: draft && draft.subject || defaultSubject,
			bodyHTML: draft && draft.bodyHTML || "",
			toEmail: this._session.userName,
			fromEmail: "",
			alertWhenSetOrUpdated: true,
			createCustomAlert: true,
			createEmailInvite: false,
			emailInviteUID: null,
			emailUpdateSequence: 0,
			templateDateCharId: null,
			templateDateContainsType: null,
			doNotSendIfRecordInactive: false,
			toID: null,
			triggerSNSOrSQS: false,
			addActionBtn: draft && draft.addActionBtn
		};

		this.editingAlert = alert;
		this.dateAlertEditingLabel = draft && draft.label || "Custom";

		const sub = this._modalService.openEditDateAlertsConfigModal(cmd, this.characteristicMetaData, recCharId, this.alerts, this.editingAlert, parentEntityId, isBulkEdit, isEditAlert, this.dateAlertEditingLabel, this.charTypeId, this.templateId, this.characteristicId, this.notificationMessagesEnabled, this._injector)
			.subscribe(event => {
				if (event !== undefined) {
					this.alerts = event;
				}
			});

		this._subs.push(sub);
	}

	editAlert(event: Event, alert: ICharDataExtDataAlert) {
		event.preventDefault();
		const parentEntityId = "";
		const recCharId = null;
		const cmd = this.characteristicMetaData.find(cmd => cmd.characteristicID === this.characteristicId);
		const isEditAlert = true;
		const isBulkEdit = this._bulkGridService !== null;
		this.editingAlert = cloneDeep(alert);

		const sub = this._modalService.openEditDateAlertsConfigModal(cmd, this.characteristicMetaData, recCharId, this.alerts, this.editingAlert, parentEntityId, isBulkEdit, isEditAlert, this.dateAlertEditingLabel, this.charTypeId, this.templateId, this.characteristicId, this.notificationMessagesEnabled, this._injector)
			.subscribe(event => {
				if (event !== undefined) {
					this.alerts = event;
				}
			});

		this._subs.push(sub);
	}

	deleteAlert(alert: ICharDataExtDataAlert) {
		const deleteAlert$: Observable<void> = this._alertService.deleteAlert(alert.id);

		deleteAlert$.pipe(
			switchMap(() => this._alertService.getAlerts(this.charTypeId, this.templateId, this.characteristicId, null)),
			tap(alerts => this.alerts = alerts)
		).subscribe(() => this._growlerService.success().growl("Date Alert deleted."));
	}

	hasError(control: FormControl) {
		return control.invalid && control.touched;
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	async submit($event: Event) {
		let returnValue = -1;
		if (this.isCreate) {
			const currentFieldNames = this.charMetaData.map(x => x.label.toLowerCase());
			const fieldName = this.formGroup.controls.fieldName.value;
			if (currentFieldNames.indexOf(fieldName.toLowerCase()) > -1) {
				this._growlerService.error().growl(`There is already a field with the Field Name ${fieldName}.`);
				return;
			}
		}
		if (this.formGroup.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._progressService.startProgress();
			let success = true;
			try {
				const charMetaData: ICharacteristicMetaData = {
					charTypeID: this.charTypeId,
					characteristicID: this.characteristicId,
					label: this.formGroup.controls.fieldName.value,
					tagLabel: this.formGroup.controls.mergeFieldLabel.value,
					maxLength: this.formGroup.controls.maxLength.value,
					dataTypeID: this.formGroup.controls.dataType.value.key,
					systemIndicator: this.formGroup.controls.systemIndicator.value,
					multipleIndicator: this.formGroup.controls.canBeMultiple.value ? 1 : 0,
					extractable: this.formGroup.controls.extractable.value,
					groupID: this.formGroup.controls.templateSection.value.groupID,
					sequenceNumber: 0,
					requiredIndicator: this.formGroup.controls.required.value.key,
					visibilityIndicator: this.templateFieldModel?.visibilityIndicator ?? VisibilityIndicator.InternalExternal,
					charValueSourceID: this.formGroup.controls.pickListNameId.value.charValueSourceID !== EMPTY_UUID ? this.formGroup.controls.pickListNameId.value.charValueSourceID : null,
					reportIndicator: this.formGroup.controls.reportIndicator.value ? 1 : 0,
					charValueSetID: this.formGroup.controls.pickListSource.value.pickListSourceID,
					// acl is null in Classic, maybe we don't need it?
					acl: null,
					triggerCharID: null,
					triggerTypeID: null,
					triggerValue: null,
					description: this.formGroup.controls.mergeFieldDescription.value,
					// ignore copyIndicator if this is a system source field because system fields are not copied
					copyIndicator: this.systemSourceField ? 1 : +this.formGroup.controls.copyIndicator.value
				};

				const conditionalField: IConditionalField = {
					conditionalFieldID: EMPTY_UUID,
					// XCHR_CND_FLD_TYP_ID uses default hardcoded value for now ("Show Field"), slated for deprecation
					conditionalFieldTypeID: "932b1d41-9578-e811-8129-dbc5c04ff85e",
					divID: 0,
					charTypeID: this.charTypeId,
					charID: this.characteristicId,
					templateID: this.templateId,
					triggerCharID: this.formGroup.controls.conditionalTriggerFieldId.value.characteristicID,
					triggerGroupID: null,
					triggerValue: this.formGroup.controls.conditionalTriggerValue.value
				};

				const model: IAddAndAssociateCharacteristic = {
					charTypeID: this.charTypeId,
					templateID: this.templateId,
					groupID: this.formGroup.controls.templateSection.value.groupID,
					charMetaData,
					permissions: this.contextPermissions,
					conditionalField
				};

				returnValue = await this._entityConfigService.addOrUpdateAndAssociateTemplateField(model).toPromise();
			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				this.isSaving = false;
				this.buildForm();
				if (success) {
					if (returnValue === 0) {
						this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
					} else {
						this.onComplete.next(true);
						this._growlerService.success().growl("Your changes were saved.");
					}
				} else {
					this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
				}
			}
		}
	}

	private requiredFieldsValidator: ValidatorFn = (form: FormGroup<ICreateTemplateFormData>): ValidationErrors | null => {
		const errors: ValidationErrors = {};

		if (!form.controls.fieldName?.value) {
			const requiredResult = Validators.required(form.controls.fieldName);
			if (requiredResult !== null) {
				errors["fieldNameRequired"] = true;
			}
		}

		if (!form.controls.mergeFieldLabel?.value) {
			const requiredResult = Validators.required(form.controls.mergeFieldLabel);
			if (requiredResult !== null) {
				errors["mergeFieldLabelRequired"] = true;
			}
		}

		const dataTypeControl = form.get("dataType");
		if (dataTypeControl.value.key === null) {
			const requiredResult = Validators.required(form.controls.dataType);
			if (requiredResult !== null && this.isCreate) {
				errors["dataTypeRequired"] = true;
			}
		}

		if (form.controls.pickListNameId?.value?.charValueSourceID === null && form.controls.pickListSource.value?.pickListSourceID !== null) {
			const requiredResult = Validators.required(form.controls.pickListNameId);
			if (requiredResult !== null) {
				errors["pickListNameIdRequired"] = true;
			}
		}

		if (form.controls.conditionalTriggerFieldId?.value?.characteristicID && !this.selectedValueIds.length && !this.anyValueSelected) {
			const requiredResult = Validators.required(form.controls.conditionalTriggerValue);
			if (requiredResult !== null) {
				errors["conditionalTriggerValueRequired"] = true;
			}
		}

		return errors;
	};

	private patternValidator: ValidatorFn = (form: FormGroup<ICreateTemplateFormData>): ValidationErrors | null => {
		const errors: ValidationErrors = {};

		if (form.controls.mergeFieldLabel.value) {
			const pattern = /^[a-z0-9_]*$/;
			const isValidMergeFieldLabel = pattern.test(form.controls.mergeFieldLabel.value);
			if (!isValidMergeFieldLabel) {
				errors["mergeFieldLabelInvalid"] = true;
			}
		}

		return errors;
	};
}
