import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPlaceholder, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { chain, cloneDeep, Dictionary, find, findIndex, first, isEmpty, isEqual, max, remove, some } from "lodash";
import { DateValueChangedEvent } from "rl-common/components/char-data/controls/date-edit-control.component";
import { ICharDataExtDataDateMath } from "rl-common/models/i-char-data-ext-data-date-math";
import { ICharDataExtDataDateMathCalc } from "rl-common/models/i-char-data-ext-data-date-math-calc";
import { ICharDataExtDataDateMathParentRelInfo } from "rl-common/models/i-char-data-ext-data-date-math-parent-rel-info";
import { ICharDataExtDataDateMathRel } from "rl-common/models/i-char-data-ext-data-date-math-rel";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { BulkGridService } from "rl-common/services/bulk-grid/bulk-grid.service";
import { CharDataTableGlobalService } from "rl-common/services/char-data-table-global.service";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { DateMathSearchTableSelectEvent } from "rl-common/services/datemath/datemath.models";
import { DateMathService } from "rl-common/services/datemath/datemath.service";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { DateLocaleType, DateUtil, IDUtil } from "rl-common/utils";
import { BehaviorSubject, forkJoin, merge, Observable, of, Subject, Subscription } from "rxjs";
import { filter, finalize, map } from "rxjs/operators";
import { DateEditControlComponent } from "../../char-data/controls/date-edit-control.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { BLANK_DATE, CalcCondition, DateMathTimeframe, RecalcOptions, RelativeDateType } from "../date-edit.models";
import { DatemathEditRowComponent } from "../datemath-edit-row/datemath-edit-row.component";
import { DatemathSearchTableComponent } from "../datemath-search-table/datemath-search-table.component";

type RelInfoPair = [ICharDataExtDataDateMathRel, ICharDataExtDataDateMathParentRelInfo];

@Component({
    selector: "rl-datemath-edit",
    templateUrl: "./datemath-edit.component.html",
    styleUrls: ["./datemath-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, NgFor, NgIf, FormsModule, LoaderComponent, CdkDropList, DatemathEditRowComponent, CdkDrag, CdkDragHandle, CdkDragPlaceholder, DateEditControlComponent, DatemathSearchTableComponent, AsyncPipe]
})
export class DatemathEditComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	dateMath: ICharDataExtDataDateMath;

	private _dateMath: ICharDataExtDataDateMath;

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	recCharId: number;

	@Input()
	isRelative: boolean;

	@Input()
	parentEntityId = "";

	@Input()
	isBulkEdit = false;

	@Output()
	dateMathChange = new EventEmitter<ICharDataExtDataDateMath>();

	@Output()
	close = new EventEmitter();

	entityTitle$: BehaviorSubject<string>;
	closeRows$ = new Subject<number>();
	isBusy$ = new BehaviorSubject<boolean>(false);

	timeframeFC: UntypedFormControl;
	recalcOptionFC: UntypedFormControl;
	calcConditionFC: UntypedFormControl;
	dateOptionFC: UntypedFormControl;

	calcDescription: string;
	isSelectOpen = false;

	relInfoPairs: RelInfoPair[] = [];
	contextEntityId: string;
	compareToDate: string;
	selectedOptionId = 0;
	isCalendarDateSelected = false;

	relationships = null;

	private subs: Subscription[] = [];
	private _closing = false;

	// Relative on Mock Entities
	public relativeOptions: { value: number; text: string }[] = [
		{ value: RelativeDateType.DateRelativeToCatalog, text: "Date relative to Catalog" },
		{ value: RelativeDateType.DateRelativeToTableRow, text: "Date relative to Table row" },
		{ value: RelativeDateType.DateRelativeToRights, text: "Date relative to Rights" },
		{ value: RelativeDateType.DateRelativeToDeal, text: "Date relative to Deal" }
	];

	public relativeProfileOption: number;

	private parentRelInfoDict: Dictionary<ICharDataExtDataDateMathParentRelInfo>;

	timeframeList: [number, string][] = [
		[DateMathTimeframe.Equals, "Equals"],
		[DateMathTimeframe.EarliestOf, "Earliest Of"],
		[DateMathTimeframe.LatestOf, "Latest Of"],
		[DateMathTimeframe.PriorityOf, "Priority Of"]
	];

	private readonly _hardSetRecalcOption: [number, string] = [RecalcOptions.HardSet, "Hard Set: Break the calculation link as soon as this date is first set."];

	recalcOptionList: [number, string][] = [
		this._hardSetRecalcOption,
		[RecalcOptions.Archive, "Archive: Break the calculation link once this date is in the past."],
		[RecalcOptions.Open, "Open: Do not break the calculation link.  Always allow updates."]
	];

	calcConditionList: [number, string][] = [
		[CalcCondition.Any, "Calculate as soon as ANY comparison date is set."],
		[CalcCondition.All, "Keep TBD until ALL comparison dates are set."]
	];

	patchQuiet = { emitEvent: false };

	get showAddAnother() {
		return this._dateMath.relationships.length > 0 && this.timeframeFC.value != DateMathTimeframe.Equals;
	}

	get showCompareToDate() {
		const value = this.timeframeFC && this.timeframeFC.value;
		return value === DateMathTimeframe.LatestOf || value === DateMathTimeframe.EarliestOf;
	}

	get canReorder() {
		const value = this.timeframeFC && this.timeframeFC.value;
		return value === DateMathTimeframe.PriorityOf;
	}

	get dateOptionValue() {
		return this.dateOptionFC && this.dateOptionFC.value ? 1 : 0;
	}

	constructor(
		private readonly charDataTableService: CharDataTableService,
		private charDataTableGlobalService: CharDataTableGlobalService<CharDataTableService>,
		private dateMathService: DateMathService,
		private fb: UntypedFormBuilder,
		private entityConfigService: EntityConfigService,
		@Optional() private readonly _bulkGridService: BulkGridService
	) {

		this.timeframeFC = this.fb.control(DateMathTimeframe.Equals);
		this.recalcOptionFC = this.fb.control(RecalcOptions.Archive);
		this.calcConditionFC = this.fb.control(CalcCondition.Any);
		this.dateOptionFC = this.fb.control(false);

	}

	ngOnInit() {
		if (this.isBulkEdit) {
			this._bulkGridService.setDateOptionsModalOpen(true);
		}
		const template = this.charDataTableService.template;
		const recordId = this.charDataTableService.recordID$.value ? this.charDataTableService.recordID$.value : -1;
		this.contextEntityId = IDUtil.toID(template.divID, template.charTypeID, recordId);
		this.entityTitle$ = this.charDataTableService.entityTitle$;

		if (this.charDataTableService.recordID$.value !== recordId) {
			this.charDataTableService.setRecordId(recordId);
		}

		if (this._dateMath.relationships.length === 0) {
			this.isSelectOpen = true;
		}

		if (this.isRelative) {
			this.selectedOptionId = RelativeDateType.DateRelativeToRights;
		}

		this.relationships = this._dateMath.relationships;

		// set timeframe/option/condition whenever either changes
		const sub = merge(
			this.timeframeFC.valueChanges,
			this.recalcOptionFC.valueChanges,
			this.calcConditionFC.valueChanges,
			this.dateOptionFC.valueChanges
		).subscribe(() => {
			if (this._dateMath.calcs.length > 0) {
				// // update change
				let dateMath = cloneDeep(this._dateMath);
				dateMath.calcs[0].timeFrameID = this.timeframeFC.value;
				dateMath.calcs[0].dateRecalcOptionID = this.recalcOptionFC.value;
				dateMath.calcs[0].calcConditionID = this.calcConditionFC.value;
				dateMath.calcs[0].dateOptionID = this.dateOptionValue;

				if (dateMath.calcs[0].dateMathCalcID > 0) {
					dateMath = this.trackCalcAs(dateMath, dateMath.calcs[0], "updated");
				}
				this._dateMath = dateMath;
			}
			this.setDescription();
		});

		this.subs.push(sub);

		// refresh list when timeframe changes
		const sub2 = this.timeframeFC.valueChanges.subscribe((value) => {
			const hardSetRecalcIndex = this.recalcOptionList.findIndex(x => x[0] === RecalcOptions.HardSet);
			if (value !== DateMathTimeframe.Equals && hardSetRecalcIndex >= 0) {
				this.recalcOptionList.splice(hardSetRecalcIndex, 1);
			} else if (value === DateMathTimeframe.Equals && hardSetRecalcIndex < 0) {
				this.recalcOptionList.unshift(this._hardSetRecalcOption);
			}
			this.refreshRows();
		});
		this.subs.push(sub2);

		const sub3 = this.closeRows$
			.pipe(filter(dateMathRelId => dateMathRelId != null))
			.subscribe(() => {
				this.isSelectOpen = false;
			});

		this.subs.push(sub3);
	}

	ngOnDestroy() {
		this.subs.forEach(s => s.unsubscribe());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this._closing) {
			const dateMathChanges = changes["dateMath"];
			if (dateMathChanges) {
				if (dateMathChanges.firstChange || !isEqual(dateMathChanges.currentValue, dateMathChanges.previousValue)) {
					this._dateMath = cloneDeep(dateMathChanges.currentValue);
					this.refreshParentInfos();
					const calc = first(this._dateMath.calcs);
					if (calc) {
						// update dropdown
						this.timeframeFC.patchValue(calc.timeFrameID, this.patchQuiet);
						this.recalcOptionFC.patchValue(calc.dateRecalcOptionID, this.patchQuiet);
						this.calcConditionFC.patchValue(calc.calcConditionID, this.patchQuiet);
						this.dateOptionFC.patchValue(calc.dateOptionID, this.patchQuiet);
						this.compareToDate = DateUtil.formatAsDate(calc.compareToDate, DateLocaleType.Storage);
					}
				}
			}
			if (changes["cmd"] || changes["dateMath"]) {
				this.setDescription();
			}
		}
	}

	removeRel(rel: ICharDataExtDataDateMathRel) {
		let dateMath = cloneDeep(this._dateMath);
		dateMath = this.trackRelAs(dateMath, rel, "deleted");
		remove(dateMath.relationships, rel2 => rel2.dateMathRelID === rel.dateMathRelID);
		this._dateMath = dateMath;
		const calc = first(dateMath.calcs);

		if (calc) {
			this._dateMath = this.trackCalcAs(dateMath, calc, "updated");
		}

		this.refreshRows();
	}

	dateSelected($event: DateMathSearchTableSelectEvent, rel: ICharDataExtDataDateMathRel) {
		this.isSelectOpen = false;
		this.isBusy$.next(true);
		let dateMath = cloneDeep(this._dateMath);
		const parentEntityId = IDUtil.splitEntityID($event.entityId);
		// get the recCharID (whether it exists or you have to create it remotely/locally)
		const sub = this.ensureRecCharID$($event)
			.pipe(finalize(() => this.isBusy$.next(false)))
			.subscribe(recCharID => {
				if (recCharID != null || $event.relativeCatalogCharId || $event.relativeDealCharId) {
					// ensure there is a dateMath calc
					dateMath = this.ensureCalc(dateMath);
					const calc = first(dateMath.calcs);
					if (rel == null) {
						const maxSortOrder = max(dateMath.relationships.map(rel2 => rel2.sortOrder));
						const maxSort = maxSortOrder >= 0 ? maxSortOrder : -1;
						// placeholder recCharId for rrp datemath
						const parentRecCharId = $event.relativeCatalogCharId ? $event.relativeCatalogCharId * -1000 : $event.relativeDealCharId ? $event.relativeDealCharId * -1000 : recCharID;
						// create the dateMath rel
						rel = {
							dateMathCalcID: calc.dateMathCalcID,
							dateMathRelID: this.charDataTableGlobalService.getTempId(),
							dayQuantity: null,
							divID: parentEntityId.divID,
							frequencyID: null,
							isPriorTo: null,
							parentCharTypeID: parentEntityId.charTypeID,
							parentRecCharID: parentRecCharId,
							parentRecID: parentEntityId.recID,
							parentDateCharId: $event.charId,
							recCharID: this.recCharId,
							relativeCatalogCharID: $event.relativeCatalogCharId,
							relativeDealCharID: $event.relativeDealCharId,
							sortOrder: maxSort + 1,
							monthQuantity: null,
							weekQuantity: null,
							yearQuantity: null
						};

						if (this.isRelative && this.timeframeFC.value === DateMathTimeframe.Equals) {
							dateMath.relationships = [rel];
						} else {
							dateMath.relationships.push(rel);
						}
						dateMath = this.trackRelAs(dateMath, rel, "created");
					} else {
						// update existing dateMath rel
						rel.parentCharTypeID = parentEntityId.charTypeID;
						rel.parentRecCharID = recCharID;
						rel.parentRecID = parentEntityId.recID;
						rel.relativeCatalogCharID = $event.relativeCatalogCharId;
						rel.relativeDealCharID = $event.relativeDealCharId;
						rel.parentDateCharId = $event.charId;

						const index = findIndex(dateMath.relationships, rel2 => rel2.dateMathRelID === rel.dateMathRelID);
						if (index > -1) {
							dateMath.relationships[index] = rel;
						}
						if (rel.dateMathRelID > 0) {
							dateMath = this.trackRelAs(dateMath, rel, "updated");
						} else {
							dateMath = this.trackRelAs(dateMath, rel, "created");
						}
					}
					this._dateMath = dateMath;
					this.refreshParentInfos();
				}
			});
		this.subs.push(sub);
	}

	dropSort($event: CdkDragDrop<ICharDataExtDataDateMathRel>) {
		const rels = this.relInfoPairs.map(pair => pair[0]);
		moveItemInArray(rels, $event.previousIndex, $event.currentIndex);
		rels.forEach((rel, i) => {
			rel.sortOrder = i;
		});

		// reset all sortOrders to order of newRels
		rels.forEach((rel, i) => {
			rel.sortOrder = i;
			this.trackRelAs(this._dateMath, rel, "updated");
		});
		this._dateMath.relationships = rels;
		this.refreshRows();
	}

	relChanged($event: ICharDataExtDataDateMathRel) {
		let dateMath = cloneDeep(this._dateMath);
		const rel = $event;
		const index = findIndex(dateMath.relationships, rel2 => rel2.dateMathRelID === rel.dateMathRelID);
		if (index > -1) {
			dateMath.relationships[index] = rel;
			if (rel.dateMathRelID > 0) {
				dateMath = this.trackRelAs(dateMath, rel, "updated");
			}
			this._dateMath = dateMath;
		}
	}

	compareToDateChanged(event: DateValueChangedEvent) {
		const dateValue = event.dateValue
		const momentValue = DateUtil.parseToUTCMoment(dateValue, DateLocaleType.Storage);

		const dateMath = cloneDeep(this._dateMath);
		const calc = first(dateMath.calcs);
		if (calc) {
			if (momentValue?.isValid()) {
				calc.compareToDate = dateValue;
			} else {
				calc.compareToDate = null;
			}

			this._dateMath = this.trackCalcAs(dateMath, calc, "updated");
		}
		this.isCalendarDateSelected = true;
	}

	private refreshParentInfos() {
		if (this._dateMath.relationships && this._dateMath.relationships.length > 0) {
			this.isBusy$.next(true);
			const contextEntityId = IDUtil.splitEntityID(this.contextEntityId);

			const isLocalFn = (rel: ICharDataExtDataDateMathRel): boolean => rel.parentCharTypeID === contextEntityId.charTypeID && rel.parentRecID === contextEntityId.recID;

			const localRels = this._dateMath.relationships.filter(rel => isLocalFn(rel));
			const nonLocalRels = this._dateMath.relationships.filter(rel => !isLocalFn(rel));

			const sub = forkJoin(this.getNonLocalParentRelInfos(nonLocalRels), this.getLocalParentRelInfos(localRels))
				.pipe(
					finalize(() => this.isBusy$.next(false)),
					map((results) => [...results[0], ...results[1]])
				)
				.subscribe((result) => {
					this.parentRelInfoDict = result.reduce<Dictionary<ICharDataExtDataDateMathParentRelInfo>>(
						(acc, info) => {
							acc[info.dateMathRelID] = info;
							return acc;
						}, {});

					this.refreshRows();
				});
			this.subs.push(sub);
		} else {
			this.parentRelInfoDict = {};
			this.refreshRows();
		}

	}

	private refreshRows(): void {
		this.relInfoPairs = chain(this._dateMath.relationships)
			.orderBy([rel => rel.sortOrder, rel => this.sortedRelID(rel.dateMathRelID)])
			.map(r => ([r, this.parentRelInfoDict[r.dateMathRelID]] as RelInfoPair))
			.value();
		if (this.timeframeFC && this.timeframeFC.value === DateMathTimeframe.Equals) {
			this.relInfoPairs = this.relInfoPairs.slice(0, 1);
		}
		if (isEmpty(this._dateMath.relationships)) {
			this.isSelectOpen = true;
		}
	}

	private getNonLocalParentRelInfos(rels: ICharDataExtDataDateMathRel[]) {
		if (rels.length === 0) {
			return of<ICharDataExtDataDateMathParentRelInfo[]>([]);
		} else {
			return this.dateMathService.getParentRelInfoList(rels);
		}
	}

	private getLocalParentRelInfos(rels: ICharDataExtDataDateMathRel[]) {
		if (rels.length === 0) {
			return of<ICharDataExtDataDateMathParentRelInfo[]>([]);
		} else {
			const cmds = this.charDataTableService.template.characteristicMetaDatas;
			const charDatas = chain(this.charDataTableService.charDatas)
				.values()
				.flatten()
				.value();

			return this.entityConfigService.getCharacteristicTypes()
				.pipe(
					map(() => rels.reduce<ICharDataExtDataDateMathParentRelInfo[]>((acc, rel) => {
						const cd = find(charDatas, cd2 => cd2.recordCharacteristicID === rel.parentRecCharID);
						if (cd) {
							const cmd = find(cmds, cmd2 => cmd2.characteristicID === cd.charactersticID);
							const segments = [
								this.entityTitle$.value,
								"Details",
								cmd.label
							];
							if (cmd) {
								acc.push({
									currentValue: cd.value,
									dateMathRelID: rel.dateMathRelID,
									entityID: this.contextEntityId,
									path: segments.join(" > ")
								});
							}
						}
						return acc;
					}, []))
				);
		}
	}

	/** sort positive and negative number in consistent order of creation */
	private sortedRelID(dateMathRelID: number) {
		if (dateMathRelID < 0) {
			return (Number.MAX_SAFE_INTEGER / 2) + Math.abs(dateMathRelID);
		} else {
			return dateMathRelID;
		}
	}

	private setDescription(): void {
		const calc = first(this._dateMath.calcs);
		const timeFrameId = calc?.timeFrameID ?? this.timeframeFC.value;
		if (this.cmd) {
			switch (timeFrameId) {
				case DateMathTimeframe.Equals:
					this.calcDescription = `${this.cmd.label} equals:`;
					break;
				case DateMathTimeframe.EarliestOf:
					this.calcDescription = `${this.cmd.label} equals the earliest of the following dates:`;
					break;
				case DateMathTimeframe.LatestOf:
					this.calcDescription = `${this.cmd.label} equals the latest of the following dates:`;
					break;
				case DateMathTimeframe.PriorityOf:
					this.calcDescription = `${this.cmd.label} equals the priority of the following dates:`;
					break;
				default:
					break;
			}
		}
	}

	private ensureRecCharID$($event: DateMathSearchTableSelectEvent): Observable<number> {
		// TODO: regression test APP-7357
		// const isLocal = ($event.entityId === this.contextEntityId && !this.isBulkEdit);

		// if the parent entity is the same as the child entity we can create the charData locally in mem
		const isLocal = $event.entityId === this.contextEntityId;
		if ($event.recCharId > 0) {
			// already had a recCharId
			return of($event.recCharId);
		} else if ($event.relativeCatalogCharId > 0) {
			return of($event.recCharId);
		} else if ($event.relativeDealCharId > 0) {
			return of($event.recCharId);
		} else {
			// check for other active chardata tables for chars or create remotely
			return this.charDataTableGlobalService.ensureCharData($event.entityId, $event.charId, { value: BLANK_DATE }, isLocal, this._bulkGridService);
		}
	}

	private ensureCalc(dateMath: ICharDataExtDataDateMath) {
		const entityId = IDUtil.splitEntityID(this.contextEntityId);
		if (!some(dateMath.calcs)) {
			const calc: ICharDataExtDataDateMathCalc = {
				charTypeID: entityId.charTypeID,
				divID: entityId.divID,
				recID: entityId.recID,
				recCharID: this.recCharId,
				dateMathCalcID: this.charDataTableGlobalService.getTempId(),
				dateMathCalcJson: "",
				calcConditionID: this.calcConditionFC.value,
				compareToDate: this.compareToDate, // fix this
				dateOptionID: this.dateOptionValue,
				dateRecalcOptionID: this.recalcOptionFC.value,
				frequencyIntervalID: null,
				frequencyRelativeIntervalID: null,
				isCalc: false,
				timeFrameID: this.timeframeFC.value
			};

			dateMath.calcs.push(calc);
			return this.trackCalcAs(dateMath, calc, "created");
		}
		return dateMath;
	}

	private trackRelAs(dateMath: ICharDataExtDataDateMath, rel: ICharDataExtDataDateMathRel, status: "updated" | "deleted" | "created") {
		switch (status) {
			case "created":
				return this.setRelChangeTracking(dateMath, rel.dateMathRelID, 1);
			case "updated":
				if (rel.dateMathRelID > 0) {
					return this.setRelChangeTracking(dateMath, rel.dateMathRelID, 0);
				}
				break;
			case "deleted":
				if (rel.dateMathRelID > 0) {
					return this.setRelChangeTracking(dateMath, rel.dateMathRelID, -1);
				} else {
					return this.setRelChangeTracking(dateMath, rel.dateMathRelID, null);
				}
			default:
				break;
		}
		return dateMath;
	}

	private setRelChangeTracking(dateMath: ICharDataExtDataDateMath, relID: number, status: -1 | 0 | 1 | null) {
		if (status == null) {
			delete dateMath.relationshipChangeTracking[relID];
		} else {
			dateMath.relationshipChangeTracking[relID] = status;
		}
		return dateMath;
	}

	private trackCalcAs(dateMath: ICharDataExtDataDateMath, calc: ICharDataExtDataDateMathCalc, status: "updated" | "deleted" | "created") {
		switch (status) {
			case "created":
				return this.setCalcChangeTracking(dateMath, calc.dateMathCalcID, 1);
			case "updated":
				if (calc.dateMathCalcID > 0) {
					return this.setCalcChangeTracking(dateMath, calc.dateMathCalcID, 0);
				}
				break;
			case "deleted":
				if (calc.dateMathCalcID > 0) {
					return this.setCalcChangeTracking(dateMath, calc.dateMathCalcID, -1);
				} else {
					return this.setCalcChangeTracking(dateMath, calc.dateMathCalcID, null);
				}
			default:
				break;
		}
		return dateMath;
	}

	private setCalcChangeTracking(dateMath: ICharDataExtDataDateMath, calcID: number, status: -1 | 0 | 1 | null) {
		if (status == null) {
			delete dateMath.calcsChangeTracking[calcID];
		} else {
			dateMath.calcsChangeTracking[calcID] = status;
		}
		return dateMath;
	}

	addAnother() {
		this.isSelectOpen = !this.isSelectOpen;
		if (this.isSelectOpen) {
			this.closeRows$.next(null);
		}
	}

	/** take care of illegal cal/rel situations */
	validateDateMath(dateMath: ICharDataExtDataDateMath) {
		let output = cloneDeep(dateMath);
		// only non-deleted rels
		const activeRels = chain(dateMath.relationships)
			.orderBy([rel => rel.sortOrder, rel => this.sortedRelID(rel.dateMathRelID)])
			.filter(rel => dateMath.relationshipChangeTracking[rel.dateMathRelID] !== -1)
			.value();

		// if no rels, delete the "orphaned" calc
		if (activeRels.length === 0 && output.calcs[0]) {
			output = this.trackCalcAs(output, output.calcs[0], "deleted");
		}

		// if calc is equals ensure only one rel
		if (output.calcs[0] && output.calcs[0].timeFrameID === DateMathTimeframe.Equals && activeRels.length > 1) {
			activeRels.forEach((rel, i) => {
				if (i > 0) {
					output = this.trackRelAs(output, rel, "deleted");
				}
			});
		}

		return output;
	}

	canApply() {
		// Handles Earliest Of, Latest Of, Priority Of
		if (this.timeframeFC.value !== DateMathTimeframe.Equals) {
			if (this._dateMath.relationships.length >= 2 || !isEmpty(this._dateMath.relationships) && this.isCalendarDateSelected) {
				return this.isBusy$;
			}
		} else {
			if (!isEmpty(this._dateMath.relationships)) {
				return this.isBusy$;
			}
		}
	}

	apply() {
		this._closing = true;
		const dateMath = this.validateDateMath(cloneDeep(this._dateMath));
		this.dateMathChange.emit(dateMath);
		this.setDateOptionsModalOpenFalse();
		this.close.emit();
	}

	cancel() {
		this.setDateOptionsModalOpenFalse();
		this.close.emit();
	}

	setDateOptionsModalOpenFalse() {
		if (this.isBulkEdit) {
			this._bulkGridService.setDateOptionsModalOpen(false);
		}
	}

	relTrackBy(index: number, relPair: RelInfoPair) {
		return relPair[0].dateMathRelID;
	}

	checkForSearch() {
		if (this.isSelectOpen === false) {
			this.isSelectOpen = true;
		}
	}
}
