import { Injectable, Injector } from "@angular/core";
import { ClipboardModalAdapter } from "rl-common/components/clipboard/clipboard.adapter";
import { EditClipboardModalAdapter } from "rl-common/components/clipboard/edit-clipboard/edit-clipboard.adapter";
import { WorksheetType, IWorksheet } from "rl-common/services/clipboard/clipboard.models";
import { ProgressService } from "rl-common/services/progress.service";
import { AvailsBulkCreateRightsModalAdapter } from "./avails-bulk-create-rights/avails-bulk-create-rights.adapter";
import { AvailsSnapshot } from "./rights-explorer.models";

@Injectable({ providedIn: "root" })
export class RightsExplorerModalService {

	constructor(
		// private _modalBuilder: ModalBuilder,
		private _progressService: ProgressService,
		private readonly _availsBulkCreateRightsModalAdapter: AvailsBulkCreateRightsModalAdapter,
		private readonly _clipboardModalAdapter: ClipboardModalAdapter,
		private readonly _editClipboardComponentModalAdapter: EditClipboardModalAdapter
	) {

	}

	createAvailsSnapshotModal(avails: AvailsSnapshot[], injector: Injector) {
		return this._clipboardModalAdapter.openModal(component => {
			component.clipboardType = WorksheetType.AvailsSnapshot;
			component.items = avails;
			return component.onComplete;
		}, { injector, backdrop: "static" });
	}

	editSnapshotModal(snapshot: IWorksheet<unknown>, injector: Injector) {
		return this._editClipboardComponentModalAdapter.openModal(component => {
			component.selectedSnapshot = snapshot;
			component.items = snapshot.data;
			component.clipboardType = snapshot.clipboardTypeId;
			component.clipboardTitle = snapshot.title;
			component.setDataSource();
			component.showModalHeader = true;

			return component.onComplete;
		}, { injector, backdrop: "static" });
	}

	bulkCreateRightsModal(avails: AvailsSnapshot[], injector: Injector) {
		this._progressService.startProgress();
		return this._availsBulkCreateRightsModalAdapter.openModal(component => {
			component.avails = avails;
			this._progressService.endProgress();
			return component.onComplete;
		}, { injector, backdrop: "static" });
	}

}