<div class="modal-header">
	<h2 class="modal-title">Associate {{charTypeId | pluralCharTypeName}} to
		<strong>{{parentTitle?.length > 73 && isCollapsed ? parentTitle.slice(0,73) + '...' : parentTitle}}</strong>
		({{parentAssociations.count}})
	</h2>
	<button *ngIf="parentTitle?.length > 73" class="btn btn-sm btn-link me-auto" (click)="toggle()">{{isCollapsed ?
		'Expand' : 'Collapse'}}</button>
	<button aria-label="Close" class="btn btn-close ms-2" type="button" (click)="cancel()"
		data-cy="CloseNewAssociationModalButton"><i class="far fa-times"></i></button>
</div>
<div class="modal--associate">
	<rl-new-association data-cy="NewAssociationModal" [charTypeId]="charTypeId"
		[parentAssociations]="parentAssociations" [parentTemplateId]="parentTemplateId"
		[defaultSelectedIds]="defaultSelectedIds" [shouldSave]="shouldSave" [parentTitle]="parentTitle"
		[showExisting]="showExisting" [isLocked]="isLocked" [isMultiple]="isMultiple"
		[partyId]="partyId" [direction]="relationshipType" [relationshipDirectionTypes]="relationshipDirectionTypes"
		[hasValidCreateTemplates]="hasValidCreateTemplates" [keywords]="keywords" (onCancel)="cancel()"
		(onCreate)="emitCreate($event)" (onApply)="onApply.next($event)">
	</rl-new-association>
</div>