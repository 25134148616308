import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Moment } from "moment";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { DateInputComponent } from "../../../date-input/date-input.component";

@Component({
	selector: "rl-uneven-date-control",
	templateUrl: "./uneven-date-control.component.html",
	styleUrls: ["./uneven-date-control.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UnevenDateControlComponent),
			multi: true
		},
	],
	imports: [NgIf, ReactiveFormsModule, NgFor, DateInputComponent]
})
export class UnevenDateControlComponent implements OnInit, OnChanges, ControlValueAccessor {
	get dates() {
		return this.unevenDateForm.get("dates") as UntypedFormArray;
	}
	public disabled = false;
	public value: Moment[];
	@Input()
	numRows = 0;

	@Output()
	change = new EventEmitter<void>();
	unevenDateForm: UntypedFormGroup;
	constructor(
		private readonly fb: UntypedFormBuilder
	) { }

	ngOnInit() {
		const ctrls = Array.apply(null, { length: this.numRows })
			.map(index => this.createControl());
		this.unevenDateForm = new UntypedFormGroup(
			{
				dates: new UntypedFormArray(ctrls)
			}
		);
		this.updateControl();
	}

	ngOnChanges(changes: ComponentChanges<UnevenDateControlComponent>): void {
		if (this.unevenDateForm && changes.numRows.previousValue !== changes.numRows.currentValue) {
			const diff = Math.abs(changes.numRows.previousValue - changes.numRows.currentValue);
			if (changes.numRows.previousValue < changes.numRows.currentValue) {
				for (let i = 0; i < diff; i++) {
					this.dates.push(this.createControl());
				}
			} else if (changes.numRows.previousValue > changes.numRows.currentValue) {
				for (let i = 0; i < diff; i++) {
					this.dates.removeAt(this.dates.length - 1);
				}
			}
			this.updateControl();
		}
	}

	createControl() {
		return this.fb.group({
			date: ""
		});
	}

	public onChange(newVal: number[]) { }
	public onTouched(_?: any) { }
	public writeValue(obj: Moment[]): void { this.value = obj; }
	public registerOnChange(fn: any): void { this.onChange = fn; }
	public registerOnTouched(fn: any): void { this.onTouched = fn; }
	public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

	updateControl() {
		const newVals = this.dates.controls.map(c => c.value.date);
		this.value = newVals;
		this.onChange(newVals);
		this.change.emit();
	}
}
