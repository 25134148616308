import { CdkScrollable } from "@angular/cdk/scrolling";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Params, Router, RouterOutlet } from "@angular/router";
import { NgbAccordionModule, NgbAlertModule, NgbCarouselModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownConfig, NgbDropdownModule, NgbModalModule, NgbModalRef, NgbNavModule, NgbOffcanvasModule, NgbPaginationModule, NgbPopoverConfig, NgbPopoverModule, NgbProgressbarModule, NgbRatingModule, NgbScrollSpyModule, NgbTimepickerModule, NgbToastModule, NgbTooltipConfig, NgbTooltipModule, NgbTypeaheadConfig, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular-ivy";
import { FeatureService } from "admin/components/features/feature.service";
import { isEmpty } from "lodash";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { ModalBuilder } from "rl-common/services/modal-builder/modal-builder";
import { SessionService } from "rl-common/services/session.service";
import { SettingsService } from "rl-common/services/settings/settings.service";
import { BehaviorSubject, Subscription, from, interval } from "rxjs";
import { filter, map, pairwise, take, tap } from "rxjs/operators";
import { GrowlerService } from "./../../common/services/growler.service";
import { OneConfigService } from "./../../common/services/one-config/one-config.service";
import { TokenStorageService } from "./../../common/services/token-storage/token-storage.service";
import { RlFooterComponent } from "./footer/footer.component";
import { GrowlNotificationsComponent } from "./growl-notifications/growl-notifications.component";
import { RlHeaderComponent } from "./header/header.component";
import { DebugModalComponent } from "./modals/debug-modal/debug-modal.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { SideNavigationComponent } from "./side-navigation/side-navigation.component";


const ngbTypes = [NgbAccordionModule, NgbAlertModule, NgbCarouselModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbNavModule, NgbOffcanvasModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbRatingModule, NgbScrollSpyModule, NgbTimepickerModule, NgbToastModule, NgbTooltipModule, NgbTypeaheadModule];

@Component({
	selector: "rl-app",
	templateUrl: "./rl-app.component.html",
	providers: [],
	imports: [NgClass, RlHeaderComponent, SideNavigationComponent, CdkScrollable, RouterOutlet, NgIf, RlFooterComponent, ProgressBarComponent, GrowlNotificationsComponent, AsyncPipe, ...ngbTypes]
})
export class RLAppComponent implements OnInit, OnDestroy {

	isLoggedIn$: BehaviorSubject<boolean>;
	showFooter = false;
	navigationStart;
	isReporting = false;
	isUtilLanding = false;
	private _debugModalRef: NgbModalRef;

	private readonly _subs: Subscription[] = [];

	public constructor(
		private sessionService: SessionService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _tokenStorageService: TokenStorageService,
		private readonly _growlerService: GrowlerService,
		private readonly _settingsService: SettingsService,
		private readonly _featureService: FeatureService,
		public linkHelper: LinkHelperService,
		private readonly _router: Router,
		dropDownConfig: NgbDropdownConfig,
		popoverConfig: NgbPopoverConfig,
		typeAheadConfig: NgbTypeaheadConfig,
		tooltipConfig: NgbTooltipConfig,
		private browserTitle: Title,
		private activatedRoute: ActivatedRoute,
		private readonly _modalBuilder: ModalBuilder,
		private _ngZone: NgZone,
		private _cdRef: ChangeDetectorRef
	) {
		this.isLoggedIn$ = this.sessionService.isValid$;

		const sentryScopeSub = this.sessionService.sessionChange$.subscribe(() => this.setSentryContext());
		setTimeout(() => this.setSentryContext(), 0);

		const navigateToLoginSub = this.sessionService.sessionChange$.pipe(
			filter(() => !this.sessionService.isAuthenticated),
		).subscribe(() => {
			this._modalBuilder.dismissAll("User no longer authenticated.");
			const returnUrl = this._router.url;
			const queryParams: Params = {};
			if (returnUrl && !isEmpty(returnUrl) && returnUrl !== "/" && !returnUrl.startsWith("/login")) {
				queryParams["returnUrl"] = returnUrl;
			}
			const navExtras: NavigationExtras = {
				queryParams: queryParams
			};
			this._ngZone.run(() => this._router.navigate(this.linkHelper.login(), navExtras));
		});

		const navigateToHomeSub = this.sessionService.divId$.pipe(
			pairwise(),
			filter(pair => this.sessionService.isAuthenticated && pair[0] !== -1 && pair[0] !== pair[1]),
		).subscribe(() => {
			this._ngZone.run(() => this._router.navigate(this.linkHelper.home()));
		});

		const featureSub = this._settingsService.features$.subscribe(features => {
			const existing = _featureService.enabledFeatures$.value;
			features.forEach(feature => {
				existing[feature.key] = feature.isEnabled;
			})
			_featureService.enabledFeatures$.next(existing);
		});

		// forces change detection when the browser's tab goes from hidden to active
		interval(500).pipe(
			map(() => document.hidden),
			pairwise(),
			filter(([from, to]) => from !== to && to)
		).subscribe(() => {
			this._cdRef.detectChanges();
		});

		this._subs.push(navigateToLoginSub, navigateToHomeSub, sentryScopeSub, featureSub);

		dropDownConfig.container = "body";
		popoverConfig.container = "body";
		typeAheadConfig.container = "body";
		tooltipConfig.container = "body";
	}

	private setSentryContext() {
		if (this.sessionService.isAuthenticated) {
			Sentry.setContext("session", {
				divId: this.sessionService.divId,
				userId: this.sessionService.userId
			});
		} else {
			Sentry.setContext("session", null);
		}
	}

	public toggleDebugModal() {
		if (this._debugModalRef) {
			this._debugModalRef.dismiss();
			return;
		}

		this._debugModalRef = this._modalBuilder.plain<DebugModalComponent>(DebugModalComponent).open();

		from(this._debugModalRef.result).subscribe(
			() => { }, // close
			() => {
				this._debugModalRef = null;
			} // dismissed
		);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	@HostListener("document:keydown.control.shift.y", ["$event"])
	onDebugModalToggle() {
		const sub = this.sessionService.isRlAdmin$.pipe(take(1)).subscribe((isRlAdmin) => {
			if (isRlAdmin) {
				this.toggleDebugModal();
			}
		});
		this._subs.push(sub);
	}

	@HostListener("document:keydown.control.shift.k", ["$event"])
	onRefreshConfig() {
		const sub = this._oneConfigService.refresh(this._tokenStorageService.jwt)
			.subscribe(() => this._growlerService.success("Refresh Config").growl("Cached changes have been updated from config. "));
		this._subs.push(sub);
	}

	ngOnInit() {
		const titleSub = this._router.events
			.pipe(
				tap(((x: NavigationStart) => {
					x instanceof NavigationStart;
					this.navigationStart = x;
					if (x.url) {
						const url = new URL(x.url, window.location.origin);
						const isRecord = url.pathname.indexOf("mod") > -1 && url.pathname.split("/").length >= 2;
						const isAdmin = url.pathname.indexOf("administration") > -1;
						const isConfig = url.pathname.indexOf("\/config\/") > -1 || url.pathname === ("/config");
						const isDataTools = url.pathname.indexOf("data-tools") > -1;
						this.isUtilLanding = url.pathname === ("/administration") || url.pathname === ("/config") || url.pathname === ("/data-tools");
						this.isReporting = url.pathname.indexOf("report-builder") > -1;
						//hide footer for sections with fixed header
						this.showFooter = !isRecord && !isAdmin && !isConfig && !isDataTools;
						// izenda redirect
						if ((url.hash.indexOf("#?source=/report/view/") > -1 || url.hash.indexOf("#?source=/settings") > -1 ||
							url.hash.indexOf("#?source=/dashboard") > -1 || url.hash.indexOf("#?source=/report") > -1)
							// And not already in Report Builder
							&& url.pathname != "/reporting/report-builder") {
							console.log(`redirecting to report builder from: ${url}`);
							this._router.navigate(["/reporting/report-builder"], { fragment: url.hash.replace("#", "") });
						}
					}

				})),
				filter((event: any) => event instanceof NavigationEnd),
				map(() => {
					if (this.navigationStart?.url) {
						const isAdmin = this.navigationStart.url.indexOf("administration") > -1;
						const isRecord = this.navigationStart.url.indexOf("mod") > -1 && this.navigationStart.url.split("/").length > 2;
						const isConfig = this.navigationStart.url.indexOf("\/config\/") > -1 || this.navigationStart.url === ("/config");
						const isDataTools = this.navigationStart.url.indexOf("data-tools") > -1;
						this.isUtilLanding = this.navigationStart.url === ("/administration") || this.navigationStart.url === ("/config") || this.navigationStart.url === ("/data-tools");
						this.isReporting = this.navigationStart.url.indexOf("report-builder") > -1;
						//hide footer for sections with fixed header
						this.showFooter = !isRecord && !isAdmin && !isConfig && !isDataTools;
					}
					let child = this.activatedRoute.firstChild;
					while (child.firstChild) {
						child = child.firstChild;
					}
					if (child.snapshot.data["title"]) {
						return child.snapshot.data["title"];
					}
				})
			)
			.subscribe((title: string) => {
				if (title) {
					this.browserTitle.setTitle(title);
				}
			});

		// The following snippet restores the navigation state when the user clicks forward or back
		// https://github.com/angular/angular/issues/28108
		const routerSub = this._router.events.pipe(
			filter(e => e instanceof NavigationStart),
			filter((e: NavigationStart) => e.navigationTrigger === "popstate")
		).subscribe((x: NavigationStart) => {
			const nav = this._router.getCurrentNavigation();
			nav.extras.state = { ...x.restoredState, navigationId: x.id };
		});

		this._subs.push(titleSub, routerSub);
	}

}
