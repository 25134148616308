import { Dictionary } from "lodash";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ISecurityPolicy, ISecurityPolicyPermission, IWfProcesses, IWfRole, IWfRoleStepAssociation, IWfStep } from "../company/company.models";
import { IWorkflowActionExtensionResponse } from "../workflow/workflow.messages";
import { IPartyNotification, IPartyWithNotif, IWorkflowNotification } from "../workflow/workflow.models";

export interface IEntityTemplateId {
	divId: number;
	charTypeId: number;
	templateId: number;
}

export interface ICharacteristicType {
	charTypeId: number;
	charTypeName: string;
	seqNumber: number;
}

export interface ISourceField {
	divID: number;
	charTypeID: number;
	sourceFieldID: number;
	sourceFieldName: string;
	mergeTag: string;
	dataTypeID: number;
	length: number;
	systemIndicator: number;
	multipleIndicator: number;
	extractable: boolean;
	reportIndicator: number;
	charValueSetID: string;
	description: string;
	usedIn: Array<ISourceFieldTemplateAssociationUsage>;
	hint: string;
}

export interface ISourceFieldSearchOptions {
	pageSize: number;
	selectedRecordIds?: number[];
}


export interface ISourceFieldTemplateAssociationUsage {
	templateID: number;
	templateLbl: string;
	templateSourceFieldGroupID: number;
	templateSourceFieldGroupLbl: string;
}

export interface ISourceFieldSection {
	charGroupId: number;
	charGroupLabel: string;
	systemIndicator?: number;
	visibilityIndicator?: number;
	sequenceNumber?: number;
	expGroupIndicator?: number;
}

export interface IPickListSource {
	divID: number;
	pickListSourceID: string;
	pickListSourceLabel: string;
	pickListSourceDescription: string;
}

export interface IPickListSourceList {
	divID: number;
	charValueSourceID: string;
	charValueSetID: string;
	charValueSourceLabel: string;
	charValueSourceDescription: string;
	createdBy: number;
	createdAt: Date;
	updatedBy: number;
	updatedAt: Date;
}

export interface IConditionalField {
	conditionalFieldID: string;
	conditionalFieldTypeID: string;
	divID: number;
	charTypeID: number;
	charID: number;
	templateID: number;
	triggerCharID?: number;
	triggerGroupID?: number;
	triggerValue: string;
}

export interface IConditionalFieldType {
	id: number;
	label: string;
	description: string;
}

export interface IAddAndAssociateCharacteristic {
	charTypeID: number;
	templateID: number;
	groupID: number;
	charMetaData: ICharacteristicMetaData;
	permissions: Dictionary<ISecurityPolicyPermission>;
	conditionalField: IConditionalField;
}

export interface IPartyCollection {
	divID: number;
	charTypeID: number;
	templateID: number;
	groups: Array<IPartyGroup>;
	parties: Array<IPartyAssociation>;
	allParties: Array<IParty>;
}

export interface IPartyGroup {
	divisionID: number;
	groupID: number;
	groupLabel: string;
	groupDescription: string;
	expandIndicator: number;
	expandGroupIndicator: number;
	systemIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	userID: number;
}

export interface IPartyAssociation {
	divisionID: number;
	charTypeID: number;
	templateID: number;
	partyID: number;
	partyGroupID: number;
	sourceParty: string;
	partyName: string;
	partyDescription: string;
	requiredIndicator: number;
	multipleIndicator: number;
	editIndicator: number;
	deleteIndicator: number;
	primaryIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	systemIndicatorId: number;
}

export interface IParty {
	divisionID: number;
	charTypeID: number;
	templateID: number;
	partyID: number;
	partyGroupID: number;
	partyName: string;
	partyDescription: string;
	requiredIndicator: number;
	multipleIndicator: number;
	editIndicator: number;
	deleteIndicator: number;
	primaryIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	systemIndicatorId: number;
}

export interface IPartyTemplate {
	divID: number;
	charTypeID: number;
	templateID: number;
	partyID: number;
	sourcePartyID: number;
	partyName: string;
	partyGroupID: number;
	required: number;
	multiple: number;
	visibility: number;
	editable: number;
	deletable?: number;
	primary?: number;
	sequenceNumber: number;
	userID: number;
	validContactTemplates: number[];
}

export interface ICharacteristicTemplateAssociationCollection {
	divisionID: number;
	charTypeID: number;
	templateID: number;
	charTypeAssociation: ICharacteristicTypeAssociation;
	templateAssociation: ITemplateAssociation;
}

export interface ICharacteristicTypeAssociation {
	charTypeDescription: string;
	charTypeID: number;
	charTypeName: string;
	relRecTypeID: number;
}

export interface ITemplateAssociation {
	divisionID: number;
	childCharTypeID: number;
	childCharTypeLabel: string;
	childTemplateID: number;
	childTemplateLabel: string;
	relRecTypeID: number;
	relRecTypeDescription: string;
	selected: boolean;
}

export interface ITemplateChanges {
	charTypeID: number;
	templateID: number;
	childCharTypeID: number;
	childTemplateID: number;
	relRecTypeID: number;
	selected: boolean;
}

export interface ITemplateChangesResponse {
	success: boolean;
	message: string;
}

export interface ITemplateAssociationsPanel {
	panelID: number;
	panelName: string;
}

export interface ICharType {
	charTypeID: number;
	charTypeName: string;
	charTypeTemplates: ITemplateAssociation[];
}

export interface IWFProcessConfig extends IWfProcesses {
	steps: IWFStepConfig;
	actions: IWFAction[];
}

export interface IWFStepConfig {
	stepName: string;
	processName: string;
	lockIndicator: number;
	deleteIndicator: number;
}

export interface IWFAction {
	processID: number;
	divID: number;
	actionID: number;
	actionName: string;
	actionDescription: string;
	pointProcID: number;
	nextPageID: number;
	sequenceNumber: number;
	createdBy: number;
	createdAt: Date;
	updatedBy: number;
	updatedAt: Date;
	docGenIndicator: number;
	stepName: string;
	updateCurrencyConversion?: boolean
}

export interface IWFActionConfig extends IWFAction {
	nextStep: string;
	nextAction: [number, string][];
	updateProcess: string;
	selectedAssociatedRoleLabels?: IWfRole[];
	selectedChainedActions?: ISelectedChainedAction[];
	selectedRoleNotifications?: ISelectedNotification[];
}

export interface ISelectedNotification {
	notificationId: number;
	selectedRoleIds: number[];
	selectedParties: { [partyId: number]: boolean }[];
}

export interface ISelectedChainedAction {
	processId: number;
	processLabel: string;
	nextActionId: number;
	nextActionLabel: string;
}

export interface IWFEditActionConfig extends IWFAction {
	allRoles: IWfRole[];
	allProcesses: IWfProcesses[];
	allSteps: IWfStep[];
	selectedRoles: IWfRole[];
	selectedSteps: [number, number][];
	selectedChainedActions: { [key: string]: number };
	selectedProcessIds: number[];
	selectedRoleNotifications: Dictionary<IWorkflowNotification>;
	selectedNotificationRoleIDs: number[];
	allowActionDuringValidations: boolean;
	allowActionWithBlockers: boolean;
}

export interface IWFEditActionsPartyListAndExtsResponse {
	wfEditActionConfig: IWFEditActionConfig;
	partyWithNotifs: IPartyWithNotif[];
	wfExtensionResult: IWorkflowActionExtensionResponse;
}

export interface IWFActionSaveRequest {
	charTypeId: number;
	actionId: number;
	actionName: string;
	actionDescription: string;
	docGenIndicator: number;
	pointProcId: number;
	processId: number;
	selectedChainedActionIds: Dictionary<[number, number]>;
	selectedNotificationIds: Dictionary<[number, number]>;
	selectedPartyNotifications: IPartyNotification[];
	selectedStepIds: Dictionary<[number, number]>;
	selectedRoleIds: number[];
	selectedPartyIds?: number[];
	pathId?: number;
	sequenceNumber?: number;
	divisionId?: number;
	createdBy?: number;
	allowActionDuringValidations: boolean;
	allowActionWithBlockers: boolean;
	updateCurrencyConversion?: boolean;
}

export interface IWFUpdatePointProcIdRequest {
	charTypeId: number;
	processId: number;
	actionId: number;
	pointProcId: number;
}

export interface ICharTemplateDataCountRequest {
	charTypeId: number;
	templateId: number;
	characteristicId: number;
}
export interface IWFAssocAction {
	divID: number;
	processID: number;
	actionID: number;
}

export interface IWFActionAndStepRoleAssocsResponse {
	wfAssocAction: IWFAssocAction[];
	wfStepRoleAssoc: IWfRoleStepAssociation[];
}

export interface IWFStepsActionsRolesAndAssocsResponse {
	wfActionConfig: IWFActionConfig[];
	wfRoles: IWfRole[];
	wfActionAndStepRoleAssocsUx2Response: IWFActionAndStepRoleAssocsResponse;
}

export interface IWFParty {
	divisionID: number;
	charTypeID: number;
	templateID: number;
	partyID: number;
	partyGroupID: number;
	partyName: string;
	partyDescription: string;
	requiredIndicator: number;
	multipleIndicator: number;
	editIndicator: number;
	deleteIndicator: number;
	primaryIndicator: number;
	visibilityIndicator: number;
	sequenceNumber: number;
	createdBy: number;
	createdAt: Date;
	updatedBy: number;
	updatedAt: Date;
	systemIndicatorId: number;
}

export interface ITemplateFieldPolicySummaryModel {
	charTypeId: number;
	templateGroupId: number;
	templateId: number;
	charGroupId: number;
	charId?: number;
}

export interface ITemplateFieldPolicySummaryResponse {
	policies: ISecurityPolicy[];
	permissions: Dictionary<[string, ISecurityPolicyPermission]>
}


export enum SyncAndAvailsOptions {
	Invalid = -1,
	NoSyncNoAvails = 1,
	YesSyncYesAvails = 2,
	NoSyncYesAvails = 3
}

export enum PanelKey {
	CharTypeTemplates = 0,
	Modules = 1,
	Components = 2
}

export enum RecordType {
	Modules = null,
	Contacts = 2,
	Projects = 16,
	Catalog = 1,
	Deals = 4,
	Inventory = 17,
	Jobs = 15,
	Accounting = 7,
	Components = null,
	Rights = 3,
	Tables = 5,
	Amounts = 10,
	Royalties = 13,
	Documents = 14,
	ContractScope = 18
}

export enum UsedIn {
	Unused = 0,
	Used = 1,
	All = 2
}

export enum DocumentOptions {
	none = 0,
	generatesDoc = 1,
	sendForEsig = 2,
	esigComplete = 3,
	sendForEsigPartiesOnly = 4
}

export enum DealScopedOptions {
	Disabled = 0,
	Enabled = 1,
	OneToOne = 2
}

export enum RevisionStatus {
	InSetup = 1,
	Complete = 2,
	Approved = 3,
	InRevision = 4
}
