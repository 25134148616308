<div class="modal-header" data-cy="NewCharModalHeader">
	<h2 class="modal-title">New {{template?.templateName}}</h2>
	<button class="btn btn-close" (click)="cancel()" data-cy="ModalCloseButton" aria-label="Close">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="modal-body scroll-vert" data-cy="NewCharModalBody">
	<aw-wizard>
		<aw-wizard-step>
			<div class="d-inline-flex align-items-center mb-5" *ngIf="availableTemplates.length > 1">
				<h4 class="text-nowrap mb-0" for="templateSelector">Select the Template:</h4>
				<select id="templateSelector" class="form-select ms-2" [formControl]="chooseTemplate"
					(change)="changeTemplate()" data-cy="TemplateSelectorDropdown">
					<option *ngFor="let t of availableTemplates" [value]="t.templateID"
						[selected]="template.templateID === t.templateID" [attr.data-cy]="t.templateID">
						{{t.templateName}}
					</option>
				</select>
			</div>
			<ng-container *ngIf="charDataLoaded">
				<rl-component-relationships *ngIf="!isRelative" [charTypeId]="charTypeId"
					[templateId]="template.templateID" [defaultSelectStateDict]="selectedStateDictionary"
					(onRelationshipStateUpdated)="updateRelationshipStates($event)">

				</rl-component-relationships>
				<rl-char-data-table #table [charData]="emptyCharData" [template]="templateMetaData"
					[isRelative]="isRelative" [editMode]="editMode" [parentEntityId]="parentEntityId"
					(tableCharDataChange)="charData=$event.charData" [isVariesByCatalog]="isVariesByCatalog">
				</rl-char-data-table>
			</ng-container>
			<div *ngIf="!charDataLoaded" class="text-center m-2">
				<rl-loader></rl-loader>
			</div>
		</aw-wizard-step>
		<aw-wizard-step>
			<rl-varies-by-catalog-wizard-step *ngIf="wizardStep === 1 && charDataLoaded" [charData]="charData"
				[templateMetaData]="templateMetaData" [relationships]="relationships">
			</rl-varies-by-catalog-wizard-step>
		</aw-wizard-step>
	</aw-wizard>
</div>

<div class="modal-footer" data-cy="ModalFooter">
	<button class="btn btn-info" (click)="back()" *ngIf="wizardStep !== 0">
		<i class="far fa-chevron-left me-2" data-cy="BackButton"></i> Back
	</button>
	<button class="btn btn-light ms-3" (click)="cancel()" *ngIf="wizardStep === 0"
		data-cy="ModalCancelButton">Cancel</button>
	<button class="btn ms-3" (click)="save()" [class.btn-success]="!isVaried || wizardStep === 1"
		[class.btn-info]="isVaried && wizardStep === 0"
		[disabled]="!isCharDataValid || !areRelsValid || !requiredRelsCheck() || !wizardValidationCheck() || isSaving"
		data-cy="ModalSaveButton">
		<i class="far fa-spinner fa-spin me-2" *ngIf="isSaving"></i>
		{{ isVaried && wizardStep === 0 ? "Next" : "Save" }}
		<i class="far fa-chevron-right ms-2" *ngIf="isVaried && wizardStep === 0"></i>
	</button>
</div>