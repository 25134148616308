import { CdkDragHandle } from "@angular/cdk/drag-drop";
import { AsyncPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CharTypeId } from "rl-common/consts";
import { APKeyValueOption } from "rl-common/models/ap-key-value-option";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ContactRuleType } from "rl-common/services/accounting-processes/models/contact-rule-type";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { IAccountingProcessAccountGroupRule } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group-rule";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SearchService } from "rl-common/services/search/search.service";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AccountingProcesses } from "../accounting-processes.consts";

@Component({
    selector: "rl-account-group-rule",
    templateUrl: "./account-group-rule.component.html",
    styleUrls: ["./account-group-rule.component.scss"],
    imports: [CdkDragHandle, AsyncPipe]
})

export class AccountGroupRuleComponent implements OnInit, OnDestroy {
	@Input()
	accountGroup: IAccountingProcessAccountGroup;

	@Input()
	rule: IAccountingProcessAccountGroupRule;

	@Input()
	picklistItems: ICharacteristicMetaData[] = [];

	@Input()
	templateItems: APKeyValueOption[] = [];

	@Input()
	templateGroupItems: APKeyValueOption[] = [];

	@Input()
	defaultAccounts: [string, string][] = []; //[ID, NAME]

	@Output()
	edit = new EventEmitter();

	@Output()
	delete = new EventEmitter();

	groupId: string;
	picklistLabel: string;
	picklistValueLabel: string;
	contactName: string;
	templateName: string;
	templateGroupName: string;
	defaultAccountName: string;

	get contactRuleType() {
		return ContactRuleType;
	}

	get getContactRuleTypeName() {
		let ruleTypeName = "";
		switch (this.rule.contactRuleType) {
			case ContactRuleType.FieldValue:
				ruleTypeName = "Field Value";
				break;
			case ContactRuleType.Record:
				ruleTypeName = "Record";
				break;
			case ContactRuleType.Template:
				ruleTypeName = "Template";
				break;
			case ContactRuleType.TemplateGroup:
				ruleTypeName = "Template Group";
				break;
		}
		return ruleTypeName;
	}

	get getPicklistLabel() {
		if (this.picklistLabel) {
			return this.picklistLabel;
		} else {
			this.picklistLabel = this.picklistItems?.find(p => p.charValueSetID === this.rule.charValueSetId || p.charValueSourceID === this.rule.charValueSourceId)?.label;
		}
	}

	get getPicklistValueLabel() {
		if (this.picklistValueLabel) {
			return this.picklistValueLabel;
		} else if (this.rule.contactRuleType === ContactRuleType.FieldValue && this.rule.characteristicValueId !== 0) {
			const picklistValueItems = this._oneConfig.getLovMetaData(this.rule.charValueSourceId).listOfValues?.map(v => AccountingProcesses.KeyValueOptionMapping.fromCharacteristicMetaDataValue(v)).sort((a, b) => a.value.localeCompare(b.value));
			this.picklistValueLabel = picklistValueItems?.find(pv => pv.id === this.rule.characteristicValueId)?.value;
		}
	}

	get getTemplateName() {
		if (this.templateName) {
			return this.templateName;
		} else {
			this.templateName = this.templateItems.find(t => t.id === this.rule.templateId)?.value;
		}
	}

	get getTemplateGroupName() {
		if (this.templateGroupName) {
			return this.templateGroupName;
		} else {
			this.templateGroupName = this.templateGroupItems.find(tg => tg.id === this.rule.templateGroupId)?.value;
		}
	}

	get getDefaultAccountName() {
		if (this.defaultAccountName) {
			return this.defaultAccountName;
		} else {
			if (this.defaultAccounts.length > 0) {
				const defaultAccount = this.defaultAccounts?.find(da => da[0] === this.rule.accountId);
				if (defaultAccount) {
					this.defaultAccountName = defaultAccount[1];
				}
			}
		}
	}

	_subs: Subscription[] = [];

	contactName$: Observable<string>;

	constructor(
		private readonly _oneConfig: OneConfigService,
		private readonly _searchService: SearchService
	) { }

	ngOnInit(): void {
		this.groupId = this.accountGroup.id;

		this.contactName$ = this._searchService.getTitles(CharTypeId.User, [this.rule.recordId]).pipe(
			map(result => {
				return result.get(this.rule.recordId);
			})
		);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	editRule() {
		this.edit.emit();
	}

	deleteRule() {
		this.delete.emit();
	}
}
