import { Injectable } from "@angular/core";
import { RlHttpClient } from "../rl-http-client.service";
import { IWorkflowConfigRole, IWorkflowConfigProcessNotification } from "./workflow-config.models";

@Injectable({ providedIn: "root" })
export class WorkflowConfigService {

	constructor(private readonly _httpClient: RlHttpClient) { }

	roles() {
		return this._httpClient
			.get(`/workflow-config/roles/`)
			.fetch<IWorkflowConfigRole[]>();
	}

	notifications(charTypeId: number, templateId: number) {
		return this._httpClient
			.get(`/workflow-config/notifications/`)
			.params({
				charTypeId,
				templateId
			})
			.fetch<IWorkflowConfigProcessNotification[]>();
	}

}
