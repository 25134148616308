import { Injectable } from "@angular/core";

import { ApplicationInfoResponse } from "../company/company.models";
import { RlHttpClient } from "../rl-http-client.service";

@Injectable({ providedIn: "root" })
export class ForgotPasswordService {
	constructor(private readonly _httpClient: RlHttpClient) {
	}

	forgotPassword(email: string) {
		const result = this._httpClient
			.patch("/forgot-password")
			.body({
				email: email
			})
			.fetch();

		return result;
	}

	resetPassword(token: string, password: string) {
		const result = this._httpClient
			.patch("/reset-password")
			.body({
				token: token,
				password: password
			})
			.fetch();

		return result;
	}

	getUserDivApplications(token: string) {
		const result = this._httpClient
			.patch("/user-apps")
			.body({
				token: token
			})
			.fetch<ApplicationInfoResponse>();
		return result;
	}
}
