<form *ngIf="bulkCreateAmountsForm" [formGroup]="bulkCreateAmountsForm">
	<rl-bulk-create-amounts-form-errors [bulkConfig]="bulkConfig" [form]="bulkCreateAmountsForm"
		[dataSource]="dataSource">
	</rl-bulk-create-amounts-form-errors>
	<div class="d-flex justify-content-start bg-light p-3 mb-4">
		<div class="me-4">
			<rl-child-template-selector [templateId]="templateId" [charTypeId]="bulkConfig.charTypeId"
				[childCharTypeId]="amountCharTypeId" (onTemplateChange)="updateMetaData($event)"
				formControlName="template">
				Amount Type
			</rl-child-template-selector>
		</div>

		<div class="d-flex align-items-center me-4">
			<label class="title--overline mb-0" for="numRowsRadio"># of Rows</label>
			<input class="form-control w-50 ms-2" id="numRowsRadio" formControlName="numRows" type="number" value="1"
				(change)="emitEvent()" data-cy="BulkCreateAmountNumberOfRows" />
		</div>

		<div class="d-flex align-items-center">
			<input id="perCatalogItemRadio" class="form-check-input me-1" formControlName="perCatalogItem" type="checkbox"
				(change)="emitEvent()" data-cy="PerCatalogItemCheckbox" />
			<label class="form-check-label ms-1" for="perCatalogItemRadio">per Catalog item</label>
			<span class="help ms-2">
				<i class="far fa-question-circle" [ngbPopover]="perCatalogHelp"></i>
			</span>
		</div>
	</div>

	<div class="row mb-5">
		<div class="col-lg-6">
			<h4 class="mb-3">Split Table Row's Total</h4>
			<div class="d-flex">
				<div class="me-4">
					<div class="d-flex align-items-center mb-2">
						<input class="form-check-input" type="radio" formControlName="splitType" [value]="1"
							id="evenlyRadio" [attr.disabled]="!totalFeeConfigured || hasNoFee" (change)="emitEvent()"
							data-cy="SplitTableRowEvenly" />
						<label class="form-check-label ms-1" for="evenlyRadio">Evenly</label>
					</div>
					<div class="d-flex align-items-center mb-2">
						<input class="form-check-input" type="radio" formControlName="splitType" [value]="2"
							id="unevenlyByPercentageRadio" [attr.disabled]="!totalFeeConfigured || hasNoFee"
							data-cy="SplitTableRowUnevenlyByPercent" />
						<label class="form-check-label ms-1" for="unevenlyByPercentageRadio">Unevenly by
							percentage</label>
					</div>
					<div class="d-flex align-items-center mb-2">
						<input class="form-check-input" type="radio" formControlName="splitType"
							[attr.disabled]="isAmountDisabled" [value]="3" id="unevenlyByAmountRadio"
							(change)="emitEvent()" data-cy="SplitTableRowUnevenlyByAmount" />
						<label class="form-check-label ms-1" for="unevenlyByAmountRadio">Unevenly by amount</label>
					</div>
					<div class="d-flex align-items-center">
						<input class="form-check-input" type="radio" formControlName="splitType" [value]="0"
							id="splitTypeLeaveBlankRadio" (change)="emitEvent()" data-cy="SplitRowLeaveBlankSetLater" />
						<label class="form-check-label ms-1" for="splitTypeLeaveBlankRadio">Leave blank / Set
							later</label>
					</div>
				</div>
				<div>
					<div [hidden]="splitType.value !== 2" class="bg-light p-3">
						<strong>Enter Percentages</strong>
						<rl-uneven-amount-control [numRows]="numRows" [isPercentage]="true"
							formControlName="unevenPercentage" (change)="emitEvent()">
						</rl-uneven-amount-control>
					</div>
					<div [hidden]="splitType.value !== 3" class="bg-light p-3">
						<strong>Enter Amounts</strong>
						<rl-uneven-amount-control [numRows]="numRows" formControlName="unevenAmount"
							(change)="emitEvent()">
						</rl-uneven-amount-control>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-6 mt-sm-5 mt-lg-0">
			<div class="mb-2" [hidden]="isDueDateHidden">
				<h4 class="mb-3">Set Due Dates</h4>
				<div class="d-flex">
					<div class="me-4">
						<div class="d-flex align-items-center mb-2">
							<input class="form-check-input" type="radio" formControlName="dueDateType" [value]="1"
								id="dueDateForEachRow" (change)="emitEvent()" data-cy="SetDueDateForEachRow" />
							<label class="form-check-label" for="dueDateForEachRow">For each row</label>
						</div>
						<div class="d-flex align-items-center mb-2">
							<input class="form-check-input" type="radio" formControlName="dueDateType" [value]="2"
								id="dueDateRecurrenceRadio" (change)="emitEvent()" data-cy="SetDueDateRecurrence" />
							<label class="form-check-label" for="dueDateRecurrenceRadio">Recurrence</label>
						</div>
						<div class="d-flex align-items-center">
							<input class="form-check-input" type="radio" formControlName="dueDateType" [value]="0"
								id="leaveBlankDueDateRadio" (change)="emitEvent()" data-cy="SetDueDateLeaveBlank" />
							<label class="form-check-label" for="leaveBlankDueDateRadio">Leave blank / Set later</label>
						</div>
					</div>
					<div>
						<div [hidden]="dueDateType !== 1" class="bg-light p-3">
							<strong>Enter Date for Each Row</strong>
							<rl-uneven-date-control [numRows]="numRows" formControlName="unevenDate"
								(change)="emitEvent()">
							</rl-uneven-date-control>
						</div>

						<div [hidden]="dueDateType !== 2" class="bg-light p-3">
							<strong>Set Recurrence</strong>
							<select class="form-select form-control-sm mt-2"
								formControlName="recurrence">
								<option [ngValue]="null" disabled>Select</option>
								<option *ngFor="let option of recurrenceOptions" [ngValue]="option.value">
									{{option.label}}
								</option>
							</select>
							<div class="my-2 ms-2"><em>beginning</em></div>
							<rl-date-input formControlName="recurrenceDate">
							</rl-date-input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex align-items-center mb-3">
		<input type="checkbox" class="form-check-input" value="true" formControlName="copyFromTables"
			data-cy="CopyValuesFromTableCheckbox" />
		<label class=" form-check-label ms-1">Copy values from Table to Amounts, for matching fields.</label>
	</div>
</form>

<ng-template #perCatalogHelp>
	<h4>Bulk Create Amounts Per Catalog</h4>
	<p>If selected, total will be split evenly among all Catalog Items on each Table row, and can be further split, per
		Catalog.</p>
	<p>If not selected, no Catalog Items will be associated to the new Amounts.</p>
</ng-template>