<div class="container-fluid" data-cy="VariesByCatalogOuterContainer">
	<div data-cy="VariesByCatalogInnerContainer">
		<table *ngIf="headers.length > 0" class="table rl-table table-striped">
			<thead *ngIf="!hideExclusivity" class="exclusivityHeaderRow">
				<tr>
					<th></th>
					<th [attr.colspan]="headers.length" *ngIf="!hideExclusivity">If Exclusive</th>
					<th [attr.colspan]="headers.length">If Non-Exclusive</th>
				</tr>
			</thead>
			<thead>
				<tr class="editHeaderRow">
					<th>Catalog</th>

					<!-- Exclusive Header -->
					<ng-container *ngIf="!hideExclusivity">
						<th *ngFor="let header of headers" [attr.data-cy]="header.label + '_ExclusiveHeader'">
							{{ header.label }}
							<button class="btn btn-sm btn-icon btn-info" title="Edit Column"
								(click)="showExclusiveBulkEdit[header.tagLabel] = true"
								*ngIf="!showExclusiveBulkEdit[header.tagLabel]">
								<i class="far fa-pencil fa-sm"
									[attr.data-cy]="header.label + '_ExclusiveEditButton'"></i>
							</button>

							<div *ngIf="showExclusiveBulkEdit[header.tagLabel]" class="mt-2">
								<rl-varies-by-catalog-char-data-element-edit [cmd]="header"
									[initialCharDatas]="charData" [charData]="getChar(-1, true, header)" [recordId]="-1"
									[isExclusive]="true" [template]="templateMetaData"
									(elementDataChanged)="elementDataChanged(-1, true, $event)" #variesByElement>
								</rl-varies-by-catalog-char-data-element-edit>

								<div *ngIf="canApplyExclusiveEdit[header.tagLabel]"
									class="d-flex justify-content-start mb-2">
									<button class="btn btn-sm" title="Apply"
										(click)="applyBulkEdit(true, header.tagLabel, variesByElement.instanceId)"
										[disabled]="isCorrectingRels[header.tagLabel]">
										<i *ngIf="isCorrectingRels[header.tagLabel]"
											class="far fa-spinner fa-spin me-2"></i>
										<i class="far fa-check fa-sm text-success"
											[attr.data-cy]="header.label + '_ExclusiveApplyButton'"></i>
									</button>
									<button class="btn btn-sm btn-remove" title="Cancel"
										(click)="showExclusiveBulkEdit[header.tagLabel] = false"
										[disabled]="isCorrectingRels[header.tagLabel]">
										<i class="far fa-times fa-sm"
											[attr.data-cy]="header.label + '_ExclusiveCancelButton'"></i>
									</button>
								</div>
							</div>
						</th>
					</ng-container>

					<!-- Non-Exclusive Header -->
					<th *ngFor="let header of headers; first as isFirst"
						[class.border-start]="!hideExclusivity && isFirst" [class.ps-3]="!hideExclusivity && isFirst"
						[attr.data-cy]="header.label + '_NonExclusiveHeader'">
						{{ header.label }}
						<button class="btn btn-sm btn-icon btn-info"
							(click)="showNonExclusiveBulkEdit[header.tagLabel] = true"
							*ngIf="!showNonExclusiveBulkEdit[header.tagLabel]" title="Edit">
							<i class="far fa-pencil fa-sm"
								[attr.data-cy]="header.label + '_NonExclusiveEditButton'"></i>
						</button>

						<div *ngIf="showNonExclusiveBulkEdit[header.tagLabel]" class="mt-2">
							<rl-varies-by-catalog-char-data-element-edit [cmd]="header" [initialCharDatas]="charData"
								[charData]="getChar(-1, false, header)" [recordId]="-1" [isExclusive]="false"
								[template]="templateMetaData"
								(elementDataChanged)="elementDataChanged(-1, false, $event)" #variesByElement>
							</rl-varies-by-catalog-char-data-element-edit>

							<div *ngIf="canApplyNonExclusiveEdit[header.tagLabel]"
								class="d-flex justify-content-start mb-2">
								<button class="btn btn-sm" title="Apply"
									(click)="applyBulkEdit(false, header.tagLabel, variesByElement.instanceId)"
									[disabled]="isCorrectingRels[header.tagLabel]">
									<i *ngIf="isCorrectingRels[header.tagLabel]"
										class="far fa-spinner fa-spin me-2"></i>
									<i class="far fa-check text-success"
										[attr.data-cy]="header.label + '_NonExclusiveApplyButton'"></i>
								</button>
								<button class="btn btn-sm btn-remove" title="Cancel"
									(click)="showNonExclusiveBulkEdit[header.tagLabel] = false"
									[disabled]="isCorrectingRels[header.tagLabel]">
									<i class="far fa-times"
										[attr.data-cy]="header.label + '_NonExclusiveCancelButton'"></i>
								</button>
							</div>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let catalog of taggedCatalogs; index as i">
					<td>{{ catalog.title }}</td>

					<!-- Exclusive Char Data -->
					<ng-container *ngIf="!hideExclusivity">
						<td *ngFor="let header of headers" [class.has-error]="hasError(catalog.recordId, true)"
							[attr.data-cy]="catalog.title + '_ExclusiveCharData'">
							<rl-varies-by-catalog-char-data-element-edit [cmd]="header" [initialCharDatas]="charData"
								[charData]="exclusiveCharDataCollection[catalog.recordId][header.characteristicID]"
								[recordId]="catalog.recordId" [isExclusive]="true" [template]="templateMetaData"
								(elementDataChanged)="elementDataChanged(catalog.recordId, true, $event)">
							</rl-varies-by-catalog-char-data-element-edit>
						</td>
					</ng-container>

					<!-- Non-Exclusive Char Data -->
					<td *ngFor="let header of headers; first as isFirst"
						[class.border-start]="!hideExclusivity && isFirst" [class.ps-3]="!hideExclusivity && isFirst"
						[class.has-error]="hasError(catalog.recordId, false)"
						[attr.data-cy]="catalog.title + '_NonExclusiveCharData'">
						<rl-varies-by-catalog-char-data-element-edit [cmd]="header" [initialCharDatas]="charData"
							[charData]="nonExclusiveCharDataCollection[catalog.recordId][header.characteristicID]"
							[recordId]="catalog.recordId" [isExclusive]="false" [template]="templateMetaData"
							(elementDataChanged)="elementDataChanged(catalog.recordId, false, $event)">
						</rl-varies-by-catalog-char-data-element-edit>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="mt-3 d-flex justify-content-end">
		<div>
			<div class="mb-1">
				<label class="form-check-label">
					<input class="form-check-input" type="radio" [value]="false" [(ngModel)]="optimizeRights"
						data-cy="SeparateRightsButton">
					Create a separate Rights record for each exclusive and non-exclusive input
				</label>
			</div>
			<div>
				<label class="form-check-label">
					<input class="form-check-input" type="radio" [value]="true" [(ngModel)]="optimizeRights"
						data-cy="OptimizeFewestRightsButton">
					Optimize where possible to create the fewest number of Rights records
				</label>
			</div>
		</div>
	</div>
</div>