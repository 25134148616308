import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { isEmpty } from "lodash";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { ChipComponent } from "../chip.component";

export interface IChipDeselectedEvent<ChipId, ChipValue> {
	isNewRow: boolean;
	isSelectAll: boolean;
	id?: ChipId;
	value?: ChipValue;
}

export interface IChipWithTitle<T> {
	title: string;
	chip: T;
}

@Component({
	selector: "rl-chips",
	templateUrl: "./chips.component.html",
	styleUrls: ["./chips.component.scss"],
	imports: [NgIf, ChipComponent, NgFor]
})
export class ChipsComponent implements OnInit, OnChanges {
	@Input()
	newRecordTitle: IRecordTitle;

	@Input()
	displayFn: (chip: unknown) => string;

	@Input()
	idFn: (chip: unknown) => number | string;

	@Input()
	selectedState: GridSelectState<number, IEntitySearchDoc>;
	selectedStateChips: IChipWithTitle<IEntitySearchDoc>[] = [];

	@Input()
	records: unknown[] = [];
	recordChips: IChipWithTitle<unknown>[] = [];

	@Input()
	selectedCount: number;

	@Output()
	onDeselected = new EventEmitter<IChipDeselectedEvent<unknown, unknown>>();

	constructor() { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.selectedState && this.selectedState && !this.selectedState.isAllSelected) {
			this.selectedStateChips = Array.from(this.selectedState.selectedValues).map(val => {
				return <IChipWithTitle<IEntitySearchDoc>>{ title: this.buildTitle(val), chip: val };
			});
		}
		if (changes.records && this.records && !isEmpty(this.records)) {
			this.recordChips = this.records.map(rec => {
				return <IChipWithTitle<unknown>>{ title: this.buildTitle(rec), chip: rec };
			});
		}
		if (this.selectedCount < 1) {
			this.recordChips = [];
		}
	}

	buildTitle(chip: unknown) {
		const title = this.displayFn(chip);
		return !isEmpty(title) ? title : `Record ${this.idFn(chip)}`;
	}

	titleNotEmpty(title: string) {
		return title && !isEmpty(title.trim());
	}

	selectAllDeselected() {
		this.onDeselected.emit({ isSelectAll: true, isNewRow: false });
	}

	newRowDeselected() {
		this.onDeselected.emit({ isSelectAll: false, isNewRow: true });
	}

	existingRowDeselected(chip: unknown) {
		const id = this.idFn(chip);
		this.onDeselected.emit({ isSelectAll: false, isNewRow: false, id, value: chip });
	}
}
