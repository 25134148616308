import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cloneDeep } from "lodash";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { ITemplateHierarchy } from "../create-entity.models";

@Component({
	selector: "rl-template-hierarchy-count-item",
	templateUrl: "./template-hierarchy-count-item.component.html",
	styleUrls: ["./template-hierarchy-count-item.component.scss"],
	imports: [ReactiveFormsModule, FormsModule, NgIf, NgFor]
})
export class TemplateHierarchyCountItemComponent implements OnInit {

	@Input()
	template: ITemplateHierarchy;

	@Input()
	charTypeId: number;

	constructor(private readonly _oneConfigService: OneConfigService) { }

	ngOnInit() {
		const templates = this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.template.templateID, this.charTypeId);

		this.template.childTemplates = [];
		templates.forEach(t => {
			const parentTemplates = cloneDeep(this.template.directParentTemplates);
			parentTemplates.push(this.template.templateID);
			const newTemp: ITemplateHierarchy = {
				directParentTemplates: parentTemplates,
				templateID: t.templateID,
				templateLabel: t.templateName,
				childTemplates: null,
				level: this.template.level + 1,
				numberOfRecords: 0
			};

			// ensure that this template doesn't exist in the recursive line of direct parent templates
			if (parentTemplates.indexOf(newTemp.templateID) < 0) {
				this.template.childTemplates.push(newTemp);
			}
		});

	}
}
