import { NgClass } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { EntityService } from "rl-common/services/entity/entity.service";
import { IDocument } from "rl-common/services/entity/models/i-document";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { IPreviewNotificationFileRequest, IPreviewNotificationRequest } from "rl-common/services/workflow/workflow.messages";
import { IWorkflowAction, IWorkflowNotification } from "rl-common/services/workflow/workflow.models";
import { WorkflowService } from "rl-common/services/workflow/workflow.service";
import { Subscription } from "rxjs";
import { FileNamePipe } from "../../../../pipes/file-name.pipe";
import { HtmlEditorComponent } from "../../../html-editor/html-editor.component";
import { IPreviewNotificationResult } from "./preview-notifications.models";

@Component({
    selector: "rl-preview-notifications",
    templateUrl: "./preview-notifications.component.html",
    styleUrls: ["./preview-notifications.component.scss"],
    imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgClass, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, ReactiveFormsModule, FormsModule, HtmlEditorComponent, NgbNavOutlet, FileNamePipe]
})
export class PreviewNotificationsComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	models: ISearchRequestModel[];

	@Input()
	action: IWorkflowAction;

	@Input()
	documents: IDocument[] = [];

	@Input()
	notifications: IWorkflowNotification[] = [];

	@ViewChild('accordion')
	accordion: NgbAccordionDirective;

	@Output()
	onSend = new EventEmitter<string>();

	previews: IPreviewNotificationResult[] = [];

	isExecuting = false;
	showFiles = false;
	subs: Subscription[] = [];

	constructor(private readonly _entityService: EntityService,
		private readonly _workflowService: WorkflowService) { }
	ngOnInit() {
		let files: IPreviewNotificationFileRequest[] = [];
		this.notifications.forEach(notification => {
			if (this.documents) {
				files = this.documents
					.map<IPreviewNotificationFileRequest>(d => {
						const documentRequest: IPreviewNotificationFileRequest = {
							recordId: d.recordId,
							fileUri: d.fileUri,
							selected: false
						};
						return documentRequest;
					});

				notification.attachments.forEach(attachment => {
					const found = files.find(x => x.fileUri.includes(attachment));
					if (found) {
						found.selected = true;
					}
				});
			}
			const previewResult: IPreviewNotificationResult = {
				notification,
				documents: files
			};
			this.previews.push(previewResult);
		});

		if (this.documents?.length > 0) {
			this.showFiles = true;
		}
	}

	ngAfterViewInit() { }

	send() {
		this.isExecuting = true;
		let files: IPreviewNotificationFileRequest[] = [];
		const notifications = this.previews.map<IPreviewNotificationRequest>(preview => {
			if (preview.documents) {
				files = preview.documents.filter(d => d.selected);
			}

			const request: IPreviewNotificationRequest = {
				id: preview.notification.id,
				subject: preview.notification.subject,
				body: preview.notification.body,
				bodyHtml: preview.notification.bodyHtml,
				files
			};

			return request;
		});

		const sub = this._workflowService.executeJobAndSendNotifications(this.charTypeId, this.models, this.action, notifications)
			.subscribe(response => {
				this.isExecuting = false;
				this.onSend.emit(response.jobId);
			});
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
