import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";
import { IEnvironment } from "environments/environment.models";
import { isEmpty } from "lodash";
import moment from "moment";
import { ConstUtils } from "rl-common/consts";
import { GrowlerService } from "rl-common/services/growler.service";
import { SessionService } from "rl-common/services/session.service";
import { IVersionInfo } from "rl-common/services/version/version.models";
import version from "../../../../../assets/version.json";
import { NgIf } from "@angular/common";
import { KonamiDirective } from "../../../directives/konami.directive";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "rl-version-info",
    templateUrl: "./version-info.component.html",
    styleUrls: ["./version-info.component.scss"],
    imports: [NgIf, KonamiDirective, NgbTooltip]
})
export class VersionInfoComponent implements OnInit {

	versionInfo: IVersionInfo = version;
	environment: IEnvironment = environment;

	releaseName: string;
	releaseUrl: string;

	buildDate: moment.Moment;
	isLocal: boolean;

	branch: string;
	branchUrl: string;

	currentSha: string;
	currentShaUrl: string;
	previousSha: string;
	previousShaUrl: string;

	apiVersionMismatch = true;
	apiVersion: string;

	get hasBranch() {
		return !!this.branch && !isEmpty(this.branch) && this.branch !== "unknown";
	}

	constructor(
		private readonly _growlerService: GrowlerService,
		private readonly _sessionService: SessionService
	) {
	}

	ngOnInit() {
		const hostName = window.location.hostname;
		const versionInfo: IVersionInfo = version;

		this.branch = versionInfo.Branch;
		this.branchUrl = `https://github.com/rightsline/rightsline/tree/${this.branch}`;

		this.isLocal = ConstUtils.isLocal(hostName);
		this.buildDate = !this.isLocal ? moment.utc(version.UtcDateBuilt) : null;
		this.releaseName = ConstUtils.getReleaseName(versionInfo, hostName);
		this.releaseUrl = `https://sentry.io/organizations/rightsline/releases/${this.releaseName}/?project=6007786`;

		const githubUrl = "https://github.com/rightsline/rightsline/commits/";
		this.currentSha = versionInfo.CurrentSHA;
		this.currentShaUrl = `${githubUrl}${this.currentSha}`;
		this.previousSha = versionInfo.PreviousSHA;
		this.previousShaUrl = `${githubUrl}${this.previousSha}`;
		this.apiVersion = this._sessionService.apiVersion ?? "";
		this.apiVersionMismatch = this.apiVersion.indexOf(this.currentSha.substring(0, 10)) === -1;
	}

	toggleUx2Links() {
		const newSetting = this._sessionService.toggleUx2Links();
		this._growlerService.success().growl(`Toggled Ux2 Links: ${newSetting ? "Enabled" : "Disabled"}`);
	}

	testSentry() {
		throw Error(`SENTRY TEST`);
	}
}
