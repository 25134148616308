import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { CharTypeId } from "rl-common/consts";
import { IModsLinks } from "rl-common/models/i-mods-links";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { NewTabInModalDirective } from "../../../../directives/new-tab-in-modal.directive";
import { IConflictCheckDealConflicts } from "../validation-errors-modal.models";

@Component({
	selector: "rl-conflict-check-issue",
	templateUrl: "./conflict-check-issue.component.html",
	imports: [NgIf, NewTabInModalDirective, RouterLink]
})
export class ConflictCheckIssueComponent {
	@Input()
	issue: IConflictCheckDealConflicts;

	dealLinkHelper: IModsLinks;
	catalogLinkHelper: IModsLinks;

	constructor(private readonly linkHelper: LinkHelperService) {
		this.dealLinkHelper = linkHelper.mods[CharTypeId.Transaction];
		this.catalogLinkHelper = linkHelper.mods[CharTypeId.Property];
	}
}
