<div class="pt-1 pb-4 pe-4">
	<div class="d-flex justify-content-between align-items-baseline border-bottom amounts__header">
		<h5 class="mb-0">Amounts</h5>
		<rl-custom-views [charTypeId]="amountCharTypeId" [templateId]="amountTemplateId"
			[gridViewColumnStrategy]="amountsDataSource.columnStrategy">
		</rl-custom-views>
	</div>
	<rl-grid *ngIf="(amountsDataSource.indexedRowData$ | async).length > 0" #amountsGrid [options]="amountsGridOptions"
		[dataSource]="amountsDataSource" data-cy="BulkAmountsRLGrid">
		<div *rlCellTemplate="'amount_row_lock'; let cellData">
			<span class="far fa-lock" *ngIf="cellData.lockedIndicator === 1" aria-label="Item Locked" role="img"></span>
		</div>
		<div *rlCellTemplate="'link_invoice_id'; let cellData">
			<a [routerLink]="modLinkHelperInvoices.details(cellData.invoiceRecID)" *ngIf="cellData.invoiceRecID > 0"
				[innerText]="cellData.invoiceRecID"></a>
		</div>
	</rl-grid>
</div>