<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeEditTemplate">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="char-data__group">
				<div *ngIf="_charTypeId === 3 && _templateId">
					<div class="alert alert-warning my-3" style="display: inline-block;">
						Making changes here and saving could result in an automated avails refresh for your instance.
						Please proceed with discretion.</div>
				</div>
				<div class="char-data__group__element" *ngIf="form.controls.templateId">
					<div class="char-data__label pt-2">
						<span>Template ID</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="templateIdContainer">
							<rl-text-input [(formControl)]="form.controls.templateId"
								[displayAsText]="form.controls.templateId"
								[dataLabel]="'templateIdInput'"></rl-text-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element"
					[class.has-error]="form.controls.templateName.invalid && form.controls.templateName.touched">
					<div class="char-data__label pt-2">
						<span>Template Name</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="templateNameContainer">
							<rl-text-input [(formControl)]="form.controls.templateName"
								[dataLabel]="'templateNameInput'"></rl-text-input>
						</div>
						<div *ngIf="form.controls.templateName.errors?.required && form.controls.templateName.touched"
							class="help-block">Template Name is required.</div>
						<div *ngIf="form.controls.templateName.errors?.templateNameDuplicate" class="help-block">
							Duplicate Template Name is not allowed.</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Template Group</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="templateGroupContainer">
							<rl-dropdown-single [(formControl)]="form.controls.templateGroup" [options]="groups"
								[showEmptyOption]="true" [dataLabel]="'selectTemplateGroup'">
							</rl-dropdown-single>
						</div>
					</div>
				</div>
				<div class="char-data__group__element">
					<div class="char-data__label pt-2">
						<span>Workflow Process</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="workflowProcessContainer">
							<rl-dropdown-single [(formControl)]="form.controls.workflowProcess" [options]="processes"
								[showEmptyOption]="false" [dataLabel]="'selectWorkflowProcess'">
							</rl-dropdown-single>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="_charTypeId === 3">
					<div class="char-data__label pt-2">
						<span>Deal Scoped</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="dealScopedContainer">
							<rl-dropdown-single [(formControl)]="form.controls.dealScoped"
								[options]="dealScopedOptionsList" [showEmptyOption]="false"
								[dataLabel]="'selectDealScoped'">
							</rl-dropdown-single>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="form.controls.systemIndicator">
					<div class="char-data__label pt-2">
						<span>System Indicator</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="systemIndicatorContainer">
							<rl-number-input [formControl]="form.controls.systemIndicator"
								[dataLabel]="'systemIndicatorInput'"></rl-number-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="_charTypeId === 3">
					<div class="char-data__label pt-2">
						<span>Calc Sequence</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="calcSequenceContainer">
							<rl-number-input [formControl]="form.controls.calcSequence"
								[dataLabel]="'calcSequenceInput'"></rl-number-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="form.controls.charTypeId.value === 3">
					<div class="char-data__label pt-2">
						<span>Color</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="templateColorContainer">
							<rl-color-picker-input [formControl]="form.controls.visualization"></rl-color-picker-input>
						</div>
					</div>
				</div>
				<div class="char-data__group__element" *ngIf="isDeal">
					<div class="char-data__label pt-2">
						<span>Amortization Model</span>
					</div>
					<div class="char-data__control container m-0">
						<div class="char-data-element-display" data-cy="templateAmortContainer">
							@if(amortOptions.items.length > 0) {
								<rl-dropdown-multiple [formControl]="form.controls.selectedAmortModels" [options]="amortOptions" [showEmptyOption]="true">
								</rl-dropdown-multiple>
							} @else {
								<label>
									<input type="checkbox" class="form-check-input" [ngModel]="true" [disabled]="true" [ngModelOptions]="{standalone: true}" />
									Straight Line
								</label>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelTemplateEdit">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveTemplateEdit">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</ng-template>