<p class="acctOperationPanel__trigger mb-0">
	<em>({{ triggerTypeName }})</em>:
	<ng-container [ngSwitch]="trigger.triggerType">
		<ng-container *ngSwitchCase="0">
			When a <strong>{{ anchorTemplateLabel }}</strong> record is associated to a <strong>{{
				accountingTemplateLabel }}</strong> Accounting template.
		</ng-container>
		<ng-container *ngSwitchCase="1">
			When <strong>{{ workflowActionLabel }}</strong> Workflow Action is taken on a <strong>{{
				anchorTemplateLabel }}</strong> record.
		</ng-container>
		<ng-container *ngSwitchCase="2">
			When <strong>{{ fieldLabel }}</strong> on <ng-container *ngIf="isTableCharTypeId">parent </ng-container> <strong>{{ dataBehaviorTemplateLabel }}</strong> {{ operatorLabel
			}}<ng-container [ngSwitch]="valueEntryType">
				<ng-container *ngSwitchCase="'none'">
					<!-- EMPTY -->
				</ng-container>
				<ng-container *ngSwitchCase="'single'">
					<strong class="ms-1">
						<rl-data-behavior-single-value-entry [editMode]="false" [control]="valueControl" [cmd]="cmd">
						</rl-data-behavior-single-value-entry>
					</strong>
				</ng-container>
				<ng-container *ngSwitchCase="'range'">
					<div class="ms-1 d-inline-block">
						<strong class="ms-1">
							<rl-data-behavior-single-value-entry [editMode]="false" [control]="range1Control"
								[cmd]="cmd"></rl-data-behavior-single-value-entry>
						</strong>
						and
						<strong>
							<rl-data-behavior-single-value-entry [editMode]="false" [control]="range2Control"
								[cmd]="cmd"></rl-data-behavior-single-value-entry>
						</strong>
					</div>
				</ng-container>
			</ng-container>.
		</ng-container>
		<!-- Record Creation -->
		<ng-container *ngSwitchCase="3">
			When a <strong>{{ amountTemplateLabel }}</strong> is created on a <strong>{{ tableTemplateLabel }}</strong>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<em>Unknown Trigger Type</em>
		</ng-container>
	</ng-container>
</p>