<h4 class="mt-3">
	Set to {{ getConflictActionLabel() }}
</h4>

<rl-grid [options]="gridOptions" [dataSource]="dataSource" data-cy="ConflictCheckStatusRLGrid">
	<div class="text-truncate" *rlCellTemplate="'date-range'; let cellData">
		{{cellData.startDate | localeDate }} to {{cellData.endDate | localeDate}}
	</div>

	<div class="text-truncate" *rlCellTemplate="'conflict-check-issue'; let cellData">
		<rl-conflict-check-issue [issue]="cellData"></rl-conflict-check-issue>
	</div>

	<div *rlCellTemplate="'reason-for-change'; let cellData">
		<input type="text" class="form-control" placeholder="{{ bulkReasonForChange || 'Reason for Change' }}"
			[(ngModel)]="reasonForChange[cellData.id]" />
	</div>
</rl-grid>

<div class="modal-footer mt-5">
	<button class="btn btn-light ms-auto" (click)="cancel()">
		Back
	</button>
	<button class="btn btn-success ms-2" (click)="save()" [disabled]="!canUpdateConflictCheckStatus()"
		rlBusyButton>Save</button>
</div>