import { AsyncPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { isEqual } from "lodash";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { BitmapDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/bitmap-data-select.strategy";
import { AssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { IndexedRowData } from "rl-common/components/grid/models/indexed-row-data";
import { SearchOptionsFactory } from "rl-common/factories";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IQueryNode } from "rl-common/models/i-query-node";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { ISelectionSearchRequestModel } from "rl-common/services/search/search.models";
import { SearchService } from "rl-common/services/search/search.service";
import { Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { ChipsBitmapComponent } from "../../../chip/chips-bitmap/chips-bitmap.component";
import { EntitySearchComponent } from "../../../entities/entity-search/entity-search.component";
import { CharTypeId, SystemIndicators } from "./../../../../rl-common.consts";
import { OneConfigService } from "./../../../../services/one-config/one-config.service";
import { EntitySearchDataSource } from "./../../../grid/datasource/search/entity-search.datasource";
import { InputListComponent, IValidateEvent } from "./input-list/input-list.component";
import { ISelectEntityRelationshipStateEvent } from "./models/i-select-entity-relationship-state-event";


@Component({
	selector: "rl-component-relationship-bitmap",
	templateUrl: "./component-relationship-bitmap.component.html",
	styleUrls: ["./component-relationship-bitmap.component.scss"],
	imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, EntitySearchComponent, InputListComponent, NgbNavOutlet, ChipsBitmapComponent, AsyncPipe]
})
export class ComponentRelationshipBitmap implements OnInit, OnDestroy, OnChanges {
	@Input()
	assocCharTypeId: number;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	dataSource: EntitySearchDataSource<IEntitySearchDoc, unknown>;

	@Input()
	selectedDirection: RelationshipTypes;

	@Output()
	onEntityRelationshipChange = new EventEmitter<ISelectEntityRelationshipStateEvent<number, IEntitySearchDoc>>();

	isTablesOrRights = false;
	private readonly _subs: Subscription[] = [];
	selectedInputList: IEntitySearchDoc[] = [];
	searchOptions: SearchOptions = {} as SearchOptions;
	parentCharTypeId: CharTypeId;
	parentRecordId: number;
	direction: RelationshipTypes;
	matchesParentQuery: IQueryNode;
	selectedBitmap: string = "";
	selectedCount: number = 0;

	get isAssociatingChild() {
		return this.direction === this.selectedDirection;
	}


	constructor(
		private readonly _parentEntityService: ParentEntityService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _searchService: SearchService
	) { }

	ngOnInit(): void {
		this.buildSearchOptions();
		this.isTablesOrRights = this.assocCharTypeId === CharTypeId.Right || this.assocCharTypeId === CharTypeId.Usage;
		if (this.charTypeId === CharTypeId.Usage && this.assocCharTypeId === CharTypeId.User) {
			const template = this._oneConfigService.getTemplate(this.charTypeId, this.templateId);
			if (template.systemIndicator === SystemIndicators.UsageDeductionsTemplate) {
				// TODO: support radio selection
				// this.dataSelectStrategy.withSelectType(GridSelectType.Radio);
			}
		}

		const selectStateChangeSub = this.dataSelectStrategy.selectStateBitmapChange$.pipe(
			filter(state => !!state.selectedIdsBitmap),
			distinctUntilChanged((a, b) => a.selectedIdsBitmap === b.selectedIdsBitmap)
		).subscribe((state) => {
			this.selectedBitmap = state.selectedIdsBitmap;
			this.selectedCount = state.size;
			this.emit();
		});

		const valueChangesSub = this.dataSource.formGroup.valueChanges.pipe(
			distinctUntilChanged((a, b) => isEqual(a, b))
		).subscribe((e) => this.emit());

		this._subs.push(selectStateChangeSub, valueChangesSub);
	}

	private selectedQuery() {
		const model: ISelectionSearchRequestModel = {
			start: 0,
			query: {},
			filterQueries: [],
			rows: this.selectedCount,
			selectedBitmap: this.selectedBitmap
		}
		return model;
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.dataSource && changes.dataSource.currentValue && changes.dataSource.currentValue instanceof AssocModuleSelectDataSource) {
			const assocDataSource = changes.dataSource.currentValue as AssocModuleSelectDataSource<any, any>;
			if (assocDataSource.direction !== undefined && assocDataSource.direction !== null) {
				this.direction = assocDataSource.direction;
			}
		}
	}

	get dataSelectStrategy() {
		return this.dataSource.dataSelectStrategy as BitmapDataSelectStrategy<IEntitySearchDoc>;
	}

	private buildSearchOptions() {
		this.searchOptions = this.getSearchOptions();
		let parentTemplateId = null;
		if (this.assocCharTypeId === CharTypeId.Mock) {
			this.searchOptions.charTypeId = this.assocCharTypeId;
		} else if (this._parentEntityService.hasGrandParent()) {
			this.searchOptions.parentCharTypeId = this._parentEntityService.parent.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.parent.recordId;
			parentTemplateId = this._parentEntityService.parent.templateId;
			this.searchOptions.relatedCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.relatedRecordId = this._parentEntityService.recordId;
		} else {
			this.searchOptions.parentCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.recordId;
		}
		const validTemplates = this._oneConfigService.getChildAssocTemplateIds(this.searchOptions.parentCharTypeId, parentTemplateId, this.assocCharTypeId);
		this.searchOptions.filteredTemplateIds = validTemplates;
		if (this.searchOptions.parentCharTypeId === CharTypeId.Relationship && this.searchOptions.charTypeId === CharTypeId.Amount) {
			this.searchOptions.selectType = SelectType.Radio;
		}
		this.parentCharTypeId = this.searchOptions.parentCharTypeId;
		this.parentRecordId = this.searchOptions.parentRecordId;
	}

	private getSearchOptions() {
		return SearchOptionsFactory.buildTaggedRelationshipOptions(this._parentEntityService.charTypeId, this.assocCharTypeId, this.searchOptions.relatedCharTypeId);
	}

	public updateValidated(event: IValidateEvent) {
		const removedRows = event.deselectedRecords ?? [];
		removedRows.forEach(x => {
			this.dataSelectStrategy.deselectRowById(x.recordID, x.recordID);
		});
		event.validRecords.map(rec => {
			const selectedRow: IndexedRowData<IEntitySearchDoc> = {
				index: rec.recordID,
				rowPath: null,
				data: rec
			};
			return selectedRow;
		}).forEach(x => {
			this.dataSelectStrategy.selectRow(x);
		});
	}

	private emit() {
		const isValid = !this.dataSource.formGroup.hasError("requiredGrid");
		const model = this.selectedQuery(); // take the search model from the moment the select/deselect all was used

		this.onEntityRelationshipChange.emit({
			inputListSelected: [],
			model,
			selectedBitmap: this.selectedBitmap,
			isValid,
			selectedCount: this.selectedCount,
			labelFn: (x) => x.title
		});
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
