import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataDefaultSetService } from "rl-common/services/data-default-set/data-default-set.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { PluralCharTypeNamePipe } from "../../../pipes/plural-char-type-name.pipe";

@Component({
    selector: "rl-create-data-default-modal",
    templateUrl: "./create-data-default-modal.component.html",
    imports: [ReactiveFormsModule, FormsModule, PluralCharTypeNamePipe]
})
export class CreateDataDefaultModalComponent implements OnInit {
	@Input()
	model: ISearchRequestModel;

	@Input()
	charTypeId: number;

	@Input()
	setCharTypeId: number;

	@Output()
	onApply = new EventEmitter();

	title: string;

	constructor(private readonly activeModal: NgbActiveModal, private readonly dataDefaultService: DataDefaultSetService) { }

	ngOnInit() {

	}

	apply() {
		this.dataDefaultService.createDataDefault(this.model, this.charTypeId, this.setCharTypeId, this.title).subscribe(x => {
			this.onApply.emit();
			this.activeModal.dismiss();
		});
	}

	canApply() {
		return !!this.title;
	}

	cancel() {
		this.activeModal.dismiss();
	}

}
