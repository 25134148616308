import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cloneDeep, Dictionary, find, first, flatten, isEmpty, some } from "lodash";
import { BLANK_DATE } from "rl-common/components/date-edit/date-edit.models";
import { CharDataType, CharTypeId } from "rl-common/consts";
import { ICharDataChangeEvent } from "rl-common/models/i-char-data-change-event";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { CharDataTableGlobalService } from "rl-common/services/char-data-table-global.service";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { RelativeToCatalogCorrection } from "rl-common/services/datemath/datemath.models";
import { DateMathService } from "rl-common/services/datemath/datemath.service";
import { EntityService } from "rl-common/services/entity/entity.service";
import { SessionService } from "rl-common/services/session.service";
import { VariesByCatalogService } from "rl-common/services/varies-by-catalog.service";
import { DateLocaleType, DateUtil, IDUtil } from "rl-common/utils";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { VariesByCatalogCharDataElementEditComponent } from "../varies-by-catalog-char-data-element-edit/varies-by-catalog-char-data-element-edit.component";

@Component({
    selector: "rl-varies-by-catalog-wizard-step",
    templateUrl: "./varies-by-catalog-wizard-step.component.html",
    styleUrls: ["./varies-by-catalog-wizard-step.component.scss"],
    imports: [NgIf, NgFor, VariesByCatalogCharDataElementEditComponent, ReactiveFormsModule, FormsModule]
})
export class VariesByCatalogWizardStepComponent implements OnInit, OnDestroy {
	@Input()
	charData: ICharacteristicData[];

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	relationships: { [charTypeId: number]: IRecordTitle[] } = {};

	headers: ICharacteristicMetaData[] = [];
	exclusiveCharDataCollection: { [recordId: number]: _.Dictionary<ICharacteristicData[]> } = {};
	nonExclusiveCharDataCollection: { [recordId: number]: _.Dictionary<ICharacteristicData[]> } = {};

	exclusiveBulkEdit: ICharacteristicData[];
	showExclusiveBulkEdit = [];
	nonExclusiveBulkEdit: ICharacteristicData[];
	showNonExclusiveBulkEdit = [];
	isCorrectingRels = [];

	canApplyNonExclusiveEdit: { [tagLabel: string]: boolean } = {};
	canApplyExclusiveEdit: { [tagLabel: string]: boolean } = {};

	optimizeRights = true;
	hideExclusivity = false;
	hasExclusiveError: { [recordId: number]: boolean } = {};
	hasNonExclusiveError: { [recordId: number]: boolean } = {};

	taggedCatalogs: IRecordTitle[];


	private _subs: Subscription[] = [];

	constructor(
		private _variesByService: VariesByCatalogService,
		private _entityService: EntityService,
		private _sessionService: SessionService,
		private _dateMathService: DateMathService,
		private readonly _globalCharDataTableService: CharDataTableGlobalService<CharDataTableService>
	) { }

	ngOnInit() {
		this.getHeaders();

		this.taggedCatalogs = this.relationships[CharTypeId.Property];
		const newRecIds = this.taggedCatalogs.map(x => x.recordId);
		const oldRecIds = Object.keys(this.exclusiveCharDataCollection).map(x => Number(x));

		oldRecIds.forEach(id => {
			if (newRecIds.indexOf(id) < 0) {
				delete (this.exclusiveCharDataCollection[id]);
			}
		});

		this.taggedCatalogs.forEach(catalog => {
			const pivoted = {};

			this.charData.forEach(cd => {
				if (!pivoted[cd.charactersticID]) {
					pivoted[cd.charactersticID] = [cd];
				} else {
					pivoted[cd.charactersticID].push(cd);
				}
			});

			this.exclusiveCharDataCollection[catalog.recordId] = cloneDeep(pivoted);
			this.nonExclusiveCharDataCollection[catalog.recordId] = cloneDeep(pivoted);
			this.exclusiveBulkEdit = this.charData;
			this.nonExclusiveBulkEdit = this.charData;

			// remove any prefilled date from non exclusive char data
			const startDateChar = this.headers.find(x => x.tagLabel.indexOf("_start") > 0);
			const endDateChar = this.headers.find(x => x.tagLabel.indexOf("_end") > 0);
			if (startDateChar) {
				this.nonExclusiveCharDataCollection[catalog.recordId][startDateChar.characteristicID] = [];
			}

			if (endDateChar) {
				this.nonExclusiveCharDataCollection[catalog.recordId][endDateChar.characteristicID] = [];
			}
		});

		this.getCommonCmds(newRecIds);

		this.hideExclusivity = !find(this.templateMetaData.characteristicMetaDatas, x => x.tagLabel === "exclusivity");
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	getHeaders() {
		this.headers = Object.keys(this._variesByService.variedFields).filter(x => this._variesByService.variedFields[+x]).map(x => {
			const sysInd = Number(x);
			const char = find(this.templateMetaData.characteristicMetaDatas, y => y.systemIndicator === sysInd);
			return char;
		});
	}

	getCommonCmds(recordIds: number[]) {
		const entityIds = recordIds.map(x => IDUtil.toID(this._sessionService.divId, CharTypeId.Property, x));
		this._entityService.getCharDatas(entityIds).subscribe(response => {
			let commonCmds = response[0].templateMetaData.characteristicMetaDatas.filter(x => x.dataTypeID === CharDataType.Date);

			// filter out date characteristics that aren't on the other selected catalog templates
			response.forEach(catalog => {
				commonCmds = commonCmds.filter(cmd => !!catalog.templateMetaData.characteristicMetaDatas.find(y => y.characteristicID === cmd.characteristicID));
			});

			this._variesByService.commonCmds = commonCmds;
		});
	}

	isFormValid() {
		const taggedCatalogs = this.relationships[CharTypeId.Property];
		let isValid = true;

		taggedCatalogs.forEach(record => {
			const isExclusiveValid = this.isValid(record.recordId, true);
			const isNonExclusiveValid = this.isValid(record.recordId, false);
			const hasValuesInExclusive = this.hasValues(record.recordId, true);
			const hasValuesInNonExclusive = this.hasValues(record.recordId, false);
			isValid = isValid &&
				(isExclusiveValid || isNonExclusiveValid) &&
				(!hasValuesInExclusive || isExclusiveValid) &&
				(!hasValuesInNonExclusive || isNonExclusiveValid);
		});

		return isValid;
	}

	hasValues(recordId: number, isExclusive: boolean) {
		const charData = isExclusive ? this.exclusiveCharDataCollection[recordId] : this.nonExclusiveCharDataCollection[recordId];

		let isEmpty = true;
		this.headers.forEach(header => {
			const newChars = charData[header.characteristicID];
			const noCharData = !newChars || newChars.length === 0 || !newChars[0].value;
			const emptyDate = !noCharData && newChars[0].value === BLANK_DATE && newChars[0].ext == null;
			isEmpty = isEmpty && (noCharData || emptyDate);
		});

		return !isEmpty;
	}

	isValid(recordId: number, isExclusive: boolean) {
		const charData = isExclusive ? this.exclusiveCharDataCollection[recordId] : this.nonExclusiveCharDataCollection[recordId];
		const hasError = isExclusive ? this.hasExclusiveError : this.hasNonExclusiveError;

		let isValid = true;
		hasError[recordId] = false;
		this.headers.forEach(header => {
			const newChars = charData[header.characteristicID];
			isValid = isValid && (newChars?.length > 0 && (newChars[0].value !== "" && newChars[0].value !== null && (newChars[0].value !== BLANK_DATE || newChars[0].ext != null)));
		});

		const startDateChar = this.headers.find(x => x.tagLabel.indexOf("_start") > 0);
		const endDateChar = this.headers.find(x => x.tagLabel.indexOf("_end") > 0);

		if (startDateChar || endDateChar) {
			const startDateCharData = startDateChar ? first(charData[startDateChar.characteristicID]) : null;
			const endDateCharData = endDateChar ? first(charData[endDateChar.characteristicID]) : null;

			const startDate = startDateCharData ? DateUtil.parseToUTCMoment(startDateCharData.value, DateLocaleType.Storage) : null;
			const endDate = endDateCharData ? DateUtil.parseToUTCMoment(endDateCharData.value, DateLocaleType.Storage) : null;

			isValid = isValid && (!startDate || startDate.isValid()) && (!endDate || endDate.isValid());

			if (startDate && startDate.isValid() && startDateCharData.value !== BLANK_DATE && endDate && endDate.isValid() && endDateCharData.value !== BLANK_DATE) {
				const areDatesValid = startDate.isBefore(endDate);
				isValid = isValid && areDatesValid;
				hasError[recordId] = !areDatesValid;
			}
		}

		const allCharDatas = flatten(Object.values(charData));
		const dateMathRels = flatten(allCharDatas.map(x => x.ext?.dateMath?.relationships ?? []));
		const badDateMathRels = dateMathRels.filter(rel => {
			// place date math rel validation checks here
			if (rel.parentCharTypeID == CharTypeId.All) {
				return true;
			}

			return false;
		});

		const dateMathRelsAreValid = isEmpty(badDateMathRels);
		const isCorrectingRels = some(this.isCorrectingRels, x => !!x);
		return isValid && dateMathRelsAreValid && !isCorrectingRels;
	}

	hasError(recordId: number, isExclusive: boolean) {
		const hasError = isExclusive ? this.hasExclusiveError : this.hasNonExclusiveError;
		return hasError[recordId];
	}

	getChar(recordId: number, isExclusive: boolean, cmd: ICharacteristicMetaData) {
		const charData = this.getCollection(recordId, cmd.characteristicID, isExclusive);

		const def: ICharacteristicData[] = [{
			charactersticID: cmd.characteristicID,
			recordCharacteristicID: 0,
			value: ""
		}];

		return charData.filter(x => x.charactersticID === cmd.characteristicID) || def;
	}

	elementDataChanged(recordId: number, isExclusive: boolean, $event: ICharDataChangeEvent) {
		const sub = forkJoin(this.elementDataChanged$(recordId, isExclusive, $event)).subscribe();
		this._subs.push(sub);
	}

	elementDataChanged$(recordId: number, isExclusive: boolean, $event: ICharDataChangeEvent) {
		const isBulkEdit = recordId < 0;
		const tagLabel = $event.cmd ? $event.cmd.tagLabel : "";

		// update bulk edit apply button
		if (isBulkEdit) {
			if ($event.charData.length > 0 && ($event.charData[0].value || $event.charData[0]?.ext?.dateMath?.calcs.length > 0)) {
				if (isExclusive) {
					this.canApplyExclusiveEdit[tagLabel] = true;
				} else {
					this.canApplyNonExclusiveEdit[tagLabel] = true;
				}
			} else {
				if (isExclusive) {
					this.canApplyExclusiveEdit[tagLabel] = false;
				} else {
					this.canApplyNonExclusiveEdit[tagLabel] = false;
				}
			}
		}

		let corrections$: Observable<RelativeToCatalogCorrection[]>[] = [of([])];
		if (!isBulkEdit) {
			corrections$ = this.fixRelativeToCatalogDM$(recordId, $event.charData, isExclusive);
		}

		// merge char data
		let collection = this.getCollection(recordId, $event.cmd.characteristicID, isExclusive) || [];
		collection = collection.filter(x => x.charactersticID !== $event.cmd.characteristicID);

		$event.charData.forEach(x => {
			collection.push(cloneDeep(x));
		});

		if (isBulkEdit) {
			if (isExclusive) {
				this.exclusiveBulkEdit = collection;
			} else {
				this.nonExclusiveBulkEdit = collection;
			}
		} else {
			if (isExclusive) {
				this.exclusiveCharDataCollection[recordId][$event.cmd.characteristicID] = collection;
			} else {
				this.nonExclusiveCharDataCollection[recordId][$event.cmd.characteristicID] = collection;
			}
		}

		return corrections$;
	}

	fixRelativeToCatalogDM$(recordId: number, charDatas: ICharacteristicData[], isExclusive: boolean) {
		const observables: Observable<RelativeToCatalogCorrection[]>[] = [of([])];
		for (let i = 0; i < charDatas.length; i++) {
			const cd = charDatas[i];
			const rels = cd?.ext?.dateMath?.relationships;
			if (!rels) {
				continue;
			}

			for (let t = 0; t < cd.ext.dateMath.relationships.length; t++) {
				const relationship = cd.ext.dateMath.relationships[t];

				// relative to catalog
				if (relationship.relativeCatalogCharID) {
					const catalogCharTagLabels = this._variesByService.commonCmds
						.filter(x => cd.ext.dateMath.relationships.map(y => y.relativeCatalogCharID).indexOf(x.characteristicID) >= 0)
						.map(x => x.tagLabel);

					const ob = this._dateMathService.correctRelativeToCatalog([recordId], catalogCharTagLabels).pipe(
						tap(response => {
							response.forEach(correction => {
								const collection = isExclusive ?
									this.exclusiveCharDataCollection[recordId][cd.charactersticID] :
									this.nonExclusiveCharDataCollection[recordId][cd.charactersticID];

								const relToFix = collection[0].ext.dateMath.relationships.find(rel => rel.relativeCatalogCharID === correction.charId);
								relToFix.parentRecCharID = correction.recCharId;
								relToFix.parentRecID = correction.assetId;
								relToFix.parentCharTypeID = CharTypeId.Property;
								collection[0].value = BLANK_DATE;
							});
						})
					);
					observables.push(ob);
				}
			}
		}
		return observables;
	}

	applyBulkEdit(isExclusive: boolean, tagLabel: string, instanceId: string) {
		const bulkCollection = isExclusive ? this.exclusiveBulkEdit : this.nonExclusiveBulkEdit;
		const recordCollection = isExclusive ? this.exclusiveCharDataCollection : this.nonExclusiveCharDataCollection;
		const recordIds = Object.keys(recordCollection).map(x => +x);

		const char = this.templateMetaData.characteristicMetaDatas.find(x => x.tagLabel === tagLabel);
		const bulkCharsToCopy = bulkCollection.filter(x => x.charactersticID === char.characteristicID);

		const ext = bulkCollection.find(x => x.charactersticID === char.characteristicID).ext;
		const relativeRels = ext && ext.dateMath.relationships.filter(x => x.relativeCatalogCharID);

		const corrections$ = flatten(
			recordIds.map(recordId => this.elementDataChanged$(+recordId, isExclusive, { cmd: char, charData: bulkCharsToCopy }))
		);

		let correctRels$: Observable<RelativeToCatalogCorrection[]> = of([]);
		if (relativeRels) {
			const recIds = this.relationships[CharTypeId.Property].map(x => x.recordId);
			const catalogCharTagLabels = this._variesByService.commonCmds
				.filter(x => ext.dateMath.relationships.map(y => y.relativeCatalogCharID).indexOf(x.characteristicID) >= 0)
				.map(x => x.tagLabel);
			correctRels$ = this._dateMathService.correctRelativeToCatalog(recIds, catalogCharTagLabels);
		}

		this.isCorrectingRels[tagLabel] = true;
		const sub = forkJoin(corrections$).pipe(
			switchMap(() => correctRels$),
			finalize(() => {
				this.isCorrectingRels[tagLabel] = false;
				const sourceCharDataTable = this._globalCharDataTableService.getInstance(instanceId);
				const sourceCharDatas = sourceCharDataTable.charDatas;
				this.ensureDateMathRelsExist(isExclusive, sourceCharDatas)
			})
		).subscribe((response) => {
			response.forEach(correction => {
				const toCorrect = recordCollection[correction.assetId][char.characteristicID][0];
				const relToFix = toCorrect.ext.dateMath.relationships.find(rel => rel.relativeCatalogCharID === correction.charId);
				relToFix.parentRecCharID = correction.recCharId;
				relToFix.parentRecID = correction.assetId;
				relToFix.parentCharTypeID = CharTypeId.Property;
				toCorrect.value = BLANK_DATE;
			});
			this.toggleBulkEditMenu(tagLabel, isExclusive);
		});

		this._subs.push(sub);

	}

	private ensureDateMathRelsExist(isExclusive: boolean, sourceCharDatas: Dictionary<ICharacteristicData[]>) {
		const recordCollection = isExclusive ? this.exclusiveCharDataCollection : this.nonExclusiveCharDataCollection;
		const recordIds = Object.keys(recordCollection).map(x => +x);
		recordIds.forEach(recId => {
			const charDataCollection = recordCollection[recId];
			const recordCharDatas = flatten(Object.values(charDataCollection));
			const characteristicIds = Object.keys(charDataCollection).map(x => +x);
			characteristicIds.forEach(charId => {
				const cmd = this.templateMetaData.characteristicMetaDatas.find(x => x.characteristicID === charId);
				if (cmd.dataTypeID !== CharDataType.Date) {
					return;
				}
				const cd = first(charDataCollection[charId]);
				if (cd?.ext?.dateMath?.relationships) {
					cd.ext.dateMath.relationships.forEach(dmRel => {
						const parentCmd = this.templateMetaData.characteristicMetaDatas.find(x => x.characteristicID === dmRel.parentDateCharId);
						if (!parentCmd) {
							return;
						}

						const parentDate = recordCharDatas.find(y => y.recordCharacteristicID === dmRel.parentRecCharID);
						if (!parentDate) {
							const sourceParentCharData = sourceCharDatas[parentCmd.characteristicID] ?? [];
							this.elementDataChanged(recId, isExclusive, { cmd: parentCmd, charData: cloneDeep(sourceParentCharData) });
						}
					});
				}
			})
		})
	}

	toggleBulkEditMenu(tagLabel: string, isExclusive: boolean) {
		if (isExclusive) {
			this.showExclusiveBulkEdit[tagLabel] = false;
		} else {
			this.showNonExclusiveBulkEdit[tagLabel] = false;
		}
	}

	getCollection(recordId: number, charId: number, isExclusive: boolean) {
		let collection: ICharacteristicData[];

		if (recordId < 0) {
			collection = isExclusive ? this.exclusiveBulkEdit : this.nonExclusiveBulkEdit;
		} else {
			collection = isExclusive ? this.exclusiveCharDataCollection[recordId][charId] : this.nonExclusiveCharDataCollection[recordId][charId];
		}

		return collection;
	}
}
