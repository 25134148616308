<div class="roles-container border">
	<table class="table rl-table table-hover">
		<thead>
			<tr>
				<th>Identity Role</th>
				<th>Role's Data Policy</th>
				<th>Role's Activity Policy</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let role of identityRoles" (click)="toggleRole(role)" class="toggle-role">
				<td>
					<input type="checkbox" [ngModel]="selectedRoles.has(role.roleID)" class="form-check-input">
					<span class="ms-2">{{ role.name }}</span>
				</td>
				<td>
					{{ getPolicyName(role.dataSecurityPolicyID) }}
				</td>
				<td>
					{{ getPolicyName(role.activitySecurityPolicyID) }}
				</td>
			</tr>
		</tbody>
	</table>
</div>