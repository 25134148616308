import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { first, isEqual } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IAccountingOperationEvent } from "rl-common/services/accounting-processes/models/i-accounting-operation-event";
import { CompanyService } from "rl-common/services/company/company.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { IAccountOrGroupId } from "../build-accounting-operation/models/i-account-or-group-id";

export interface ICreditDebitAccountOrGroup {
	creditName: string;
	creditId: IAccountOrGroupId;
	debitName: string;
	debitId: IAccountOrGroupId;
}

@Component({
	selector: "rl-account-operation-event",
	templateUrl: "./account-operation-event.component.html",
	styleUrls: ["./account-operation-event.component.scss"],
	imports: [NgIf, NgFor]
})
export class AccountOperationEventComponent implements OnChanges, OnDestroy {

	@Input()
	anchorTemplateId: number;

	@Input()
	event: IAccountingOperationEvent;

	anchorTemplate: ICharacteristicMetaDataCollection;

	get anchorTemplateLabel(): string {
		return this.anchorTemplate?.template?.templateName;
	}

	initialStatusLabel: string;
	wfActionLabel: string;
	resultingStatusLabel: string;

	creditDebitAccountOrGroups: ICreditDebitAccountOrGroup[];

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _oneConfig: OneConfigService,
		private readonly _companyService: CompanyService
	) { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.anchorTemplateId && changes.anchorTemplateId.currentValue && changes.anchorTemplateId.currentValue !== changes.anchorTemplateId.previousValue) {
			this.anchorTemplate = this._oneConfig.getTemplateMetaData(CharTypeId.Amount, this.anchorTemplateId);
			this.updateLabels();
		}

		if (changes.event && changes.event.currentValue && !isEqual(changes.event.currentValue, changes.event.previousValue)) {
			const accountOrGroups = this.event.accountOrGroups ?? [];
			this.creditDebitAccountOrGroups = accountOrGroups.map(x => ({ creditName: x.creditName, creditId: x.creditId, debitName: x.debitName, debitId: x.debitId } as ICreditDebitAccountOrGroup));
			this.updateLabels();
		}
	}

	private updateLabels() {
		if (!this.anchorTemplate) {
			this.initialStatusLabel = `Unknown Status`;
			this.wfActionLabel = `Unknown Action`;
			this.resultingStatusLabel = `Unknown Status`;
			return;
		}
		const statuses = this._oneConfig.getTemplateStatuses(this.anchorTemplate.charTypeID, this.anchorTemplate.templateID);
		if (this.event.statusId == null) {
			this.initialStatusLabel = `None`;
		} else {
			const initialStatus = statuses.find(x => x.stepId === this.event.statusId);
			this.initialStatusLabel = initialStatus?.stepName ?? `Unknown Status`;
		}

		const wfProcessId = this._oneConfig.getTemplateProcess(this.anchorTemplate.charTypeID, this.anchorTemplate.templateID);
		if (this.event.workflowActionId == null) {
			this.wfActionLabel = `None`;
			this.resultingStatusLabel = `None`;
		} else {
			const wfAction = this._oneConfig.getWorkflowAction(this.anchorTemplate.charTypeID, wfProcessId, this.event.workflowActionId);
			this.wfActionLabel = wfAction?.actionName ?? `Unknown Action`;
			this.updateStatusLabel(wfProcessId, wfAction?.actionId);
		}
	}

	private updateStatusLabel(processId: number, actionId: number) {
		if (!actionId || !processId) {
			this.resultingStatusLabel = "";
		}
		const statuses = this._oneConfig.getTemplateStatuses(this.anchorTemplate.charTypeID, this.anchorTemplate.templateID);
		const sub = this._companyService.getWfActionStepAssociations(processId, actionId).pipe(
			map(associations => statuses.filter(step => associations.find(assoc => assoc.stepID === step.stepId)))
		).subscribe(steps => {
			this.resultingStatusLabel = first(steps)?.stepName ?? "No Status Change";
		});

		this._subs.push(sub);
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}
