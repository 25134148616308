<rl-chip *ngIf="newRecordTitle" (onRemove)="newRowDeselected()">
	{{ titleNotEmpty(newRecordTitle.title) ? newRecordTitle.title : "Record " + newRecordTitle.recordId}}
</rl-chip>

<ng-container *ngIf="selectedState; else items">
	<div *ngIf="!selectedState?.isAllSelected; else allSelected">
		<rl-chip *ngFor="let chipWithTitle of selectedStateChips"
			(onRemove)="existingRowDeselected(chipWithTitle.chip)">
			{{ chipWithTitle.title }}
		</rl-chip>
	</div>
</ng-container>

<ng-template #items>
	<rl-chip *ngFor="let chipWithTitle of recordChips" (onRemove)="existingRowDeselected(chipWithTitle.chip)">
		{{ chipWithTitle.title }}
	</rl-chip>
</ng-template>

<ng-template #allSelected>
	<rl-chip (onRemove)="selectAllDeselected()">
		All selected from current search ({{selectedCount}})
	</rl-chip>
</ng-template>