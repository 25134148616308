import { CdkDrag, CdkDragEnter, CdkDragHandle, CdkDragPlaceholder, CdkDropList, CdkDropListGroup, moveItemInArray } from "@angular/cdk/drag-drop";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { IzendaUrlChangeService } from "app/+izenda/services/url-change/izenda-url-change.service";
import { IFlexDashboard } from "rl-common/services/dashboard/dashboard.models";
import { DashboardService } from "rl-common/services/dashboard/dashboard.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { HttpStatusCode } from "rl-common/services/rl-http.models";
import { SessionService } from "rl-common/services/session.service";
import { Subscription, of, throwError } from "rxjs";
import { catchError, delay, distinctUntilChanged, filter } from "rxjs/operators";
import { AvailsStatusComponent } from "../../../common/components/avails-status/avails-status.component";
import { LoaderComponent } from "../../../common/components/panel/loader/loader.component";
import { WidgetHandleDirective } from "./widget/widget-handle.directive";
import { WidgetComponent } from "./widget/widget.component";
import { IWidgetOptions } from "./widget/widget.models";

export interface IDashboardChangedEvent {
	widgets: IWidgetOptions[];
}

@Component({
	selector: "rl-flex-dashboard",
	templateUrl: "./flex-dashboard.component.html",
	styleUrls: ["./flex-dashboard.component.scss"],
	providers: [
		IzendaUrlChangeService
	],
	imports: [AvailsStatusComponent, NgIf, LoaderComponent, CdkDropListGroup, NgFor, CdkDropList, NgClass, WidgetComponent, CdkDrag, WidgetHandleDirective, CdkDragHandle, CdkDragPlaceholder]
})
export class FlexDashboardComponent implements OnInit, OnDestroy {

	isLoading = false;
	isSaving = false;
	dashboard: IFlexDashboard;

	private readonly _subscriptions: Subscription[] = [];

	get widgets() {
		return this.dashboard?.widgets || [];
	}

	constructor(
		private readonly _growlerService: GrowlerService,
		private readonly _dashboardService: DashboardService,
		private readonly _session: SessionService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _titleService: Title
	) { }

	ngOnInit() {
		this._titleService.setTitle("Rightsline | Dashboard");

		const removedWidgetSub = this._dashboardService.removeWidget$.subscribe(id => {
			const widget = this.dashboard.widgets.find(x => x.id === id);
			const index = this.dashboard.widgets.indexOf(widget);
			this.dashboard.widgets.splice(index, 1);
			this.saveDashboard();
		});

		const updateParamsSub = this._dashboardService.updateWidgetParams$.subscribe(([id, params]) => {
			const widget = this.dashboard.widgets.find(x => x.id === id);
			widget.params = params;
			this.saveDashboard();
		});

		const loadDashboardSub = this._session.divId$.pipe(
			filter(divId => divId !== -1),
			distinctUntilChanged(),
			delay(0),
		).subscribe(() => {
			this.loadDashboard();
		});

		this._subscriptions.push(removedWidgetSub, loadDashboardSub, updateParamsSub);
	}

	entered(event: CdkDragEnter) {
		moveItemInArray(this.dashboard.widgets, event.item.data, event.container.data);
	}

	loadDashboard() {
		this.isLoading = true;
		const sub = this._dashboardService.getDashboard()
			.subscribe(dashboard => {
				this.dashboard = dashboard;
				this.isLoading = false;
			});
		this._subscriptions.push(sub);
	}

	saveDashboard() {
		this.isSaving = true;
		const sub = this._dashboardService.saveDashboard(this.dashboard).pipe(
			catchError(e => {
				if (e.status === HttpStatusCode.PreConditionFailed) {
					return of(null);
				}
				return throwError(e);
			})
		).subscribe(dashboard => {
			this.isSaving = false;
			this.loadDashboard();
		});

		this._subscriptions.push(sub);
	}

	resetDashboard() {
		if (!this.dashboard.id) {
			return;
		}
		const sub = this._dashboardService.deleteDashboard(this.dashboard.id).subscribe(() => {
			this.loadDashboard();
		});
		this._subscriptions.push(sub);
	}

	addWidget() {
		const sub = this._modalService.addWidget(this.dashboard.widgets).pipe(
			filter(widgets => widgets.length > 0)
		).subscribe(widgets => {
			this.dashboard.widgets.push(...widgets);
			this.saveDashboard();
		});
		this._subscriptions.push(sub);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

}
