import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IOriginalConfirmEvent } from "./original-confirm-modal.models";

@Component({
	selector: "rl-original-confirm-modal",
	templateUrl: "./original-confirm-modal.component.html",
	styleUrls: ["./original-confirm-modal.component.scss"],
	imports: [ReactiveFormsModule, FormsModule]
})
export class OriginalConfirmModalComponent implements OnInit {
	@Output()
	onConfirm = new EventEmitter<IOriginalConfirmEvent>();

	makeKeyArt = false;
	makeThumb = false;
	constructor(
		private readonly _activeModal: NgbActiveModal,
	) { }

	ngOnInit() {
	}
	continue() {
		this.onConfirm.emit({
			makeKeyArt: this.makeKeyArt,
			makeThumb: this.makeThumb
		});
	}
	cancel() {
		this._activeModal.dismiss();
	}
}
