import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import _, { first, isEmpty, isEqual } from "lodash";
import { CharTypeId, MaxInt } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { AccountingProcessActiveIndicator } from "rl-common/services/accounting-processes/models/accounting-process-active-indicator";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { CompanyService } from "rl-common/services/company/company.service";
import { IOneConfigWorkflowAction, IOneConfigWorkflowProcess, IOneConfigWorkflowStep } from "rl-common/services/one-config/one-config.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { of, Subscription } from "rxjs";
import { distinctUntilChanged, finalize, map, switchMap } from "rxjs/operators";
import { IAccountOrGroupId } from "../build-accounting-operation/models/i-account-or-group-id";
import { IBuildAccountingOperationAccountOrGroup } from "../build-accounting-operation/models/i-build-accounting-operation-account-or-group";
import { IBuildAccountingOperationEvent } from "../build-accounting-operation/models/i-build-accounting-operation-event";
import { IBuildAccountingOperationTrigger } from "../build-accounting-operation/models/i-build-accounting-operation-trigger";
import { INullAccountOrGroup } from "../build-accounting-operation/models/i-null-account-or-group";
import { BuildAccountingProcessService } from "../build-accounting-process-modal/build-accounting-process.service";

export interface IAccountingOperationEventForm {
	id: FormControl<string>;
	statusId: FormControl<number>;
	workflowActionId: FormControl<number>;
	workflowDisabled: FormControl<boolean>;
	accountOrGroups: FormArray<FormControl<IBuildAccountingOperationAccountOrGroup>>;
}

@Component({
	selector: "rl-add-accounting-operation-event",
	templateUrl: "./add-accounting-operation-event.component.html",
	styleUrls: ["./add-accounting-operation-event.component.scss"],
	imports: [ReactiveFormsModule, NgFor, FormsModule, NgIf]
})
export class AddAccountingOperationEventComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	event: IBuildAccountingOperationEvent;

	@Input()
	accounts: IAccountingProcessAccount[] = [];

	@Input()
	accountGroups: IAccountingProcessAccountGroup[] = [];

	@Input()
	workflowTrigger: IBuildAccountingOperationTrigger;

	@Output()
	onSave = new EventEmitter<IBuildAccountingOperationEvent>();

	@Output()
	onCancel = new EventEmitter();

	accountsMap: { [id: string]: IAccountingProcessAccount };
	accountGroupsMap: { [id: string]: IAccountingProcessAccountGroup };

	form: FormGroup<IAccountingOperationEventForm>;
	formKey: string;

	wfProcess: IOneConfigWorkflowProcess;
	availableStatuses: IOneConfigWorkflowStep[] = [];
	availableWfActions: IOneConfigWorkflowAction[] = [nullWfAction];
	resultingStatus: IOneConfigWorkflowStep = nullResultingStatus;

	private _formSubs: Subscription[] = [];
	private readonly _subs: Subscription[] = [];

	get availableAccounts() {
		return this.accounts?.filter(x => x.activeIndicator === AccountingProcessActiveIndicator.Active) ?? [];
	}

	get availableAccountGroups() {
		return this.accountGroups;
	}

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _oneConfig: OneConfigService,
		private readonly _companyService: CompanyService,
		private readonly _buildAccountingProcessService: BuildAccountingProcessService
	) { }

	ngOnInit(): void {
		this.accountGroups = this.accountGroups.filter(g => g.ruleCount > 0);
		if (this.anchorTemplate) {
			this.setStatuses();
		}
		this.setWfProcess();
		this.buildForm(this.event);
		this.buildAccountsMap();
		this.buildAccountGroupsMap();
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.anchorTemplate && changes.anchorTemplate.currentValue) {
			this.setWfProcess();
			this.setStatuses();
		}

		if (changes.event && changes.event.previousValue?.id !== changes.event.currentValue?.id) {
			this.buildForm(this.event);
		}

		if (changes.accounts) {
			this.buildAccountsMap();
		}

		if (changes.accountGroups) {
			this.buildAccountGroupsMap();
		}

		if (!isEqual(changes.workflowTrigger?.currentValue, changes.workflowTrigger?.previousValue)) {
			const workflowDisabled = this.form && this.form.controls.id.value && this.form.controls.statusId.value == null && this.form.controls.workflowActionId.value == null;
			if (!workflowDisabled) {
				this.updateStatusIdControl(this.event);
			}
		}
	}

	getAnchorTemplateText() {
		if (this.anchorTemplate) {
			return `, ${this.anchorTemplate.templateName}`;
		}
		return "";
	}

	setWfProcess() {
		const templateProcessId = this._oneConfig.getTemplateProcess(CharTypeId.Amount, this.anchorTemplate.templateID);
		this.wfProcess = this._oneConfig.getCharTypeProcesses(CharTypeId.Amount).find(x => x.processId === templateProcessId);
	}

	setStatuses() {
		const statuses = this._oneConfig.getTemplateStatuses(CharTypeId.Amount, this.anchorTemplate.templateID);
		this.availableStatuses = [nullStatus].concat(statuses);
	}

	buildAccountsMap() {
		this.accountsMap = this.accounts.reduce<{ [id: string]: IAccountingProcessAccount }>((dict, account) => {
			dict[account.id] = account;
			return dict;
		}, {});
	}

	buildAccountGroupsMap() {
		this.accountGroupsMap = this.accountGroups.reduce<{ [id: string]: IAccountingProcessAccountGroup }>((dict, accountGroup) => {
			dict[accountGroup.id] = accountGroup;
			return dict;
		}, {});
	}

	buildForm(event: IBuildAccountingOperationEvent = null) {
		this._formSubs.forEach(sub => sub.unsubscribe());
		this._formSubs = [];

		const defaultEvent = this.defaultEvent();
		const merged = { ...defaultEvent, ...event };

		const accountOrGroupControls = merged.accountOrGroups.map(x => new FormControl<IBuildAccountingOperationAccountOrGroup>(x, [accountRequiredValidator]));
		this.form = this._formBuilder.group({
			id: new FormControl<string>(merged.id),
			statusId: new FormControl<number>(merged.statusId, [Validators.required]),
			workflowActionId: new FormControl<number>(merged.workflowActionId, [Validators.required]),
			accountOrGroups: new FormArray<FormControl<IBuildAccountingOperationAccountOrGroup>>(accountOrGroupControls),
			workflowDisabled: new FormControl<boolean>(false)
		}, { validators: accountsValidator });

		const statusSub = this.form.controls.statusId.valueChanges.pipe(
			distinctUntilChanged(),
			switchMap(stepId => {
				if (!stepId || !this.wfProcess) {
					return of([]);
				}
				this.form.controls.workflowActionId.disable();
				const status = this.availableStatuses.find(x => x.stepId === stepId);
				return this._companyService.getWfStepActionAssociations(this.wfProcess.processId, status.stepId).pipe(
					map(associations => {
						const availableActions = this.wfProcess.workflowActions.filter(action => associations.find(x => x.actionID === action.actionId));
						return availableActions;
					}),
					finalize(() => this.form.controls.workflowActionId.enable())
				);
			})
		).subscribe(availableActions => {
			this.availableWfActions = [nullWfAction].concat(availableActions);
			const workflowActionId = this.form.controls.workflowActionId.value;
			if (!this.availableWfActions.find(x => x.actionId === workflowActionId)) {
				this.form.controls.workflowActionId.setValue(nullWfAction.actionId);
			}
		});

		const wfActionIdSub = this.form.controls.workflowActionId.valueChanges.pipe(
			distinctUntilChanged(),
			switchMap(actionId => {
				if (actionId === nullWfAction.actionId || !this.wfProcess) {
					return of([]);
				}
				return this._companyService.getWfActionStepAssociations(this.wfProcess.processId, actionId).pipe(
					map(associations => this.wfProcess.workflowSteps.filter(step => associations.find(assoc => assoc.stepID === step.stepId)))
				);
			})
		).subscribe(steps => {
			this.resultingStatus = first(steps) ?? nullResultingStatus;
		});

		if (event.statusId) {
			this.form.controls.statusId.setValue(event.statusId, { emitEvent: true });
		}

		const wfDisabledSub = this.form.controls.workflowDisabled.valueChanges.subscribe((wfDisabled) => {
			if (wfDisabled) {
				this.form.controls.statusId.setValue(nullStatus.stepId);
				this.form.controls.statusId.disable();
				this.form.controls.workflowActionId.setValue(nullWfAction.actionId);
				this.form.controls.workflowActionId.disable();
			} else {
				this.form.controls.workflowActionId.enable({ emitEvent: false });
				this.updateStatusIdControl(event);
			}
		});

		const workflowDisabled = event.id && event.statusId == null && event.workflowActionId == null;
		if (workflowDisabled) {
			this.form.controls.workflowDisabled.setValue(workflowDisabled);
		} else {
			this.form.controls.statusId.disable();
			this.updateStatusIdControl(event);
		}

		this._formSubs.push(statusSub, wfActionIdSub, wfDisabledSub);

		this.formKey = `AddAccountingOperationEvent-${event?.id ?? "0"}`;
		this._buildAccountingProcessService.registerForm(this.formKey, this.form);
	}

	compareAccountOrGroup(aog1: IAccountOrGroupId, aog2: IAccountOrGroupId) {
		return aog1?.id === aog2?.id;
	}

	private defaultEvent(event: IBuildAccountingOperationEvent = null) {
		const statusId = event?.statusId ?? nullStatus.stepId;
		const actionId = event?.workflowActionId ?? nullWfAction.actionId;
		const accountOrGroup = event?.accountOrGroups ?? [this.defaultAccountOrGroup()];
		const defaultEvent: IBuildAccountingOperationEvent = {
			statusId,
			workflowActionId: actionId,
			accountOrGroups: accountOrGroup
		} as IBuildAccountingOperationEvent;
		return defaultEvent;
	}

	private defaultAccountOrGroup() {
		return {
			debitId: { id: nullAccountOrGroup.id, isGroup: false },
			creditId: { id: nullAccountOrGroup.id, isGroup: false },
			debitName: nullAccountOrGroup.name,
			creditName: nullAccountOrGroup.name
		} as IBuildAccountingOperationAccountOrGroup;
	}

	private updateStatusIdControl(ev: IBuildAccountingOperationEvent = null) {
		if (!this.form) {
			return;
		}
		const defaultEvent = this.defaultEvent(ev);
		if (this.workflowTrigger) {
			const processId = this._oneConfig.getTemplateProcess(CharTypeId.Amount, this.anchorTemplate.templateID);
			const wfProcess = this._oneConfig.getCharTypeProcesses(CharTypeId.Amount).find(x => x.processId === processId);
			const sub = this._companyService.getWfActions(1, MaxInt.Num, processId).subscribe(response => {
				const resultingWorkflowActions = response.data;
				const resultingStepName = resultingWorkflowActions.find(x => x.actionID === this.workflowTrigger.actionId)?.stepName;
				const stepId = wfProcess.workflowSteps.find(x => x.stepName === resultingStepName)?.stepId;
				if (!stepId) {
					this.form.controls.workflowDisabled.disable({ emitEvent: false });
					this.form.controls.workflowDisabled.setValue(true, { emitEvent: true });
				} else {
					this.form.controls.statusId.disable({ emitEvent: false });
					this.form.controls.statusId.setValue(stepId ?? defaultEvent.statusId, { emitEvent: true });
				}
			});
			this._subs.push(sub);
		} else {
			this.form.controls.statusId.enable({ emitEvent: true });
			this.form.controls.statusId.setValue(defaultEvent.statusId, { emitEvent: true });
		}
	}

	availableDebitAccounts(control: FormControl<IBuildAccountingOperationAccountOrGroup>) {
		const accountOrGroup = control.value;
		return [nullAccountOrGroup].concat(this.availableAccounts.filter(x => x.id !== accountOrGroup.creditId.id));
	}

	availableCreditAccounts(control: FormControl<IBuildAccountingOperationAccountOrGroup>) {
		const accountOrGroup = control.value;
		return [nullAccountOrGroup].concat(this.availableAccounts.filter(x => x.id !== accountOrGroup.debitId.id));
	}

	availableDebitAccountGroups(control: FormControl<IBuildAccountingOperationAccountOrGroup>) {
		const accountOrGroup = control.value;
		return this.availableAccountGroups.filter(x => x.id !== accountOrGroup.creditId.id);
	}

	availableCreditAccountGroups(control: FormControl<IBuildAccountingOperationAccountOrGroup>) {
		const accountOrGroup = control.value;
		return this.availableAccountGroups.filter(x => x.id !== accountOrGroup.debitId.id);
	}

	removeAccount(index: number) {
		const formArray = this.form.controls.accountOrGroups;
		formArray.removeAt(index);
	}

	addAnotherAccountOrGroup() {
		const defaultAccountOrGroup = this.defaultAccountOrGroup();
		const formArray = this.form.controls.accountOrGroups;
		formArray.push(new FormControl<IBuildAccountingOperationAccountOrGroup>(defaultAccountOrGroup, [accountRequiredValidator]));
	}

	updateAccountOrGroupControl(index: number) {
		const controls = this.form.controls.accountOrGroups.controls;
		const control = controls[index];
		const account = control.value;
		const debitId = account.debitId;
		const creditId = account.creditId;

		const debitName = debitId.id in this.accountsMap ? this.accountsMap[debitId.id].name :
			debitId.id in this.accountGroupsMap ? this.accountGroupsMap[debitId.id].groupName : "";
		const creditName = creditId.id in this.accountsMap ? this.accountsMap[creditId.id].name :
			creditId.id in this.accountGroupsMap ? this.accountGroupsMap[creditId.id].groupName : "";

		const result: IBuildAccountingOperationAccountOrGroup = {
			debitId,
			creditId,
			debitName,
			creditName
		} as IBuildAccountingOperationAccountOrGroup;
		control.setValue(result);
		this.form.markAsDirty();
	}

	cancel() {
		this.onCancel.emit();
	}

	save() {
		const accountOrGroups = this.form.controls.accountOrGroups.controls.map(control => control.value);
		const event: IBuildAccountingOperationEvent = {
			id: this.form.controls.id.value,
			statusId: this.form.controls.statusId.value,
			workflowActionId: this.form.controls.workflowActionId.value,
			accountOrGroups
		};
		this.onSave.emit(event);
	}

	ngOnDestroy(): void {
		this._formSubs.forEach(sub => sub.unsubscribe());
		if (this.formKey) {
			this._buildAccountingProcessService.unregisterForm(this.formKey);
		}
		this._subs.forEach(sub => sub.unsubscribe());
	}
}

const nullResultingStatus = { stepId: null, stepName: "No Status Change" } as IOneConfigWorkflowStep;
const nullStatus: IOneConfigWorkflowStep = { stepId: null, stepName: "Select" } as IOneConfigWorkflowStep;
const nullWfAction: IOneConfigWorkflowAction = { actionId: null, actionName: "Select" } as IOneConfigWorkflowAction;
const nullAccountOrGroup: INullAccountOrGroup = { id: null, name: "Select" } as INullAccountOrGroup;

export const accountRequiredValidator: ValidatorFn = (control: FormControl<IBuildAccountingOperationAccountOrGroup>): ValidationErrors | null => {
	const account = control.value;
	if (account.debitId.id === nullAccountOrGroup.id || account.creditId.id === nullAccountOrGroup.id) {
		return { accountRequired: true };
	}
	return null;
};

export const accountsValidator: ValidatorFn = (form: FormGroup<IAccountingOperationEventForm>): ValidationErrors | null => {
	const accountsFormArray = form.controls.accountOrGroups;
	if (isEmpty(accountsFormArray.controls)) {
		return { accountsRequired: true };
	}
	const accounts = accountsFormArray.controls.map(x => x.value);
	const allAccounts = accounts.map(x => x.creditId.id).concat(accounts.map(x => x.debitId.id)).filter(id => !!id);
	const duplicateAccounts = _(allAccounts)
		.groupBy(accountId => accountId)
		.filter(grp => grp.length > 1)
		.value();

	if (!isEmpty(duplicateAccounts)) {
		return { accountUniqueness: true };
	}

	return null;
};
