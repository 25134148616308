import { isEmpty, some } from "lodash";
import { CharDataType, CharTypeId } from "rl-common/consts";
import { APKeyValueOption } from "rl-common/models/ap-key-value-option";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ICharacteristicTemplateGroup } from "rl-common/models/i-characteristic-template-group";
import { TriggerEventOperator } from "rl-common/services/accounting-processes/models/trigger-event-operator";
import { TriggerEventType } from "rl-common/services/accounting-processes/models/trigger-event-type";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { LovUtil } from "rl-common/utils/lov-util";

export namespace AccountingProcesses {

	export enum ValueEntryType {
		none = `none`,
		single = `single`,
		range = `range`
	}

	export const triggerTypeName = (triggerType: TriggerEventType): string => {
		switch (triggerType) {
			case TriggerEventType.TemplateAssociation:
				return `Template Association`;
			case TriggerEventType.WorkflowAction:
				return `Workflow Status Change`;
			case TriggerEventType.DataBehavior:
				return `Data Behavior`;
			case TriggerEventType.RecordCreation:
				return `Record Creation`;
			default:
				throw Error(`eventType: ${triggerType} not found.`);
		}
	};

	export interface IOperatorOption {
		operator: TriggerEventOperator;
		label: string;
		entryType: ValueEntryType;
	}

	export const valueOperators: TriggerEventOperator[] = [
		TriggerEventOperator.EqualTo,
		TriggerEventOperator.NotEmpty,
		TriggerEventOperator.IsEmpty,
	];

	export const allOperators: TriggerEventOperator[] = [
		TriggerEventOperator.EqualTo,
		TriggerEventOperator.Between,
		TriggerEventOperator.GreaterThan,
		TriggerEventOperator.GreaterThanOrEqual,
		TriggerEventOperator.LessThan,
		TriggerEventOperator.LessThanOrEqual,
		TriggerEventOperator.NotEmpty,
		TriggerEventOperator.IsEmpty,
	];

	export const operatorOptionsLookup: { [key in CharDataType]?: TriggerEventOperator[] } = {
		[CharDataType.InternetAddress]: valueOperators,
		[CharDataType.Email]: valueOperators,
		[CharDataType.Alphanumeric]: valueOperators,
		[CharDataType.FourDigitYear]: allOperators,
		[CharDataType.Number]: allOperators,
		[CharDataType.Date]: allOperators,
		[CharDataType.Percentage]: allOperators,
		[CharDataType.Money]: allOperators,
		[CharDataType.Currency]: valueOperators,
		[CharDataType.Checkbox]: valueOperators,
		[CharDataType.ExternalDocument]: valueOperators
	};

	export const hasOperators = (cmd: ICharacteristicMetaData) => {
		const dataType: CharDataType = cmd?.dataTypeID;
		return dataType in operatorOptionsLookup;
	};

	export const availableOperators = (cmd: ICharacteristicMetaData): IOperatorOption[] => {
		if (hasOperators(cmd)) {
			return operatorOptionsLookup[cmd.dataTypeID].map(op => {
				const option: IOperatorOption = { operator: op, label: operatorVerbiage(op), entryType: entryType(op) };
				return option;
			});
		}
		return [];
	};

	export const operatorVerbiage = (operator: TriggerEventOperator) => {
		switch (operator) {
			case TriggerEventOperator.EqualTo:
				return `is equal to`;
			case TriggerEventOperator.Between:
				return `is between`;
			case TriggerEventOperator.GreaterThan:
				return `is greater than`;
			case TriggerEventOperator.GreaterThanOrEqual:
				return `is greater than or equal`;
			case TriggerEventOperator.LessThan:
				return `is less than`;
			case TriggerEventOperator.LessThanOrEqual:
				return `is less than or equal`;
			case TriggerEventOperator.NotEmpty:
				return `is populated`;
			case TriggerEventOperator.IsEmpty:
				return `is blank`;
			default:
				throw Error(`operator: ${operator} not found.`);
		}
	};

	export const entryType = (operator: TriggerEventOperator) => {
		switch (operator) {
			case TriggerEventOperator.EqualTo:
			case TriggerEventOperator.GreaterThan:
			case TriggerEventOperator.GreaterThanOrEqual:
			case TriggerEventOperator.LessThan:
			case TriggerEventOperator.LessThanOrEqual:
				return ValueEntryType.single;
			case TriggerEventOperator.Between:
				return ValueEntryType.range;
			case TriggerEventOperator.NotEmpty:
			case TriggerEventOperator.IsEmpty:
				return ValueEntryType.none;
			default:
				throw Error(`operator: ${operator} not found.`);
		}
	};

	export const dataBehaviorCmds = (oneConfig: OneConfigService, charTypeId: CharTypeId, templateId: number) => {
		const cmds = oneConfig
			.getTemplateCmds(charTypeId, templateId)
			.filter(cmd => {
				if (cmd.multipleIndicator === 1) {
					return false; // multiples not yet supported
				}

				if (!AccountingProcesses.hasOperators(cmd)) {
					return false; // operators must be available
				}

				const isLov = CharDataValueMapping.isLov(cmd);
				if (isLov) {
					const lmd = oneConfig.getLovMetaData(cmd.charValueSourceID);
					return !LovUtil.isTree(lmd); // trees not yet supported
				}

				return true;
			});

		return cmds;
	};

	export const dataBehaviorDateCmds = (cmds: ICharacteristicMetaData[]) => {
		const dateCmds = cmds.filter(cmd => {
			return CharDataValueMapping.isDate(cmd);
		});
		return dateCmds;
	};

	export namespace CharDataValueMapping {

		export const DELIMITER = "|";

		export const fromTriggerValues = (tmd: ICharacteristicMetaDataCollection, characteristicId: number, value: string): ICharacteristicData[] => {
			const cmd = tmd.characteristicMetaDatas.find(x => x.characteristicID == characteristicId);
			if (!cmd) {
				return null;
			}

			const values = value.split(DELIMITER);
			if (isLov(cmd) || isCheckbox(cmd)) {
				const valueIds = values
					.map(x => +x)
					.filter(x => !!x);
				return valueIds.map(valueId => ({
					charactersticID: characteristicId,
					recordCharacteristicID: null,
					value: null,
					valueID: valueId
				} as ICharacteristicData));
			}
			return values.map(val => ({ charactersticID: characteristicId, recordCharacteristicID: null, value: val } as ICharacteristicData));
		};

		export const toTriggerValues = (tmd: ICharacteristicMetaDataCollection, characteristicId: number, charDatas: ICharacteristicData[]) => {
			if (!tmd || !characteristicId || !charDatas || isEmpty(charDatas)) {
				return null;
			}

			const cmd = tmd.characteristicMetaDatas.find(x => x.characteristicID === characteristicId);
			if (!cmd) {
				return null;
			}

			let values = charDatas.map(cd => cd.value);
			if (isLov(cmd) || isCheckbox(cmd)) {
				values = charDatas.map(cd => cd.valueID?.toString()).filter(val => !!val);
			}

			if (some(values, val => val?.includes(DELIMITER))) {
				throw Error(`'${DELIMITER}' not allowed in trigger values.`);
			}

			return values.join(DELIMITER);
		};

		export const isLov = (cmd: ICharacteristicMetaData) => cmd?.dataTypeID === CharDataType.Alphanumeric && !!cmd?.charValueSourceID;
		export const isCheckbox = (cmd: ICharacteristicMetaData) => cmd?.dataTypeID === CharDataType.Checkbox && !!cmd?.charValueSourceID;
		export const isDate = (cmd: ICharacteristicMetaData) => cmd?.dataTypeID === CharDataType.Date;

	}

	export namespace KeyValueOptionMapping {

		export const fromTemplate = (template: ICharacteristicTemplate) => {
			return { id: template.templateID, value: template.templateName } as APKeyValueOption;
		};

		export const fromTemplateGroup = (group: ICharacteristicTemplateGroup) => {
			return { id: group.characteristicTemplateGroupID, value: group.characteristicTemplateGroupName } as APKeyValueOption;
		};

		export const fromCharacteristicMetaDataValue = (cmdValue: ICharacteristicMetaDataValue) => {
			return { id: cmdValue.characteristicValueID, value: cmdValue.characteristicValueLabel } as APKeyValueOption;
		};

	}
}
