import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, DestroyRef, effect, EventEmitter, Injector, Input, OnChanges, OnInit, Output, Provider } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { Dictionary } from "lodash";
import { RightsExplorerModalService } from "reporting/components/rights-explorer/rights-explorer.modals.service";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { ICharLovMetaData } from "rl-common/models/i-char-lov-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { of } from "rxjs";
import { AvailsSnapshot } from "../../../../+reporting/components/rights-explorer/rights-explorer.models";
import { CharTypeId } from "../../../rl-common.consts";
import { WorksheetType, IWorksheet } from "../../../services/clipboard/clipboard.models";
import { ClipboardService } from "../../../services/clipboard/clipboard.service";
import { GrowlerService } from "../../../services/growler.service";
import { OneConfigService } from "../../../services/one-config/one-config.service";
import { ProgressService } from "../../../services/progress.service";
import { SessionService } from "../../../services/session.service";
import { LovUtil } from "../../../utils/lov-util";
import { GridDataSourceBuilder } from "../../grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "../../grid/datasource/common-grid.datasource";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";
import { EditClipboardModalAdapter, IEditClipboardComponent } from "./edit-clipboard.adapter";

@Component({
	selector: "rl-edit-clipboard",
	templateUrl: "./edit-clipboard.component.html",
	styleUrl: "./edit-clipboard.component.scss",
	imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgIf, NgbDropdownButtonItem, NgbDropdownItem, GridTableComponent, NgSwitch, NgSwitchCase, ReactiveFormsModule, FormsModule, NgSwitchDefault, NgClass]
})
export class EditClipboardComponent implements OnInit, OnChanges, IEditClipboardComponent {

	@Input()
	items: unknown[] = [];

	@Input()
	charTypeId: number;

	@Input()
	clipboardType: WorksheetType;

	@Input()
	selectedSnapshot: IWorksheet<unknown>;

	@Input()
	isModify: boolean = false;

	@Output()
	onComplete = new EventEmitter<boolean>();

	dataSource: CommonGridDataSource<unknown>;

	showModalHeader = false;
	clipboardTitle: string;
	clipboardCopyTitle: string;
	metaData: ICharacteristicMetaDataCollection;
	lovMetaData: Dictionary<ICharLovMetaData> = {};

	constructor(private readonly _progressService: ProgressService,
		private readonly _clipboardService: ClipboardService,
		private readonly _sessionService: SessionService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _oneConfig: OneConfigService,
		private readonly _injector: Injector,
		private readonly _destroyRef: DestroyRef,
		private readonly _rightsExplorerModalService: RightsExplorerModalService,
		private readonly _growlerService: GrowlerService) {


		if (!this.selectedSnapshot) {
			this.clipboardTitle = `Avails Snapshot | ${new Date().toLocaleDateString()} | ${this._sessionService.userName}`;
		}
	}

	get clipboardTypeName() {
		switch (this.clipboardType) {
			case WorksheetType.AvailsSnapshot: return "Avails";
			default: return "Not Supported";
		}
	}

	get hasSelections() {
		return this.dataSource.dataSelectStrategy.selectedState.selectedIds.size > 0 || this.dataSource.dataSelectStrategy.selectedState.isAllSelected;
	}

	ngOnInit() {
		if (this.clipboardType === WorksheetType.AvailsSnapshot) {
			this.metaData = this._oneConfig.getTemplateMetaData(CharTypeId.Right, 1);
			this.metaData.characteristicMetaDatas.forEach((cmd) => {
				if (cmd.charValueSourceID) {
					this.lovMetaData[cmd.systemIndicator] = this._oneConfig.getLovMetaData(cmd.charValueSourceID);
				}
			});
		}

		if (this.selectedSnapshot) {
			this.clipboardTitle = this.selectedSnapshot.title;
		}

		this.setDataSource();
	}

	ngOnChanges() {
		if (this.selectedSnapshot) {
			this.clipboardTitle = this.selectedSnapshot.title;
		}
		this.setDataSource();
	}

	public setDataSource() {
		if (this.items) {
			switch (this.clipboardType) {
				case WorksheetType.AvailsSnapshot: {
					this.setAvailsDatasource();
					break;
				}
				case WorksheetType.Records: {
					this.setRecordsDatasource();
					break;
				}
				default: {
					console.error("Unsupported ClipboardType");
					break;
				}
			}
		}
	}

	private setRecordsDatasource() {

	}

	private setAvailsDatasource() {
		const selectStrategy = this._gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<AvailsSnapshot, AvailsSnapshot, AvailsSnapshot>(row => row, row => row)
			.setSelectAllEnabled(false)
			.withSelectType(GridSelectType.Checkbox);

		this.dataSource = this._gridDataSourceBuilder.commonGridDataSource<AvailsSnapshot>(row => row.catalogId)
			.withFetchFn((ds) => {
				return of({ rowCount: this.items.length, rowData: this.items } as IGridFetchResults<AvailsSnapshot>);
			})
			.setColumns(this.availsColumns)
			.withDataSelectStrategy(selectStrategy);

		effect(() => {
			this.dataSource.fetchRows()
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe();
		}, { injector: this._injector });
	}

	cancel() {
		this.onComplete.emit(false);
	}

	save(complete = true) {
		this._progressService.startProgress();
		// Create
		if (!this.selectedSnapshot) {
			const cb: IWorksheet<unknown> = {
				clipboardTypeId: this.clipboardType,
				title: this.clipboardTitle,
				data: this.items as unknown[]
			}

			this._clipboardService.createAvailsWorksheet(cb)
				.subscribe((res) => {
					this._progressService.endProgress();
					this._clipboardService.getWorksheets(this.clipboardType)
						.subscribe(() => {

						});
					this.onComplete.emit(true);
				});
		} else {
			//Save As (copy)
			if (this.clipboardCopyTitle) {
				this.selectedSnapshot.title = this.clipboardCopyTitle;
				const cb: IWorksheet<unknown> = {
					clipboardTypeId: this.clipboardType,
					title: this.clipboardCopyTitle,
					data: this.items as unknown[]
				}
				this._clipboardService.createAvailsWorksheet(cb)
					.subscribe((res) => {
						this._progressService.endProgress();
						this._clipboardService.getWorksheets(this.clipboardType)
							.subscribe(() => {

							});
						this.onComplete.emit(true);
					});
				//Rename (update)
			} else {
				if (this.clipboardTitle !== this.selectedSnapshot.title) {
					this.selectedSnapshot.title = this.clipboardTitle;
				}
				this._clipboardService.updateAvailsWorksheet(this.selectedSnapshot)
					.subscribe(() => {
						this._progressService.endProgress();
						this._clipboardService.getWorksheets(this.clipboardType)
							.subscribe((res) => {

							});
						if (complete) {
							this.onComplete.emit(true);
						} else {
							this.setDataSource();
						}
					});
			}
		}
	}

	export() {
		this._progressService.startProgress();
		this._clipboardService.exportAvails(WorksheetType.AvailsSnapshot, this.selectedSnapshot.clipboardId)
			.subscribe((jobId) => {
				this._growlerService.success().growl("Success: An email with a download link to the CSV file will be sent.");
				this._progressService.endProgress();
			});
	}

	generateRights() {
		const selectState = this.dataSource.dataSelectStrategy.selectedState as GridSelectState<AvailsSnapshot, AvailsSnapshot>;
		this.onComplete.emit(true);

		this._rightsExplorerModalService.bulkCreateRightsModal(Array.from(selectState.selectedValues), this._injector)
			.subscribe((res) => {

			});
	}

	removeSelectedItems() {
		const selectState = this.dataSource.dataSelectStrategy.selectedState;
		this.items = this.items.filter(x => Array.from(selectState.selectedValues).indexOf(x) == -1)

		// If editing
		if (this.selectedSnapshot) {
			this.selectedSnapshot.data = this.items;
		}
		this.save(false);
	}

	private readonly availsColumns: GridColumn<AvailsSnapshot>[] = [
		{
			key: "title",
			headerName: "Catalog Title",
			renderer: "link_name",
			width: "auto",
			getCellData: (row) => row.catalogId ? ({ charTypeId: CharTypeId.Property, recordId: row.catalogId, title: row.catalogTitle } as IGridViewAssocEntityRec) : null
		},
		{
			key: "termStart",
			headerName: "Term Start",
			renderer: "date",
			width: "auto",
			getCellData: (x) => x.termStart
		},
		{
			key: "termEnd",
			headerName: "Term End",
			renderer: "date",
			width: "auto",
			getCellData: (x) => x.termEnd
		},
		{
			key: "media",
			headerName: "Media",
			renderer: "text",
			width: "auto",
			getCellData: (x) => {
				const labels: string[] = [];
				const hlc = LovUtil.buildHierarchyLabelContext(this.lovMetaData[1].listOfValues);
				x.dim1BranchIds.forEach((y) => {
					labels.push(hlc.valueIdLabelMap.get(y));
				})

				return labels.join(", ");
			}
		},
		{
			key: "territory",
			headerName: "Territory",
			renderer: "text",
			width: "auto",
			getCellData: (x) => {
				const labels: string[] = [];
				const hlc = LovUtil.buildHierarchyLabelContext(this.lovMetaData[2].listOfValues);
				x.dim2BranchIds.forEach((y) => {
					labels.push(hlc.valueIdLabelMap.get(y));
				})

				return labels.join(", ");
			}
		},
		{
			key: "language",
			headerName: "Language",
			renderer: "text",
			width: "auto",
			getCellData: (x) => {
				const labels: string[] = [];
				const hlc = LovUtil.buildHierarchyLabelContext(this.lovMetaData[3].listOfValues);
				x.dim3BranchIds.forEach((y) => {
					labels.push(hlc.valueIdLabelMap.get(y));
				})

				return labels.join(", ");
			}
		},
		{
			key: "channel",
			headerName: "Channel",
			renderer: "text",
			width: "auto",
			getCellData: (x) => {
				const labels: string[] = [];
				const hlc = LovUtil.buildHierarchyLabelContext(this.lovMetaData[4].listOfValues);
				x.dim4BranchIds.forEach((y) => {
					labels.push(hlc.valueIdLabelMap.get(y));
				})

				return labels.join(", ");
			}
		},
		{
			key: "exclusivity",
			headerName: "Exclusivity",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.exclusivity
		}
	];
}

export const EDITCLIPBOARD_MODAL_PROVIDER: Provider = {
	provide: EditClipboardModalAdapter,
	useFactory: () => new EditClipboardModalAdapter(EditClipboardComponent)
};

