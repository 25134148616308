import { Directive, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { interval, Subject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";

export enum KeyComboTypes {
	Konami = "konami",
	Ux2Link = "ux2Link"
}

const KeyComboLookup: { [key in KeyComboTypes]?: number[] } = {
	[KeyComboTypes.Konami]: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
	[KeyComboTypes.Ux2Link]: [85, 88, 50, 76, 73, 78, 75]
};

@Directive({ selector: "[rlKonami]" })
export class KonamiDirective implements OnDestroy, OnChanges {

	@Input()
	combo: KeyComboTypes = KeyComboTypes.Konami;

	@Output()
	onEntered = new EventEmitter<void>();

	private konamiEnteredSubject = new Subject<number>();
	private konamiEntered = this.konamiEnteredSubject.asObservable();

	private timer: Subscription;
	private readonly _konamiSubscription: Subscription;

	private cursor = 0;
	private konamiChars = KeyComboLookup[KeyComboTypes.Konami];

	constructor() {
		this._konamiSubscription = this.konamiEntered.pipe(
			tap(() => this.processNext())
		).subscribe();
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes && changes.combo && changes.combo.currentValue && changes.combo.currentValue !== changes.combo.previousValue) {
			this.konamiChars = KeyComboLookup[this.combo];
		}
	}

	@HostListener("document:keydown", ["$event"])
	keyDown(event: KeyboardEvent) {
		const keyCode = event.keyCode;

		if (!keyCode) {
			return;
		}

		this.stopTimer();

		const konamiFound = keyCode === this.konamiChars[this.cursor];

		if (!konamiFound) {
			this.reset();
			return;
		}

		this.konamiEnteredSubject.next(keyCode);
	}

	private processNext() {
		const isLast = this.cursor === this.konamiChars.length - 1;

		if (isLast) {
			this.reset();
			this.onEntered.emit();
			return;
		}

		this.cursor++;

		this.timer = interval(500).subscribe(() => {
			this.reset();
		});
	}

	private reset() {
		this.stopTimer();
		this.cursor = 0;
	}

	private stopTimer() {
		if (this.timer && !this.timer.closed) {
			this.timer.unsubscribe();
		}
	}

	ngOnDestroy() {
		this._konamiSubscription.unsubscribe();
		this.stopTimer();
	}
}
