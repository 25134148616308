import { Component, ViewChild, type OnInit } from "@angular/core";
import { cloneDeep, isEmpty, values } from "lodash";
import { GridViewReportType } from "reporting/services/report.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { isGridViewColumnStrategy } from "rl-common/components/grid/datasource/columns/grid-view-column-strategy";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { INotification } from "rl-common/components/notifications/notification.models";
import { CharTypeId } from "rl-common/consts";
import { IFacetResult } from "rl-common/models/i-facet-result";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IComponentFilterOptions, IComponentsGroupFilterOptions } from "rl-common/services/components/component-group.models";
import { ConflictsService } from "rl-common/services/conflicts/conflicts.service";
import { IGridViewColumn } from "rl-common/services/grid-view/models/i-grid-view-column";
import { ViewLocation } from "rl-common/services/grid-view/models/view-location";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { QueryUtil } from "rl-common/utils";
import { map, tap } from "rxjs/operators";
import { FaIconDirective } from "../../../directives/fa-icon.directive";
import { LocaleDatePipe } from "../../../pipes/locale-date.pipe";
import { AdminConfigService } from "../../../services/admin-config/admin-config.service";
import { CellTemplateDirective } from "../../grid/directives/cell-template.directive";
import { ConflictCheckIssueComponent } from "../validation-errors-modal/conflict-check-issue/conflict-check-issue.component";
import { ConflictListReportColumnNames, IConflictListTuple } from "../validation-errors-modal/validation-errors-modal.models";

@Component({
	selector: "rl-assigned-conflicts-widget",
	templateUrl: "./assigned-conflicts-widget.component.html",
	styleUrl: "./assigned-conflicts-widget.component.scss",
	imports: [GridTableComponent, CellTemplateDirective, FaIconDirective, ConflictCheckIssueComponent, LocaleDatePipe]
})
export class AssignedConflictsWidgetComponent implements OnInit {

	gridOptions: GridOptions<INotification> = {
	};

	@ViewChild(GridTableComponent, { static: true })
	grid: GridTableComponent;

	rightsFilters: IComponentFilterOptions = {
		lovFilter: {}
	};
	rightsGroupFilters: IComponentsGroupFilterOptions = {
		dateFilter: {}
	};

	dataSource: SearchGridDataSource<IConflictListTuple>;


	constructor(
		private readonly gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly _conflictsService: ConflictsService,
		private readonly oneConfig: OneConfigService,
		private readonly _adminConfigService: AdminConfigService
	) {


	}

	ngOnInit() {

		(async () => {
			const columnStrategy = this.gridDataServiceBuilder.columnStrategies.conflictReportColumnViewStrategy();

			this.dataSource = this.gridDataServiceBuilder.searchGridDataSource<IConflictListTuple>(row => row[0].id)
				.withProgress(true)
				.setPaging({ pageSize: 10 })
				.setSorting({ sortKey: ConflictListReportColumnNames.ConflictUpdatedAt, sortDir: 0 })
				.withColumnStrategy(columnStrategy)
				.withFetchFn((ds) => {
					let gridViewColumns: IGridViewColumn[] = null;
					if (isGridViewColumnStrategy(ds.columnStrategy)) {
						if (ds.columnStrategy.gridView$.value) {
							gridViewColumns = ds.columnStrategy.gridView$.value.columns;
						}
					}
					const filters = this.buildFilterQueryNode();
					return this._conflictsService.conflictCheck(
						CharTypeId.User,
						-1, // this'll get substituted with context user
						filters,
						ds.sortKey$.value as string,
						ds.sortDir$.value,
						ds.rowOffset$.value,
						ds.pageSize$.value,
						gridViewColumns)
						.pipe(
							tap(results => {
								// this.canUpdateToBlocked = results.data.canUpdateToBlocked;
								// this.canUpdateToNotBlocked = results.data.canUpdateToNotBlocked;
								const facetDict = results.data.facets.reduce((acc, el) => {
									acc[el.key] = el.value;
									return acc;
								}, {} as _.Dictionary<IFacetResult[]>);
								ds.setFacetResults(facetDict);
							}),
							map(results => {

								const tupleRows = results.data.rows.map(r => {
									const t: IConflictListTuple = [r, null, null, null, null, null];
									t[1] = results.data.catalogDocs.find(d => d.recordID === r.catalogId);
									t[2] = results.data.rightsetDocs.find(d => d.recordID === r.right1Id);
									t[3] = results.data.rightsetDocs.find(d => d.recordID === r.right2Id);
									t[4] = results.data.dealDocs.find(d => d.recordID === r.deal1Id);
									t[5] = results.data.dealDocs.find(d => d.recordID === r.deal2Id);
									return t;
								});

								return { rowCount: results.numFound, rowData: tupleRows, extraGridColumnResults: results.data.dealExtraGridColumnResults } as IGridFetchResults<IConflictListTuple>;
							})
						);
				});

			// build a view manually for this
			// load views
			await columnStrategy.fetchGridViews().toPromise();
			const conflictColumns = columnStrategy.availableColumns$.value
			// columns for new view
			const columnNames = [
				ConflictListReportColumnNames.ConflictStatus,
				ConflictListReportColumnNames.ConflictType,
				ConflictListReportColumnNames.RightId,
				ConflictListReportColumnNames.DealId,
				ConflictListReportColumnNames.DealName,
				ConflictListReportColumnNames.ConflictingRightId,
				ConflictListReportColumnNames.ConflictingDealId,
				ConflictListReportColumnNames.ConflictingDealName,
				ConflictListReportColumnNames.TermStart,
				ConflictListReportColumnNames.TermEnd,
				ConflictListReportColumnNames.Dim1Labels,
				ConflictListReportColumnNames.Dim2Labels,
				ConflictListReportColumnNames.Dim3Labels,
				ConflictListReportColumnNames.Dim4Labels,
				ConflictListReportColumnNames.ConflictId,
				ConflictListReportColumnNames.ConflictStatusUpdatedAt,
				ConflictListReportColumnNames.ConflictUpdatedAt,
				ConflictListReportColumnNames.ConflictUpdated,
				ConflictListReportColumnNames.ConflictChangeReason
			];

			columnStrategy.setView({
				gridViewId: 0,
				charTypeId: 0,
				templateGroupId: 0,
				templateId: 0,
				gridViewLabel: "Temp view for Assigned Conflicts",
				reportType: GridViewReportType.ConflictList,
				isDefault: false,
				isGlobal: false,
				userId: 0,
				columns: [...columnNames
					.map(key => conflictColumns.find(c => c.columnKey === key))
					.filter(c => c !== null)],
				isKeyDefault: false,
				siteLocation: ViewLocation.none
			}, false);

			await columnStrategy.fetchColumns().toPromise();

			// load conflicts
			await this.dataSource.fetchRows().toPromise();
		})();
	}

	getStatusClass(conflictStatusLabel: "Blocked" | "Ignored" | "Needs Attention" | "Resolved") {

		switch (conflictStatusLabel) {
			case "Blocked":
				return "text-danger";
			case "Ignored":
				return "text-warning"
			case "Needs Attention":
				return "text-primary"
			case "Resolved":
				return "text-success"
			default:
				return "";
		}
	}

	buildFilterQueryNode(): IQueryNode {
		let filters: IQueryNode = {};

		const columnFilters = this.buildColumnFilters();

		if (!isEmpty(columnFilters)) {
			filters = QueryUtil.$and(...(values(columnFilters) ?? []));
		}

		return filters;
	}

	buildColumnFilters() {
		const columnFilters = cloneDeep(this.dataSource.columnFilters$.value);

		if (!isEmpty(this.rightsGroupFilters.taggedCatalogKeywords)) {
			columnFilters[ConflictListReportColumnNames.CatalogName] = QueryUtil.$contains(ConflictListReportColumnNames.CatalogName, this.rightsGroupFilters.taggedCatalogKeywords);
		}

		if (this.rightsGroupFilters.dateFilter?.startDate) {
			columnFilters[ConflictListReportColumnNames.TermEnd] = QueryUtil.$range(ConflictListReportColumnNames.TermEnd, this.rightsGroupFilters.dateFilter?.startDate, "*");
		}

		if (this.rightsGroupFilters.dateFilter?.endDate) {
			columnFilters[ConflictListReportColumnNames.TermStart] = QueryUtil.$range(ConflictListReportColumnNames.TermStart, "*", this.rightsGroupFilters.dateFilter?.endDate);
		}

		if (!isEmpty(this.rightsFilters.lovFilter)) {
			const rightsDims = this.oneConfig.getRightsDimensions().characteristics;
			[1, 2, 3, 4].forEach((dim) => {
				const dimChar = rightsDims[dim];
				if (dimChar && this.rightsFilters.lovFilter[dimChar.tagLabel]) {
					const leafIds = this.rightsFilters.lovFilter[dimChar.tagLabel]?.leafValueIds ?? [];
					if (leafIds.length > 0) {
						let columnName: string = null;
						switch (dim) {
							case 1:
								columnName = ConflictListReportColumnNames.Dim1Labels;
								break;
							case 2:
								columnName = ConflictListReportColumnNames.Dim2Labels;
								break;
							case 3:
								columnName = ConflictListReportColumnNames.Dim3Labels;
								break;
							case 4:
								columnName = ConflictListReportColumnNames.Dim4Labels;
								break;
							default:
								break;
						}
						columnFilters[columnName] = QueryUtil.$eq_any(columnName, leafIds);
					}
				}
			});
		}
		return columnFilters;
	}

}
