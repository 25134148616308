import { AsyncPipe, DatePipe, NgFor, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, computed, input, Input, Signal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { CharTypeId } from "rl-common/consts";
import { IModsLinks } from "rl-common/models/i-mods-links";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { NewTabInModalDirective } from "../../../../directives/new-tab-in-modal.directive";
import { IConflictCheckDealConflicts, IConflictCheckHistory } from "../validation-errors-modal.models";
import { IGridDataSource } from "./../../../grid/datasource/grid-datasource";

@Component({
	selector: "rl-conflict-check-issue-nested-data",
	templateUrl: "./conflict-check-issue-nested-data.component.html",
	imports: [NgFor, NgSwitch, NgSwitchCase, NewTabInModalDirective, RouterLink, AsyncPipe, DatePipe]
})
export class ConflictCheckIssueNestedDataComponent {
	parentRowData = input<IConflictCheckDealConflicts>();

	@Input()
	nestedDataSource: IGridDataSource<IConflictCheckHistory, unknown>;

	@Input()
	alternativeLabelConflictTypeIds: number[];

	modLinkHelper: IModsLinks;

	statusLabels: Signal<Map<boolean, string>>;

	constructor(private readonly linkHelper: LinkHelperService) {
		this.modLinkHelper = linkHelper.mods[CharTypeId.User];
		this.statusLabels = computed(() => {
			switch (this.parentRowData()?.conflictStatusLabel) {
				case "Needs Attention":
				case "Resolved":
					return new Map([[true, "Resolved"], [false, "Needs Attention"]]);
				default:
					return new Map([[true, "Non-Blocker"], [false, "Blocked"]]);
			}
		});
	}
}
