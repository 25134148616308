import { Injectable, OnDestroy } from "@angular/core";
import { TypeGuard } from "rl-common/guards/type.guard";
import { RoaringBitmapUtil } from "rl-common/utils/roaring-bitmap.util";
import { RoaringArenaAllocator, RoaringBitmap32 } from "roaring-wasm";

@Injectable()
export class BitmapAllocatorService implements OnDestroy {

	private readonly _allocator: RoaringArenaAllocator;

	/** gotta track and get rid of these because wasm memory is manually managed */
	private _bitmapsToTrack: RoaringBitmap32[] = [];

	get allocator() {
		return this._allocator;
	}

	constructor() {
		this._allocator = new RoaringArenaAllocator();
	}

	allocateBitmap(ids?: string | number[]): RoaringBitmap32 {
		let bitmap: RoaringBitmap32;
		if (TypeGuard.isString(ids)) {
			bitmap = RoaringBitmapUtil.deserializeBitmapFromBase64(this._allocator, ids);
		} else {
			bitmap = this._allocator.newRoaringBitmap32(ids);
		}
		this._bitmapsToTrack.push(bitmap);
		return bitmap;
	}

	ngOnDestroy(): void {
		// disposes all the bitmaps managed by the allocator
		this._allocator.stop();

		// asserts that all the bitmaps we're tracking within this scope have been disposed
		this._bitmapsToTrack.forEach(bitMap => {
			if (!bitMap.isDisposed) {
				throw new Error(`MEMORY LEAK: Bitmap was not disposed. You must use the allocator or manually dispose the bitmap.`);
			}
		})
	}

}