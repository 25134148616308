<div class="modal-body" data-cy="DocGenModalBody">
	<h4>Select Template</h4>
	<select class="form-select w-50 mb-4" [(ngModel)]="selectedTemplateId" (change)="updateTemplate()"
		data-cy="DocGenSelectTemplate">
		<option *ngFor="let templateOption of docTemplateOptions$ | async" [value]="templateOption.value"
			[attr.data-cy]="templateOption.label">
			{{templateOption.label}}</option>
	</select>

	<h4>Output Formats</h4>
	<select class="form-select w-50" [(ngModel)]="selectedFormat" data-cy="DocGenSelectOutputFormat">
		<option *ngFor="let opt of outputOptions" [value]="opt.value" [attr.data-cy]="opt.label">{{opt.label}}</option>
	</select>
</div>
<div class="modal-footer" data-cy="DocGenModalFooter">
	<ng-content></ng-content>
	<button class="btn btn-success ms-2" (click)="save()" [disabled]="isGenerating || !selectedTemplate"
		data-cy="DocGenModalButtonSave">Save</button>
</div>