import { RevisionStatus } from "rl-common/services/entity-config/entity-config.models";

export interface RevisionStatusMeta {
	displayName: string;
	value: number;
}

export namespace RevisionStatusUtil {
	export const RevisionStatusLookup: { [key in RevisionStatus]: RevisionStatusMeta } = {
		[RevisionStatus.InSetup]: {
			displayName: "In Setup",
			value: 1
		},
		[RevisionStatus.Complete]: {
			displayName: "Complete",
			value: 2
		},
		[RevisionStatus.Approved]: {
			displayName: "Approved",
			value: 3
		},
		[RevisionStatus.InRevision]: {
			displayName: "In Revision",
			value: 4
		}
	};

	export function toDisplayName(revisionStatus: RevisionStatus) {
		return RevisionStatusLookup[revisionStatus].displayName;
	}
}
