<h3>Create Hierarchical {{ charTypeId | pluralCharTypeName }}</h3>
<article>
	<div class="panel">
		<div class="container-fluid hierarchy-table">
			<div class="row align-items-center mb-3">
				<div class="col-2">Level</div>
				<div class="col-3"># Records to Create</div>
				<div class="col-7">Template</div>
			</div>
			<rl-template-hierarchy-count-item *ngFor="let child of templates" [charTypeId]="charTypeId"
				[template]="child">
			</rl-template-hierarchy-count-item>
		</div>

		<div class="action-footer mt-2">
			<button (click)="goToPreviousWizardStep()" class="btn btn-info"
				data-cy="CreateAndAssociateHierarchicalPrevious"><i class="far fa-chevron-left me-2"></i>
				Previous</button>
			<button (click)="cancel()" class="btn btn-light ms-3"
				data-cy="CreateAndAssociateHierarchicalCancel">Cancel</button>
			<button (click)="create()" class="btn btn-success ms-3" [disabled]="isSaving"
				data-cy="CreateAndAssociateHierarchicalDone"><i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
				Done</button>
		</div>
	</div>
</article>