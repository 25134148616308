import { Injectable } from "@angular/core";
import { CurrencyConversionType } from "company/components/currency/currency.models";
import { duration } from "moment";
import { RightsActionCondition } from "reporting/components/rights-explorer/rights-explorer.models";
import { RlHttpClient } from "../rl-http-client.service";
import { ICurrencyConversion, ICurrencyImportResponse, ICurrencySettings, ICurrencyValue, IPolicyTreeResponse, IRightsDimension, ISecurityPolicy, ISecurityPolicyPermission, ISecurityPolicyResponse, IUserRoleResponse, IWfActionResponse, IWfActionStepAssociation, IWfProcesses, IWfRoleActionAssociation, IWfRoleResponse, IWfRoleStepAssociation, IWfStepActionAssociation, IWfStepResponse, PolicyType, UsedInType } from "./company.models";

@Injectable({
	providedIn: "root"
})
export class CompanyService {
	roleId = "";

	constructor(private readonly _rlHttpClient: RlHttpClient) { }

	getSecurityRoles(page: number = 1, pageSize: number = 1, keywords: string = null, allRoles: boolean = true, roleId: string = null) {
		return this._rlHttpClient
			.get(`/company/security-roles`)
			.params({
				page,
				pageSize,
				...(keywords && { keywords }),
				allRoles,
				roleId
			})
			.fetch<IUserRoleResponse>();
	}

	createRole(name: string, activitySecurityPolicyId: string, dataSecurityPolicyId: string, xref: string) {
		return this._rlHttpClient
			.post(`/company/role`)
			.body({
				name,
				...(activitySecurityPolicyId && { activitySecurityPolicyId }),
				...(dataSecurityPolicyId && { dataSecurityPolicyId }),
				...(xref && { xref }),
			})
			.fetch<string>();
	}

	updateRole(roleId: string, name: string, activitySecurityPolicyId: string, dataSecurityPolicyId: string, xref: string) {
		return this._rlHttpClient
			.put(`/company/role`)
			.body({
				roleId,
				name,
				activitySecurityPolicyId,
				dataSecurityPolicyId,
				xref
			})
			.fetch();
	}

	deleteRole(roleId: string) {
		return this._rlHttpClient
			.delete(`/company/role/${roleId}`)
			.fetch<string>();
	}

	getWfRoles(page: number, pageSize: number, keywords: string) {
		return this._rlHttpClient
			.get(`/company/wf-roles`)
			.params({
				page,
				pageSize,
				...(keywords && { keywords })
			})
			.fetch<IWfRoleResponse>();
	}

	deleteWfRole(roleId: number) {
		return this._rlHttpClient
			.delete(`/company/wf-role/${roleId}`)
			.fetch();
	}

	createWfRole(roleName: string, description: string, superUserIndicator: number, roleId: number) {
		return this._rlHttpClient
			.post(`/company/wf-role`)
			.body({
				roleName,
				description,
				superUserIndicator,
				roleId
			})
			.fetch<string>();
	}

	updateWfRole(roleId: number, roleName: string, description: string, superUserIndicator: number) {
		return this._rlHttpClient
			.put(`/company/wf-role`)
			.body({
				roleId,
				roleName,
				description,
				superUserIndicator
			})
			.fetch<string>();
	}

	getPolicies(page: number = 1, pageSize: number = 1, keywords: string = null, allPolicyTypes: boolean = true,
		policyType: number = PolicyType.activityPolicy, usedInType: number = UsedInType.all) {
		return this._rlHttpClient
			.get("/company/policies")
			.params({
				page,
				pageSize,
				allPolicyTypes,
				policyType,
				...(keywords && { keywords }),
				usedInType
			})
			.fetch<ISecurityPolicyResponse>();
	}

	getPolicy(policyId: string) {
		return this._rlHttpClient
			.get("/company/policy")
			.params({
				policyId
			})
			.fetch<ISecurityPolicy>();
	}

	getPolicyTree(policyType: number) {
		return this._rlHttpClient
			.get("/company/policy-tree")
			.params({
				policyType
			})
			.fetch<IPolicyTreeResponse>();
	}

	getPolicyPermissions(securityPolicyId: string) {
		return this._rlHttpClient
			.get("/company/policy-permissions")
			.params({
				securityPolicyId
			})
			.fetch<ISecurityPolicyPermission[]>();
	}

	updatePolicyPermissions(securityPolicyID: string, permissions: ISecurityPolicyPermission[]) {
		return this._rlHttpClient
			.put("/company/policy-permissions")
			.body({
				securityPolicyID,
				permissions
			})
			.fetch();
	}

	deletePolicy(securityPolicyId: string) {
		return this._rlHttpClient
			.delete(`/company/policy/${securityPolicyId}`)
			.fetch();
	}

	createPolicy(policyTitle: string, policyType: number, isGlobal: number) {
		return this._rlHttpClient
			.post(`/company/policy`)
			.body({
				policyTitle,
				policyType,
				isGlobal
			})
			.fetch<string>();
	}

	updatePolicy(securityPolicyId: string, policyTitle: string, policyType: number, isGlobal: number) {
		return this._rlHttpClient
			.put(`/company/policy`)
			.body({
				securityPolicyId,
				policyTitle,
				policyType,
				isGlobal
			})
			.fetch();
	}

	getWfProcesses() {
		return this._rlHttpClient
			.get("/company/wf-processes")
			.fetch<IWfProcesses[]>();
	}

	getWfRoleStepAssociations(processId: number, roleId: number) {
		return this._rlHttpClient
			.get("/company/wf-role-step-associations")
			.params({
				processId,
				roleId
			})
			.fetch<IWfRoleStepAssociation[]>();
	}

	getWfSteps(page: number = 1, pageSize: number = 1, processId: number) {
		return this._rlHttpClient
			.get("/company/wf-steps")
			.params({
				page,
				pageSize,
				processId
			})
			.fetch<IWfStepResponse>();
	}

	getWfActions(page: number = 1, pageSize: number = 1, processId: number) {
		return this._rlHttpClient
			.get("/company/wf-actions")
			.params({
				page,
				pageSize,
				processId
			})
			.fetch<IWfActionResponse>();
	}

	getWfStepActionAssociations(processId: number, stepId: number) {
		return this._rlHttpClient
			.get("/company/wf-step-action-associations")
			.cache(`wf-step-action-associations-p${processId}s${stepId}`, duration(5, "minute"))
			.params({
				processId,
				stepId,
			})
			.fetch<IWfStepActionAssociation[]>();
	}

	getWfActionStepAssociations(processId: number, actionId: number) {
		return this._rlHttpClient
			.get("/company/wf-action-step-associations")
			.cache(`wf-action-step-associations-p${processId}a${actionId}`, duration(5, "minute"))
			.params({
				processId,
				actionId,
			})
			.fetch<IWfActionStepAssociation[]>();
	}

	getWfRoleActionAssociations(processId: number, roleId: number) {
		return this._rlHttpClient
			.get("/company/wf-role-action-associations")
			.params({
				processId,
				roleId
			})
			.fetch<IWfRoleActionAssociation[]>();
	}

	setWfRoleActionAssociations(processId: number, roleId: number, actionIds: number[]) {
		return this._rlHttpClient
			.post(`/company/set-wf-action-role-assoc`)
			.body({
				processId,
				roleId,
				actionIds
			})
			.fetch();
	}

	setWfStepRoleAssociations(processId: number, roleId: number, stepIds: number[]) {
		return this._rlHttpClient
			.post(`/company/set-wf-step-role-assoc`)
			.body({
				processId,
				roleId,
				stepIds
			})
			.fetch();
	}

	getCompanyCurrencyConversionListLegacy(showHistoricalAndFutureRates: boolean = false, conversionType: CurrencyConversionType = CurrencyConversionType.All) {
		return this._rlHttpClient
			.get("/company/company-currency-conversion-list-legacy")
			.params({
				showHistoricalAndFutureRates,
				conversionType
			})
			.fetch<ICurrencyConversion[]>();
	}

	createCurrencyConversion(fromCurrency: string, toCurrency: string, rate: string,
		effectiveDate: Date, conversionType: CurrencyConversionType, rateSource: number, isDeprecated: boolean) {
		return this._rlHttpClient
			.post(`/company/currency-conversion-info`)
			.body({
				fromCurrency,
				toCurrency,
				rate,
				effectiveDate,
				conversionType,
				rateSource,
				isDeprecated
			})
			.fetch();
	}

	updateCurrencyConversion(conversionId: number, conversionType: CurrencyConversionType, fromCurrency: string, toCurrency: string, rate: string,
		effectiveDate: Date, rateSource: number) {
		return this._rlHttpClient
			.put(`/company/currency-conversion-info`)
			.body({
				conversionType,
				conversionId,
				fromCurrency,
				toCurrency,
				rate,
				effectiveDate,
				rateSource
			})
			.fetch();
	}

	updateCurrencyConversionLegacy(legacyConversionId: string, conversionType: CurrencyConversionType, fromCurrency: string, toCurrency: string, rate: string,
		effectiveDate: Date, rateSource: number) {
		return this._rlHttpClient
			.put(`/company/currency-conversion-info`)
			.body({
				conversionType,
				legacyConversionId,
				fromCurrency,
				toCurrency,
				rate,
				effectiveDate,
				rateSource,
				isDeprecated: true
			})
			.fetch();
	}

	deleteCurrencyConversion(currencyConversionId: number) {
		return this._rlHttpClient
			.delete(`/company/currency-conversion-info`)
			.params({ conversionInfoId: currencyConversionId })
			.fetch();
	}

	deleteCurrencyConversionLegacy(currencyConversionId: string) {
		return this._rlHttpClient
			.delete(`/company/currency-conversion-info-legacy`)
			.params({ conversionInfoId: currencyConversionId })
			.fetch();
	}

	getStandardCurrencyList() {
		return this._rlHttpClient
			.get("/company/standard-currency-list")
			.fetch<ICurrencyValue[]>();
	}

	getCompanyCurrencyList() {
		return this._rlHttpClient
			.get("/company/company-currency-list")
			.fetch<ICurrencyValue[]>();
	}

	getCurrencySettings() {
		return this._rlHttpClient
			.get("/company/currency-settings")
			.fetch<ICurrencySettings>();
	}

	getCompanyCurrency() {
		return this._rlHttpClient
			.get("/company/company-currency")
			.fetch<string>();
	}

	uploadCurrencyConversion(fileName: string) {
		return this._rlHttpClient
			.post(`/company/upload-currency-conversion`)
			.params({
				fileName
			})
			.fetch();
	}

	uploadCurrencyConversionEnhanced(fileName: string, currencyConversionType: number) {
		return this._rlHttpClient
			.post(`/company/upload-currency-conversion-enhanced`)
			.params({
				fileName,
				currencyConversionType
			})
			.fetch<ICurrencyImportResponse>();
	}

	getRightsDimensions() {
		return this._rlHttpClient
			.get("/company/rights-dimensions")
			.fetch<IRightsDimension[]>();
	}

	getSelectedRightsDimensions() {
		return this._rlHttpClient
			.get("/company/selected-rights-dimensions")
			.fetch<IRightsDimension[]>();
	}

	updateRightsDimensions(dimensions: IRightsDimension[]) {
		return this._rlHttpClient
			.post(`/company/rights-dimensions`)
			.body({
				dimensions
			})
			.fetch();
	}

	getRightsHistoryDefault() {
		return this._rlHttpClient
			.get("/company/rights-history-default")
			.fetch<number[]>();
	}

	setRightsHistoryDefault(templateIds: number[]) {
		return this._rlHttpClient
			.put("/company/rights-history-default")
			.body(templateIds)
			.fetch<void>();
	}

	getRightsActionConditionDefault() {
		return this._rlHttpClient
			.get("/company/rights-action-default")
			.fetch<RightsActionCondition[]>();
	}

	setRightsActionConditionDefault(racs: RightsActionCondition[]) {
		return this._rlHttpClient
			.put("/company/rights-action-default")
			.body(racs)
			.fetch<void>();
	}

	getRightsSummaryChipsDefault() {
		return this._rlHttpClient
			.get("/company/rights-summary-chips-default")
			.fetch<number>();
	}

	setRightsSummaryChipsDefault(rightsSummaryChipsDefault: number) {
		return this._rlHttpClient
			.put("/company/rights-summary-chips-default")
			.body(rightsSummaryChipsDefault)
			.fetch<void>();
	}
}
