<div class="entity--associations" *ngIf="originalEntity">
	<div *ngIf="canBeDirectlyAssociated" class="d-flex align-items-center justify-content-start bg-light p-3 mb-3">
		<div>
			<input [formControl]="associateNewEntityControl" class="me-2 mt-1 form-check-input" type="checkbox"
				id="associateToNewEntity" data-cy="associateToNewEntityCheckbox">
		</div>
		<div>
			<label class="m-0" for="associateToNewEntity">
				Associate <strong>{{newEntityTitle}}</strong> as the
			</label>
		</div>
		<div class="mx-2">
			<select class="form-select" [formControl]="associationDirectionControl">
				<option *ngFor="let option of relationshipOptions" [ngValue]="option.direction">{{ option.label }}
				</option>
			</select>
		</div>
		<div>
			of <strong>{{originalEntity.title}}</strong>
		</div>
		<div class="col-2 ms-auto text-end help">
			<i class="far fa-question-circle lead" [ngbPopover]="associationPopover" triggers="click:blur"
				placement="bottom-right"></i>
		</div>
	</div>

	<h4 class="my-4 ms-3">Select all associations you want to transfer from <strong>{{originalEntity.title}}</strong>:
	</h4>
	<div class="entity--associations__list">
		<ng-container *ngIf="!(isLoading$ | async); else loadingAssociations">
			<span class="form-check py-1 ms-3" *ngIf="charTypes()?.length">
				<input type="checkbox" class="form-check-input" [(ngModel)]="areAllSelected"
					(ngModelChange)="toggleAllAssociations()" data-cy="selectAllCheck" />
				<label class="form-check-label ps-1 text-nowrap">Select All</label>
			</span>
			<rl-transfer-association-char-type *ngFor="let charType of charTypes()" [charType]="charType"
				(onChange)="updateAssociations()">
			</rl-transfer-association-char-type>
			<em *ngIf="!charTypes()?.length" class="m-3">No associations found</em>
		</ng-container>
	</div>
</div>

<ng-template #associationPopover>
	<div class="m-0 p-2">
		This creates a <i>direct</i> Association between these two records. (No other records associated with <strong
			class="ng-binding">{{newEntityTitle}}</strong> will be associated to or visible from <strong
			class="ng-binding">{{originalEntity.title}}</strong> - and vice versa - unless selected below.)
	</div>
</ng-template>

<ng-template #loadingAssociations>
	<rl-loader></rl-loader>
</ng-template>