import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive, RouterLink } from "@angular/router";
import { NgbDropdown, NgbModalRef, NgbTooltip, NgbPopover, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from "@ng-bootstrap/ng-bootstrap";
import { RouteSetting, SideBarRoutes } from "app/rightsline-app/utils/routing.utils";
import { environment } from "environments/environment";
import { CharTypeId } from "rl-common/consts";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { SessionService } from "rl-common/services/session.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Observable, Subscription, from } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import version from "../../../../assets/version.json";
import { Acl } from "../../../common/rl-common-acl.consts";
import { AccountService } from "../../../common/services/account/account.service";
import { ModalBuilder } from "../../../common/services/modal-builder/modal-builder";
import { IVersionInfo } from "../../../common/services/version/version.models";
import { DebugModalComponent } from "../modals/debug-modal/debug-modal.component";
import { AclUtil } from "./../../../common/utils/acl.util";
import { AdminLinks, IAdminLink, IComponentListPageReadAccess } from "./side-navigation.models";
import { NewTabInModalDirective } from "../../../common/directives/new-tab-in-modal.directive";
import { NgFor, NgIf, AsyncPipe } from "@angular/common";
import { FeatureEnabledDirective } from "../../../common/directives/feature-enabled.directive";
import { HasReadAccessDirective } from "../../../common/directives/has-read-access.directive";

@Component({
    selector: "rl-side-navigation",
    templateUrl: "./side-navigation.component.html",
    styleUrls: ["./side-navigation.component.scss"],
    imports: [NewTabInModalDirective, RouterLinkActive, NgbTooltip, RouterLink, NgFor, NgIf, NgbPopover, FeatureEnabledDirective, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, HasReadAccessDirective, AsyncPipe]
})
export class SideNavigationComponent implements OnInit, OnDestroy {

	versionInfo: IVersionInfo = version;
	modules: RouteSetting[] = SideBarRoutes;
	adminLinks: IAdminLink[] = AdminLinks;
	availableAcl = Acl.Activities.ActivityAdminAcls.ReportsAndAvailsAcls.Available;
	isRLAdmin$: Observable<boolean>;
	isComponentActive = false;
	private _debugModalRef: NgbModalRef;

	clpReadAccess: IComponentListPageReadAccess = {
		amounts: null,
		documents: null,
		rights: null,
		tables: null
	}

	get hasAdminLinks() {
		return this.adminLinks.length > 0;
	}

	get conflictingVersions() {
		return this._sessionService.apiVersion?.indexOf(this.versionInfo.CurrentSHA.substring(0, 10)) == -1;
	}

	get shouldShowComponents() {
		return this.clpReadAccess.amounts || this.clpReadAccess.documents || this.clpReadAccess.rights || this.clpReadAccess.tables;
	}

	private readonly _subscriptions: Subscription[] = [];

	constructor(
		public linkHelper: LinkHelperService,
		private readonly _sessionService: SessionService,
		private readonly _accountService: AccountService,
		private readonly _modalBuilder: ModalBuilder,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _router: Router
	) {

		this.isRLAdmin$ = this._sessionService.isRlAdmin$;
	}

	ngOnInit() {
		const aclSub = this._sessionService.acls$.pipe(
			distinctUntilChanged()
		).subscribe((acls) => {
			this.modules = SideBarRoutes.filter(x => AclUtil.hasReadAccess(acls, x.acl));
			this.adminLinks = AdminLinks.filter(x => AclUtil.hasReadAccess(acls, x.acl));
			this.populateCLPReadAccess(acls);
		});

		const compPaths = ["/comp3", "/comp5", "/comp13", "/comp10", "/comp14"];
		const routeSub = this._router.events
			.subscribe((e) => {
				if (e instanceof NavigationEnd) {
					const path = window.location.pathname;
					this.isComponentActive = compPaths.includes(path);
				}
		});

		this._subscriptions.push(aclSub, routeSub);
	}

	populateCLPReadAccess(acls: string[]) {
		this.clpReadAccess.amounts = AclUtil.hasReadAccess(acls, Acl.Activities.ActivityAdminAcls.ListPagesAcls.Amounts.toString());
		this.clpReadAccess.documents = AclUtil.hasReadAccess(acls, Acl.Activities.ActivityAdminAcls.ListPagesAcls.Documents.toString());
		this.clpReadAccess.rights = AclUtil.hasReadAccess(acls, Acl.Activities.ActivityAdminAcls.ListPagesAcls.Rights.toString());
		this.clpReadAccess.tables = AclUtil.hasReadAccess(acls, Acl.Activities.ActivityAdminAcls.ListPagesAcls.Tables.toString());
	}

	shouldShowComponent(charTypeId: number): boolean {
		switch (charTypeId) {
			case CharTypeId.Amount:
				return this.clpReadAccess.amounts;
			case CharTypeId.Document:
				return this.clpReadAccess.documents;
			case CharTypeId.Right:
				return this.clpReadAccess.rights;
			case CharTypeId.Usage:
				return this.clpReadAccess.tables;
			default:
				return false;
		}
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	toCssClass(route: RouteSetting) {
		return CharTypeIdUtil.toCssClass(route.charTypeId);
	}

	openAvailable(event: Event) {
		event.preventDefault();
		this._accountService.getAvailableToken()
			.subscribe((token) => {
				window.open(`${environment.availableSSOUrl}${token}`, "_blank");
			});
	}

	public toggleDebugModal() {
		if (this._debugModalRef) {
			this._debugModalRef.dismiss();
			return;
		}

		this._debugModalRef = this._modalBuilder.plain<DebugModalComponent>(DebugModalComponent).open();

		from(this._debugModalRef.result).subscribe(
			() => { }, // close
			() => {
				this._debugModalRef = null;
			} // dismissed
		);
	}
	over (drop:NgbDropdown) {
    	drop.open();
	}
	out (drop:NgbDropdown) {
		drop.close();
	}
}

export class SideNavigationModel {
	public moduleLinks: NavLinkModel[];
}
export class NavLinkModel {
	public text: string;
}
