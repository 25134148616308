import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Provider } from "@angular/core";
import { cloneDeep } from "lodash";
import { ComponentKey } from "rl-common/components/mod-details-layout/mod-layout.consts";
import { IPanelSection, toComponentKeyName } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { Subscription } from "rxjs";
import { BuildPanelElementComponent } from "../build-panel-element/build-panel-element.component";
import { BuildPanelElementModalAdapter, IBuildPanelElementModalComponent } from "./build-panel-element-modal.adapter";

@Component({
	selector: "rl-build-panel-element-modal",
	templateUrl: "./build-panel-element-modal.component.html",
	styleUrls: ["./build-panel-element-modal.component.scss"],
	imports: [BuildPanelElementComponent]
})
export class BuildPanelElementModalComponent implements OnInit, OnDestroy, OnChanges, IBuildPanelElementModalComponent {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<unknown>;

	@Input()
	componentKey: ComponentKey;

	workingElement: IPanelSection<unknown>;
	elementName: string;

	@Output()
	onComplete = new EventEmitter<IPanelSection<unknown>>();

	_subs: Subscription[] = [];

	constructor() { }

	ngOnInit() {
		if (this.section) {
			this.workingElement = cloneDeep(this.section);
		}
		if (this.componentKey) {
			this.elementName = toComponentKeyName(this.componentKey);
		}
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section && this.section) {
			this.workingElement = cloneDeep(this.section);
		}
		if (changes.componentKey && this.componentKey) {
			this.elementName = toComponentKeyName(this.componentKey);
		}
	}

	close() {
		this.onComplete.emit(null);
	}

	done() {
		this.onComplete.emit(this.workingElement);
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}
}

export const BUILDPANELELEMENT_MODAL_PROVIDER: Provider = {
  provide: BuildPanelElementModalAdapter,
  useFactory: () => new BuildPanelElementModalAdapter(BuildPanelElementModalComponent)
};

