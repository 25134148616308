<div *ngIf="dateControls.length > 0">
	<ng-container *ngFor="let dateControl of dateControls; let first = first; let i = index">
		<strong *ngIf="!first" class="d-block p-2 pb-1">AND</strong>
		<ng-container *ngIf="editIndex === i">
			<div class="mt-2">
				<rl-add-accounting-operation-date [anchorTemplate]="anchorTemplate" [anchorTemplateDates]="anchorTemplateDates" [anchorTemplateDefaultDates]="anchorTemplateDefaultDates" [date]="dateControl.value" [otherDates]="otherDates" [otherOperationDates]="otherOperationDates" [operationTriggers]="operationTriggers" (onSave)="updateDate($event)" (onCancel)="cancelEdit(i)">
				</rl-add-accounting-operation-date>
			</div>
		</ng-container>
		<ng-container *ngIf="editIndex !== i">
			<div class="d-flex align-items-center p-1 date-item">
				<div class="flex-grow-1">
					<rl-accounting-operation-date [anchorTemplateId]="anchorTemplate?.templateID" [date]="dateControl.value" [datesDictionary]="datesDictionary"></rl-accounting-operation-date>
				</div>
				<div class="date-item__buttons">
					<button class="btn btn-icon btn-sm" (click)="editDate(i)" [disabled]="isEditing" title="Edit"
						[attr.data-cy]="'editDate' + i">
						<i class="far fa-pencil"></i>
					</button>
					<button class="btn btn-icon btn-sm ms-2" (click)="removeDate(i, confirmDeletePrompt)" [disabled]="isEditing"
						title="Delete" [attr.data-cy]="'deleteDate' + i">
						<i class="far fa-trash-alt"></i>
					</button>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<div *ngIf="!isEditing" class="d-flex">
		<!-- <strong class="p-2">AND</strong> -->
		<button class="btn btn-sm btn-link" (click)="addDate(false)" data-cy="AddAnotherDatePopulation">
			+ Add Another Date Population
		</button>
	</div>
</div>
<div *ngIf="dateControls.length === 0">
	<div class="alert alert-warning">
		No Dates Added.
	</div>
	<button class="mt-2 btn btn-sm btn-link" (click)="addDate(false)" data-cy="AddAnotherDatePopulationNone">+ Add a Date Population</button>
</div>

<ng-template #confirmDeletePrompt let-prompt="prompt">
	<div>
		<p>
			Are you sure you want to delete this Date?
		</p>
	</div>
	<div class="text-end mt-3 w-100">
		<button class="btn btn-light btn-sm" (click)="prompt.onConfirm.next(false)" data-cy="ConfirmDeleteNo">
			Cancel
		</button>
		<button class="btn btn-success btn-sm ms-2" (click)="prompt.onConfirm.next(true)" data-cy="ConfirmDeleteYes">
			Delete
		</button>
	</div>
</ng-template>