import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IAppOption } from "app/rightsline-app/components/header/profile-dropdown/profile-dropdown.models";
import { some, take } from "lodash";
import { RecentDivsService } from "rl-common/services/recent-divs/recent-divs.service";
import { SessionService } from "rl-common/services/session.service";
import { NgIf, NgFor } from "@angular/common";

export interface ISelectRecentDivEvent {
	recentDiv: IAppOption;
}

@Component({
    selector: "rl-recent-divs",
    templateUrl: "./recent-divs.component.html",
    styleUrls: ["./recent-divs.component.scss"],
    imports: [NgIf, NgFor]
})
export class RecentDivsComponent implements OnInit {

	@Input()
	recentCount = 3;

	private _disabled = false;

	@Output()
	onDivSelect = new EventEmitter<ISelectRecentDivEvent>();

	@Input()
	set disabled(disabled: boolean) {
		this._disabled = disabled;
	}

	get disabled() {
		return this._disabled;
	}

	get topDivs() {
		const currentDiv = this._session.divId;
		const recentDivs = this._recentDivsService.recentDivs;
		return take(recentDivs.filter(x => x.divId !== currentDiv), this.recentCount);
	}

	get hasRecentDivs() {
		return some(this.topDivs);
	}

	constructor(private readonly _session: SessionService,
		private readonly _recentDivsService: RecentDivsService) { }

	ngOnInit() {
		this._recentDivsService.refreshRecentDivs();
	}

	selectDiv(recentDiv: IAppOption) {
		this.onDivSelect.emit({ recentDiv: recentDiv });
	}
}
