import { computed, effect, Injectable, signal } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { isEmpty } from "lodash";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { CmdVisibilityStrategy } from "../char-data/strategies/cmd-visibility-strategy";

@Injectable()
export class CreateEntityCmdVisibilityService extends CmdVisibilityStrategy {

	showRequired = signal<boolean>(false);
	showDesired = signal<boolean>(false);
	showPopulated = signal<boolean>(false);
	showExtracted = signal<boolean>(false);

	computedShowAll = computed(() => {
		const showRequired = this.showRequired();
		const showDesired = this.showDesired();
		const showPopulated = this.showPopulated();
		const showExtracted = this.showExtracted();
		return !showRequired && !showDesired && !showPopulated && !showExtracted;
	});

	// We cannot bind the form to a computed signal, and signal cannot be updated via an effect
	showAll = true;

	title = computed(() => {
		const showAll = this.computedShowAll();
		if (showAll) {
			return `All Fields`;
		}
		const titles = [];
		if (this.showRequired()) {
			titles.push(`Required Fields`);
		}
		if (this.showDesired()) {
			titles.push(`Recommended Fields`);
		}
		if (this.showPopulated()) {
			titles.push(`Populated Fields`);
		}
		if (this.showExtracted()) {
			titles.push(`Extracted Fields`);
		}
		return titles.join(", ");
	});

	private _extractedCharIds: number[] = [];

	constructor() {
		super();

		effect(() => {
			this.showAll = this.computedShowAll();
		});

	}

	setExtractedChars(charIds: number[]) {
		this._extractedCharIds = charIds ?? [];
	}

	isCmdVisible(charDataService: CharDataTableService, cmd: ICharacteristicMetaData): boolean {
		if (this.computedShowAll()) {
			return true;
		}

		// show required fields
		if (this.showRequired() && cmd.requiredIndicator === 1) {
			return true;
		}

		// show desired fields
		if (this.showDesired() && cmd.requiredIndicator === 2) {
			return true;
		}

		// show extracted chars
		if (this.showExtracted() && this._extractedCharIds.includes(cmd.characteristicID)) {
			return true;
		}

		// show populated fields
		if (this.showPopulated()) {
			const control = charDataService.tableFormGroup.controls[cmd.tagLabel] as UntypedFormControl;
			const charData = ((control?.value as ICharacteristicData[]) ?? []).filter(cd => !!cd);
			return !isEmpty(charData)
		}

		return false;
	}

	showAllFields() {
		this.showRequired.set(false);
		this.showDesired.set(false);
		this.showPopulated.set(false);
		this.showExtracted.set(false);
	}

}