<div class="row p-1">
	<div class="col-2">{{template.level}}
	</div>
	<div class="col-3">
		<input type="number" min="0" class="form-control w-50" [(ngModel)]="template.numberOfRecords"
			[attr.data-cy]="'Level' + template.level + '_' + template.templateLabel + '_NumberOfRecordsInput'" />
	</div>
	<div class="col-7">
		<span class="indent" [style.width]="((template.level - 1) * 30) + 'px'"></span>
		<span [class.level-1]="template.level == 1" [class.level-2]="template.level == 2">
			{{template.templateLabel}}
		</span>
	</div>
</div>
<!-- DO NOT USE [hidden] - The UI will die if there's a loop -->
<ng-container *ngIf="template.numberOfRecords > 0">
	<rl-template-hierarchy-count-item *ngFor="let child of template.childTemplates" [charTypeId]="charTypeId"
		[template]="child">
	</rl-template-hierarchy-count-item>
</ng-container>