import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Provider } from "@angular/core";
import { AlliantDataService } from "app/+alliant/services/alliant-data.service";
import { ISyncedTemplate } from "company/components/data-bridge/models/alliant-mappings.models";
import { first, isEmpty, uniq } from "lodash";
import { SystemIndicators } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { AlliantEntityMinimal } from "rl-common/services/alliant/models/alliant-entity-minimal";
import { AlliantFilterVerb } from "rl-common/services/alliant/models/alliant-filter-verb";
import { IAlliantMultiItemSearchOptions } from "rl-common/services/alliant/models/i-alliant-multi-item-search-options";
import { ICollectionFieldDetail } from "rl-common/services/alliant/models/i-collection-field-detail";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SearchService } from "rl-common/services/search/search.service";
import { QueryUtil } from "rl-common/utils";
import { CharacteristicUtil } from "rl-common/utils/characteristic.util";
import { Subscription } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { FaIconDirective } from "../../../directives/fa-icon.directive";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { FindRlEntitiesComponent } from "../find-rl-entities/find-rl-entities.component";
import { FindRlEntitiesModalAdapter, IFindRlEntitiesModalComponent } from "./find-rl-entities-modal.adapter";
import { ISelectEvent } from "./find-rl-entities-modal.models";

@Component({
	selector: "rl-find-rl-entities-modal",
	templateUrl: "./find-rl-entities-modal.component.html",
	styleUrls: ["./find-rl-entities-modal.component.scss"],
	imports: [LoaderComponent, FindRlEntitiesComponent, NgIf, FaIconDirective]
})
export class FindRlEntitiesModalComponent implements OnInit, OnDestroy, IFindRlEntitiesModalComponent {

	@Input()
	fieldDetail: ICollectionFieldDetail;

	@Input()
	entity: AlliantEntityMinimal;

	@Input()
	mappedTemplates: ISyncedTemplate[] = [];

	@Output()
	onSelect = new EventEmitter<ISelectEvent>();

	selected: IEntitySearchDoc[] = [];

	isLoading = false;
	isSubmitting = false;

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _alliantDataService: AlliantDataService,
		private readonly _oneConfig: OneConfigService,
		private readonly _searchService: SearchService,
		private readonly _growler: GrowlerService
	) { }

	ngOnInit(): void {
		if (this.entity) {
			this.isLoading = true;

			// if a selection is passed in, let's find the corresponding rl entity
			const charTypeId = first(this.mappedTemplates).charTypeId;
			const alliantIdSolrLabels = this._oneConfig.getTemplates(charTypeId).map(template => {
				const templateCmd = this._oneConfig.getTemplateMetaData(charTypeId, template.templateID);
				const alliantIdCmd = templateCmd.characteristicMetaDatas.find(x => x.systemIndicator === SystemIndicators.AlliantId);
				if (!alliantIdCmd) {
					return null;
				}
				return CharacteristicUtil.GetCharSolrLabel(alliantIdCmd.dataTypeID, alliantIdCmd.multipleIndicator, alliantIdCmd.tagLabel);
			})
				.filter(solrLabel => !isEmpty(solrLabel));

			// query solr by the alliant id field
			const distinctLabels = uniq(alliantIdSolrLabels);
			const alliantIdQueries = distinctLabels.map(x => QueryUtil.$eq(x, this.entity.guid));
			const query = QueryUtil.$or(...alliantIdQueries);

			const options: ISearchRequestOptions = {
				rows: 1,
				start: 0,
				facetFields: [],
				filterQueries: [],
			};
			const sub = this._searchService.search(charTypeId, "", query, options).pipe(
				finalize(() => this.isLoading = false)
			).subscribe(response => {
				this.selected = response.documents;
				if (isEmpty(response.documents)) {
					this._growler.warning().growl(`${this.entity.displayName} does not appear to be data bridged.`);
				}
			});

			this._subs.push(sub);
		}
	}

	close() {
		this.onSelect.emit({ success: false, entities: [] });
	}

	done() {
		this.isSubmitting = true;
		const doc = first(this.selected);
		if (!doc) {
			this.onSelect.emit({ success: true, entities: [] });
		}

		// grabs the alliant entity when the user is finished searching/selecting the rl entity
		const templateCmd = this._oneConfig.getTemplateMetaData(doc.charTypeID, doc.templateID);
		const alliantIdCmd = templateCmd.characteristicMetaDatas.find(x => x.systemIndicator === SystemIndicators.AlliantId);
		const solrLabel = CharacteristicUtil.GetCharSolrLabel(alliantIdCmd.dataTypeID, alliantIdCmd.multipleIndicator, alliantIdCmd.tagLabel);
		const alliantId = doc.characteristics[solrLabel];
		const endpoint = `api/data/${this.fieldDetail._typeInfo._domain}`;
		const searchOptions: IAlliantMultiItemSearchOptions = {
			pageSize: 1,
			rowOffset: 0,
			sortKey: null,
			sortDir: 0,
			filters: [{ field: "guid", verb: AlliantFilterVerb.eq, value: alliantId }],
			include: ["displayName"]
		};
		const sub = this._alliantDataService.searchAlliantData(endpoint, searchOptions).pipe(
			map(response => {
				const alliantEntity = first(response.items);
				if (!alliantEntity) {
					this._growler.error().growl(`${doc.title} does not exist in alliant.`);
				}
				return alliantEntity;
			})
		).subscribe(entity => {
			this.onSelect.emit({ success: true, entities: [entity] });
		});

		this._subs.push(sub);
	}

	setSelected(selectedRows: IEntitySearchDoc[]) {
		this.selected = selectedRows;
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}

export const FINDRLENTITIES_MODAL_PROVIDER: Provider = {
	provide: FindRlEntitiesModalAdapter,
	useFactory: () => new FindRlEntitiesModalAdapter(FindRlEntitiesModalComponent)
};

