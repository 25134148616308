<ng-container [ngSwitch]="pendingResync">
	<ngb-alert *ngSwitchCase="true" type="warning" [dismissible]="false">
		<i class="far  fa-exclamation-triangle"></i> A Rights specific configuration change has been made and
		Avails/Conflicts are currently undergoing a refresh. A
		confirmation email will be sent to specified participants when the refresh has completed.
	</ngb-alert>
	<ng-container *ngSwitchDefault [ngSwitch]="divAvailsStatus">
		<ngb-alert *ngSwitchCase="DivAvailsStatus.ConfigMismatch" type="danger" [dismissible]="false">
			<i class="far  fa-exclamation-triangle"></i> Our Rightsline Support Team has been alerted to conduct a
			refresh of Avails data because a Rights specific configuration
			change has been made. Once the refresh has been completed, this message will no longer appear.
		</ngb-alert>
		<ngb-alert *ngSwitchCase="DivAvailsStatus.Inactive" type="warning" [dismissible]="false">
			<i class="far  fa-exclamation-triangle"></i> Avails Calculation is not active for your division. Please
			contact our Rightsline Support Team for further assistance.
		</ngb-alert>
		<ngb-alert *ngSwitchCase="DivAvailsStatus.Paused" type="warning" [dismissible]="false">
			<i class="far  fa-exclamation-triangle"></i> Avails/Conflicts Calculation is paused for your division.
			Please contact our Rightsline Support Team for further
			assistance.
		</ngb-alert>
	</ng-container>
</ng-container>