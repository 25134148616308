import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { every, first, flatMap, isEmpty, some } from "lodash";
import { IEntity } from "rl-common/models/i-entity";
import { IEntityRelationship } from "rl-common/models/i-entity-relationship";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { delay, filter, map, take } from "rxjs/operators";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { TransferAssociationCharTypeComponent } from "./transfer-association-char-type/transfer-association-char-type.component";
import { TransferAssociationsService } from "./transfer-associations.service";

export interface IAssociationsChangedEvent {
	selectedAssociations: IEntityRelationship[];
	deletedAssociations: IEntityRelationship[];
}

export interface IRelationshipOption {
	direction: RelationshipTypes;
	label: string;
}

@Component({
	selector: "rl-transfer-associations",
	templateUrl: "./transfer-associations.component.html",
	styleUrls: ["./transfer-associations.component.scss"],
	imports: [NgIf, ReactiveFormsModule, NgFor, NgbPopover, FormsModule, TransferAssociationCharTypeComponent, LoaderComponent, AsyncPipe]
})
export class TransferAssociationsComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input()
	originalEntity: IEntity;

	private _newCharTypeId: number;

	private _newTemplateId: number;

	private _newEntityTitle: string;

	@Output()
	onAssociationsChanged = new EventEmitter<IAssociationsChangedEvent>();

	@Output()
	onAssociateNewEntityChanged = new EventEmitter<RelationshipTypes>();

	@ViewChildren(TransferAssociationCharTypeComponent)
	entityAssociationCharTypeComponents: QueryList<TransferAssociationCharTypeComponent>;

	areAllSelected: boolean = true;

	charTypes = toSignal(this._transferAssociationsService.charTypes$.pipe(
		map(charTypes => {
			// exclude any groups where all the associations are empty
			return (charTypes ?? []).filter(x => !x.groups.every(g => isEmpty(g.associations)));
		})
	));

	isLoading$ = this._transferAssociationsService.isLoading$;

	charTypeKeys: number[];
	associateNewEntityControl: UntypedFormControl;
	associationDirectionControl: UntypedFormControl;

	private _parentRelationshipOption: IRelationshipOption = { direction: RelationshipTypes.Parent, label: "Parent" };
	private _childRelationshipOption: IRelationshipOption = { direction: RelationshipTypes.Child, label: "Child" };

	relationshipOptions: IRelationshipOption[] = [];

	private readonly _subscriptions: Subscription[] = [];

	@Input()
	set newCharTypeId(newCharTypeId: number) {
		const changed = this._newCharTypeId !== newCharTypeId;
		this._newCharTypeId = newCharTypeId;
		if (changed) {
			this.fetchRelationshipOptions();
		}
	}

	get newCharTypeId() {
		return this._newCharTypeId;
	}

	@Input()
	set newTemplateId(newTemplateId: number) {
		const changed = this._newTemplateId !== newTemplateId;
		this._newTemplateId = newTemplateId;
		if (changed) {
			this.fetchRelationshipOptions();
		}
	}

	get newTemplateId() {
		return this._newTemplateId;
	}

	@Input()
	set newEntityTitle(newEntityTitle: string) {
		this._newEntityTitle = newEntityTitle;
	}

	get newEntityTitle() {
		return this._newEntityTitle;
	}

	get canBeDirectlyAssociated() {
		return some(this.relationshipOptions);
	}

	constructor(
		private readonly _transferAssociationsService: TransferAssociationsService,
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _oneConfigService: OneConfigService
	) { }

	ngOnInit() {
		this.associateNewEntityControl = this._formBuilder.control(false);
		this.associationDirectionControl = this._formBuilder.control(first(this.relationshipOptions));

		this.toggleAssociationDirectionForm();

		const sub1 = this.associateNewEntityControl.valueChanges.subscribe(() => {
			this.toggleAssociationDirectionForm();
			this.emitNewEntityAssociationChange();
		});
		this._subscriptions.push(sub1);

		const sub2 = this.associationDirectionControl.valueChanges.subscribe(() => {
			this.emitNewEntityAssociationChange();
		});

		this._subscriptions.push(sub2);

	}

	ngAfterViewInit(): void {
		const selectedAssociations = flatMap(this.charTypes(), ct => flatMap(ct.groups, g => g.associations.filter(a => a.isSelected).map(a => a.relationship)));
		const deletedAssociations = flatMap(this.charTypes(), ct => flatMap(ct.groups, g => g.associations.filter(a => !a.isSelected).map(a => a.relationship)));

		this.onAssociationsChanged.emit({
			selectedAssociations: selectedAssociations,
			deletedAssociations: deletedAssociations
		});

		const sub = this._transferAssociationsService.charTypes$.pipe(
			filter(charTypes => !!charTypes),
			take(1),
			delay(0)
		).subscribe(() => {
			this.updateAssociations();
		});

		this._subscriptions.push(sub);
	}

	private fetchRelationshipOptions() {
		if (!this.originalEntity || !this.newCharTypeId || !this.newTemplateId) {
			return;
		}
		this.relationshipOptions = [];
		const originalCharTypeId = this.originalEntity.charTypeID;
		const originalTemplateId = this.originalEntity.templateID;
		const childTemplates = this._oneConfigService.getChildAssocTemplates(originalCharTypeId, originalTemplateId, this.newCharTypeId);
		const parentTemplates = this._oneConfigService.getParentAssocTemplates(originalCharTypeId, originalTemplateId, this.newCharTypeId);

		if (childTemplates.find(x => x.templateID === this.newTemplateId)) {
			this.relationshipOptions.push(this._childRelationshipOption);
		}
		if (parentTemplates.find(x => x.templateID === this.newTemplateId)) {
			this.relationshipOptions.push(this._parentRelationshipOption);
		}

		const firstOption = first(this.relationshipOptions);
		this.associationDirectionControl.setValue(firstOption && firstOption.direction);

	}

	private toggleAssociationDirectionForm() {
		const associateToNewEntity = this.associateNewEntityControl.value as boolean;
		if (associateToNewEntity) {
			this.associationDirectionControl.enable();
		} else {
			this.associationDirectionControl.disable();
		}
	}

	private emitNewEntityAssociationChange() {
		let direction: RelationshipTypes = null;
		if (this.associateNewEntityControl.value) {
			direction = this.associationDirectionControl.value;
		}
		this.onAssociateNewEntityChanged.emit(direction);
	}

	toggleAllAssociations() {
		const areAllSelected = this.areAllSelected;
		this.entityAssociationCharTypeComponents.toArray().forEach(comp => {
			comp.areAllSelected = areAllSelected;
			comp.toggleSelectAll();
		});

		this.updateAssociations();
	}

	updateAssociations() {
		this.areAllSelected = every(this.entityAssociationCharTypeComponents.toArray(), comp => comp.areAllSelected);

		const selectedAssociations = flatMap(this.charTypes(), ct => flatMap(ct.groups, g => g.associations.filter(a => a.isSelected).map(a => a.relationship)));
		const deletedAssociations = flatMap(this.charTypes(), ct => flatMap(ct.groups, g => g.associations.filter(a => !a.isSelected).map(a => a.relationship)));

		this.onAssociationsChanged.emit({
			selectedAssociations: selectedAssociations,
			deletedAssociations: deletedAssociations
		});
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
