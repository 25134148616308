import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { AngularEditorConfig, AngularEditorModule } from "@kolkov/angular-editor";
import { HtmlSanitizerService } from "rl-common/services/html-sanitzer.service";

@Component({
	selector: "rl-html-editor",
	templateUrl: "./html-editor.component.html",
	styleUrls: ["./html-editor.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HtmlEditorComponent),
			multi: true
		}
	],
	imports: [AngularEditorModule, ReactiveFormsModule, FormsModule]
})
export class HtmlEditorComponent implements ControlValueAccessor, OnInit {
	@Input()
	set placeholder(placeholder: string) {
		this.editorConfig.placeholder = placeholder;
	}

	@Input()
	disabled = false;

	html: string;

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "250px",
		minHeight: "200px",
		maxHeight: "auto",
		width: "auto",
		minWidth: "0",
		translate: "yes",
		enableToolbar: true,
		showToolbar: true,
		placeholder: "",
		sanitize: false,
		toolbarPosition: "top",
	};

	onChange = (html: string) => {
		this.html = this.sanitizeHtml(html);
	};
	onTouched = () => { };
	constructor(
		private readonly _htmlSanitizerService: HtmlSanitizerService
	) {

	}

	writeValue(html: string): void {
		this.html = this.sanitizeHtml(html);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnInit() {
	}

	sanitizeHtml(html: string) {
		const sanitizedHtml = this._htmlSanitizerService.sanitizeWhilePreservingStyles(html);
		return sanitizedHtml;
	}
}
