import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { last, partition } from "lodash";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { AlertsService } from "rl-common/services/alerts/alerts.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { Observable } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { AlertFormComponent } from "../alert-form/alert-form.component";

@Component({
    selector: "rl-edit-date-alerts-config-modal",
    templateUrl: "./edit-date-alerts-config-modal.component.html",
    styleUrls: ["./edit-date-alerts-config-modal.component.scss"],
    imports: [NgIf, AlertFormComponent, LoaderComponent]
})
export class EditDateAlertsConfigModalComponent {

	private _alerts: ICharDataExtDataAlert[] = [];

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	recCharId: number;

	@Input()
	isBulkEdit = false;

	@Input()
	parentEntityId?: string = "";

	@Input()
	editingAlert: ICharDataExtDataAlert;

	@Input()
	isSubmitting = false;

	@Input()
	editingLabel: string;

	@Input()
	cmds: ICharacteristicMetaData[];

	@Input()
	isEditAlert: boolean;

	@Input()
	dateAlertEditingLabel: string;

	@Input()
	templateId: number;

	@Input()
	charTypeId: number;

	@Input()
	characteristicId: number;

	@Input()
	notificationMessagesEnabled: boolean;

	@Input()
	set alerts(alerts: ICharDataExtDataAlert[]) {
		const alertGroups = partition(alerts, alert => alert.recordID === 0);
		this._alerts = last(alertGroups);
	}

	@Output()
	onChange = new EventEmitter<ICharDataExtDataAlert[]>();

	get alerts() {
		return this._alerts;
	}

	isLoading = false;

	constructor(private readonly _activeModal: NgbActiveModal,
		private readonly _alertService: AlertsService,
		private readonly _growlerService: GrowlerService) { }

	backToReadView() {}

	addNewAlert(alert: ICharDataExtDataAlert) {
		this.isSubmitting = true;
		const createAlert$: Observable<void> = this._alertService.createAlert(alert);

		createAlert$.pipe(
			finalize(() => {
				this.isSubmitting = false;
			}),
			switchMap(() => this.fetchAlerts())
		).subscribe(() => {
			this.emitAlertsChange();
			this._activeModal.close();
			this._growlerService.success().growl("Date Alert added.");
		});
	}

	updateAlert(alert: ICharDataExtDataAlert) {
		this.isSubmitting = true;
		const updateAlert$: Observable<void> = this._alertService.updateAlert(alert);

		updateAlert$.pipe(
			finalize(() => {
				this.isSubmitting = false;
			}),
			switchMap(() => this.fetchAlerts())
		).subscribe(() => {
			this.emitAlertsChange();
			this._activeModal.close();
			this._growlerService.success().growl("Date Alert updated.");
		});
	}

	private fetchAlerts() {
		this.isLoading = true;

		const obs: Observable<ICharDataExtDataAlert[]> = this._alertService.getAlerts(this.charTypeId, this.templateId, this.characteristicId, null);

		return obs.pipe(
			tap(alerts => this._alerts = alerts),
			finalize(() => this.isLoading = false)
		)
	}

	private emitAlertsChange() {
		const alerts = this._alerts;
		this.onChange.emit(alerts);
	}

	closeModal() {
		this._activeModal.close();
	}

}