import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { first, union, unionWith } from "lodash";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { EntityRelationshipDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/entity-relationship-data-select.strategy";
import { AssocModuleSelectDataSource } from "rl-common/components/grid/datasource/search/assoc-module-select.datasource";
import { SqlAssocEntitySearchDataSource } from "rl-common/components/grid/datasource/search/sql-assoc-entity-search.datasource";
import { IndexedRowData } from "rl-common/components/grid/models/indexed-row-data";
import { SearchOptionsFactory } from "rl-common/factories";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IQueryNode } from "rl-common/models/i-query-node";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { combineLatest, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { EntitySearchComponent } from "../../../entities/entity-search/entity-search.component";
import { AssociationChipsComponent } from "../../../new-association-modal/new-association-wizard/select-associations/association-chips/association-chips.component";
import { CharTypeId, RelationshipIndicatorIds } from "./../../../../rl-common.consts";
import { OneConfigService } from "./../../../../services/one-config/one-config.service";
import { QueryUtil } from "./../../../../utils/query.util";
import { InputListComponent, IValidateEvent } from "./input-list/input-list.component";


@Component({
	selector: "rl-sql-component-relationship",
	templateUrl: "./sql-component-relationship.component.html",
	styleUrls: ["./sql-component-relationship.component.scss"],
	imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, EntitySearchComponent, InputListComponent, NgbNavOutlet, NgIf, AssociationChipsComponent]
})
export class SqlComponentRelationshipComponent implements OnInit, OnDestroy, OnChanges {
	@Input()
	assocCharTypeId: number;

	@Input()
	charTypeId: number;

	@Input()
	dataSource: SqlAssocEntitySearchDataSource<IEntitySearchDoc, unknown>;

	@Output()
	onEntityRelationshipChange = new EventEmitter<IEntityRelationshipState<number, IEntitySearchDoc>>();

	isTablesOrRights = false;
	selectedDataFromSearch: IRecordTitle[] = [];
	private readonly _subs: Subscription[] = [];
	gridCount = 0;
	inputListCount = 0;
	selectedInputList: IEntitySearchDoc[] = [];
	searchOptions: SearchOptions = {} as SearchOptions;
	parentCharTypeId: CharTypeId;
	parentRecordId: number;
	direction: RelationshipTypes = RelationshipTypes.Child;
	matchesParentQuery: IQueryNode;
	shouldUpdateSelectedRows = false;

	get isAssociatingChild() {
		return this.direction === RelationshipTypes.Child;
	}

	constructor(
		private readonly _parentEntityService: ParentEntityService,
		private readonly _oneConfigService: OneConfigService,
	) { }

	ngOnInit(): void {
		this.buildSearchOptions();
		this.isTablesOrRights = this.assocCharTypeId === CharTypeId.Right || this.assocCharTypeId === CharTypeId.Usage;
		const sub = combineLatest([this.dataSource.dataSelectStrategy.selectStateChange$, this.dataSelectStrategy.netChanges$])
			.pipe(filter(rd => !!rd))
			.subscribe(([selectedState, nc]) => {
				this.emit();
			});

		const sub2 = this.dataSource.indexedRowData$
			.pipe(
				filter((ird) => !!ird),
				take(1)
			)
			.subscribe((initialRowData) => {
				if (!this.dataSelectStrategy?.defaultSelectedIds) {
					return;
				}

				if (this.dataSelectStrategy.selectAllEnabled && this.dataSource.rowCount$.value > 1
					&& this.dataSelectStrategy.defaultSelectedIds.length === this.dataSource.rowCount$.value) {
					if (this.searchOptions.parentCharTypeId === CharTypeId.Property && this.searchOptions.charTypeId === CharTypeId.Property) {
						let defSelectedIdsLength = this.dataSelectStrategy.defaultSelectedIds.length;
						if (this.dataSelectStrategy.defaultSelectedIds.find(d => d === this.searchOptions.parentRecordId)) {
							defSelectedIdsLength--;
							if (defSelectedIdsLength === this.dataSource.rowCount$.value) {
								this.dataSelectStrategy.selectAll();
							} else {
								const selectedRowData = initialRowData.filter(rd => this.dataSelectStrategy.defaultSelectedIds.includes(rd.data.recordID));
								if (selectedRowData.length > 0) {
									this.dataSource.dataSelectStrategy.selectRows(selectedRowData);
								}
							}
						}
					} else {
						this.dataSelectStrategy.selectAll();
					}
				} else if (this.dataSelectStrategy.isRequired && initialRowData.length === 1 && this.assocCharTypeId === CharTypeId.Property) {
					this.dataSelectStrategy.selectRow(first(initialRowData));
				} else {
					this.shouldUpdateSelectedRows = true;
					this.updateSelectedRowsOnPageChange(initialRowData);
				}
			});
		const sub5 = this.dataSource.indexedRowData$
			.pipe(
				filter((ird) => !!ird),
			)
			.subscribe((rowData) => {
				if (this.shouldUpdateSelectedRows) {
					this.updateSelectedRowsOnPageChange(rowData);
				}
			});

		const sub3 = this.dataSelectStrategy.count$
			.subscribe(c => this.gridCount = c);

		const sub4 = this.dataSource.formGroup.statusChanges.subscribe(() => {
			this.emit();
		});
		this._subs.push(sub, sub2, sub3, sub4, sub5);
	}

	private updateSelectedRowsOnPageChange(initialRowData: IndexedRowData<IEntitySearchDoc>[]) {
		const netChanges = this.dataSelectStrategy.netChanges$.value;
		let selectedRowData = [];
		if (this.dataSelectStrategy.isAllSelected) {
			selectedRowData = initialRowData.filter(rd => {
				const excludedRecordIds = Array.from(netChanges.deletedIds);
				return !excludedRecordIds.includes(rd.data.recordID);
			});
		} else {
			selectedRowData = initialRowData.filter(rd => {
				const includedRecordIds = [...this.dataSelectStrategy.defaultSelectedIds, ...netChanges.addedIds];
				const excludedRecordIds = Array.from(netChanges.deletedIds);
				return includedRecordIds.includes(rd.data.recordID) && !excludedRecordIds.includes(rd.data.recordID);
			});
		}
		if (selectedRowData.length > 0) {
			this.dataSource.dataSelectStrategy.selectRows(selectedRowData);
		}
	}

	ngOnChanges(changes: ComponentChanges<this>) {
		if (changes.dataSource && changes.dataSource.currentValue && changes.dataSource.currentValue instanceof AssocModuleSelectDataSource) {
			const assocDataSource = changes.dataSource.currentValue as AssocModuleSelectDataSource<any, any>;
			if (assocDataSource.direction !== undefined && assocDataSource.direction !== null) {
				this.direction = assocDataSource.direction;
			}
		}
	}

	get dataSelectStrategy() {
		return this.dataSource.dataSelectStrategy as EntityRelationshipDataSelectStrategy<IEntitySearchDoc, number, IEntitySearchDoc>;
	}

	private buildSearchOptions() {
		this.searchOptions = this.getSearchOptions();
		let parentTemplateId = null;
		if (this.assocCharTypeId === CharTypeId.Mock) {
			this.searchOptions.charTypeId = this.assocCharTypeId;
		} else if (this._parentEntityService.hasGrandParent()) {
			this.searchOptions.parentCharTypeId = this._parentEntityService.parent.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.parent.recordId;
			parentTemplateId = this._parentEntityService.parent.templateId;
			this.searchOptions.relatedCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.relatedRecordId = this._parentEntityService.recordId;
		} else {
			this.searchOptions.parentCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.recordId;
		}
		const validTemplates = this._oneConfigService.getChildAssocTemplateIds(this.searchOptions.parentCharTypeId, parentTemplateId, this.assocCharTypeId);
		this.searchOptions.filteredTemplateIds = validTemplates;
		if (this.searchOptions.parentCharTypeId === CharTypeId.Relationship && this.searchOptions.charTypeId === CharTypeId.Amount) {
			this.searchOptions.selectType = SelectType.Radio;
		}
		this.parentCharTypeId = this.searchOptions.parentCharTypeId;
		this.parentRecordId = this.searchOptions.parentRecordId;
	}

	private getSearchOptions() {
		return SearchOptionsFactory.buildTaggedRelationshipOptions(this._parentEntityService.charTypeId, this.assocCharTypeId, this.searchOptions.relatedCharTypeId);
	}

	public updateValidated(event: IValidateEvent) {
		const inputListRecordIds = event.validRecords.map(r => r.recordID);
		const indexedMatchingRows = this.dataSource.indexedRowData$?.value?.filter(rd => inputListRecordIds.includes(rd.data.recordID)) ?? [];
		const recordIds = indexedMatchingRows.map(x => x.data.recordID);
		const nonIndexedRows = event.validRecords.filter(x => recordIds.indexOf(x.recordID) < 0);
		const removedRows = event.deselectedRecords ?? [];
		removedRows.forEach(x => {
			this.dataSelectStrategy.deselectRowById(x.recordID, x);
		});
		this.dataSelectStrategy.selectRows(indexedMatchingRows);
		const dataSourceSelected = Array.from(this.dataSource.dataSelectStrategy.selectedState.selectedIds ?? new Set<unknown>());
		this.selectedInputList = event.validRecords.filter(x => dataSourceSelected.indexOf(x.recordID) === -1);
		if (nonIndexedRows.length > 0) {
			const selectedRows: IndexedRowData<IEntitySearchDoc>[] = [];
			nonIndexedRows.forEach(row => {
				const selectedRow: IndexedRowData<IEntitySearchDoc> = {
					index: row.recordID,
					rowPath: null,
					data: row
				};
				selectedRows.push(selectedRow);
			});
			this.dataSelectStrategy.selectRows(selectedRows);
		}
	}

	public removeSelection(recordTitle: IRecordTitle) {
		// We can't pull the entity search doc from the indexed row data due to page changes or filtering
		// TODO: We need a better way to go from a selected entity to the original grid row data type
		this.dataSource.dataSelectStrategy.deselectRowById(recordTitle.recordId, recordTitle.title);
	}

	private emit() {
		const rowData = this.dataSource.indexedRowData$?.value ?? [];
		const selectedInputOffPage = this.selectedInputList.filter(r => rowData.map(rd => rd.data.recordID).includes(r.recordID));
		this.inputListCount = this.selectedInputList.length;
		const selectedCount = this.getSelectedCount(selectedInputOffPage);
		const selectedIds = union(Array.from(this.dataSelectStrategy.selectedState.selectedIds), this.selectedInputList.map(r => r.recordID));
		const deselectedIds = Array.from(this.dataSelectStrategy.selectedState.deselectedIds);
		const inputListSelected = this.selectedInputList;
		const selectedValues = unionWith([...Array.from(this.dataSelectStrategy.selectedState.selectedValues), ...this.selectedInputList], (s, s2) => s.recordID !== s2.recordID);
		const model = this.dataSource.getSelectedSearchModel()[0];

		const matchesParentQueries = [
			QueryUtil.$eq(SearchFieldNames.Relationship.childCharTypeID, this.assocCharTypeId),
			QueryUtil.$eq(SearchFieldNames.Relationship.parentCharTypeID, this.parentCharTypeId),
			QueryUtil.$eq(SearchFieldNames.Relationship.parentRecordID, this.parentRecordId),
		];
		if (this.assocCharTypeId !== CharTypeId.User && !this.dataSource.isRelatedChild) {
			matchesParentQueries.push(QueryUtil.$eq(SearchFieldNames.Relationship.relInd, RelationshipIndicatorIds.Default));
		}
		const queries = matchesParentQueries;
		if (deselectedIds.length > 0) {
			const deselectedQuery = QueryUtil.$eq_none(SearchFieldNames.Relationship.childRecordID, deselectedIds);
			queries.push(deselectedQuery);
		}
		this.matchesParentQuery = QueryUtil.$join_parent_rel(
			QueryUtil.$and(...queries)
		);
		model.query = this.matchesParentQuery;
		model.rows = 1000;
		const isValid = !this.dataSource.formGroup.hasError("requiredGrid");
		this.onEntityRelationshipChange.emit({
			netChanges: this.dataSelectStrategy.netChanges$.value,
			gridSelected: this.dataSelectStrategy.selectedState,
			gridCount: this.dataSource.rowCount$.value,
			inputListSelected,
			defaultSelectedIds: this.dataSelectStrategy.defaultSelectedIds,
			model,
			selectedValues,
			selectedIds,
			isValid,
			selectedCount,
			labelFn: (val) => val.title
		});
	}

	private getSelectedCount(selectedInputOffPage: IEntitySearchDoc[]) {
		if (!this.dataSelectStrategy.selectedState.isAllSelected) {
			return this.dataSelectStrategy.selectedState.selectedIds.size + selectedInputOffPage.length;
		} else {
			return this.dataSource.rowCount$.value - this.dataSelectStrategy.selectedState.deselectedIds.size;
		}
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
