import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AlliantDataService } from "app/+alliant/services/alliant-data.service";
import { isEmpty } from "lodash";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { AlliantEntityMinimal } from "rl-common/services/alliant/models/alliant-entity-minimal";
import { AlliantFilterVerb } from "rl-common/services/alliant/models/alliant-filter-verb";
import { AlliantMultiEntityResult } from "rl-common/services/alliant/models/alliant-multi-entity-result";
import { IAlliantMultiItemSearchOptions } from "rl-common/services/alliant/models/i-alliant-multi-item-search-options";
import { IAlliantSearchFilter } from "rl-common/services/alliant/models/i-alliant-search-filter";
import { ICollectionFieldDetail } from "rl-common/services/alliant/models/i-collection-field-detail";
import { ICollectionPropertiesMetadata } from "rl-common/services/alliant/models/i-collection-properties-metadata";
import { IGridViewColumn } from "rl-common/services/grid-view/models/i-grid-view-column";
import { Subscription } from "rxjs";
import { filter, finalize, map, skip, tap } from "rxjs/operators";
import { ChipComponent } from "../../chip/chip.component";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";
import { LoaderComponent } from "../../panel/loader/loader.component";

export interface IContainsSearchCriteriaForm {
	id: FormControl<string>;
	displayName: FormControl<string>;
	description: FormControl<string>;
	bigdescription: FormControl<string>;
	formattedName: FormControl<string>;
	name: FormControl<string>;
}

@Component({
	selector: "rl-find-alliant-entities",
	templateUrl: "./find-alliant-entities.component.html",
	styleUrls: ["./find-alliant-entities.component.scss"],
	imports: [NgIf, ReactiveFormsModule, GridTableComponent, LoaderComponent, NgFor, ChipComponent]
})
export class FindAlliantEntitiesComponent implements OnInit, OnDestroy {

	@Input()
	fieldDetail: ICollectionFieldDetail;

	@Input()
	metadata: ICollectionPropertiesMetadata;

	@Input()
	selectedValues: AlliantEntityMinimal[];

	@Output()
	onSelect = new EventEmitter<AlliantEntityMinimal[]>();

	values: AlliantMultiEntityResult;
	reportType: string;

	gridOptions: GridOptions<AlliantEntityMinimal> = {
		defaultGetCellDataFn: (column, doc) => doc
	};

	dataSource: CommonGridDataSource<AlliantEntityMinimal>;

	containsForm: FormGroup<IContainsSearchCriteriaForm>;
	defaultPageSize = 20;
	availableFilters: string[] = ["id", "description"];

	get dataSourceLoaded() {
		return !!this.dataSource?.indexedRowData$.value;
	}

	get endpoint() {
		if (!this.fieldDetail) {
			return null;
		}
		return `api/data/${this.fieldDetail._typeInfo._domain}`;
	}

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _alliantData: AlliantDataService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _formBuilder: FormBuilder
	) { }

	ngOnInit(): void {
		const domain = this.fieldDetail._typeInfo._domain.toLocaleLowerCase();
		this.reportType = `entity_${domain}`;
		const columnStrategy = this._gridDataSourceBuilder.columnStrategies.alliantCollectionColumnViewStrategy<AlliantEntityMinimal>(
			this.reportType,
			(vc) => this.buildColumn(vc)
		);

		const selectStrategy = this._gridDataSourceBuilder.dataSelectStrategies.commonDataSelectStrategy<AlliantEntityMinimal, string, AlliantEntityMinimal>(row => row.guid, row => row)
			.setSelectAllEnabled(false)
			.withSelectType(GridSelectType.Radio);

		this.dataSource = this._gridDataSourceBuilder.commonGridDataSource<AlliantEntityMinimal>(row => row.guid)
			.setPaging({ pageSize: this.defaultPageSize })
			.withColumnStrategy(columnStrategy)
			.withDataSelectStrategy(selectStrategy)
			.withFetchFn((ds) => {
				const containsFilters = this.buildFilters(AlliantFilterVerb.contains, this.containsForm);
				const filters = containsFilters;
				const include = ds.columns$.value.map(col => col.key.toString());
				include.push("sid");
				const endpoint = this.endpoint;
				const pageSize = ds.pageSize$.value;
				const rowOffset = ds.rowOffset$.value;
				const sortKey = ds.sortKey$.value as string;
				const sortDir = ds.sortDir$.value;
				const collectionDomain = this.metadata?._singleDomain;
				const fieldApiName = this.fieldDetail?._apiName;
				const filterForField = `${collectionDomain}.${fieldApiName}`;
				const searchOptions: IAlliantMultiItemSearchOptions = { pageSize, rowOffset, sortKey, sortDir, filters, include, filterForField };
				return this._alliantData.searchAlliantData(endpoint, searchOptions).pipe(
					tap((results) => {
						// remove any columns that are not available
						const availableColumns = ds.columns$.value.filter(col => !results.missingIncludes.includes(col.key.toString()));
						this.availableFilters = availableColumns.map(col => col.key.toString());
						ds.setColumns(availableColumns);
					}),
					map((results) => ({ rowCount: results.totalItemCount, rowData: results.items } as IGridFetchResults<AlliantEntityMinimal>)),
					finalize(() => {
						this.containsForm.markAsPristine();
					})
				);
			});

		// emits the selection event when the selection changes from the grid
		const selectSub = selectStrategy.selectStateChange$.pipe(
			skip(1) // skip the init state
		).subscribe(state => {
			const selectedInGrid = Array.from(state.selectedValues);
			this.selectedValues = selectedInGrid;
			this.onSelect.emit(selectedInGrid);
		});

		// sets the selected values in the grid on each page
		const indexedRowDataSub = this.dataSource.indexedRowData$.pipe(
			filter(rows => !!rows),
		).subscribe(rows => {
			if (!isEmpty(this.selectedValues)) {
				const selectedIds = (this.selectedValues ?? []).map(x => x.guid);
				const selected = rows.filter(row => selectedIds.includes(row.data.guid));

				// only select rows if they exist on the current page
				if (!isEmpty(selected)) {
					this.dataSource.dataSelectStrategy.selectRows(selected);
				}
			}
		});

		this._subs.push(selectSub, indexedRowDataSub);

		this.containsForm = this._formBuilder.group<IContainsSearchCriteriaForm>({
			id: new FormControl<string>(""),
			displayName: new FormControl<string>(""),
			description: new FormControl<string>(""),
			formattedName: new FormControl<string>(""),
			bigdescription: new FormControl<string>(""),
			name: new FormControl<string>(""),
		});

		this.runSearch();
	}

	buildColumn(vc: IGridViewColumn): GridColumn {
		const column: GridColumn<AlliantEntityMinimal> = {
			key: vc.columnKey,
			headerName: this.getHeaderName(vc),
			renderer: "text",
			sortKey: vc.columnKey,
			width: "auto",
			getCellData: (x) => this.getCellData(x, vc)
		};
		return column;
	}

	getHeaderName(column: IGridViewColumn) {
		switch (column.columnKey) {
			case "id":
				return "ID";
			case "name":
				return "Name";
			case "formattedName":
				return "Formatted Name";
			case "description":
				return "Description";
			case "adminClassReference":
				return "Admin Class";
			case "statusReference":
				return "Status";
			case "systemModifiedDatetime":
				return "Modified On";
		}
		return column.displayName;
	}

	getCellData(entity: AlliantEntityMinimal, column: IGridViewColumn) {
		switch (column.columnKey) {
			case "adminClassReference":
				return entity.adminClassReference?.displayName;
			case "statusReference": {
				return entity.statusReference?.displayName;
			}
		}
		return entity[column.columnKey];
	}

	private buildFilters(verb: AlliantFilterVerb, form: FormGroup): IAlliantSearchFilter[] {
		const filters = Object.keys(form.controls)
			.map(key => {
				const value = form.controls[key].value;
				const filter: IAlliantSearchFilter = { field: key, verb, value };
				return filter;
			})
			.filter(x => x.value && !isEmpty(x.value));
		return filters;
	}

	runSearch() {
		this.dataSource.setPaging({ rowOffset: 0, pageSize: this.defaultPageSize });
		this.dataSource.fetchRows().subscribe();
	}

	remove(entity: AlliantEntityMinimal) {
		// update the selected values
		this.selectedValues = this.selectedValues.filter(x => x.guid !== entity.guid);

		// update the grid
		this.dataSource.dataSelectStrategy.deselectRowById(entity.guid, entity);

		this.onSelect.emit(this.selectedValues);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
