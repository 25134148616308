<div data-cy="AddRoleModal">
	<div class="modal-header">
		<h2 class="modal-title">{{action}} Identity Role</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="ModalCloseButton">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body scroll-vert">
		<div class="alert alert-danger" *ngIf="isDuplicate">
			An Identity Role with this name already exists.
		</div>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="char-data__label pt-2">
					<span>Role Name</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<input type="text" [formControl]="roleName" (keyup)="checkForError()" class="form-control"
							data-cy="RoleNameField" />
					</div>
				</div>
			</div>
			<div class="char-data__group__element">
				<div class="char-data__label pt-2">
					<span>Xref</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<input type="text" [formControl]="roleXref" class="form-control" data-cy="RoleXrefField" />
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label pt-2">
					<span>Activity Policy</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<select class="form-select" [formControl]="roleActivityPolicy" data-cy="ActivityPolicySelect">
							<option *ngFor="let policy of activityPolicies" [ngValue]="policy.securityPolicyID"
								[attr.data-cy]="policy.securityPolicyID">
								{{policy.policyTitle}}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="char-data__group__element">
				<div class="char-data__label pt-2">
					<span>Data Policy</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<select class="form-select" [formControl]="roleDataPolicy" data-cy="DataPolicySelect">
							<option *ngFor="let policy of dataPolicies" [ngValue]="policy.securityPolicyID"
								[attr.data-cy]="policy.securityPolicyID">
								{{policy.policyTitle}}
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" class="btn btn-light me-3" data-cy="ModalCancelButton">Cancel</button>
		<button (click)="save()" [disabled]="!canSave()" class="btn btn-success" data-cy="ModalSaveButton"
			[class.disabled]="isSaving">
			<i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
			{{buttonText}}
		</button>
	</div>
</div>