import { ArchwizardModule } from "@achimha/angular-archwizard";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { isEmpty, some } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicDataCollection } from "rl-common/models/i-characteristic-data-collection";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntityCharDataPair } from "rl-common/models/i-entity-char-data-pair";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { CharacteristicUtil, TagLabel } from "rl-common/utils/characteristic.util";
import { of, Subscription } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ModDetailService } from "../../../services/mod-detail/mod-detail.service";
import { RoyaltiesService } from "../../../services/royalties/royalties.service";
import { IDUtil } from "../../../utils";
import { EntityRelationshipComponent, ISelectedChangeEvent } from "../../associations/entity-relationships/entity-relationship/entity-relationship.component";
import { CharDataTableComponent } from "../../char-data/char-data-table.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { IRoyaltySplit, IRoyaltySplitData } from "../royalties.models";
import { EditRoyaltySplitComponent } from "../royalty-split/edit-royalty-split/edit-royalty-split.component";
import { ISplitsChangedEvent } from "../royalty-split/royalty-split-table/royalty-split-table.component";

@Component({
    selector: "rl-create-royalty-wizard",
    templateUrl: "./create-royalty-wizard.component.html",
    styleUrls: ["./create-royalty-wizard.component.scss"],
    imports: [ArchwizardModule, NgIf, CharDataTableComponent, EntityRelationshipComponent, EditRoyaltySplitComponent, LoaderComponent]
})
export class CreateRoyaltyWizardComponent implements OnInit, OnDestroy {

	@Output()
	onComplete = new EventEmitter<string>();

	@Output()
	onCancel = new EventEmitter<void>();

	royaltyCharDataPair: IEntityCharDataPair;
	royaltyRelRecId: number;
	_template: ICharacteristicTemplate;
	catalogCharType = CharTypeId.Property;
	isApplying = false;
	charData: ICharacteristicData[] = [];
	templateMetaData: ICharacteristicMetaDataCollection;
	validTemplates: ICharacteristicTemplate[];
	selectedCatalogItems: IRecordTitle[] = [];
	editMode = true;
	tempCharDataCollection: ICharacteristicDataCollection;
	royaltySplits: IRoyaltySplitData;
	splits: IRoyaltySplit[];
	isSplitsValid = true;
	parentCharTypeId: number;
	parentRecordId: number;
	charDataIsValid: boolean;

	private readonly _subscriptions: Subscription[] = [];

	@Input()
	set template(template: ICharacteristicTemplate) {
		const oldTemplateId = this._template ? this._template.templateID : null;
		if (template && template.templateID !== oldTemplateId) {
			this.loadEmptyCharData(template);
			this.loadChildrenCatalogTemplates(template);
		}
		this._template = template;
	}

	get template() {
		return this._template;
	}

	get charDataLoaded() {
		return this.charData && this.templateMetaData && this.validTemplates;
	}

	get hasIncomeGroups() {
		if (!this.templateMetaData || !this.charData) {
			return false;
		}
		const incomeGroupsChar = CharacteristicUtil.getCharacteristics(TagLabel.RoyaltyIncomeGroup, this.templateMetaData, this.charData);
		return some(incomeGroupsChar);
	}

	get canSave() {
		return this.charDataIsValid && this.selectedCatalogItems.length > 0 && this.isSplitsValid && !this.isApplying;
	}

	constructor(
		private readonly _modDetailService: ModDetailService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _royaltyService: RoyaltiesService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _sessionService: SessionService,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit() {
		const entity = this._modDetailService.entityData.entity;
		this._parentEntityService.initializeParent({
			charTypeId: CharTypeId.Royalty,
			recordId: -1,
			templateId: this.template.templateID,
			parent: {
				charTypeId: entity.charTypeID,
				recordId: entity.recordID,
				templateId: entity.templateID,
			}
		});
	}

	setSelectedCatalogItems(event: ISelectedChangeEvent, charTypeId: number) {
		this.selectedCatalogItems = event.selectedRelationships;
	}

	cancel() {
		this.onCancel.emit();
	}

	setupRoyaltySplits() {
		this.tempCharDataCollection = {
			characteristicDatas: this.charData,
			entityID: undefined,
			entityStatus: undefined,
			templateMetaData: this.templateMetaData
		};

		this.royaltySplits = {
			relRecordId: -1,
			royaltyCharData: null,
			incomeGroupCharacteristicIds: [],
			aboveSplitDictionary: {},
			belowSplitDictionary: {},
			incomeGroups: [],
			splits: []
		};
	}

	saveRoyalty() {
		this.isApplying = true;
		const catalogItems = this.selectedCatalogItems.map((x) => IDUtil.toID(this._sessionService.divId, CharTypeId.Property, x.recordId));
		const sub = this._modDetailService.entityIdString$.pipe(
			switchMap(parentEntityId =>
				this._royaltyService.createRoyaltyAndSplits(this.template.templateID, parentEntityId, this.charData, this.splits, catalogItems)),
			catchError(err => {
				const statusCode = err.status;
				if (statusCode < 500) {
					this._growlerService.error().growl(err?.error?.message ?? `Create Failed`);
					return of(null);
				}
				throw err;
			})
		).subscribe(() => {
			this.onComplete.emit();
			this.isApplying = false;
		});
		this._subscriptions.push(sub);
	}

	private loadChildrenCatalogTemplates(template: ICharacteristicTemplate) {
		this.validTemplates = this._oneConfigService.getChildAssocTemplates(CharTypeId.Royalty, template.templateID, CharTypeId.Property);
	}

	private loadEmptyCharData(template: ICharacteristicTemplate) {
		this.templateMetaData = this._oneConfigService.getTemplateMetaData(CharTypeId.Royalty, template.templateID);
		this.charData = [];
	}

	setSplits(event: ISplitsChangedEvent) {
		this.splits = event.splits;
		const hasSplits = !isEmpty(this.splits ?? []);
		this.isSplitsValid = !hasSplits || event.isValid;
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
