<div class="modal-header">
	<h2 class="modal-title">{{ notification.charTypeId |charTypeName }} Notification</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="d-flex">
		<div class="flex-grow-1">
			<h4>
				<b>{{ notification.subject }}</b>
			</h4>
		</div>
		<div>
			<p>
				<em>{{ notification.createdAt | localDate: DateTimeFormatType.ShortDateOrTodayDateTime}}</em>
			</p>
		</div>
	</div>
	<div [innerHTML]="notification.body" class="border p-2"></div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()">Close</button>
	<button *ngIf="isUnread" type="button" class="btn btn-info" (click)="markAsRead()" [disabled]="markingAsRead">
		<i *ngIf="markingAsRead" class="far fa-spinner fa-spin me-2"></i>Mark as Read
	</button>
</div>