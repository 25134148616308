<div class="d-flex flex-row col-md-12">
	<!-- TODO: Implement <rl-dropdown-single> -->
	<select class="form-select me-2" [(ngModel)]="chainedActionControl.value.processId"
		(ngModelChange)="getNextActions()" data-cy="workflowAction">
		<option value="null" selected>Select Workflow</option>
		<option *ngFor="let process of processes" [ngValue]="process.processID"
			[hidden]="omitChainedActionOption(process.processID + ' ' + process.processName)">{{ process.processID
			}} - {{ process.processName }}</option>
	</select>
	<select class="form-select me-2" [(ngModel)]="chainedActionControl.value.nextActionId" data-cy="selectAction">
		<option value="null" selected>Select Action</option>
		<option *ngFor="let action of filteredNextActions" [ngValue]="chainedActionControl ? action.actionID : ''">
			{{ action.actionName }}</option>
	</select>
</div>