<div class="modal-header">
	<h2 class="modal-title">{{ isView ? "View" : (amortizationModel ? "Create" : "Edit") }} Amortization Model</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="form" [formGroup]="form">
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Name</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display"
						[class.has-error]="form.controls.name.invalid && form.controls.name.touched">
						<input type="text" class="form-control" formControlName="name" *ngIf="!isView" />
						<span *ngIf="isView">{{ amortizationModel?.name }}</span>
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Number of Periods</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display"
						[class.has-error]="form.controls.periodCount.invalid && form.controls.periodCount.touched">
						<input type="number" class="form-control" formControlName="periodCount" *ngIf="!isView" />
						<span *ngIf="isView">{{ amortizationModel?.periods?.length }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="periodCount > 0" class="scroll pt-3">
		<table class="table rl-table bg-white container-fluid">
			<thead>
				<tr>
					<th *ngFor="let p of amortizationModel.periods; index as i">
						Month {{ i + 1 }}
					</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td *ngFor="let p of amortizationModel.periods; index as i">
						<input *ngIf="!isView" type="number" step="0.01" min="0" max="100" [(ngModel)]="p.split"
							class="form-control" />
						<span *ngIf="isView">{{ p.split }}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="mt-3" *ngIf="currentTotal != '100.00' && periodCount > 0">
		<span class="text-danger">Periods must add up to 100%. Current: {{ currentTotal }}</span>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()">Cancel</button>
	<button type="button" class="btn btn-info" *ngIf="isView" (click)="isView = false">
		Edit
	</button>
	<button type="button" class="btn btn-success" *ngIf="!isView"
		[disabled]="isSaving || this.form.invalid || currentTotal != '100.00'" (click)="save()">
		Save
	</button>
</div>