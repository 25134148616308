import { first, flatMap, isEmpty } from "lodash";
import { BulkConfig, BulkNestedStrategy } from "rl-common/components/bulk-grid/bulk-config.strategy";
import { FormControlWarn } from "rl-common/components/char-data/elements/element-validators";
import { CharDataType, SystemIndicators } from "rl-common/consts";
import { IRelSearchDoc } from "rl-common/models/i-rel-search-doc";
import { IExtraGridColumnResults } from "rl-common/services/grid-view/models/i-extra-grid-column-results";
import { CharTypeId } from "./../../../../../rl-common.consts";
import { OneConfigService } from "./../../../../../services/one-config/one-config.service";
import { CharacteristicUtil, TagLabel } from "./../../../../../utils/characteristic.util";




export enum BulkCreateErrors {
	TableConfig = "table_config",
	AmountConfig = "amount_config",
}

export enum Warnings {
	TableTotalFee = "table_total_fee",
	TablePercent = "table_percent",
	AmountDueDate = "amount_due_date",
	CatalogMismatch = "catalog_mismatch",
	CatalogsMissing = "catalogs_missing"
}

export namespace BulkCreateCustomErrorsUtil {
	export const errorsLookup = {
		"table_config": "The selected Tables are not properly configured for Bulk Amount Creation.",
		"amount_config": "The selected Amount type is not properly configured for Bulk Amount Creation."
	};

	export const getMismatchedTemplates = (oneConfigService: OneConfigService, amountTemplateId: number, extraGridColumnResults: IExtraGridColumnResults,) => {
		const catalogTemplateIds = oneConfigService.getChildAssocTemplateIds(CharTypeId.Amount, amountTemplateId, CharTypeId.Property);
		return catalogTemplateIds.length > 0 && extraGridColumnResults?.assoc_entity ? flatMap(Object.values(extraGridColumnResults.assoc_entity)
			.map(tableAssocs => {
				return tableAssocs[CharTypeId.Property];
			})
			.filter(catalogAssoc => !!catalogAssoc), (catalogAssoc => {
				const mismatchedTemplates = catalogAssoc.filter(assoc => !catalogTemplateIds.includes(assoc.templateId))
					.map(assoc => assoc.templateId);
				return mismatchedTemplates;
			})) : [];
	};

	export const bulkCreateConfigValidator = (oneConfigService: OneConfigService, bulkConfig: BulkConfig, amountTemplateId: number,
		tableRowData: IRelSearchDoc[], extraGridColumnResults: IExtraGridColumnResults) => {
		return (control: FormControlWarn) => {
			const nestedStrategy = <BulkNestedStrategy>bulkConfig.bulkStrategy;
			const tableCmds = oneConfigService.getTemplateCmds(bulkConfig.charTypeId, bulkConfig.templateId);
			let aTemplateId = amountTemplateId;
			if (!aTemplateId) {
				const amountTemplateIds = oneConfigService.getChildAssocTemplateIds(bulkConfig.charTypeId, bulkConfig.templateId, CharTypeId.Amount);
				aTemplateId = first(amountTemplateIds);
			}
			const amountCmds = oneConfigService.getTemplateCmds(nestedStrategy.charTypeId, aTemplateId);
			const usageTotalFeeCmd = tableCmds.find(cmd => cmd.systemIndicator === SystemIndicators.UsageTotalFee && cmd.dataTypeID === CharDataType.Money);
			const warnings = {};
			const errors = {};
			const catalogTemplates = oneConfigService.getChildAssocTemplateIds(CharTypeId.Amount, amountTemplateId, CharTypeId.Property);
			if (catalogTemplates.length === 0) {
				warnings[Warnings.CatalogsMissing] = true;
			}

			if (!usageTotalFeeCmd) {
				errors[BulkCreateErrors.TableConfig] = true;
				warnings[Warnings.TablePercent] = true;
			} else {
				const solrLabel = CharacteristicUtil.GetCharSolrLabel(usageTotalFeeCmd.dataTypeID, usageTotalFeeCmd.multipleIndicator, usageTotalFeeCmd.tagLabel);
				const allFees = tableRowData.map(row => row.characteristics[solrLabel]);
				const areAllFeesValid = allFees.every((fee, i) => fee);
				const areAllFeesTheSame = allFees.every((val, i, arr) => val === arr[0]);

				if (!areAllFeesValid) {
					warnings[Warnings.TablePercent] = true;
				}
				if (!areAllFeesTheSame) {
					warnings[Warnings.TableTotalFee] = true;
				}
			}

			const dueDateCmd = amountCmds.find(cmd => cmd.tagLabel === TagLabel.AmountDueDate);
			if (!dueDateCmd) {
				warnings[Warnings.AmountDueDate] = true;
			}

			const amountAmountCmd = amountCmds.find(cmd => cmd.systemIndicator === SystemIndicators.Amount);
			if (!amountAmountCmd) {
				errors[BulkCreateErrors.AmountConfig] = true;
			}

			const allTablesHaveCatalogs = tableRowData.every(tr => {
				const tableAssocs = extraGridColumnResults.assoc_entity[tr.recordID];
				if (!tableAssocs) {
					return false;
				}
				const catalogAssocs = tableAssocs[CharTypeId.Property];
				return catalogAssocs?.length > 0;
			});
			if (!allTablesHaveCatalogs) {
				warnings[Warnings.CatalogsMissing] = true;
			}
			const mismatchingTemplates = getMismatchedTemplates(oneConfigService, amountTemplateId, extraGridColumnResults);
			if (mismatchingTemplates?.length > 0) {
				warnings[Warnings.CatalogMismatch] = true;
			}

			if (!isEmpty(warnings)) {
				control.warnings = warnings;
			}
			if (isEmpty(errors)) {
				return null;
			}
			control.setErrors(errors, { emitEvent: false });
			return errors;
		};
	};
}

