<div class="d-flex align-items-center mt-3">
	<strong>Show Only: </strong>
	<div ngbDropdown autoClose="outside" placement="bottom-left"
		#dealsByStatusDropdown="ngbDropdown">
		<button class="btn btn-link" ngbDropdownToggle role="button">
			{{ createCmdVisibilityService.title() }}
		</button>
		<div ngbDropdownMenu class="p-3">
			<div class="form-check my-1">
				<label class="form-check-label" [class.text-grey]="disableAllFields">
					<input class="me-2 form-check-input" type="checkbox"
						[(ngModel)]="createCmdVisibilityService.showAll" [disabled]="disableAllFields" (ngModelChange)="createCmdVisibilityService.showAllFields()" />
					All Fields
				</label>
			</div>
			<div class="form-check my-1">
				<label class="form-check-label">
					<input class="me-2 form-check-input" type="checkbox"
						[(ngModel)]="createCmdVisibilityService.showRequired" />
					Required Fields
				</label>
			</div>
			<div class="form-check my-1">
				<label class="form-check-label">
					<input class="me-2 form-check-input" type="checkbox"
						[(ngModel)]="createCmdVisibilityService.showDesired" />
					Recommended Fields
				</label>
			</div>
			<div class="form-check my-1">
				<label class="form-check-label">
					<input class="me-2 form-check-input" type="checkbox"
						[(ngModel)]="createCmdVisibilityService.showPopulated" />
					Populated Fields
				</label>
			</div>
			<ng-container *rlFeatureEnabled="'extractContractOption'">
				@if(extractedCharIds() !== null) {
					<div class="form-check my-1">
						<label class="form-check-label">
							<input class="me-2 form-check-input" type="checkbox"
							[(ngModel)]="createCmdVisibilityService.showExtracted" />
							Extracted Fields
						</label>
					</div>
				}
			</ng-container>
		</div>
	</div>
</div>