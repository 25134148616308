import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AccountingOperationTriggerComponent } from "../accounting-operation-trigger/accounting-operation-trigger.component";
import { AddAccountingOperationTriggerComponent } from "../add-accounting-operation-trigger/add-accounting-operation-trigger.component";
import { IBuildAccountingOperationTrigger } from "../build-accounting-operation/models/i-build-accounting-operation-trigger";

@Component({
	selector: "rl-add-accounting-operation-triggers",
	templateUrl: "./add-accounting-operation-triggers.component.html",
	styleUrls: ["./add-accounting-operation-triggers.component.scss"],
	imports: [NgIf, NgFor, AddAccountingOperationTriggerComponent, AccountingOperationTriggerComponent]
})
export class AddAccountingOperationTriggersComponent implements OnInit, OnDestroy, OnChanges {

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	triggersFormArray: FormArray<FormControl<IBuildAccountingOperationTrigger>>;

	@Input()
	disabled = false;

	editIndex: number = null;

	get triggerControls() {
		return this.triggersFormArray.controls;
	}

	get otherTriggers() {
		const triggers = this.triggersFormArray.controls.map(control => control.value);
		const front = triggers.slice(0, this.editIndex);
		const back = triggers.slice(this.editIndex + 1, triggers.length);
		return front.concat(back);
	}

	get isEditing() {
		return this.editIndex !== null;
	}

	private readonly _subscriptions: Subscription[] = [];

	constructor(private readonly _promptService: PromptService) { }

	ngOnInit(): void {
		if (this.triggersFormArray.length == 0) {
			this.addTrigger(false);
		}
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.triggersFormArray) {
			// Handles the form changing during an edit
			if (this.editIndex !== null && this.editIndex < changes.triggersFormArray?.previousValue?.controls?.length) {
				const previousControl = changes.triggersFormArray.previousValue.controls[this.editIndex] as FormControl<IBuildAccountingOperationTrigger>;
				if (previousControl.value.id) {
					this.triggersFormArray.controls[this.editIndex].setValue(previousControl.value, { emitEvent: false });
				} else {
					this.triggersFormArray.insert(this.editIndex, previousControl, { emitEvent: false });
				}
			}
			if (changes.triggersFormArray.currentValue?.controls?.length == 0 && changes.triggersFormArray.previousValue?.controls?.length > 0) {
				this.addTrigger(false);
			}
		}
	}

	editTrigger(index: number) {
		this.editIndex = index;
	}

	cancelEdit(index: number) {
		this.editIndex = null;

		const isNewTrigger = !this.triggersFormArray.controls[index].value.id;
		if (isNewTrigger) {
			this.triggersFormArray.removeAt(index, { emitEvent: false });
		}
	}

	updateTrigger(trigger: IBuildAccountingOperationTrigger) {
		this.triggerControls[this.editIndex].setValue(trigger);
		this.editIndex = null;
	}

	addTrigger(emitEvent: boolean = true) {
		const control = new FormControl<IBuildAccountingOperationTrigger>(<IBuildAccountingOperationTrigger>{});
		const nextIndex = this.triggersFormArray.length;
		this.triggersFormArray.insert(nextIndex, control, { emitEvent });
		this.editTrigger(nextIndex);
	}

	removeTrigger(index: number, confirmDeleteDialog: TemplateRef<unknown>) {
		const sub = this._promptService.confirm("Confirmation", confirmDeleteDialog,).pipe(
			filter(confirm => confirm),
		).subscribe(() => {
			this.triggersFormArray.removeAt(index);
		});

		this._subscriptions.push(sub);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

}
