<div class="roles-container border">
	<table class="table rl-table table-hover">
		<tbody>
			<tr *ngFor="let role of workflowRoles" (click)="toggleRole(role)" class="toggle-role">
				<td>
					<input type="checkbox" [ngModel]="selectedWfRoles.has(role.roleID)" class="form-check-input">
					<span class="ms-2">{{ role.roleName }}</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>