import { transition, trigger, useAnimation } from "@angular/animations";
import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Provider, TemplateRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { first } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { CharDataType, CharTypeId, SystemIndicators } from "rl-common/consts";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { AccountingProcessActiveIndicator } from "rl-common/services/accounting-processes/models/accounting-process-active-indicator";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IAccountingOperation } from "rl-common/services/accounting-processes/models/i-accounting-operation";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { IAccountingProcessDetails } from "rl-common/services/accounting-processes/models/i-accounting-process-details";
import { ICreateAccountingProcess } from "rl-common/services/accounting-processes/models/i-create-accounting-process";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { of, Subscription } from "rxjs";
import { distinctUntilChanged, filter, finalize, map, switchMap, take } from "rxjs/operators";
import { AccountingOperationsComponent } from "../accounting-operations/accounting-operations.component";
import { AccountingProcessSteps, IBuildAccountingProcessResult } from "../accounting-processes.models";
import { BuildAccountingOperationComponent } from "../build-accounting-operation/build-accounting-operation.component";
import { BuildAccountingProcessModalAdapter, IBuildAccountingProcessModalComponent } from "./build-accounting-process-modal.adapter";
import { BuildAccountingProcessService } from "./build-accounting-process.service";

export interface IAccountingProcessForm {
	name: FormControl<string>;
	anchorTemplateId: FormControl<number>;
}

@Component({
	selector: "rl-build-accounting-process-modal",
	templateUrl: "./build-accounting-process-modal.component.html",
	styleUrls: ["./build-accounting-process-modal.component.scss"],
	providers: [BuildAccountingProcessService],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, NgSwitch, NgSwitchCase, ReactiveFormsModule, NgFor, AccountingOperationsComponent, BuildAccountingOperationComponent, AsyncPipe]
})
export class BuildAccountingProcessModalComponent implements OnInit, OnChanges, OnDestroy, IBuildAccountingProcessModalComponent {

	@Input()
	selectedContact: IAccountingContact;

	@Input()
	accountingProcessId: string;

	@Input()
	createOperation = false;

	@Input()
	editOperationId: string;

	@Output()
	templatesDateCmdsChange = new EventEmitter<{ [key: string]: ICharacteristicMetaData[] }>();

	@Output()
	anchorTemplateChange = new EventEmitter<ICharacteristicTemplate>();

	@Output()
	onClose = new EventEmitter<IBuildAccountingProcessResult>();

	isNewAccountingProcess = true;
	accounts: IAccountingProcessAccount[] = [];
	accountGroups: IAccountingProcessAccountGroup[] = [];
	accountingProcessDetails: IAccountingProcessDetails;
	isLoadingDetails = false;

	form: FormGroup<IAccountingProcessForm>;
	anchorTemplates: ICharacteristicTemplate[] = [];
	anchorTemplatesDateCmds: { [key: number]: ICharacteristicMetaData[] } = {};
	allocationTemplateSystemIndicator = SystemIndicators.AllocationTemplate;

	openOperationPanels: string[] = [];

	get processName() {
		return this.form.controls.name.value;
	}

	get anchorTemplate() {
		const templateId = this.form?.controls?.anchorTemplateId?.value;
		if (!templateId) {
			return null;
		}
		const anchorTemplate = this.anchorTemplates.find(x => x.templateID == templateId);
		if (!anchorTemplate) {
			return null;
		}
		return anchorTemplate;
	}

	status$ = this._buildAccountingProcessService.status$;
	isSaving$ = this._buildAccountingProcessService.status$.pipe(map(x => x.isSaving));

	currentStep: AccountingProcessSteps = AccountingProcessSteps.AccountingProcess;

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _oneConfig: OneConfigService,
		private readonly _promptService: PromptService,
		private readonly _accountingProcessService: AccountingProcessesService,
		private readonly _buildAccountingProcessService: BuildAccountingProcessService
	) { }

	ngOnInit(): void {
		if (this.editOperationId) {
			this.openOperationPanels = [this.editOperationId];
		}
		this.isNewAccountingProcess = !this.accountingProcessId;
		if (!this.isNewAccountingProcess) {
			this.currentStep = AccountingProcessSteps.AccountingOperations;
		}
		this.createOperation = this.isNewAccountingProcess || this.createOperation;
		this.anchorTemplates = this._oneConfig.getTemplates(CharTypeId.Amount).filter(x => x.systemIndicator === this.allocationTemplateSystemIndicator);
		this.anchorTemplates.forEach(t => {
			const templateCmds = this._oneConfig.getTemplateCmds(CharTypeId.Amount, t.templateID);
			this.anchorTemplatesDateCmds[t.templateID] = templateCmds.filter(cmd => cmd.dataTypeID === CharDataType.Date);
		});
		this.templatesDateCmdsChange.emit(this.anchorTemplatesDateCmds);
		this.loadAccountingProcessDetails();
		this.loadAccounts();
		this.loadAccountGroups();
	}

	private loadAccountingProcessDetails() {
		if (!this.accountingProcessId) {
			this.accountingProcessDetails = null;
			this.buildAccountingProcessForm();
			return;
		}
		this.isLoadingDetails = true;
		this._accountingProcessService.getAccountingProcessDetails(this.accountingProcessId).pipe(
			finalize(() => this.isLoadingDetails = false)
		).subscribe(details => {
			this.accountingProcessId = details.id;
			this.accountingProcessDetails = details;
			this.buildAccountingProcessForm();
		});
	}

	private loadAccounts() {
		const sub = this._accountingProcessService.getAccounts(this.selectedContact.recordId).subscribe(results => {
			this.accounts = results.map(x => x.account);
			// TODO: block creating accounting process if less than two accounts exist
		});
		this._subs.push(sub);
	}

	private loadAccountGroups() {
		const sub = this._accountingProcessService.getAccountGroups(this.selectedContact.recordId).subscribe(results => {
			this.accountGroups = results;
			// TODO: expand groups to have debit and credit refs as well
		});
		this._subs.push(sub);
	}

	private buildAccountingProcessForm() {
		let anchorTemplateId = this.accountingProcessDetails?.anchorTemplateId || first(this.anchorTemplates)?.templateID;
		// if a template was configured which no longer has the system indicator
		if (this.accountingProcessDetails?.anchorTemplateId && !this.anchorTemplates.find(x => x.templateID == this.accountingProcessDetails?.anchorTemplateId)) {
			anchorTemplateId = null;
		}

		this.form = this._formBuilder.group<IAccountingProcessForm>({
			name: new FormControl<string>(this.accountingProcessDetails?.name, [Validators.required]),
			anchorTemplateId: new FormControl<number>(anchorTemplateId, [Validators.required])
		});
		if (!this.accountingProcessDetails) {
			this.form.controls.anchorTemplateId.markAsDirty();
		}
		if (this.anchorTemplates.length <= 1) {
			this.form.controls.anchorTemplateId.disable();
		}
		if (anchorTemplateId == null) {
			this.form.controls.anchorTemplateId.markAsTouched();
		}

		const sub = this.form?.controls?.anchorTemplateId?.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(templateId => {
			const selectedTemplate = this.anchorTemplates.find(t => t.templateID === templateId);
			if (selectedTemplate) {
				this.onAnchorTemplateChange(selectedTemplate);
			}
		});

		this._subs.push(sub);

		this._buildAccountingProcessService.registerForm(`BuildAccountingProcessModalComponent`, this.form);
	}

	onAnchorTemplateChange(template: ICharacteristicTemplate) {
		this.anchorTemplateChange.emit(template);
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.accountingProcessId && changes.accountingProcessId.currentValue !== changes.accountingProcessId.previousValue) {
			this.loadAccountingProcessDetails();
		}
	}

	saveAccountingProcess(updateAnchorTemplateWarning: TemplateRef<unknown>) {
		if (!this.form.valid) {
			return;
		}

		const anchorTemplateChanged = this.accountingProcessDetails && this.accountingProcessDetails.anchorTemplateId !== this.form.controls.anchorTemplateId.value;
		let clearTriggers$ = of(true);
		if (anchorTemplateChanged) {
			clearTriggers$ = this._promptService.confirm(`Warning`, updateAnchorTemplateWarning);
		}

		const process: ICreateAccountingProcess = {
			id: this.accountingProcessId,
			contactRecordId: this.selectedContact.recordId,
			accountingProcessName: this.form.controls.name.value,
			activeIndicator: this.accountingProcessDetails ? this.accountingProcessDetails.activeIndicator : AccountingProcessActiveIndicator.Active,
			anchorTemplateId: this.form.controls.anchorTemplateId.value,
			xref: null
		};

		const sub = clearTriggers$.pipe(
			filter(clearTriggers => clearTriggers),
			switchMap(clearTriggers => {
				process.clearTriggers = clearTriggers;
				this._buildAccountingProcessService.emitStatus({ isSaving: true, message: `Saving Accounting Process` });
				return this._accountingProcessService.upsertAccountingProcess(process).pipe(
					finalize(() => {
						this._buildAccountingProcessService.emitStatus({ isSaving: false, message: `Saved` });
					})
				);
			}),
		).subscribe(details => {
			this.accountingProcessDetails = details;
			this.accountingProcessId = details.id;
			this.proceedToOperations();
			this.buildAccountingProcessForm();
		});
		this._subs.push(sub);
	}

	private emitClose() {
		this.onClose.next({
			isNewAccountingProcess: this.isNewAccountingProcess,
			selectedContact: this.selectedContact,
			accountingProcessId: this.accountingProcessId
		});
	}

	close(dialogTemplate: TemplateRef<unknown>) {
		this._buildAccountingProcessService.status$.pipe(
			take(1),
			switchMap(status => {
				if (status.isDirty) {
					return this._promptService.confirm("Confirmation", dialogTemplate);
				}
				return of(true);
			}),
			filter(confirm => confirm)
		).subscribe(() => {
			this.emitClose();
		});
	}

	proceedToOperations() {
		this.currentStep = AccountingProcessSteps.AccountingOperations;
	}

	addAnotherOperation() {
		this.createOperation = true;
		this.loadAccountingProcessDetails();
		this._buildAccountingProcessService.addAnotherOperation$.next();
	}

	operationDeleted(operation: IAccountingOperation) {
		this.loadAccountingProcessDetails();
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}

export const BUILDACCOUNTINGPROCESS_MODAL_PROVIDER: Provider = {
  provide: BuildAccountingProcessModalAdapter,
  useFactory: () => new BuildAccountingProcessModalAdapter(BuildAccountingProcessModalComponent)
};

