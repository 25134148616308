import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
	providedIn: "root"
})
export class HtmlSanitizerService {

	constructor(
		private readonly _sanitizer: DomSanitizer
	) { }

	sanitizeWhilePreservingStyles(html: string) {
		if (!html) {
			return html;
		}
		const styleAttr = "style";
		const renamedAttrName = "alt";
		const renamedHtml = this.renameAttribute(styleAttr, renamedAttrName, html);
		const sanitizedHtml = this._sanitizer.sanitize(SecurityContext.HTML, renamedHtml);
		const revertedHtml = this.renameAttribute(renamedAttrName, styleAttr, sanitizedHtml);
		return revertedHtml;
	}
	renameAttribute(sourceAttribute: string, targetAttribute: string, html: string) {
		const regex = new RegExp(`${sourceAttribute}\ ?\=\ ?\"`);
		const replacedValue = `${targetAttribute}="`;
		const sanitizedHtml = this.replaceAll(html, regex, replacedValue);
		return sanitizedHtml;
	}

	private replaceAll(str: string, expression: RegExp, replaceWith: string): string {
		return str.split(expression).join(replaceWith);
	}
}