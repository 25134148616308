import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { cloneDeep, Dictionary, first, flatMap } from "lodash";
import { BulkEditsPackage } from "rl-common/components/bulk-grid/bulk-edit.models";
import { EntityCharDataGridDataSource } from "rl-common/components/grid/datasource/entity-chardata-grid.datasource";
import { FacetType } from "rl-common/models/facet-type";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { CharTypeId } from "./../../../rl-common.consts";
import { IMultipleTemplateWarnings } from "./bulk-multiple-template-confirm-modal.models";

@Component({
    selector: "rl-bulk-multiple-template-confirm-modal",
    templateUrl: "./bulk-multiple-template-confirm-modal.component.html",
    styleUrls: ["./bulk-multiple-template-confirm-modal.component.scss"],
    imports: [NgFor]
})
export class BulkMultipleTemplateConfirmModalComponent implements OnInit {
	@Input()
	bulkPackage: BulkEditsPackage<ICharacteristicData[]>;
	@Input()
	charTypeId: CharTypeId;
	@Input()
	templateId: number;
	@Input()
	dataSource: EntityCharDataGridDataSource;
	@Output()
	onConfirm = new EventEmitter<boolean>();
	invalidWarnings: IMultipleTemplateWarnings[];
	charDataWarnings: any[];
	constructor(
		private readonly _oneConfigService: OneConfigService,
	) { }

	ngOnInit(): void {
		const templateFacets = this.dataSource.facetResults$.value[FacetType.Template]
			.map(facet => facet.facet.split("|"));
		const ces = cloneDeep(this.bulkPackage.extraColumnEdits);
		const cmdDictionary: Dictionary<ICharacteristicMetaData[]> = templateFacets.map(tf => +tf[1])
			.reduce((acc, templateId) => {
				acc[templateId] = this._oneConfigService.getTemplateCmds(this.charTypeId, templateId);
				return acc;
			}, {});
		const allCmds = flatMap(Object.values(cmdDictionary), cmds => cmds);
		this.invalidWarnings = templateFacets.map(templateFacet => {
			const templateId = +templateFacet[1];
			const templateName = templateFacet[0];
			const cmds = cmdDictionary[templateId];
			const charData = this.bulkPackage.columnEdits
				.filter(ce => !cmds.find(cmd => cmd.characteristicID === ce.charID))
				.map(ce => {
					const matchingCmd = allCmds.find(cmd => cmd.characteristicID === ce.charID);
					return {
						matchingCmd,
						templateName
					};
				});
			const associations = ces
				.filter(assocColEdit => {
					const errors = assocColEdit.associations.filter(assoc => !this._oneConfigService.canAssociateToChild(this.charTypeId, templateId, assoc.charTypeId, assoc.templateId));
					return errors.length > 0;
				}).map(assocColEdit => {
					const filteredAssocs = assocColEdit.associations.filter(assoc => !this._oneConfigService.canAssociateToChild(this.charTypeId, templateId, assoc.charTypeId, assoc.templateId));
					const ct = first(filteredAssocs).charTypeId;
					const titles = filteredAssocs.map(a => a.title).join(", ");
					const message = `${titles} ${CharTypeIdUtil.toDisplayNamePlural(ct)} cannot be associated to records of template ${templateName}`;
					return message;
				});
			return { templateId, associations, charData };
		});
	}

	public decline() {
		this.onConfirm.emit(false);
	}

	public accept() {
		this.onConfirm.emit(true);
	}

	public dismiss() {
		this.onConfirm.emit(false);
	}
}
