<div *ngIf="sourceFieldsLoaded; else isLoading">
	<div class="table-container scroll-vert">
		<table class="table rl-table table-hover" cdkDropList>
			<thead>
				<tr>
					<th class="ps-4">ID</th>
					<th class="text-nowrap">Source Field Name</th>
					<th class="text-nowrap">Tag Label</th>
					<th class="text-nowrap">Data Type</th>
					<th>Length</th>
					<th class="text-nowrap">Can be Multiple</th>
					<th class="text-nowrap">Used In</th>
				</tr>
			</thead>
			<tbody>
				<tr class="no-items-message" *ngIf="cmd.length === 0">
					<td colspan="10" class="border-0">
						<div class="d-flex align-items-center justify-content-center p-3">
							<i class="far fa-th-list"></i>
							<h5 class="mb-0 ms-3">No records found.</h5>
						</div>
					</td>
				</tr>
				<tr *ngFor="let char of cmd">
					<td class="ps-1 text-nowrap">
						<input type="checkbox" class="form-check-input me-1" [value]="char.sourceFieldID"
							(change)="toggleSourceField(char.sourceFieldID)" [checked]="isChecked(char.sourceFieldID)"
							data-cy="checkboxInputToggleSourceField" />
						{{char.sourceFieldID}}
					</td>
					<td>
						{{char.sourceFieldName}}
					</td>
					<td>
						{{char.mergeTag}}
					</td>
					<td>
						{{char.dataTypeID | charDataTypeName}}
					</td>
					<td>
						{{char.length}}
					</td>
					<td>
						{{char.multipleIndicator | multipleIndicatorName}}
					</td>
					<td class="pb-0">
						<em class="text-grey text-nowrap" *ngIf="char.usedIn.length === 0">
							Not Used
						</em>
						<a *ngIf="char.usedIn.length > 0" [ngbPopover]="usedInPopover" #p="ngbPopover"
							[autoClose]="'outside'" placement="left auto"
							class="btn btn-link btn-icon btn-sm p-0 h-auto cursor-pointer" aria-label="Used In List"
							role="button" data-cy="usedIn">
							<i class="far fa-link"></i>
						</a>
						<ng-template #usedInPopover>
							<div class="scroll-vert popover-max-height">
								<span *ngFor="let usedIn of char.usedIn" class="d-block my-1 text-small">
									<span>{{char.charTypeID | charTypeName}}</span>
									<span class="px-2">&bull;</span>
									<span>{{usedIn.templateLbl}}</span>
									<span class="px-2">&bull;</span>
									<span>{{usedIn.templateSourceFieldGroupLbl}}</span>
								</span>
							</div>
						</ng-template>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="border-top py-4 mt-5">
		<h4>Selected {{charTypeName}} Source Fields</h4>
		<div class="mt-2">
			<rl-chip *ngFor="let sourceFieldID of fieldsToAdd" (onRemove)="toggleSourceField(sourceFieldID)">
				{{ getSourceFieldById(sourceFieldID) }}
			</rl-chip>
			<span *ngIf="!fieldsToAdd">No Fields selected.</span>
		</div>
	</div>
</div>
<div class="modal-footer">
	<ng-content></ng-content>
	<button type="submit" class="btn btn-success ms-3" (click)="submit()" [disabled]="fieldsToAdd.size === 0"
		data-cy="saveSelectTemplateSourceField">Save</button>
</div>

<ng-template #isLoading>
	<div class="p-4 text-center">
		<rl-loader></rl-loader>
	</div>
</ng-template>