<rl-hierarchy-group>
	<span rlHierarchyGroupHead class="pt-1">
		<span class="form-check">
			<input type="checkbox" class="form-check-input" [(ngModel)]="areAllSelected" (change)="toggleAllSelected()"
				[attr.data-cy]="'GroupId_' + group.groupId" />
			<label class="form-check-label ms-1 text-nowrap"> {{group.groupLabel}}: (Template ID:
				#{{group.groupId}})</label>
		</span>
	</span>
	<div rlHierarchyGroupContent>
		<div class="mx-6" *ngFor="let association of group.associations">
			<span class="form-check">
				<input type="checkbox" class="form-check-input" [(ngModel)]="association.isSelected"
					(change)="selectAssociation(association.record.recordID)"
					[attr.data-cy]="'AssociationsId_' + association.record.recordID" />
				<label class="form-check-label text-nowrap">
					{{association.record.recordID}}: {{association.record.title}}
				</label>
			</span>
		</div>
	</div>
</rl-hierarchy-group>