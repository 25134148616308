import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntity } from "rl-common/models/i-entity";
import { IEntityId, IEntityID } from "rl-common/models/i-entity-id";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { IDUtil } from "rl-common/utils";
import { Subscription, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LoaderComponent } from "../../../panel/loader/loader.component";
import { AssociationType } from "../../list-group-actions.models";
import { TablesService } from "./../../../../services/tables/tables.service";

@Component({
    selector: "rl-associate-invoice-modal",
    templateUrl: "./associate-invoice-modal.component.html",
    styleUrls: ["./associate-invoice-modal.component.scss"],
    imports: [NgIf, ReactiveFormsModule, NgFor, LoaderComponent]
})
export class AssociateInvoiceModalComponent implements OnInit, OnDestroy {

	@Input()
	parentEntityId: IEntityId;

	@Input()
	amountModels: ISearchRequestModel[];

	@Output()
	onAssociateComplete = new EventEmitter();

	get associationType() {
		return +this.form.get("associationType").value;
	}

	private subs: Subscription[] = [];
	loadingText = "Loading";
	existingAccountSelected = false;
	form: UntypedFormGroup = this._formBuilder.group({
		associationType: new UntypedFormControl(0),
		existingInvoice: new UntypedFormControl(0),
		newInvoiceTemplate: new UntypedFormControl(0),
		newInvoiceTemplateForMultiple: new UntypedFormControl(0)
	});
	amountDocuments: IEntitySearchDoc[];
	invoices: IEntity[];
	invoiceTemplates: ICharacteristicTemplate[] = [];
	isSaving = false;

	constructor(
		private readonly _tablesService: TablesService,
		private readonly _linkHelper: LinkHelperService,
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _modal: NgbActiveModal,
		private readonly _promptService: PromptService
	) { }

	ngOnInit(
	) {
		const sub = this._tablesService.getAssociableInvoices(this.parentEntityId, this.amountModels)
			.pipe(catchError(err => {
				this._modal.close();
				alert("Unable to associate amounts of different currencies to a single document.");
				return throwError(err);
			}))
			.subscribe((response) => {
				this.invoices = response.invoices;
				this.invoiceTemplates = response.invoiceTemplates;
				this.amountDocuments = response.amountDocuments;
				this.loadingText = null;
				let defaultAssocationSelection = response.invoices.length > 0 ? AssociationType.Existing : AssociationType.New;
				this.form.get("associationType").setValue(defaultAssocationSelection);
				if (this.invoices.length > 0) {
					const defaultExistingRecordId = this.invoices[0].recordID;
					this.form.get("existingInvoice").setValue(defaultExistingRecordId);
				}
				if (this.invoiceTemplates.length > 0) {
					let defaultTemplateId = this.invoiceTemplates[0].templateID;
					this.form.get("newInvoiceTemplate").setValue(defaultTemplateId);
					this.form.get("newInvoiceTemplateForMultiple").setValue(defaultTemplateId);
				}
				this.onSelectionChange(defaultAssocationSelection);
			});

		this.subs.push(sub);
	}

	associateExistingInvoice() {
		const existingRecId = this.form.get("existingInvoice").value;
		this.loadingText = `Associating to Invoice: ${existingRecId}`;
		const amountIds = this.amountDocuments.map(doc => doc.recordID);
		const sub = this._tablesService.associateAmountsToInvoice(existingRecId, amountIds)
			.subscribe((entityId) => {
				this.openInvoiceInNewTab(entityId);
				this.onComplete();
			});
		this.subs.push(sub);
	}

	associateToNewInvoice(isMultiple: boolean = false) {
		const templateId = isMultiple ? this.form.get("newInvoiceTemplateForMultiple").value : this.form.get("newInvoiceTemplate").value;
		this.loadingText = isMultiple ? 'Associating to new invoices' : `Associating to new invoice`;
		const amountIds = this.amountDocuments.map(doc => doc.recordID);
		const sub = this._tablesService.associateAmountsToNewInvoice(IDUtil.toIdString(this.parentEntityId), templateId, amountIds, isMultiple).pipe(
			tap(entityId => {
				var toID = IDUtil.splitEntityID(entityId);
				if (entityId) {
					this.openInvoiceInNewTab(toID);
				}
				this.onComplete();
			})).subscribe();
		this.subs.push(sub);
	}

	openInvoiceInNewTab(entityId: IEntityID) {
		const url = this._linkHelper.go(entityId.charTypeID, entityId.recID);
		window.open(url[0], "_blank");
	}

	setEnabled(dropDown: "existing" | "new" | "newMultiple", enabled: Boolean) {
		// don't emit event because enabling drop down triggers same event as changing the value
		if (dropDown === "existing") {
			this.form.get(`existingInvoice`)[enabled ? "enable" : "disable"]({ emitEvent: false });
		}
		else if (dropDown === "new") {
			this.form.get(`newInvoiceTemplate`)[enabled ? "enable" : "disable"]({ emitEvent: false });
		}
		else {
			this.form.get(`newInvoiceTemplateForMultiple`)[enabled ? "enable" : "disable"]({ emitEvent: false });
		}

		if (enabled) {
			this.form.get("associationType").enable({ emitEvent: false });
		} else {
			this.form.get("associationType").disable({ emitEvent: false });
		}
	}

	onSelectionChange(method: number) {
		this.setEnabled("existing", +method === AssociationType.Existing);
		this.setEnabled("new", +method === AssociationType.New);
		this.setEnabled("newMultiple", +method === AssociationType.NewMultiple);
		this.form.get("associationType").enable({ emitEvent: false });
	}

	save() {
		this.isSaving = true;
		const selectedAssocation = this.form.get("associationType").value;
		if (selectedAssocation === AssociationType.Existing) {
			this.associateExistingInvoice()
		}
		else if (selectedAssocation === AssociationType.New) {
			this.associateToNewInvoice();
		}
		else if (selectedAssocation === AssociationType.NewMultiple) {
			this.associateToNewInvoice(true);
		}
	}

	onComplete() {
		this.loadingText = null;
		this.isSaving = false;
		this.onAssociateComplete.emit();
	}

	cancel() {
		this.isSaving = false;
		this._modal.close();
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
