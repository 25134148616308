<div *ngIf="unevenAmountForm" [formGroup]="unevenAmountForm" [class.has-error]="hasError()" class="mt-2">
	<div formArrayName="amounts">
		<div *ngFor="let a of amounts.controls; let i = index">
			<div [formGroupName]="i">
				<input class="form-control form-control-sm mb-1" type="number" formControlName="amount"
					(change)="updateControl()" (keyup)="updateControl()" [attr.data-cy]="i + '_numberInput'" />
			</div>
		</div>
	</div>
	<div *ngIf="isPercentage">
		<span>Total: {{sum}}% </span>
		<div *ngIf="hasError()" class="text-danger">Must equal 100%</div>
	</div>
</div>