import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, HostListener, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { SearchFieldNames } from 'rl-common/components/entities/entity-search/query.models';
import { GrowlerService } from 'rl-common/services/growler.service';
import { IGroupedSearchRequestModel, ISearchResponseGroup } from 'rl-common/services/search/search.models';
import { SearchService } from 'rl-common/services/search/search.service';
import { SessionService } from 'rl-common/services/session.service';
import { of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { UniversalSearchResultsComponent } from '../universal-search-results/universal-search-results.component';
import { EMPTY_SEARCH_RESULTS, GROUP_LIMIT, ISearchResultGroup, ISearchResults } from './universal-search.models';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { FaIconDirective } from '../../../../common/directives/fa-icon.directive';
import { NgbHighlight } from '@ng-bootstrap/ng-bootstrap';
import { CharTypeNamePipe } from '../../../../common/pipes/char-type-name.pipe';

@Component({
    selector: 'rl-universal-search',
    templateUrl: './universal-search.component.html',
    styleUrls: ['./universal-search.component.scss'],
    imports: [ReactiveFormsModule, FormsModule, NgIf, FaIconDirective, NgbHighlight, CharTypeNamePipe]
})
export class UniversalSearchComponent implements OnInit, OnDestroy {

	@Input()
	keywords: string = "";

	@ViewChild("searchForm")
	searchElementRef: ElementRef<HTMLElement>;

	@ViewChild("keywordsInput")
	keywordsInputRef: ElementRef<HTMLInputElement>;

	isLoading = false;
	search$ = new Subject<string>();

	searchResults: ISearchResults = EMPTY_SEARCH_RESULTS;
	searchResultsComponent: UniversalSearchResultsComponent;

	private readonly _subs: Subscription[] = [];

	get hasKeywords() {
		return this.keywords && !isEmpty(this.keywords);
	}

	get searchIsEmpty() {
		return isEmpty(this.searchResults.term) && isEmpty(this.searchResults.results);
	}

	private _searchResultsOverlay: OverlayRef;

	constructor(
		private readonly _searchService: SearchService,
		private readonly _overlay: Overlay,
		private readonly _injector: Injector,
		private readonly _growlerService: GrowlerService,
		private readonly _session: SessionService,
		private readonly _router: Router
	) { }

	ngOnInit(): void {
		const sub = this.search$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => {
				this.closeSearchResults();
				if (!term || isEmpty(term)) {
					return of(EMPTY_SEARCH_RESULTS);
				}
				this.isLoading = true;
				const searchModel: IGroupedSearchRequestModel = {
					keywords: term,
					groupByField: SearchFieldNames.Entity.charTypeID,
					limit: GROUP_LIMIT,
					sortField: "score",
					query: {},
					filterQueries: [],
					timeAllowed: 300_000,
				}
				return this._searchService.searchModuleGroups(searchModel)
					.pipe(
						catchError(() => {
							this._growlerService.error().growl(`An error occurred while running your search.`);
							return of([]);
						}),
						map(data => this.mapSearchResults(term, data))
					);
			})
		).subscribe(results => {
			this.isLoading = false
			this.searchResults = results;
			this.displaySearchResults();
		});

		const sessionSub = this._session.divId$.pipe(
			distinctUntilChanged(),
			filter(() => this.hasKeywords)
		).subscribe(() => {
			this.keywords = "";
			this.search();
		});

		const routeChangedSub = this._router.events.pipe(
			filter(x => x instanceof NavigationEnd)
		).subscribe(() => {
			this.keywordsInputRef.nativeElement.blur();
		});

		this._subs.push(sub, sessionSub, routeChangedSub);
	}

	mapSearchResults(term: string, data: ISearchResponseGroup[]) {
		const groupedDocuments = data.reduce<{ [charTypeId: number]: ISearchResultGroup }>((dict, next) => {
			const charTypeId = +next.groupValue;
			if (!(charTypeId in dict)) {
				dict[charTypeId] = {
					documents: next.documents,
					numFound: next.numFound
				};
			}
			return dict;
		}, {});
		const results: ISearchResults = {
			term,
			results: groupedDocuments,
		};
		return results;
	}

	search() {
		this.search$.next(this.keywords);
	}

	onFocused() {
		if (!this.searchIsEmpty) {
			this.displaySearchResults();
		}
	}

	onFocusOut() {
		this.closeSearchResults();
	}

	private displaySearchResults() {
		if (!this._searchResultsOverlay && !isEmpty(this.searchResults.term)) {
			const positionStrategy = this._overlay.position()
				.flexibleConnectedTo(this.searchElementRef)
				.withPositions([
					{ originX: "end", originY: "bottom", overlayX: "end", overlayY: "top", offsetY: 5 }
				])
				.withPush(true);
			const portal = new ComponentPortal(UniversalSearchResultsComponent, undefined, this._injector);
			this._searchResultsOverlay = this._overlay.create({
				positionStrategy: positionStrategy,
				hasBackdrop: false,
				disposeOnNavigation: true,
				scrollStrategy: this._overlay.scrollStrategies.reposition()
			});
			const overlayRef = this._searchResultsOverlay.attach(portal);
			this.searchResultsComponent = overlayRef.instance;
			this.searchResultsComponent.searchResults = this.searchResults;

			const sub = this._searchResultsOverlay.backdropClick()
				.pipe(
					tap(($event) => {
						$event.stopPropagation();
						this.closeSearchResults();
					}),
				).subscribe();

			this._subs.push(sub);
		}
	}

	private closeSearchResults() {
		if (this.searchResultsComponent) {
			this.searchResultsComponent.animationState = "hidden";
		}
		setTimeout(() => {
			if (this._searchResultsOverlay) {
				this._searchResultsOverlay.dispose();
				this._searchResultsOverlay = undefined;
				this.searchResultsComponent = undefined;
			}
		}, 100);
	}

	@HostListener("document:keydown.control.g", ["$event"])
	handleGoToRecord(event: Event) {
		event.preventDefault();
		const selection = document.getSelection();
		this.keywords = selection?.toString();
		setTimeout(() => {
			this.keywordsInputRef.nativeElement.focus();
			if (this.hasKeywords) {
				this.search();
			}
		}, 0);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
