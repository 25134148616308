import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NumberUtil } from "rl-common/utils/number.util";
import { IAllocatedAmount } from "../../financial.models";
import { NgFor, NgIf } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LocaleDatePipe } from "../../../../pipes/locale-date.pipe";

@Component({
    selector: "rl-allocation-spread",
    templateUrl: "./allocation-spread.component.html",
    styleUrls: ["./allocation-spread.component.scss"],
    imports: [NgFor, ReactiveFormsModule, FormsModule, NgIf, LocaleDatePipe]
})
export class AllocationSpreadComponent {

	constructor() { }

	@Input()
	allocatedAmounts: IAllocatedAmount[];

	@Input()
	parentAmount: number;

	@Output()
	onAmountChange = new EventEmitter<IAllocatedAmount[]>();

	toServerAmount(value: string) {
		const unmasked = NumberUtil.parseLocaleNumber(value);
		if (unmasked !== "" && isNaN(unmasked)) {
			return NumberUtil.maskLocaleNumber(value);
		}
		return NumberUtil.maskLocaleNumber(unmasked.toString());
	}

	updatePercentage(amount: string, allocatedAmount: IAllocatedAmount) {
		const fee = parseFloat(amount.replace(/,/g, ""));
		const percentageAmount = isNaN(fee) ? 0 : +(100 * fee / this.parentAmount).toFixed(2);
		this.updateAmount(fee, percentageAmount, allocatedAmount);
	}

	updateFee(percentage: number, allocatedAmount: IAllocatedAmount) {
		const feeAmount = isNaN(percentage) ? 0 : this.toServerAmount((this.parentAmount * (percentage / 100)).toString());
		this.updateAmount(+feeAmount, percentage, allocatedAmount);
	}

	updateAmount(amount: number, percentage: number, allocatedAmount: IAllocatedAmount) {
		this.allocatedAmounts.find(x => x.sourceCharTypeId === allocatedAmount.sourceCharTypeId && x.sourceRecordId === allocatedAmount.sourceRecordId).amount = isNaN(amount) ? 0 : amount;
		this.allocatedAmounts.find(x => x.sourceCharTypeId === allocatedAmount.sourceCharTypeId && x.sourceRecordId === allocatedAmount.sourceRecordId).percentage = isNaN(percentage) ? 0 : percentage;
		this.onAmountChange.emit(this.allocatedAmounts);
	}
}
