import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { IAmortizationModel, IAmortizationPeriod } from "rl-common/services/amortization/amortization.models";
import { AmortizationService } from "rl-common/services/amortization/amortization.service";
import { Subscription } from "rxjs";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "rl-edit-amortization-model",
    templateUrl: "./edit-amortization-model.component.html",
    styleUrls: ["./edit-amortization-model.component.scss"],
    imports: [ReactiveFormsModule, NgIf, NgFor, FormsModule]
})
export class EditAmortizationModelComponent implements OnInit, OnDestroy {

	@Input()
	amortizationModel: IAmortizationModel;

	@Input()
	isView: boolean;

	@Output()
	onClose = new EventEmitter<boolean>();

	_subscriptions: Subscription[] = [];

	form: UntypedFormGroup;
	isSaving = false;

	get currentTotal() {
		if (!this.amortizationModel?.periods) {
			return 0;
		}

		return this.amortizationModel.periods.reduce((prev, cur) => prev + cur.split, 0).toFixed(2);
	}

	get periodCount() {
		if (!this.form) {
			return 0;
		}

		return +this.form.controls["periodCount"].value;
	}

	constructor(private readonly formBuilder: UntypedFormBuilder, private readonly amortizationService: AmortizationService) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			name: new UntypedFormControl(this.amortizationModel?.name, [Validators.required]),
			periodCount: new UntypedFormControl(this.amortizationModel?.periods?.length, [Validators.required])
		});

		this.form.controls["periodCount"].valueChanges.subscribe(x => {
			if (!this.amortizationModel.periods) {
				this.amortizationModel.periods = [];
			}

			const num = +x;
			if (num > this.amortizationModel.periods.length) {
				const amt = num - this.amortizationModel.periods.length;
				for (let i = 0; i < amt; i++) {
					this.amortizationModel.periods.push(<IAmortizationPeriod>{ id: 0, modelId: this.amortizationModel.id, split: 0 });
				}
			} else {
				this.amortizationModel.periods = this.amortizationModel.periods.splice(0, num);
			}
		});
	}

	close(requiresRefresh = false) {
		this.onClose.emit(requiresRefresh);
	}

	save() {
		this.isSaving = true;
		const newAmortModel: IAmortizationModel = {
			id: this.amortizationModel?.id ?? 0,
			name: this.form.controls["name"].value,
			periods: this.amortizationModel.periods
		};

		const sub = this.amortizationService.editAmortizationModel(newAmortModel).subscribe(
			_ => this.close(true),
			_ => this.isSaving = false
		);
		this._subscriptions.push(sub);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(x => x.unsubscribe());
	}
}
