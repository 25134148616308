import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CharValueSetColumnKey } from "rl-common/components/grid/datasource/config/char-value-set.column-strategy";
import { CharValueSetDataSource } from "rl-common/components/grid/datasource/config/char-value-set.datasource";
import { CommonDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/common-data-select.strategy";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { GridRowStyles } from "rl-common/components/grid/models/grid-row-styles";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { ICharacteristicValueSet } from "rl-common/models/i-characteristic-value-set";
import { AdminUtilService } from "rl-common/services/admin-util/admin-util.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { TokenStorageService } from "rl-common/services/token-storage/token-storage.service";
import { of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { GridTableComponent } from "../../../../../common/components/grid/grid-table/grid-table.component";
import { ICurrencyValidatorResponse, ILookup, LookupKeys } from "../lookup.models";

@Component({
    selector: "rl-lookup-edit-modal",
    templateUrl: "./lookup-edit-modal.component.html",
    styleUrls: ["./lookup-edit-modal.component.scss"],
    imports: [NgIf, GridTableComponent]
})
export class LookupEditModalComponent implements OnInit, OnDestroy {

	@Input()
	lookupKey: LookupKeys;

	@Output()
	onComplete = new EventEmitter<number>();

	subs: Subscription[] = [];
	isLoading = false;
	response: ICurrencyValidatorResponse;
	dataSource: CharValueSetDataSource;
	gridOptions: GridOptions<ICharacteristicValueSet> = {
		defaultGetCellDataFn: (column, cvs) => {
			switch (column.key) {
				case CharValueSetColumnKey.CharValueSetId:
					return cvs.charValueSetID;
				case CharValueSetColumnKey.CharValueSetLabel:
					return cvs.charValueSetLabel;
				case CharValueSetColumnKey.CharValueSetDescription:
					return cvs.charValueSetDescription;
				case CharValueSetColumnKey.CreatedBy:
					return cvs.createdBy;
				case CharValueSetColumnKey.CreatedAt:
					return cvs.createdAt;
				case CharValueSetColumnKey.UpdatedBy:
					return cvs.updatedBy;
				case CharValueSetColumnKey.UpdatedAt:
					return cvs.updatedAt;
				default:
					return cvs[column.key];
			}
		}
	};

	constructor(
		private readonly _adminUtilService: AdminUtilService,
		private readonly _tokenStorageService: TokenStorageService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder
	) { }

	ngOnInit(): void {
		const sub = this._adminUtilService.getLookup(this.lookupKey).subscribe(response => this.response = response);
		const dataSelectStrategy = new CommonDataSelectStrategy<ICharacteristicValueSet>(rd => rd.charValueSetID, rd => rd)
			.withSelectType(GridSelectType.Radio);
		this.dataSource = this._gridDataSourceBuilder.charValueSetDataSource(this.lookupKey)
			.withDataSelectStrategy(dataSelectStrategy)
			.setPaging({
				pageSize: 100
			})
			.withGetRowStylesFn(rd => {
				if (rd.charValueSetID === this.response.lookup.guidValue) {
					return of([GridRowStyles.Highlight]);
				}
				return of([]);
			});
		const sub2 = this.dataSource.fetchRows().subscribe();
		this.subs.push(sub, sub2);
	}

	saveLookup() {
		if (this.dataSource.dataSelectStrategy.selectedState.selectedIds?.size !== 1) {
			return;
		}
		const selectedValues = Array.from(this.dataSource.dataSelectStrategy.selectedState.selectedValues);
		const guidValue = (<ICharacteristicValueSet>selectedValues[0]).charValueSetID;
		const lkup: ILookup = {
			guidValue,
			key: this.lookupKey,
		};
		const sub = this._adminUtilService.saveLookup(this.lookupKey, lkup)
			.pipe(switchMap(() => this._oneConfigService.refresh(this._tokenStorageService.jwt)))
			.subscribe(() => window.location.reload());
		this.subs.push(sub);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	toArray(envEnum) {
		return Object.keys(envEnum)
			.map(key => envEnum[key]);
	}
}
