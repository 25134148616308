<div class="modal-content">
	<div class="modal-header">
		<h2 class="modal-title">Set Date Options</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="onClose.emit()"
			data-cy="DateMathCloseButton">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body">
		<ul ngbNav #nav="ngbNav" class="nav-tabs tabset--rl" [destroyOnHide]="false">
			<li ngbNavItem>
				<a ngbNavLink data-cy="DateMathTab">
					<i class="fas fa-calculator me-1" title="DateMath"></i> DateMath
				</a>
	
				<ng-template ngbNavContent>
					<rl-datemath-edit [dateMath]="dateMath" [cmd]="cmd" [recCharId]="recCharId"
						[isRelative]="isRelative" [parentEntityId]="parentEntityId" [isBulkEdit]="isBulkEdit"
						(dateMathChange)="dateMathChanged($event)" (close)="onClose.emit()">
					</rl-datemath-edit>
				</ng-template>
			</li>
			<li ngbNavItem *ngIf="showAlerts">
				<a ngbNavLink data-cy="DateAlertsTab">
					<i class="far fa-bell me-1" title="Date Alerts"></i> Date Alerts
				</a>
	
				<ng-template ngbNavContent>
					<rl-edit-alerts [cmd]="cmd" [alerts]="alerts" (alertsChange)="alertsChanged($event)"></rl-edit-alerts>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="nav" class="p-0"></div>
	</div>
</div>