import { Injectable } from "@angular/core";
import { IAccountManageState, IDataResponse, IGenerateGoogleAuthenticatorKeyResponse } from "../../components/manage-security/manage-security.models";
import { IChangePasswordResponse } from "../account/account.messages";
import { RlHttpClient } from "../rl-http-client.service";

@Injectable({
	providedIn: "root"
})
export class AccountService {

	constructor(private readonly _httpClient: RlHttpClient) { }

	saveAvatar(avatar: string) {
		return this._httpClient
			.put(`/account/save-avatar`)
			.body({
				avatar
			})
			.fetch();
	}

	changePassword(oldPassword: string, newPassword: string) {
		return this._httpClient
			.put(`/account/password`)
			.body({ oldPassword, newPassword })
			.fetch<IChangePasswordResponse>();
	}

	generateGoogleAuthenticatorKey() {
		return this._httpClient
			.get("/account/generate-google-authenticator-key")
			.fetch<IGenerateGoogleAuthenticatorKeyResponse>();
	}

	confirmGoogleAuthenticatorKey(secretKey: string, code: string) {
		return this._httpClient
			.post("/account/confirm-google-authenticator-key")
			.body({ secretKey, code })
			.fetch<IDataResponse<any>>();
	}

	getAccountManageState() {
		return this._httpClient
			.get("/account/get-account-manage-state")
			.fetch<IAccountManageState>();
	}

	removeGoogleAuthenticator(password: string) {
		return this._httpClient
			.delete("/account/remove-google-authenticator")
			.body({ password })
			.fetch();
	}

	getAvailableToken() {
		return this._httpClient
			.get("/account/get-available-token")
			.fetch();
	}

	getSupportToken() {
		return this._httpClient
			.get("/account/support-token")
			.fetch<string>();
	}
}
