import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IColumnMetaData } from "rl-common/components/grid/columns/column.models";
import { IListChangedEvent } from "rl-common/components/list-selector/list-selector.component";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ListSelectorComponent } from "../../list-selector/list-selector.component";
import { SyncMetadataToCatalogHierarchyModalResult } from "./sync-metadata-to-catalog-hierarchy-modal.models";

@Component({
    selector: "rl-sync-metadata-to-catalog-hierarchy-modal",
    templateUrl: "./sync-metadata-to-catalog-hierarchy-modal.component.html",
    imports: [ListSelectorComponent]
})
export class SyncMetadataToCatalogHierarchyModalComponent implements OnInit {
	@Input()
	charTypeId: number;
	@Input()
	recordId: number;
	@Input()
	charMetaData: ICharacteristicMetaData[];

	@Output()
	onApply = new EventEmitter<SyncMetadataToCatalogHierarchyModalResult>();

	unSelectedList: IColumnMetaData[] = [];
	selectedList: IColumnMetaData[] = [];
	unSelectedHeader = "Choose Fields";
	selectedHeader = "Selected Fields";

	constructor(private readonly activeModal: NgbActiveModal) { }

	ngOnInit() {
		this.unSelectedList = this.charMetaData.map((x) => {
			return {
				key: x.tagLabel,
				charDataTypeId: x.dataTypeID,
				multipleIndicator: x.multipleIndicator,
				displayName: x.label,
				isTaggedEntity: false,
				characteristicMetaData: x
			};
		}).sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	updateLists(event: IListChangedEvent<IColumnMetaData>) {
		this.selectedList = event.selectedList;
		this.unSelectedList = event.unSelectedList;
	}

	keyFn(item: IColumnMetaData) {
		return item.key;
	}

	labelFn(item: IColumnMetaData) {
		return item.displayName;
	}


	apply() {
		this.activeModal.dismiss();
		this.onApply.emit({ selectedColumns: this.selectedList });
	}

	cancel() {
		this.activeModal.dismiss();
	}
}
