
import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HierarchyGroupComponent } from "../hierarchy-group/hierarchy-group.component";
import { IEntityGroup } from "../transfer-associations.model";

@Component({
    selector: "rl-transfer-association-group",
    templateUrl: "./transfer-association-group.component.html",
    styleUrls: ["./transfer-association-group.component.scss"],
    imports: [HierarchyGroupComponent, ReactiveFormsModule, FormsModule, NgFor]
})
export class TransferAssociationGroupComponent implements OnInit {
	@Input()
	group: IEntityGroup;

	@Output()
	onChange = new EventEmitter<boolean>();

	public areAllSelected = true;
	constructor() { }

	ngOnInit() {
		this.group.associations.forEach(assoc => assoc.isSelected = true);
	}
	toggleAllSelected() {
		this.selectAll(this.areAllSelected);
		this.onChange.emit(true);
	}
	selectAssociation(recordId: number) {
		const association = this.group.associations.find(assoc => assoc.record.recordID === recordId);
		if (this.areAllSelected && !association.isSelected) {
			this.areAllSelected = false;
		} else if (!this.areAllSelected && this.group.associations.every(assoc => assoc.isSelected)) {
			this.areAllSelected = true;
		}
		this.onChange.emit(true);
	}

	public selectAll(value: boolean) {
		this.group.associations.forEach(assoc => assoc.isSelected = value);
	}
}
