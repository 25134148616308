import { FormArray, FormControl } from "@angular/forms";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IBuildAccountingOperationDate } from "./build-accounting-operation/models/i-build-accounting-operation-date";
import { IBuildAccountingOperationEvent } from "./build-accounting-operation/models/i-build-accounting-operation-event";
import { IBuildAccountingOperationTrigger } from "./build-accounting-operation/models/i-build-accounting-operation-trigger";

export interface IBuildAccountingProcessResult {
	isNewAccountingProcess: boolean;
	selectedContact: IAccountingContact;
	accountingProcessId: string;
}

export enum AccountingProcessSteps {
	AccountingProcess = `accounting-process`,
	AccountingOperations = `accounting-operations`
}

export interface IBuildAccountingProcessStatus {
	isSaving: boolean;
	message: string;
	isDirty?: boolean;
}

export interface IAccountingOperationForm {
	id: FormControl<string>;
	name: FormControl<string>;
	triggers: FormArray<FormControl<IBuildAccountingOperationTrigger>>;
	events: FormArray<FormControl<IBuildAccountingOperationEvent>>;
	dates: FormArray<FormControl<IBuildAccountingOperationDate>>;
}