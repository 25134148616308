import { Component, EventEmitter, Input } from "@angular/core";
import { IPreviewDocument } from "rl-common/services/workflow/workflow.models";
import { NgIf } from "@angular/common";
import { PreviewDocumentComponent } from "../execute-workflow-modal/execute-workflow-wizard/preview-document/preview-document.component";

@Component({
    selector: "rl-preview-document-modal",
    templateUrl: "./preview-document-modal.component.html",
    styleUrls: ["./preview-document-modal.component.scss"],
    imports: [NgIf, PreviewDocumentComponent]
})
export class PreviewDocumentModalComponent {

	@Input()
	file: IPreviewDocument;

	onClose = new EventEmitter<void>();

	constructor() { }

	close() {
		this.onClose.emit();
	}
}
