<rl-panel-switcher [activeId]="editMode" class="no-panel">
	<div rlPanelSwitcherHead>
		<div class="row alert-edit__header align-items-center">
			<div class="col-lg-6">
				<div class="d-inline-flex text-large">
					<span class="mt-1">Create New Alert: </span>
					<div ngbDropdown>
						<button class="btn btn-link border-bottom ms-2" ngbDropdownToggle role="button"
							[disabled]="editMode !== 'read'" data-cy="SelectAlertTypeButton">
							Select Alert Type
						</button>
						<div ngbDropdownMenu>
							<button ngbDropdownItem (click)="addCustomNotification()"
								data-cy="CreateCustomAlertButton">Create Custom Alert</button>
							<div class="dropdown-divider"></div>
							<h6 class="dropdown-header">Alert Profiles</h6>
							<button *ngFor="let notification of notifications$ | async" ngbDropdownItem
								(click)="addAlertFromDraft(notification)" [attr.data-cy]="notification.id">
								{{notification.label}}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="d-none d-lg-block col-lg-6 text-end">
				<em>Set email and in-app notifications to monitor this date.</em>
			</div>
		</div>
	</div>

	<rl-panel id="read">
		<ng-template rlPanelContent>
			<ng-container *ngIf="!isLoading; else loading">
				<div class="alert-edit__body">
					<ul class="list-unstyled">
						<li class="d-flex align-items-center border-bottom py-2" *ngFor="let alert of configAlerts">
							<div class="flex-grow-1">
								<rl-alert-title [characteristicMetaDatas]="characteristicMetaDatas" [cmd]="cmd"
									[alert]="alert">
								</rl-alert-title>
							</div>
							<div>
								<button class="btn btn-sm btn-icon btn-info" [disabled]="true" title="Edit"><i
										class="far fa-pencil"></i></button>
								<button class="btn btn-sm btn-icon btn-remove ms-2" [disabled]="true" title="Delete"><i
										class="far fa-trash-alt"></i></button>
							</div>
						</li>
						<li class="d-flex align-items-center border-bottom py-2" *ngFor="let alert of alerts">
							<div class="flex-grow-1">
								<rl-alert-title [characteristicMetaDatas]="characteristicMetaDatas" [cmd]="cmd"
									[alert]="alert">
								</rl-alert-title>
							</div>
							<div>
								<button class="btn btn-sm btn-icon btn-info" [disabled]="alert.recordID === 0"
									(click)="editAlert(alert)" title="Edit">
									<i class="far fa-pencil"></i>
								</button>
								<button class="btn btn-sm btn-icon btn-remove ms-2" [disabled]="alert.recordID === 0"
									(click)="deleteAlert(alert)" title="Delete">
									<i class="far fa-trash-alt"></i>
								</button>
							</div>
						</li>
					</ul>
				</div>
			</ng-container>
		</ng-template>
	</rl-panel>
	<rl-panel id="edit">
		<ng-template rlPanelContent>
			<div class="alert-edit__body">
				<h3>Edit Alert</h3>
				<rl-alert-form [cmd]="cmd" [cmds]="characteristicMetaDatas" [alert]="editingAlert" [isSubmitting]="isSubmitting" [parentEntityId]="parentEntityId" [templateId]="templateId" [notificationMessagesEnabled]="notificationMessagesEnabled"
					(onApply)="updateAlert($event)" (onCancel)="backToReadView()">
				</rl-alert-form>
			</div>
		</ng-template>
	</rl-panel>
	<rl-panel id="create">
		<ng-template rlPanelContent>
			<div class="alert-edit__body">
				<h3>New <strong>{{editingLabel}}</strong> Alert</h3>
				<rl-alert-form [cmd]="cmd" [cmds]="characteristicMetaDatas" [alert]="editingAlert" [isSubmitting]="isSubmitting" [parentEntityId]="parentEntityId" [templateId]="templateId" [notificationMessagesEnabled]="notificationMessagesEnabled"
					(onApply)="addNewAlert($event)" (onCancel)="backToReadView()">
				</rl-alert-form>
			</div>
		</ng-template>
	</rl-panel>
</rl-panel-switcher>

<ng-template #loading>
	<rl-loader></rl-loader>
</ng-template>