import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cloneDeep, some } from "lodash";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { CharTypeId } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { RightsService } from "rl-common/services/rights/rights.service";
import { RelativeRightsProfilesOptions } from "rl-common/services/rights/rights.service.models";
import { SessionService } from "rl-common/services/session.service";
import { QueryUtil } from "rl-common/utils";
import { AclUtil } from "rl-common/utils/acl.util";
import { Subscription } from "rxjs";
import { finalize, map, tap } from "rxjs/operators";
import { ComponentRelationshipComponent } from "../../associations/entity-relationships/entity-relationship/component-relationship.component";
import { ChipComponent } from "../../chip/chip.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { OneConfigService } from "./../../../services/one-config/one-config.service";
import { GridDataSourceBuilder } from "./../../grid/datasource/builders/grid-datasource-builder";
import { AssocEntitySelectDataSource } from "./../../grid/datasource/search/assoc-entity-select.datasource";
import { IApplyRelativeRightsProfileEvent } from "./applt-relative-rights-profile.models";


@Component({
	selector: "rl-apply-relative-rights-profile",
	templateUrl: "./apply-relative-rights-profile.component.html",
	styleUrls: ["./apply-relative-rights-profile.component.scss"],
	imports: [NgIf, LoaderComponent, ArchwizardModule, ComponentRelationshipComponent, ReactiveFormsModule, FormsModule, NgFor, ChipComponent]
})
export class ApplyRelativeRightsProfileComponent implements OnInit, OnDestroy {

	@Input()
	recordId: number;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	profile: IEntitySearchDoc;

	@Input()
	catalogsSelected: IRecordTitle[] = [];

	@Output()
	onCreate = new EventEmitter<IApplyRelativeRightsProfileEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	@ViewChild(WizardComponent)
	wizard: WizardComponent;

	rightsCharType = CharTypeId.Right;

	isApplying = false;
	state: IEntityRelationshipState<number, IEntitySearchDoc>;

	isLoading = false;
	hasInvalidRights = false;
	userCanCreateAllRightsOnRrp = true;
	selectedRelativeRightsOption = 2;
	allCatalogsRemoved = false;
	isFromCatalogList = false;
	rightsTemplateId: number;
	subs: Subscription[] = [];
	dataSource: AssocEntitySelectDataSource<IEntitySearchDoc, unknown>;

	relativeRightsOptions = [
		{
			label: "Add to existing Rights Profiles on selected Catalog items",
			value: RelativeRightsProfilesOptions.Default
		},
		{
			label: "Refresh this Rights Profile on selected Catalog items",
			value: RelativeRightsProfilesOptions.DeleteAndRecreate
		},
		{
			label: "Remove from existing Rights Profiles on selected Catalog items",
			value: RelativeRightsProfilesOptions.Remove
		}
	];

	get relationshipsSelected() {
		return this.state?.selectedCount > 0;
	}

	constructor(
		private readonly _session: SessionService,
		private readonly _rightsService: RightsService,
		private readonly _parentEntityService: ParentEntityService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _oneConfigService: OneConfigService
	) { }

	ngOnInit() {
		this.isFromCatalogList = this.catalogsSelected.length > 0;
		if (!this.isFromCatalogList) {
			this.rightsTemplateId = this._oneConfigService.getDefaultTemplate(CharTypeId.Right).templateID;

			this._parentEntityService.initializeParent({
				charTypeId: CharTypeId.Right,
				recordId: -1,
				templateId: this.rightsTemplateId,
				parent: {
					charTypeId: this.charTypeId,
					recordId: this.recordId,
					templateId: this.templateId
				}
			});

			const sub = this.buildDataSource$().subscribe(dataSource => {
				this.dataSource = dataSource;
			});

			this.subs.push(sub);
		}
	}

	buildDataSource$() {
		this.isLoading = true;
		return this._rightsService.getMockRightsTemplates(this.profile.recordID).pipe(
			finalize(() => this.isLoading = false),
			tap(mockTemplates => {
				const acls = this._session.acls;

				const validTemplates = this._oneConfigService.getChildAssocTemplates(this.charTypeId, this.templateId, CharTypeId.Right).filter(x => AclUtil.hasCreateAccess(acls, x.acl));

				this.hasInvalidRights = some(mockTemplates, x => validTemplates.find(associatedTemplate => associatedTemplate.templateID === x.templateID) === undefined);

				this.userCanCreateAllRightsOnRrp = mockTemplates.every(x => {
					const rrpAcls = AclUtil.getDataEntityAcl(CharTypeId.Right, x.template.templateGroupID, x.template.templateID);
					return AclUtil.hasCreateAccess(acls, rrpAcls) && AclUtil.hasWriteAccess(acls, rrpAcls);
				});
			}),
			map(mockTemplates => this._gridDataSourceBuilder.assocEntitySearchDataSource(CharTypeId.Property, CharTypeId.Right, null, this.recordId, null, this.charTypeId, this.recordId))
		);
	}

	cancel() {
		this.onCancel.emit();
	}

	setSelected(event: IEntityRelationshipState<number, IEntitySearchDoc>) {
		this.state = event;
	}

	createRightsFromCatalogList() {
		this.isApplying = true;
		// TODO: Support the ability to select all catalogs from the catalog listing page
		const catalogRecordIds = this.catalogsSelected.map(x => x.recordId);
		const sub = this._rightsService.applyRelativeRightsProfile(this.profile.recordID, null, catalogRecordIds, null, this.selectedRelativeRightsOption)
			.subscribe(() => {
				this.isApplying = false;
				this.wizard.goToNextStep();
			});
		this.subs.push(sub);
	}

	createRights() {
		this.isApplying = true;
		const model = cloneDeep(this.state.model);
		if (!this.state.gridSelected.isAllSelected) {
			let query = model.query;
			query = QueryUtil.$and(
				query,
				QueryUtil.$eq_any(SearchFieldNames.Entity.recordID, this.state.selectedIds)
			);
			model.query = query;
		}
		const sub = this._rightsService.applyRelativeRightsProfile(this.profile.recordID, this.recordId, [], model, RelativeRightsProfilesOptions.Default)
			.subscribe(() => {
				this.isApplying = false;
				this.wizard.goToNextStep();
			});
		this.subs.push(sub);
	}

	remove(rec: IRecordTitle) {
		this.catalogsSelected = this.catalogsSelected.filter(x => x.recordId !== rec.recordId);
		if (this.catalogsSelected.length < 1) {
			this.allCatalogsRemoved = true;
		}
	}

	finish() {
		this.onCreate.emit({ success: true });
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
