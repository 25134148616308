import { Component, OnInit } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";

@Component({
    selector: "rl-hierarchy-group",
    templateUrl: "./hierarchy-group.component.html",
    styleUrls: ["./hierarchy-group.component.scss"],
    imports: [NgClass, NgIf]
})
export class HierarchyGroupComponent implements OnInit {

	isExpanded = true;
	constructor() { }

	ngOnInit() {
	}
	toggle() {
		this.isExpanded = !this.isExpanded;
	}

}
