import { EventEmitter, Injectable, Type } from "@angular/core";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
export interface ITemplateFieldAddComponent {
	group: ITemplateCharacteristicGroup;
	templateFieldModel: ICharacteristicMetaData;
	charMetaData: ICharacteristicMetaData[];
	templateId: number;
	onComplete: EventEmitter<boolean>;
}

@Injectable({ providedIn: "root" })
export class TemplateFieldAddModalAdapter extends ModalAdapter<ITemplateFieldAddComponent> {
	constructor(type: Type<ITemplateFieldAddComponent>) {
		super(type);
	}

}


