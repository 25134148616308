import { Component, Input } from "@angular/core";
import { IzendaTokenService } from "../../../common/services/izenda-token.service";
import { IzendaReportDirective } from "../../directives/izenda-report.directive";

@Component({
    selector: "rl-izenda-report",
    templateUrl: "./izenda-report.component.html",
    styleUrls: ["./izenda-report.component.scss"],
    imports: [IzendaReportDirective]
})
export class IzendaReportComponent {

	@Input()
	reportId: string;

	@Input()
	hideToolbar = false;

	@Input()
	hideFilter = false;

	@Input()
	hideReportName = false;

	@Input()
	hidePreviewRecords = false;

	isLoading = true;

	constructor(private _izendaTokenService : IzendaTokenService) {
		this._izendaTokenService.isLoading$.subscribe(() => {
			this.isLoading = false;
		});
	}

}
