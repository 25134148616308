import { KeyValuePipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { CharTypeNamePipe } from "../../../pipes/char-type-name.pipe";

@Component({
    selector: "rl-locked-parents-modal",
    templateUrl: "./locked-parents-modal.component.html",
    imports: [NgFor, NgIf, KeyValuePipe, CharTypeNamePipe]
})
export class LockedParentsModalComponent implements OnInit {
	@Input()
	recordsWithLockedParents: Map<IEntitySearchDoc, IEntitySearchDoc[]>;

	@Output()
	onConfirm = new EventEmitter<boolean>();

	expandedRows: { [recId: number]: boolean } = {}

	constructor() { }

	ngOnInit() {
	}

	public decline() {
		this.onConfirm.emit(false);
	}

	public accept() {
		this.onConfirm.emit(true);
	}

	toggleRow(recId: number) {
		this.expandedRows[recId] = !this.expandedRows[recId];
	}
}
