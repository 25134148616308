import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { intersection, uniq, values } from "lodash";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { IQueryNode } from "rl-common/models/i-query-node";
import { ConflictsService } from "rl-common/services/conflicts/conflicts.service";
import { DealService } from "rl-common/services/deal/deal.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { ProgressService } from "rl-common/services/progress.service";
import { QueryUtil } from "rl-common/utils/query.util";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { BusyButtonDirective } from "../../../../directives/busy-button.directive";
import { LocaleDatePipe } from "../../../../pipes/locale-date.pipe";
import { CellTemplateDirective } from "../../../grid/directives/cell-template.directive";
import { GridTableComponent } from "../../../grid/grid-table/grid-table.component";
import { ConflictCheckIssueComponent } from "../conflict-check-issue/conflict-check-issue.component";
import { ConflictListReportColumnNames, IConflictCheckDealConflicts } from "../validation-errors-modal.models";

@Component({
    selector: "rl-conflict-check-update-status",
    templateUrl: "./conflict-check-update-status.component.html",
    styleUrls: ["./conflict-check-update-status.component.scss"],
    imports: [GridTableComponent, CellTemplateDirective, ConflictCheckIssueComponent, ReactiveFormsModule, FormsModule, BusyButtonDirective, LocaleDatePipe]
})
export class ConflictCheckUpdateStatusComponent implements OnInit, OnDestroy {

	@Input()
	isBlocker: boolean;

	@Input()
	selectedState: GridSelectState<string, IConflictCheckDealConflicts>;

	@Input()
	filterQueryNodes: _.Dictionary<IQueryNode>;

	@Input()
	alternativeLabelConflictTypeIds: number[];

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onSave = new EventEmitter<void>();

	dataSource: SearchGridDataSource<IConflictCheckDealConflicts>;
	reasonForChange: { [id: string]: string } = {};
	bulkReasonForChange: string;

	private readonly columns: GridColumn<IConflictCheckDealConflicts>[] = [
		{
			key: "issue",
			headerName: "Issue",
			renderer: "conflict-check-issue",
			width: "auto",
			getCellData: (not => not),
		},
		{
			key: "dates",
			headerName: "Dates",
			renderer: "date-range",
			width: "min-content",
			getCellData: (not => {
				return {
					startDate: not.startDate,
					endDate: not.endDate
				};
			})
		},
		{
			key: "lastUpdated",
			headerName: "Last Updated",
			renderer: "date",
			width: "min-content",
			getCellData: (not => not.lastUpdatedTimestamp)
		},
		{
			key: "reason",
			headerName: "Reason for Change",
			renderer: "reason-for-change",
			width: "auto",
			getCellData: (not => not),
			columnEditRenderer: "textarea"
		}
	];
	gridOptions: GridOptions<IConflictCheckDealConflicts> = {
	};

	subscriptions: Subscription[] = [];

	constructor(
		private gridDataServiceBuilder: GridDataSourceBuilder,
		private dealService: DealService,
		private modDetailService: ModDetailService,
		private progressService: ProgressService,
		private readonly _conflictsService: ConflictsService
	) { }

	ngOnInit() {
		const dataSelectStrategy = this.gridDataServiceBuilder.dataSelectStrategies.commonDataSelectStrategy<IConflictCheckDealConflicts, string, IConflictCheckDealConflicts>(
			(rowData) => rowData.id, (rowData) => rowData)
			.withEnabled(false);

		const dataChangeStrategy = this.gridDataServiceBuilder.dataChangeStrategies.commonDataChangeStrategy<IConflictCheckDealConflicts>()
			.setEditEnabled(true)
			.setColumnEditToolsEnabled(true);

		this.dataSource = this.gridDataServiceBuilder.searchGridDataSource<IConflictCheckDealConflicts>((row) => row.id)
			.withProgress(true)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: ConflictListReportColumnNames.ConflictId, sortDir: 0 })
			.setColumns(this.columns)
			.withDataSelectStrategy(dataSelectStrategy)
			.withDataChangeStrategy(dataChangeStrategy)
			.withFetchFn((ds) => {

				const filterQueryNode = this.buildFilterQueryNode();

				return this._conflictsService.conflictCheck(
					this.modDetailService.charTypeId,
					this.modDetailService.recordId,
					filterQueryNode,
					ds.sortKey$.value as string,
					ds.sortDir$.value,
					ds.rowOffset$.value,
					ds.pageSize$.value,
					[])
					.pipe(
						map(results => {
							return { rowCount: results.numFound, rowData: results.data.rows } as IGridFetchResults<IConflictCheckDealConflicts>;
						})
					);
			});

		const sub = this.dataSource.modifiedColumnDatas$.subscribe(mod => {
			const columnEdit = mod.get("reason");

			if (columnEdit) {
				this.bulkReasonForChange = columnEdit[1].toString();
			} else {
				this.bulkReasonForChange = "";
			}
		});
		this.subscriptions.push(sub);

		this.subscriptions.push(this.dataSource.fetchRows().subscribe());
	}

	buildFilterQueryNode() {
		const selectedIds = this.selectedState ? Array.from(this.selectedState.selectedIds) || [] : [];
		const deselectedIds = this.selectedState ? Array.from(this.selectedState.deselectedIds) || [] : [];
		const isAllSelected = this.selectedState ? this.selectedState.isAllSelected : false;

		const columnFilters = this.filterQueryNodes;

		if (isAllSelected && deselectedIds.length > 0) {
			columnFilters[ConflictListReportColumnNames.ConflictId] = QueryUtil.$eq_none(ConflictListReportColumnNames.ConflictId, deselectedIds);
		} else if (selectedIds.length > 0) {
			columnFilters[ConflictListReportColumnNames.ConflictId] = QueryUtil.$eq_any(ConflictListReportColumnNames.ConflictId, selectedIds);
		}

		const filterQueryNode = QueryUtil.$and(...values(columnFilters));
		return filterQueryNode;
	}

	canUpdateConflictCheckStatus() {
		const hasBulkReason = !!this.bulkReasonForChange;
		const hasReasonForEveryRow = this.dataSource.rowCount$.value === Object.keys(this.reasonForChange).length;
		const everyReasonIsNotNull = Object.keys(this.reasonForChange).every(x => !!this.reasonForChange[x]);

		return hasBulkReason || (hasReasonForEveryRow && everyReasonIsNotNull);
	}

	cancel() {
		this.onCancel.emit();
	}

	save() {
		const charTypeId = this.modDetailService.charTypeId;
		const recordId = this.modDetailService.recordId;
		const specificReasons = Object.keys(this.reasonForChange).map(x => {
			return {
				id: x,
				reason: this.reasonForChange[x]
			};
		});

		this.progressService.startProgress();

		const sub = this._conflictsService.updateConflictCheckStatus(
			charTypeId,
			recordId,
			this.buildFilterQueryNode(),
			this.isBlocker ? 0 : 1,
			this.bulkReasonForChange,
			specificReasons
		).subscribe(() => {
			this.onSave.emit();
			this.progressService.endProgress();
		});
		this.subscriptions.push(sub);
	}

	getConflictActionLabel() {
		const rowData = this.dataSource.rowData$.value ?? [];
		const conflictTypeIds = uniq(rowData.map(x => x.conflictTypeId));

		if (intersection(conflictTypeIds, this.alternativeLabelConflictTypeIds).length == conflictTypeIds.length) {
			if (!this.isBlocker) return "Resolved";
			else return "Needs Attention";
		} else {
			if (this.isBlocker) return "Blocker";
			else return "Non-Blocker";
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
