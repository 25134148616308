import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { RlHttpClient } from "./../../../common/services/rl-http-client.service";
import { INotification, INotificationResponse, NotificationStatus } from "./notification.models";

@Injectable({
	providedIn: "root"
})
export class NotificationService {

	public notifications$ = new Subject<INotificationResponse>();
	public unreadNotifications$ = new BehaviorSubject<INotification[]>([]);
	public unreadNotificationCount$ = new BehaviorSubject<number>(0);
	constructor(
		private readonly httpClient: RlHttpClient
	) { }
	public getNotifications(start: number, count: number, charTypeId: number, statusLabel: string, sortColumn: string, sortDirection: string) {
		return this.httpClient.get(`/account/notifications/${charTypeId}`)
			.params({
				start: start,
				count: count,
				statusLabel: statusLabel,
				sortColumn: sortColumn,
				sortDirection: sortDirection
			}).fetch<INotificationResponse>()
			.pipe(
				tap(notifications => {
					this.notifications$.next(notifications);
					if (statusLabel === NotificationStatus.Unread) {
						this.unreadNotifications$.next(notifications.data);
					} else if (statusLabel === NotificationStatus.All) {
						const unreadNotifications = notifications.data.filter(not => not.status === NotificationStatus.Unread);
						this.unreadNotifications$.next(unreadNotifications);
					}
				})
			);
	}

	public getNotificationCount$(charTypeId: number, statusLabel: string, sortColumn: string, sortDirection: string) {
		return this.httpClient.get(`/account/notifications/${charTypeId}/count`)
			.params({
				statusLabel: statusLabel,
				sortColumn: sortColumn,
				sortDirection: sortDirection
			}).fetch<number>()
			.pipe(
				tap(count => {
					if (statusLabel === NotificationStatus.Unread) {
						this.unreadNotificationCount$.next(count);
					}
				})
			);
	}

	public markAllAsRead(charTypeId: number) {
		return this.httpClient.post(`/account/notifications/${charTypeId}/readAll`)
			.fetch<number>();
	}

	public setNotificationStatus(charTypeId: number, notificationIds: number[], status: string) {
		return this.httpClient.post(`/account/notifications/${charTypeId}/status`)
			.body({
				notificationIds: notificationIds,
				status: status
			})
			.fetch<void>();
	}
}
