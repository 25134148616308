<div class="modal-header">
	<h2 class="modal-title">Update Contact snapshot for this Deal only</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div>
		<rl-char-data-table [charData]="modalCharData" [template]="modalTemplate" [editMode]="true"
			(tableCharDataChange)="modalCharData=$event.charData"></rl-char-data-table>
	</div>
</div>
<div class="modal-footer">
	<button (click)="cancel()" class="btn btn-light me-3">Cancel</button>
	<button (click)="saveContactOnDeal()" class="btn btn-success" [class.disabled]="isSaving" [disabled]="isSaving">
		<i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
		Update Deal Snapshot
	</button>
</div>