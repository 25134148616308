import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from "rl-common/services/account/account.service";
import { IUserApplicationInfo } from "rl-common/services/company/company.models";
import { ForgotPasswordService } from "rl-common/services/forgot-password/forgot-password.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { ProgressService } from "rl-common/services/progress.service";
import { UserService } from "rl-common/services/user/user.service";
import { FormValidators } from "rl-common/utils/form-validators.util";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { BusyButtonDirective } from "../../directives/busy-button.directive";
import { NewTabInModalDirective } from "../../directives/new-tab-in-modal.directive";

@Component({
	selector: "rl-change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.scss"],
	imports: [NgIf, NgbAlert, NewTabInModalDirective, RouterLink, ReactiveFormsModule, FormsModule, BusyButtonDirective]
})
export class ChangePasswordComponent implements OnInit {

	@Output()
	onCancel = new EventEmitter();

	@Input()
	resetMode: boolean;

	private token: string;
	public portalOnlyUser: boolean = false;
	private apps: IUserApplicationInfo[];
	private partnerPortalTypeId: number = 1;
	public portalLink;

	public errorMessage: string;
	public resetPasswordForm: UntypedFormGroup;
	public password: UntypedFormControl;
	public confirmPassword: UntypedFormControl;
	public showSuccessMessage = false;
	public errorAlertClosed = true;
	public successAlertClosed = true;
	public currentPassword: UntypedFormControl;

	constructor(private readonly _formBuilder: UntypedFormBuilder,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _forgotPasswordService: ForgotPasswordService,
		private readonly _progressService: ProgressService,
		private readonly _accountService: AccountService,
		private readonly _growlerService: GrowlerService,
		private readonly _modalService: ModalServiceAbstract,
		private readonly _userService: UserService
	) { }

	ngOnInit() {
		this.setupForm();
		//APP-7771
		//Temporary until portal 2.0
		let sub = this._forgotPasswordService.getUserDivApplications(this.token).subscribe((result) => {
			this.portalOnlyUser = result.appTypeIDs.length == 1 && result.appTypeIDs.includes(this.partnerPortalTypeId);
			if (this.portalOnlyUser) {
				this.portalLink = result.urls[0];
			}
		});
	}

	private setupForm() {
		this.token = this._activatedRoute.snapshot.params["token"];
		this.password = this._formBuilder.control("", [Validators.required, FormValidators.validPassword]);
		this.confirmPassword = this._formBuilder.control("", Validators.required);
		this.currentPassword = this._formBuilder.control("", Validators.required);

		this.resetPasswordForm = new UntypedFormGroup({
			password: this.password,
			confirmPassword: this.confirmPassword
		}, FormValidators.confirmPasswordsMatch);
	}

	public submitChange() {
		this.errorMessage = null;
		this.errorAlertClosed = true;

		if (!this.resetPasswordForm.valid) {
			return;
		}
		this._progressService.startProgress();
		this._accountService.changePassword(this.currentPassword.value, this.password.value)
			.subscribe((result) => {
				if (result.succeeded) {
					this.close();
					this._growlerService.success().growl("Password has been changed");
					this._progressService.endProgress();
				} else {
					this.errorMessage = result.errors.join();
					this.errorAlertClosed = false;
				}
				this._progressService.clearProgress();
			});
	}

	public submit() {
		this.showSuccessMessage = false;
		this.errorMessage = null;
		this.successAlertClosed = true;
		this.errorAlertClosed = true;

		if (!this.resetPasswordForm.valid) {
			return;
		}
		this._progressService.startProgress();

		this._forgotPasswordService.resetPassword(this.token, this.password.value)
			.pipe(catchError(error => {
				if (error.error.message) {
					this.errorMessage = error.error.message;
					this.errorAlertClosed = false;
				}
				this._progressService.clearProgress();
				return of(null);
			}))
			.subscribe((result) => {
				if (!result) {
					return;
				}
				this.showSuccessMessage = true;
				this.successAlertClosed = false;

				this._progressService.endProgress();
			});
	}

	showGdpr() {
		this._modalService.showGdpr();
	}

	close() {
		this.onCancel.emit();
	}
}
