import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Dictionary, values } from "lodash";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { getDocument } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IModsLinks } from "rl-common/models/i-mods-links";
import { IComponentRow } from "rl-common/services/components/component-group.models";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { Subscription } from "rxjs";
import { NewTabInModalDirective } from "../../../directives/new-tab-in-modal.directive";
import { CustomViewsComponent } from "../../custom-views/custom-views.component";
import { CellTemplateDirective } from "../../grid/directives/cell-template.directive";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";

@Component({
	selector: "rl-bulk-amounts",
	templateUrl: "./bulk-amounts.component.html",
	styleUrls: ["./bulk-amounts.component.scss"],
	imports: [CustomViewsComponent, NgIf, GridTableComponent, CellTemplateDirective, NewTabInModalDirective, RouterLink, AsyncPipe]
})
export class BulkAmountsComponent implements OnInit {
	@Input()
	amountsDataSource: CommonGridDataSource<IEntitySearchDoc>;

	@Input()
	parentRowData: IComponentRow | IEntitySearchDoc;

	@Input()
	rowPath: string;

	modLinkHelperInvoices: IModsLinks;
	amountCharTypeId: CharTypeId = CharTypeId.Amount;
	amountTemplateId: number = null;

	amountsGridOptions: GridOptions<any> = {

	};

	private subs: Subscription[] = [];

	isLocked$ = this._modDetailService.isLocked$;

	constructor(
		private readonly _linkHelper: LinkHelperService,
		private readonly _modDetailService: ModDetailService,
		private readonly _parentEntityService: ParentEntityService,
	) { }

	ngOnInit() {
		this.modLinkHelperInvoices = this._linkHelper.mods[CharTypeId.Invoice];

		const parentDocument = getDocument(this.parentRowData);
		const baseEntityId = this._modDetailService.entityData.entityId;
		const parentEntityId = parentDocument.entityID;
		const parentRelRecId = (<any>parentDocument).relRecId;

		this._parentEntityService.initRelParent(baseEntityId, parentEntityId, parentRelRecId);

		var rowTemplates: Dictionary<ICharacteristicMetaDataCollection> = this.amountsDataSource["rowTemplates"];
		if (rowTemplates && values(rowTemplates).length == 1) {
			this.amountTemplateId = values(rowTemplates)[0].templateID;
		}
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
