import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cloneDeep, first, isEqual } from "lodash";
import { CharDataElementUsage } from "rl-common/components/char-data/char-data.models";
import { CharDataType } from "rl-common/consts";
import { ICharLovMetaData } from "rl-common/models/i-char-lov-meta-data";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { LovUtil } from "rl-common/utils/lov-util";
import { BehaviorSubject } from "rxjs";
import { CharDataElementComponent } from "../../../../../common/components/char-data/char-data-element.component";
import { DropdownEditControlComponent } from "../../../../../common/components/char-data/controls/dropdown-edit-control.component";
import { ElementValueAccessorDirective } from "../../../../../common/components/char-data/elements/element-value-accessor";
import { TextModeDateInputComponent } from "../../../../../common/components/text-mode-date-input/text-mode-date-input.component";
import { LocaleDatePipe } from "../../../../../common/pipes/locale-date.pipe";
import { AccountingProcesses } from "../../accounting-processes.consts";

@Component({
	selector: "rl-data-behavior-single-value-entry",
	templateUrl: "./data-behavior-single-value-entry.component.html",
	styleUrls: ["./data-behavior-single-value-entry.component.scss"],
	imports: [NgSwitch, NgSwitchCase, CharDataElementComponent, ElementValueAccessorDirective, ReactiveFormsModule, NgIf, DropdownEditControlComponent, TextModeDateInputComponent, FormsModule, LocaleDatePipe]
})
export class DataBehaviorSingleValueEntryComponent implements OnInit, OnChanges {

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	control: FormControl<ICharacteristicData[]>;

	@Input()
	editMode = true;

	elementUsage = CharDataElementUsage.CharDataTable;

	entryType: "dropdown" | "char-data" | "date" = "char-data";
	isLov: boolean;
	lmd: ICharLovMetaData;
	lovs: ICharacteristicMetaDataValue[];
	focused$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	get selectedValueIds() {
		return (this.control?.value ?? []).map(cd => cd.valueID);
	}

	get selectedValuesLabel() {
		const valueIds = this.selectedValueIds;
		const lovs = this.lovs ?? [];
		const labels = lovs.filter(x => valueIds.includes(x.characteristicValueID)).map(x => x.characteristicValueLabel);
		return labels.join(", ");
	}

	dateValue: string;

	constructor(
		private readonly _oneConfig: OneConfigService
	) { }

	ngOnInit(): void {

	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.cmd && !isEqual(changes.cmd.currentValue, changes.cmd.previousValue) && this.cmd) {
			const isLov = AccountingProcesses.CharDataValueMapping.isLov(this.cmd);
			const isCheckbox = AccountingProcesses.CharDataValueMapping.isCheckbox(this.cmd);
			if (isLov || isCheckbox) {
				this.lmd = this._oneConfig.getLovMetaData(this.cmd.charValueSourceID);
				this.lovs = this.lmd.listOfValues;
				this.entryType = "dropdown";
			} else if (this.cmd.dataTypeID === CharDataType.Date) {
				this.entryType = "date";
				const date = first(this.control.value?.map(x => x.value) ?? []);
				this.dateValue = date;
			}
		}
	}

	selectedValueChanged(value: number[]) {
		const newCharData = LovUtil.convertValueIdsToCharData(this.cmd, this.lovs, value);
		const newCharDatas = (newCharData).filter(cd => cd.valueID != null);
		const oldCharDatas = this.control.value;
		this.control.setValue(newCharDatas);
		if (!isEqual(oldCharDatas, newCharDatas)) {
			this.control.markAsDirty();
		}
	}

	dateUpdated() {
		const charData = cloneDeep(first(this.control.value)) ?? <ICharacteristicData>{ charactersticID: this.cmd.characteristicID };
		const oldDate = charData?.value;
		charData.value = this.dateValue;
		this.control.setValue([charData]);
		if (oldDate !== this.dateValue) {
			this.control.markAsDirty();
		}
	}

}
