import { AsyncPipe, JsonPipe } from "@angular/common";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { Dictionary } from "lodash";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { CharTypeId } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IModsLinks } from "rl-common/models/i-mods-links";
import { IQueryNode } from "rl-common/models/i-query-node";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { QueryUtil } from "rl-common/utils";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GridNestedTemplateDirective } from "../../common/components/grid/directives/grid-nested-template.directive";
import { GridRowHoverOpsDirective } from "../../common/components/grid/directives/grid-row-hover-ops.directive";


@Component({
	selector: "rl-grid-test",
	templateUrl: "./grid-test3.component.html",
	imports: [GridTableComponent, GridRowHoverOpsDirective, GridNestedTemplateDirective, NgbPopover, ReactiveFormsModule, AsyncPipe, JsonPipe]
})
export class GridTest3Component implements OnInit, OnDestroy {

	@ViewChild("wrapper", { static: true })
	wrapperElRef: ElementRef<HTMLElement>;

	@ViewChild("handle", { static: true })
	handleElRef: ElementRef<HTMLElement>;

	@ViewChild("grid", { static: true })
	grid: GridTableComponent;

	modLinkHelper: IModsLinks;

	charTypeID = 1;

	rightsetRecordCounts$ = new BehaviorSubject<Dictionary<number>>({});

	columns: GridColumn<IEntitySearchDoc>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "link_id",
			width: "min-content",
			getCellData: (doc) => doc,
			locked: true
		},
		{
			key: "name",
			headerName: "Name",
			width: "minmax(max-content, 250px)",
			renderer: "link_name",
			getCellData: (doc) => doc
		},
		{
			key: "template",
			headerName: "Template",
			width: "auto",
			renderer: "text",
			getCellData: (doc) => doc.template,
			facetKey: "template_meta",
			filterKey: "template_meta"
		},
		{
			key: "status",
			headerName: "Status",
			width: "auto",
			renderer: "text",
			getCellData: (doc) => doc.status,
			facetKey: "status_meta",
			filterKey: "status_meta"
		}
	];
	gridOptions: GridOptions<IEntitySearchDoc> = {
		defaultGetCellDataFn: (column, doc) => {
			switch (column.key) {
				case "id":
					return doc.recordID;
				default:
					return doc.characteristics[column.key];
			}
		},
		// bodyHeight: "minmax(auto, 300px)"
	};

	dataSource: SearchGridDataSource<IEntitySearchDoc, IEntitySearchDoc>;

	nestedGridColumns: GridColumn<IEntitySearchDoc>[] = [
		{
			key: "name",
			headerName: "Name",
			width: "250px",
			renderer: "link_name",
			getCellData: (doc) => doc
		},
		{
			key: "template",
			headerName: "Template",
			width: "auto",
			renderer: "text",
			getCellData: (doc) => doc.template,
			facetKey: "template_meta"
		},
		{
			key: "status",
			headerName: "Status",
			width: "auto",
			renderer: "text",
			getCellData: (doc) => doc.status,
			facetKey: "status_meta"
		}
	];

	nestedGridOptions: GridOptions<IEntitySearchDoc> =
		{
			defaultGetCellDataFn: (column, doc) => {
				switch (column.key) {
					case "id":
						return doc.recordID;
					default:
						return doc.characteristics[column.key];
				}
			}
		};

	selectState$: Observable<any>;

	constructor(
		private linkHelper: LinkHelperService,
		private gridDataSourceBuilder: GridDataSourceBuilder) { }

	ngOnInit() {

		const nestedStrategy = this.gridDataSourceBuilder.nestedStrategies.searchNestedStrategy(this.charTypeID, CharTypeId.Right)
			.withNestedDataSourceBuilderFn((rowData: IEntitySearchDoc) => {
				const query: IQueryNode = QueryUtil.$join_parent_rel(
					QueryUtil.$and(
						QueryUtil.$eq(SearchFieldNames.Relationship.parentCharTypeID, rowData.charTypeID),
						QueryUtil.$eq(SearchFieldNames.Relationship.parentRecordID, rowData.recordID)
					)
				);
				return this.gridDataSourceBuilder.entitySearchGridDataSourceDeprecated(CharTypeId.Right)
					.withProgress(true)
					.setColumns(this.nestedGridColumns)
					.setQueryNode(query);
			});

		this.dataSource = this.gridDataSourceBuilder.entitySearchGridDataSourceDeprecated<IEntitySearchDoc>(this.charTypeID)
			.withProgress(true)
			.withNestedStrategy(nestedStrategy)
			.setColumns(this.columns);

		this.selectState$ = this.dataSource.dataSelectStrategy.selectStateChange$
			.pipe(
				map((state) => ({
					isAllSelected: state.isAllSelected,
					selectedIds: Array.from(state.selectedIds),
					selectedValues: Array.from(state.selectedValues)
				}))
			);

		this.modLinkHelper = this.linkHelper.mods[1];
		this.search();

		const wrapper = this.wrapperElRef.nativeElement;
		const handle = this.handleElRef.nativeElement;
		const documentEl = handle.ownerDocument;
		const mousemove = (event: MouseEvent) => {
			wrapper.style.height = wrapper.getBoundingClientRect().height + event.movementY + "px";
		};
		const mouseup = () => {
			documentEl.removeEventListener("mousemove", mousemove);
			documentEl.removeEventListener("mousemove", mouseup);
			this.grid.calculateLayout();
		};

		handle.addEventListener("mousedown", () => {
			documentEl.addEventListener("mousemove", mousemove);
			documentEl.addEventListener("mouseup", mouseup);
		});
	}

	search() {
		const query: IQueryNode = {};
		this.dataSource
			.setQueryNode(query)
			.fetchRows()
			.subscribe();
	}

	refreshNested(rowPath: string) {
		const ds = this.dataSource.nestedStrategy.nestedDataSourceMap$.value.get(rowPath);
		if (ds) {
			ds.fetchRows().subscribe();
		}
	}

	ngOnDestroy(): void {
		// Called once, before the instance is destroyed.
		// Add 'implements OnDestroy' to the class.
	}
}
