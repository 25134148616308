<div class="modal-header mb-3">
	<h2 class="modal-title">Create New User</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<aw-wizard [awNavigationMode] class="rl-wizard" navBarLayout="small">
	<aw-wizard-step [stepTitle]="'Create Username'">
		<div class="modal-body">
			<form>
				<div class="mb-2 row align-items-center">
					<label class="col-sm-3 col-form-label text-end text-large">
						Add username (email address):
					</label>
					<div class="col-sm-3">
						<input type="text" class="form-control" placeholder="Email" [formControl]="usernameControl">
					</div>
					<div class="col-sm-3">
						<button class="btn btn-light"
							[disabled]="!usernameIsValid || !usernameIsDirty || usernameConfirmed"
							(click)="checkAvailability()">
							<i *ngIf="checkingAvailability" class="far fa-spinner fa-spin me-1"></i>
							<i *ngIf="!checkingAvailability && usernameConfirmed"
								class="far fa-check-circle me-1 text-success"></i>
							Check Availability
						</button>
					</div>
				</div>
				<div class="mb-2 row"
					*ngIf="usernameAvailability || (usernameControl.errors && usernameControl.errors.rightslineInvalidEmail)">
					<div class="col-sm-3"></div>
					<div class="col-sm-9">
						<span class="text-danger"
							*ngIf="usernameControl.errors && usernameControl.errors.rightslineInvalidEmail">
							{{errorMessages['rightslineInvalidEmail']}}
						</span>
						<ng-container [ngSwitch]="usernameAvailability">
							<ng-container *ngSwitchCase="'username_available'">
								<p class="text-success">
									This username is available.
								</p>
							</ng-container>
							<ng-container *ngSwitchCase="'username_unavailable'">
								<p class="text-danger">
									This username is already in use by an existing Contact with Rightsline access.<br>
									Please enter a new username.
								</p>
							</ng-container>
							<ng-container *ngSwitchCase="'username_already_in_use_by_contact'">
								<p class="text-danger">
									This username is already in use by an existing Contact who does not have Rightsline
									access.
								</p>
								<p>
									Click 'Next' to make a new Contact with Rightsline access, or click 'Give
									Rightsline Access' to give access to the existing Contact.
								</p>
							</ng-container>
							<ng-container *ngSwitchCase="'username_already_in_use_by_multiple'">
								<p class="text-danger">
									This username is already in use by multiple Contacts who do not have Rightsline
									access.
								</p>
								<p>
									Click 'Next' to make a new Contact with Rightsline access, or click 'Give
									Rightsline Access' to give access to an existing Contact.
								</p>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light ms-5" (click)="close()">Cancel</button>
			<button *ngIf="showGiveExistingContactAccessButton" class="btn btn-success ms-3"
				[disabled]="!usernameIsValid || checkingAvailability || !usernameConfirmed" (click)="giveAccess()">
				Give Rightsline Access
			</button>
			<button class="btn btn-info ms-3" type="button"
				[disabled]="!usernameIsValid || checkingAvailability || !usernameConfirmed" awNextStep>
				Next <i class="far fa-chevron-right ms-2"></i>
			</button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Add Details'">
		<div class="container-fluid bg-light p-3 mt-3">
			<div class="row">
				<div class="col">
					<form>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								Username (email address):
							</label>
							<div class="col-sm-8 col-form-label">
								<b>{{username}}</b>
							</div>
						</div>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								<span class="me-1 text-danger">*</span>
								Select Template:
							</label>
							<div class="col-sm-8 col-form-label">
								<div *ngIf="templates.length > 1">
									<select id="templateSelector" class="form-control" [formControl]="templateIdControl"
										(change)="setSelectedTemplate()" data-cy="TemplateSelector">
										<option *ngFor="let t of templates" [value]="t.templateID"
											[selected]="selectedTemplate.templateID == t.templateID"
											[attr.data-cy]="t.templateName">
											{{t.templateName}}
										</option>
									</select>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="col">
					<form>
						<div class="row">
							<label class="col-sm-4 col-form-label text-end">
								<span class="me-1" [class.text-danger]="!appUrlIdsControl.valid">*</span>
								Grant Access to:
							</label>
							<div class="col-sm-8 col-form-label ps-0">
								<rl-select-apps (onChange)="selectAppUrlIds($event)"></rl-select-apps>
							</div>
						</div>
						<div class="mb-2 row">
							<label class="col-sm-4 col-form-label text-end">
								Disable Event Messages:
							</label>
							<div class="col-sm-8 col-form-label form-check">
								<label class="form-check-label">
									<input type="checkbox" class="me-2 form-check-input" id="audit-disable"
										(change)="selectDisableAuditMessages($event)"
										data-cy="DisableMessagesCheckbox" />
									SNS/SQS/EventBridge messages will not be generated for this user's activity
								</label>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal-body">
			<rl-show-only-dropdown></rl-show-only-dropdown>
			<rl-char-data-table [charData]="charData" [template]="selectedTemplate" [editMode]="true" (tableCharDataChange)="charData=$event.charData"
				#charDataTable></rl-char-data-table>
		</div>
		<div class="modal-footer">
			<button class="btn btn-info" awPreviousStep>
				<i class="far fa-chevron-left me-2"></i>Back
			</button>
			<button type="button" class="btn btn-light ms-3" (click)="close()">Cancel</button>
			<button class="btn btn-info ms-3" type="button" awNextStep
				[disabled]="!appUrlIdsControl.valid || !charDataTable.valid">
				Next <i class="far fa-chevron-right ms-2"></i>
			</button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Select Roles'">
		<div class="container-fluid bg-light p-3 mt-3">
			<div class="row">
				<div class="col">
					<form>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								Username (email address):
							</label>
							<div class="col-sm-8 col-form-label">
								<strong>{{username}}</strong>
							</div>
						</div>
						<div class="row mb-0">
							<label class="col-sm-4 col-form-label text-end">
								Template:
							</label>
							<div class="col-sm-8 col-form-label">
								{{ selectedTemplate.template.templateName}}
							</div>
						</div>
					</form>
				</div>
				<div class="col">
					<form>
						<div class="mb-2 row">
							<label class="col-sm-4 col-form-label text-end">
								Has Access to:
							</label>
							<div class="col-sm-8 col-form-label">
								<div *ngFor="let app of selectedUserApps">{{app.label}}</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal-body">
			<div class="form">
				<div [class.text-danger]="!identityRolesControl.pristine && !identityRolesControl.valid">
					<h4><span class="me-1 text-danger">*</span>Select at least one Identity Role:</h4>
				</div>
				<rl-select-identity-roles (onChange)="updateIdentityRoles($event)"></rl-select-identity-roles>
				<div class="mt-5" [class.text-danger]="!wfRolesControl.pristine && !wfRolesControl.valid">
					<h4><span class="me-1 text-danger">*</span>Select at least one Workflow Role:</h4>
				</div>
				<rl-select-wf-roles (onChange)="updateWfRoles($event)"></rl-select-wf-roles>
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-info" awPreviousStep>
				<i class="far fa-chevron-left me-2"></i>Back
			</button>
			<button type="button" class="btn btn-light ms-3" (click)="close()">Cancel</button>
			<button class="btn btn-success ms-3" (click)="inviteUser()" type="button"
				[disabled]="isSaving || !formGroup.valid">
				<i *ngIf="isSaving" class="far fa-spinner fa-spin me-1"></i>Save
			</button>
		</div>
	</aw-wizard-step>
</aw-wizard>