<div>
	<rl-entity-search *ngIf="dataSource" #entitySearch [searchOptions]="searchOptions" [dataSource]="dataSource"
				[layoutMaxHeight]="'40vh'">
	</rl-entity-search>
</div>
<div class="border-top my-3 pt-3">
	<h4>Selected {{ fieldDetail._singularLabel }}</h4>
	<div *ngIf="selectedValues.length === 0">
		No {{ fieldDetail._singularLabel }} selected.
	</div>
	<div class="all-chips">
		<rl-chip *ngFor="let row of selectedRows" (onRemove)="remove(row)">
		{{ row.title }}
	</rl-chip>
	</div>
</div>