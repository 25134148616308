import { Component } from "@angular/core";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { ChangePasswordComponent } from "../../../common/components/change-password/change-password.component";


@Component({
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"],
	imports: [ChangePasswordComponent]
})
export class ResetPasswordComponent {
	constructor(
		private readonly _modalService: ModalServiceAbstract
	) { }

	showGdpr() {
		this._modalService.showGdpr();
	}
}

