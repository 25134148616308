<div data-cy="RemoveAssocModalContent">
	<div class="modal-header" data-cy="RemoveAssocModalHeader">
		<ng-container [ngSwitch]="direction">
			<h2 class="modal-title" *ngSwitchCase="0">
				Remove {{ childCharTypeId | pluralCharTypeName}} from {{ parentCharTypeId | charTypeName}}
			</h2>
			<h2 class="modal-title" *ngSwitchCase="1">
				Remove {{ parentCharTypeId | pluralCharTypeName}} from {{ childCharTypeId | charTypeName}}
			</h2>
		</ng-container>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()">
			<i class="far fa-times"></i>
		</button>
	</div>
	<ng-container *ngIf="(isRelatedToInvoices$ | async) as isRelatedToInvoices; else loading">
		<ng-container *ngIf="isRelatedToInvoices.length === 0; else hasAccountingDocsBody">
			<div class="modal-body" data-cy="RemoveAssocModalBody">
				<div>
					<div class="mb-3" *ngIf="this.isDataBridged">
						<h4>This relationship has been synced to Alliant which may potentially be involved in financial calculations and additional
							checks are required to confirm this.</h4>
						<h4>Are you sure you wish to proceed with the removal of this association? An email will be sent to you with the removal
							status once complete.</h4>
					</div>
					<div *ngIf="isRemovingCatalogFromDeal">
						<h4>The items listed below and related Catalog Items will be removed from any Rights,
							Tables, and Amounts on this Deal:</h4>
						{{ titles | listJoin }}
					</div>
					<div *ngIf="!isRemovingCatalogFromDeal">
						<h4>The following items will be removed:</h4>
						{{ titles | listJoin:true }}
					</div>

				</div>
			</div>
			<div class="modal-footer" data-cy="RemoveAssocModalFooter">
				<span class="text-medium me-4"><strong>Are you sure?</strong></span>
				<button type="button" class="btn btn-light" (click)="decline()" data-cy="RemoveAssocModalButtonNo">No</button>
				<button type="button" class="btn btn-info ms-3" (click)="removeAssociations()" data-cy="RemoveAssocModalButtonYes">
					Yes
				</button>
			</div>
		</ng-container>
	</ng-container>

	<ng-template #hasAccountingDocsBody>
		<div class="modal-body">
			Unable to delete {{ childCharTypeId | charTypeName:hasMultiple }} because there are one or more
			associated accounting
			records.
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-info" (click)="decline()">Ok</button>
		</div>
	</ng-template>

	<ng-template #loading>
		<div class="modal-body">
			<rl-loader></rl-loader>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-info" (click)="decline()">Cancel</button>
		</div>
	</ng-template>
</div>