import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { first } from "lodash";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ICreateEntityResponse } from "rl-common/services/entity/entity.messages";
import { EntityService } from "rl-common/services/entity/entity.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Observable, of, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { CharDataTableComponent } from "../char-data";

@Component({
	selector: "rl-create-entity-basic",
	templateUrl: "./create-entity-basic.component.html",
	styleUrls: ["./create-entity-basic.component.scss"],
	imports: [NgIf, ReactiveFormsModule, NgFor, CharDataTableComponent]
})
export class CreateEntityBasicComponent implements OnInit, OnDestroy {

	@Input()
	charTypeId: number;

	@Input()
	availableTemplateIds: number[];

	availableTemplates: ICharacteristicTemplate[];

	@ViewChild("charDataTable")
	charDataTable: CharDataTableComponent;

	@Output()
	entityCreate = new EventEmitter<string>();

	charData: ICharacteristicData[] = [];
	templateMetaData: ICharacteristicMetaDataCollection;

	private readonly _subs: Subscription[] = [];

	templateFC: UntypedFormControl;

	get valid() {
		return this.charDataTable?.valid;
	}

	constructor(
		private readonly _entityService: EntityService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _formBuilder: UntypedFormBuilder
	) {
		this.templateFC = this._formBuilder.control(null);

		const sub = this.templateFC.valueChanges
			.pipe(
				tap((templateId) => {
					this.setupTemplate(this.availableTemplates.find(t => t.templateID === templateId));
				})
			)
			.subscribe();
		this._subs.push(sub);
	}

	ngOnInit(): void {
		this.availableTemplates = this.availableTemplateIds.map(tid => this._oneConfigService.getTemplate(this.charTypeId, tid));
		this.templateFC.setValue(first(this.availableTemplates.map(t => t.templateID)));
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	setupTemplate(template: ICharacteristicTemplate) {
		if (template != null) {
			this.templateMetaData = this._oneConfigService.getTemplateMetaData(this.charTypeId, template.templateID);
			this.charData = [];
		}
	}

	saveEntity$(): Observable<ICreateEntityResponse> {
		if (this.charDataTable.valid) {
			return this._entityService.createEntityDeprecated(this.templateMetaData.charTypeID, this.templateMetaData.templateID, this.charData);
		} else {
			return of(null);
		}
	}

	saveEntity() {
		if (this.charDataTable.valid) {
			const sub = this.saveEntity$()
				.pipe(
					tap(response => this.entityCreate.next(response.entityId))
				)
				.subscribe();
			this._subs.push(sub);
		}
	}

}
