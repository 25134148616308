import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, isEqual } from "lodash";
import { EntitySearchComponent } from "rl-common/components/entities/entity-search/entity-search.component";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { ISelectStateChangeEvent } from "rl-common/components/entities/entity-search/select-state-net-changes";
import { GridSelectData } from "rl-common/components/grid/models/grid-select-data";
import { GridSelectState } from "rl-common/components/grid/models/grid-select-state";
import { SearchOptionsFactory, SearchType } from "rl-common/factories/search-options.factory";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { CharTypeId } from "./../../../../rl-common.consts";
import { InputListComponent, IValidateEvent } from "./input-list/input-list.component";

export interface ISelectedChangeEvent {
	selectedRelationships: IRecordTitle[];
}

export interface IRecordRemoved {
	recordTitle: IRecordTitle;
	wasRemoved: boolean;
}

@Component({
	selector: "rl-entity-relationship",
	templateUrl: "./entity-relationship.component.html",
	styleUrls: ["./entity-relationship.component.scss"],
	imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, EntitySearchComponent, NgIf, InputListComponent, NgbNavOutlet]
})
export class EntityRelationshipComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	charTypeId: number;

	@Input()
	isMultiple = true;

	@Input()
	isRequired = false;

	@Input()
	isTablesOrRights = false;

	@Input()
	includeCurrentAssociations = false;

	_selectedRelationships: IRecordTitle[] = [];

	@Input()
	validTemplates: ICharacteristicTemplate[] = [];

	@Input()
	searchType: SearchType = SearchType.TaggedRelationship;

	@Output()
	onSelectedChange = new EventEmitter<ISelectedChangeEvent>();

	@Output()
	selectedStateChange = new EventEmitter<ISelectStateChangeEvent<unknown, unknown>>();

	@Input()
	relationshipDirection: RelationshipTypes;

	@Input()
	keywords: string;

	@ViewChild(EntitySearchComponent)
	entitySearch: EntitySearchComponent;

	selectData$ = new BehaviorSubject<GridSelectData<IRecordTitle>>({
		selectedValues: []
	});
	searchOptions: SearchOptions = {} as SearchOptions;
	selectedInputList: IRecordTitle[] = [];
	selectedDataFromSearch: IRecordTitle[] = [];

	private readonly _subscriptions: Subscription[] = [];

	private _lastSelectState: GridSelectState<number, IEntitySearchDoc>;

	@Input()
	set selectedRelationships(relationships: IRecordTitle[]) {
		if (relationships) {
			const clone = cloneDeep(relationships);
			clone.forEach(rel => rel.relRecordId = null);
			// The relRecordId is needed for bulk edit, but makes the isEqual() return false because new relationships won't have rel rec ids.
			this._selectedRelationships = clone;
		}
		this.selectData$.next({ selectedValues: this._selectedRelationships });
	}

	get selectedRelationships() {
		return this._selectedRelationships;
	}
	constructor(
		private readonly _parentEntityService: ParentEntityService
	) { }

	ngOnInit() {
		this.buildSearchOptions();
	}

	private buildSearchOptions() {
		// TODO: We need to clean this up
		this.searchOptions = this.getSearchOptions();
		if (this.charTypeId === CharTypeId.Mock) {
			this.searchOptions.charTypeId = this.charTypeId;
		} else if (this._parentEntityService.hasGrandParent()) {
			this.searchOptions.parentCharTypeId = this._parentEntityService.parent.charTypeId;
			this.searchOptions.parentRecordId = this._parentEntityService.parent.recordId;
			this.searchOptions.relatedCharTypeId = this._parentEntityService.charTypeId;
			this.searchOptions.relatedRecordId = this._parentEntityService.recordId;

		} else {
			if (this.relationshipDirection === RelationshipTypes.Parent) {
				this.searchOptions.childRecordId = this._parentEntityService.recordId;
				this.searchOptions.charTypeId = this._parentEntityService.charTypeId;
				this.searchOptions.parentCharTypeId = this.charTypeId;
			} else {
				this.searchOptions.parentCharTypeId = this._parentEntityService.charTypeId;
				this.searchOptions.parentRecordId = this._parentEntityService.recordId;
			}
		}
		this.searchOptions.filteredTemplateIds = this.validTemplates.map(template => template.templateID);
		this.searchOptions.selectType = this.isMultiple ? SelectType.Checkbox : SelectType.Radio;
		this.searchOptions.selectedRecordIds = this.selectedRelationships.map(rel => rel.recordId);
		this.searchOptions.isRequired = this.isRequired;
		if (this.searchOptions.parentCharTypeId === CharTypeId.Relationship && this.searchOptions.charTypeId === CharTypeId.Amount) {
			this.searchOptions.selectType = SelectType.Radio;
		}
	}

	private getSearchOptions() {
		switch (this.searchType) {
			case SearchType.EditRelationship:
				return SearchOptionsFactory.buildEditAvailableAssociationOptions(this._parentEntityService.charTypeId, this.charTypeId);
			case SearchType.NewRelationship: {
				if (this.relationshipDirection === RelationshipTypes.Parent) {
					const opts = SearchOptionsFactory.buildNewAvailableParentAssociationOptions(this.charTypeId, this._parentEntityService.charTypeId);
					opts.isNewAssociation = true;
					return opts;
				} else {
					let opts;
					if (this.includeCurrentAssociations) {
						opts = SearchOptionsFactory.buildNewAvailableChildAssociationOptionsWithCurrentAssociations(this._parentEntityService.charTypeId, this.charTypeId);
					} else {
						opts = SearchOptionsFactory.buildNewAvailableChildAssociationOptions(this._parentEntityService.charTypeId, this.charTypeId);
					}
					opts.isNewAssociation = true;
					return opts;
				}
			}
			case SearchType.TaggedRelationship:
			default:
				return SearchOptionsFactory.buildTaggedRelationshipOptions(this._parentEntityService.charTypeId, this.charTypeId, this.searchOptions.relatedCharTypeId);
		}
	}

	ngOnChanges(changes: ComponentChanges<EntityRelationshipComponent>) {
		if (changes.relationshipDirection &&
			!changes.relationshipDirection.firstChange &&
			changes.relationshipDirection.previousValue !== changes.relationshipDirection.currentValue) {
			this.buildSearchOptions();
		}
		if (changes.selectedRelationships && changes.selectedRelationships.currentValue !== changes.selectedRelationships.previousValue) {
			this.selectData$.next({ selectedValues: this.selectedRelationships });
		}
	}
	public select(event: ISelectStateChangeEvent<number, IEntitySearchDoc>) {
		const selected = event.selected;

		if (!selected.isAllSelected) {
			this.selectedDataFromSearch = (Array.from(selected.selectedValues || [])).map(x => ({ title: x.title, recordId: x.recordID }));
			this.selectedRelationships = this.isMultiple ? this.selectedRelationships = this.selectedRelationships.filter(x => !event.netChanges.deletedIds.has(x.recordId)) : this.selectedDataFromSearch;
			this.updateSelected(this.selectedRelationships);
		} else {
			const isDifferent = !isEqual(this._lastSelectState, selected);
			const isDeselection = this._lastSelectState && isDifferent && this._lastSelectState.deselectedIds.size < selected.deselectedIds.size;
			if (isDeselection) {
				this.selectedDataFromSearch = this.selectedDataFromSearch.filter(x => !selected.deselectedIds.has(x.recordId));
				this.updateSelected();
			} else if (isDifferent) {
				const deselectedIds = new Set([...this._lastSelectState.deselectedIds].filter(x => !selected.deselectedIds.has(x)));
				const recordsOnPage = this.entitySearch.dataSourceWrapper.indexedRowData$.value.filter(x => deselectedIds.has(x.data.recordID));

				if (deselectedIds.size === recordsOnPage.length && deselectedIds.size > 0) {
					this.selectedDataFromSearch.push(...recordsOnPage.map(x => ({ title: x.data.title, recordId: x.data.recordID })));
					this.updateSelected();
				} else {
					const sub = this.entitySearch.getSelectedRecords$().pipe(
						tap(sel => {
							this.selectedDataFromSearch = sel.documents.map(x => ({ title: x.title, recordId: x.recordID }));
							this.updateSelected();
						})
					).subscribe();
					this._subscriptions.push(sub);
				}
			}
		}

		this._lastSelectState = cloneDeep(selected);
		event.selected = selected;
		this.selectedStateChange.emit(event);
	}

	public updateValidated(event: IValidateEvent) {
		this.selectedInputList = event.validRecordsDeprecated;
		this.updateSelected();
	}

	private updateSelected(includeSelections: IRecordTitle[] = []) {
		const selectedRecords = [...this.selectedInputList, ...this.selectedDataFromSearch, ...includeSelections];
		const distinctRecords: IRecordTitle[] = selectedRecords.reduce((selected, next) => {
			const found = selected.find(x => x.recordId === next.recordId);
			if (!found) {
				selected.push(next);
			}
			return selected;
		}, []);
		this.onSelectedChange.emit({
			selectedRelationships: distinctRecords
		});
	}

	public removeSelection(recordTitle: IRecordTitle) {
		// We can't pull the entity search doc from the indexed row data due to page changes or filtering
		// TODO: We need a better way to go from a selected entity to the original grid row data type
		this.entitySearch.dataSourceWrapper.dataSelectStrategy.deselectRowById(recordTitle.recordId, recordTitle.title);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
