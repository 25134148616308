<div class="panel mx-3 mb-3">
	<div class="panel__title">
		<span class="title--overline">Intelligence Center</span>
		<h3>Intelligent Deal Extraction (beta)</h3>
	</div>
	<section class="getting-started d-flex align-items-stretch mx-3 my-4">
		<div class="getting-started-guide text-center mt-4 w-75">
			<h3 class="getting-started-guide-title border-bottom pb-2">Here's How It Works</h3>
			<div class="d-flex justify-content-between align-items-start pt-5">
				<div class="col-3 guide-adjust-top">
					<img src="/assets/img/intelligent-extract-step1.png" border="0" alt="Upload a Deal Document" />
					<h5 class="getting-started-guide-copy mt-3">Upload a Deal Document</h5>
					<p>Upload a document that contains the data needed to populate your new Deal Record.</p>
				</div>
				<div class="col-3">
					<img src="/assets/img/intelligent-extract-step2.png" border="0"
						alt="Rightsline Intelligent Extraction will analyze it" />
					<h5 class="getting-started-guide-copy mt-3">Rightsline Intelligent Extraction will analyze it</h5>
					<p>Rightsline Intelligent Extraction will use AI to analyze the uploaded document and parse the
						information into the configured data model.</p>
				</div>
				<div class="col-3">
					<img src="/assets/img/intelligent-extract-step3.png" border="0" alt="View your new Deal Record" />
					<h5 class="getting-started-guide-copy mt-3">View your new Deal Record</h5>
					<p>Once the analysis is complete, you will receive an email from Rightsline with a textual summary
						of the document and a link to the new Deal record.</p>
				</div>
			</div>
		</div>
		<div class="getting-started-select w-25 d-flex flex-column justify-content-center ms-5"
			[class.success]="isUploaded">
			@if(!isUploaded) {
			<h5 class="getting-started-select-title mb-0">Get Started</h5>
			<h6 class="getting-started-select-copy mb-3">Create a Deal using<br>Intelligent Extraction</h6>
			<select class="form-select" [(ngModel)]="templateId">
				<option value="null">Select Deal Template</option>
				<option *ngFor="let template of templates" [ngValue]="template.templateID"
					[attr.data-cy]="template.templateID">
					{{template.templateName}}
				</option>
			</select>
			<br>
			<button class="btn btn-success" (click)="upload()" data-cy="UploadExtractPdf"
				[disabled]="isUploading || !templateId || templateId === null">Upload Document</button>
			} @else {
			<div class="success-icon mb-4"><i class="fas fa-check-circle"></i></div>
			<h5 class="success-title">Success!</h5>
			<h6 class="getting-started-select-copy mb-3">Check your email for the extraction summary and link to your
				new Deal.</h6>
			<button class="btn btn-link px-0 text-start" (click)="isUploaded = !isUploaded">Upload Another
				Document</button>
			}
		</div>
	</section>
</div>