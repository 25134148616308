import { ChangeDetectionStrategy, Component, computed, DestroyRef, input, OnInit, signal, Signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavLinkBase, NgbNavLinkButton, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, isEmpty, values } from "lodash";
import { ReportService } from "reporting/services/report.service";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { IGridDataSource } from "rl-common/components/grid/datasource/grid-datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridRowStyles } from "rl-common/components/grid/models/grid-row-styles";
import { IRowVisualization } from "rl-common/components/grid/models/i-row-visualization";
import { CharTypeId } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ConflictsService } from "rl-common/services/conflicts/conflicts.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { CustomViewsComponent } from "../../../custom-views/custom-views.component";
import { GridTableComponent } from "../../../grid/grid-table/grid-table.component";
import { ConflictCheckIssueNestedDataComponent } from "../conflict-check-issue-nested-data/conflict-check-issue-nested-data.component";
import { ConflictCheckType, IConflictCheckHistory, IConflictListTuple } from "../validation-errors-modal.models";


@Component({
    selector: "rl-conflicts-subgrid",
    templateUrl: "./conflicts-subgrid.component.html",
    styleUrl: "./conflicts-subgrid.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgbNav, NgbNavItem, NgbNavLinkButton, NgbNavLinkBase, NgbNavContent, ConflictCheckIssueNestedDataComponent, CustomViewsComponent, GridTableComponent, NgbNavOutlet]
})
export class ConflictsSubgridComponent implements OnInit {

	rowData = input<IConflictListTuple>();
	rowPath = input<string>();
	allowActions = input<string>();
	refresh$ = input<Observable<unknown>>();

	dataSource: Signal<IGridDataSource<unknown>>;
	hasHistory: Signal<boolean>;
	hasActions: Signal<boolean>;
	activeTab: Signal<"action" | "history">;
	activeTabSet = signal<"action" | "history">("history");

	rightsRowVisualizationFn = (rowData: IEntitySearchDoc) => {
		const template = this._oneConfigService.getTemplate(rowData.charTypeID, rowData.templateID);
		if (template && !isEmpty(template.visualization)) {
			return { label: template.templateName, className: "", bgColor: template.visualization } as IRowVisualization;
		}
		return null;
	};

	constructor(
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _destroyRef: DestroyRef,
		private readonly _oneConfigService: OneConfigService,
		private readonly _reportService: ReportService,
		private readonly _conflictsService: ConflictsService) {

		this.hasActions = computed(() => {
			return this.allowActions() && this.rowData()?.[0].conflictTypeId == ConflictCheckType.NoClearance.key
		});

		this.hasHistory = computed(() => {
			return !!this.rowData()?.[0].historyCreatedTimeStamp
		});

		this.activeTab = computed(() => {
			let activeTab = this.activeTabSet();
			if (activeTab == "history" && !this.hasHistory()) {
				activeTab = "action";
			} else if (activeTab == "action" && !this.hasActions()) {
				activeTab = "history";
			}
			return activeTab;
		})

		this.dataSource = computed(() => {
			const rowData = this.rowData();
			const rowPath = this.rowPath();
			if (rowPath && rowData) {
				if (this.activeTab() == "history" && this.hasHistory()) {
					const ds = this.buildRightsHistoryDS(rowPath, rowData);
					// kick off first fetch
					ds.fetchRows().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
					return ds;
				} else if (this.activeTab() == "action" && this.hasActions()) {
					const ds = this.buildRightsActionDS(rowPath, rowData);
					// kick off first fetch
					ds.fetchRows().pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
					return ds;
				}
			}
			return null;
		});

	}
	ngOnInit(): void {
		if (this.refresh$()) {
			this.refresh$()
				.pipe(
					switchMap(() => {
						if (this.dataSource()) {
							return this.dataSource().fetchRows();
						} else {
							return of(null);
						}
					}),
					takeUntilDestroyed(this._destroyRef)
				)
				.subscribe();
		}
	}

	buildRightsHistoryDS(rowPath: string, rowData: IConflictListTuple) {

		return this._gridDataSourceBuilder.commonGridDataSource<IConflictCheckHistory>(row => row.id)
			.setPaging({ rowOffset: 0, pageSize: 10 })
			.withProgress(true)
			.withFetchFn((ds2) => {
				return this._conflictsService.conflictCheckHistory(rowData[0].id).pipe(
					map((history: IConflictCheckHistory[]) => {
						return {
							rowCount: 1,
							rowData: history
						} as IGridFetchResults<IConflictCheckHistory>;
					}),
				);
			});
	}

	buildRightsActionDS(rowPath: string, rowData: IConflictListTuple) {
		const nestedDataSelectStrategy = this._gridDataSourceBuilder.dataSelectStrategies.searchDocDataSelectStrategy<number, IEntitySearchDoc>(doc => doc.recordID, doc => doc);

		const columnStrategy = this._gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(CharTypeId.Right);

		return this._gridDataSourceBuilder
			.nestedSearchGridDataSource<IConflictListTuple, IEntitySearchDoc>((rowData2) => rowData2.recordID, rowData)
			.withDataSelectStrategy(nestedDataSelectStrategy)
			.withColumnStrategy(columnStrategy)
			.withGetRowVisualizationFn(this.rightsRowVisualizationFn)
			.withGetRowStylesFn((row) => {
				if (row && row.activeIndicator === 0) {
					return of([GridRowStyles.Invalid]);
				}
				return of([]);
			})
			.withFetchFn((ds) => {
				const t = rowData[0];
				const gv = columnStrategy.gridView$.value;

				return this._reportService.getConflictActionsRights(rowData[0].catalogId, gv.columns, t.startDate,
					t.endDate,
					t.mediaIds,
					t.territoryIds,
					t.languageIds,
					t.channelIds,
					ds.sortKey$.value as string,
					ds.sortDir$.value,
					values(cloneDeep(ds.columnFilters$.value)))
					.pipe(
						tap(res => {
							ds.setFacetResults(res.facetFields);
						}),
						map(result => ({ rowData: result.documents, rowCount: result.documents.length, extraGridColumnResults: result.extraGridColumnResults }))
					);
			});
	}
}
