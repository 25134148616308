import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IDUtil } from "rl-common/utils";
import { JSONUtil } from "rl-common/utils/json.util";
import { Subscription, timer } from "rxjs";
import { concatMap, tap } from "rxjs/operators";
import { NewTabInModalDirective } from "../../../../directives/new-tab-in-modal.directive";
import { JobService } from "./../../../../services/job.service";
import { IJobState } from "./../../../../services/job.service.models";
import { IJobOptions, IJobProgressResult } from "./job-progress.component.models";


@Component({
	selector: "rl-job-progress",
	templateUrl: "./job-progress.component.html",
	styleUrls: ["./job-progress.component.scss"],
	imports: [NgIf, NgFor, NewTabInModalDirective, RouterLink]
})
export class JobProgressComponent implements OnInit, OnDestroy {

	@Input()
	jobId: string;

	@Input()
	jobOptions: IJobOptions;

	@Input()
	hasNextView = false;

	@Input()
	allowCloseBeforeComplete = false;

	@Input()
	isWithinWizard = false;

	@Input()
	warningMessage: string[];

	@Input()
	hideModalHeading: false;

	@Input()
	titleOverride: string;

	@Output()
	onComplete = new EventEmitter<IJobProgressResult>();

	@Output()
	onNext = new EventEmitter<void>();

	resultMessage: string;
	progressMessage: string;
	status: string;
	total: number;
	completed: number;
	failed: number;
	completedPercent: number;
	failedPercent: number;
	isComplete: boolean;
	hasFailure: boolean;
	showResultMessage = false;
	showProgressMessage = false;
	showCloseIcon = false;
	showRouterLink = false;

	completedStatuses: string[] = ["Done", "Failed", "Cancelled"];

	private pollingSubscription: Subscription;

	get autoClose() {
		return !this.jobOptions?.isManualClose && !this.hasFailure;
	}

	constructor(private _jobService: JobService) { }

	ngOnInit() {
		this.progressMessage = this.titleOverride;
		this.showProgressMessage = this.progressMessage && this.progressMessage !== "" && !IDUtil.isEntityID(this.progressMessage);
		const fetchUpdate$ = this._jobService.getJobState(this.jobId);
		this.showCloseIcon = !this.isWithinWizard && this.isComplete && this.jobOptions?.isManualClose || this.allowCloseBeforeComplete && !this.isWithinWizard;

		this.pollingSubscription = timer(0, 2000)
			.pipe(
				concatMap(() => fetchUpdate$),
				tap(jobState => {
					this.updateJobState(jobState)
				})
			).subscribe();
		this.showRouterLink = this.jobOptions?.routerLink?.length > 0;
	}

	private updateJobState(jobState: IJobState) {
		this.resultMessage = jobState.resultMessage;
		this.progressMessage = this.titleOverride || jobState.progressMessage;
		this.status = jobState.statusDescription;
		this.total = jobState.total;
		this.completed = jobState.completed;
		this.failed = jobState.failed;
		this.showResultMessage = !(IDUtil.isEntityID(jobState.resultMessage) || JSONUtil.IsJson(jobState.resultMessage));
		this.showProgressMessage = !IDUtil.isEntityID(jobState.progressMessage);
		this.completedPercent = Math.floor((this.completed / this.total) * 100);
		this.failedPercent = Math.floor((this.failed / this.total) * 100);
		this.hasFailure = this.failed > 0 || this.status === "Failed";

		const completedStatus = this.completedStatuses.find(x => x === jobState.status);
		if (completedStatus) {
			this.stopPolling();
			this.isComplete = true;
		}
	}

	ngOnDestroy() {
		this.stopPolling();
	}

	private stopPolling() {
		if (this.pollingSubscription && !this.pollingSubscription.closed) {
			this.pollingSubscription.unsubscribe();
		}

		if (this.autoClose) {
			this.close();
		}
	}

	close() {
		if (this.hasNextView) {
			this.onNext.emit();
		} else {
			const result: IJobProgressResult = {
				jobCompleted: this.status === "Done",
				jobCanceled: false,
				total: this.total,
				completedCount: this.completed,
				failedCount: this.failed,
				resultMessage: this.resultMessage
			};
			this.complete(result);
		}
	}

	cancel() {
		this._jobService.cancelJob(this.jobId).subscribe(() => {
			const result: IJobProgressResult = {
				jobCompleted: false,
				jobCanceled: true,
				total: this.total,
				completedCount: this.completed,
				failedCount: this.failed,
				resultMessage: this.resultMessage
			};
			this.complete(result);
		});
	}

	private complete(result: IJobProgressResult) {
		setTimeout(() => {
			this.onComplete.emit(result);
		}, 1000);
	}
}
