<div class="modal-header">
	<h2 class="modal-title">Find {{ fieldDetail._singularLabel }}</h2>
	<button class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	@if(isLoading) {
	<div class="p-3 text-center">
		<rl-loader></rl-loader>
	</div>
	} @else {
	<rl-find-rl-entities [fieldDetail]="fieldDetail" [selectedValues]="selected" [mappedTemplates]="mappedTemplates" (onSelect)="setSelected($event)">
	</rl-find-rl-entities>
	}
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="close()">Cancel</button>
	<button class="btn btn-info ms-3" [disabled]="isSubmitting" (click)="done()">
		<i *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i> Done
	</button>
</div>