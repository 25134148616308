<div class="row align-items-center p-1">
	<div class="col-2">{{template.level}}
	</div>
	<div class="col-9">
		<span class="indent" [style.width]="((template.level - 1) * 30) + 'px'"></span>
		<i *ngIf="template.childTemplates.length > 0"
			class="arrow-btn fa fa-caret-right cursor-pointer toggle-chevron p-1 me-1"
			[title]="template.numberOfRecords > 0 ? '1' : '0'" (click)="toggleHierarchy(template)"
			[class.open]="template.numberOfRecords > 0">
		</i>
		<input *ngIf="parentTemplateId !== template.templateID" type="radio" class="form-check-input"
			name="radioSelection" [value]="template.templateID" (change)="updateValue(template.templateID)"
			[ngModel]="selectedSuballocationId" />
		<span [class.level-1]="template.level === 1" [class.level-2]="template.level === 2">
			{{template.templateLabel}}
		</span>
	</div>
</div>
<ng-container *ngIf="template.numberOfRecords > 0">
	<rl-catalog-hierarchy-allocation *ngFor="let child of template.childTemplates" [charTypeId]="charTypeId"
		[template]="child" (onChange)="updateValue($event)">
	</rl-catalog-hierarchy-allocation>
</ng-container>