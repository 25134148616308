import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FlexDashboardComponent } from "./dashboard/flex-dashboard.component";

@Component({
    selector: "rl-app-home",
    templateUrl: "./home.component.html",
    imports: [FlexDashboardComponent]
})
export class HomeComponent implements OnInit {
	constructor(private browserTitle: Title) { }

	ngOnInit() {
		this.browserTitle.setTitle("Rightsline");
	}
}
