import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { cloneDeep, isEqual } from "lodash";
import { ElementsUtil } from "rl-common/components/char-data/elements/elements.util";
import { CharTypeId } from "rl-common/consts";
import { CharDataModifyAction } from "rl-common/models/char-data-modify-action";
import { DateMathChanges } from "rl-common/models/date-math-changes";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IEntity } from "rl-common/models/i-entity";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IEntityRelationshipState } from "rl-common/services/entity/entity-relationship.models";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ISaveCharDataRequest } from "rl-common/services/entity/models/i-save-char-data-request";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/models/i-grid-view-assoc-entity-rec";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModalBuilder } from "rl-common/services/modal-builder/modal-builder";
import { HttpStatusCode } from "rl-common/services/rl-http.models";
import { SessionService } from "rl-common/services/session.service";
import { CharacteristicUtil } from "rl-common/utils/characteristic.util";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { ComponentRelationshipsComponent } from "../../associations/entity-relationships/component-relationships/component-relationships.component";
import { CharDataTableComponent } from "../../char-data/char-data-table.component";
import { SelectState } from "./../../../services/entity/entity.messages";
import { OneConfigService } from "./../../../services/one-config/one-config.service";
import { IDUtil } from "./../../../utils/id.util";
import { EditWarningModalComponent } from "./edit-warning-modal/edit-warning-modal.component";
import { EditEntityCompleteEvent } from "./models/edit-entity-complete-event";

export interface CharTypeRelChange {
	charTypeId: CharTypeId;
	changeWasMade: boolean;
	wasAdded: boolean;
	wasDeleted: boolean;
}

@Component({
	selector: "rl-edit-entity",
	templateUrl: "./edit-entity.component.html",
	styleUrls: ["./edit-entity.component.scss"],
	imports: [NgIf, ComponentRelationshipsComponent, CharDataTableComponent]
})
export class EditEntityComponent implements OnInit, OnDestroy {
	@Input()
	editingEntityId: string;

	@Input()
	baseEntityId?: string;

	@Input()
	parentEntityId?: string;

	@Input()
	isRelative = false;

	@Output()
	onComplete = new EventEmitter<EditEntityCompleteEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	relationships: { [charTypeId: number]: IRecordTitle[] } = {};
	entity: IEntity;
	template: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];
	initialCharData: ICharacteristicData[];

	isSaving = false;
	charTypeId: number;
	recordId: number;
	editingRelRecId: number;
	parentRelRecId: number;
	isMultiple = true;
	requiredCharTypeDict: { [charTypeId: number]: boolean } = {};
	selectedStateDictionary: { [charTypeId: number]: IEntityRelationshipState<number, IGridViewAssocEntityRec> } = {};
	defaultSelectStateDict: { [charTypeId: number]: SelectState<number, IGridViewAssocEntityRec> } = {};
	subs: Subscription[] = [];

	private readonly _tablesAndRights = [CharTypeId.Usage, CharTypeId.Right];

	get isTablesOrRights() {
		return this._tablesAndRights.includes(this.charTypeId);
	}

	constructor(
		private readonly _parentEntityService: ParentEntityService,
		private readonly _entityService: EntityService,
		private readonly _sessionService: SessionService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _modalBuilder: ModalBuilder,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit() {
		const editingEntityId = IDUtil.splitEntityID(this.editingEntityId);
		this.recordId = editingEntityId.recID;
		this.charTypeId = editingEntityId.charTypeID;
		this.isMultiple = this.charTypeId !== CharTypeId.Amount;

		const getEditEntityMetaData$ = this._entityService.getEditEntityMetaData(this.editingEntityId, this.baseEntityId, this.parentEntityId);
		const getCharData$ = this._entityService.getCharData(this.editingEntityId);

		const sub = combineLatest([getEditEntityMetaData$, getCharData$])
			.pipe(
		).subscribe(([entityData, cd]) => {
			this.charData = cd;
			this.entity = entityData.entityData.entity;
			const tmd = this._oneConfigService.getTemplateMetaData(this.charTypeId, entityData.entityData.entity.templateID);
			this.template = tmd;
			this.initialCharData = cloneDeep(entityData.entityData.characteristicData);
			this.relationships = entityData.assocEntities;
			this.editingRelRecId = entityData.editingRelRecId;
			this.parentRelRecId = entityData.parentRelRecId;
			this.requiredCharTypeDict = entityData.requiredCharTypeDict;
			this.defaultSelectStateDict = entityData.defaultSelectStateDict;
			this._parentEntityService.initEditParent(this.editingEntityId, this.parentEntityId, this.baseEntityId, this.parentRelRecId, this.editingRelRecId, this.template?.templateID);
		});

		this.subs.push(sub);
	}

	save() {
		this.isSaving = true;

		const entityId = this.editingEntityId;
		const charData = cloneDeep(this.charData);
		const editedCharDataRequest = this.getEditedCharData(this.initialCharData, charData, this.template.characteristicMetaDatas);

		const relChangesWereMade = this.relChangesWereMade();
		const charDataChangesWereMade = this.charDataChangesWereMade(editedCharDataRequest);
		const changesWereMade = relChangesWereMade || charDataChangesWereMade;
		if (!changesWereMade) {
			this.onComplete.emit({
				success: true,
				entityId: this.parentEntityId,
				relWasAdded: false,
				relWasDeleted: false,
				charDataChangesWereMade: false,
				anyChangesWereMade: false,
				relChangesWereMade: false
			});
			return;
		}
		const parentEntityId = this._parentEntityService.parent ?
			IDUtil.toID(this._sessionService.divId, this._parentEntityService.parent.charTypeId, this._parentEntityService.parent.recordId) :
			null;
		if (this.relChangesWereMade()) {
			// TODO: refactor this so that we don't have modals opening modals
			const confirmationSub = this._modalBuilder.build<EditWarningModalComponent>(EditWarningModalComponent)
				.open(comp => {
					comp.selectedStateDictionary = this.selectedStateDictionary;
					comp.charTypeId = this.charTypeId;
					return comp.onConfirm;
				}).pipe(
					switchMap(performSave => {
						if (performSave) {
							return this.saveContinue$(entityId, editedCharDataRequest, parentEntityId);
						} else {
							this.isSaving = false;
							return of();
						}
					}),
					catchError((err) => {
						this.isSaving = false;
						throw err;
					})
				)
				.subscribe();
			this.subs.push(confirmationSub);
		} else {
			const saveSub = this.saveContinue$(entityId, editedCharDataRequest, parentEntityId).subscribe();
			this.subs.push(saveSub);
		}
	}

	private getEmitEvent(): EditEntityCompleteEvent {
		const charData = cloneDeep(this.charData);
		const editedCharDataRequest = this.getEditedCharData(this.initialCharData, charData, this.template.characteristicMetaDatas);
		const relChanges = this.getRelChanges();
		const charDataChangesWereMade = this.charDataChangesWereMade(editedCharDataRequest);
		const relChangesWereMade = relChanges.some(c => c.changeWasMade);
		const changesWereMade = relChangesWereMade || charDataChangesWereMade;
		return {
			success: true,
			entityId: this.parentEntityId,
			relWasAdded: relChanges.some(c => c.wasAdded),
			relWasDeleted: relChanges.some(c => c.wasDeleted),
			charDataChangesWereMade,
			relChangesWereMade,
			anyChangesWereMade: changesWereMade,
		};
	}

	private saveContinue$(entityId: string, editedCharDataRequest: ISaveCharDataRequest,
		parentEntityId: string = null) {
		const split = IDUtil.splitEntityID(entityId);
		const emitEvent = this.getEmitEvent();

		return this.allowAmountToSaveContinue(split.charTypeID, split.recID, editedCharDataRequest)
			.pipe(
				switchMap(allowSave => {
					if (!allowSave) {
						this.isSaving = false;
					}
					return of(allowSave);
				}),
				filter(allowSaveToCont => allowSaveToCont),
				switchMap(() => this._entityService.saveEntity(split.charTypeID, split.recID, editedCharDataRequest, this.selectedStateDictionary, parentEntityId)
					.pipe(
						catchError(e => {
							if (e.status === HttpStatusCode.Conflict) {
								this._growlerService.error().growl(e?.error?.message);
								return of(null);
							}
							throw e;
						}),
						tap((result) => {
							this.onComplete.emit(emitEvent);
							this.isSaving = false;
						})
					))
			);
	}

	private allowAmountToSaveContinue(charTypeId: number, recordId: number, editedCharDataRequest: ISaveCharDataRequest): Observable<boolean> {
		if (charTypeId === CharTypeId.Amount) {
			const amountCurrencySymbol = CharacteristicUtil.getLocalCurrency(charTypeId, this.template.characteristicMetaDatas, editedCharDataRequest.charDatas);
			if (amountCurrencySymbol) {
				const json = `[{"recID":${recordId},"curSym": "${amountCurrencySymbol}"}]`;
				return this._entityService.checkAmountInvoiceCurrencyMisMatch(charTypeId, json)
					.pipe(
						map(result => {
							if (result && result.length > 0 && amountCurrencySymbol !== result[0].invoiceCurSym) {
								this._growlerService.error("Error").growl(`Amount's selected Currency ${amountCurrencySymbol} does not match with associated Invoice Currency ${result[0].invoiceCurSym}`);
								return false;
							}
							return true;
						})
					);
			}
		}
		return of(true);
	}

	changesWereMade(editedCharDataRequest: ISaveCharDataRequest) {
		return this.relChangesWereMade() || this.charDataChangesWereMade(editedCharDataRequest);
	}

	relChangesWereMade() {
		return Object.values(this.selectedStateDictionary)
			.some(state => {
				if (state.netChanges.isAllDeselected) {
					return state.defaultSelectedIds.length > 0 || state.netChanges?.addedIds?.size > 0;
				} else if (!state.netChanges.isAllSelected) {
					return state.netChanges?.addedIds?.size > 0 || state.netChanges?.deletedIds?.size > 0;
				}
				const wasAllSelectedBefore = state.defaultSelectedIds.length === state.gridCount;
				return !wasAllSelectedBefore || state.netChanges.deletedIds.size > 0;
			});
	}

	getRelChanges(): CharTypeRelChange[] {
		return Object.entries(this.selectedStateDictionary)
			.map(kvp => {
				const ct = +kvp[0];
				const state = kvp[1];
				let wasAdded = false;
				let wasDeleted = false;
				if (state.netChanges.isAllDeselected) {
					wasAdded = state.defaultSelectedIds.length > 0;
				} else if (!state.netChanges.isAllSelected) {
					wasAdded = state.netChanges?.addedIds?.size > 0;
					wasDeleted = state.netChanges?.deletedIds?.size > 0;
				} else {
					const wasAllSelectedBefore = state.defaultSelectedIds.length === state.gridCount;
					wasDeleted = !wasAllSelectedBefore || state.netChanges.deletedIds.size > 0;
				}
				return {
					charTypeId: ct,
					changeWasMade: wasAdded || wasDeleted,
					wasAdded,
					wasDeleted
				}
			});
	}

	charDataChangesWereMade(editedCharDataRequest: ISaveCharDataRequest) {
		return editedCharDataRequest.charDatas.length > 0 || editedCharDataRequest.deletedRecCharIds.length > 0 ||
			editedCharDataRequest.deletedDateMathCalcIds.length > 0 || editedCharDataRequest.deletedDateMathRelIds.length > 0;
	}

	cancel() {
		this.onCancel.emit();
	}

	updateRelationshipStates(states: { [charTypeId: number]: IEntityRelationshipState<number, IGridViewAssocEntityRec> }) {
		this.selectedStateDictionary = states;
	}

	get areRelsValid() {
		const invalidRequired = Object.keys(this.requiredCharTypeDict)
			.filter(charTypeId => this.selectedStateDictionary[charTypeId]?.isValid === false);
		return invalidRequired.length === 0;
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	// TODO: Do we still need this method?
	// Look at makeCharDataBulkEditPackage() in entity-chardata-data-change.strategy.ts, at some point we should share this logic.
	getEditedCharData(allInitialCharData: ICharacteristicData[], allNewCharData: ICharacteristicData[], cmds: ICharacteristicMetaData[]): ISaveCharDataRequest {
		allInitialCharData = allInitialCharData ?? [];
		allNewCharData = allNewCharData ?? [];
		const deletedRecCharIds: number[] = [];
		const editedCharData: ICharacteristicData[] = [];
		const deletedDateMathCalcIds: number[] = [];
		const deletedDateMathRelIds: number[] = [];

		// process column edits and lov merging
		cmds.forEach(cmd => {
			const charId = cmd.characteristicID;
			const newCharData = allNewCharData.filter(x => x.charactersticID === charId);
			const oldCharData = allInitialCharData.filter(x => x.charactersticID === charId);
			const lovMetaData = this._oneConfigService.getLovMetaData(cmd.charValueSourceID);
			let lovs = lovMetaData?.listOfValues;
			if (lovMetaData?.inactiveListOfValues !== undefined) {
				lovs = lovs.concat(lovMetaData?.inactiveListOfValues.map(x => x.cmdv));
			}

			let mergedCharData: ICharacteristicData[] = cloneDeep(oldCharData);
			mergedCharData = CharacteristicUtil.modifyCharData(cmd, mergedCharData, CharDataModifyAction.Replace, newCharData, lovs);

			if (!mergedCharData) {
				return;
			}

			// don't create empty char data when the user didn't even edit the field
			if (oldCharData.length === 0 && newCharData.length === 1 && !newCharData[0].value && !newCharData[0].valueID && !newCharData[0].ext) {
				return;
			}

			// don't update chars if there wasn't any changes
			if (mergedCharData.length === 1 && oldCharData.length === 1 &&
				mergedCharData[0].value === oldCharData[0].value &&
				(mergedCharData[0].valueID || null) === (oldCharData[0].valueID || null) && // (|| null) is to fix instance where null !== undefined.  thank you javascript
				isEqual(mergedCharData[0].ext, oldCharData[0].ext)) {
				return;
			}

			let recCharsToCreate = mergedCharData;
			if (cmd.multipleIndicator <= 0) {
				recCharsToCreate = mergedCharData.filter(x => {
					const isValueUpdated = !oldCharData?.find(y => y.valueID === x.valueID && y.value === x.value);
					const dateMathChanges = CharacteristicUtil.getDateMathChanges(x.ext?.dateMath, [DateMathChanges.Created, DateMathChanges.Updated]);
					const dateMathDeletes = CharacteristicUtil.getDateMathChanges(x.ext?.dateMath, [DateMathChanges.Deleted]);

					return isValueUpdated ||
						dateMathChanges.calcChanges.length > 0 ||
						dateMathChanges.relChanges.length > 0 ||
						dateMathDeletes && x.value; // if we remove datemath calc but there's still a date filled in
				});
			}

			const recCharsToDelete = CharacteristicUtil.getCharsToDelete(mergedCharData, recCharsToCreate, cmd, oldCharData);
			editedCharData.push(...recCharsToCreate);
			deletedRecCharIds.push(...recCharsToDelete.map(x => x.recordCharacteristicID).filter(x => recCharsToCreate.map(y => y.recordCharacteristicID).indexOf(x) === -1));
			const editElementType = ElementsUtil.mapToEditElement(cmd, true, true);
			if (recCharsToDelete && (cmd.multipleIndicator > 0 || editElementType === "dropdown" || editElementType === "money" || editElementType === "checkbox")) {
				recCharsToDelete.forEach(rc => {
					if (recCharsToCreate.filter(x => x.recordCharacteristicID === rc.recordCharacteristicID).length === 0) {
						rc.value = "";
						rc.valueID = undefined;
					}
				});
				editedCharData.push(...recCharsToDelete);
			}
			const dateMathToDelete = mergedCharData.length > 0 ? CharacteristicUtil.getDateMathChanges(mergedCharData[0].ext?.dateMath, [DateMathChanges.Deleted]) : null;
			deletedDateMathCalcIds.push(...(dateMathToDelete ? dateMathToDelete.calcChanges : []));
			deletedDateMathRelIds.push(...(dateMathToDelete ? dateMathToDelete.relChanges : []));
		});

		return {
			charDatas: editedCharData,
			deletedRecCharIds,
			deletedDateMathCalcIds,
			deletedDateMathRelIds
		} as ISaveCharDataRequest;
	}
}
