import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, first, isEmpty } from "lodash";
import { CharTypeId, ConstUtils, SystemIndicators, VirtualCharTagLabels } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ICharacteristicType } from "rl-common/models/i-characteristic-type";
import { IEntity } from "rl-common/models/i-entity";
import { IEntityID } from "rl-common/models/i-entity-id";
import { IEntityRelationship } from "rl-common/models/i-entity-relationship";
import { IEntityStatus } from "rl-common/models/i-entity-status";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { CopyMethod, CopyWarningChecks } from "rl-common/services/copy.models";
import { CopyService } from "rl-common/services/copy.service";
import { EntityService } from "rl-common/services/entity/entity.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { TemplateService } from "rl-common/services/template/template.service";
import { WorkflowService } from "rl-common/services/workflow/workflow.service";
import { IDUtil } from "rl-common/utils";
import { AclUtil } from "rl-common/utils/acl.util";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Observable, of, Subscription } from "rxjs";
import { delay, finalize, map, switchMap, take, tap } from "rxjs/operators";
import { BusyButtonDirective } from "../../directives/busy-button.directive";
import { IAssociationsChangedEvent, TransferAssociationsComponent } from "../copy-entity/transfer-associations/transfer-associations.component";
import { TransferAssociationsService } from "../copy-entity/transfer-associations/transfer-associations.service";
import { JobProgressComponent } from "../modals/job-progress-modal/job-progress/job-progress.component";
import { IJobProgressResult } from "../modals/job-progress-modal/job-progress/job-progress.component.models";
import { LoaderComponent } from "../panel/loader/loader.component";
import { IEntityCopiedEvent } from "./copy-entity-modal.models";

enum DateMathSelectionType {
	Default = 1,
	Original = 2,
	Copied = 3
}

@Component({
	selector: "rl-copy-entity-modal",
	templateUrl: "./copy-entity-modal.component.html",
	styleUrls: ["./copy-entity-modal.component.scss"],
	providers: [TransferAssociationsService],
	imports: [NgIf, ArchwizardModule, ReactiveFormsModule, NgClass, NgbPopover, NgFor, FormsModule, TransferAssociationsComponent, BusyButtonDirective, JobProgressComponent, LoaderComponent]
})
export class CopyEntityModalComponent implements OnInit, OnDestroy {

	private readonly _nonAssociatingCharTypes = [CharTypeId.Usage, CharTypeId.Right, CharTypeId.Royalty, CharTypeId.Amount];

	@Input()
	public entityId: string;
	private _parentId: string;
	parentEntityId: IEntityID;

	@Input()
	public parentTemplateId: number;

	newEntityTitle: string;

	@Output()
	onComplete = new EventEmitter<IEntityCopiedEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	@ViewChild(WizardComponent)
	public wizard: WizardComponent;

	form: UntypedFormGroup;

	canCreateOriginalTemplate = false;
	canDeleteOriginalTemplate = false;
	canWriteOriginalTemplate = false;
	showCopySwitchCharTypeSelector = false;

	copyToCharTypes: ICharacteristicType[] = [];
	switchToCharTypes: ICharacteristicType[] = [];

	copyToTemplates: ICharacteristicTemplate[] = [];
	switchToTemplates: ICharacteristicTemplate[] = [];

	warningChecks: CopyWarningChecks = null;

	entity: IEntity;
	entityStatus: IEntityStatus;
	calcCharData: ICharacteristicData[];

	isLoading = true;
	selectedAssociations: IEntityRelationship[];
	deletedAssociations: IEntityRelationship[];
	associateEntityDirection: RelationshipTypes = null;
	jobId: string;
	wfJobId: string;
	isModuleLevelCharType = true;
	nullCalcFieldValues = false;

	nameLimit: number;
	newEntityId: string;
	dateMathOption = DateMathSelectionType.Default;
	containsDatemath = false;
	continueText = "Continue To Associate";
	datemathLoaded = false;

	@Input()
	set parentId(parentId: string) {
		this._parentId = parentId;
		this.parentEntityId = parentId && IDUtil.isEntityID(parentId) ? IDUtil.splitEntityID(parentId) : null;
	}

	get parentId() {
		return this._parentId;
	}

	get isTableOrAmount() {
		const split = IDUtil.splitEntityID(this.entityId);
		return split.charTypeID === CharTypeId.Usage || split.charTypeID === CharTypeId.Amount;
	}

	get isRightSet() {
		return this.entity && this.entity.charTypeID === CharTypeId.Right;
	}

	get isTable() {
		return this.entity && this.entity.charTypeID === CharTypeId.Usage;
	}

	get allowAssociations() {
		return !this._nonAssociatingCharTypes.includes(this.entity && this.entity.charTypeID);
	}

	get allowDateMath() {
		return !this._nonAssociatingCharTypes.includes(this.entity && this.entity.charTypeID);
	}

	get forceRelCopy() {
		return this.isTableOrAmount || this.isRightSet;
	}

	get showLockedWarning() {
		return this.entityStatus && this.entityStatus.lockIndicator > 0 && this.copyMethod === CopyMethod.SwitchTemplate;
	}

	get invalidAssociations() {
		if (!this.warningChecks) {
			return null;
		}

		let rtn = "";

		Object.keys(this.warningChecks.invalidAssociations).forEach((key) => {
			const charTypeName = CharTypeIdUtil.toDisplayName(+key);

			if (rtn) {
				rtn += "; ";
			}

			rtn += `${charTypeName}: ${this.warningChecks.invalidAssociations[key].join(", ")}`;
		});

		return rtn;
	}

	get showWarnings() {
		return this.warningChecks && (this.warningChecks.lockedAssociations || this.warningChecks.invalidFields || this.warningChecks.invalidParties || this.invalidAssociations || this.showLockedWarning);
	}

	get nameControl() {
		return this.form.get("name");
	}

	get newTitle() {
		return this.nameControl.value;
	}

	get copyMethod() {
		return +this.form.get("copyMethod").value;
	}

	get copyParties() {
		return !!this.form.get("includeParties").value;
	}

	get manualAssociations() {
		return !!this.form.get("manualAssociations").value;
	}

	get newCharTypeId() {
		if (this.copyMethod === CopyMethod.CopyTo) {
			return +this.form.get("copyToCharTypeId").value;
		} else if (this.copyMethod === CopyMethod.SwitchTemplate) {
			return +this.form.get("switchToCharTypeId").value;
		} else {
			return this.entity && this.entity.charTypeID;
		}
	}

	get newTemplateId() {
		if (this.copyMethod === CopyMethod.CopyTo) {
			return +this.form.get("copyToTemplateId").value;
		} else if (this.copyMethod === CopyMethod.SwitchTemplate) {
			return +this.form.get("switchToTemplateId").value;
		} else {
			return this.entity && this.entity.templateID;
		}
	}

	get canCopy() {
		return !this.isTableOrAmount && this.canCreateOriginalTemplate;
	}

	get canSwitch() {
		return !this.isTableOrAmount && this.canWriteOriginalTemplate && this.canDeleteOriginalTemplate && !isEmpty(this.switchToTemplates);
	}

	get showIncludeParties() {
		const split = IDUtil.splitEntityID(this.entityId);
		return split.charTypeID !== CharTypeId.User && !this.forceRelCopy && !this.isTableOrAmount;
	}

	get showSelectAssociations() {
		return !this.forceRelCopy;
	}

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _copyService: CopyService,
		private readonly _sessionService: SessionService,
		private readonly _entityService: EntityService,
		private readonly _transferAssociationsService: TransferAssociationsService,
		private readonly _router: Router,
		private readonly _linkHelper: LinkHelperService,
		private readonly _templateService: TemplateService,
		private readonly _oneConfigService: OneConfigService,
		private readonly _workflowService: WorkflowService
	) { }

	ngOnInit() {
		const sub = this._entityService.checkEntityForDatemath(this.entityId).pipe(
			tap((result) => {
				this.containsDatemath = result;
				this.continueText = result ? "Continue to Datemath" : "Continue to Associate";
				this.datemathLoaded = true;
			}),
			switchMap(() => this._templateService.getTemplateMetaData(this.entityId)),
			tap((result) => {
				const cmd = result.characteristicMetaDatas.find(x => x.tagLabel === VirtualCharTagLabels.Title);
				this.nameLimit = cmd?.maxLength ?? 255;
				this.form = this._formBuilder.group({
					name: new UntypedFormControl("", [Validators.required, Validators.maxLength(this.nameLimit)]),
					copyMethod: new UntypedFormControl(0),
					includeParties: new UntypedFormControl(true),
					manualAssociations: new UntypedFormControl(false),
					copyToCharTypeId: new UntypedFormControl(0),
					copyToTemplateId: new UntypedFormControl(0),
					switchToCharTypeId: new UntypedFormControl(0),
					switchToTemplateId: new UntypedFormControl(0),
				});
				this.loadData();
			}),

		).subscribe();
		this._subs.push(sub);
	}

	setEnabled(dropDown: "copy" | "switch", enabled: boolean) {
		// don't emit event because enabling drop down triggers same event as changing the value
		this.form.get(`${dropDown}ToCharTypeId`)[enabled ? "enable" : "disable"]({ emitEvent: false });
		this.form.get(`${dropDown}ToTemplateId`)[enabled ? "enable" : "disable"]({ emitEvent: false });

		if (enabled || this.copyMethod === CopyMethod.Copy) {
			this.form.get("copyMethod").enable({ emitEvent: false });
		} else {
			this.form.get("copyMethod").disable({ emitEvent: false });
		}
	}

	onCopyMethodChanged(method: number) {
		this.warningChecks = null;
		this.setEnabled("copy", method === CopyMethod.CopyTo);
		this.setEnabled("switch", method === CopyMethod.SwitchTemplate);
		this.form.get("copyMethod").enable({ emitEvent: false });

		if (this.forceRelCopy) {
			const title = this.entity?.title ?? "";
			let entityTitle = "NEW -- " + title;
			if (this.forceRelCopy && method === CopyMethod.CopyTo) {
				entityTitle = "COPY -- " + title;
			} else if (this.forceRelCopy && method === CopyMethod.SwitchTemplate) {
				entityTitle = title;
			}

			const formName = this.form.get("name");
			formName.setValue(entityTitle);
			if (method === CopyMethod.SwitchTemplate) {
				formName.disable({ emitEvent: false });
			} else {
				formName.enable({ emitEvent: false });
			}
		}

		const methodStr = (method === CopyMethod.Copy || method === CopyMethod.CopyTo) ? "copy" : "switch";
		const templateId = this.form.get(`${methodStr}ToTemplateId`).value;
		if (templateId) {
			this.templateIdChanged(methodStr, templateId);
		}
	}

	onCopyToCharTypeIdChanged() {
		const charTypeId = this.newCharTypeId;
		this.copyToTemplates = [];
		this.warningChecks = null;
		this.isLoading = true;
		this.setEnabled("copy", false);
		this.form.get("copyToTemplateId").setValue(0);

		const sub = this.getCopyTemplates(charTypeId).pipe(
			delay(0),
			finalize(() => {
				this.isLoading = false;
				this.setEnabled("copy", this.copyMethod === CopyMethod.CopyTo);
			})
		).subscribe(response => {
			this.copyToTemplates = response.copyToTemplates;
			this.form.get("copyToTemplateId").setValue(this.entity.charTypeID === charTypeId ? this.entity.templateID : this.defaultTemplateId(this.copyToTemplates));
		});
		this._subs.push(sub);
	}

	private defaultTemplateId(templates: ICharacteristicTemplate[]) {
		const template = first(templates);
		return template?.templateID;
	}

	getCopyTemplates(charTypeId: number): Observable<ICopyTemplates> {
		return this._sessionService.acls$.pipe(
			take(1),
			map(acls => {
				let templates: ICharacteristicTemplate[] = [];
				if (this.parentEntityId?.charTypeID === CharTypeId.Relationship) {
					// The wrong parent was likely passed in
					throw Error(`Parent charTypeId: ${this.parentEntityId.charTypeID} is not supported.`);
				}
				if (ConstUtils.isModuleLevelCharType(charTypeId)) {
					templates = this._oneConfigService.getTemplates(charTypeId);
				} else if (this.parentId && this.parentTemplateId) {
					templates = this._oneConfigService.getChildAssocTemplates(this.parentEntityId.charTypeID, this.parentTemplateId, charTypeId);
				}
				templates = templates.filter(t => AclUtil.hasCreateAccess(acls, t.acl)).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
				return {
					switchToTemplates: cloneDeep(templates.filter(x => x.templateID !== this.entity.templateID)),
					copyToTemplates: templates
				};
			})
		);
	}

	onCopyToTemplateIdChanged(templateId: number) {
		this.templateIdChanged("copy", templateId);
	}

	onSwitchToCharTypeIdChanged() {
		const charTypeId = this.newCharTypeId;
		this.switchToTemplates = [];
		this.warningChecks = null;
		this.isLoading = true;
		this.setEnabled("switch", false);
		this.form.get("switchToTemplateId").setValue(0);
		const sub = this.getCopyTemplates(charTypeId).pipe(
			finalize(() => {
				this.isLoading = false;
				this.setEnabled("switch", this.copyMethod === CopyMethod.SwitchTemplate);
			})
		).subscribe(response => {
			this.switchToTemplates = response.switchToTemplates;
			this.form.get("switchToTemplateId").setValue(this.defaultTemplateId(this.switchToTemplates));
		});
		this._subs.push(sub);
	}

	onSwitchToTemplateIdChanged(templateId: number) {
		this.templateIdChanged("switch", templateId);
	}

	templateIdChanged(type: "copy" | "switch", templateId: number) {
		this.warningChecks = null;
		if (!templateId) {
			return;
		}
		this.isLoading = true;
		this.setEnabled(type, false);

		const charTypeId = this.form.get(`${type}ToCharTypeId`).value;
		const method = type === "copy" ? CopyMethod.CopyTo : CopyMethod.SwitchTemplate;
		const sub = this._copyService.getWarningChecks(this.entity.id, charTypeId, templateId, method).subscribe(response => {
			this.warningChecks = response;
			this.isLoading = false;
			this.setEnabled(type, this.copyMethod === method);
		});
		this._subs.push(sub);
	}

	loadData() {
		const charTypesSub = this._copyService.getCharTypes(this.entityId).subscribe(chars => {
			this.copyToCharTypes = chars.copyToCharTypes;
			this.switchToCharTypes = chars.switchToCharTypes;
		});

		const entityStatusSub = this._entityService.getEntityStatus(this.entityId).subscribe(status => {
			this.entityStatus = status;
		});

		const recordSub = this._entityService.getRecord(this.entityId).subscribe(entity => {
			this.entity = entity;
			const split = IDUtil.splitEntityID(entity.id);

			this.showCopySwitchCharTypeSelector = ConstUtils.isModuleLevelCharType(split.charTypeID);
			this.isModuleLevelCharType = ConstUtils.isModuleLevelCharType(split.charTypeID);

			this.form.get("copyToCharTypeId").setValue(this.entity.charTypeID);
			this.form.get("switchToCharTypeId").setValue(this.entity.charTypeID);
			this.onCopyToCharTypeIdChanged();
			this.onSwitchToCharTypeIdChanged();

			const acl = AclUtil.getDataEntityAcl(this.entity.charTypeID, this.entity.templateGroupID, this.entity.templateID);

			this.canCreateOriginalTemplate = AclUtil.hasCreateAccess(this._sessionService.acls, acl);
			this.canWriteOriginalTemplate = AclUtil.hasWriteAccess(this._sessionService.acls, acl);
			this.canDeleteOriginalTemplate = AclUtil.hasDeleteAccess(this._sessionService.acls, acl);

			let entityTitle = `NEW -- ${this.entity.title}`;

			if (this.forceRelCopy && this.copyMethod === CopyMethod.CopyTo) {
				entityTitle = `COPY -- ${this.entity.title}`;
			} else if (this.forceRelCopy && this.copyMethod === CopyMethod.SwitchTemplate) {
				entityTitle = this.entity.title;
			}
			this.newEntityTitle = entityTitle;

			this.form.get("name").setValue(entityTitle);

			let defaultCopyMethod = CopyMethod.Copy;
			if (!this.isTableOrAmount && !this.canCreateOriginalTemplate && this.canWriteOriginalTemplate) {
				defaultCopyMethod = CopyMethod.SwitchTemplate;
			} else if (this.isTableOrAmount || (this.copyMethod === CopyMethod.Copy && !this.canCopy)) {
				defaultCopyMethod = CopyMethod.CopyTo;
				this.form.get("copyToTemplateId").setValue(this.defaultTemplateId(this.copyToTemplates));
			}
			this.form.get("copyMethod").setValue(defaultCopyMethod);
		});

		const charDatasSub = this._entityService.getCharDatas([this.entityId]).subscribe(response => {
			const calcSysInds = [SystemIndicators.UsageTotalFee,
			SystemIndicators.Exclusive,
			SystemIndicators.AllocatedFee,
			SystemIndicators.Unallocated,
			SystemIndicators.AmortizedAmount,
			SystemIndicators.TotalFee3007,
			SystemIndicators.InvoiceTotal,
			SystemIndicators.InvoiceBalance,
			SystemIndicators.Unamortized,
			SystemIndicators.Amortized];

			const calcCharMetaData = response[0].templateMetaData?.characteristicMetaDatas?.filter(cmd => calcSysInds.includes(cmd.systemIndicator));
			const calcCharIds = calcCharMetaData.map(cmd => cmd.characteristicID);
			const calcCharData = response[0].characteristicDatas?.filter(cd => calcCharIds.includes(cd.charactersticID));
			this.calcCharData = calcCharData;
		});

		this._subs.push(charTypesSub, entityStatusSub, recordSub, charDatasSub);
	}

	public cancel() {
		this.onCancel.emit();
	}

	setAssociateEntityDirection(event: RelationshipTypes) {
		this.associateEntityDirection = event;
	}

	setAssociations(event: IAssociationsChangedEvent) {
		this.selectedAssociations = event.selectedAssociations;
		this.deletedAssociations = event.deletedAssociations;
	}

	public copyAndAssociate() {
		this.submit(this.selectedAssociations, this.deletedAssociations, this.associateEntityDirection);
	}

	public loadAssociations() {
		const split = IDUtil.splitEntityID(this.entityId);
		const sub = this._transferAssociationsService.loadAssociations(split.charTypeID, split.recID, this.newCharTypeId, this.newTemplateId).subscribe();
		this._subs.push(sub);
	}

	public submit(selectedAssociations?: IEntityRelationship[], deletedAssociations?: IEntityRelationship[], associateNewEntityDirection?: RelationshipTypes) {
		this.isLoading = true;
		let doNotCopyAssociations = true;

		this.hasNullCalcFieldValues(selectedAssociations);

		if (!selectedAssociations && !deletedAssociations) {
			doNotCopyAssociations = false;
		}

		if (this.copyMethod === CopyMethod.Copy || this.copyMethod === CopyMethod.CopyTo) {
			const sub = this._copyService.copy(this.entityId, this.newTitle, this.newCharTypeId, this.newTemplateId,
				this.copyParties, selectedAssociations, this.dateMathOption, associateNewEntityDirection, doNotCopyAssociations, this.nullCalcFieldValues).subscribe(jobId => {
					this.jobId = jobId;
					this.wizard.goToNextStep();
				});
			this._subs.push(sub);
		} else if (this.copyMethod === CopyMethod.SwitchTemplate) {
			// TODO: move this off the close like the other copy method
			const sub = this._copyService.switch(this.entityId, this.newTitle, this.newCharTypeId, this.newTemplateId, this.copyParties, selectedAssociations, deletedAssociations).subscribe(newEntityId => {
				this.completeCopy(newEntityId);
			});
			this._subs.push(sub);
		}
	}

	hasNullCalcFieldValues(selectedAssociations?: IEntityRelationship[]) {
		if (!selectedAssociations?.length && this.calcCharData && this.calcCharData.length) {
			this.nullCalcFieldValues = true;
		}
	}

	handleCopyJobComplete(event: IJobProgressResult) {
		this.newEntityId = event.resultMessage;

		const sub = this.runInitialWorkflowAction$(this.newEntityId)
			.subscribe((wfJobId) => {
				if (wfJobId) {
					this.jobId = null;
					this.wfJobId = wfJobId;
				} else {
					this.completeCopy(this.newEntityId);
				}
			});
		this._subs.push(sub);
	}

	workFlowActionDone(event: IJobProgressResult) {
		this.completeCopy(this.newEntityId);
	}

	private completeCopy(newEntityId: string) {
		if (!newEntityId || isEmpty(newEntityId)) {
			return;
		}

		const newTemplate = this.copyToTemplates.find(t => t.templateID === this.newTemplateId);
		const templateGroupIds = [newTemplate?.templateGroupID].filter(x => !!x);
		const entityId = IDUtil.splitEntityID(newEntityId);

		if (this.isModuleLevelCharType) {
			const url = this._linkHelper.go(entityId.charTypeID, entityId.recID);
			this._router.navigate(url);
		}

		this.onComplete.emit({
			templateIds: [this.newTemplateId],
			templateGroupIds: templateGroupIds,
			shouldUpdate: true
		});
	}

	private runInitialWorkflowAction$(newEntityId: string) {
		const entityId = IDUtil.splitEntityID(newEntityId);
		const processes = this._oneConfigService.getCharTypeProcesses(this.newCharTypeId);
		const templateProcessId = this._oneConfigService.getTemplateProcess(this.newCharTypeId, this.newTemplateId);
		const process = processes.find(p => p.processId === templateProcessId);
		if (!process) {
			return of(null);
		}

		const processActions = process.workflowActions;
		const initialAction = processActions.find(pa => pa.pointProcId === 1);

		if (initialAction) {
			return this._workflowService.getWorkflowActionExtension(process.processId, initialAction.actionId)
				.pipe(
					switchMap((wfActionExtReponse) => {
						if (wfActionExtReponse.extensions.length > 0) {
							return this._workflowService.executeJob(this.newCharTypeId, entityId.recID, initialAction.actionId);
						}
						return of(null);
					})
				);
		} else {
			return of(null);
		}
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}

export interface ICopyTemplates {
	copyToTemplates: ICharacteristicTemplate[];
	switchToTemplates: ICharacteristicTemplate[];
}
