import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IFinancialPeriod } from "rl-common/services/financial-period/financial-period.models";
import { EditFinancialPeriodComponent } from "./edit-financial-period.component";

@Component({
    selector: "rl-financial-period-modal",
    templateUrl: "./financial-period-modal.component.html",
    imports: [EditFinancialPeriodComponent]
})
export class FinancialPeriodModalComponent implements OnInit {

	@Input()
	financialPeriod: IFinancialPeriod;

	@Input()
	isView: boolean;

	@Output()
	onComplete = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit(): void {

	}

	close(requiresRefresh) {
		this.onComplete.emit(requiresRefresh);
	}
}
