<rl-hierarchy-group>
	<span rlHierarchyGroupHead class="form-check pt-1">
		<input type="checkbox" class="form-check-input" [(ngModel)]="areAllSelected" (change)="toggleSelectAll()"
			[attr.data-cy]="'CharTypeId_' + charType.characteristicType.charTypeID" />
		<label class="form-check-label ps-1 text-nowrap">{{charType.characteristicType.charTypeName}}</label>
	</span>
	<div class="mx-4" rlHierarchyGroupContent>
		<rl-transfer-association-group *ngFor="let group of charType.groups" [group]="group"
			(onChange)="updateSelectAll()">
		</rl-transfer-association-group>
	</div>
</rl-hierarchy-group>