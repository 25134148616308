import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { BulkCreateOptionsFormComponent } from "../bulk-create-options/bulk-create-options-form/bulk-create-options-form.component";

@Component({
    selector: "rl-bulk-create-options-modal",
    templateUrl: "./bulk-create-options-modal.component.html",
    styleUrls: ["./bulk-create-options-modal.component.scss"],
    imports: [BulkCreateOptionsFormComponent]
})
export class BulkCreateOptionsModalComponent implements OnInit {

	@Input()
	charTypeId: CharTypeId;

	@Input()
	template: ICharacteristicTemplate;

	@Output()
	onComplete = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit(): void {
	}

	complete(success: boolean) {
		this.onComplete.emit(success);
	}

	cancel() {
		this.onComplete.emit(false);
	}

}
