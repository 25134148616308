<div class="modal-body" data-cy="ModalBody">
	<ul ngbNav #nav="ngbNav" class="nav-tabs tabset--rl grey" [destroyOnHide]="false">
		<li ngbNavItem *ngIf="showExisting">
			<a ngbNavLink>
				<div [attr.data-cy]="'SelectExistingTab_' + charTypeId">Select Existing {{charTypeId |
					pluralCharTypeName}}</div>
			</a>
			<ng-template ngbNavContent>
				<div class="mt-3">
					<rl-module-association *ngIf="dataSource" [dataSource]="dataSource" [charTypeId]="charTypeId"
						(onEntityRelationshipChange)="updateState($event)">

					</rl-module-association>
				</div>
				<div class="modal-footer" data-cy="ModalFooter">
					<button class="btn btn-light me-3" (click)="cancel()"
						data-cy="CancelNewAssociationModalButton">Cancel</button>
					<button class="btn btn-success" type="button" data-cy="SaveNewAssociationModalButton"
						(click)="apply()" [disabled]="isSaving || !isValid">
						<ng-template [ngIf]="!isSaving">
							{{applyText}}
						</ng-template>
						<ng-template [ngIf]="isSaving"><i class="far fa-spinner fa-spin"></i>
							{{applyText}}
						</ng-template>
					</button>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem *ngIf="!includeCurrentAssociations && hasValidCreateTemplates">
			<a ngbNavLink>
				<div [attr.data-cy]="'CreateNewTab_' + charTypeId">Create New {{charTypeId | charTypeName}}</div>
			</a>
			<ng-template ngbNavContent>
				<aw-wizard class="rl-wizard mt-3" navBarLayout="small" [disableNavigationBar]="true">
					<aw-wizard-step stepTitle="Create New Record">
						<rl-create-new-association [form]="form" [charTypeId]="charTypeId"
							[parentCharTypeId]="parentCharTypeId" [parentTemplateId]="parentTemplateId"
							[parentTitle]="parentTitle" [direction]="direction" [partyId]="partyId"
							[relationshipDirectionTypes]="relationshipDirectionTypes" [dataSource]="dataSource"
							(onDirectionChange)="setNewEntityDirection($event)"
							(onFolderUploaded)="folderUploaded($event)">
						</rl-create-new-association>
						<rl-association-chips *ngIf="showExisting && dataSource" [charTypeId]="charTypeId"
							[dataSource]="dataSource">

						</rl-association-chips>
						<div class="d-flex justify-content-end border-top mt-3 pt-3">
							<label *ngIf="charTypeId !== documentCharType" class="m-2">
								<input [(ngModel)]="viewNewRecordAfterCreation" type="checkbox"
									class="me-1 form-check-input" data-cy="GoToNewRecordCheckbox" />
								Go to new Record on Save
							</label>
							<button type="button" class="btn btn-light ms-4" (click)="cancel()"
								data-cy="CancelCreateNewAssociationModalButton">Cancel</button>
							<button class="btn btn-success ms-3" type="button"
								data-cy="SaveCreateNewAssociationModalButton" (click)="apply()"
								[disabled]="isSaving || !isValid || isCreating">
								<ng-template [ngIf]="!isSaving">{{applyText}}</ng-template>
								<ng-template [ngIf]="isSaving"><i class="far fa-spinner fa-spin"></i> {{applyText}}
								</ng-template>
							</button>
							<button *ngIf="showContacts" type="button" data-cy="ContinueToAssociateContactsButton"
								class="btn btn-info ms-3" (click)="continueToNextStep()"
								[disabled]="isCreating || !isValid || isSaving">
								<span *ngIf="!isCreating">Continue to Associate Contacts <i
										class="far fa-chevron-right ms-2"></i></span>
								<span *ngIf="isCreating"><i class="far fa-spinner fa-spin"></i>
									Continue to Associate Contacts <i class="far fa-chevron-right ms-2"></i></span>
							</button>
						</div>
					</aw-wizard-step>
					<aw-wizard-step *ngIf="showContacts" stepTitle="Associate Contacts (optional)">
						<rl-associate-contacts *ngIf="newAssociationTemplate" [charTypeId]="charTypeId"
							[templateId]="newAssociationTemplate.templateID" [parentRecId]="parentRecordId"
							[parentCharTypeId]="parentCharTypeId" [canAutoCreateChildItems]="canAutoCreateHierarchy"
							(onCancel)="cancel()" (onBack)="goToPreviousWizardStep()" (onCreate)="apply([])"
							(onGoToNextStep)="continueToNextStep()">
						</rl-associate-contacts>
					</aw-wizard-step>
					<aw-wizard-step *ngIf="canAutoCreateHierarchy" stepTitle="Auto-Create Child Items (optional)">
						<rl-create-and-associate-hierarchical-records [templates]="childTemplates"
							[charTypeId]="charTypeId" [entityId]="createdEntityId" (onCancel)="cancel()"
							(onBack)="goToPreviousWizardStep()" (onHierarchyCompleted)="apply($event)">
						</rl-create-and-associate-hierarchical-records>
					</aw-wizard-step>
				</aw-wizard>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>