<rl-form-table-row>
	<ng-template rlFormTableLabel>Record Type</ng-template>
	<ng-template rlFormTableControl>
		<!-- TODO: Implement <rl-dropdown-single> -->
		<select class="form-select" [(ngModel)]="selectedComponentCharTypeId" (ngModelChange)="updateCharType()">
			<option [ngValue]="null" data-cy="DefaultSelect">Select</option>
			<option *ngFor="let opt of availableCharTypeOptions$ | async" [ngValue]="opt.charTypeId">
				{{ opt.charTypeId | charTypeName }}
			</option>
		</select>
	</ng-template>
</rl-form-table-row>
@if(showGroupByTypeOptions) {
<rl-form-table-row>
	<ng-template rlFormTableLabel>Default Group By Type</ng-template>
	<ng-template rlFormTableControl>
		<rl-radio-input class="d-block py-1" [label]="'All ' + (section.params.charTypeId | pluralCharTypeName)"
			[name]="'groupTypeAll'" [(ngModel)]="groupByType" [value]="1" [dataLabel]="'groupTypeAll'"
			(ngModelChange)="updateGroupByType()"></rl-radio-input>
		<rl-radio-input class="d-block py-1" [label]="'Template'" [name]="'groupTypeTemplate'" [(ngModel)]="groupByType"
			[value]="2" [dataLabel]="'groupTypeTemplate'" (ngModelChange)="updateGroupByType()"></rl-radio-input>
		<rl-radio-input class="d-block py-1" [label]="'Template Group'" [name]="'groupTypeTemplateGroup'"
			[(ngModel)]="groupByType" [value]="3" [dataLabel]="'groupTypeTemplateGroup'"
			(ngModelChange)="updateGroupByType()"></rl-radio-input>
	</ng-template>
</rl-form-table-row>
}
@if(showTemplateOptions) {
<rl-form-table-row>
	<ng-template rlFormTableLabel>Templates</ng-template>
	<ng-template rlFormTableControl>
		<rl-dropdown-menu [(ngModel)]="selectedTemplates" [options]="templateOptions" [displayAsCheckbox]="true"
			(onValueChange)="updateTemplates()">
		</rl-dropdown-menu>
	</ng-template>
</rl-form-table-row>
}