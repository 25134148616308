<div class="modal-header mb-3">
	<h2 *ngIf="isTable" class="modal-title">Copy Record</h2>
	<h2 *ngIf="!isTable" class="modal-title">Copy Record / Switch Template</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="CopyModalCloseButton">
		<i class="far fa-times"></i>
	</button>
</div>

<aw-wizard *ngIf="!jobId && !wfJobId && form && datemathLoaded ; else loadingModal" class="rl-wizard"
	navBarLayout="small">
	<aw-wizard-step stepTitle="Copy" [canExit]="form.valid">
		<form [formGroup]="form" (submit)="submit()">
			<div class="modal-body" data-cy="CopyModalBody">
				<div class="container">
					<div class="row align-items-center bg-light p-3 mb-5">
						<label class="col-3 col-form-label text-end"><strong>Name the new record:</strong></label>
						<div class="col-6">
							<input type="text" class="form-control" formControlName="name"
								[ngClass]="{ 'is-invalid': nameControl.errors }" data-cy="copy_entity_title" />
							<div *ngIf="nameControl.errors" class="invalid-feedback">
								<div *ngIf="nameControl.errors.required">Name is required</div>
								<div *ngIf="nameControl.errors.maxlength">Name cannot be over {{nameLimit}} characters
								</div>
							</div>
						</div>
						<div class="col-2 offset-1 text-end help">
							<i class="far fa-question-circle lead" [ngbPopover]="helpPopover" triggers="click:blur"
								placement="bottom-right"></i>
						</div>
					</div>
					<div class="mb-2 border-bottom" *ngIf="canCopy">
						<div class="d-flex ps-4">
							<input type="radio" class="form-check-input" formControlName="copyMethod" [value]="0"
								(ngModelChange)="onCopyMethodChanged($event)" id="copyRadio" data-cy="copy_radio" />
							<label class="form-check-label ms-2 no-select" for="copyRadio">
								<span class="d-block bold">Copy</span>
								Duplicate the record exactly, including data and associations.
							</label>
						</div>
					</div>
					<div class="mb-2 border-bottom">
						<div class="d-flex ps-4">
							<input type="radio" class="form-check-input" formControlName="copyMethod" [value]="1"
								(ngModelChange)="onCopyMethodChanged($event)" id="copyToRadio" data-cy="copyto_radio" />
							<label class="form-check-label ms-2 no-select" for="copyToRadio">
								<span class="d-block bold">Copy To</span>
								Copy any valid data and associations from the record to the template:
							</label>
						</div>
						<div class="row ms-4 mt-3">
							<div class="col-5 p-0" *ngIf="showCopySwitchCharTypeSelector">
								<select class="form-select" formControlName="copyToCharTypeId"
									(ngModelChange)="onCopyToCharTypeIdChanged()" data-cy="copyToFirstLov">
									<option *ngFor="let char of copyToCharTypes" [value]="char.charTypeID"
										[attr.data-cy]="char.charTypeID">
										{{ char.charTypeName }}
									</option>
								</select>
							</div>
							<div class="col-5 p-0" [ngClass]="{ 'ms-3': showCopySwitchCharTypeSelector }">
								<select class="form-select" formControlName="copyToTemplateId"
									(ngModelChange)="onCopyToTemplateIdChanged($event)" data-cy="copyToSecondLov">
									<option *ngFor="let template of copyToTemplates" [value]="template.templateID"
										[attr.data-cy]="template.templateID">
										{{ template.templateName }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="mb-2 border-bottom" *ngIf="canSwitch">
						<div class="d-flex ps-4">
							<input type="radio" class="form-check-input" formControlName="copyMethod" [value]="2"
								(ngModelChange)="onCopyMethodChanged($event)" id="switchRadio" data-cy="switch_radio" />
							<label class="form-check-label ms-2 no-select" for="switchRadio">
								<span class="d-block bold">Switch Template</span>
								Switch the record, along with any valid data and associations, to the template:
							</label>
						</div>
						<div class="row ms-4 mt-3">
							<div class="col-5 p-0" *ngIf="showCopySwitchCharTypeSelector">
								<select class="form-select" formControlName="switchToCharTypeId"
									(ngModelChange)="onSwitchToCharTypeIdChanged()" data-cy="switchFirstLov">
									<option *ngFor="let char of switchToCharTypes" [value]="char.charTypeID"
										[attr.data-cy]="char.charTypeID">
										{{ char.charTypeName }}
									</option>
								</select>
							</div>
							<div class="col-5 p-0" [ngClass]="{ 'ms-3': showCopySwitchCharTypeSelector }">
								<select class="form-select" formControlName="switchToTemplateId"
									(ngModelChange)="onSwitchToTemplateIdChanged($event)" data-cy="switchSecondLov">
									<option *ngFor="let template of switchToTemplates" [value]="template.templateID"
										[attr.data-cy]="template.templateID">
										{{ template.templateName }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="mb-2" *ngIf="showIncludeParties">
						<div class="d-flex ps-4">
							<input type="checkbox" class="form-check-input" formControlName="includeParties"
								id="includeParties" data-cy="includePartiesCheckbox" />
							<label class="form-check-label ms-2" for="includeParties">
								Include Parties/Contacts, where valid.
							</label>
						</div>
					</div>
					<div *ngIf="showIncludeParties" class="border-bottom p-0"></div>
					<div class="py-3" *ngIf="showWarnings">
						<div class="d-flex px-4 warning-results" *ngIf="showLockedWarning">
							<i class="far fa-exclamation-triangle" title="Error"></i>
							The record cannot be switched to another template, because the record is locked in the
							current Workflow status. You can cancel this operation, change the Workflow status, then try
							again.
						</div>
						<div class="d-flex px-4 warning-results"
							*ngIf="warningChecks && warningChecks.lockedAssociations">
							<i class="far fa-exclamation-triangle" title="Warning"></i>
							<div>
								The following Catalog Items cannot be copied to the new Rights Set, because their
								current Workflow status has the record locked. You can continue to copy without
								associating these Catalog Items, or cancel this operation, change the Workflow status,
								then try again:
								<span>{{ warningChecks.lockedAssociations }}</span>
							</div>
						</div>
						<div class="d-flex px-4 warning-results" *ngIf="warningChecks && warningChecks.invalidFields">
							<i class="far fa-exclamation-triangle" title="Warning"></i>
							<div>
								@if(copyMethod === 2) {
									These fields will not be switched, because: 1) the fields do not exist in the
									target, or 2) your administrator has not given you sufficient permissions to
									edit them:
								} @else {
									These fields will not be copied, because: 1) the fields do not exist in the
									target, 2) the configuration does not allow it, or 3) your administrator has not given you sufficient permissions to
									edit them:
								}
								<span>{{ warningChecks.invalidFields }}</span>
							</div>
						</div>
						<div class="d-flex px-4 warning-results" *ngIf="warningChecks && warningChecks.invalidParties">
							<i class="far fa-exclamation-triangle" title="Warning"></i>
							<div>
								These Parties/Contacts will not be copied/switched, because: 1) they do not exist in the
								target, or 2) the target Party does not accept the same kind of Contact, or 3) there is
								more than one Contact and the target does not accept multiples:
								<span>{{ warningChecks.invalidParties }}</span>
							</div>
						</div>
						<div class="d-flex px-4 warning-results" *ngIf="warningChecks && invalidAssociations">
							<i class="far fa-exclamation-triangle" title="Warning"></i>
							<div>
								Records on these Templates will not be copied/switched, because they are not valid for
								use with the target Template. (Valid associations can be modified in Configuration by
								your administrator):
								<span>{{ invalidAssociations }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer" data-cy="CopyModalFooter">
				<button type="button" class="btn btn-light" (click)="cancel()"
					data-cy="copyModalCancelButton">Cancel</button>
				<button type="submit" class="btn btn-success ms-3" (click)="submit()"
					[disabled]="!form.valid || isLoading || showLockedWarning" data-cy="copyModalSaveButton">
					<i class="far fa-spinner fa-spin" *ngIf="isLoading"></i>
					Save
				</button>
				<button *ngIf="allowAssociations" type="button" [disabled]="showLockedWarning" class="btn btn-info ms-3"
					data-cy="copyModalContinueAssocButton" awNextStep>{{continueText}} <i
						class="far fa-chevron-right ms-2"></i></button>
			</div>
		</form>
	</aw-wizard-step>
	<aw-wizard-step *ngIf="allowDateMath && containsDatemath" stepTitle="DateMath" awOptionalStep>
		<div class="modal-body" data-cy="CopyModalBody">
			<div class="row justify-content-center">
				<div class="col-10">
					<h4 class="mb-3">
						Select from the options below to set the DateMath copy behavior:
					</h4>
					<div class="border-bottom d-flex py-3" *ngIf="canCopy">
						<input type="radio" class="form-check-input" [value]="1" name="dateMathOptionGroup"
							[(ngModel)]="dateMathOption" id="defaultRadio" data-cy="dateMathDefaultOption_radio" />
						<label class="form-check-label ms-2 no-select" for="defaultRadio">
							<span class="d-block bold">Default</span>
							All DateMath referring to module record dates should refer to the copied record dates; all
							DateMath referring to component record dates should refer to the original record dates.
						</label>
					</div>
					<div class="border-bottom d-flex py-4">
						<input type="radio" class="form-check-input" [value]="2" name="dateMathOptionGroup"
							[(ngModel)]="dateMathOption" id="originalRadio" data-cy="dateMathOriginalOption_radio" />
						<label class="form-check-label ms-2 no-select" for="originalRadio">
							<span class="d-block bold">Refer to Original Record</span>
							All DateMath on module record and child components being copied should refer to original
							record dates.
						</label>
					</div>
					<div class="d-flex py-4">
						<input type="radio" class="form-check-input" [value]="3" name="dateMathOptionGroup"
							[(ngModel)]="dateMathOption" id="copiedRadio" data-cy="dateMathCopiedOption_radio" />
						<label class="form-check-label ms-2 no-select" for="copiedRadio">
							<span class="d-block bold">Refer to Copied Record</span>
							All DateMath on module record and child components being copied should refer to copied
							record dates.
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer" data-cy="CopyModalFooter">
			<button type="button" class="btn btn-light" (click)="cancel()"
				data-cy="copyModalCancelButton">Cancel</button>
			<button type="submit" class="btn btn-success ms-3" (click)="submit()"
				[disabled]="!form.valid || isLoading || showLockedWarning" data-cy="copyModalSaveButton">
				<i class="far fa-spinner fa-spin" *ngIf="isLoading"></i>
				Save
			</button>
			<button *ngIf="allowAssociations" type="button" [disabled]="showLockedWarning" class="btn btn-info ms-3"
				data-cy="copyModalContinueAssocButton" awNextStep>Continue
				to Associate <i class="far fa-chevron-right ms-2"></i></button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step *ngIf="allowAssociations" stepTitle="Associate (Optional)" (stepEnter)="loadAssociations()"
		awOptionalStep>
		<div class="modal-body" data-cy="AssociateModalBody">
			<div class="container">
				<rl-transfer-associations [originalEntity]="entity" [newEntityTitle]="newTitle"
					[newCharTypeId]="newCharTypeId" [newTemplateId]="newTemplateId"
					(onAssociationsChanged)="setAssociations($event)"
					(onAssociateNewEntityChanged)="setAssociateEntityDirection($event)">
				</rl-transfer-associations>
			</div>
		</div>
		<div class="modal-footer">
			<button awPreviousStep class="btn btn-info" data-cy="backToCopyRecordButton"><i
					class="far fa-chevron-left me-2"></i> Back</button>
			<button type="button" class="btn btn-light ms-3" (click)="cancel()"
				data-cy="cancelContAssocButton">Cancel</button>
			<button class="btn btn-success ms-3" (click)="copyAndAssociate()" data-cy="saveContAssocButton"
				rlBusyButton>
				<i class="far fa-spinner fa-spin" *ngIf="isLoading"></i>
				Save
			</button>
		</div>
	</aw-wizard-step>
</aw-wizard>
<div *ngIf="jobId || wfJobId">
	<div *ngIf="isModuleLevelCharType" class="text-center">
		<div class="m-2" *ngIf="jobId">We are copying your record. Once the copy process is done, we will notify you via
			email for next steps.</div>
		<div class="m-2" *ngIf="wfJobId">We are running the custom workflow action for a newly copied record.</div>
		<button class="btn btn-info" (click)="onCancel.next()">Continue Browsing</button>
	</div>
	<rl-job-progress *ngIf="jobId" [jobId]="jobId" (onComplete)="handleCopyJobComplete($event)"></rl-job-progress>
	<rl-job-progress *ngIf="wfJobId" [jobId]="wfJobId" (onComplete)="workFlowActionDone($event)"></rl-job-progress>
</div>

<ng-template #helpPopover>
	<div class="popover--help p-1">
		<h5>COPY</h5>
		Associations to Projects, Catalog Items, Deals, Inventory Items, and Jobs get additionally associated to your
		new record, without making copies of the originals.
		<hr />
		Associations to Rights Sets, Royalty Sets, and Tables are always copied, and those copies get Associated to your
		new record.
		<hr />
		Associations to Accounting Items and Documents/Files are never automatically associated to your new record, but
		you can associate them manually through the selective associations process.
		<hr />
		<div *ngIf="!isTable">
			<h5 class="mt-3">SWITCH TEMPLATE</h5>
			Associations to Projects, Catalog Items, Deals, Inventory Items, Jobs, and Accounting Items get transferred
			to
			your new template, without making copies of the originals.
			<hr />
			Associations to Rights Sets, Royalty Sets, Tables, and Documents/Files get transferred to your new template,
			without making copies of them.
			<hr />
			Records that cannot be validly associated to your target template (based on your system configuration) are
			automatically skipped.
		</div>
	</div>
</ng-template>

<ng-template #loadingModal>
	<div class="modal-body">
		<rl-loader></rl-loader>
	</div>
</ng-template>