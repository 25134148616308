import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from "@angular/forms";
import { flatten, isEqual, uniq } from "lodash";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { MultipleIndicator } from "rl-common/models/multiple-indicator";
import { DateGroupType } from "rl-common/services/accounting-processes/models/date-group-type";
import { DateType } from "rl-common/services/accounting-processes/models/date-type";
import { ICreateTrigger } from "rl-common/services/accounting-processes/models/i-create-trigger";
import { PopulateType } from "rl-common/services/accounting-processes/models/populate-type";
import { TriggerEventType } from "rl-common/services/accounting-processes/models/trigger-event-type";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AccountingProcesses } from "../accounting-processes.consts";
import { IBuildAccountingOperationDate } from "../build-accounting-operation/models/i-build-accounting-operation-date";

export interface IAddAccountingOperationDateForm {
	accountingProcessOperationDateId: FormControl<string>;
	dateGroupTypeId: FormControl<number>;
	dateTypeId: FormControl<number>;
	characteristicId: FormControl<number>;
	characteristicIdDefault1: FormControl<number>;
	populateTypeId: FormControl<number>;
}
export interface IDateFieldDate {
	characteristicId: number;
	label: string;
	disabled: boolean;
}
@Component({
	selector: "rl-add-accounting-operation-date",
	templateUrl: "./add-accounting-operation-date.component.html",
	styleUrls: ["./add-accounting-operation-date.component.scss"],
	imports: [NgIf, ReactiveFormsModule, NgFor, FormsModule]
})
export class AddAccountingOperationDateComponent implements OnInit, OnChanges, OnDestroy {
	private _subs: Subscription[] = [];
	selectedCharacteristicIdValue: string;
	isCheckboxChecked: boolean = false;
	selectedCharacteristicId: number;

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	anchorTemplateDates: ICharacteristicMetaData[] = [];

	@Input()
	anchorTemplateDefaultDates: ICharacteristicMetaData[] = [];

	@Input()
	date: IBuildAccountingOperationDate;

	@Input()
	otherDates: IBuildAccountingOperationDate[] = [];

	@Input()
	otherOperationDates: IBuildAccountingOperationDate[] = [];

	@Input()
	operationTriggers: ICreateTrigger[];

	@Output()
	onSave = new EventEmitter<IBuildAccountingOperationDate>();

	@Output()
	onCancel = new EventEmitter();

	dateFieldDates: IDateFieldDate[] = [];

	populateTypes: [PopulateType, string][] = [];

	form: FormGroup<IAddAccountingOperationDateForm>;

	isFirst: boolean = false;

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _oneConfig: OneConfigService
	) { }

	ngOnInit() {
		this.anchorTemplateDates = this.alphaSort(this.anchorTemplateDates);
		this.anchorTemplateDefaultDates = this.alphaSort(this.anchorTemplateDefaultDates);
		this.isFirst = this.getIsFirst();
		this.buildForm(this.date);
	}

	getIsFirst() {
		return !this.otherDates?.find(d => d.dateGroupTypeId === DateGroupType.FirstRecord);
	}

	buildDateFieldDates() {
		this.dateFieldDates = [
			{
				characteristicId: null,
				label: "Select",
				disabled: false
			}
		];
		this.anchorTemplateDates.forEach(a => {
			const dateFieldDate: IDateFieldDate = {
				characteristicId: a.characteristicID,
				label: a.label,
				disabled: this.dateFieldDateDisabled(a.characteristicID)
			}
			this.dateFieldDates.push(dateFieldDate);
		});
	}

	dateFieldDateDisabled(characteristicId: number): boolean {
		return !!this.otherDates.find(d => d.dateGroupTypeId === DateGroupType.AdditionalSection && d.characteristicId === characteristicId) || !!this.otherOperationDates.find(d => d.dateGroupTypeId === DateGroupType.AdditionalSection && d.characteristicId === characteristicId);
	}

	buildPopulateTypes() {
		this.populateTypes = [
			[null, "Select"]
		];
		const dataBehaviorTriggers = this.operationTriggers.filter(t => t.triggerType === TriggerEventType.DataBehavior);

		const templateDateCmds = uniq(flatten(dataBehaviorTriggers.map(t => {
			const dataBehaviorCmds = AccountingProcesses.dataBehaviorCmds(this._oneConfig, t.charTypeId, t.templateId);
			return AccountingProcesses.dataBehaviorDateCmds(dataBehaviorCmds);
		})));

		const dataBehaviorDateBasedTriggers = dataBehaviorTriggers.filter(t => !!templateDateCmds.find(cmd => cmd.characteristicID === t.characteristicId));

		const uniqueDataBehaviorDateBasedTriggers = dataBehaviorDateBasedTriggers.reduce((acc, curr) => {
			if (!acc.find(t => t.characteristicId === curr.characteristicId)) {
				acc.push(curr);
			}
			return acc;
		}, [] as ICreateTrigger[]);

		if (this.operationTriggers.length === 1) {
			this.populateTypes.push([PopulateType.DateOfEvent, "Date of Event"]);
		} else if (dataBehaviorDateBasedTriggers.length > 1) {
			if (this.operationTriggers.length === dataBehaviorDateBasedTriggers.length && uniqueDataBehaviorDateBasedTriggers.length === 1) {
				this.populateTypes.push([PopulateType.DateOfEvent, "Date of Event"]);
			}
		}

		if (uniqueDataBehaviorDateBasedTriggers.length === 1) {
			this.populateTypes.push([PopulateType.ValueInField, "Value in Field"]);
		}

		if (uniqueDataBehaviorDateBasedTriggers.length > 1) {
			this.populateTypes.push(
				[PopulateType.LatestOfValuesInDateTriggers, "Latest of Values in this Operation's Date Triggers"],
				[PopulateType.EarliestOfValuesInDateTriggers, "Earliest of Values in this Operation's Date Triggers"]
			);
		}
	}

	buildForm(date: IBuildAccountingOperationDate = null) {
		this._subs.forEach(sub => sub.unsubscribe());

		const defaultValues = this.defaultDateValues();
		const merged = { ...defaultValues, ...date };

		this.form = this._formBuilder.group<IAddAccountingOperationDateForm>({
			accountingProcessOperationDateId: this._formBuilder.control(merged.accountingProcessOperationDateId),
			dateGroupTypeId: this._formBuilder.control(merged.dateGroupTypeId),
			dateTypeId: this._formBuilder.control(merged.dateTypeId),
			characteristicId: this._formBuilder.control(merged.characteristicId),
			characteristicIdDefault1: this._formBuilder.control(merged.characteristicIdDefault1),
			populateTypeId: this._formBuilder.control(merged.populateTypeId)
		}, { validators: dateFieldsRequiredValidatorFactory(this.isCheckboxChecked) });

		const dateTypeIdChangeSub = this.form.controls.dateTypeId.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe((dateTypeId) => {
			this.form.controls.dateTypeId.setValue(dateTypeId);
			this.form.controls.characteristicId.setValue(defaultValues.characteristicId);
			this.form.controls.characteristicIdDefault1.setValue(defaultValues.characteristicIdDefault1);
			this.form.controls.populateTypeId.setValue(defaultValues.populateTypeId); // is this necessary?
			this.form.updateValueAndValidity();
		})

		const characteristicIdChangeSub = this.form.controls.characteristicId.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(characteristicId => {
			if (characteristicId !== this.selectedCharacteristicId) {
				this.onCharacteristicIdSelect(characteristicId);
			}
		});

		this._subs.push(characteristicIdChangeSub, dateTypeIdChangeSub);
	}

	resetForm() {
		this.selectedCharacteristicId = null;
		this.isCheckboxChecked = false;
		this.buildForm();
	}

	private resetAnchorTemplateDefaultDates() {
		const updatedTemplateDefaultDates = Object.values(this.anchorTemplateDates).filter(d => d.multipleIndicator !== MultipleIndicator.Multiple);
		this.anchorTemplateDefaultDates = this.alphaSort([...updatedTemplateDefaultDates]);
	}

	private alphaSort(dates: ICharacteristicMetaData[]) {
		return dates.sort((a, b) => {
			const labelA = a.label.trim().toLowerCase();
			const labelB = b.label.trim().toLowerCase();
			return labelA.localeCompare(labelB);
		})
	}

	defaultDateValues() {
		return {
			accountingProcessOperationDateId: "",
			dateGroupTypeId: this.isFirst ? DateGroupType.FirstRecord : DateGroupType.AdditionalSection,
			dateTypeId: this.isFirst ? DateType.DateOfEvent : null,
			characteristicId: null,
			characteristicIdDefault1: null,
			populateTypeId: null
		} as IBuildAccountingOperationDate;
	}

	onCharacteristicIdSelect(characteristicId: number) {
		if (this.isValidDateChar(characteristicId)) {
			this.handleValidChar(characteristicId);
		} else {
			this.resetAnchorTemplateDefaultDates();
			this.selectedCharacteristicId = null;
		}

		this.anchorTemplateDates = this.alphaSort(this.anchorTemplateDates);
		this.anchorTemplateDefaultDates = this.alphaSort(this.anchorTemplateDefaultDates);
	}

	private isValidDateChar(characteristicId: number) {
		return characteristicId !== null && characteristicId !== undefined;
	}

	private isInDefaultDates(characteristicId: number) {
		return this.anchorTemplateDefaultDates.some(d => d.characteristicID === characteristicId);
	}

	private isInTemplateDates(characteristicId: number) {
		return this.anchorTemplateDates.some(d => d.characteristicID === characteristicId && d.multipleIndicator !== MultipleIndicator.Multiple);
	}

	private addCharToDefaultDates(characteristicId: number) {
		const characteristic = this.anchorTemplateDates.find(d => d.characteristicID === characteristicId);
		if (characteristic) {
			this.anchorTemplateDefaultDates.push(characteristic);
		}
	}

	private removeCurrentChar(characteristicId: number) {
		const index = this.anchorTemplateDefaultDates.findIndex(dateField => dateField.characteristicID === characteristicId);
		if (index !== -1) {
			this.anchorTemplateDefaultDates.splice(index, 1);
		}
	}

	private handleValidChar(characteristicId: number) {
		if (characteristicId !== this.selectedCharacteristicId) {
			if (!this.isInDefaultDates(characteristicId) && this.isInTemplateDates(characteristicId)) {
				this.addCharToDefaultDates(characteristicId);
			} else if (!this.isInDefaultDates(this.selectedCharacteristicId) && this.selectedCharacteristicId !== null && this.isInTemplateDates(this.selectedCharacteristicId)) {
				this.addCharToDefaultDates(this.selectedCharacteristicId);
			}

			this.removeCurrentChar(characteristicId);
			this.selectedCharacteristicId = characteristicId;
		}
	}

	toggleCheckbox(): void {
		const characteristicIdFormControl = this.form.controls.characteristicId;
		const characteristicIdDefault1FormControl = this.form.controls.characteristicIdDefault1;

		if (!this.isCheckboxChecked) {
			if (!this.anchorTemplateDefaultDates.map(d => d.characteristicID).includes(characteristicIdFormControl.value)) {
				this.anchorTemplateDefaultDates.push(this.anchorTemplateDates.find(d => d.characteristicID === characteristicIdFormControl.value));
			}

			characteristicIdDefault1FormControl.patchValue(null);
		} else {
			if (this.anchorTemplateDefaultDates.map(d => d.characteristicID).includes(characteristicIdFormControl.value)) {
				const index = this.anchorTemplateDefaultDates.findIndex(dateField => dateField.characteristicID === characteristicIdFormControl.value);

				if (index !== -1) {
					this.anchorTemplateDefaultDates.splice(index, 1);
				}
			}
		}

		this.anchorTemplateDefaultDates = this.alphaSort(this.anchorTemplateDefaultDates);
		this.updateDateFieldsValidator();
	}

	updateDateFieldsValidator() {
		this.form.setValidators(dateFieldsRequiredValidatorFactory(this.isCheckboxChecked));
		this.form.updateValueAndValidity();
	}

	private toDate(form: FormGroup<IAddAccountingOperationDateForm>) {
		const date: IBuildAccountingOperationDate = {
			accountingProcessOperationDateId: form.controls.accountingProcessOperationDateId.value,
			dateGroupTypeId: form.controls.dateGroupTypeId.value,
			dateTypeId: form.controls.dateTypeId.value,
			characteristicId: form.controls.characteristicId.value,
			characteristicIdDefault1: form.controls.characteristicIdDefault1.value,
			populateTypeId: form.controls.populateTypeId.value
		};

		return date;
	}

	save() {
		const date = this.toDate(this.form);
		this.onSave.emit(date);
	}

	cancel() {
		this.onCancel.emit();
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.anchorTemplate && !isEqual(changes.anchorTemplate.previousValue, changes.anchorTemplate.currentValue)) {
			this.resetAnchorTemplateDefaultDates();
			this.resetForm();
		}

		if (changes.dates && !isEqual(changes.dates.previousValue, changes.dates.currentValue)) {
			setTimeout(() => {
				this.buildForm(this.date);
			}, 0);
		}

		if (changes.anchorTemplateDates && !isEqual(changes.anchorTemplateDates.previousValue, changes.anchorTemplateDates.currentValue)) {
			this.anchorTemplateDates = this.alphaSort(this.anchorTemplateDates);
		}

		if (changes.anchorTemplateDefaultDates && !isEqual(changes.anchorTemplateDefaultDates.previousValue, changes.anchorTemplateDefaultDates.currentValue)) {
			this.anchorTemplateDefaultDates = this.alphaSort(this.anchorTemplateDefaultDates);
		}

		if (changes.otherOperationDates) {
			if (!isEqual(changes.otherOperationDates.previousValue, changes.otherOperationDates.currentValue)) {
				this.otherOperationDates = changes.otherOperationDates.currentValue as IBuildAccountingOperationDate[];
				this.buildDateFieldDates();
				this.resetForm();
			}
		}

		if (changes.operationTriggers) {
			if (!isEqual(changes.operationTriggers.previousValue, changes.operationTriggers.currentValue)) {
				this.operationTriggers = changes.operationTriggers.currentValue as ICreateTrigger[];
				this.buildPopulateTypes();
			}
		}
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}

export const dateFieldsRequiredValidator = (isCheckboxChecked: boolean): ValidatorFn => {
	return (form: FormGroup<IAddAccountingOperationDateForm>): ValidationErrors | null => {
		const dateGroupTypeId = form?.controls?.dateGroupTypeId?.value;
		const dateTypeId = form?.controls?.dateTypeId?.value;
		const characteristicId = form?.controls?.characteristicId?.value;
		const characteristicIdDefault1 = form?.controls?.characteristicIdDefault1?.value;
		const populateTypeId = form?.controls?.populateTypeId?.value;

		if (dateGroupTypeId === DateGroupType.FirstRecord) {
			if (dateTypeId === DateType.DateFieldValue && !characteristicId) {
				return { dateFieldRequired: true };
			}

			if (dateTypeId === DateType.DateFieldValue && isCheckboxChecked && characteristicId && !characteristicIdDefault1) {
				return { defaultDateFieldRequired: true };
			}
		}

		if (dateGroupTypeId === DateGroupType.AdditionalSection) {
			if (!characteristicId || !populateTypeId) {
				return { bothAdditionalSectionDropdownsRequired: true };
			}
		}

		return null;
	};
};

export function dateFieldsRequiredValidatorFactory(isCheckboxChecked: boolean): ValidatorFn {
	return dateFieldsRequiredValidator(isCheckboxChecked);
}