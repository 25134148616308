import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { isEmpty, isEqual } from "lodash";
import { CharDataElementUsage } from "rl-common/components/char-data/char-data.models";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IAccountingOperationTrigger } from "rl-common/services/accounting-processes/models/i-accounting-operation-trigger";
import { TriggerEventType } from "rl-common/services/accounting-processes/models/trigger-event-type";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { AccountingProcesses } from "../accounting-processes.consts";
import { DataBehaviorSingleValueEntryComponent } from "../add-accounting-operation-trigger/data-behavior-single-value-entry/data-behavior-single-value-entry.component";

@Component({
	selector: "rl-accounting-operation-trigger",
	templateUrl: "./accounting-operation-trigger.component.html",
	styleUrls: ["./accounting-operation-trigger.component.scss"],
	imports: [NgSwitch, NgSwitchCase, NgIf, DataBehaviorSingleValueEntryComponent, NgSwitchDefault]
})
export class AccountingOperationTriggerComponent implements OnInit, OnChanges {

	@Input()
	anchorTemplateId: number;

	@Input()
	trigger: IAccountingOperationTrigger;

	anchorTemplate: ICharacteristicMetaDataCollection;

	triggerTypeName: string;

	get anchorTemplateLabel(): string {
		return this.anchorTemplate?.template?.templateName;
	}

	get isTableCharTypeId(): boolean {
		return this.trigger.charTypeId === CharTypeId.Usage;
	}

	accountingTemplateLabel: string;
	workflowActionLabel: string;
	fieldLabel: string;
	operatorLabel: string;
	dataBehaviorTemplateLabel: string;

	elementUsage = CharDataElementUsage.CharDataGrid;
	cmd: ICharacteristicMetaData;
	valueEntryType: AccountingProcesses.ValueEntryType;
	valueControl: FormControl<ICharacteristicData[]>;
	range1Control: FormControl<ICharacteristicData[]>;
	range2Control: FormControl<ICharacteristicData[]>;

	amountTemplateLabel: string;
	tableTemplateLabel: string;

	constructor(private readonly _oneConfig: OneConfigService) { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.anchorTemplateId && changes.anchorTemplateId.currentValue && changes.anchorTemplateId.currentValue !== changes.anchorTemplateId.previousValue) {
			this.anchorTemplate = this._oneConfig.getTemplateMetaData(CharTypeId.Amount, this.anchorTemplateId);
		}

		if (changes.trigger && changes.trigger.currentValue && !isEqual(changes.trigger.currentValue, changes.trigger.previousValue)) {
			this.updateTriggerLabels();
		}
	}

	private updateTriggerLabels() {
		const triggerType = this.trigger?.triggerType;
		if (triggerType == null || triggerType === undefined) {
			return;
		}
		this.triggerTypeName = AccountingProcesses.triggerTypeName(triggerType);
		switch (triggerType) {
			case TriggerEventType.TemplateAssociation:
				const accountingTemplate = this._oneConfig.getTemplateMetaData(CharTypeId.Invoice, this.trigger.templateId);
				this.accountingTemplateLabel = accountingTemplate?.template?.templateName ?? `Unknown`;
				break;
			case TriggerEventType.WorkflowAction:
				const templateProcessId = this._oneConfig.getTemplateProcess(CharTypeId.Amount, this.anchorTemplate.templateID);
				const process = this._oneConfig.getCharTypeProcesses(CharTypeId.Amount).find(x => x.processId === templateProcessId);
				const wfAction = this._oneConfig.getWorkflowAction(CharTypeId.Amount, process.processId, this.trigger.actionId);
				this.workflowActionLabel = wfAction?.actionName ?? `Unknown`;
				break;
			case TriggerEventType.DataBehavior:
				const template = this._oneConfig.getTemplate(this.trigger.charTypeId, this.trigger.templateId);
				this.dataBehaviorTemplateLabel = template.templateName;
				const cmds = this._oneConfig.getTemplateMetaData(this.trigger.charTypeId, this.trigger.templateId);
				this.cmd = cmds.characteristicMetaDatas.find(x => x.characteristicID === this.trigger.characteristicId);
				this.fieldLabel = this.cmd?.label ?? `Unknown Field`;
				this.operatorLabel = AccountingProcesses.operatorVerbiage(this.trigger.operator);
				this.valueEntryType = AccountingProcesses.entryType(this.trigger.operator);
				this.valueControl = new FormControl<ICharacteristicData[]>(this.mapCharDataValues(this.trigger, this.trigger.value));
				this.range1Control = new FormControl<ICharacteristicData[]>(this.mapCharDataValues(this.trigger, this.trigger.rangeValue1));
				this.range2Control = new FormControl<ICharacteristicData[]>(this.mapCharDataValues(this.trigger, this.trigger.rangeValue2));
				break;
			case TriggerEventType.RecordCreation:
				this.amountTemplateLabel = this._oneConfig.getTemplateMetaData(CharTypeId.Amount, this.trigger.amountTemplateId).template.templateName;
				this.tableTemplateLabel = this._oneConfig.getTemplateMetaData(CharTypeId.Usage, this.trigger.tableTemplateId).template.templateName;
				break;
		}

	}

	private mapCharDataValues(trigger: IAccountingOperationTrigger, value: string): ICharacteristicData[] {
		if (!value || isEmpty(value)) {
			return null;
		}
		const tmd = this._oneConfig.getTemplateMetaData(trigger.charTypeId, trigger.templateId);
		return AccountingProcesses.CharDataValueMapping.fromTriggerValues(tmd, trigger.characteristicId, value);
	}

}
