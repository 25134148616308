import { transition, trigger, useAnimation } from "@angular/animations";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { PickerFileMetadata } from "filestack-js";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { FileStackService } from "rl-common/services/file-stack/file-stack.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { IDocTemplateConfig, IDocTemplateSaveRequest } from "rl-common/services/workflow/workflow.models";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableMessageDirective } from "../../../../../common/components/form-table/form-table-message.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";
import { TrimWhitespacePipe } from "../../../../../common/pipes/trim-whitespace.pipe";

interface IDocTemplateForm {
	documentTemplateId: FormControl<number>;
	templateLabel: FormControl<string>;
	filePath: FormControl<string>;
	selectedTemplateIds: FormArray<FormControl<number>>;
}
@Component({
    selector: "rl-draft-template-edit",
    styleUrls: ["./draft-template-edit.component.scss"],
    templateUrl: "./draft-template-edit.component.html",
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: "250ms ease-out"
                    }
                })
            ])
        ])
    ],
    imports: [ReactiveFormsModule, FormsModule, FormTableRowComponent, FormTableControlDirective, TextInputComponent, NgIf, FormTableMessageDirective, NgFor, TrimWhitespacePipe]
})
export class DraftTemplateEditComponent implements OnInit, OnDestroy {

	@Input()
	docTemplateConfig: IDocTemplateConfig = null;

	@Input()
	charTypeId: number;

	@Input()
	selectedTemplateIds: number[];

	@Input()
	docTemplates: IDocTemplateConfig[];

	@Output()
	onComplete = new EventEmitter<boolean>();

	_subs: Subscription[] = [];
	formLoaded: boolean = false;
	isSaving: boolean = false;
	title: string = "";
	pickedFiles: PickerFileMetadata[];
	path: string;

	form: FormGroup<IDocTemplateForm>;

	templates: ICharacteristicTemplate[] = [];

	get isCreate() {
		return !this.docTemplateConfig;
	}

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _fileStackService: FileStackService,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _growlerService: GrowlerService) { }

	ngOnInit(): void {
		this.getCharTemplates();

		if (this.isCreate) {
			this.title = "Create " + CharTypeIdUtil.getCharTypeDisplayName(this.charTypeId, 1) + " Draft Template";
		} else {
			this.title = "Edit " + CharTypeIdUtil.getCharTypeDisplayName(this.charTypeId, 1) + " Draft Template - " + this.docTemplateConfig.doc.templateLabel;
		}

		this.buildForm();
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	buildForm() {
		if (this.isCreate) {
			this.form = this._fb.group<IDocTemplateForm>({
				documentTemplateId: new FormControl(0, Validators.required),
				templateLabel: new FormControl("", [Validators.required, this.duplicateDraftTemplateLabelValidator]),
				filePath: new FormControl(null, Validators.required),
				selectedTemplateIds: new FormArray<FormControl<number>>([])
			});
		} else {
			this.form = this._fb.group<IDocTemplateForm>({
				documentTemplateId: new FormControl(this.docTemplateConfig.doc.documentTemplateId, Validators.required),
				templateLabel: new FormControl(this.docTemplateConfig.doc.templateLabel, [Validators.required, this.duplicateDraftTemplateLabelValidator]),
				filePath: new FormControl(this.docTemplateConfig.doc.fileName, Validators.required),
				selectedTemplateIds: this._fb.array(this.selectedTemplateIds)
			});
		}
		this.formLoaded = true;
	}

	private duplicateDraftTemplateLabelValidator = (fc: FormControl<string>) => {
		if (!fc.value) {
			return null;
		}

		const cleanedDraftTemplateLabel = fc.value.trim().toLowerCase();
		const isDuplicate = this.docTemplates.some(t => t.doc.templateLabel.trim().toLowerCase() === cleanedDraftTemplateLabel && (this.isCreate || t.doc.documentTemplateId !== this.docTemplateConfig.doc.documentTemplateId))

		return isDuplicate ? { "draftTemplateLabelDuplicate": true } : null;
	}

	isTemplateIdChecked(templateId: number) {
		return this.selectedTemplateIds.includes(templateId);
	}

	onSelectedTemplateIdsChange(id: number, isChecked: boolean) {
		const templateIdsFormArray = this.form.controls.selectedTemplateIds as FormArray<FormControl<number>>;

		if (isChecked) {
			templateIdsFormArray.push(new FormControl<number>(id));
		} else {
			const index = templateIdsFormArray.controls.findIndex(x => x.value === id);
			templateIdsFormArray.removeAt(index);
		}

		this.form.controls.selectedTemplateIds.setValue(templateIdsFormArray.value);
	}

	chooseFile($event: Event) {
		const options = this._fileStackService.getBasicFileStackOptions();
		options.maxFiles = 1;
		options.minFiles = 1;
		options.onUploadDone = (response) => {
			this.form.controls.filePath.setValue(response.filesUploaded[0].filename);
			this.pickedFiles = response.filesUploaded;
			this.path = this.pickedFiles[0].key;
		};
		this._fileStackService.upload(options);
	}

	removeFile() {
		this.pickedFiles = [];
		this.form.controls.filePath.setValue(null);
	}

	close() {
		this.onComplete.next(null);
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			let success = true;
			let errorMessage = "";

			try {
				const request: IDocTemplateSaveRequest = {
					charTypeId: this.charTypeId,
					selectedTemplateIds: this.form.controls.selectedTemplateIds.value,
					path: this.path,
					doc: {
						documentTemplateId: this.form.controls.documentTemplateId.value,
						fileName: this.form.controls.filePath.value,
						// backend wants this value but DB overwrites with templateLabel anyway
						templateDescription: this.form.controls.templateLabel.value,
						templateLabel: this.form.controls.templateLabel.value
					}
				};

				const validateDoc = this._entityConfigService.validateDocTemplate(request.path).toPromise();
				const saveDocTempAndAttachment = this._entityConfigService.saveDocTemplateAndAttachment(request).toPromise();

				await Promise.all([validateDoc, saveDocTempAndAttachment]);
			} catch (e) {
				success = false;
				errorMessage = e?.error?.message;
			} finally {
				this.isSaving = false;
			}

			if (success) {
				this.onComplete.next(true);
				this._growlerService.success().growl("Draft template updated successfully");
			} else {
				this._growlerService.error().growl(errorMessage);
			}
		}
	}

	getCharTemplates() {
		this._entityConfigService.getCharTemplates(this.charTypeId).pipe(
			tap(templates => this.templates = templates)
		).subscribe();
	}
}

