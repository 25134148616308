<div class="modal-body">
	<div class="modal-header">
		<h2 class="modal-title" [innerHtml]="title"></h2>
		<button type="button" class="btn btn-close" aria-label="Cancel" (click)="close()" data-cy="closeModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<form (ngSubmit)="submit($event)" *ngIf="formLoaded; else isLoading" [@fadeIn]>
		<div class="modal-body">
			<div class="row">
				<div class="col-8">
					<div class="char-data__group">
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Party Name</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display"
									[ngClass]="{'has-error': form.controls.partyName.touched && form.invalid && form.controls.partyName.value === ''}">
									<rl-text-input [formControl]="form.controls.partyName"
										[dataLabel]="'textInputPartyName'"></rl-text-input>
									<div *ngIf="form.controls.partyName.touched && form.invalid && form.controls.partyName.value === ''"
										class="help-block">Value is required.</div>
								</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Party Description</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display"
									[ngClass]="{'has-error': form.controls.partyDescription.touched && form.invalid && form.controls.partyDescription.value === ''}">
									<rl-text-area [formControl]="form.controls.partyDescription"
										[styles]="{maxLength: 10000}"
										[dataLabel]="'textareaInputDescription'"></rl-text-area>
									<div *ngIf="form.controls.partyDescription.touched && form.invalid && form.controls.partyDescription.value === ''"
										class="help-block">Value is required.</div>
								</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>System Indicator</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-number-input [formControl]="form.controls.systemIndicatorId"
										[dataLabel]="'numberInputSystemIndicator'"></rl-number-input>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-light" (click)="close()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success" [disabled]="form.invalid || isSaving" (click)="submit($event)"
				data-cy="saveButton">Save</button>
		</div>
	</form>
</div>

<ng-template #isLoading>
	<div class="modal-body" [@fadeIn]>
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="shiny__loading__bg h-100 w-100">
					&nbsp;
				</div>
				<div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
					<div class="char-data__control container m-0">
						<div class="shiny__loading__bg w-100">
							&nbsp;
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>