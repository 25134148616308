export enum DonutChartTooltipPositionMode {
	TrackCursor,
	ArcMidpoint,
	ArcOuterEdge
}

export interface ISVGProperties {
	position: {
		left: number;
		top: number;
	};
	dimensions: {
		width: number;
		height: number;
	};
	centerPoint: {
		left: number;
		top: number;
	};
}

export interface IChartProperties {
	chartId: string;
	properties: {
		svgNode: HTMLElement;
		svgProperties: ISVGProperties;
	};
}
