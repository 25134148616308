import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonColumnStrategy } from "rl-common/components/grid/datasource/columns/common-column-strategy";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { CommonDataChangeStrategy } from "rl-common/components/grid/datasource/data-change/common-data-change.strategy";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { ProgressService } from "rl-common/services/progress.service";
import { of, Subscription } from "rxjs";
import { GridTableComponent } from "../../../grid/grid-table/grid-table.component";
import { IAmountInvoiceRelationship } from "./amount-invoice-errors.models";

@Component({
	selector: "rl-amount-invoice-errors",
	templateUrl: "./amount-invoice-errors.component.html",
	styleUrls: ["./amount-invoice-errors.component.scss"],
	imports: [NgIf, GridTableComponent]
})
export class AmountInvoiceErrorsComponent implements OnInit, OnDestroy {
	@Input()
	amountInvoiceRelationships: IAmountInvoiceRelationship[] = [];

	dataSource: CommonGridDataSource<IAmountInvoiceRelationship>;
	_subs: Subscription[] = [];

	@Output()
	onComplete = new EventEmitter<void>();
	constructor(
		private readonly gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _progressService: ProgressService,
		private readonly fb: UntypedFormBuilder
	) { }

	ngOnInit(): void {
		const columnStrategy = new CommonColumnStrategy<IAmountInvoiceRelationship>()
			.withFetchColumnsFn(() => {
				const columns: GridColumn<IAmountInvoiceRelationship>[] = [
					{
						headerName: "Amount Record ID",
						width: "25%",
						key: "recordId",
						renderer: "text",
						getCellData: (row) => row.recordId
					},
					{
						headerName: "Invoices",
						width: "75%",
						key: "invoices",
						renderer: "text",
						getCellData: (row) => row.invoices.map(i => i.title).join(" | ")
					}
				];
				return of(columns);
			});
		const dataChangeStrategy = new CommonDataChangeStrategy<IAmountInvoiceRelationship>();
		this.dataSource = new CommonGridDataSource<IAmountInvoiceRelationship>(row => row.recordId, this._progressService, this.fb)
			.withColumnStrategy(columnStrategy)
			.withDataChangeStrategy(dataChangeStrategy)
			.withFetchFn(ds => of(<IGridFetchResults<IAmountInvoiceRelationship>>{
				rowCount: this.amountInvoiceRelationships.length,
				rowData: this.amountInvoiceRelationships,
			}));
		const sub1 = columnStrategy.ensureColumns().subscribe();
		const sub2 = this.dataSource.fetchRows().subscribe();
		this._subs.push(sub1, sub2);
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}
}
