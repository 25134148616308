
import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { transition, trigger, useAnimation } from "@angular/animations";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Provider, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { first } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { CreateEntityBasicComponent } from "rl-common/components/create-entity-basic/create-entity-basic.component";
import { EntitySearchComponent } from "rl-common/components/entities/entity-search/entity-search.component";
import { SearchOptions, SelectType } from "rl-common/components/entities/entity-search/entity-search.models";
import { ISelectStateChangeEvent } from "rl-common/components/entities/entity-search/select-state-net-changes";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountResult } from "rl-common/services/accounting-processes/models/i-accounting-process-account-result";
import { EntityService } from "rl-common/services/entity/entity.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Subscription } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";
import { ChipComponent } from "../../../../common/components/chip/chip.component";
import { CharTypeNamePipe } from "../../../../common/pipes/char-type-name.pipe";
import { PluralCharTypeNamePipe } from "../../../../common/pipes/plural-char-type-name.pipe";
import { ChartOfAccountsComponent } from "../chart-of-accounts/chart-of-accounts.component";
import { AddContactAccountsModalAdapter, IAddContactAccountsModalComponent } from "./add-contact-accounts-modal.adapter";
import { IAddContactAccountsResult } from "./models/i-add-contact-accounts-result";

@Component({
	selector: "rl-add-contact-accounts-modal",
	templateUrl: "./add-contact-accounts-modal.component.html",
	styleUrls: ["./add-contact-accounts-modal.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [ArchwizardModule, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, EntitySearchComponent, NgIf, NgFor, ChipComponent, CreateEntityBasicComponent, NgbNavOutlet, ChartOfAccountsComponent, CharTypeNamePipe, PluralCharTypeNamePipe]
})
export class AddContactAccountsModalComponent implements OnInit, OnChanges, OnDestroy, IAddContactAccountsModalComponent {

	@Input()
	existingContacts: IAccountingContact[] = [];

	selectedContact: IAccountingContact = null;

	@Output()
	onComplete = new EventEmitter<IAddContactAccountsResult>();

	accounts: IAccountingProcessAccount[] = [];

	@ViewChild(WizardComponent)
	public wizard: WizardComponent;

	@ViewChild("entityCreate")
	entityCreateComponent: CreateEntityBasicComponent;

	@ViewChild("entitySearch")
	entitySearchComponent: EntitySearchComponent;

	contactCharTypeId = CharTypeId.User;
	createTemplateIds: number[] = [];
	isCreatingNewContact = false;

	activeTabId = 1;

	searchOptions: SearchOptions;

	get isNewAccountingContact() {
		return !this.existingContacts.find(x => x.recordId === this.selectedContact.recordId);
	}

	get createFormIsValid() {
		return this.entityCreateComponent.valid;
	}

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _promptService: PromptService,
		private readonly _oneConfig: OneConfigService,
		private readonly _entityService: EntityService
	) { }

	ngOnInit(): void {
		this.searchOptions = this.buildSearchOptions();
		this.createTemplateIds = this._oneConfig.getTemplates(CharTypeId.User).map(x => x.templateID);
	}

	buildSearchOptions() {
		const searchOptions = SearchOptionsFactory.buildListPageOptions(CharTypeId.User);
		searchOptions.selectAllEnabled = false;
		searchOptions.selectType = SelectType.Radio;
		searchOptions.pageSize = 25;
		return searchOptions;
	}

	ngOnChanges(changes: ComponentChanges<this>): void {

	}

	setContact(contact: IAccountingContact) {
		this.selectedContact = contact;
		this.accounts = [];
	}

	close() {
		this.onComplete.next({
			contact: this.selectedContact,
			accounts: this.accounts,
			continueToAccountProcesses: false,
			contactsChanged: this.contactsChanged()
		});
	}

	continue() {
		this.onComplete.next({
			contact: this.selectedContact,
			accounts: this.accounts,
			continueToAccountProcesses: true,
			contactsChanged: this.contactsChanged()
		});
	}

	private contactsChanged(): boolean {
		return this.selectedContact && this?.accounts?.length > 0 && !this.existingContacts.find(x => x.recordId === this.selectedContact.recordId);
	}

	cancel(dialogTemplate: TemplateRef<unknown>, form: FormGroup) {
		if (!form.dirty) {
			this.close();
			return;
		}

		this._promptService.confirm("Confirmation", dialogTemplate)
			.subscribe(confirm => {
				if (confirm) {
					this.close();
				}
			});
	}

	setAccounts(accounts: IAccountingProcessAccountResult[]) {
		this.accounts = accounts.map(x => x.account);
	}

	selectedStateChange($event: ISelectStateChangeEvent<number, IEntitySearchDoc>) {
		const selectedDoc = first(Array.from($event.selected.selectedValues));
		this.selectedContact = selectedDoc ? { title: selectedDoc.title, recordId: selectedDoc.recordID } : null;
	}

	clearSelections() {
		this.entitySearchComponent.dataSourceWrapper.dataSelectStrategy.clearSelected();
	}

	saveNewContact() {
		this.isCreatingNewContact = true;
		const sub2 = this.entityCreateComponent.saveEntity$()
			.pipe(
				switchMap(result => this._entityService.getEntity(result.entityId)),
				tap((result) => {
					this.selectedContact = { title: result.entity.title, recordId: result.entity.recordID };
					this.entityCreateComponent.templateFC.setValue(first(this.entityCreateComponent.availableTemplateIds));
					const searchOptions = this.buildSearchOptions();
					searchOptions.selectedRecordIds = [result.entity.recordID];
					this.searchOptions = searchOptions;
					this.activeTabId = 1;
				}),
				finalize(() => this.isCreatingNewContact = false)
			)
			.subscribe(() => {
				this.wizard.goToNextStep();
			});
		this._subs.push(sub2);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}

export const ADDCONTACTACCOUNTS_MODAL_PROVIDER: Provider = {
  provide: AddContactAccountsModalAdapter,
  useFactory: () => new AddContactAccountsModalAdapter(AddContactAccountsModalComponent)
};

