import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ISecurityPolicy, ISecurityPolicyPermission, PolicyType } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { of, Subscription } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { NgIf } from "@angular/common";
import { FormTableRowComponent } from "../../form-table/form-table-row/form-table-row.component";
import { FormTableLabelDirective } from "../../form-table/form-table-label.directive";
import { FormTableControlDirective } from "../../form-table/form-table-control.directive";

@Component({
    selector: "rl-add-edit-policy-modal",
    templateUrl: "./add-edit-policy-modal.component.html",
    styleUrls: ["./add-edit-policy-modal.component.scss"],
    imports: [ReactiveFormsModule, NgIf, FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective]
})
export class AddEditPolicyModalComponent implements OnInit, OnDestroy {

	@Input()
	policy: ISecurityPolicy;

	@Input()
	policyType: number;

	@Input()
	isCopy = false;

	@Output()
	onComplete = new EventEmitter<string>();

	isCreate = false;
	action = "Edit";
	type = "Data";
	buttonText = "Save";
	policyTitle = new UntypedFormControl("", [Validators.required]);
	globalDiv = new UntypedFormControl("");
	isSaving = false;
	isDuplicate = false;
	existingTitles: string[] = [];
	_subscriptions: Subscription[] = [];
	permissions: ISecurityPolicyPermission[] = [];
	createdId: string;

	formGroup = new UntypedFormGroup({
		policyTitle: this.policyTitle,
		globalDiv: this.globalDiv,
	});

	constructor(private readonly _modal: NgbActiveModal, private readonly _companyService: CompanyService) { }

	ngOnInit(): void {
		this.type = this.policyType === PolicyType.activityPolicy ? " Activity " : " Data ";

		const sub =
			this._companyService.getPolicies().pipe(
				tap((results) => {
					const policies = results.data;
					this.existingTitles = policies.filter(x => x.policyType === this.policyType).map(x => x.policyTitle);
				}
				)).subscribe();
		this._subscriptions.push(sub);

		if (this.isCopy) {
			this.isCreate = true;
			this.action = "Copy ";
			this.buttonText = "Create";
			this.formGroup.markAsDirty();
			const policySub = this._companyService.getPolicyPermissions(this.policy.securityPolicyID).subscribe((result) => {
				this.permissions = result;
			});
			this._subscriptions.push(policySub);
		}

		if (!this.policy) {
			this.isCreate = true;
			this.action = "Create New ";
			this.buttonText = "Create";
		} else {
			const policyTitle = this.isCopy ? "COPY -- " + this.policy.policyTitle : this.policy.policyTitle;
			this.policyTitle.setValue(policyTitle);
		}
	}

	cancel() {
		this._modal.close();
	}

	canSave() {
		return this.formGroup.valid && this.formGroup.dirty && !this.isDuplicate && !this.isSaving;
	}

	checkForError() {
		if (this.isDuplicate) {
			this.isDuplicate = false;
		}
	}

	save() {
		this.isSaving = true;
		if (this.policy?.policyTitle !== this.policyTitle.value) {
			const lowerCaseExistingRoles = this.existingTitles.map(v => v.toLowerCase().trim());
			if (lowerCaseExistingRoles.includes(this.policyTitle.value.toLowerCase().trim())) {
				this.isDuplicate = true;
				this.isSaving = false;
			}
		}
		if (!this.isDuplicate) {
			if (this.isCreate) {
				const createSub = this._companyService.createPolicy(this.policyTitle.value, this.policyType, this.globalDiv.value).pipe(
					switchMap((result) => {
						this.createdId = result;
						if (this.isCopy) {
							const permissionsToCopy = this.permissions.map(permission => this.createPermission(result, permission));
							return this._companyService.updatePolicyPermissions(result, permissionsToCopy);
						} else {
							return of(null);
						}
					})
				).subscribe(() => {
					this.onComplete.emit(this.createdId);
					this.isSaving = false;
				});
				this._subscriptions.push(createSub);
			} else {
				this._companyService.updatePolicy(this.policy.securityPolicyID, this.policyTitle.value, this.policyType, this.globalDiv.value).subscribe(() => {
					this.onComplete.emit();
					this.isSaving = false;
				}
				);
			}
		}
	}

	createPermission(securityPolicyId: string, permissionToCopy: ISecurityPolicyPermission): ISecurityPolicyPermission {
		let permission: ISecurityPolicyPermission = null;
		switch (this.policy.policyType) {
			case PolicyType.activityPolicy:
				permission = {
					permissionValue: permissionToCopy.permissionValue,
					charTypeID: permissionToCopy.charTypeID,
					templateGroupID: null,
					templateID: permissionToCopy.templateID,
					charGroupID: permissionToCopy.charGroupID,
					securityActivityID: permissionToCopy.securityActivityID,
					securityPolicyID: securityPolicyId,
					securityPolicyPermissionID: null,
					charID: null
				};
				this.permissions.push(permission);
				break;
			case PolicyType.dataPolicy:
				permission = {
					permissionValue: permissionToCopy.permissionValue,
					charTypeID: permissionToCopy.charTypeID,
					templateGroupID: permissionToCopy.templateGroupID,
					templateID: permissionToCopy.templateID,
					securityActivityID: permissionToCopy.securityActivityID,
					charGroupID: permissionToCopy.charGroupID,
					charID: permissionToCopy.charID,
					securityPolicyID: securityPolicyId,
					securityPolicyPermissionID: null
				};
				this.permissions.push(permission);
				break;
			default:
				break;
		}
		return permission;
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
