import { Injectable, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { flatten, pick } from "lodash";
import { ReadOnlyBehaviorSubject } from "rl-common/utils/rx.util";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { IAccountingOperationForm, IBuildAccountingProcessStatus } from "../accounting-processes.models";

@Injectable()
export class BuildAccountingProcessService implements OnDestroy {

	get status$(): ReadOnlyBehaviorSubject<IBuildAccountingProcessStatus> {
		return this._status$;
	}

	private readonly _status$ = new BehaviorSubject<IBuildAccountingProcessStatus>({ isDirty: false, isSaving: false, message: null });

	public readonly addAnotherOperation$ = new Subject<void>();

	private readonly _forms: { [key: string]: FormGroup<any> } = {};
	private _formSubs: Subscription[];

	unregisterForm(key: string) {
		if (key in this._forms) {
			delete this._forms[key];
		}

		this.setupFormSubs();

		this.emitStatus(this._status$.value);
	}

	registerForm(key: string, form: FormGroup<any>) {
		if (key in this._forms) {
			delete this._forms[key];
		}

		this._forms[key] = form;

		this.setupFormSubs();
	}

	private setupFormSubs() {
		this.unsubscribeFormSubs();

		const forms = Object.values(this._forms);

		this._formSubs = forms.map(x => {
			return x.valueChanges.subscribe(() => this.emitStatus(this._status$.value));
		});
	}

	private unsubscribeFormSubs() {
		if (this._formSubs) {
			this._formSubs.forEach(sub => sub.unsubscribe());
			this._formSubs = null;
		}
	}

	emitStatus(nextStatus: IBuildAccountingProcessStatus) {
		const forms = Object.values(this._forms);
		const isDirty = forms.some(form => form.dirty);
		const currentStatus = this.status$.value;
		nextStatus.isDirty = isDirty;
		const merged = { ...currentStatus, ...nextStatus };
		this._status$.next(merged);
	}

	getOtherAccountingProcessOperationDates(operationId: string) {
		const otherOperationKeys = Object.keys(this._forms).filter(k => k.includes("BuildAccountingOperationComponent") && !k.includes(operationId));
		const otherOperationForms = Object.values(pick(this._forms, otherOperationKeys)) as FormGroup<IAccountingOperationForm>[];
		return flatten(otherOperationForms.map(f => f.value.dates));
	}

	ngOnDestroy(): void {
		this.unsubscribeFormSubs();
	}

}
