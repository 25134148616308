<div class="modal-header">
	<h2 class="modal-title">Apply Relative Rights Profile: {{profile.title}}</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>

<div *ngIf="isLoading">
	<div class="modal-body">
		<p class="text-center">
			<rl-loader></rl-loader>
		</p>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" class="btn btn-light">Cancel</button>
	</div>
</div>
<div *ngIf="!isLoading">
	<aw-wizard [awNavigationMode] navigateBackward="deny" navigateForward="deny" [navBarLayout]="'small'"
		[disableNavigationBar]="true">
		<aw-wizard-step *ngIf="!isFromCatalogList" [stepTitle]="'Apply Relative Rights'">
			<div class="modal-body">
				<div class="alert alert-danger" *ngIf="hasInvalidRights">
					<div>
						One or more Rights templates from {{profile.title}} is not allowed on this Deal template.
					</div>
					<div *ngIf="!userCanCreateAllRightsOnRrp">
						Creation of one or more Rights templates not allowed by Data Policies.
					</div>
				</div>
				<rl-component-relationship *ngIf="!hasInvalidRights && rightsTemplateId && dataSource"
					[assocCharTypeId]="1" [dataSource]="dataSource" (onEntityRelationshipChange)="setSelected($event)">
				</rl-component-relationship>
			</div>
			<div class="modal-footer">
				<button class="btn btn-light" (click)="cancel()" [disabled]="isApplying">Cancel</button>
				<button class="btn btn-success ms-3" (click)="createRights()"
					[disabled]="!relationshipsSelected || isApplying">
					<i class="far fa-plus me-2" *ngIf="!isApplying"></i>
					<i class="far fa-spinner fa-spin me-2" *ngIf="isApplying"></i>
					Create
				</button>
			</div>
		</aw-wizard-step>
		<aw-wizard-step *ngIf="isFromCatalogList" [stepTitle]="'Apply Relative Rights'">
			<div class="modal-body">
				<div class="container w-100">
					<h4>Choose how to apply this Profile:</h4>
					<select class="form-select w-50" style="font-size:14px;" [(ngModel)]="selectedRelativeRightsOption">
						<option *ngFor="let opt of relativeRightsOptions" [value]="opt.value">
							{{opt.label}}</option>
					</select>
					<h4 class="mt-5">This Profile will be applied to the following Catalog items:</h4>
					<rl-chip *ngFor="let catalog of catalogsSelected" (onRemove)="remove(catalog)">
						{{catalog.title}}
					</rl-chip>
					<div class="alert alert-danger" *ngIf="allCatalogsRemoved">
						<div>
							No Catalog records selected.
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button (click)="cancel()" class="btn btn-light">Cancel</button>
				<button (click)="createRightsFromCatalogList()" [disabled]="allCatalogsRemoved"
					class="btn btn-success ms-3">Apply</button>
			</div>
		</aw-wizard-step>
		<aw-wizard-step [stepTitle]="'Complete'">
			<div class="modal-body">
				<div class="container w-100">
					<div class="d-flex">
						<div>
							<i class="far fa-check-circle fs-1 text-success"></i>
						</div>
						<div class="flex-grow-1 ms-4">
							<h4>The <strong>{{profile.title}}</strong> Relative Rights Profile is being applied.</h4>
							<p>You will receive an email notification once the job completes and all records have been
								made visible (re-indexed).<br>
								In the meantime, you can leave this page and continue working elsewhere.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-info" (click)="finish()">Close</button>
			</div>
		</aw-wizard-step>
	</aw-wizard>
</div>