import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import _ from "lodash";
import { CharTypeId } from "rl-common/consts";
import { IQueryNode } from "rl-common/models/i-query-node";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { IAdvancedSearchForm } from "../advanced-search-modal/advanced-search-models";
import { AdvancedSearch } from "../rl-advanced-search.consts";
import { QueryNodeRowComponent } from "./query-node-row/query-node-row.component";

export interface ISearchQueryChangedEvent {
	query: IQueryNode;
	description: string;
}

@Component({
	selector: "rl-advanced-search-builder",
	templateUrl: "./advanced-search-builder.component.html",
	styleUrls: ["./advanced-search-builder.component.scss"],
	imports: [NgIf, ReactiveFormsModule, NgFor, QueryNodeRowComponent]
})
export class AdvancedSearchBuilderComponent implements OnInit, OnDestroy {

	@Input()
	form: FormGroup<IAdvancedSearchForm>;

	@Input()
	parentCharTypeId: CharTypeId;

	@Input()
	parentTemplateId: number;

	@Input()
	charTypeId: CharTypeId;

	@Input()
	description: string;

	@ViewChildren(QueryNodeRowComponent)
	queryNodeRows: QueryList<QueryNodeRowComponent>;

	queryNodeStrategies: AdvancedSearch.IQueryNodeJoinStrategy[];

	private readonly _subscriptions: Subscription[] = [];

	get queryNodesFormArray() {
		return this.form.controls.queryNodeRows;
	}

	get queryIsEmpty() {
		return this.queryNodesFormArray && this.queryNodesFormArray.length === 0;
	}

	get queryNodeFormControls() {
		return this.queryNodesFormArray && this.queryNodesFormArray.controls;
	}

	get hasAvailableSelections() {
		return this.queryNodeStrategies?.length > 0;
	}

	constructor(
		private readonly _oneConfig: OneConfigService,
	) { }

	ngOnInit() {
		this.queryNodeStrategies = this.getQueryNodeStrategies();
		const sub = this.queryNodesFormArray.valueChanges.subscribe(() => {
			this.updateForm();
		});
		this._subscriptions.push(sub);
	}

	addNewRow() {
		this.queryNodesFormArray.push(new FormControl<IQueryNode>({}));
	}

	removeRow(index: number) {
		this.queryNodesFormArray.removeAt(index);
	}

	clearRows() {
		this.queryNodesFormArray.clear();
	}

	private getQueryNodeStrategies() {
		const parentCharTypes = new Set(this._oneConfig.getParentAssocCharTypeIds(this.charTypeId));
		const childCharTypes = new Set(this._oneConfig.getChildAssocCharTypeIds(this.charTypeId));
		const divParties = this._oneConfig.getDivParties()

		const templateParties = _(this._oneConfig.getTemplates(this.charTypeId))
			.flatMap((template) => this._oneConfig.getParties(this.charTypeId, template.templateID))
			.uniqBy(party => party.partyID)
			.value();

		const parties = divParties.filter(d => {
			return templateParties.some(t => {
				return t.partyID === d.partyID;
			});
		});

		return AdvancedSearch.getQueryNodeStrategies(this.charTypeId, parentCharTypes, childCharTypes, parties);
	}

	private updateForm() {
		const queryNodes = this.queryNodesFormArray.value;
		const queryNode: IQueryNode = {
			$and: queryNodes
		};
		const queryControl = this.form.controls.query;
		queryControl.setValue(queryNode);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
