import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IWidgetOptions } from "app/rightsline-app/components/dashboard/widget/widget.models";
import { ChipComponent } from "../chip/chip.component";
import { ISelectedWidget } from "./add-widget-modal.models";
import { SelectWidgetComponent } from "./select-widget/select-widget.component";


@Component({
	selector: "rl-add-widget-modal",
	templateUrl: "./add-widget-modal.component.html",
	styleUrls: ["./add-widget-modal.component.scss"],
	imports: [SelectWidgetComponent, NgFor, ChipComponent, NgIf]
})
export class AddWidgetModalComponent implements OnInit {

	@Input()
	currentWidgets: IWidgetOptions[];

	selectedWidgets: ISelectedWidget[] = [];

	@Output()
	onWidgetsSelected = new EventEmitter<IWidgetOptions[]>();

	constructor() { }

	ngOnInit(): void {
	}

	cancel() {
		this.onWidgetsSelected.emit([]);
	}

	removeReportWidget(reportId: string) {
		this.selectedWidgets = this.selectedWidgets.filter(x => x.widget.params.reportId !== reportId);
	}

	widgetSelected(selectedWidget: ISelectedWidget) {
		this.selectedWidgets = this.selectedWidgets.concat(selectedWidget);
	}

	addWidgets() {
		this.onWidgetsSelected.emit(this.selectedWidgets.map(x => x.widget));
	}

}
