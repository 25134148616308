import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbAccordionBody, NgbAccordionButton, NgbAccordionCollapse, NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbAccordionToggle, NgbCollapse, NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { CompanyModalService } from "company/services/company-modal.service";
import { IParty } from "rl-common/components/contacts/models/i-party";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { GridRowStyles } from "rl-common/components/grid/models/grid-row-styles";
import { CharTypeId, MaxInt } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { MultipleIndicator } from "rl-common/models/multiple-indicator";
import { AccountingProcessesService } from "rl-common/services/accounting-processes/accounting-processes.service";
import { AccountingProcessActiveIndicator } from "rl-common/services/accounting-processes/models/accounting-process-active-indicator";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { IAccountingProcessAccountGroupRule } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group-rule";
import { IAccountingProcessAccountResult } from "rl-common/services/accounting-processes/models/i-accounting-process-account-result";
import { ICreateAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-create-accounting-process-account";
import { ICreateAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-create-accounting-process-account-group";
import { IGroupSequenceNumber } from "rl-common/services/accounting-processes/models/i-group-sequence-number";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { SessionService } from "rl-common/services/session.service";
import { Observable, of, Subscription } from "rxjs";
import { catchError, distinctUntilChanged, filter, finalize, map, switchMap, take, tap } from "rxjs/operators";
import { FormTableControlDirective } from "../../../../common/components/form-table/form-table-control.directive";
import { FormTableGroupComponent } from "../../../../common/components/form-table/form-table-group/form-table-group.component";
import { FormTableRowComponent } from "../../../../common/components/form-table/form-table-row/form-table-row.component";
import { GridRowHoverOpsDirective } from "../../../../common/components/grid/directives/grid-row-hover-ops.directive";
import { GridTableComponent } from "../../../../common/components/grid/grid-table/grid-table.component";
import { TextInputComponent } from "../../../../common/components/text/text-input/text-input.component";
import { IsRlAdminDirective } from "../../../../common/directives/is-rl-admin.directive";
import { TrimWhitespacePipe } from "../../../../common/pipes/trim-whitespace.pipe";
import { AccountGroupRulesComponent } from "../account-group-rules/account-group-rules.component";

export interface ICoaForm {
	id: FormControl<string>;
	name: FormControl<string>;
	accountId: FormControl<string>;
	notes: FormControl<string>;
	xref: FormControl<string>;
}
export interface ICoaGroupForm {
	id: FormControl<string>;
	groupName: FormControl<string>;
	dealTemplateId: FormControl<number>;
	partyId: FormControl<number>;
	defaultAccountId: FormControl<string>;
}

export const enum COAFormTypes {
	Account = "account",
	AccountGroup = "account-group"
}

export enum COAFormTypesIndex {
	Account = 1,
	AccountGroup = 2
}

@Component({
	selector: "rl-chart-of-accounts",
	templateUrl: "./chart-of-accounts.component.html",
	styleUrls: ["./chart-of-accounts.component.scss"],
	imports: [ReactiveFormsModule, FormsModule, NgFor, NgClass, NgIf, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, GridTableComponent, GridRowHoverOpsDirective, IsRlAdminDirective, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, FormTableRowComponent, FormTableControlDirective, TextInputComponent, FormTableGroupComponent, AccountGroupRulesComponent, NgbNavOutlet, TrimWhitespacePipe]
})
export class ChartOfAccountsComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	contact: IAccountingContact;

	@Input()
	contactsAreLoading: boolean;

	@Output()
	onAccountsLoad = new EventEmitter<IAccountingProcessAccountResult[]>();

	@ViewChild("accordion")
	accordion: NgbAccordionDirective;

	@ViewChildren(AccountGroupRulesComponent)
	accountGroupRules: QueryList<AccountGroupRulesComponent>;

	formType: string = COAFormTypes.Account;
	coaOptions = [
		{
			id: COAFormTypes.Account,
			value: "Account"
		},
		{
			id: COAFormTypes.AccountGroup,
			value: "Account Group"
		}
	];

	private readonly columns: GridColumn<IAccountingProcessAccountResult>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "uuid",
			width: "auto",
			getCellData: (x) => x.account.id
		},
		{
			key: "name",
			headerName: "Account Name",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.account.name
		},
		{
			key: "accountId",
			headerName: "Account ID",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.account.accountId
		},
		{
			key: "xref",
			headerName: "XREF",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.account.xref
		},
		{
			key: "notes",
			headerName: "Account Notes",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.account.notes
		},
		{
			key: "alliantAccountGuid",
			headerName: "Synced Account",
			renderer: "uuid",
			width: "auto",
			getCellData: (x) => x.account.alliantAccountGuid
		},
		{
			key: "alliantContactGuid",
			headerName: "Synced Contact",
			renderer: "uuid",
			width: "auto",
			getCellData: (x) => x.account.alliantContactGuid
		},
		{
			key: "sequenceId",
			headerName: "Sequence ID",
			renderer: "text",
			width: "auto",
			getCellData: (x) => x.account.alliantSequenceId
		},
		{
			key: "syncResult",
			headerName: "Sync Result",
			renderer: "text",
			width: "300px",
			getCellData: (x) => x.account.syncResult
		},
	];

	gridOptions: GridOptions<any> = {
		defaultGetCellDataFn: (column, doc) => doc
	};

	accountsDataSource: CommonGridDataSource<IAccountingProcessAccountResult> = this._gridDataSourceBuilder.commonGridDataSource<IAccountingProcessAccountResult>(row => row.account.id)
		.setPaging({ pageSize: MaxInt.Num })
		.setColumns(this.columns)
		.withGetRowStylesFn(row => {
			if (row.account.activeIndicator !== this.activeIndicator) {
				return of([GridRowStyles.Invalid]);
			}
			return of([]);
		})
		.withFetchFn((ds) => this._accountingProcessesService.getAccounts(this.contact.recordId).pipe(
			tap((results) => {
				this.defaultAccounts = results.map(r => [r.account.id, r.account.name]);
			}),
			map((results) => ({ rowCount: results.length, rowData: results } as IGridFetchResults<IAccountingProcessAccountResult>))));

	accountsForm: FormGroup<ICoaForm>;
	accountGroupsForm: FormGroup<ICoaGroupForm>;

	get isEditingExistingAccount() {
		return !!this.accountsForm?.controls?.id?.value;
	}

	get isEditingExistingAccountGroup() {
		return !!this.accountGroupsForm?.controls?.id?.value;
	}

	get formBorderClass() {
		if (this.isEditingExistingAccount) {
			return `warning`;
		}
		return ``;
	}

	isSubmitting = false;
	isDeleting = new Set<string>();
	isDeletingGroup = new Set<string>();
	isRlAdmin = false;
	activeIndicator = AccountingProcessActiveIndicator.Active;
	account: IAccountingProcessAccount;
	accountGroup: IAccountingProcessAccountGroup;
	accountGroups: IAccountingProcessAccountGroup[] = [];
	sortedAccountGroups: IAccountingProcessAccountGroup[] = [];

	coaFormTypesIndex = COAFormTypesIndex;
	allDealTemplates: ICharacteristicTemplate[] = [];
	selectedDealPartyCollection: IParty[] = [];
	dealPartyCollection: { [dealTemplateId: number]: IParty[]; } = {};
	defaultAccounts: [string, string][] = []; //[ID, NAME]

	private readonly _subscriptions: Subscription[] = [];

	loadAccountGroups$: Observable<IAccountingProcessAccountGroup[]>;

	activeTabId = 1;
	groupSequenceNumbers: IGroupSequenceNumber[] = [];

	constructor(
		private readonly _accountingProcessesService: AccountingProcessesService,
		private readonly _modalService: CompanyModalService,
		private readonly _injector: Injector,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly _formBuilder: FormBuilder,
		private readonly _oneConfig: OneConfigService,
		private readonly _growler: GrowlerService,
		private readonly _promptService: PromptService,
		private readonly _session: SessionService
	) { }

	ngOnInit(): void {
		this.allDealTemplates = this._oneConfig.getTemplates(CharTypeId.Transaction);
		this.buildAccountsForm();
		this.buildAccountGroupsForm();

		const rowDataSub = this.accountsDataSource.rowData$.pipe(
			filter(rowData => !!rowData)
		).subscribe((rowData) => {
			this.onAccountsLoad.next(rowData);
		});

		const isAdminSub = this._session.isRlAdmin$.pipe(
			distinctUntilChanged()
		).subscribe(isRlAdmin => {
			this.setIsRlAdmin(isRlAdmin);
		});

		this._subscriptions.push(rowDataSub, isAdminSub);
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.contact && changes.contact.previousValue?.recordId !== changes.contact.currentValue?.recordId) {
			this.refreshAccountsAndGroups();
			this.buildAccountsForm();
			this.buildAccountGroupsForm();
		}
	}

	refreshAccountsAndGroups() {
		this.loadAccounts();
		this.loadAccountGroups();
	}

	coaOptionChanged() {
		this.activeTabId = (this.formType === COAFormTypes.Account) ? COAFormTypesIndex.Account : COAFormTypesIndex.AccountGroup;
		this.clearForm();
	}

	coaTabChanged() {
		this.formType = this.activeTabId === this.coaFormTypesIndex.Account ? COAFormTypes.Account : COAFormTypes.AccountGroup;
		this.clearForm();
	}

	private clearForm() {
		if (this.formType === COAFormTypes.Account) {
			this.buildAccountsForm();
		} else if (this.formType === COAFormTypes.AccountGroup) {
			this.buildAccountGroupsForm();
		}
	}

	buildAccountsForm(account: IAccountingProcessAccount = null) {
		this.account = account;

		if (account) {
			this.accountsForm = this._formBuilder.group({
				id: new FormControl<string>(account.id, [Validators.required]),
				name: new FormControl<string>(account.name, [Validators.required, this.duplicateAccountNameValidator]),
				accountId: new FormControl<string>(account.accountId, [Validators.required]),
				notes: new FormControl<string>(account.notes),
				xref: new FormControl<string>(account.xref)
			});
		} else {
			this.accountsForm = this._formBuilder.group({
				id: new FormControl<string>(null),
				name: new FormControl<string>("", [Validators.required, this.duplicateAccountNameValidator]),
				accountId: new FormControl<string>(null, [Validators.required]),
				notes: new FormControl<string>(""),
				xref: new FormControl<string>("")
			});
		}
	}

	buildAccountGroupsForm(accountGroup: IAccountingProcessAccountGroup = null) {
		this.accountGroup = accountGroup;
		this.selectedDealPartyCollection = [];

		if (accountGroup) {
			this.accountGroupsForm = this._formBuilder.group({
				id: new FormControl<string>(accountGroup.id, [Validators.required]),
				groupName: new FormControl<string>(accountGroup.groupName, [Validators.required, this.duplicateAccountGroupNameValidator]),
				dealTemplateId: new FormControl<number>(accountGroup.dealTemplateId, [Validators.required]),
				partyId: new FormControl<number>(accountGroup.partyId, [Validators.required]),
				defaultAccountId: new FormControl<string>(accountGroup.defaultAccountId, [Validators.required])
			});
		} else {
			this.accountGroupsForm = this._formBuilder.group({
				id: new FormControl<string>(null),
				groupName: new FormControl<string>("", [Validators.required, this.duplicateAccountGroupNameValidator]),
				dealTemplateId: new FormControl<number>(null, [Validators.required]),
				partyId: new FormControl<number>(null, [Validators.required]),
				defaultAccountId: new FormControl<string>(null, [Validators.required])
			});
		}

		const dealTemplateChangedSub = this.accountGroupsForm.controls.dealTemplateId.valueChanges.subscribe(templateId => {
			if (templateId) {
				this.getSelectedDealPartyCollection(templateId);
			}
			this.accountGroupsForm.controls.partyId.setValue(null);
		});

		if (accountGroup?.dealTemplateId) {
			this.getSelectedDealPartyCollection(accountGroup.dealTemplateId);
		}

		this._subscriptions.push(dealTemplateChangedSub);
	}

	getSelectedDealPartyCollection(templateId: number) {
		if (!templateId) {
			return;
		}

		this.selectedDealPartyCollection = this._oneConfig.getParties(CharTypeId.Transaction, templateId).filter(p => p.multipleIndicator === MultipleIndicator.Singular);

		if (this.selectedDealPartyCollection.length === 0) {
			this.accountGroupsForm.controls.partyId.setValue(null);
		}
	}

	duplicateAccountNameValidator = (fc: FormControl<string>) => {
		if (!fc.value) {
			return null;
		}

		const currentAccountNames = this.accountsDataSource.rowData$.value.map(x => x.account.name.toLowerCase());
		const cleanedAccountName = fc.value.trim().toLowerCase();
		const isDuplicateWhenEditing = this.isEditingExistingAccount &&
			currentAccountNames.some(an => an === cleanedAccountName && an !== this.account?.name.trim().toLowerCase());
		const isDuplicateWhenCreating = !this.isEditingExistingAccount &&
			currentAccountNames.includes(cleanedAccountName);

		if (isDuplicateWhenEditing || isDuplicateWhenCreating) {
			return { "accountNameDuplicate": true };
		}

		return null;
	};

	duplicateAccountGroupNameValidator = (fc: FormControl<string>) => {
		if (!fc.value) {
			return null;
		}

		const currentAccountGroupNames = this.accountGroups.map(x => x.groupName.toLowerCase());
		const cleanedAccountGroupName = fc.value.trim().toLowerCase();
		const isDuplicateWhenEditing = this.isEditingExistingAccountGroup &&
			currentAccountGroupNames.some(an => an === cleanedAccountGroupName && an !== this.accountGroup.groupName.trim().toLowerCase());
		const isDuplicateWhenCreating = !this.isEditingExistingAccountGroup &&
			currentAccountGroupNames.includes(cleanedAccountGroupName);

		if (isDuplicateWhenEditing || isDuplicateWhenCreating) {
			return { "accountGroupNameDuplicate": true };
		}

		return null;
	};

	loadAccounts() {
		this.isDeleting.clear();
		const sub = this.accountsDataSource.fetchRows().subscribe();
		this._subscriptions.push(sub);
	}

	loadAccountGroups() {
		this.loadAccountGroups$ = this._accountingProcessesService.getAccountGroups(this.contact.recordId).pipe(
			tap(groups => {
				this.accountGroups = groups;
				this.sortAccountGroups();
			})
		);
		this._subscriptions.push(this.loadAccountGroups$.subscribe());
	}

	private sortAccountGroups() {
		this.sortedAccountGroups = this.accountGroups
			.map(g => ({
				...g,
				groupName: g.groupName.trim()
			}))
			.sort((a, b) => a.groupName.localeCompare(b.groupName))
			.map(g => ({
				...g,
				groupName: g.groupName
			}));
	}

	saveAccount() {
		this.isSubmitting = true;
		const account: ICreateAccountingProcessAccount = {
			id: this.accountsForm.controls.id.value,
			contactRecordId: this.contact.recordId,
			name: this.accountsForm.controls.name.value,
			accountId: this.accountsForm.controls.accountId.value,
			notes: this.accountsForm.controls.notes.value,
			xref: this.accountsForm.controls.xref.value
		};

		const sub = this._accountingProcessesService.upsertAccount(account).pipe(
			switchMap(() => this.accountsDataSource.fetchRows()),
			catchError(err => {
				const message = err?.error?.message ?? `There was an error updating account`;
				this._growler.error().growl(message);
				throw err;
			}),
			finalize(() => this.isSubmitting = false)
		).subscribe(() => {
			this.buildAccountsForm();
		});

		this._subscriptions.push(sub);
	}

	saveAccountGroup() {
		this.isSubmitting = true;
		const accountGroup: ICreateAccountingProcessAccountGroup = {
			id: this.accountGroupsForm.controls.id.value,
			groupName: this.accountGroupsForm.controls.groupName.value,
			dealTemplateId: this.accountGroupsForm.controls.dealTemplateId.value,
			partyId: this.accountGroupsForm.controls.partyId.value,
			defaultAccountId: this.accountGroupsForm.controls.defaultAccountId.value,
			contactId: this.contact.recordId
		};

		const sub = this._accountingProcessesService.upsertAccountGroup(accountGroup).pipe(
			switchMap(() => this.loadAccountGroups$),
			catchError(err => {
				const message = err?.error?.message ?? `There was an error updating account group`;
				this._growler.error().growl(message);
				throw err;
			}),
			finalize(() => this.isSubmitting = false)
		).subscribe(() => {
			this.buildAccountGroupsForm();
		});

		this._subscriptions.push(sub);
	}

	syncAccount(account: IAccountingProcessAccount) {
		const sub = this._accountingProcessesService.syncAccount(this.contact.recordId, account.id).pipe(
			switchMap((success) => this.accountsDataSource.fetchRows().pipe(map(() => success))),
			catchError(err => {
				const message = err?.error?.message ?? `There was an error synchronizing account`;
				this._growler.error().growl(message);
				throw err;
			}),
		).subscribe((success) => {
			if (success) {
				const message = `Account sync succeeded.`;
				this._growler.success().growl(message);
			} else {
				const message = `Account sync failed.`;
				this._growler.error().growl(message);
			}
		});

		this._subscriptions.push(sub);
	}

	isFormEmpty(form: FormGroup) {
		return Object.values(form.controls).every(control => {
			return control.value === null || control.value === "";
		});
	}

	editAccount(account: IAccountingProcessAccount, cancelDialog: TemplateRef<unknown>) {
		if (!this.accountsForm.dirty) {
			this.buildAccountsForm(account);
			return;
		}


		const isFormEmpty = this.isFormEmpty(this.accountsForm);

		if (!isFormEmpty) {
			const sub = this._promptService.confirm("Confirmation", cancelDialog)
				.subscribe(confirm => {
					if (confirm) {
						this.buildAccountsForm(account);
					}
				});

			this._subscriptions.push(sub);
		} else {
			this.buildAccountsForm(account);
		}
	}

	editGroup(group: IAccountingProcessAccountGroup, cancelDialog: TemplateRef<unknown>) {
		if (!this.accountGroupsForm.dirty) {
			this.buildAccountGroupsForm(group);
			return;
		}

		const isFormEmpty = this.isFormEmpty(this.accountGroupsForm);

		if (!isFormEmpty) {
			const sub = this._promptService.confirm("Confirmation", cancelDialog)
				.subscribe(confirm => {
					if (confirm) {
						this.buildAccountGroupsForm(group);
					}
				});

			this._subscriptions.push(sub);
		} else {
			this.buildAccountGroupsForm(group);
		}
	}

	editRule($event: { group: IAccountingProcessAccountGroup, rule: IAccountingProcessAccountGroupRule }) {
		this.addOrEditAccountGroupRule($event.group, $event.rule);
	}

	addOrEditAccountGroupRule(group: IAccountingProcessAccountGroup, accountGroupRule: IAccountingProcessAccountGroupRule = null) {
		const sub = this.accountsDataSource.rowData$.pipe(
			take(1),
			map(accountRows => {
				return { accounts: accountRows.map(a => a.account), sequence: accountGroupRule ? accountGroupRule.sequenceNumber : ++this.groupSequenceNumbers.find(grc => grc.groupId === group.id).maxSequenceNumber };
			}),
			switchMap(accountsAndSequence => this._modalService.buildAccountGroupRule(accountsAndSequence.accounts, group, accountGroupRule, accountsAndSequence.sequence, this._injector).pipe(
				map(accountGroupRuleResult => {
					return accountGroupRuleResult;
				})
			)),
			filter(accountGroupRuleResult => !!accountGroupRuleResult),
			switchMap(accountGroupRuleResult => this._accountingProcessesService.upsertAccountGroupRule(accountGroupRuleResult.accountGroupRule).pipe(
				tap(id => {
					if (id) {
						const message = accountGroupRule ? `Account Group Rule successfully updated` : `Account Group Rule added to group: "${group.groupName}"`;
						this._growler.success().growl(message);
						const childRulesComponent = this.accountGroupRules.find(acr => acr.groupId === group.id);
						childRulesComponent.loadRules();
					}
				}),
				catchError(err => {
					const message = err?.error?.message ?? `Error adding/editing Account Group Rule`;
					this._growler.error().growl(message);
					throw err;
				})
			))
		).subscribe();

		this._subscriptions.push(sub);
	}

	setMaxGroupSequenceNumber(event: IGroupSequenceNumber) {
		const groupSequenceNumber = this.groupSequenceNumbers.find(g => g.groupId === event.groupId);
		if (groupSequenceNumber) {
			groupSequenceNumber.maxSequenceNumber = event.maxSequenceNumber;
		} else {
			this.groupSequenceNumbers.push(event);
		}
	}

	deleteAccount(accountResult: IAccountingProcessAccountResult, confirmDeleteDialog: TemplateRef<unknown>) {
		if (accountResult.creditRefs.length > 0 || accountResult.debitRefs.length > 0) {
			const message = `${accountResult.account.name} cannot be deleted because it is referenced by one or more accounting processes.`;
			this._promptService.alert("Cannot Delete Account", message);
		} else if (accountResult.account.alliantAccountGuid) {
			const message = `${accountResult.account.name} cannot be deleted because it has been synchronized with Alliant.`;
			this._promptService.alert("Cannot Delete Account", message);
		} else {
			const sub = this._promptService.confirm("Confirmation", confirmDeleteDialog, accountResult.account).pipe(
				filter(confirm => confirm),
				tap(() => this.isDeleting.add(accountResult.account.id)),
				switchMap(() => this._accountingProcessesService.deleteAccountingProcessAccount(accountResult.account.id))
			).subscribe(() => this.loadAccounts());
			this._subscriptions.push(sub);
		}
	}

	deleteGroup(group: IAccountingProcessAccountGroup, confirmDeleteDialog: TemplateRef<unknown>) {
		if (group.isAssociated) {
			const message = `${group.groupName} cannot be deleted because it is referenced by one or more accounting processes.`;
			this._promptService.alert("Cannot Delete Account Group", message);
		} else {
			const sub = this._promptService.confirm("Confirmation", confirmDeleteDialog, { group }).pipe(
				filter(confirm => confirm),
				tap(() => this.isDeleting.add(group.id)),
				switchMap(() => this._accountingProcessesService.deleteAccountGroup(group.id)),
				switchMap(() => this.loadAccountGroups$)
			).subscribe();
			this._subscriptions.push(sub);
		}
	}

	archiveAccount(accountResult: IAccountingProcessAccountResult, confirmArchiveDialog: TemplateRef<unknown>) {
		const sub = this._promptService.confirm("Confirmation", confirmArchiveDialog, accountResult.account).pipe(
			filter(confirm => confirm),
			tap(() => this.isDeleting.add(accountResult.account.id)),
			switchMap(() => this._accountingProcessesService.archiveAccountingProcessAccount(accountResult.account.id))
		).subscribe(() => this.loadAccounts());
		this._subscriptions.push(sub);
	}

	cancelAccount(cancelDialog: TemplateRef<unknown>) {
		if (!this.accountsForm.dirty) {
			this.buildAccountsForm();
			return;
		}

		const sub = this._promptService.confirm("Confirmation", cancelDialog)
			.subscribe(confirm => {
				if (confirm) {
					this.buildAccountsForm();
				}
			});

		this._subscriptions.push(sub);
	}

	private clearPartiesValues() {
		this.accountGroupsForm.controls.partyId.setValue(null);
		this.selectedDealPartyCollection = [];
	}

	cancelGroup(cancelGroupDialog: TemplateRef<unknown>) {
		if (!this.accountGroupsForm.dirty) {
			this.clearPartiesValues();
			this.buildAccountGroupsForm();
			return;
		}

		const sub = this._promptService.confirm("Confirmation", cancelGroupDialog)
			.subscribe(confirm => {
				if (confirm) {
					this.clearPartiesValues();
					this.buildAccountGroupsForm();
				}
			});

		this._subscriptions.push(sub);
	}

	setIsRlAdmin(isRlAdmin: boolean) {
		this.isRlAdmin = isRlAdmin;
		const adminOnlyColumns = new Set<string | number>(["id", "xref", "alliantAccountGuid", "alliantContactGuid", "sequenceId", "syncResult"]);
		const columns = this.columns.filter(col => isRlAdmin || !adminOnlyColumns.has(col.key));
		this.accountsDataSource.setColumns(columns);
	}

	getDealTemplateDisplayText(templateId: number) {
		const template = this.allDealTemplates?.find(t => t.templateID === templateId);
		if (!template) {
			return "";
		}
		return template.templateID + " - " + template.templateName;
	}

	getPartyDisplayText(dealTemplateId: number, partyId: number) {
		let dealParties = this.dealPartyCollection[dealTemplateId];
		if (!dealParties) {
			dealParties = this._oneConfig.getParties(CharTypeId.Transaction, dealTemplateId);
			this.dealPartyCollection[dealTemplateId] = dealParties;
		}
		const party = dealParties.find(p => p.partyID === partyId);
		return party ? partyId + " - " + party.partyName : partyId + " - Party Not Found";
	}

	getDefaultAccountName(id: string) {
		if (this.defaultAccounts.length > 0) {
			const defaultAccount = this.defaultAccounts?.find(da => da[0] === id);
			if (defaultAccount) {
				return defaultAccount[1];
			}
		}
		return "";
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

}
