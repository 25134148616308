<div class="modal-header">
	<h2 class="modal-title">{{modalTitle}}</h2>
	<button type="button" class="btn btn-close" (click)="close()" aria-label="Close">
		<i class="far fa-times"></i>
	</button>
</div>
<div>
	<rl-new-association [charTypeId]="charTypeId" [parentAssociations]="associatedRecords"
		[parentTemplateId]="parentTemplateId" [shouldSave]="false" [parentTitle]="parentTitle" [isMultiple]="true"
		[includeCurrentAssociations]="true" [direction]="relationshipType"
		(onCancel)="close()" (onApply)="revokeOrGrantAccess($event)">
	</rl-new-association>
</div>