<div class="border-top my-3 pt-3">
	<h4>Selected {{charTypeId | pluralCharTypeName}}</h4>
	<div *ngIf="selectedInputList.length === 0 && isEmpty$ | async">
		No {{charTypeId | pluralCharTypeName}} selected.
	</div>
	<div class="all-chips">
		<rl-chips [idFn]="gridChipIdFn" [displayFn]="gridChipDisplayFn" [selectedCount]="selectedCount$ | async"
			[selectedState]="dataSource.dataSelectStrategy.selectStateChange$ | async"
			(onDeselected)="deselect($event)">

		</rl-chips>
		<rl-chips [idFn]="inputListChipIdFn" [displayFn]="inputListChipDisplayFn"
			[selectedCount]="selectedInputList.length" [records]="selectedInputList"
			(onDeselected)="deselectInputList($event)"></rl-chips>
	</div>
</div>