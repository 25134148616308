import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { range } from "lodash";
import { AccountService } from "rl-common/services/account/account.service";
import { SessionService } from "rl-common/services/session.service";
import { NgFor } from "@angular/common";
import { ProfilePictureComponent } from "../../profile-picture/profile-picture.component";

const emptyAvatar = "";

@Component({
    selector: "rl-select-avatar",
    templateUrl: "./select-avatar.component.html",
    styleUrls: ["./select-avatar.component.scss"],
    imports: [NgFor, ProfilePictureComponent]
})
export class SelectAvatarComponent implements OnInit {

	@Output()
	onSave = new EventEmitter<string>();

	@Output()
	onCancel = new EventEmitter();

	isSaving = false;

	selectedAvatar: string;

	avatars: string[] = [emptyAvatar];
	userName: string;

	constructor(private readonly _session: SessionService, private readonly _accountService: AccountService) { }

	ngOnInit() {
		this.userName = this._session.userName;
		this.selectedAvatar = this._session.profileAvatar || emptyAvatar;
		const rows = range(1, 18);
		const cols = range(1, 18);
		for (const row of rows) {
			for (const col of cols) {
				this.avatars.push(`avatar${row}-${col}`);
			}
		}
	}

	selectAvatar(avatar: string) {
		this.selectedAvatar = avatar;
	}

	saveAvatar() {
		this.isSaving = true;
		this._accountService.saveAvatar(this.selectedAvatar)
			.subscribe(() => {
				this.onSave.emit(this.selectedAvatar);
				this.isSaving = false;
			});
	}

	cancel() {
		this.onCancel.emit();
	}
}
