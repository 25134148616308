import { Pipe, PipeTransform } from "@angular/core";
import { VisibilityIndicator } from "rl-common/consts";
import { VisibilityIndicatorUtil } from "rl-common/utils/visibility-indicator-id.util";

@Pipe({ name: "visibilityIndicatorName" })
export class VisibilityIndicatorPipe implements PipeTransform {
	transform(value: VisibilityIndicator, ...args: unknown[]): unknown {
		return VisibilityIndicatorUtil.toDisplayName(value);
	}
}
