import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ISecurityPolicy, IUserRole } from "rl-common/services/company/company.models";
import { UserService } from "rl-common/services/user/user.service";
import { combineLatest } from "rxjs";
import { finalize, take } from "rxjs/operators";
import { NgFor } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@Component({
    selector: "rl-select-identity-roles",
    templateUrl: "./select-identity-roles.component.html",
    styleUrls: ["./select-identity-roles.component.scss"],
    imports: [NgFor, ReactiveFormsModule, FormsModule]
})
export class SelectIdentityRolesComponent implements OnInit {

	identityRoles: IUserRole[] = [];
	selectedRoles: Set<string> = new Set<string>();
	policies: ISecurityPolicy[] = [];
	rolesLoaded = false;

	@Output()
	onChange = new EventEmitter<string[]>();

	constructor(
		private readonly _userService: UserService,
	) { }

	getRoles() {
		combineLatest([this._userService.getRoles(), this._userService.getPolicies()]).pipe(
			finalize(() => {
				this.rolesLoaded = true;
			}),
			take(1)
		).subscribe(
			([roles, policies]) => {
				this.identityRoles = roles.identityRoles;
				this.policies = policies;
			});
	}

	toggleRole(role: IUserRole) {
		const roleId = role.roleID;
		if (this.selectedRoles.has(roleId)) {
			this.selectedRoles.delete(roleId);
		} else {
			this.selectedRoles.add(roleId);
		}
		const selected = Array.from(this.selectedRoles);
		this.onChange.next(selected);
	}

	getPolicyName(id: string) {
		return this.policies.find(x => x.securityPolicyID === id)?.policyTitle;
	}

	ngOnInit(): void {
		this.getRoles();
	}

}
