import { signal } from "@angular/core";
import { Subject } from "rxjs";
import { ISubgridStrategy, SubgridToggleEvent } from "./subgrid.strategy";

export class CommonSubgridStrategy<RowType> implements ISubgridStrategy<RowType> {
	rowIsOpen = signal(new Map());
	rowToggled$ = new Subject<SubgridToggleEvent<RowType>>();

	_showArrowFn: (rowPath: string, rowData: RowType) => boolean;

	constructor() {

	}

	showArrow(rowPath: string, rowData: RowType): boolean {
		if (this._showArrowFn == null) {
			throw new Error("Method not implemented.");
		}
		return this._showArrowFn(rowPath, rowData);
	}

	toggleOpen(rowPath: string, rowData: RowType, open: boolean) {
		this.rowToggled$.next({ rowData, rowPath, isOpen: open });
		this.rowIsOpen.update(map => {
			const map2 = new Map(map);
			map2.set(rowPath, open);
			return map2;
		})
	}

	withShowArrowFn(showArrowFn: (rowPath: string, rowData: RowType) => boolean): this {
		this._showArrowFn = showArrowFn;
		return this;
	}

	clearOpenMap(): void {
		this.rowIsOpen.set(new Map());
	}
}