import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CharTypeId } from "rl-common/consts";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { RelationshipTypes } from "rl-common/models/relationship-types";
import { AssociationService } from "rl-common/services/associations/association.service";
import { ISearchResponse } from "rl-common/services/search/search.models";
import { SearchService } from "rl-common/services/search/search.service";
import { Observable, Subscription } from "rxjs";
import { CharTypeNamePipe } from "../../../pipes/char-type-name.pipe";
import { ListJoinPipe } from "../../../pipes/list-join.pipe";
import { PluralCharTypeNamePipe } from "../../../pipes/plural-char-type-name.pipe";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { IRemoveAssociationsResult } from "./remove-associations-modal.component.models";


@Component({
	selector: "rl-remove-associations-modal",
	templateUrl: "./remove-associations-modal.component.html",
	styleUrls: ["./remove-associations-modal.component.scss"],
	imports: [NgSwitch, NgSwitchCase, NgIf, LoaderComponent, AsyncPipe, CharTypeNamePipe, PluralCharTypeNamePipe, ListJoinPipe]
})
export class RemoveAssociationsModalComponent implements OnInit, OnDestroy {

	@Input()
	parentCharTypeId: CharTypeId;

	@Input()
	parentRecordId: number;

	@Input()
	childCharTypeId: CharTypeId;

	@Input()
	direction: RelationshipTypes;

	@Input()
	childRecords: IRecordTitle[];

	@Input()
	parentTitle: string;

	@Input()
	removingParentAssociationFromChildModDetail: boolean;

	@Input()
	isDataBridged: boolean;

	@Output()
	onRemove = new EventEmitter<IRemoveAssociationsResult>();

	isRelatedToInvoices$: Observable<number[]>;
	relatedTables$: Observable<ISearchResponse>;

	get hasMultiple() {
		return this.childRecords?.length > 1;
	}

	get isRemovingCatalogFromDeal() {
		return this.parentCharTypeId === CharTypeId.Transaction && this.childCharTypeId === CharTypeId.Property;
	}

	get titles() {
		return this.removingParentAssociationFromChildModDetail ? [this.parentTitle] : this.childRecords?.map(x => x.title);
	}

	get childRecordIds() {
		return this.childRecords?.map(x => x.recordId);
	}

	private readonly _subscriptions: Subscription[] = [];

	constructor(
		private readonly _associationsService: AssociationService,
		private readonly _searchService: SearchService
	) { }

	ngOnInit(): void {
		this.isRelatedToInvoices$ = this._associationsService.getExistingCatalogItemsFromDealToAccountingDocs(this.parentRecordId, this.childRecordIds);
	}

	removeAssociations() {
		const sub = this._associationsService.deleteAssociations(
			this.parentCharTypeId,
			this.parentRecordId,
			this.childCharTypeId,
			this.childRecords.map(x => x.recordId),
			this.direction,
			this.isDataBridged
		).subscribe(result => {
			this.onRemove.emit({
				confirmed: true,
				jobId: result
			});
		});

		this._subscriptions.push(sub);
	}

	decline() {
		this.onRemove.next({ confirmed: false, jobId: null });
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
