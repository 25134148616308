import { Injectable, Injector } from "@angular/core";
import { AddContactAccountsModalAdapter } from "company/components/accounting-processes/add-contact-accounts-modal/add-contact-accounts-modal.adapter";
import { BuildAccountGroupRuleModalAdapter } from "company/components/accounting-processes/build-account-group-rule-modal/build-account-group-rule-modal.adapter";
import { BuildAccountingProcessModalAdapter } from "company/components/accounting-processes/build-accounting-process-modal/build-accounting-process-modal.adapter";
import { CopyAccountingProcessModalAdapter } from "company/components/accounting-processes/copy-accounting-process-modal/copy-accounting-process-modal.adapter";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IAccountingProcess } from "rl-common/services/accounting-processes/models/i-accounting-process";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { IAccountingProcessAccountGroupRule } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group-rule";

@Injectable({ providedIn: "root" })
export class CompanyModalService {

	constructor(
		private readonly _addContactAccountsModalAdapter: AddContactAccountsModalAdapter,
		private readonly _buildAccountingProcessModalAdapter: BuildAccountingProcessModalAdapter,
		private readonly _copyAccountingProcessModalAdapter: CopyAccountingProcessModalAdapter,
		private readonly _buildAccountGroupRuleModalAdapter: BuildAccountGroupRuleModalAdapter,

	) {
	}

	addContactAccounts(contacts: IAccountingContact[], injector: Injector) {
		return this._addContactAccountsModalAdapter.openModal(component => {
			component.existingContacts = contacts;
			return component.onComplete;
		}, { injector, backdrop: "static" });
	}

	buildAccountingProcess(selectedContact: IAccountingContact, injector: Injector, accountingProcessId: string = null, createOperation: boolean = false, editOperationId: string = null) {
		return this._buildAccountingProcessModalAdapter.openModal(component => {
			component.accountingProcessId = accountingProcessId;
			component.selectedContact = selectedContact;
			component.createOperation = createOperation;
			component.editOperationId = editOperationId;
			return component.onClose;
		}, { injector, backdrop: "static" });
	}

	copyAccountingProcess(currentContactId: number, selectedContact: IAccountingContact, accountingProcess: IAccountingProcess, injector: Injector) {
		return this._copyAccountingProcessModalAdapter.openModal(component => {
			component.currentContactId = currentContactId;
			component.selectedContact = selectedContact;
			component.accountingProcess = accountingProcess;
			return component.onComplete;
		}, { injector, backdrop: "static" });
	}

	buildAccountGroupRule(accounts: IAccountingProcessAccount[], accountGroup: IAccountingProcessAccountGroup, accountGroupRule: IAccountingProcessAccountGroupRule, maxSequenceNumber: number, injector: Injector) {
		return this._buildAccountGroupRuleModalAdapter.openModal(component => {
			component.accounts = accounts;
			component.accountGroup = accountGroup;
			component.accountGroupRule = accountGroupRule;
			component.maxSequenceNumber = maxSequenceNumber;
			return component.onClose;
		}, { injector, backdrop: "static" });
	}
}