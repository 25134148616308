import { ArchwizardModule } from "@achimha/angular-archwizard";
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { first, isEmpty } from "lodash";
import { CmdVisibilityService } from "rl-common/components/char-data/cmd-visibility.service";
import { CreateEntityCmdVisibilityService } from "rl-common/components/create-workflow/create-entity-cmd-visibility.service";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { CharTypeId, SortDirection } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IApplicationInfo, IInviteNewUserRequest } from "rl-common/services/company/company.models";
import { GrowlerService } from "rl-common/services/growler.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { SessionService } from "rl-common/services/session.service";
import { IFindRlUserResult, IUserEntityDocument } from "rl-common/services/user/user.models";
import { UserService } from "rl-common/services/user/user.service";
import { QueryUtil } from "rl-common/utils";
import { AclUtil } from "rl-common/utils/acl.util";
import { combineLatest, of, Subscription } from "rxjs";
import { catchError, filter, finalize } from "rxjs/operators";
import { CharDataTableComponent } from "../../../../common/components/char-data/char-data-table.component";
import { ErrorMessageUtil } from "../../../../common/components/char-data/elements/element-error-messages/element-error-messages.model";
import { rightslineEmailValidator } from "../../../../common/components/char-data/elements/element-validators";
import { ShowOnlyDropdownComponent } from "../../../../common/components/create-workflow/show-only-dropdown/show-only-dropdown.component";
import { DivSettingKey } from "../../../../common/services/settings/settings.models";
import { SettingsService } from "../../../../common/services/settings/settings.service";
import { SelectAppsComponent } from "../select-apps/select-apps.component";
import { SelectIdentityRolesComponent } from "../select-identity-roles/select-identity-roles.component";
import { SelectWfRolesComponent } from "../select-wf-roles/select-wf-roles.component";
import { ICreateNewUserWizardEvent } from "./create-new-user-wizard.models";

@Component({
    selector: "rl-create-new-user-wizard",
    templateUrl: "./create-new-user-wizard.component.html",
    styleUrls: ["./create-new-user-wizard.component.scss"],
    providers: [CmdVisibilityService, CreateEntityCmdVisibilityService],
    imports: [ArchwizardModule, ReactiveFormsModule, FormsModule, NgIf, NgSwitch, NgSwitchCase, NgFor, SelectAppsComponent, ShowOnlyDropdownComponent, CharDataTableComponent, SelectIdentityRolesComponent, SelectWfRolesComponent]
})
export class CreateNewUserWizardComponent implements OnInit, OnDestroy {

	@Output()
	onComplete = new EventEmitter<ICreateNewUserWizardEvent>();

	formGroup: UntypedFormGroup;
	rolesForm: UntypedFormGroup;

	selectedTemplate: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[] = [];
	templates: ICharacteristicTemplate[] = [];
	isSaving: boolean;
	enableContinue = false;
	userDetails: [ICharacteristicData[], ICharacteristicMetaDataCollection] = null;

	checkingAvailability = false;
	confirmedUsername: string = null;

	usernameAvailability: UserAvailabilityResult;

	userApps: IApplicationInfo[] = [];
	errorMessages = ErrorMessageUtil.errorsLookup;

	private readonly _subscriptions: Subscription[] = [];

	get username() {
		return this.usernameControl?.value as string;
	}

	get disableMessages() {
		return this.disableMessagesControl.value as boolean;
	}

	get usernameControl() {
		return this.formGroup?.get("username");
	}

	get usernameIsValid() {
		return this.usernameControl?.valid ?? false;
	}

	get usernameIsDirty() {
		return this.usernameControl?.dirty ?? false;
	}

	get usernameConfirmed() {
		return this.confirmedUsername === this.username;
	}

	get selectedUserApps() {
		const selectedAppUrlIds = this.appUrlIdsControl.value as number[] ?? [];
		return this.userApps.filter(x => selectedAppUrlIds.includes(x.appUrlID));
	}

	get templateIdControl() {
		return this.formGroup.get("templateId");
	}

	get appUrlIdsControl() {
		return this.formGroup.get("appUrlIds");
	}

	get identityRolesControl() {
		return this.formGroup.get("identityRoles");
	}

	get wfRolesControl() {
		return this.formGroup.get("wfRoles");
	}

	get disableMessagesControl() {
		return this.formGroup.get("disableMessages");
	}

	get showGiveExistingContactAccessButton() {
		return this.usernameAvailability == UserAvailabilityResult.UserNameAlreadyInUseByContact || this.usernameAvailability == UserAvailabilityResult.UserNameAlreadyInUseByMultipleContacts;
	}

	constructor(
		private readonly _oneConfigService: OneConfigService,
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _userService: UserService,
		private readonly _growler: GrowlerService,
		private readonly _session: SessionService,
		private readonly _settingsService: SettingsService,
		cmdVisibilityService: CmdVisibilityService,
		readonly createCmdVisibilityService: CreateEntityCmdVisibilityService
	) {
		cmdVisibilityService.setStrategy(createCmdVisibilityService);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	ngOnInit(): void {
		const userTemplates = this._oneConfigService.getTemplatesWhere(CharTypeId.User, cmd => cmd.tagLabel === "email");
		if (isEmpty(userTemplates)) {
			this._growler.error().growl(`No user templates with an 'email' characteristic`);
			this.close();
			return;
		}

		const acl = this._session.acls;
		this.templates = userTemplates.filter(template => AclUtil.hasCreateAccess(acl, template.acl));
		if (isEmpty(this.templates)) {
			this._growler.error().growl(`User does not have create access`);
			this.close();
			return;
		}

		const firstTemplate = first(this.templates);
		const usernameControl = new UntypedFormControl("", [Validators.required, Validators.email]);

		if (this._session.userName.endsWith("@rightsline.com")) {
			this._settingsService.getDivSetting(DivSettingKey.OverrideRightslineEmailValidation).toPromise()
				.then((sett) => {
					if (!sett || sett.value != new Date().toISOString().slice(0, 10)) {
						usernameControl.addValidators(rightslineEmailValidator(this._session.userName));
					}
				})
				.catch((err) => {
					usernameControl.addValidators(rightslineEmailValidator(this._session.userName));
				});
		}

		const appUrlIdsControl = new UntypedFormControl([], Validators.required);
		this.formGroup = this._formBuilder.group({
			username: usernameControl,
			templateId: new UntypedFormControl(firstTemplate.templateID, [Validators.required]),
			appUrlIds: appUrlIdsControl,
			identityRoles: new UntypedFormControl([], [Validators.required]),
			wfRoles: new UntypedFormControl([], [Validators.required]),
			disableMessages: new UntypedFormControl("")
		});

		const sub = this._userService.getApps().subscribe(apps => this.userApps = apps);
		this._subscriptions.push(sub);
		this.setSelectedTemplate();
	}

	checkAvailability() {
		this.checkingAvailability = true;
		this.confirmedUsername = null;
		this.usernameAvailability = null;

		const model: ISearchRequestModel = {
			query: QueryUtil.$eq(SearchFieldNames.User.lowercaseEmail, this.username.toLowerCase(), false),
			keywords: "",
			rows: 2,
			start: 0,
			sortDir: SortDirection.Ascending,
			sortField: "status_updated",
			facetFields: []
		};

		// TODO: move this into a dedicated endpoint with a dedicated proc
		const sub = combineLatest([
			this._userService.getUsers(model),
			this._userService.findRlUser(this.username)
		]).pipe(
			finalize(() => this.checkingAvailability = false)
		).subscribe(([searchResponse, findRlUserResult]) => {
			this.usernameAvailability = this.mapUserAvailabilityResult(findRlUserResult, searchResponse.documents);
			const confirmStates = new Set<UserAvailabilityResult>([
				UserAvailabilityResult.UsernameAvailable,
				UserAvailabilityResult.UserNameAlreadyInUseByContact,
				UserAvailabilityResult.UserNameAlreadyInUseByMultipleContacts
			]);
			if (confirmStates.has(this.usernameAvailability)) {
				this.confirmedUsername = this.username;
			}
		});
		this._subscriptions.push(sub);
	}

	private mapUserAvailabilityResult(findRlUserResult: IFindRlUserResult, contactsFound: IUserEntityDocument[]) {
		if (!!findRlUserResult.rlUserId && findRlUserResult.userApplications.find(x => x.divisionID == this._session.divId)) {
			// The aspnet user already exists and has access to the current div, nothing to do here
			return UserAvailabilityResult.UsernameUnavailable;
		}
		if (contactsFound.length == 1) {
			return UserAvailabilityResult.UserNameAlreadyInUseByContact;
		}
		if (contactsFound.length > 1) {
			return UserAvailabilityResult.UserNameAlreadyInUseByMultipleContacts;
		}
		return UserAvailabilityResult.UsernameAvailable;
	}

	setSelectedTemplate() {
		const templateId = +this.templateIdControl.value;
		this.selectedTemplate = this._oneConfigService.getTemplateMetaData(CharTypeId.User, templateId);
	}

	selectAppUrlIds(appUrlIds: number[]) {
		this.appUrlIdsControl.setValue(appUrlIds);
	}

	updateIdentityRoles(identityRoles: string[]) {
		this.identityRolesControl.setValue(identityRoles);
	}

	updateWfRoles(wfRoles: number[]) {
		this.wfRolesControl.setValue(wfRoles);
	}

	selectDisableAuditMessages(event) {
		this.disableMessagesControl.setValue(event.target.checked);
	}

	giveAccess() {
		this.onComplete.emit({ addExistingEmail: this.confirmedUsername.toLocaleLowerCase() });
	}

	inviteUser() {
		this.isSaving = true;
		const request: IInviteNewUserRequest = {
			email: this.formGroup.controls["username"].value,
			applicationUrlIds: this.appUrlIdsControl.value as number[],
			identityRoleIds: this.identityRolesControl.value as string[],
			workflowRoleIds: this.wfRolesControl.value as number[],
			templateId: this.selectedTemplate.templateID,
			charData: this.charData,
			disableMessages: this.disableMessages
		};
		const sub = this._userService.inviteNewUser(request).pipe(
			catchError(error => {
				this._growler.error().growl(error?.error?.message);
				return of(null);
			}),
			finalize(() => {
				this.isSaving = false;
			}),
			filter(userId => !!userId),
		).subscribe(userId => this.onComplete.next({ rlUserId: userId }));
		this._subscriptions.push(sub);
	}

	close() {
		this.onComplete.next(null);
	}
}

export enum UserAvailabilityResult {
	UsernameAvailable = "username_available",
	UsernameUnavailable = "username_unavailable",
	UserNameAlreadyInUseByContact = "username_already_in_use_by_contact",
	UserNameAlreadyInUseByMultipleContacts = "username_already_in_use_by_multiple",
}

export interface IUserAvailablityResult {
	contactsFound: IUserEntityDocument[];
	rlUserId: number;
}
