import { NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { HttpStatusCode } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { isEmpty } from "lodash";
import { usDomainRe } from "rl-common/consts";
import { TokenScope } from "rl-common/models/token-scope";
import { AccountService } from "rl-common/services/account/account.service";
import { JwtTokenAclsResponse } from "rl-common/services/authentication/authentication.models";
import { AuthenticationService } from "rl-common/services/authentication/authentication.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { ProgressService } from "rl-common/services/progress.service";
import { SessionService } from "rl-common/services/session.service";
import { StorageUtil } from "rl-common/services/storage.service";
import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { MaintenanceComponent } from "../../../common/components/maintenance/maintenance.component";
import { AutoFocusDirective } from "../../../common/directives/auto-focus.directive";
import { BusyButtonDirective } from "../../../common/directives/busy-button.directive";
import { NewTabInModalDirective } from "../../../common/directives/new-tab-in-modal.directive";
import { IzendaTokenService } from "../../../common/services/izenda-token.service";
import { KonamiDirective } from "../../directives/konami.directive";
import { ISelectRecentDivEvent, RecentDivsComponent } from "../header/profile-dropdown/recent-divs/recent-divs.component";
import { SelectDivControlComponent } from "../header/profile-dropdown/select-div-control/select-div-control.component";
import { IAppOption } from "./../header/profile-dropdown/profile-dropdown.models";
import { LoginStepComponent } from "./login-step/login-step.component";
import { IOauthError, LoginOptionsResponse, LoginSteps } from "./login.models";
import { SsoLoginButtonComponent } from "./sso-login-button/sso-login-button.component";

const lsKey = "pref_reg";
const filmtrackBrandId = "29368277775643";

@Component({
	selector: "rl-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	imports: [NgIf, NgSwitch, NgSwitchCase, LoginStepComponent, ReactiveFormsModule, FormsModule, AutoFocusDirective, BusyButtonDirective, NewTabInModalDirective, RouterLink, SsoLoginButtonComponent, SelectDivControlComponent, NgFor, RecentDivsComponent, MaintenanceComponent, KonamiDirective]
})
export class LoginComponent implements OnInit, OnDestroy {

	currentStep: LoginSteps = LoginSteps.LoginOptions;
	email: UntypedFormControl;
	password: UntypedFormControl;
	twoFactorToken: UntypedFormControl;
	rememberDevice: UntypedFormControl;
	chooseApp: UntypedFormControl;
	loginForm: UntypedFormGroup;
	loginOptions: LoginOptionsResponse = {} as LoginOptionsResponse;
	errorMessage: string;
	chooseApps: IAppOption[];

	typeAheadSelectedApp: IAppOption;

	isNavigating = false;

	zendeskReturnTo: string = null;

	isUSDomain = false;
	showAltRegions = false;

	private twoFactorRegex = /^[0-9]{6}$/;
	private tempToken: string;
	private _emailKey = "ux2_login_email";

	private _subs: Subscription[] = [];

	constructor(
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _authenticationService: AuthenticationService,
		private readonly _progressService: ProgressService,
		private readonly _sessionService: SessionService,
		private readonly _router: Router,
		private readonly _titleService: Title,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _linkHelper: LinkHelperService,
		private readonly _accountService: AccountService,
		private readonly _growlerService: GrowlerService,
		private readonly _izendaTokenService: IzendaTokenService) { }

	ngOnInit() {
		this.isUSDomain = usDomainRe.test(new URL(window.location.href).hostname);

		// see if we were redirected from a filmtrack zendesk auth attempt
		if (this._activatedRoute.snapshot.queryParamMap.get("brand_id") == filmtrackBrandId && this._activatedRoute.snapshot.queryParamMap.has("return_to")) {
			// just send to filmtrack landing page
			window.location.href = "https://filmtrack.com";
			return;
		}

		this._titleService.setTitle("Login to Rightsline");
		const navigateToReturnUrl$ = this._activatedRoute.queryParams.pipe(
			tap(() => this.isNavigating = true),
			switchMap((params) => {
				if (this.zendeskReturnTo != null) {
					// external redirect
					return this.createZendeskRedirectUrl$(this.zendeskReturnTo)
						.pipe(
							tap(zendeskUrl => window.location.href = zendeskUrl)
						);
				} else {
					const url = params["returnUrl"] || "/";
					return this._router.navigateByUrl(url);
				}
			}),
			catchError(e => {
				console.warn(`Could not navigate to url: '${e?.message}'`);
				this._router.navigate(this._linkHelper.home());
				return of(false);
			}),
			tap(() => this.isNavigating = false)
		);

		if (this._activatedRoute.snapshot.queryParamMap.has("return_to") && this._activatedRoute.snapshot.queryParamMap.has("brand_id")) {
			this.zendeskReturnTo = this._activatedRoute.snapshot.queryParamMap.get("return_to");
		}

		this.suggestAltRegions();

		const alreadyAuthenticated = this._sessionService.isAuthenticated;
		if (alreadyAuthenticated) {
			const sub = navigateToReturnUrl$.subscribe();
			this._subs.push(sub);
		} else {
			const sub = this._sessionService.sessionChange$.pipe(
				filter(() => this._sessionService.isAuthenticated),
				mergeMap(() => navigateToReturnUrl$)
			).subscribe();
			this._subs.push(sub);
		}

		const email = StorageUtil.get<string>(this._emailKey) ?? "";
		this.email = this._formBuilder.control(email, [Validators.required, Validators.email]);
		this.password = this._formBuilder.control("", Validators.required);
		this.twoFactorToken = this._formBuilder.control("", [Validators.required, Validators.pattern(this.twoFactorRegex)]);
		this.rememberDevice = this._formBuilder.control(false);
		this.chooseApp = this._formBuilder.control(null);

		this.loginForm = new UntypedFormGroup({
			email: this.email,
			password: this.password,
			chooseApp: this.chooseApp,
			twoFactorToken: this.twoFactorToken,
			rememberMe: this.rememberDevice
		});

		this.password.disable();
		this.twoFactorToken.disable();

		const maintenance = JSON.parse(localStorage.getItem("maintenance"));
		if (maintenance) {
			this.currentStep = LoginSteps.Maintenance;
		}

		if (!alreadyAuthenticated && !isEmpty(email)) {
			this.submitEmail(null);
		}
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	altRegionSelected(region: "EU" | "AU") {
		localStorage.setItem(lsKey, region);
		this.doRegionRedirect(region);
	}

	private doRegionRedirect(region: "EU" | "AU") {
		const redirectUrl = new URL(window.location.href);
		const hostname = redirectUrl.hostname;
		if (this.isUSDomain && region === "EU") {
			redirectUrl.hostname = hostname.replace(usDomainRe, "$1.eu");
			redirectUrl.port = "";
			redirectUrl.protocol = "https";
		} else if (this.isUSDomain && region === "AU") {
			redirectUrl.hostname = hostname.replace(usDomainRe, "$1.com.au");
			redirectUrl.port = "";
			redirectUrl.protocol = "https";
		}
		window.location.href = redirectUrl.href;
	}

	suggestAltRegions() {
		const currentUrl = new URL(window.location.href);
		const currentHostname = currentUrl.hostname;
		if (this.isUSDomain && !isEmpty(this.zendeskReturnTo)) {
			const prefRegion = localStorage.getItem(lsKey);
			if (prefRegion === "EU" || prefRegion === "AU") {
				this.doRegionRedirect(prefRegion);
				return;
			}
			this.showAltRegions = true;
		}
	}

	private createZendeskRedirectUrl$(returnToUrl: string): Observable<string> {
		return this._accountService.getSupportToken()
			.pipe(
				map(jwt => `https://rightsline.zendesk.com/access/jwt?jwt=${jwt}&return_to=${returnToUrl}`)
			);
	}

	public submitEmail($event: Event) {
		this.errorMessage = null;
		this._progressService.startProgress();
		StorageUtil.set(this._emailKey, this.email.value);
		const sub = this._authenticationService.getLoginOptions(this.email.value)
			.pipe(catchError(err => {
				if (err.status === HttpStatusCode.ServiceUnavailable) {
					this._sessionService.logout();
					this.currentStep = LoginSteps.Maintenance;
					this._progressService.clearProgress();
				}
				return throwError(err);
			}))
			.subscribe(response => {
				this.loginOptions = response;

				if (this.loginOptions.redirectToClassic) {
					window.location.assign(this._linkHelper.classic());
				}

				if (response.showPassword) {
					this.password.enable();
				}

				if (response.showPassword || response.showSSO) {
					this.currentStep = LoginSteps.EnterPassword;
				} else {
					this.errorMessage = "No login options available";
				}

				this._progressService.endProgress();
			});
		this._subs.push(sub);
	}

	public submitPassword($event: Event) {
		this.errorMessage = null;
		if (this.loginForm.valid) {
			this._progressService.startProgress();
			const sub = this._authenticationService.login(this.email.value, this.password.value)
				.subscribe((result) => {
					this.handleLoginResult(result);
				}, (errorResponse) => {
					this.handleErrorResponse(errorResponse);
				}, () => {
					this._progressService.endProgress();
				});
			this._subs.push(sub);
		}
	}

	public submitTwoFactor($event: Event) {
		this.errorMessage = null;
		if (this.loginForm.valid) {
			this._progressService.startProgress();
			const sub = this._authenticationService.twoFactorLogin(this.email.value, this.password.value, this.twoFactorToken.value, this.rememberDevice.value)
				.subscribe((result) => {
					this.handleLoginResult(result);
				}, (errorResponse) => {
					this.handleErrorResponse(errorResponse);
				}, () => {
					this._progressService.endProgress();
				});
			this._subs.push(sub);
		}
	}

	private handleErrorResponse(errorResponse: any) {
		this._progressService.endProgress();
		if (errorResponse.status === HttpStatusCode.ServiceUnavailable) {
			this.currentStep = LoginSteps.Maintenance;
			this._progressService.clearProgress();
			return;
		}

		const error = errorResponse?.error as IOauthError;
		const authErrors = ["invalid_grant", "locked_out"];
		this.errorMessage = error?.authErrorDescription;
		if (errorResponse.status === HttpStatusCode.BadRequest && authErrors.includes(error?.authError)) {
			this.errorMessage = error.authErrorDescription;
			this.currentStep = LoginSteps.EnterPassword;
			return throwError(errorResponse);
		}
	}

	private handleLoginResult(result: JwtTokenAclsResponse) {
		if (this._sessionService.assertScope(result.access_token, TokenScope.PasswordResetRequired)) {
			const resetToken = this._sessionService.getPasswordResetToken(result.access_token);
			this._router.navigate([`reset-password/${resetToken}`]);
		} else if (this._sessionService.assertScope(result.access_token, TokenScope.TwoFactorRequired)) {
			this._sessionService.setUserId(result.access_token);
			this.tempToken = result.access_token;
			this.twoFactorToken.enable();
			this.currentStep = LoginSteps.TwoFactor;
		} else if (this._sessionService.assertScope(result.access_token, TokenScope.ChooseApp)) {
			this._sessionService.setUserId(result.access_token);
			this.chooseApps = this._sessionService.getUserApps(result.access_token);
			this.tempToken = result.access_token;
			this.currentStep = LoginSteps.ChooseApp;
		} else {
			if (!result.refresh_token || isEmpty(result.refresh_token)) {
				console.warn("No refresh token was provided. ):");
			}
			this._sessionService.setSession(result.access_token, result.refresh_token, result.acls_token);
			this._subs.push(this._izendaTokenService.initialize().subscribe());
		}

		if (this.isUSDomain || window.location.hostname === "localhost") {
			// clear out this preference on US/local login
			localStorage.removeItem(lsKey);
		}
	}

	public chooseRecentApp(event: ISelectRecentDivEvent) {
		const divId = event.recentDiv.divId;
		const appUrlId = event.recentDiv.appUrlId;
		const selected = this.chooseApps.find(x => x.divId === divId && x.appUrlId === appUrlId);
		if (!selected) {
			console.error(`Recent divId: ${divId} and appUrl: ${appUrlId} not found in available apps.`);
		} else {
			this.chooseApp.patchValue(selected);
		}
	}

	public typeAheadChooseDiv() {
		if (!this.typeAheadSelectedApp) {
			return;
		}
		const divId = this.typeAheadSelectedApp.divId;
		const appUrlId = this.typeAheadSelectedApp.appUrlId;
		const selected = this.chooseApps.find(x => x.divId === divId && x.appUrlId === appUrlId);
		this.chooseApp.patchValue(selected);
	}

	public submitWithApp() {
		this.errorMessage = null;
		this._progressService.startProgress();
		const appValue: IAppOption = this.chooseApp.value;
		const divId = appValue.divId;
		const appUrlId = appValue.appUrlId;
		this.chooseApp.disable();

		const sub = this._authenticationService.loginWithDivIDAppUrlID(
			this.email.value,
			this.password.value,
			this.tempToken,
			divId,
			appUrlId)
			.subscribe((result) => {
				this._progressService.endProgress();
				this.handleLoginResult(result);
			}, (errorResponse) => {
				this.handleErrorResponse(errorResponse);
			});
		this._subs.push(sub);
	}

	public bypassMaintenance() {
		this.currentStep = LoginSteps.LoginOptions;
	}

}
