export enum DivAvailsActorState {
	Idle = 0, IncrRecalc = 1, FullRecalc = 2, Resync = 3
}

export enum DivAvailsStatus {
	Active = 0, Inactive = 1, Paused = 2, ConfigMismatch = 3
}

export interface DivAvailsInfoExt {
	divId: number;
	processedAt: string;
	dataUpdatedAt: string;
	status: DivAvailsStatus;
	calcStatus: DivAvailsActorState;
	progress: [number, number];
	configHash: number;
}
