import { ArchwizardModule } from "@achimha/angular-archwizard";
import { AsyncPipe, NgIf } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import { IContactPartyGroup } from "rl-common/components/contacts/models/i-contact-party-group";
import { IParty } from "rl-common/components/contacts/models/i-party";
import { EntitySearchComponent } from "rl-common/components/entities/entity-search/entity-search.component";
import { SearchOptions } from "rl-common/components/entities/entity-search/entity-search.models";
import { ISelectStateChangeEvent } from "rl-common/components/entities/entity-search/select-state-net-changes";
import { SearchOptionsFactory } from "rl-common/factories";
import { IEntityCharDataPair } from "rl-common/models/i-entity-char-data-pair";
import { IEntityId } from "rl-common/models/i-entity-id";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ContactService } from "rl-common/services/contact/contact.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { IPartyEmailAddress } from "rl-common/services/workflow/workflow.models";
import { WorkflowService } from "rl-common/services/workflow/workflow.service";
import { CharacteristicUtil, TagLabel } from "rl-common/utils/characteristic.util";
import { Observable, of, Subscription } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { PrependWithDirective } from "../../../char-data/directives/prepend-with.directive";
import { ListSelectorComponent } from "../../../list-selector/list-selector.component";
import { NumberInputComponent } from "../../../number-input/number-input.component";

export interface ISignatoryOption {
	recordId: number;
	title: string;
	email: string;
	partyNames: string;
	checked?: boolean;
	sequenceNumber?: number;
}

export interface IListChangedEvent<ISignatoryOption> {
	unSelectedList: ISignatoryOption[];
	selectedList: ISignatoryOption[];
	reorder: boolean;
}

@Component({
	selector: "rl-send-for-esignature",
	templateUrl: "./send-for-esignature.component.html",
	styleUrls: ["./send-for-esignature.component.scss"],
	imports: [ArchwizardModule, NgIf, EntitySearchComponent, NumberInputComponent, ReactiveFormsModule, FormsModule, PrependWithDirective, ListSelectorComponent, AsyncPipe]
})
export class SendForESignatureComponent implements OnInit, AfterViewInit, OnDestroy {

	unSelectedList: ISignatoryOption[] = [];
	selectedList: ISignatoryOption[] = [];

	unSelectedListCc: ISignatoryOption[] = [];
	selectedListCc: ISignatoryOption[] = [];

	enforceSigningOrder: boolean = false;
	unSelectedHeader: string = "Select or enter at least one Signatory";
	selectedHeader: string = "Selected Signatories";
	reorderHeader: string = "Enforce signing order";
	unselectedCcRecipientsHeader: string = "Select CC Recipients";
	selectedCcRecipientsHeader: string = "Selected CC Recipients";

	searchOptions: SearchOptions = {
		autoLoad: false
	} as SearchOptions;

	signatoryOptions: ISignatoryOption[] = [];
	signatoryOptionsCc: ISignatoryOption[] = [];

	signatories$: Observable<IEntityCharDataPair[]> = this._contactService.contactsPanel$
		.pipe(
			filter(data => !!data),
			map(response => {
				return Object.values(response.contacts);
			})
		);

	parties$: Observable<IParty[]> = this._contactService.contactsPanel$
		.pipe(
			filter(data => !!data),
			map(response => {
				return Object.values(response.parties);
			})
		);

	groupedParties$: Observable<IContactPartyGroup[]> = this._contactService.contactsPanel$
		.pipe(
			filter(data => !!data),
			map(response => {
				return Object.values(response.groupedParties);
			})
		);

	signatoryOptions$ = this.signatories$
		.pipe(
			map(signatories => {
				return signatories
					.filter(signatory => {
						const emailCharacteristic = CharacteristicUtil.getCharacteristic(TagLabel.Email, signatory.characteristics.templateMetaData, signatory.characteristics.characteristicDatas);

						return !!emailCharacteristic?.value;
					})
					.map(signatory => {
						const emailCharacteristic = CharacteristicUtil.getCharacteristic(TagLabel.Email, signatory.characteristics.templateMetaData, signatory.characteristics.characteristicDatas);

						const partyIds = [];
						const partyNames = [];

						this.groupedParties$.subscribe(groups => {
							groups.forEach(group => {
								group.contactParties.forEach(contactParty => {
									const contact = contactParty.contacts.find(c => c.recordId === signatory.entity.recordID);
									if (contact && partyIds.indexOf(contactParty.partyId) < 0) {
										partyIds.push(contactParty.partyId);
									}
								})
							})
						});

						this.parties$.subscribe(parties =>
							parties.filter(p =>
								partyIds.indexOf(p.partyID) >= 0 && partyNames.indexOf(p.partyName) < 0)
								.map(x => {
									partyNames.push(x.partyName)
								}));

						return {
							recordId: signatory.entity.recordID,
							title: signatory.entity.title,
							email: emailCharacteristic.value,
							checked: false,
							partyNames: partyNames ? partyNames.join(", ") : null
						};
					});
			})
		);
	searchOptions$ = this._modDetailService.entity$
		.pipe(
			filter(entityData => !!entityData?.entity),
			map(entityData => {
				const opts = SearchOptionsFactory.buildDocumentAssociationOptions(entityData.entity.charTypeID);
				opts.parentCharTypeId = this._modDetailService.entity.charTypeId;
				opts.parentRecordId = this._modDetailService.entity.recordId;
				return opts;
			})
		);

	@ViewChild(EntitySearchComponent)
	entitySearch: EntitySearchComponent;

	@Input()
	actionId: number;

	@Input()
	partiesOnly: boolean;

	selectedDocument: { recordID: number, title: string };
	email: string = "";
	ccEmail: string = "";
	emailRegex = /.+@.+\..+/;
	get isValidEmail(): boolean {
		if (this.email.length > 0) {
			return !!this.email.match(this.emailRegex);
		}
	}

	get isValidEmailCc(): boolean {
		if (this.ccEmail.length > 0) {
			return !!this.ccEmail.match(this.emailRegex) && this.selectedList.filter(f => f.email.toLocaleLowerCase() === this.ccEmail.toLocaleLowerCase()).length == 0;
		}
	}

	isSending: boolean;
	invalidEmails: boolean;
	entityId: IEntityId;
	private subs: Subscription[] = [];

	@Output()
	onJobStart = new EventEmitter<string>();

	get canSubmit() {
		const hasContacts = !!this.selectedList.length;

		return this.canContinue && hasContacts;
	}

	get canContinue() {
		const hasFile = !!this.selectedDocument;

		return hasFile;
	}

	expireDays: number = null;
	warnBeforeExpireDays: number = null;

	constructor(
		private readonly _modDetailService: ModDetailService,
		private readonly _contactService: ContactService,
		private readonly _workflowService: WorkflowService,
		private readonly _growlerService: GrowlerService,
		private readonly _modal: NgbActiveModal
	) { }

	ngOnInit() {
		const sub = this._modDetailService.entityId$
			.subscribe((entityId) => {
				this.entityId = entityId;
			});
		const sub2 = this.signatoryOptions$
			.subscribe(options => this.signatoryOptions = options);

		const sub3 = this.signatoryOptions$
			.subscribe(options => this.signatoryOptionsCc = options);

		this.subs.push(sub, sub2, sub3);

		this.unSelectedList = this.signatoryOptions;
		this.unSelectedListCc = this.signatoryOptionsCc;
	}
	ngAfterViewInit(): void {
		this.searchOptions$
			.subscribe(() => {
				this.entitySearch.search();
			});
	}

	submit() {
		this.isSending = true;
		const toEmails = this.selectedList.map(m => {

			const emailAddress: IPartyEmailAddress = {
				recordId: m.recordId,
				emailAddress: m.email
			};

			return emailAddress;
		});

		const ccEmails = this.selectedListCc.map(m => {

			const emailAddress: IPartyEmailAddress = {
				recordId: m.recordId,
				emailAddress: m.email
			};

			return emailAddress;
		});

		const sub = this._workflowService.sendForSignature(this.entityId, this.selectedDocument.recordID, toEmails, ccEmails, this.actionId, this.enforceSigningOrder, this.expireDays, this.warnBeforeExpireDays)
			.pipe(catchError(error => {
				this._growlerService.error().growl(error.error.exceptionMessage);
				return of(undefined);
			}))
			.subscribe((response) => {
				if (response) {
					this.onJobStart.emit(response.jobId);
				}
			});
		this.subs.push(sub);
	}

	setSelected(event: ISelectStateChangeEvent<number, IEntitySearchDoc>) {
		this.selectedDocument = Array.from(event.selected.selectedValues || [])[0];
	}

	addEmail() {
		const newItem: ISignatoryOption = {
			recordId: null,
			title: this.email,
			email: this.email,
			partyNames: null
		};
		this.selectedList.push(newItem);
	}

	addEmailCc() {
		const newItem: ISignatoryOption = {
			recordId: null,
			title: this.ccEmail,
			email: this.ccEmail,
			partyNames: null
		};
		this.selectedListCc.push(newItem);
	}

	ngOnDestroy() {
		this.subs.forEach(sub => sub.unsubscribe());
	}

	labelFn(unSelectedList: ISignatoryOption) {
		if (unSelectedList.partyNames && unSelectedList.title) {
			return unSelectedList.title + " - " + unSelectedList.partyNames;
		}

		return unSelectedList.title ?? unSelectedList.email;
	}

	keyFn(signatoryItem: ISignatoryOption): string {
		const col = signatoryItem;
		return `${col.recordId}_${col.email}`;
	}

	groupLabelFn(signatoryItem: ISignatoryOption): string {
		return null;
	}

	updateLists(event: IListChangedEvent<ISignatoryOption>) {
		this.selectedList = cloneDeep(event.selectedList);
		this.unSelectedListCc = this.signatoryOptionsCc;
		this.unSelectedListCc = this.unSelectedListCc.filter(u => this.selectedList.filter(s => s.recordId == u.recordId).length == 0);
		this.unSelectedListCc = this.unSelectedListCc.filter(u => this.selectedListCc.filter(s => s.recordId == u.recordId).length == 0);
		this.selectedListCc = this.selectedListCc.filter(u => this.selectedList.filter(s => s.recordId == u.recordId).length == 0);
		this.selectedList.forEach((c, i) => {
			c.sequenceNumber = i;
		});
		this.unSelectedList = event.unSelectedList.filter(r => r.recordId != null);
		this.enforceSigningOrder = event.reorder;
	}

	updateListsCc(event: IListChangedEvent<ISignatoryOption>) {
		this.selectedListCc = cloneDeep(event.selectedList);
		this.selectedListCc.forEach((c, i) => {
			c.sequenceNumber = i;
		});
		this.unSelectedListCc = event.unSelectedList.filter(r => r.recordId != null);
	}

	cancel() {
		this._modal.dismiss();
	}

	textValueChanged(event: number, field: string) {
		if (field === "expireDays") {
			this.expireDays = event;
		} else {
			this.warnBeforeExpireDays = event;
		}
	}

}
