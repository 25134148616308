import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { ICharacteristicType } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { BehaviorSubject, ReplaySubject, Subscription } from "rxjs";
import { filter, finalize, switchMap, take, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TemplateLandingService implements OnDestroy {

	charTypeTemplateIds$ = new ReplaySubject<[number, number]>(1);
	loadingTemplateMetaData$ = new BehaviorSubject<boolean>(true);
	templateMetaData$ = new ReplaySubject<ICharacteristicMetaDataCollection>(1);
	templateCharacteristicMetaDatas$ = new ReplaySubject<ICharacteristicMetaData[]>(1);
	templateGroups$ = new ReplaySubject<ITemplateCharacteristicGroup[]>(1);
	charData$ = new ReplaySubject<ICharacteristicType[]>(1);
	charTemplates$ = new ReplaySubject<ICharacteristicTemplate[]>();

	characteristicMetaDatas: ICharacteristicMetaData[];

	private readonly _subs: Subscription[] = [];

	constructor(
		private _route: ActivatedRoute,
		private _entityConfig: EntityConfigService) {

		let charTypeId;
		let templateId;
		const sub = this._route.params.pipe(
			filter(params => !!params && "charTypeId" in params && "templateId" in params),
			switchMap(params => {
				charTypeId = +params["charTypeId"];
				templateId = +params["templateId"];
				this.charTypeTemplateIds$.next([charTypeId, templateId]);
				return this.fetchTemplateMetaData$(charTypeId, templateId);
			})
		).subscribe();

		const sub2 = this._entityConfig.getCharacteristicTypes().pipe(
			tap(response => {
				const charType = response.filter(x => x.charTypeId === charTypeId);
				this.charData$.next(charType);
			})
		).subscribe();

		this._subs.push(sub, sub2);

	}

	fetchCharTemplates(charTypeId: number) {
		return this._entityConfig.getCharTemplates(charTypeId).pipe(
			tap(templates => this.charTemplates$.next(templates))
		);
	}

	private fetchTemplateMetaData$(charTypeId: number, templateId: number) {
		this.loadingTemplateMetaData$.next(true);
		return this._entityConfig.getTemplateMetaData(charTypeId, templateId).pipe(
			finalize(() => {
				this.loadingTemplateMetaData$.next(false);
			}),
			tap(metaData => {
				this.templateMetaData$.next(metaData);
				this.templateCharacteristicMetaDatas$.next(metaData.characteristicMetaDatas);
				this.templateGroups$.next(metaData.groups);
			})
		);
	}

	refresh$() {
		return this.charTypeTemplateIds$.pipe(
			take(1),
			switchMap(([charTypeId, templateId]) => this.fetchTemplateMetaData$(charTypeId, templateId))
		);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}
}
