import { AsyncPipe, NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { isEmpty } from "lodash";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { IComponentGridParams } from "rl-common/components/mod-details-layout/mod-layout-sections-params.models";
import { IPanelSection } from "rl-common/components/mod-details-layout/mod-layout.models";
import { CharTypeId, Tables } from "rl-common/consts";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { ComponentsGroupBy } from "rl-common/services/components/component-group.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SettingsService } from "rl-common/services/settings/settings.service";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { DropdownMenuComponent } from "../../../../../common/components/dropdown/dropdown-menu/dropdown-menu.component";
import { FormTableControlDirective } from "../../../../../common/components/form-table/form-table-control.directive";
import { FormTableLabelDirective } from "../../../../../common/components/form-table/form-table-label.directive";
import { FormTableRowComponent } from "../../../../../common/components/form-table/form-table-row/form-table-row.component";
import { RadioInputComponent } from "../../../../../common/components/radio-input/radio-input.component";
import { CharTypeNamePipe } from "../../../../../common/pipes/char-type-name.pipe";
import { PluralCharTypeNamePipe } from "../../../../../common/pipes/plural-char-type-name.pipe";


export interface ICharTypeOption {
	charTypeId: CharTypeId;
	featureKey?: FeatureKeys;
}
@Component({
	selector: "rl-build-component-grid-element",
	templateUrl: "./build-component-grid-element.component.html",
	styleUrls: ["./build-component-grid-element.component.scss"],
	imports: [FormTableRowComponent, FormTableLabelDirective, FormTableControlDirective, ReactiveFormsModule, FormsModule, NgFor, RadioInputComponent, DropdownMenuComponent, CharTypeNamePipe, PluralCharTypeNamePipe, AsyncPipe]
})
export class BuildComponentGridElementComponent implements OnInit, OnChanges {

	@Input()
	templateMetaData: ICharacteristicMetaDataCollection;

	@Input()
	section: IPanelSection<IComponentGridParams>;

	@Output()
	paramsChange = new EventEmitter<IComponentGridParams>();

	params: IComponentGridParams;

	allCharTypeOptions: ICharTypeOption[] = [
		{ charTypeId: CharTypeId.Right },
		{ charTypeId: CharTypeId.Usage },
		{ charTypeId: CharTypeId.Royalty },
		{ charTypeId: CharTypeId.ContractScope, featureKey: FeatureKeys.ContractScope }
	];
	availableCharTypeOptions$: Observable<ICharTypeOption[]>;

	templateOptions: DropdownOptions<ICharacteristicTemplate>;
	selectedTemplates: ICharacteristicTemplate[] = [];

	selectedComponentCharTypeId: number = null;
	groupByType: ComponentsGroupBy = ComponentsGroupBy.All;

	get showTemplateOptions() {
		return this.templateOptions && this.section.params.charTypeId === CharTypeId.Usage;
	}

	get showGroupByTypeOptions() {
		return this.section?.params?.charTypeId === CharTypeId.Right && this.selectedTemplates?.length !== 1;
	}

	constructor(
		private readonly _oneConfig: OneConfigService,
		private readonly _settingsService: SettingsService,
		private readonly _featureService: FeatureService
	) { }

	ngOnInit(): void {
		this.availableCharTypeOptions$ = this._settingsService.features$.pipe(
			take(1),
			map(features => {
				const enabledOptions = this.allCharTypeOptions.filter(opt => !opt.featureKey || this._featureService.isEnabled(opt.featureKey, features));
				return enabledOptions;
			})
		)
	}
	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.section && this.section.params) {
			this.params = this.section.params;
			this.selectedComponentCharTypeId = this.section.params.charTypeId;
			this.buildTemplateOptions(this.section.params.templateIds);
			this.groupByType = this.section.params.groupByType ?? ComponentsGroupBy.All;
		}
	}

	updateCharType() {
		const fieldSectionParams: IComponentGridParams = {
			charTypeId: this.selectedComponentCharTypeId
		};

		this.selectedTemplates = [];
		switch (this.selectedComponentCharTypeId) {
			case CharTypeId.Right:
				this.groupByType = ComponentsGroupBy.All;
				break;
			default:
				this.groupByType = ComponentsGroupBy.Template;
		}
		this.buildTemplateOptions();

		this.params = fieldSectionParams;
		this.paramsChange.emit(fieldSectionParams);
	}

	private buildTemplateOptions(templateIds: number[] = null) {
		// clear out the template options if no char type selected
		if (!this.selectedComponentCharTypeId) {
			this.templateOptions = null;
			return;
		}

		const childTemplateIds = this._oneConfig.getChildAssocTemplateIds(
			this.templateMetaData.charTypeID,
			this.templateMetaData.templateID,
			this.selectedComponentCharTypeId
		);

		const templates = this._oneConfig.getTemplates(this.selectedComponentCharTypeId)
			.filter(x => !Tables.financialSystemIndicators.includes(x.systemIndicator))
			.filter(x => childTemplateIds.includes(x.templateID));

		this.templateOptions = {
			items: templates,
			rowKeyFn: (tmd) => tmd.templateID,
			rowLabelFn: (tmd) => tmd.templateName,
			selectedLabelFn: (count) => {
				if (count === 0) {
					return `All Templates`
				}
				return `Select Templates (${count})`;
			}
		};

		if (templateIds) {
			this.selectedTemplates = templates.filter(x => templateIds.includes(x.templateID));
		}
	}

	updateTemplates() {
		const templateIds = this.selectedTemplates.map(x => x.templateID);
		if (isEmpty(templateIds)) {
			this.params.templateIds = undefined;
		} else {
			this.params.templateIds = templateIds;
		}
		this.paramsChange.emit(this.params);
	}

	updateGroupByType() {
		this.params.groupByType = this.groupByType;
		this.paramsChange.emit(this.params);
	}

}
