import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Provider } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { cloneDeep, flatten, isEmpty, isNull, isString, isUndefined, uniqBy } from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { CharTypeId } from "rl-common/consts";
import { APKeyValueOption } from "rl-common/models/ap-key-value-option";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ContactRuleType } from "rl-common/services/accounting-processes/models/contact-rule-type";
import { IAccountingProcessAccount } from "rl-common/services/accounting-processes/models/i-accounting-process-account";
import { IAccountingProcessAccountGroup } from "rl-common/services/accounting-processes/models/i-accounting-process-account-group";
import { ICreateAccountingProcessAccountGroupRule } from "rl-common/services/accounting-processes/models/i-create-accounting-process-account-group-rule";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SearchService } from "rl-common/services/search/search.service";
import { Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DropdownSingleComponent } from "../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { FormTableControlDirective } from "../../../../common/components/form-table/form-table-control.directive";
import { FormTableRowComponent } from "../../../../common/components/form-table/form-table-row/form-table-row.component";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { AccountingProcesses } from "../accounting-processes.consts";
import { APContactTypeAheadComponent, ITypeAheadSelectedEvent } from "../ap-contact-type-ahead/ap-contact-type-ahead.component";
import { BuildAccountGroupRuleModalAdapter, IBuildAccountGroupRuleModalComponent } from "./build-account-group-rule-modal.adapter";
import { IBuildAccountGroupRuleResult } from "./build-account-group-rule-modal.models";

export interface IAccountGroupRuleForm {
	/* This will need more work, but you can sort of see how the dropdowns are being set up in the form here */
	contactRuleType: FormControl<APKeyValueOption>;
	picklist: FormControl<ICharacteristicMetaData>;
	picklistValue: FormControl<APKeyValueOption>;
	contactRecord: FormControl<APKeyValueOption>;
	template: FormControl<ICharacteristicTemplate>;
	templateGroup: FormControl<APKeyValueOption>;
	account: FormControl<IAccountingProcessAccount>;
}

export interface IEditRule {
	picklist: ICharacteristicMetaData;
	picklistValue: APKeyValueOption;
	contactRecord: APKeyValueOption;
	template: ICharacteristicTemplate;
	templateGroup: APKeyValueOption;

}

@Component({
    selector: "rl-build-account-group-rule-modal",
    templateUrl: "./build-account-group-rule-modal.component.html",
    styleUrls: ["./build-account-group-rule-modal.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: "250ms ease-out"
                    }
                })
            ])
        ])
    ],
    imports: [FormTableRowComponent, FormTableControlDirective, DropdownSingleComponent, ReactiveFormsModule, APContactTypeAheadComponent, LoaderComponent]
})
export class BuildAccountGroupRuleModalComponent implements OnInit, OnDestroy, IBuildAccountGroupRuleModalComponent {

	@Input()
	accounts: IAccountingProcessAccount[];

	@Input()
	accountGroup: IAccountingProcessAccountGroup;

	@Input()
	accountGroupRule: ICreateAccountingProcessAccountGroupRule;

	@Input()
	maxSequenceNumber: number;

	@Output()
	onClose = new EventEmitter<IBuildAccountGroupRuleResult>();

	formReady: boolean = false;
	isNewAccountGroupRule: boolean;
	pageTitle: string;
	sequenceNumber: number;

	contactCharTypeId = CharTypeId.User;

	form: FormGroup<IAccountGroupRuleForm>;

	contactRuleTypeItems: APKeyValueOption[] = [
		{ id: ContactRuleType.FieldValue, value: "Field Value" },
		{ id: ContactRuleType.Record, value: "Record" },
		{ id: ContactRuleType.Template, value: "Template" },
		{ id: ContactRuleType.TemplateGroup, value: "Template Group" }
	];
	contactRuleTypeOptions: DropdownOptions<APKeyValueOption> = {
		items: this.contactRuleTypeItems,
		rowKeyFn: (type: APKeyValueOption) => type.id,
		rowLabelFn: (type: APKeyValueOption) => type.value
	};

	triggerPicklistItems: ICharacteristicMetaData[] = [];
	triggerPicklistOptions: DropdownOptions<ICharacteristicMetaData> = {
		items: this.triggerPicklistItems,
		rowKeyFn: (type: ICharacteristicMetaData) => type.charValueSetID,
		rowLabelFn: (type: ICharacteristicMetaData) => type.label
	};

	triggerPicklistValueItems: APKeyValueOption[] = [];
	triggerPicklistValueOptions: DropdownOptions<APKeyValueOption> = {
		items: this.triggerPicklistValueItems,
		rowKeyFn: (type: APKeyValueOption) => type.id,
		rowLabelFn: (type: APKeyValueOption) => type.value
	};

	triggerTemplateItems: ICharacteristicTemplate[] = [];
	triggerTemplateOptions: DropdownOptions<ICharacteristicTemplate> = {
		items: this.triggerTemplateItems,
		rowKeyFn: (template: ICharacteristicTemplate) => template.templateID,
		rowLabelFn: (template: ICharacteristicTemplate) => template.templateID + " - " + template.templateName
	};

	triggerTemplateGroupItems: APKeyValueOption[] = [];
	triggerTemplateGroupOptions: DropdownOptions<APKeyValueOption> = {
		items: this.triggerTemplateGroupItems,
		rowKeyFn: (type: APKeyValueOption) => type.id,
		rowLabelFn: (type: APKeyValueOption) => type.value
	};

	accountItems: IAccountingProcessAccount[] = [];
	accountOptions: DropdownOptions<IAccountingProcessAccount> = {
		items: this.accountItems,
		rowKeyFn: (type: IAccountingProcessAccount) => type.id,
		rowLabelFn: (type: IAccountingProcessAccount) => type.name
	};

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _oneConfig: OneConfigService,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _searchService: SearchService
	) { }

	ngOnInit(): void {
		this.isNewAccountGroupRule = !this.accountGroupRule?.groupRuleId;

		this.sequenceNumber = this.isNewAccountGroupRule ? this.maxSequenceNumber++ : this.accountGroupRule.sequenceNumber;

		if (this.isNewAccountGroupRule) {
			this.pageTitle = "Add New Rule to Account Group: <strong>" + this.accountGroup.groupName + "</strong>";
		} else {
			this.pageTitle = "Edit Rule on Account Group: <strong>" + this.accountGroup.groupName + "</strong>";
		}

		this.accountItems = this.accounts.sort((a, b) => a.name.localeCompare(b.name));
		this.accountOptions.items = this.accountItems;

		this.buildAccountGroupRuleForm();
	}

	private async buildAccountGroupRuleForm() {
		let contactRuleType: ContactRuleType = null;
		if (this.isNewAccountGroupRule) {
			this.form = this._fb.group<IAccountGroupRuleForm>({
				contactRuleType: this._fb.control(null, { validators: Validators.required }),
				picklist: this._fb.control(null),
				picklistValue: this._fb.control(null),
				contactRecord: this._fb.control(null),
				template: this._fb.control(null),
				templateGroup: this._fb.control(null),
				account: this._fb.control(null, { validators: Validators.required })
			}, { validators: [ruleFieldsRequiredValidator] });
		} else {
			const rule = cloneDeep(this.accountGroupRule);
			contactRuleType = rule.contactRuleType;

			const editRule = {
				picklist: null,
				picklistValue: null,
				contactRecord: null,
				template: null,
				templateGroup: null
			} as IEditRule;

			if (contactRuleType === ContactRuleType.FieldValue) {
				editRule.picklist = {
					charValueSetID: rule.charValueSetId,
					charValueSourceID: rule.charValueSourceId
				} as ICharacteristicMetaData;

				if (rule.characteristicValueId !== 0) {
					this.getTriggerPicklistValueItems(editRule.picklist);
				}

				editRule.picklistValue = rule.characteristicValueId === 0 ? null : { id: rule.characteristicValueId, value: null };
			}

			if (contactRuleType === ContactRuleType.Record) {
				editRule.contactRecord = rule.recordId === 0 ? null : { id: rule.recordId, value: await this.getContactName(rule.recordId) };
			}

			if (contactRuleType === ContactRuleType.Template) {
				editRule.template = { templateID: rule.templateId } as ICharacteristicTemplate;
			}

			if (contactRuleType === ContactRuleType.TemplateGroup) {
				editRule.templateGroup = rule.templateGroupId === 0 ? null : { id: rule.templateGroupId, value: null };
			}

			const account = this.accountItems.find(a => a.id === rule.accountId);

			this.form = this._fb.group<IAccountGroupRuleForm>({
				contactRuleType: this._fb.control({ id: rule.contactRuleType, value: null }, { validators: Validators.required }),
				picklist: this._fb.control(editRule.picklist),
				picklistValue: this._fb.control(editRule.picklistValue),
				contactRecord: this._fb.control(editRule.contactRecord),
				template: this._fb.control(editRule.template),
				templateGroup: this._fb.control(editRule.templateGroup),
				account: this._fb.control(account, { validators: Validators.required })
			}, { validators: [ruleFieldsRequiredValidator] });
		}

		const picklistSub = this.form.controls.picklist.valueChanges.subscribe(cmd => {
			this.getTriggerPicklistValueItems(cmd);
			this.form.controls.picklistValue.setValue(this.triggerPicklistValueItems[0]);
		});

		await this._entityConfigService.getPartyTemplates(CharTypeId.Transaction, this.accountGroup.dealTemplateId, this.accountGroup.partyId).pipe(
			map(entities => {
				return entities.map(e => this._oneConfig.getTemplate(e.charTypeId, e.templateId));
			}),
			map(templates => {
				const templateItems = templates.sort((a, b) => a.templateName.localeCompare(b.templateName));
				this.triggerTemplateItems = templateItems;
				this.triggerTemplateOptions.items = this.triggerTemplateItems;
				if (contactRuleType === ContactRuleType.Template) {
					this.formReady = true;
				}
				return templateItems;
			}),
			tap(templateItems => {
				const charCollection = templateItems.map(ti => this._oneConfig.getTemplateMetaData(CharTypeId.User, ti.templateID));
				this.triggerPicklistItems = uniqBy(flatten(charCollection.map(c => c.characteristicMetaDatas.filter(cmd => AccountingProcesses.CharDataValueMapping.isLov(cmd)))), "characteristicID").sort((a, b) => a.label.localeCompare(b.label));
				this.triggerPicklistOptions.items = this.triggerPicklistItems;

				if (contactRuleType === ContactRuleType.FieldValue) {
					this.formReady = true;
				}
			})
		).toPromise();

		await this._entityConfigService.getTemplateGroupList(CharTypeId.User).pipe(
			map(contactTemplateGroups => {
				return contactTemplateGroups.map(ctg => AccountingProcesses.KeyValueOptionMapping.fromTemplateGroup(ctg));
			}),
			tap(contactTemplateGroupKeyValueOptions => {
				this.triggerTemplateGroupItems = contactTemplateGroupKeyValueOptions.sort((a, b) => a.value.localeCompare(b.value));
				this.triggerTemplateGroupOptions.items = this.triggerTemplateGroupItems;
				if (contactRuleType === ContactRuleType.TemplateGroup) {
					this.formReady = true;
				}
			})
		).toPromise();

		if (this.isNewAccountGroupRule || contactRuleType === ContactRuleType.Record) {
			this.formReady = true;
		}

		this._subs.push(picklistSub);
	}

	getContactName(recordId: number) {
		return this._searchService.getTitles(CharTypeId.User, [recordId]).pipe(
			map(result => {
				return result.get(recordId);
			})
		).toPromise();
	}

	getTriggerPicklistValueItems(cmd: ICharacteristicMetaData) {
		this.triggerPicklistValueItems = this._oneConfig.getLovMetaData(cmd.charValueSourceID).listOfValues?.map(v => AccountingProcesses.KeyValueOptionMapping.fromCharacteristicMetaDataValue(v)).sort((a, b) => a.value.localeCompare(b.value));
		this.triggerPicklistValueOptions.items = this.triggerPicklistValueItems;
	}

	contactRecordTypeAheadAdd($event: ITypeAheadSelectedEvent) {
		this.form.controls.contactRecord.setValue({ id: $event.recordId, value: $event.title } as APKeyValueOption);
		this.form.markAsDirty();
	}

	clearContactRecord() {
		this.form.controls.contactRecord.setValue(null);
	}

	save() {
		if (!this.form.valid) {
			return;
		}
		// get account group rule from form
		const selectedContactRuleType = this.form.controls.contactRuleType.value.id as ContactRuleType;

		const baseRule: Partial<ICreateAccountingProcessAccountGroupRule> = {
			groupRuleId: this.accountGroupRule?.groupRuleId,
			groupId: this.accountGroup.id,
			sequenceNumber: this.isNewAccountGroupRule ? this.sequenceNumber : this.accountGroupRule.sequenceNumber,
			contactRuleType: this.form.controls.contactRuleType.value.id,
			accountId: this.form.controls.account.value.id
		};

		let additionalRule: Partial<ICreateAccountingProcessAccountGroupRule>;

		if (selectedContactRuleType === ContactRuleType.FieldValue) {
			additionalRule = {
				charValueSetId: this.form.controls.picklist.value.charValueSetID,
				charValueSourceId: this.form.controls.picklist.value.charValueSourceID,
				characteristicValueId: this.form.controls.picklistValue.value.id
			};
		}

		if (selectedContactRuleType === ContactRuleType.Record) {
			additionalRule = {
				recordId: this.form.controls.contactRecord.value.id
			};
		}

		if (selectedContactRuleType === ContactRuleType.Template) {
			additionalRule = {
				templateId: this.form.controls.template.value.templateID
			};
		}

		if (selectedContactRuleType === ContactRuleType.TemplateGroup) {
			additionalRule = {
				templateGroupId: this.form.controls.templateGroup.value.id
			};
		}

		this.accountGroupRule = { ...baseRule, ...additionalRule } as ICreateAccountingProcessAccountGroupRule;

		this.onClose.next({
			accountGroupRule: this.accountGroupRule
		});
	}

	close() {
		this.onClose.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}

export const ruleFieldsRequiredValidator: ValidatorFn = (form: FormGroup<IAccountGroupRuleForm>): ValidationErrors | null => {
	const contactRuleType = form?.controls?.contactRuleType?.value?.id as ContactRuleType;

	const requiredValues: unknown[] = [];
	switch (contactRuleType) {
		case ContactRuleType.FieldValue:
			requiredValues.push(form.controls.picklist.value);
			requiredValues.push(form.controls.picklistValue.value);
			break;
		case ContactRuleType.Record:
			requiredValues.push(form.controls.contactRecord.value);
			break;
		case ContactRuleType.Template:
			requiredValues.push(form.controls.template.value);
			break;
		case ContactRuleType.TemplateGroup:
			requiredValues.push(form.controls.templateGroup.value);
			break;
	}

	const missingValues = requiredValues.filter(val => isNull(val) || isUndefined(val) || (isString(val) && isEmpty(val)));
	if (!isEmpty(missingValues)) {
		return { requiredFieldsMissing: true };
	}

	return null;
};

export const BUILDACCOUNTGROUPRULE_MODAL_PROVIDER: Provider = {
  provide: BuildAccountGroupRuleModalAdapter,
  useFactory: () => new BuildAccountGroupRuleModalAdapter(BuildAccountGroupRuleModalComponent)
};
