import { PortalModule } from "@angular/cdk/portal";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { enableProdMode, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, Router, withRouterConfig } from "@angular/router";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { NgbAlertConfig, NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular-ivy";
import { registerLocales } from "app/rightsline-app/rl-app.locales";
import { COMPANY_MODAL_PROVIDERS } from "company/company.providers";
import { CONFIG_PROVIDERS } from "config/config.providers";
import { NgxPendoModule } from "ngx-pendo";
import { RL_COMMON_PROVIDERS } from "rl-common/common.providers";
import { ConstUtils } from "rl-common/consts";
import { RLHttpInterceptor } from "rl-common/services/rl-http-interceptor.service";
import { SessionInitResult, SessionService } from "rl-common/services/session.service";
import { IVersionInfo } from "rl-common/services/version/version.models";
import { roaringLibraryInitialize } from "roaring-wasm";
import { Observable, of } from "rxjs";
import { timeoutWith } from "rxjs/operators";
import { PendoService } from "./app/common/services/pendo/pendo.service";
import { RLAppComponent } from "./app/rightsline-app/components/rl-app.component";
import { routes } from "./app/rightsline-app/rl-app.routing";
import { GlobalErrorHandler } from "./app/rightsline-app/utils/global-error-handler";
import version from "./assets/version.json";
import { environment } from "./environments/environment";

registerLocales();
const tokenRe = /\/sso\/([a-f0-9]{8}\-?[a-f0-9]{4}\-?[a-f0-9]{4}\-?[a-f0-9]{4}\-?[a-f0-9]{12})/i;

export function initializeApp(sessionService: SessionService, pendoService: PendoService) {
	return async (): Promise<any> => {
		if (tokenRe.exec(window.location.pathname) != null) {
			sessionService.clearSession();
		}

		const redirectToLogin = () => {
			const location = window.location;
			// prevent infinite loops
			if (location.pathname !== "/login") {
				location.href = `/login?returnUrl=${encodeURIComponent(location.pathname + location.search + location.hash)}`;
			}
		};

		if (window.BroadcastChannel !== undefined) {
			const refreshChannel = new BroadcastChannel("refresh");
			refreshChannel.onmessage = ($evt) => {
				window.location.reload();
			};
		}

		try {
			const initResult = await sessionService.initialize();
			switch (initResult) {
				case SessionInitResult.NoSession:
					console.log("no session found");
					break;
				case SessionInitResult.InvalidSession:
					console.log("invalid existing session");
					sessionService.clearSession();
					redirectToLogin();
					break;
				case SessionInitResult.ValidSession:
					console.log("valid session");
					break;
			}
		} catch (err) {
			console.log(err);
			sessionService.clearSession();
		}

		// must do this to support the wasm built library
		await roaringLibraryInitialize();

		if (window["onRLAppLoad"]) {
			const obs = new Observable<void>((observer) => {
				window["onRLAppLoad"](() => {
					observer.next(null);
					observer.complete();
					console.timeEnd("Loading");
					try {
						const diff = Math.round((performance.now() - window["rlAppStartTime"]) / 1000);
						pendoService.trackEvent("Load Time", { message: `${diff} seconds` });
					} catch (error) {
						console.error(error);
					}
				});
			});
			// give callback up to 500ms to return
			await obs.pipe(timeoutWith(500, of(null))).toPromise();
		}
	};
}

const hostName = window.location.hostname;
const versionInfo: IVersionInfo = version;
const isLocal = ConstUtils.isLocal(hostName);
const releaseName = ConstUtils.getReleaseName(versionInfo, hostName);

let sampleRate = .05;
// We bump up the sample rate up for lowers because they don't get as much traffic
switch (environment.name) {
	case "dev":
	case "staging":
	case "qa":
		sampleRate = .1;
		break;
}

Sentry.init({
	enabled: !isLocal,
	dsn: "https://bff022cca553458796560e6a5b1b57d9@o1039064.ingest.sentry.io/6007786",
	integrations: [],
	sampleRate,
	release: releaseName,
	environment: environment.name,
	ignoreErrors: [
		/^Non-Error exception captured with keys.*/,
		/.*ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.*/,
		/.*pendo is not defined.*/,
		/.*Can't find variable: pendo.*/,
		/.*No error message.*/,
		/^Http failure response for .*: 503.*/, // Maintenance response code
		/^Http failure response for .*: 401 Unauthorized$/, // Unauthorized
		/^Http failure response for .*: 403.*/, // Forbidden
		/^Http failure response for .*: 404.*/, // Not Found
		/^Http failure response for .*: 428.*/, // Bad Config
		/^Http failure response for .*: 0 Unknown Error$/, // CORS error
		/^Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+$/,
		/Loading chunk [\d]+ failed/,
		/^Non-Error promise rejection captured with keys: abort, always, catch, done, fail$/,
		/^Error: FS-Cannot upload file part\s*$/, //  happens when FS upload is canceled
		/^unmountComponentAtNode\(\.\.\.\): Target container is not a DOM element\.$/, // react exception (izenda)
		/^Uncaught \(in promise\): OpenFailedError: InvalidStateError.*/,
		/^Internal error opening backing store for indexedDB\.open\..*/,
		/InvalidStateError: A mutation operation was attempted on a database that did not allow mutations\./,
		/InvalidStateError: Failed to execute 'abort' on 'IDBTransaction': The transaction has finished\./,
		/Can't create duplicate variable:/, // Safari shenanigans
		/^22P01: floating-point exception$/, // Postgres floating point exception (rights explorer)
		/^Method not implemented\./,
		/^ResizeObserver loop limit exceeded/, // https://github.com/WICG/resize-observer/issues/38
		/^53300: sorry, too many clients already/ // Postgres too many clients
	],
	denyUrls: [
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
	],
	maxBreadcrumbs: 20,
	// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
	beforeSend(event, hint) {
		try {
			const ignoredBrowsers = ["firefox"];
			const userAgent = navigator.userAgent.toLowerCase();
			const isIgnoredBrowser = ignoredBrowsers.filter(x => userAgent.includes(x)).length > 0;
			if (isIgnoredBrowser) {
				return null;
			}

			const ignoreFiles = [
				`/assets/izenda/izenda_ui.js`,
				`/assets/izenda/izenda_vendors.js`,
				`/pendo.js`,
				`/react/docs/error-decoder.html` // izenda uses react
			];

			const stackFrames = event.exception?.values[0]?.stacktrace?.frames ?? [];
			if (stackFrames.length > 0) {
				const firstFrame = stackFrames[0];
				const lastFrame = stackFrames[stackFrames.length - 1];

				// https://github.com/getsentry/sentry-javascript/issues/3147
				if (firstFrame?.filename === `<anonymous>`) {
					return null;
				}

				const fileName = lastFrame?.filename;
				if (fileName && ignoreFiles.some(x => fileName.endsWith(x))) {
					return null; // don't report this exception
				}
			}

		} catch (e) { } // It's fine to swallow here, since returning the event itself means the exception will be reported to Sentry

		return event;
	}
});

if (environment.production) {
	enableProdMode();
}

export function tokenGetter(): string {
	return localStorage.getItem("token");
}


export const THIRD_PARTY_SERVICES = [
	NgbAlertConfig,
	NgbPaginationConfig,
	JwtHelperService,
	// TreeListService,
	// PolicyListService,
	// UserListService,
	// PendoService
];

bootstrapApplication(RLAppComponent, {
	providers: [
		importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, PortalModule, NgxPendoModule.forRoot({
			pendoApiKey: "2caaa24c-28b3-4c68-5328-364d2d84ee95",
			pendoIdFormatter: (value: string) => value.toString().toLowerCase()
		})),
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		...RL_COMMON_PROVIDERS,
		...COMPANY_MODAL_PROVIDERS,
		...CONFIG_PROVIDERS,
		provideRouter(routes, withRouterConfig({ onSameUrlNavigation: "reload" })),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RLHttpInterceptor,
			multi: true
		},
		{
			provide: JWT_OPTIONS,
			useValue: {
				tokenGetter
			}
		},
		...THIRD_PARTY_SERVICES,
		provideAppInitializer(() => {
			const initializerFn = (initializeApp)(inject(SessionService), inject(PendoService));
			return initializerFn();
		})
	]
});
