<div data-cy="PanelTabs">
	<ul ngbNav #nav="ngbNav" class="nav-tabs">
		<li ngbNavItem>
			<a ngbNavLink data-cy="SearchTab">Search ({{selectedCount}})</a>

			<ng-template ngbNavContent>
				<rl-entity-search [dataSource]="dataSource" [searchOptions]="searchOptions" target="_blank"
					[stretchHeight]="true" [alwaysShowHeader]="true">
				</rl-entity-search>
			</ng-template>
		</li>
		<li ngbNavItem>
			<a ngbNavLink data-cy="InputListTab">Input List</a>

			<ng-template ngbNavContent>
				<rl-input-list [childCharTypeId]="assocCharTypeId" [charTypeId]="charTypeId"
					[matchesParentQuery]="matchesParentQuery" (onValidate)="updateValidated($event)"
					[includeParentRecordId]="isTablesOrRights" [isAssociatingChild]="isAssociatingChild">
				</rl-input-list>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>
<ng-content></ng-content>
<rl-chips-bitmap [charTypeId]="assocCharTypeId"
				[bitmap]="(dataSelectStrategy.selectStateBitmapChange$ | async)?.selectedIdsBitmap"
				(removeItem)="dataSelectStrategy.deselectRowById($event, $event)">
</rl-chips-bitmap>