import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { EntityService } from "rl-common/services/entity/entity.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { Subscription } from "rxjs";
import { PluralCharTypeNamePipe } from "../../../pipes/plural-char-type-name.pipe";
import { ITemplateHierarchy } from "../create-entity.models";
import { TemplateHierarchyCountItemComponent } from "../template-hierarchy-count-item/template-hierarchy-count-item.component";

@Component({
	selector: "rl-create-and-associate-hierarchical-records",
	templateUrl: "./create-and-associate-hierarchical-records.component.html",
	styleUrls: ["./create-and-associate-hierarchical-records.component.scss"],
	imports: [NgFor, TemplateHierarchyCountItemComponent, NgIf, PluralCharTypeNamePipe]
})
export class CreateAndAssociateHierarchicalRecordsComponent implements OnInit, OnDestroy {

	@Input()
	templates: ITemplateHierarchy[] = [];

	@Input()
	charTypeId: number;

	@Input()
	entityId: string;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onBack = new EventEmitter<void>();

	@Output()
	onHierarchyCompleted = new EventEmitter<ITemplateHierarchy[]>();

	isSaving: boolean = false;

	private readonly _subscriptions: Subscription[] = [];

	constructor(private readonly entityService: EntityService, private readonly modalService: ModalServiceAbstract) { }

	ngOnInit() {
	}

	cancel() {
		this.onCancel.emit();
	}

	goToPreviousWizardStep() {
		this.onBack.emit();
	}

	create() {
		this.isSaving = true;
		this.onHierarchyCompleted.emit(this.templates);
	}

	ngOnDestroy() {
		this.isSaving = false;
		this._subscriptions.forEach(x => x.unsubscribe());
	}
}
