<h3>Associate Contacts to the New Record</h3>
<article>
	<rl-panel-switcher [activeId]="editMode" [isLoading]="isLoading">
		<rl-panel id="edit">
			<ng-template rlPanelContent>
				<rl-edit-contacts [contactPartyGroups]="contactPartyGroups" [charTypeId]="charTypeId"
					[dictionaries]="dictionaries" [isSaveEnabled]="false" [isNewAssociation]="true"
					(onStartSearch)="startSearch($event)" (onContinueToAssociateChildren)="goToNextWizardStep()">
				</rl-edit-contacts>
				<div class="mt-2 action-footer">
					<button (click)="goToPreviousWizardStep()" class="btn btn-info"
						data-cy="AssociateContactsPreviousButton"><i class="far fa-chevron-left me-2"></i>
						Previous</button>
					<button (click)="cancel()" class="btn btn-light ms-3"
						data-cy="AssociateContactsCancelButton">Cancel</button>
					<button (click)="saveChanges(false)" class="btn btn-success ms-3"
						data-cy="AssociateContactsDoneButton">Done</button>
					<button *ngIf="canAutoCreateChildItems" (click)="goToNextWizardStep()" class="btn btn-info ms-3"
						data-cy="ContinueToAutoCreateChildButton">Continue to Auto-Create Child
						Items <i class="far fa-chevron-right ms-2"></i></button>
				</div>
			</ng-template>
		</rl-panel>
		<rl-panel id="search">
			<ng-template rlPanelContent>
				<rl-entity-search #list [searchOptions]="searchOptions" (selectedStateChange)="select($event)">
				</rl-entity-search>
				<div class="mt-2 action-footer">
					<button (click)="cancel()" class="btn btn-light">Cancel</button>
					<button (click)="saveChanges(false)" class="btn btn-success ms-3">Done</button>
				</div>
			</ng-template>
		</rl-panel>
	</rl-panel-switcher>
</article>