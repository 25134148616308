import { ComponentPortal, PortalInjector, CdkPortalOutlet } from "@angular/cdk/portal";
import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AssignedConflictsWidgetComponent } from "rl-common/components/modals/assigned-conflicts-widget/assigned-conflicts-widget.component";
import { NotificationsWidgetComponent } from "rl-common/components/notifications-widget/notifications-widget.component";
import { IzendaReportWidgetComponent } from "rl-common/components/widgets/izenda-report-widget/izenda-report-widget.component";
import { RecentActivityWidgetComponent } from "rl-common/components/widgets/recent-activity-widget/recent-activity-widget.component";
import { SavedSearchWidgetComponent } from "rl-common/components/widgets/saved-search-widget/saved-search-widget.component";
import { DealsByStatusComponent } from "../../deals-by-status/deals-by-status.component";
import { FavoritesComponent } from "../../favorites/favorites.component";
import { WidgetConstants } from "./widget.const";
import { IWidgetOptions, Widgets } from "./widget.models";

@Component({
    selector: "rl-widget",
    templateUrl: "./widget.component.html",
    styleUrls: ["./widget.component.scss"],
    imports: [CdkPortalOutlet]
})
export class WidgetComponent implements OnInit, OnChanges {

	@Input()
	index: number;

	@Input()
	options: IWidgetOptions;

	portal: ComponentPortal<any>;

	constructor(private readonly _injector: Injector) { }

	ngOnInit() {
		this.createPortal();
	}

	ngOnChanges(changes: SimpleChanges) {
		const options = changes["options"];
		if (options && !options.firstChange && options.currentValue !== options.previousValue) {
			this.createPortal();
		}
	}

	private createPortal() {
		this.portal = this.getComponentPortal(this._injector, this.index, this.options, this.options.params);
	}

	private getComponentPortal(injector: Injector, widgetIndex: number, widgetOptions: IWidgetOptions, widgetParams: any = {}) {
		const portalInjector = this.createInjector(injector, widgetIndex, widgetOptions, widgetParams);
		switch (widgetOptions.type) {
			case Widgets.Favorites:
				return new ComponentPortal(FavoritesComponent);
			case Widgets.Notifications:
				return new ComponentPortal(NotificationsWidgetComponent);
			case Widgets.RecentActivity:
				return new ComponentPortal(RecentActivityWidgetComponent);
			case Widgets.SavedSearch:
				return new ComponentPortal(SavedSearchWidgetComponent, null, portalInjector);
			case Widgets.DealsByStatus:
				return new ComponentPortal(DealsByStatusComponent, null, portalInjector);
			case Widgets.IzendaReport:
				return new ComponentPortal(IzendaReportWidgetComponent, null, portalInjector);
			case Widgets.AssignedConflicts:
				return new ComponentPortal(AssignedConflictsWidgetComponent, null, portalInjector);
			default:
				throw Error(`Could not map '${widgetOptions.type}' to a component`);
		}
	}

	private createInjector(injector: Injector, widgetIndex: number, widgetOptions: IWidgetOptions, params: any) {
		const tokens = new WeakMap();
		tokens.set(WidgetConstants.WIDGET_PARAMS, params);
		tokens.set(WidgetConstants.WIDGET_OPTIONS, widgetOptions);
		tokens.set(WidgetConstants.WIDGET_INDEX, widgetIndex);
		return new PortalInjector(injector, tokens);
	}

}
