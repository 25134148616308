import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { EntityData } from "rl-common/services/mod-detail/mod-detail.models";
import { ModDetailService } from "rl-common/services/mod-detail/mod-detail.service";
import { DivSettingKey } from "rl-common/services/settings/settings.models";
import { SettingsService } from "rl-common/services/settings/settings.service";
import { GenerateDocumentFormat, IDocumentTemplate, IGenerateDocumentResponse, IWorkflowAction } from "rl-common/services/workflow/workflow.models";
import { WorkflowService } from "rl-common/services/workflow/workflow.service";
import { Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgFor, AsyncPipe } from "@angular/common";


@Component({
    selector: "rl-generate-document",
    templateUrl: "./generate-document.component.html",
    styleUrls: ["./generate-document.component.scss"],
    imports: [ReactiveFormsModule, FormsModule, NgFor, AsyncPipe]
})
export class GenerateDocumentComponent implements OnInit, OnDestroy {
	@Input()
	action: IWorkflowAction;
	selectedTemplateId: number;
	selectedFormat: GenerateDocumentFormat = GenerateDocumentFormat.PDF;
	selectedTemplate: IDocumentTemplate;
	parentEntity: EntityData;
	docTemplates: IDocumentTemplate[];
	subs: Subscription[] = [];

	@Output()
	onSave = new EventEmitter<IGenerateDocumentResponse>();

	docTemplates$ = this._modDetailService.entity$
		.pipe(
			tap(entity => this.parentEntity = entity),
			switchMap(entityData => this._workflowService.getDocTemplates(entityData.entity.charTypeID, entityData.entity.templateID)),
			tap(docTemplates => this.docTemplates = docTemplates)
		);
	docTemplateOptions$ = this.docTemplates$
		.pipe(
			map(templates => {
				return templates.map(template => {
					return {
						label: template.templateLabel,
						value: template.documentTemplateId
					};
				});
			}),
			tap(templateOptions => {
				this.selectedTemplateId = templateOptions[0]?.value;
				this.updateTemplate();
			})
		);
	outputOptions = [
		{
			label: "PDF",
			value: GenerateDocumentFormat.PDF
		},
		{
			label: "DOCX",
			value: GenerateDocumentFormat.DOCX
		}
	];

	isGenerating = false;

	constructor(
		private readonly _modDetailService: ModDetailService,
		private readonly _workflowService: WorkflowService,
		private readonly _settingsService: SettingsService
	) { }

	ngOnInit() {
		const sub = this._settingsService.divSettingsList$.subscribe(divSettings => {
			const matchingSetting = divSettings.find(ds => ds.key === DivSettingKey.DefaultDocumentGenerationType);
			if (matchingSetting?.value && this.outputOptions[0].label !== matchingSetting?.value) {
				const matchingOutputOption = this.outputOptions.find(opt => opt.label.toUpperCase() === matchingSetting.value.toUpperCase())
				if (matchingOutputOption) {
					this.selectedFormat = matchingOutputOption.value;
				}
			}
		});
		this.subs.push(sub);
	}

	updateTemplate() {
		this.selectedTemplate = this.docTemplates.find(template => template.documentTemplateId === +this.selectedTemplateId);
		if (!this.selectedTemplate) {
			// TODO: we should present something to the user if this happens for some reason
			console.warn(`Document with template id: ${this.selectedTemplateId} could not be found.`);
		}
	}

	save() {
		this.isGenerating = true;
		const saveSub = this._workflowService.generateDocument(this.parentEntity, this.action, this.selectedTemplate, this.selectedFormat)
			.subscribe((response) => {
				this.onSave.emit(response);
				this.isGenerating = false;
			});
		this.subs.push(saveSub);
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
	}
}
