import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { every } from "lodash";
import { IWfRole } from "rl-common/services/company/company.models";
import { ISelectedNotification, IWFParty } from "rl-common/services/entity-config/entity-config.models";
import { IWorkflowNotification } from "rl-common/services/workflow/workflow.models";
import { IWorkflowActionForm } from "../../workflow-action-edit.models";
import { NgFor, NgClass } from "@angular/common";
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "rl-notification-row",
    templateUrl: "./notification-row.component.html",
    styleUrls: ["./notification-row.component.scss"],
    imports: [ReactiveFormsModule, FormsModule, NgFor, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgClass]
})

export class NotificationRowComponent implements OnInit {
	selectedRoleCount = 0;
	selectedPartyCount = 0;
	selectedRoleMemberCount = 0;

	checkedRoleIds: number[] = [];
	checkedPartyIds: number[] = [];
	checkedNotifyChildrenIds: number[] = [];

	notificationId: number;
	selectAllNotifyChildContactsChecked: boolean;

	disabledRoles: { roleId: number; fromRow: number }[] = [];
	selectedPartyNotifyChildrenIds: number[];
	checkedParties: { [partyId: number]: boolean }[];

	@Input()
	form: FormGroup<IWorkflowActionForm>;

	@Input()
	notifications: IWorkflowNotification[];

	@Input()
	notificationControl: FormControl<ISelectedNotification>;

	@Input()
	allRoles: IWfRole[];

	@Input()
	allParties: IWFParty[];

	@Input()
	isCreate: boolean;

	get allRowsSelected() {
		const allRoleIds = this.allRoles && this.allRoles.map(r => r.roleID) || [];
		return every(allRoleIds, roleId => this.checkedRoleIds.includes(roleId));
	}

	get allPartiesSelected() {
		const allPartyIds = this.allParties && this.allParties.map(p => p.partyID) || [];
		return every(allPartyIds, partyId => this.checkedPartyIds.includes(partyId));
	}

	constructor() { }

	ngOnInit(): void {
		if (!this.isCreate) {
			this.setRoleIdsToChecked(this.notificationControl.value.selectedRoleIds);
			this.setPartyIdsToChecked(this.notificationControl.value.selectedParties);
			this.setPartyNotifyChildrenToChecked(this.notificationControl.value.selectedParties);
			this.isSelectAllNotifyChildContactsChecked();
		}
	}

	onNotificationChange() {
		const val = this.notificationControl.value;
		this.notificationControl.setValue(val);
		this.notificationControl.markAsTouched();
	}

	setRoleIdsToChecked(roleIds: number[]) {
		const ids = this.allRoles.map(r => r.roleID);
		this.checkedRoleIds = ids.filter(id => roleIds.includes(id)) || [];
		this.selectedRoleCount = this.checkedRoleIds.length;
		this.getSelectedRoleMemberCount(roleIds);
		return this.checkedRoleIds;
	}

	isRoleIdChecked(roleId: number) {
		return this.checkedRoleIds.includes(roleId);
	}

	private getSelectedRoleMemberCount(roleIds: number[]) {
		const selectedRoles = this.allRoles.filter(r => roleIds.includes(r.roleID));
		const memberCounts = selectedRoles.map(r => r.memberCount);
		this.selectedRoleMemberCount = memberCounts.reduce((partialSum, a) => partialSum + a, 0);
	}

	setPartyIdsToChecked(parties: { [partyId: number]: boolean }[]) {
		this.checkedPartyIds = parties.map(obj => Number(Object.keys(obj)[0]));
		this.selectedPartyCount = this.checkedPartyIds.length;
		return this.checkedPartyIds;
	}

	setPartyNotifyChildrenToChecked(parties: { [partyId: number]: boolean }[]) {
		this.checkedParties = parties.filter(obj => Object.values(obj)[0] === true);
		this.checkedNotifyChildrenIds = this.checkedParties.map(kvp => Number(Object.keys(kvp)[0]));
		return this.checkedNotifyChildrenIds;
	}

	isPartyIdChecked(partyId: number) {
		return this.checkedPartyIds.includes(partyId);
	}

	isPartyNotifyChildrenIdChecked(partyId: number) {
		return this.checkedNotifyChildrenIds.includes(partyId);
	}

	onRoleChange(id: number, isChecked: boolean) {
		const currentNotification = this.notificationControl.value as ISelectedNotification;
		const selectedRoleIdsArray = [...currentNotification.selectedRoleIds];
		const selectedRole = this.allRoles.find(r => r.roleID === id);

		if (isChecked) {
			selectedRoleIdsArray.push(id);
			this.checkedRoleIds.push(id);
			++this.selectedRoleCount;
			this.selectedRoleMemberCount += selectedRole.memberCount;
		} else {
			const index = selectedRoleIdsArray.indexOf(id);
			selectedRoleIdsArray.splice(index, 1);
			const checkedIndex = this.checkedRoleIds.indexOf(id);
			this.checkedRoleIds.splice(checkedIndex, 1);
			--this.selectedRoleCount;
			this.selectedRoleMemberCount -= selectedRole.memberCount;
		}

		this.notificationControl.patchValue({
			...currentNotification,
			selectedRoleIds: selectedRoleIdsArray
		});

		this.onNotificationChange();
	}

	isRoleDisabled(id: number) {
		const notificationFormArray = this.form.controls.notifications.value;
		const isCheckedOnAnyRow = notificationFormArray.some(n => n.selectedRoleIds.includes(id));
		const isCheckedOnRow = this.notificationControl.value.selectedRoleIds.includes(id);
		return isCheckedOnAnyRow && !isCheckedOnRow;
	}

	onPartyChange(id: number, isChecked: boolean) {
		const currentNotification = this.notificationControl.value as ISelectedNotification;
		const selectedPartiesArray = [...currentNotification.selectedParties];

		if (isChecked) {
			selectedPartiesArray.push({ [id]: false });
			this.checkedPartyIds.push(id);
			++this.selectedPartyCount;
		} else {
			const index = selectedPartiesArray.findIndex(p => Object.keys(p).includes(String(id)));
			selectedPartiesArray.splice(index, 1);
			const checkedIndex = this.checkedPartyIds.indexOf(id);
			this.checkedPartyIds.splice(checkedIndex, 1);
			const notifyChildrenIndex = this.checkedNotifyChildrenIds.indexOf(id);
			this.checkedNotifyChildrenIds.splice(notifyChildrenIndex, 1);
			--this.selectedPartyCount;
		}

		this.notificationControl.patchValue({
			...currentNotification,
			selectedParties: selectedPartiesArray
		});

		this.onNotificationChange();
	}

	isPartyDisabled(id: number) {
		const notificationFormArray = this.form.controls.notifications.value;
		const selectedPartiesArray = this.notificationControl.value.selectedParties;
		const notifPartiesArray = notificationFormArray.map(n => n.selectedParties).reduce((acc, obj) => acc.concat(Object.values(obj)), []);
		const isCheckedOnAnyRow = [].concat(...notifPartiesArray.map(obj => Object.keys(obj).map(Number))).includes(id);
		const isCheckedOnRow = [].concat(...selectedPartiesArray.map(obj => Object.keys(obj).map(Number))).includes(id);
		return isCheckedOnAnyRow && !isCheckedOnRow;
	}

	onNotifyChildContactChange(id: number, isChecked: boolean) {
		const selectedPartiesArray = this.notificationControl.value.selectedParties;
		const notification = selectedPartiesArray.find(p => Object.keys(p).includes(String(id)));

		if (notification) {
			if (isChecked) {
				notification[id] = true;
				this.checkedNotifyChildrenIds.push(id);
			} else {
				notification[id] = false;
				const index = this.checkedNotifyChildrenIds.indexOf(id);
				this.checkedNotifyChildrenIds.splice(index, 1);
			}
		}

		this.onNotificationChange();
	}

	selectAllRoles() {
		if (!this.allRowsSelected) {
			this.notificationControl.value.selectedRoleIds = [];
			const selectedRoleIdsSet = new Set<number>();
			this.allRoles.forEach(role => {
				if (!this.isRoleDisabled(role.roleID)) {
					selectedRoleIdsSet.add(role.roleID);
				}
			});
			this.notificationControl.value.selectedRoleIds = Array.from(selectedRoleIdsSet);
			this.checkedRoleIds = this.notificationControl.value.selectedRoleIds;
			this.selectedRoleCount = this.notificationControl.value.selectedRoleIds.length;
		} else {
			this.notificationControl.value.selectedRoleIds = [];
			this.checkedRoleIds = [];
			this.selectedRoleCount = 0;
		}

		this.onNotificationChange();
	}


	selectAllParties(isChecked: boolean) {
		if (isChecked) {
			const allPartyIds = this.allParties.map(p => p.partyID);

			allPartyIds.forEach(id => {
				if (!this.isPartyDisabled(id)) {
					const idFoundInSelectedParties = this.checkedPartyIds.indexOf(id) >= 0;

					if (!idFoundInSelectedParties) {
						this.checkedPartyIds.push(id);
						this.notificationControl.value.selectedParties.push({ [id]: false });
					}
				}
			});

			this.selectedPartyCount = this.checkedPartyIds.length;
		} else {
			this.notificationControl.value.selectedParties = [];
			this.checkedPartyIds = [];
			this.checkedNotifyChildrenIds = [];
			this.selectedPartyCount = 0;
			this.selectAllNotifyChildContactsChecked = false;
		}

		this.onNotificationChange();
	}

	selectAllNotifyChildContacts(isChecked: boolean) {
		if (isChecked) {
			const allPartyIds = this.allParties.map(p => p.partyID);
			this.checkedNotifyChildrenIds = allPartyIds.filter(p => this.checkedPartyIds.includes(p));
			this.checkedNotifyChildrenIds.forEach(id => {
				const partyIndex = this.notificationControl.value.selectedParties.findIndex(party => Object.keys(party)[0] === id.toString());
				const partyId = Object.keys(this.notificationControl.value.selectedParties[partyIndex])[0];
				this.notificationControl.value.selectedParties[partyIndex][partyId] = true;
			});

			this.selectAllNotifyChildContactsChecked = true;
		} else {
			this.checkedNotifyChildrenIds = [];
			this.selectAllNotifyChildContactsChecked = false;
		}

		this.onNotificationChange();
	}

	private isSelectAllNotifyChildContactsChecked() {
		if (this.checkedPartyIds.length && this.checkedPartyIds.length === this.checkedNotifyChildrenIds.length) {
			this.selectAllNotifyChildContactsChecked = true;
		}
	}
}
