import { Location } from "@angular/common";
import { Injectable, OnDestroy } from "@angular/core";

@Injectable()
export class IzendaUrlChangeService implements OnDestroy {
	private unregisterUrlChangeListener: any;
	urlChangeListenerRegistered = false;
	replacing = false;

	constructor(
		private location: Location
	) { }

	public registerListener() {
		if (!this.urlChangeListenerRegistered) {
			this.unregisterUrlChangeListener = this.location.onUrlChange((url) => {
				const reportViewerRegex = /(#\?source=)+.*/g;
				if (url.match(reportViewerRegex)) {
					// We need to make sure the browser `pushState` api doesn't get spammed by the combination of both our and izenda's routing
					// When spammed most browsers will lockdown the api to prevent abuse
					if (!this.replacing) {
						this.replacing = true;
						setTimeout(() => {
							this.location.replaceState("/");
							this.replacing = false;
						}, 500);
					}
				}
			});
			this.urlChangeListenerRegistered = true;
		}
	}

	ngOnDestroy(): void {
		this.unregisterUrlChangeListener();
	}
}
