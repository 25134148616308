import { CdkDropList } from "@angular/cdk/drag-drop";
import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { ITemplateCharacteristicGroup } from "rl-common/models/i-template-characteristic-group";
import { ISourceField } from "rl-common/services/entity-config/entity-config.models";
import { ChipComponent } from "../../../../../../common/components/chip/chip.component";
import { LoaderComponent } from "../../../../../../common/components/panel/loader/loader.component";
import { CharDataTypeNamePipe } from "../../../../../../common/pipes/char-data-type-name.pipe";
import { CharTypeNamePipe } from "../../../../../../common/pipes/char-type-name.pipe";
import { MultipleIndicatorNamePipe } from "../../../../../pipes/multiple-indicator-name.pipe";

@Component({
    selector: "rl-select-template-source-field",
    templateUrl: "./select-template-source-field.component.html",
    styleUrls: ["./select-template-source-field.component.scss"],
    imports: [NgIf, CdkDropList, NgFor, NgbPopover, ChipComponent, LoaderComponent, CharTypeNamePipe, CharDataTypeNamePipe, MultipleIndicatorNamePipe]
})
export class SelectTemplateSourceFieldComponent implements OnInit {

	@Input()
	group: ITemplateCharacteristicGroup;

	@Input()
	cmd: ISourceField[];

	@Input()
	sourceFieldsLoaded: boolean;

	@Input()
	charTypeName: string;

	@Output()
	onComplete = new EventEmitter<Set<number>>();

	fieldsToAdd = new Set<number>();

	constructor() { }

	ngOnInit(): void { }

	toggleSourceField(sourceFieldId: number) {
		if (this.fieldsToAdd.has(sourceFieldId)) {
			this.fieldsToAdd.delete(sourceFieldId);
		} else {
			this.fieldsToAdd.add(sourceFieldId);
		}
	}

	submit() {
		this.onComplete.emit(this.fieldsToAdd);
	}

	getSourceFieldById(id: number) {
		const sourceField = this.cmd.find(({ sourceFieldID }) => sourceFieldID === id);
		return sourceField.sourceFieldName;
	}

	isChecked(sourceFieldId: number) {
		return this.fieldsToAdd.has(sourceFieldId) ? true : false;
	}

}

