import { AsyncPipe, JsonPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { NgbActiveModal, NgbNav, NgbNavContent, NgbNavItem, NgbNavLink, NgbNavLinkBase, NgbNavLinkButton, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { first } from "lodash";
import { ICharDataExtDataDateMathRel } from "rl-common/models/i-char-data-ext-data-date-math-rel";
import { JwtTokenValue } from "rl-common/models/jwt-token-value";
import { Acl } from "rl-common/rl-common-acl.consts";
import { DateMathService } from "rl-common/services/datemath/datemath.service";
import { DevToolsService } from "rl-common/services/dev-tools/dev-tools.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { ModalServiceAbstract } from "rl-common/services/modal.service.abstract";
import { SessionService } from "rl-common/services/session.service";
import { SiteService } from "rl-common/services/site/site.service";
import { ISolrDbCountValues } from "rl-common/services/site/site.service.models";
import { LocalStorageKeys, StorageUtil } from "rl-common/services/storage.service";
import { TokenStorageService } from "rl-common/services/token-storage/token-storage.service";
import { AclUtil } from "rl-common/utils/acl.util";
import { of, Subscription } from "rxjs";
import { catchError, finalize, map, switchMap } from "rxjs/operators";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { TextInputComponent } from "../../../../common/components/text/text-input/text-input.component";
import { FaIconDirective } from "../../../../common/directives/fa-icon.directive";
import { NewTabInModalDirective } from "../../../../common/directives/new-tab-in-modal.directive";
import { CharTypeNamePipe } from "../../../../common/pipes/char-type-name.pipe";
import { KonamiDirective } from "../../../directives/konami.directive";
import { DatamartRecordCountComponent } from "../../statistics/datamart-record-count/datamart-record-count.component";
import { AboutAlliantComponent } from "../about-alliant/about-alliant.component";
import { UserAclInfoComponent } from "../user-acl-info/user-acl-info.component";
import { VersionInfoComponent } from "../version-info/version-info.component";

@Component({
	selector: "rl-debug-modal",
	templateUrl: "./debug-modal.component.html",
	styleUrls: ["./debug-modal.component.scss"],
	imports: [KonamiDirective, NgbNav, NgbNavItem, NgbNavLink, NgbNavLinkBase, NgbNavContent, VersionInfoComponent, NgbNavLinkButton, UserAclInfoComponent, NgbNavOutlet, NgIf, NgFor, NgClass, DatamartRecordCountComponent, NewTabInModalDirective, RouterLink, FaIconDirective, AboutAlliantComponent, ReactiveFormsModule, TextInputComponent, FormsModule, LoaderComponent, AsyncPipe, JsonPipe, CharTypeNamePipe]
})
export class DebugModalComponent implements OnInit, OnDestroy {

	accountJson: JwtTokenValue;

	loadingSolrCounts = true;
	countComparison: ISolrDbCountValues[];

	loadingOrphanedRels = false;
	orphanedDateMathRels: ICharDataExtDataDateMathRel[];

	private readonly _subs: Subscription[] = [];

	devToolsUnlocked = false;
	exchangeRateDealId: number;
	isTestingStatusCode = false;
	statusCodeControl: FormControl<number>;
	statusCodes: [number, string][] = [
		[400, "Bad Request"],
		[401, "Unauthorized"],
		[403, "Forbidden"],
		[404, "Not Found"],
		[409, "Conflict"],
		[412, "Precondition Failed"],
		[428, "Bad Config"],
		[500, "Internal Server Error"],
		[501, "Not Implemented"],
		[503, "Service Unavailable"],
	];

	displayName$ = this._sessionService.displayName$;
	userName$ = this._sessionService.userName$;
	userId$ = this._sessionService.userId$;
	isSuperAdmin$ = this._sessionService.acls$.pipe(map(acls => AclUtil.hasReadAccess(acls, Acl.Admin.admin)));

	constructor(private readonly _modal: NgbActiveModal,
		private readonly _siteService: SiteService,
		private readonly _sessionService: SessionService,
		private readonly _dateMathService: DateMathService,
		readonly linkHelper: LinkHelperService,
		private readonly _jwtHelperService: JwtHelperService,
		private readonly _tokenStorageService: TokenStorageService,
		private readonly _devToolsService: DevToolsService,
		private readonly _growlerService: GrowlerService,
		private readonly _modalService: ModalServiceAbstract
	) { }

	ngOnInit() {
		this.devToolsUnlocked = StorageUtil.get<boolean>(LocalStorageKeys.DevToolsUnlocked) ?? false;
		this.accountJson = this._jwtHelperService.decodeToken(this._tokenStorageService.jwt) as JwtTokenValue;
		const sub = this._siteService.getSolrDbCountComparison().pipe(
			finalize(() => {
				this.loadingSolrCounts = false;
			})
		).subscribe(result => {
			this.countComparison = result;
		});
		this._subs.push(sub);

		const isRlAdminSub = this._sessionService.isRlAdmin$.subscribe(isRlAdmin => {
			if (!isRlAdmin) {
				this._modal.dismiss();
			}
		});

		this.statusCodeControl = new FormControl<number>(first(this.statusCodes)[0]);
		this._subs.push(isRlAdminSub);
	}

	throwError() {
		throw new Error("SENTRY TEST. Ignore this exception.");
	}

	throwApiException() {
		this.isTestingStatusCode = true;
		const statusCode = this.statusCodeControl.value;
		const nullStatus: number = null;
		const sub = this._devToolsService.throwException(statusCode).pipe(
			map(() => nullStatus),
			catchError((err) => of(err.status as number))
		).subscribe((returnedStatus) => {
			if (returnedStatus === statusCode) {
				const tuple = this.statusCodes.find(x => x[0] === statusCode);
				this._growlerService.success().growl(`${returnedStatus} (${tuple[1]}) response confirmed!`);
			} else {
				this._growlerService.error(`Unexpected Response`).growl(`was expecting ${statusCode} and received ${returnedStatus}`);
			}
			this.isTestingStatusCode = false;
		});
		this._subs.push(sub);
	}

	runAlertNotifJob() {
		const sub = this._devToolsService.processAlertNotifications().pipe(
			switchMap(response => this._modalService.jobProgress(response.jobId))
		).subscribe();
		this._subs.push(sub);
	}

	fetchOrphanedDateMathRels() {
		this.loadingOrphanedRels = true;
		const sub = this._dateMathService.orphanedDateMathRels().pipe(
			finalize(() => {
				this.loadingOrphanedRels = false;
			})
		).subscribe((result) => this.orphanedDateMathRels = result);
		this._subs.push(sub);
	}

	close() {
		this._modal.dismiss();
	}

	unlockDevTools(nav: NgbNav, tab: string) {
		if (!this.devToolsUnlocked) {
			this._growlerService.success().growl(`Dev Tools Unlocked!`);
		}
		StorageUtil.set(LocalStorageKeys.DevToolsUnlocked, true);
		this.devToolsUnlocked = true;
		setTimeout(() => {
			nav.select(tab);
		}, 0);
	}

	runExchangeRateJob() {
		const sub = this._devToolsService.runExchangeRateJob(this.exchangeRateDealId ?? 0).subscribe(() => {
			this._growlerService.success().growl(`Exchange Rate Nightly Job has been initiated!`);
		});

		this._subs.push(sub);
	}

	ngOnDestroy() {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
