<div class="modal-header">
	<h2 class="modal-title">Select Fields to Sync</h2>
	<button class="btn btn-close" (click)="cancel()" title="Close">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<rl-list-selector [unSelectedList]="unSelectedList" [selectedList]="selectedList"
		[unSelectedHeader]="unSelectedHeader" [selectedHeader]="selectedHeader" [keyFn]="keyFn" [labelFn]="labelFn"
		(onChange)="updateLists($event)">
	</rl-list-selector>
</div>

<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success" (click)="apply()" [disabled]="selectedList.length == 0">Apply</button>
</div>