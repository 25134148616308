import { EventEmitter, Injectable, Type } from "@angular/core";
import { WorksheetType } from "rl-common/services/clipboard/clipboard.models";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
export interface IClipboardComponent {
	clipboardType: WorksheetType;
	items: unknown[];
	charTypeId?: number;
	onComplete: EventEmitter<boolean>;
}

@Injectable({ providedIn: "root" })
export class ClipboardModalAdapter extends ModalAdapter<IClipboardComponent> {
	constructor(type: Type<IClipboardComponent>) {
		super(type);
	}

}


