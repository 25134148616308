import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { cloneDeep } from "lodash";
import { CharDataElementUsage } from "rl-common/components/char-data/char-data.models";
import { ICharDataChangeEvent } from "rl-common/models/i-char-data-change-event";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { Subscription } from "rxjs";
import { CharDataElementComponent } from "../../../char-data/char-data-element.component";
import { ElementValueAccessorDirective } from "../../../char-data/elements/element-value-accessor";

@Component({
	selector: "rl-varies-by-catalog-char-data-element-edit",
	templateUrl: "./varies-by-catalog-char-data-element-edit.component.html",
	providers: [CharDataTableService],
	imports: [CharDataElementComponent, ElementValueAccessorDirective, ReactiveFormsModule]
})
export class VariesByCatalogCharDataElementEditComponent implements OnInit, OnDestroy, OnChanges {
	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	initialCharDatas: ICharacteristicData[];

	@Input()
	charData: ICharacteristicData[];

	@Input()
	template: ICharacteristicMetaDataCollection;

	@Input()
	recordId: number;

	@Input()
	isExclusive: boolean;

	@Output()
	elementDataChanged = new EventEmitter<OnVariesByCharElementChangedEvent>();

	elementUsage = CharDataElementUsage.CharDataTable;

	instanceId: string;

	private _subs: Subscription[] = [];

	public formGroup: UntypedFormGroup;

	constructor(
		private readonly _charDataTableService: CharDataTableService,
		private readonly _formBuilder: UntypedFormBuilder
	) {
		this.formGroup = this._formBuilder.group({});
		this.instanceId = this._charDataTableService.instanceId;
	}

	ngOnInit() {
		this._charDataTableService.setTemplate(this.template);
		this._charDataTableService.setCharData(cloneDeep(this.initialCharDatas));
		this._charDataTableService.setRecordId(this.recordId);

		const { charDataRules$ } = this._charDataTableService.setupTableFormGroup(this.formGroup, [this.cmd], { [this.cmd.characteristicID]: this.charData }, []);
		this._subs.push(charDataRules$.subscribe());
		this.updateCharData();
	}

	ngOnChanges(changes: ComponentChanges<VariesByCatalogCharDataElementEditComponent>) {
		if (changes.charData && this.formGroup) {
			this.updateCharData();
		}
	}

	updateCharData() {
		if (this.formGroup.contains(this.cmd.tagLabel)) {
			this.formGroup.controls[this.cmd.tagLabel].setValue(this.charData);
		}
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	onElementDataChanged($event: ICharDataChangeEvent) {
		this.elementDataChanged.emit({
			recordId: this.recordId,
			isExclusive: this.isExclusive,
			charData: $event.charData,
			cmd: $event.cmd
		});
	}
}

export class OnVariesByCharElementChangedEvent {
	recordId: number;
	isExclusive: boolean;
	charData: ICharacteristicData[];
	cmd: ICharacteristicMetaData;
}
