<div class="d-flex align-items-center" *ngIf="templates.length > 1">
	<label class="title--overline text-nowrap mb-0" for="templateSelector">
		<ng-content></ng-content>
	</label>
	<select id="templateSelector" class="form-select ms-2" [formControl]="template"
		(change)="changeTemplate()" data-cy="TemplateSelector">
		<option *ngFor="let t of templates" [value]="t.templateID"
			[selected]="selectedTemplate.templateID == t.templateID" [attr.data-cy]="t.templateName">
			{{t.templateName}}
		</option>
	</select>
</div>