import { Component, Input, OnInit } from "@angular/core";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { IRelSearchDoc } from "rl-common/models/i-rel-search-doc";
import { GridTableComponent } from "../../../grid/grid-table/grid-table.component";
import { INewEntityTemplateCharData } from "../../bulk-grid.models";

export interface IValidityUpdatedEvent {
	rowPath: string;
	valid: boolean;
}

@Component({
    selector: "rl-bulk-nested-amount-grid",
    templateUrl: "./bulk-nested-amount-grid.component.html",
    styleUrls: ["./bulk-nested-amount-grid.component.scss"],
    imports: [GridTableComponent]
})
export class BulkNestedAmountGridComponent implements OnInit {
	@Input()
	tableDataSource: SearchGridDataSource<IRelSearchDoc, INewEntityTemplateCharData>;
	@Input()
	amountsDataSource: CommonGridDataSource<INewEntityTemplateCharData>;

	@Input()
	parentRowData: IRelSearchDoc;

	@Input()
	rowPath: string;

	constructor() { }

	ngOnInit() {
	}
}
