<div class="mx-3">
	<div class="dashboard-panel-head d-flex align-items-center">
		<h3 class="m-0">Assigned Conflicts</h3>
	</div>
	<div class="pb-5">
		@if (dataSource){
		<rl-grid [options]="gridOptions" [dataSource]="dataSource" [layoutOptions]="{height: 'stretch'}"
			data-cy="NotificationsRLGrid">
			<div class="text-truncate" *rlCellTemplate="'status'; let cellData;"
				[class]="getStatusClass(cellData.conflictStatusLabel)" [title]="cellData.conflictStatusLabel">
				<i class="fa fa-minus-circle"></i>
			</div>

			<div class="text-truncate" *rlCellTemplate="'date-range'; let cellData">
				{{cellData.startDate | localeDate}} to {{cellData.endDate | localeDate}}
			</div>

			<div class="text-truncate" *rlCellTemplate="'conflict-check-issue'; let cellData">
				<rl-conflict-check-issue [issue]="cellData"></rl-conflict-check-issue>
			</div>
		</rl-grid>
		}
	</div>
</div>