<div class="d-flex align-items-end mb-0 py-2 px-3 mt-2 bg-gray" [class.border-bottom]="charDataTableReady">
	<h4 class="text-nowrap me-2">Create new</h4>
	<ng-container *ngIf="isIntraCharExcludingDocument" class="text-nowrap">
		<rl-relationship-type-select [(ngModel)]="direction" [relationships]="relationshipDirectionTypes"
			(change)="directionChanged()">
		</rl-relationship-type-select>
	</ng-container>
	<select class="form-select mx-2 w-auto" style="font-size:17px;" [(ngModel)]="selectedAssociatedTemplateIndex"
		(change)="selectedAssociatedTemplateChanged()" data-cy="CharTemplateTypeDropdown">
		<option *ngFor="let option of associatedTemplateOptions; let i = index" [value]="i"
			[attr.data-cy]="associatedTemplateOptions[i].label">
			{{associatedTemplateOptions[i].label}}</option>
		<option *ngIf="documentFolderTemplates.length > 0" [value]="associatedTemplateOptions.length">Upload
			Folder</option>
	</select>
	<h4 class="text-nowrap ms-2">{{charTypeId | charTypeName}} to associate.</h4>
</div>
<div *ngIf="showUploadFolderMenu" class="d-inline-flex align-items-center m-4">
	<h4 class="text-nowrap me-2">Upload file as</h4>
	<select class="form-select mx-2" style="font-size:17px;" [(ngModel)]="documentTypeSelectionIndex"
		(change)="chooseFile()" [attr.data-pendo]="'uploadFileAsDropdown'">
		<option *ngFor="let option of documentFileTemplates; let i = index" [value]="i">
			{{documentFileTemplates[i].label}}
		</option>
	</select>
</div>
<ng-container [formGroup]="form">
	<div class="scroll-vert" *ngIf="charDataTableReady">
		<rl-show-only-dropdown></rl-show-only-dropdown>
		@if(useFieldSections$ | async) {
		@if(layout) {
		<rl-mod-layout-char-data-table [hidden]="showUploadFolderMenu" [layout]="layout" [charData]="charData"
			[template]="templateMetaData" [editMode]="true" (tableCharDataChange)="setCharData($event)">
		</rl-mod-layout-char-data-table>
		} @else {
		<rl-loader></rl-loader>
		}
		} @else {
		<rl-char-data-table [hidden]="showUploadFolderMenu" [template]="templateMetaData" [charData]="charData"
			[editMode]="editMode" (tableCharDataChange)="setCharData($event)">
		</rl-char-data-table>
		}
	</div>
</ng-container>
<div *ngIf="isLoading">
	<rl-loader></rl-loader>
</div>

<ng-template #filterFields>
	<rl-show-only-dropdown></rl-show-only-dropdown>
</ng-template>