import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IApplyRelativeRightsProfileEvent } from "./apply-relative-rights-profile/applt-relative-rights-profile.models";
import { ApplyRelativeRightsProfileComponent } from "./apply-relative-rights-profile/apply-relative-rights-profile.component";

@Component({
	selector: "rl-apply-relative-rights-profile-modal",
	templateUrl: "./apply-relative-rights-profile-modal.component.html",
	styleUrls: ["./apply-relative-rights-profile-modal.component.scss"],
	imports: [ApplyRelativeRightsProfileComponent]
})
export class ApplyRelativeRightsProfileModalComponent {

	@Input()
	recordId: number;

	@Input()
	charTypeId: number;

	@Input()
	templateId: number;

	@Input()
	profile: IEntitySearchDoc;

	@Input()
	catalogsSelected: IRecordTitle[] = [];

	@Output()
	onCreate = new EventEmitter<IApplyRelativeRightsProfileEvent>();

	constructor(private readonly _activeModal: NgbActiveModal) { }

	emitCreate(event: IApplyRelativeRightsProfileEvent) {
		this.onCreate.emit(event);
		this.close();
	}

	close() {
		this._activeModal.close();
	}
}
