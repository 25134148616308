<div class="copy-process">
	<div class="modal-header">
		<h2 class="modal-title">Copy Accounting Process</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="CloseModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body">
		<div class="add-accounting-process__header p-3" [formGroup]="form">
			<div class="row mb-2">
				<div class="col-2 text-end"><span class="text-danger">*</span> Accounting Process Name:
				</div>
				<div class="col-3" [class.has-error]="form.controls.name.invalid && form.controls.name.touched">
					<input type="text" class="form-control add-accounting-process__header-input"
						[formControl]="form.controls.name" placeholder="Add new value"
						data-cy="AccountingProcessNameInput" />
				</div>
			</div>
		</div>
		<ng-container *ngIf="!isCopyToSameContact">
			<div class="alert alert-info">
				Only Contacts with completed Chart of Accounts are eligible for selection. Please note, any Accounts set
				in the Events
				section of an Operation will be cleared out in the newly copied Accounting Process. The user will have
				to redefine which
				Accounts should be attributed to which Events based off of the other Contact’s Chart of Accounts
				options.
			</div>
			<h4 class="pt-4 border-top">Select a Contact to copy {{ accountingProcess.name }} to</h4>
			<rl-entity-search *ngIf="dataSource" #entitySearch [searchOptions]="searchOptions" [dataSource]="dataSource"
				[layoutMaxHeight]="'40vh'">
			</rl-entity-search>
			<div class="border-top border-bottom my-3 py-3">
				<h4>Selected {{ searchOptions.charTypeId | pluralCharTypeName }}</h4>
				<p *ngIf="!selectedContact">No {{ searchOptions.charTypeId | pluralCharTypeName }}
					selected.</p>
				<div class="mt-2" *ngIf="selectedContact">
					<rl-chip *ngFor="let x of [selectedContact]" (onRemove)="clearSelections()">
						{{ x.title && x.title.length > 0 ? x.title : "Record " + x.recordID}}
					</rl-chip>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
		<button (click)="close()" class="btn btn-light me-1" data-cy="CancelButton">
			Cancel
		</button>
		<button class="btn btn-info" type="button" [disabled]="!form.valid || isCopying" (click)="copy()"
			data-cy="CopyConfirmButton">
			Copy
		</button>
	</div>
</div>