<div class="modal-body" data-cy="PreviewNotifsModalBody">
	<div class="alert alert-warning">
		This action sends out {{notifications.length}} notification(s). Please review and edit before you send.
	</div>
	<div ngbAccordion #accordion="ngbAccordion">
		@for (preview of previews; track $index) {
		<div ngbAccordionItem="ngb-panel-{{ $index }}" [collapsed]="false">
			<div ngbAccordionHeader>
				<button class="accordion-button panel__title d-flex justify-content-between align-items-center"
					[ngClass]="{'collapsed' : !accordion.isExpanded('ngb-panel-' + $index)}" ngbAccordionButton>
					<span class="cursor-pointer">
						{{preview.notification.label}}
					</span>
					<i class="far fa-chevron-right cursor-pointer toggle-chevron p-1"
						[title]="accordion.isExpanded('ngb-panel-' + $index) ? 'Collapse' : 'Expand'"
						[attr.data-cy]="accordion.isExpanded('ngb-panel-' + $index) ? 'ToCollapse' : 'ToExpand'"
						[class.open]="accordion.isExpanded('ngb-panel-' + $index)">
					</i>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					<ng-template>
						@if (preview.notification.description) {
						<div class="alert alert-primary">
							{{preview.notification.description}}
						</div>
						}
						<ul ngbNav #nav="ngbNav" class="nav-tabs">
							<li ngbNavItem>
								<a ngbNavLink>
									HTML
								</a>
								<ng-template ngbNavContent>
									<div class="mb-2 row">
										<label class="col-sm-2 col-form-label">Subject</label>
										<div class="col-sm-10">
											<input type="text" class="form-control" placeholder="Subject"
												[(ngModel)]="preview.notification.subject">
										</div>
									</div>
									<div class="mb-2 row">
										<label class="col-sm-2 col-form-label">Body</label>
										<div class="col-sm-10">
											<rl-html-editor [(ngModel)]="preview.notification.bodyHtml"
												class="text-edit">
											</rl-html-editor>
										</div>
									</div>
								</ng-template>
							</li>
							<li ngbNavItem>
								<a ngbNavLink>
									Plain Text
								</a>
								<ng-template ngbNavContent>
									<div class="mb-2 row">
										<label class="col-sm-2 col-form-label">Subject</label>
										<div class="col-sm-10">
											<input type="text" class="form-control" placeholder="Subject"
												[(ngModel)]="preview.notification.subject">
										</div>
									</div>
									<div class="mb-2 row">
										<label class="col-sm-2 col-form-label">Body</label>
										<div class="col-sm-10">
											<textarea class="form-control" style="min-height:250px;"
												[(ngModel)]="preview.notification.body"></textarea>
										</div>
									</div>
								</ng-template>
							</li>
							@if (showFiles) {
							<li ngbNavItem>
								<a ngbNavLink>
									Attachments
								</a>
								<ng-template ngbNavContent>
									@for (document of preview.documents; track $index) {
									<div class="form-check">
										<label class="form-check-label">
											<input class="form-check-input" type="checkbox"
												[(ngModel)]="document.selected">
											{{document.fileUri | fileName}}
										</label>
									</div>
									}
								</ng-template>
							</li>
							}
						</ul>
						<div [ngbNavOutlet]="nav"></div>
					</ng-template>
				</div>
			</div>
		</div>
		}
	</div>
</div>
<div class="modal-footer" data-cy="PreviewNotifsModalFooter">
	<ng-content></ng-content>
	<button class="btn btn-success" (click)="send()" [disabled]="isExecuting"
		data-cy="PreviewNotifsButtonSend">Send</button>
</div>