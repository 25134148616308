import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, isEmpty, sortBy } from "lodash";
import { IChartData } from "rl-common/components/d3/donut-chart/donut-chart.models";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { DashboardService } from "rl-common/services/dashboard/dashboard.service";
import { ITemplateEntityCountByStatus } from "rl-common/services/entity-status-counts/entity-status-counts.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { v4 } from "uuid";
import { DonutChartComponent } from "../../../common/components/d3/donut-chart/donut-chart.component";
import { LoaderComponent } from "../../../common/components/panel/loader/loader.component";
import { WidgetConstants } from "../dashboard/widget/widget.const";
import { IWidgetOptions } from "../dashboard/widget/widget.models";
import { EntityStatusCountsService } from "./../../../common/services/entity-status-counts/entity-status-counts.service";
import { TemplatesBySelectedStatePipe } from "./selected-templates.pipe";

export interface IDealsByStatusTemplatesParams {
	appliedTemplates: IDealsByStatusTemplate[];
}

export interface IDealsByStatusTemplate extends ICharacteristicTemplate {
	dealCount: string;
	isDefaultSelection: boolean;
	isSelected: boolean;
}

@Component({
    selector: "rl-deals-by-status",
    templateUrl: "./deals-by-status.component.html",
    styleUrls: ["./deals-by-status.component.scss"],
    imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgFor, NgIf, NgClass, DonutChartComponent, LoaderComponent, TemplatesBySelectedStatePipe]
})
export class DealsByStatusComponent implements OnInit, OnDestroy {

	private dealsByStatusDropdown: NgbDropdown;
	private dealsByStatusComponentId: string;
	private templateData: IDealsByStatusTemplate[];
	private numberFormatWithCommasNoDecimals = /\B(?=(\d{3})+(?!\d))/g;

	@Input()
	specifyParentContainerDimensions = true;

	isLoading = true;
	templates: IDealsByStatusTemplate[] = [];
	dealsByStatus: ITemplateEntityCountByStatus;
	selectedTemplateLimit = 4;
	appliedTemplates: IDealsByStatusTemplate[] = [];

	private _subs: Subscription[] = [];
	get selectedTemplateLimitReached() {
		return this.templates?.filter(x => x.isSelected)?.length === this.selectedTemplateLimit;
	}

	@ViewChild("dealsByStatusDropdown")
	set content(element: NgbDropdown) {
		if (element === undefined) {
			return;
		}

		this.dealsByStatusDropdown = element;
	}

	constructor(
		@Inject(WidgetConstants.WIDGET_PARAMS) params: IDealsByStatusTemplatesParams,
		@Inject(WidgetConstants.WIDGET_OPTIONS) public widgetOptions: IWidgetOptions,
		@Inject(WidgetConstants.WIDGET_INDEX) public widgetIndex: number,
		private readonly _oneConfigService: OneConfigService,
		private readonly _entityStatusCountsService: EntityStatusCountsService,
		private readonly _dashboardService: DashboardService
	) {
		this.appliedTemplates = params?.appliedTemplates || [];
	}

	ngOnInit() {
		this.dealsByStatusComponentId = v4();
		this.loadTemplates();
		this.addDealsByStatusDropdownCloseEvent();
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	private addDealsByStatusDropdownCloseEvent(): void {
		if (this.dealsByStatusDropdown !== undefined) {
			this.dealsByStatusDropdown.openChange.subscribe(x => {
				if (!this.dealsByStatusDropdown.isOpen()) {
					this.cleanupNotAppliedTemplateSelections();
				}
			});
		}
	}

	private loadTemplates(): void {
		const charTypeId = CharTypeId.Transaction;
		this.isLoading = true;
		this.templateData = <IDealsByStatusTemplate[]>this._oneConfigService.getTemplates(charTypeId);
		const sub = this._entityStatusCountsService.fetchStatusCounts(charTypeId)
			.subscribe(results => {
				this.dealsByStatus = results;
				this.templateData = sortBy(this.templateData, x => x.templateName);
				this.templateData = this.templateData.filter(x => this.dealsByStatus[x.templateID] !== undefined);
				const defaultSelectedTemplates = sortBy(this.templateData, x => x.sequenceNumber).slice(0, 4);
				const selectedTemplates = isEmpty(this.appliedTemplates) ? defaultSelectedTemplates : this.appliedTemplates;
				this.templateData.forEach(x => {
					let valueSum = 0;
					this.dealsByStatus[x.templateID].forEach(entityCountByStatus => valueSum += entityCountByStatus.value);
					x.dealCount = valueSum.toString().replace(this.numberFormatWithCommasNoDecimals, ",");
					x.isDefaultSelection = !!defaultSelectedTemplates.find(t => t.templateID === x.templateID);
					x.isSelected = !!selectedTemplates.find(t => t.templateID === x.templateID);
				});

				this.templates = this.templateData;
				this.appliedTemplates = this.templateData.filter(x => x.isSelected).splice(0);
				this.isLoading = false;
			});

		this._subs.push(sub);
	}

	private cleanupNotAppliedTemplateSelections(): void {
		const updatedTemplates = cloneDeep(this.templates);
		const appliedTemplates = this.appliedTemplates;

		updatedTemplates.forEach(x => {
			x.isSelected = appliedTemplates.find(y => y.templateID === x.templateID) !== undefined;
		});

		this.templates = updatedTemplates;
	}

	public getChartData(templateItem: IDealsByStatusTemplate) {
		const mappedData = this.dealsByStatus[templateItem.templateID]
			.slice(0, 10)
			.map(entityCountByStatus => {
				return {
					name: entityCountByStatus.key,
					value: entityCountByStatus.value
				};
			});

		const chartData: IChartData = {
			chartId: `dealsByStatusChart_${this.dealsByStatusComponentId}_${templateItem.templateID}`,
			chartTitleHeader: templateItem.dealCount,
			chartTitleDescription: templateItem.templateName,
			width: 200,
			height: 200,
			specifyParentContainerDimensions: this.specifyParentContainerDimensions,
			data: mappedData
		};

		return chartData;
	}

	public removeSelectedTemplate(templateItem: IDealsByStatusTemplate) {
		const updatedTemplates = cloneDeep(this.templates);
		updatedTemplates.find(x => x.templateID === templateItem.templateID).isSelected = false;
		this.templates = updatedTemplates;
	}

	public addSelectedTemplate(templateItem: IDealsByStatusTemplate) {
		const updatedTemplates = cloneDeep(this.templates);
		updatedTemplates.find(x => x.templateID === templateItem.templateID).isSelected = true;
		this.templates = updatedTemplates;
	}

	public cancelTemplateSelection() {
		this.dealsByStatusDropdown.close();
	}

	public resetTemplateSelection() {
		const updatedTemplates = cloneDeep(this.templates);
		updatedTemplates.forEach(x => {
			x.isSelected = x.isDefaultSelection;
		});
		this.templates = updatedTemplates;
		this.appliedTemplates = cloneDeep(updatedTemplates.filter(x => x.isSelected));
		this.dealsByStatusDropdown.close();
	}

	public loadDealsByStatusForSelectedTemplates(): void {
		this.appliedTemplates = cloneDeep(this.templates.filter(x => x.isSelected));
		this.dealsByStatusDropdown.close();
		const params: IDealsByStatusTemplatesParams = {
			appliedTemplates: this.appliedTemplates
		};
		this._dashboardService.updateWidgetParams(this.widgetOptions.id, params);
	}
}
