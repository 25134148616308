import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Provider } from "@angular/core";
import { AlliantEntityMinimal } from "rl-common/services/alliant/models/alliant-entity-minimal";
import { ICollectionFieldDetail } from "rl-common/services/alliant/models/i-collection-field-detail";
import { ICollectionPropertiesMetadata } from "rl-common/services/alliant/models/i-collection-properties-metadata";
import { Subscription } from "rxjs";
import { FindAlliantEntitiesComponent } from "../find-alliant-entities/find-alliant-entities.component";
import { FindAlliantEntitiesModalAdapter, IFindAlliantEntitiesModalComponent } from "./find-alliant-entities-modal.adapter";
import { ISelectEvent } from "./find-alliant-entities-modal.models";

@Component({
    selector: "rl-find-alliant-entities-modal",
    templateUrl: "./find-alliant-entities-modal.component.html",
    styleUrls: ["./find-alliant-entities-modal.component.scss"],
    imports: [FindAlliantEntitiesComponent]
})
export class FindAlliantEntitiesModalComponent implements OnInit, OnDestroy, IFindAlliantEntitiesModalComponent {

	@Input()
	fieldDetail: ICollectionFieldDetail;

	@Input()
	metadata: ICollectionPropertiesMetadata;

	@Input()
	entity: AlliantEntityMinimal;

	@Output()
	onSelect = new EventEmitter<ISelectEvent>();

	selected: AlliantEntityMinimal[] = [];

	private readonly _subs: Subscription[] = [];

	constructor() { }

	ngOnInit(): void {
		if (this.entity) {
			this.selected = [this.entity];
		}
	}

	close() {
		this.onSelect.emit({ success: false, entities: []});
	}

	select(entities: AlliantEntityMinimal[]) {
		this.selected = entities;
	}

	done() {
		this.onSelect.emit({ success: true, entities: this.selected});
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}

export const FINDALLIANTENTITIES_MODAL_PROVIDER: Provider = {
  provide: FindAlliantEntitiesModalAdapter,
  useFactory: () => new FindAlliantEntitiesModalAdapter(FindAlliantEntitiesModalComponent)
};

