import { transition, trigger, useAnimation } from "@angular/animations";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import _ from "lodash";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { requireCheckboxesToBeCheckedValidator } from "rl-common/components/char-data/elements/element-validators";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntityTemplateId, IParty, IPartyAssociation, IPartyCollection, IPartyGroup, IPartyTemplate } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { ProgressService } from "rl-common/services/progress.service";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { SessionService } from "rl-common/services/session.service";
import { Observable, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";
import { TrimWhitespacePipe } from "../../../../../common/pipes/trim-whitespace.pipe";
import { TemplateLandingService } from "../../templates-landing/templates-landing.service";

interface TemplatePartyForm {
	sourceParty?: FormControl<IParty>;
	templatePartyName: FormControl<string>;
	partyGroup?: FormControl<IPartyGroup>;
	required: FormControl<number>;
	multiple: FormControl<number>;
	editable: FormControl<number>;
	deletable: FormControl<number>;
	toggleValidContactTemplates: FormControl<boolean>;
	validContactTemplates: AbstractControl<any, any>;
}

@Component({
	selector: "rl-template-party-edit",
	templateUrl: "./template-party-edit.component.html",
	styleUrls: ["./template-party-edit.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, ReactiveFormsModule, FormsModule, DropdownSingleComponent, TextInputComponent, CheckboxInputComponent, NgFor, TrimWhitespacePipe, AsyncPipe]
})

export class TemplatePartyEditComponent implements OnInit, OnDestroy {
	@Input()
	_partyCollection: IPartyCollection;

	@Input()
	_groupID: number;

	@Input()
	groupName: string;

	@Input()
	_partyID: number;

	@Output()
	onComplete = new EventEmitter<boolean>();

	charTypeId: number;
	templateId: number;
	templateName$: Observable<string>;
	charTypeName$: Observable<string>;

	modalLoaded: boolean = false;
	_subs: Subscription[] = [];

	title: string;
	sequenceNumber: number;
	formLoaded = false;
	allContactTemplatesChecked = false;
	isSaving = false;

	groups: DropdownOptions<IPartyGroup> = {
		items: [],
		rowKeyFn: (type: IPartyGroup) => type.groupID,
		rowLabelFn: (type: IPartyGroup) => type.groupLabel
	};
	parties: IPartyAssociation[] = [];
	allParties: DropdownOptions<IParty> = {
		items: [],
		rowKeyFn: (type: IParty) => type.partyID,
		rowLabelFn: (type: IParty) => type.partyName
	};
	partyTemplates: IEntityTemplateId[] = [];
	contactTemplateNames: Array<{ templateName: string; templateId: number }> = [];
	form: FormGroup<TemplatePartyForm>;
	contactTemplates: ICharacteristicTemplate[] = [];
	userId: number;

	get isCreate() {
		return !this._partyID;
	}

	get isEdit() {
		return !!this._partyID;
	}

	constructor(
		private readonly _fb: FormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _entityConfigService: EntityConfigService,
		private readonly _progressService: ProgressService,
		private readonly _templateLandingService: TemplateLandingService,
		private readonly _sessionService: SessionService,
		private readonly _promptService: PromptService) {
	}

	async ngOnInit(): Promise<void> {
		const sub = this._templateLandingService.charTypeTemplateIds$.pipe(
			tap(([charTypeId, templateId]) => {
				this.charTypeId = charTypeId;
				this.templateId = templateId;
			})
		).subscribe();
		this._subs.push(sub);

		this.templateName$ = this._templateLandingService.templateMetaData$.pipe(map(cmd => cmd.template.templateName));
		this.charTypeName$ = this._templateLandingService.charData$.pipe(map(cmd => cmd[0].charTypeName));

		this.userId = this._sessionService.userId;

		this.getPartyCollectionData();
		await this.getContactTemplates();
		if (this.isCreate) {
			this.filterAllParties();
			this.getCreateSequenceNumber();
			await this.setupCreateParty();
		}
		if (this.isEdit) {
			this.partyTemplates = await this._entityConfigService.getPartyTemplates(this.charTypeId, this.templateId, this._partyID).toPromise();
			await this.setupEditParty();
		}
		this.modalLoaded = true;

	}

	updateGroupName(event: IPartyGroup) {
		if (this.isCreate) {
			this.groupName = event.groupLabel;
		}
	}

	updateTemplatePartyName(event: IParty) {
		this.form.controls.templatePartyName.setValue(event.partyName);
	}

	getPartyCollectionData() {
		this.groups.items = this._partyCollection.groups;
		this.parties = this._partyCollection.parties;
		this.allParties.items = this._partyCollection.allParties;
	}

	getPartyDefaultById(partyID = null): IParty {
		return {
			divisionID: null,
			charTypeID: null,
			templateID: null,
			partyID: partyID,
			partyGroupID: null,
			partyName: null,
			partyDescription: null,
			requiredIndicator: null,
			multipleIndicator: null,
			editIndicator: null,
			deleteIndicator: null,
			primaryIndicator: null,
			visibilityIndicator: null,
			sequenceNumber: null,
			systemIndicatorId: null
		}
	}

	getGroupDefaultById(groupID = null): IPartyGroup {
		return {
			divisionID: null,
			groupID: groupID,
			groupLabel: null,
			groupDescription: null,
			expandIndicator: null,
			expandGroupIndicator: null,
			systemIndicator: null,
			visibilityIndicator: null,
			sequenceNumber: null,
			userID: null
		}
	}

	filterAllParties() {
		this.allParties.items = this.allParties.items.filter(ap => !this.parties.some(p => p.sourceParty === ap.partyName));
	}

	async getContactTemplates() {
		this.contactTemplates = await (await this._entityConfigService.getCharTemplates(CharTypeId.User).toPromise());
	}

	getCreatePartyId() {
		return Math.max(...this.allParties.items.map(p => p.partyID)) + 1;
	}

	getCreateSequenceNumber() {
		this.sequenceNumber = Math.max(...this.parties.map(p => p.sequenceNumber)) + 1;
	}

	async setupEditParty() {
		const editParty = this.parties.find(p => p.partyID === this._partyID) as IPartyAssociation;
		this.sequenceNumber = editParty.sequenceNumber;
		const sourceParty = this.allParties.items.find(p => p.partyName === editParty.sourceParty) as IParty;
		const _fb = this._fb;
		this.form = _fb.group<TemplatePartyForm>({
			sourceParty: _fb.control({ value: this.getPartyDefaultById(sourceParty.partyID), disabled: true }, Validators.required),
			templatePartyName: _fb.control({ value: editParty.partyName, disabled: false }, Validators.required),
			partyGroup: _fb.control({ value: this.getGroupDefaultById(editParty.partyGroupID), disabled: false }, Validators.required),
			required: _fb.control(editParty.requiredIndicator),
			multiple: _fb.control(editParty.multipleIndicator),
			editable: _fb.control(editParty.editIndicator),
			deletable: _fb.control(editParty.deleteIndicator),
			toggleValidContactTemplates: _fb.control(false),
			validContactTemplates: _fb.group({})
		});

		const validContactTemplates = this.form.get("validContactTemplates") as FormGroup;
		validContactTemplates.addValidators(requireCheckboxesToBeCheckedValidator());

		let checkedContactTemplates = 0;
		for await (const c of this.contactTemplates) {
			this.contactTemplateNames.push({ templateName: c.templateName, templateId: c.templateID });
			const isChecked = !!this.partyTemplates.find(t => t.templateId === c.templateID);
			validContactTemplates.addControl(c.templateName, new FormControl(isChecked));
			checkedContactTemplates = isChecked ? ++checkedContactTemplates : checkedContactTemplates;
		}

		if (checkedContactTemplates === this.contactTemplates.length) {
			this.form.controls.toggleValidContactTemplates.setValue(true);
		}

		this.formLoaded = true;
	}

	async setupCreateParty() {
		const _fb = this._fb;
		this.form = _fb.group<TemplatePartyForm>({
			sourceParty: _fb.control({ value: this.getPartyDefaultById(), disabled: false }, Validators.required),
			templatePartyName: _fb.control({ value: "", disabled: false }, Validators.required),
			partyGroup: _fb.control({ value: this.getGroupDefaultById(this._groupID), disabled: false }, Validators.required),
			required: _fb.control(0),
			multiple: _fb.control(1),
			editable: _fb.control(1),
			deletable: _fb.control(1),
			toggleValidContactTemplates: _fb.control(true),
			validContactTemplates: _fb.group({})
		});

		const validContactTemplates = this.form.get("validContactTemplates") as FormGroup;
		validContactTemplates.addValidators(requireCheckboxesToBeCheckedValidator());

		for await (const c of this.contactTemplates) {
			this.contactTemplateNames.push({ templateName: c.templateName, templateId: c.templateID });
			validContactTemplates.addControl(c.templateName, new FormControl(true));
		}

		this.formLoaded = true;
	}

	toggleContactTemplates($event: Event) {
		const contactTemplates = this.form.get("validContactTemplates") as FormGroup;
		for (const checkbox in contactTemplates.controls) {
			if (Object.prototype.hasOwnProperty.call(contactTemplates.controls, checkbox)) {
				contactTemplates.controls[checkbox].setValue(($event.currentTarget as HTMLInputElement).checked);
			}
		}
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			this._progressService.startProgress();
			let success = true;
			try {
				const validContactTemplatesValue = _.keys(_.pickBy((this.form.controls.validContactTemplates as FormGroup).value));
				const selectedContactTemplates = this.contactTemplateNames.filter(c => validContactTemplatesValue.some(v => v === c.templateName)).map(vct => vct.templateId);

				const template: Partial<IPartyTemplate> = {
					sourcePartyID: this.form.controls.sourceParty.value.partyID,
					partyName: this.form.controls.templatePartyName.value,
					partyGroupID: this.form.controls.partyGroup.value.groupID,
					required: +this.form.controls.required.value,
					multiple: +this.form.controls.multiple.value,
					editable: +this.form.controls.editable.value,
					deletable: +this.form.controls.deletable.value,
					primary: 0, // deprecated
					validContactTemplates: selectedContactTemplates
				};
				template.divID = this._partyCollection.divID;
				template.charTypeID = this.charTypeId;
				template.templateID = this.templateId;
				template.sequenceNumber = this.sequenceNumber;
				template.userID = this.userId;
				template.partyID = this._partyID;

				if (this.isEdit) { // edit
					await this._entityConfigService.updatePartyTemplate(template as IPartyTemplate).toPromise();
				} else { // create
					await this._entityConfigService.createPartyTemplate(template as IPartyTemplate).toPromise();
				}
			} catch {
				success = false;
			} finally {
				this._progressService.endProgress();
				this.isSaving = false;
			}
			if (success) {
				this.onComplete.next(true);
				this._growlerService.success().growl("Your changes were saved.");
			} else {
				this._growlerService.error().growl("Your changes did not save, please check the form and try again.");
			}
		}
	}

	ngOnDestroy(): void {
		this._subs.forEach(s => s.unsubscribe());
	}

	close() {
		this.onComplete.next(null);
	}
}
