<div class="modal-header">
	<h2 class="modal-title">Save as {{ setCharTypeId | pluralCharTypeName }} Profile</h2>
	<button class="btn btn-close" (click)="cancel()" title="Close">
		<i class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<div class="container">
		<h5>Enter a name for the new {{ setCharTypeId | pluralCharTypeName }} Profile:</h5>
		<input class="form-control w-50" type="text" [(ngModel)]="title" />
	</div>
</div>

<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success ms-3" [disabled]="!canApply()" (click)="apply()">Save</button>
</div>