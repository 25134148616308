import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "rl-google-app-store-badge",
    templateUrl: "./google-app-store-badge.component.html",
    styleUrls: ["./google-app-store-badge.component.scss"]
})
export class GoogleAppStoreBadgeComponent implements OnInit {

	@Input()
	height: number = 45;

	constructor() { }

	ngOnInit(): void {
	}

}
