<div [formGroup]="formGroup">
	<div class="modal-header">
		<h2 class="modal-title">{{ isCreate ? "Create" : "Edit" }} Currency Conversion</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div *ngIf="!isLoading" class="modal-body scroll-vert">
		<div class="char-data__group">
			<div class="char-data__group__element" *ngIf="fromLabel === 'Group'">
				<div class="char-data__label">
					<span>{{fromLabel}} Currency</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<span [title]="currency" class="show-line-breaks">{{currency}}</span>
					</div>
				</div>
			</div>
			<rl-currency-selector *ngIf="fromLabel !== 'Group'" [label]="fromLabel" [currencyValues]="currencyValues"
				[defaultCurrency]="currencyConversion?.fromCurrency ?? fromCurrency.value" [formControl]="fromCurrency">
			</rl-currency-selector>
			<rl-currency-selector [label]="toLabel" [currencyValues]="currencyValues"
				[defaultCurrency]="currencyConversion?.toCurrency" [formControl]="toCurrency">
			</rl-currency-selector>
			<rl-currency-selector *ngIf="!isDeprecated" [label]="rateSourceLabel" [currencyValues]="rateSourceValues"
				[formControl]="rateSource">
			</rl-currency-selector>
			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Rate</span>
				</div>
				<div class="char-data__control container m-0" [class.has-error]="hasError(rate)">
					<div class="char-data-element-display">
						<input [disabled]=true type="number" ng-pattern="/^[0-9]{1,7}(\.[0-9]+)?$/" max="9999999"
							[formControl]="rate" class="form-control" />
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Effective Date</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<rl-date-input formControlName="effectiveDate"></rl-date-input>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="error">{{errorMessage}}</div>
	<div *ngIf="!isLoading" class="modal-footer">
		<button (click)="cancel()" class="btn btn-light me-1">Cancel</button>
		<button (click)="save()" [disabled]="!canSave()" class="btn btn-success">
			{{buttonText}}
		</button>
	</div>
	<div *ngIf="isLoading">
		<p class="p-3">
			<rl-loader></rl-loader>
		</p>
	</div>
</div>