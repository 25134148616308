import { ICharDataExtDataDateMathRel } from "rl-common/models/i-char-data-ext-data-date-math-rel";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { IEntity } from "rl-common/models/i-entity";
import { Observable } from "rxjs";
import { IExtraGridColumnResults } from "../grid-view/models/i-extra-grid-column-results";
import { IGridViewColumn } from "../grid-view/models/i-grid-view-column";

export enum DateMathSearchStep {
	SearchSetupTable = -1,
	Entity = 1,
	EntityDetail = 2,
	DetailCharDates = 3,
	AssocTemplates = 4,
	AssocList = 5,
	AmountsList = 6,
	SelectRecCharID = 7,
	SelectCharId = 8,
	RelativeToCatalog = 9,
	RelativeToRights = 10,
	RelativeToTableRow = 11,
	RelativeToDeal = 12
}

export interface DateMathSearchRow {
	step: DateMathSearchStep;
	title: string;
	entityId?: string;
	templateId?: string;
	charTypeId?: number;
	charId?: number;
	recCharId?: number;
}

export interface DateMathSearchTableSelectEvent {
	entityId: string;
	charId: number;
	recCharId: number;
	step: DateMathSearchStep;
	relativeCatalogCharId?: number;
	relativeDealCharId?: number;
}

export interface DateMathEntitiesSearchRequest {
	rows?: number;
	start?: number;
	keywords?: string;
	templateId?: number;
	charTypeId?: number;
	contextEntityId: string;
	parentEntityId: string;
	onlyAssociated: boolean;
}

export interface DateMathEntitiesSearchResponse {
	rows: DateMathSearchRow[];
	numFound: number;
	templates: { key: number; value: string }[];
}

export interface CommonDateCharsRequest {
	charTypeId: number;
}

export interface CommonDateCharsResult {
	commonCmds: ICharacteristicMetaData[];
}

export interface DateMathSearchDetailsAssocRequest {
	contextEntityId: string;
}

export interface DateMathSearchDetailsAssocResult {
	rows: DateMathSearchRow[];
}

export interface DateMathSearchDetailsDateCharsRequest {
	contextEntityId: string;
}

export interface DateMathSearchDetailsDateCharsResult {
	rows: DateMathSearchRow[];
}

export interface DateMathSearchAssocTypeTemplatesRequest {
	contextEntityId: string;
	charTypeId: number;
}

export interface DateMathSearchAssocTypeTemplatesResults {
	rows: DateMathSearchRow[];
}

export interface DateMathSearchAssocEntityDataRequest {
	contextEntityId: string;
	charTypeId: number;
	templateId: number;
	gridViewColumns: IGridViewColumn[];
	keywords: string;
	start: number;
	rows: number;
}

export interface DateMathSearchAssocEntityDataResults {
	records: IEntity[];
	charDatas: _.Dictionary<ICharacteristicData[]>;
	templates: _.Dictionary<ICharacteristicMetaDataCollection>;
	numFound: number;
	recordIdsWithAmounts: number[];
	extraGridColumnResults: IExtraGridColumnResults;
}

export interface DateMathSearchSetupTableRequest {
	contextEntityId: string;
	dateMathRel: ICharDataExtDataDateMathRel;
	rows: number;
}

export interface DateMathSearchSetupTableResults {
	path: DateMathSearchRow[];
	detailRows: DateMathSearchRow[];
	records: IEntity[];
	charDatas: _.Dictionary<ICharacteristicData[]>;
	templates: _.Dictionary<ICharacteristicMetaDataCollection>;
	start: number;
	numFound: number;
	recordIdsWithAmounts: number[];
	usageCatalogTitles: _.Dictionary<string[]>;
}

export interface IChildCharDataCreateOrFindChar {
	entityId: string;
	instanceId: string;
	ensureCharData(charId: number, defaults: { value?: string; valueId?: number }, asLocalChange: boolean, onComplete: (recCharId: number) => void): Observable<number>;
}

export interface DateMathCreateParentCharDataRequest {
	entityId: string;
	charId: number;
	value: string;
}

export interface DateMathSearchAssocAmountsDataRequest {
	contextEntityId: string;
	keywords: string;
	start: number;
	rows: number;
}

export interface DateMathSearchAssocAmountsDataResults {
	records: IEntity[];
	charDatas: _.Dictionary<ICharacteristicData[]>;
	templates: _.Dictionary<ICharacteristicMetaDataCollection>;
	numFound: number;
}

export interface RelativeToCatalogCorrection {
	assetId: number;
	tagLabel: string;
	recCharId: number;
	charId: number;
}
