import { transition, trigger, useAnimation } from "@angular/animations";
import { NgClass, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { IParty } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { SessionService } from "rl-common/services/session.service";
import { Subscription } from "rxjs";
import { take, tap } from "rxjs/operators";
import { NumberInputComponent } from "../../../../../../common/components/number-input/number-input.component";
import { TextAreaComponent } from "../../../../../../common/components/text/text-area/text-area.component";
import { TextInputComponent } from "../../../../../../common/components/text/text-input/text-input.component";

interface SourcePartyEditForm {
	partyName: FormControl<string>;
	partyDescription: FormControl<string>;
	systemIndicatorId: FormControl<number>;
}

@Component({
	selector: "rl-source-party-edit",
	templateUrl: "./source-party-edit.component.html",
	styleUrls: ["./source-party-edit.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, ReactiveFormsModule, FormsModule, NgClass, TextInputComponent, TextAreaComponent, NumberInputComponent]
})
export class SourcePartyEditComponent implements OnInit, OnDestroy {

	@Input()
	party: IParty = null;

	@Output()
	onComplete = new EventEmitter<boolean>();

	formLoaded = false;
	title = "";
	form: FormGroup<SourcePartyEditForm>;
	isSaving = false;
	systemSourceField = false;
	_subs: Subscription[] = [];

	isSuperAdmin: boolean;

	get isCreate() {
		return !this.party;
	}

	get isEdit() {
		return !!this.party;
	}

	constructor(
		private _entityConfigService: EntityConfigService,
		private readonly _fb: FormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _sessionService: SessionService) { }

	ngOnInit(): void {
		const sub2 = this._sessionService.isRlAdmin$.pipe(
			take(1),
			tap(result => {
				this.isSuperAdmin = result;

				if (this.isCreate) {
					this.title = "Create New Source Party";
					this.setupCreateSourceParty();
				} else {
					this.title = "Edit Source Party - <strong>" + this.party.partyName + "</strong>";
					this.setupEditSourceParty();
				}
			})
		).subscribe();
		this._subs.push(sub2);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

	setupCreateSourceParty() {
		const _fb = this._fb;
		this.form = _fb.group<SourcePartyEditForm>({
			partyName: _fb.control(null, Validators.required),
			partyDescription: _fb.control(null, Validators.required),
			systemIndicatorId: _fb.control({ value: 0, disabled: !this.isSuperAdmin }),
		});

		this.formLoaded = true;
	}

	setupEditSourceParty() {
		const _fb = this._fb;
		this.form = _fb.group<SourcePartyEditForm>({
			partyName: _fb.control(this.party.partyName, Validators.required),
			partyDescription: _fb.control(this.party.partyDescription, Validators.required),
			systemIndicatorId: _fb.control({ value: this.party.systemIndicatorId, disabled: !this.isSuperAdmin }),
		});

		this.formLoaded = true;
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			let success = true;
			let errorMessage = "";
			try {
				const sourceParty: Partial<IParty> = {
					partyID: this.party ? this.party.partyID : null,
					partyName: this.form.controls.partyName.value,
					partyDescription: this.form.controls.partyDescription.value,
					systemIndicatorId: this.form.controls.systemIndicatorId.value,
					sequenceNumber: this.party ? this.party.sequenceNumber : null
				};

				if (this.isCreate) {
					await this._entityConfigService.createSourceParty(sourceParty as IParty).toPromise();
				}
				if (this.isEdit) {
					await this._entityConfigService.updateSourceParty(sourceParty as IParty).toPromise();
				}
			} catch (e) {
				success = false;
				errorMessage = e?.error?.message;
			} finally {
				this.isSaving = false;
			}
			if (success) {
				this.onComplete.next(true);
				this._growlerService.success().growl("Source Party saved.");
			} else {
				this._growlerService.error().growl(errorMessage);
			}
		}
	}

	close() {
		this.onComplete.next(null);
	}
}
