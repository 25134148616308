import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IGridView } from "rl-common/services/grid-view/models/i-grid-view";
import { BulkConfig } from "../bulk-config.strategy";
import { BulkGridComponent } from "../bulk-grid.component";

@Component({
    selector: "rl-bulk-grid-modal",
    templateUrl: "./bulk-grid-modal.component.html",
    styleUrls: ["./bulk-grid-modal.component.scss"],
    imports: [BulkGridComponent]
})
export class BulkGridModalComponent implements OnInit {
	@Input()
	config: BulkConfig;

	@Input()
	gridView: IGridView;

	@Input()
	template: number;

	@Output()
	onComplete = new EventEmitter<boolean>();
	constructor() { }

	ngOnInit() {
	}
	complete(isComplete: boolean) {
		this.onComplete.next(isComplete);
	}
}
