import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { IDbValidationError } from "rl-common/models/i-db-validation-error";
import { CharTypeNamePipe } from "../../../../pipes/char-type-name.pipe";
import { ParseCharTypePipe } from "../../../../pipes/parse-char-type.pipe";
import { ParseRecordIdPipe } from "../../../../pipes/parse-record-id.pipe";
import { PluralCharTypeNamePipe } from "../../../../pipes/plural-char-type-name.pipe";

@Component({
    selector: "rl-validation-message",
    templateUrl: "./validation-message.component.html",
    styleUrls: ["./validation-message.component.scss"],
    imports: [NgSwitch, NgSwitchCase, NgIf, NgSwitchDefault, CharTypeNamePipe, PluralCharTypeNamePipe, ParseCharTypePipe, ParseRecordIdPipe]
})
export class ValidationMessageComponent implements OnInit {


	@Input()
	validationError: IDbValidationError;

	constructor() { }

	ngOnInit() {
	}

}
