<div class="modal-header">
	<h2 class="modal-title" [innerHTML]="title"></h2>
</div>
<div class="modal-body">
	<p>Paste your generated access token from the {{environmentName}} environment below:</p>
	<div class="d-flex align-items-center mb-2">
				<input type="text" class="form-control flex-fill" aria-label="accessKey" [(ngModel)]="accessToken" />
	</div>
	<button class="btn btn-info btn-sm" [disabled]="isLoading"
		(click)="accept()">
		<i *ngIf="isLoading" class="far fa-spinner fa-spin me-1"></i>
		Validate Access Token
	</button>
</div>
<div class="modal-footer" data-cy="DeleteConfirmationModalFooter">
	<button type="button" class="btn btn-light" (click)="decline()"
		data-cy="ConfirmationModalCancelButton">Cancel</button>
</div>