import { Component, EventEmitter, OnChanges, Output, SimpleChanges, effect, input, signal } from "@angular/core";
import { BitmapAllocatorService } from "rl-common/services/bitmap-allocator/bitmap-allocator.service";
import { SearchService } from "rl-common/services/search/search.service";
import { IChipWithTitle } from "../chips/chips.component";
import { ChipComponent } from "../chip.component";

@Component({
    selector: "rl-chips-bitmap",
    templateUrl: "./chips-bitmap.component.html",
    styleUrl: "./chips-bitmap.component.scss",
    imports: [ChipComponent]
})
export class ChipsBitmapComponent implements OnChanges {

	charTypeId = input.required<number>();
	bitmap = input<string>(null);
	limit = input(25);
	chips = signal<IChipWithTitle<number>[]>([]);
	moreCount = signal<number>(null);

	@Output()
	removeItem = new EventEmitter<number>();

	cachedTitles = new Map<number, string>();

	constructor(
		private readonly _searchService: SearchService,
		private readonly _bitmapAllocator: BitmapAllocatorService
	) {

		// have changes to these signals cause an update to chips
		effect(async () => {
			const charTypeId = this.charTypeId();
			const bitmap = this.bitmap();
			const ids: number[] = [];

			if (bitmap != null) {
				const deserialized = this._bitmapAllocator.allocateBitmap(bitmap);
				for (const id of deserialized) {
					ids.push(id);
					if (ids.length >= this.limit()) {
						break;
					}
				}
				const bitmapSize = deserialized.size;
				deserialized.dispose();

				const fetchIds = ids.filter(id => !this.cachedTitles.has(id));
				if (fetchIds.length > 0) {
					const titles = await this._searchService.getTitles(charTypeId, fetchIds).toPromise();
					titles.forEach((value, key) => {
						this.cachedTitles.set(key, value);
					});
				}
				const chips = ids.map((id) => ({ title: this.cachedTitles.get(id), chip: id } as IChipWithTitle<number>));
				this.chips.set(chips);
				this.moreCount.set(bitmapSize - ids.length);
			} else {
				this.chips.set([]);
				this.moreCount.set(0);
			}
		}, { allowSignalWrites: true });
	}

	ngOnChanges(changes: SimpleChanges): void {

	}
}