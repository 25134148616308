import { EventEmitter, Injectable, Type } from "@angular/core";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
import { IBuildAccountingProcessResult } from "../accounting-processes.models";

export interface IBuildAccountingProcessModalComponent {
	selectedContact: IAccountingContact;
	accountingProcessId: string;
	createOperation: boolean;
	editOperationId: string;
	templatesDateCmdsChange: EventEmitter<{ [key: string]: ICharacteristicMetaData[]; }>;
	anchorTemplateChange: EventEmitter<ICharacteristicTemplate>;
	onClose: EventEmitter<IBuildAccountingProcessResult>;
}

@Injectable({ providedIn: "root" })
export class BuildAccountingProcessModalAdapter extends ModalAdapter<IBuildAccountingProcessModalComponent> {
	constructor(type: Type<IBuildAccountingProcessModalComponent>) {
		super(type);
	}

}


