import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { DateGroupType } from "rl-common/services/accounting-processes/models/date-group-type";
import { DateType } from "rl-common/services/accounting-processes/models/date-type";
import { PopulateType } from "rl-common/services/accounting-processes/models/populate-type";
// not sure if this is the type we actually need
import { IBuildAccountingOperationDate } from "../build-accounting-operation/models/i-build-accounting-operation-date";

export interface ICreditDebitAccount {
	creditName: string;
	creditId: string;
	debitName: string;
	debitId: string;
}

@Component({
	selector: "rl-accounting-operation-date",
	templateUrl: "./accounting-operation-date.component.html",
	styleUrls: ["./accounting-operation-date.component.scss"]
})
export class AccountingOperationDateComponent implements OnInit, OnChanges {
	dateLabel: string;
	dateGroupTypeId: number;

	@Input()
	anchorTemplateId: number;

	@Input()
	date: IBuildAccountingOperationDate;

	@Input()
	datesDictionary: { [key: number]: string } = {};

	populateTypes: [PopulateType, string][] = [
		[PopulateType.DateOfEvent, "Date of Event"],
		[PopulateType.ValueInField, "Value in Field"],
		[PopulateType.LatestOfValuesInDateTriggers, "Latest of Values in this Operation's Date Triggers"],
		[PopulateType.EarliestOfValuesInDateTriggers, "Earliest of Values in this Operation's Date Triggers"],
	];

	constructor() { }

	ngOnInit() {
		this.dateGroupTypeId = this.date.dateGroupTypeId;
		this.updateDateLabel();
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if ((changes.date && changes.date.currentValue !== changes.date.previousValue) || (changes.datesDictionary && changes.datesDictionary.currentValue !== changes.datesDictionary.previousValue)) {
			this.updateDateLabel();
		}
	}

	private updateDateLabel() {
		if (this.date?.dateGroupTypeId === DateGroupType.FirstRecord) {
			const transactionDateFieldText = `<strong>Transaction Date Field</strong>`;

			if (this.date?.dateTypeId === DateType.DateOfEvent) {
				this.dateLabel = `When the Triggers are met, the ${transactionDateFieldText} will be populated with the <strong>Date of Event</strong>.`;
			} else if (this.date?.dateTypeId === DateType.DateFieldValue) {
				const charLabel = this.datesDictionary[this.date.characteristicId];
				const defaultCharLabel = this.datesDictionary[this.date.characteristicIdDefault1];

				if ((this.date?.characteristicIdDefault1 === null) || (this.date?.characteristicIdDefault1 === undefined)) {
					this.dateLabel = `When the Triggers are met, the ${transactionDateFieldText} will be populated with the <strong>${charLabel}</strong> field value.`;
				} else {
					this.dateLabel = `When the Triggers are met, the ${transactionDateFieldText} will be populated with the <strong>${charLabel}</strong> field value. If blank, it will default to <strong>${defaultCharLabel}</strong>.`;
				}
			}
		}
		if (this.date?.dateGroupTypeId === DateGroupType.AdditionalSection) {
			const charLabel = this.datesDictionary[this.date.characteristicId] ?? "MISSING/INVALID FIELD";
			const populatedLabel = this.populateTypes.find(p => p[0] === this.date.populateTypeId)[1];
			this.dateLabel = `The Date Field <strong>${charLabel}</strong> will be populated with <strong>${populatedLabel}</strong>.`;
		}
	}
}
