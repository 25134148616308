<aw-wizard [awNavigationMode] class="rl-wizard mt-3" navBarLayout="small">
	<aw-wizard-step [stepTitle]="'Select Document'">
		<div class="modal-body">
			<h3>Select Document</h3>
			<div class="mb-4" *ngIf="(searchOptions$ |  async) as searchOptions">
				<rl-entity-search #entitySearch [searchOptions]="searchOptions"
					(selectedStateChange)="setSelected($event)" target="_blank">
				</rl-entity-search>
			</div>
			<h3>Set Expiration (optional)</h3>
			<div class="char-data__group__element">
				<strong class="char-data__label col-form-label py-2">
					Enter how many days after sending an envelope expires
				</strong>
				<div class="char-data__control container m-0 w-50">
					<rl-number-input [(ngModel)]="expireDays" (ngModelChange)="textValueChanged($event, 'expireDays')"
						[min]="0" [max]="999" [placeholder]="'Enter Number'">
						<prepend-with class="d-flex">
							<span class="input-group-text"><i class="far fa-hashtag"></i></span>
						</prepend-with>
					</rl-number-input>
				</div>
			</div>
			<div class="char-data__group__element">
				<strong class="char-data__label col-form-label py-2">
					Enter how many days before expiration to warn signatories
				</strong>
				<div class="char-data__control container m-0 w-50">
					<rl-number-input [(ngModel)]="warnBeforeExpireDays"
						(ngModelChange)="textValueChanged($event, 'warnBeforeExpireDays')" [min]="0" [max]="999"
						[placeholder]="'Enter Number'">
						<prepend-with class="d-flex">
							<span class="input-group-text"><i class="far fa-hashtag"></i></span>
						</prepend-with>
					</rl-number-input>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button (click)="cancel()" class="btn btn-light me-1">
				Cancel
			</button>
			<button class="btn btn-info" type="button" awNextStep [disabled]="!canContinue">
				Continue <i class="far fa-chevron-right ms-2"></i>
			</button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Add Signatories'">
		<div class="modal-body">
			<div class="row">
				<div class="col-8">
					<rl-list-selector [unSelectedList]="unSelectedList" [selectedList]="selectedList"
						[unSelectedHeader]="unSelectedHeader" [selectedHeader]="selectedHeader" [labelFn]="labelFn"
						[keyFn]="keyFn" [groupLabelFn]="groupLabelFn" (onChange)="updateLists($event)"
						[toggleReorder]="true" [reorderHeader]="reorderHeader">
						<div class="email-form mt-2 text-end" *ngIf="!partiesOnly">
							<input type="email" class="form-control form-control-sm" placeholder="Enter email address"
								[(ngModel)]="email" (keyup)="isValidEmail" />
							<div *ngIf="!!email && !isValidEmail" class="text-danger">
								<small>Invalid email address</small>
							</div>
							<button type="button" class="btn btn-light btn-sm mt-1" [disabled]="!isValidEmail"
								(click)="addEmail()">Add</button>
							<em class="d-block mt-3 text-start"><strong>Note:</strong> Parties added as Signatories
								cannot be added as CC Recipients, and vice versa.</em>
						</div>
					</rl-list-selector>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-info" type="button" awPreviousStep>
				<i class="far fa-chevron-left ms-2"></i> Previous
			</button>
			<button (click)="cancel()" class="btn btn-light me-1">
				Cancel
			</button>
			<button class="btn btn-success" awNextStep [disabled]="!canContinue">
				Continue <i class="far fa-chevron-right ms-2"></i>
			</button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Add CC Recipients'">
		<div class="modal-body">
			<div class="row">
				<div class="col-8">
					<!-- TODO: Update the lists and functions here -->
					<rl-list-selector [unSelectedList]="unSelectedListCc" [selectedList]="selectedListCc"
						[unSelectedHeader]="unselectedCcRecipientsHeader" [selectedHeader]="selectedCcRecipientsHeader"
						[labelFn]="labelFn" [keyFn]="keyFn" [groupLabelFn]="groupLabelFn"
						(onChange)="updateListsCc($event)">
						<div class="email-form mt-2 text-end">
							<input type="email" class="form-control form-control-sm" placeholder="Enter email address"
								[(ngModel)]="ccEmail" (keyup)="isValidEmailCc" />
							<div *ngIf="!!ccEmail && !isValidEmailCc" class="text-danger">
								<small>Invalid email address</small>
							</div>
							<button type="button" class="btn btn-light btn-sm mt-1" [disabled]="!isValidEmailCc"
								(click)="addEmailCc()">Add</button>
							<em class="d-block mt-3 text-start"><strong>Note:</strong> Parties added as Signatories
								cannot be added as CC Recipients, and vice versa.</em>
						</div>
					</rl-list-selector>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-info" type="button" awPreviousStep>
				<i class="far fa-chevron-left ms-2"></i> Previous
			</button>
			<button (click)="cancel()" class="btn btn-light me-1">
				Cancel
			</button>
			<button class="btn btn-success" (click)="submit()" [disabled]="isSending || !canSubmit || invalidEmails">
				Done
			</button>
		</div>
	</aw-wizard-step>
</aw-wizard>