<div class="modal-header" data-cy="AdvancedSearchModalHeader">
	<h2 class="modal-title">
		Advanced Search
		<span class="btn-icon btn-info btn-sm ms-1 cursor-pointer" data-pendo="tip-advanced-search" role="button"
			aria-label="Tooltip"><i class="far fa-question-circle"></i></span>
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="AdvancedSearchClose">
		<i class="far fa-times"></i>
	</button>
</div>
<form [formGroup]="form" (ngSubmit)="applySearch()" data-cy="AdvancedSearchModalForm">
	<div class="modal-body" data-cy="AdvancedSearchModalBody">
		<rl-advanced-search-builder [form]="form" [charTypeId]="charTypeId" [parentCharTypeId]="parentCharTypeId"
			[parentTemplateId]="parentTemplateId" data-cy="RlAdvancedSearchSearchBuilder"></rl-advanced-search-builder>
	</div>
	<div class="modal-footer d-flex justify-content-end mb-3" data-cy="AdvancedSearchModalFooter">
		<div class="d-flex flex-fill" data-cy="AdvancedSearchModalFooterInline">
			<div class="w-50 d-flex flex-column justify-content-start">
				<div class="d-flex align-items-center my-1">
					<span *ngIf="savedSearch?.id" class="w-130px text-end pe-2">Edit Name:</span>
					<input class="form-control me-2 search-name" type="text"
						placeholder="Enter name to save this search" formControlName="searchName"
						data-cy="EnterNameForSaveTextbox">
				</div>
				<div *ngIf="savedSearch?.id" class="d-flex align-items-center my-1">
					<span class="w-130px text-end pe-2">OR Save as new:</span>
					<input class="form-control me-2 search-name" type="text" placeholder="Enter new saved search name"
						formControlName="saveAsName" data-cy="EnterNewSavedSearchNameTextbox">
				</div>
			</div>
			<div class="w-50 d-flex flex-column justify-content-start">
				<label class="d-flex align-items-start mt-1">
					<input type="checkbox" class="mx-2 my-1 form-check-input" formControlName="displayOnDashboard"
						data-cy="DisplayOnDashboardCheckbox">
					Display on Dashboard
				</label>
				<label class="d-flex align-items-start mt-1" *ngIf="createGlobalPermissions">
					<input type="checkbox" class="mx-2 my-1 form-check-input" formControlName="global"
						data-cy="GlobalAdvancedSearchCheckbox">
					<span>Share this with everyone<br><em>(Note: All fields may not be visible for all users due to
							permissions)</em></span>
				</label>
			</div>
		</div>
		<div data-cy="AdvancedSearchButtonContainer" class="mt-auto">
			<button type="button" class="btn btn-light" (click)="cancel()"
				data-cy="AdvancedSearchCancel">Cancel</button>
			<button type="button" class="btn btn-success ms-3" [disabled]="!form.valid" (click)="applySearch()"
				data-cy="AdvancedSearchApply">Apply</button>
		</div>
	</div>
</form>