<div [formGroup]="formGroup" data-cy="AddPolicyModal">
	<div class="modal-header">
		<h2 class="modal-title">{{action}} {{type}} Policy</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="ModalCloseButton">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body scroll-vert">
		<div class="alert alert-danger" *ngIf="isDuplicate">
			A Policy with this name already exists.
		</div>
		<rl-form-table-row>
			<ng-template rlFormTableLabel>Title</ng-template>
			<ng-template rlFormTableControl>
				<input type="text" [formControl]="policyTitle" class="form-control" data-cy="PolicyTitleField"
					(keyup)="checkForError()" />
			</ng-template>
		</rl-form-table-row>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" class="btn btn-light me-3" data-cy="ModalCancelButton">Cancel</button>
		<button (click)="save()" [disabled]="!canSave()" class="btn btn-success" data-cy="ModalSaveButton"
			[class.disabled]="isSaving">
			<i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
			{{buttonText}}
		</button>
	</div>
</div>