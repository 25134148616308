import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { IPHBulkCreateRequest } from "rl-common/components/bulk-grid/bulk-grid.models";
import { ITemplateCharData } from "rl-common/services/entity/models/i-template-char-data";
import { IGridView } from "rl-common/services/grid-view/models/i-grid-view";
import { BulkConfig } from "../../../../../common/components/bulk-grid/bulk-config.strategy";
import { BulkGridComponent } from "../../../../../common/components/bulk-grid/bulk-grid.component";
import { JobProgressComponent } from "../../../../../common/components/modals/job-progress-modal/job-progress/job-progress.component";
import { CharTypeId } from "../../../../../common/rl-common.consts";
import { LinkHelperService } from "../../../../../common/services/link-helper.service";
import { ProgressService } from "../../../../../common/services/progress.service";
import { RightsExplorerHttpService } from "../../rights-explorer-http.service";
import { IDealAssociation } from "../existing-or-new-deal/existing-or-new-deal.models";

@Component({
	selector: "rl-bulk-edit-rights",
	templateUrl: "./bulk-edit-rights.component.html",
	styleUrl: "./bulk-edit-rights.component.scss",
	imports: [JobProgressComponent, BulkGridComponent, ReactiveFormsModule, FormsModule]
})
export class BulkEditRightsComponent {

	@Input()
	config: BulkConfig;

	@Input()
	deal: IDealAssociation;

	@Input()
	templateId: number;

	@Input()
	initialData: ITemplateCharData[];

	@Output()
	onComplete = new EventEmitter<boolean>();

	gridView: IGridView;

	jobId: string;
	goToDeal: boolean = true;

	constructor(
		private _rightsExplorerHttpService: RightsExplorerHttpService,
		private _progressService: ProgressService,
		private router: Router,
		private linkHelper: LinkHelperService
	) {

	}

	cancel($event) {
		this.onComplete.emit(null);
	}

	done(response: IPHBulkCreateRequest) {
		this._rightsExplorerHttpService.createAvailsRights(this.deal.recordId, response)
			.subscribe((jobId) => {
				this.jobId = jobId;
			});
	}

	createComplete() {
		if (this.goToDeal) {
			this.goToDealRecord();
		}
		this.onComplete.next(true);
	}

	goToDealRecord() {
		if (this.deal) {
			this._progressService.startProgress();
			setTimeout(() => {
				this._progressService.endProgress();
				const url = this.linkHelper.go(CharTypeId.Transaction, this.deal.recordId);
				this.router.navigate(url);
			}, 5_000);
		}
	}

}
