import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IValidateInputListResult } from "../input-list.models";

@Component({
    selector: "rl-input-list-results",
    templateUrl: "./input-list-results.component.html",
    styleUrls: ["./input-list-results.component.scss"],
    imports: [NgIf, NgFor]
})
export class InputListResultsComponent implements OnInit {

	validResults: IEntitySearchDoc[] = [];
	invalidResults: IEntitySearchDoc[] = [];
	totalCount = 0;
	@Input()
	set results(results: IValidateInputListResult) {
		this.validResults = results?.valid;
		this.invalidResults = results?.invalid;
		this.totalCount = results?.totalCount;
	}

	get hasResults() {
		return this.totalCount > 0;
	}

	constructor() { }

	ngOnInit() { }

}
