@if(showModalHeader) {
<div class="modal-header">
	<h2 class="modal-title">Edit {{clipboardTypeName}} Snapshot</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
}
<div class="modal-body">
	<div class="d-flex justify-content-end">
		<div ngbDropdown class="dropdown-menu-right" role="menu">
			<button type="button" class="btn btn-icon btn-info ms-2 hide-caret" ngbDropdownToggle role="button"
				aria-label="Expand Menu" data-cy="DropdownMenuButton">
				<i class="far fa-bars"></i>
			</button>
			<div ngbDropdownMenu>
				<button ngbDropdownItem (click)="generateRights()" *ngIf="clipboardType === 1"
					data-cy="ClipboardGenerateRights" [disabled]="!hasSelections">
					Generate Rights
				</button>
				<button ngbDropdownItem (click)="export()" data-cy="ClipboardExport">
					Export Current List
				</button>
				<button ngbDropdownItem (click)="removeSelectedItems()" data-cy="ClipboardRemoveSelected"
					[disabled]="!hasSelections">
					Remove Selected
				</button>
			</div>
		</div>
	</div>
	<rl-grid *ngIf="dataSource" [dataSource]="dataSource" [layoutOptions]="{height:'stretch'}"
		data-cy="ClipboardEditModalRlGrid"></rl-grid>
</div>

<div class="modal-footer" [ngSwitch]="isModify">
	<ng-container *ngSwitchCase="true">
		<div class="mb-3">
			<label class="control-label">Rename:</label>
			<input type="text" class="form-control" placeholder="Worksheet Name" [(ngModel)]="clipboardTitle"
				[disabled]="clipboardCopyTitle?.length > 0" data-cy="clipboardTitle">
		</div>
		<div class="mb-3 mx-3">
			<label class="control-label">
				<strong>OR</strong>
			</label>
		</div>
		<div class="mb-3">
			<label class="control-label">Save As:</label>
			<input type="text" class="form-control" placeholder="Enter New Worksheet Name"
				[(ngModel)]="clipboardCopyTitle" data-cy="clipboardCopyTitle">
		</div>
		<button type="button" class="btn btn-light ms-5" (click)="cancel()" data-cy="cancelButton">Cancel</button>
		<button type="button" class="btn btn-success ms-2" (click)="save()" data-cy="saveButton"
			[disabled]="clipboardTitle.length < 1 && clipboardCopyTitle.length < 1">Save</button>
	</ng-container>
	<ng-container *ngSwitchDefault>
		<div class="d-inline-flex" [ngClass]="{'has-error': clipboardTitle.length < 1}">
			<input type="text" class="form-control form-control-long" placeholder="Save Worksheet As..."
				[(ngModel)]="clipboardTitle" data-cy="SaveWorksheetInput">
			<button type="button" class="btn btn-light ms-5" (click)="cancel()" data-cy="cancelButton">Cancel</button>
			<button class="btn btn-success ms-3" type="button" (click)="save()" [disabled]="clipboardTitle.length < 1"
				data-cy="SaveNewButton">
				Save
			</button>
		</div>
	</ng-container>
</div>