import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BulkCreateAmountsComponent } from "../bulk-create-amounts.component";

@Component({
    selector: "rl-bulk-create-amounts-modal",
    templateUrl: "./bulk-create-amounts-modal.component.html",
    styleUrls: ["./bulk-create-amounts-modal.component.scss"],
    imports: [BulkCreateAmountsComponent]
})
export class BulkCreateAmountsModalComponent implements OnInit {
	@Input()
	templateId: number;

	@Input()
	charTypeId: number;

	@Input()
	recordIds: number[];

	@Output()
	onComplete = new EventEmitter<boolean>();
	constructor() { }

	ngOnInit() {
	}

}
