import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { flatMap, sumBy } from "lodash";
import { IEntitySelected } from "rl-common/services/entity/models/i-entity-selected";
import { AutoFocusDirective } from "../../../directives/auto-focus.directive";
import { ChipComponent } from "../../chip/chip.component";
import { DeleteConfirmState, IDeleteConfirmationEvent } from "./delete-confirmation-modal.component.models";

@Component({
	selector: "rl-delete-confirmation-modal",
	templateUrl: "./delete-confirmation-modal.component.html",
	styleUrls: ["./delete-confirmation-modal.component.scss"],
	imports: [NgIf, NgFor, ChipComponent, ReactiveFormsModule, AutoFocusDirective, FormsModule]
})
export class DeleteConfirmationModalComponent implements OnInit {

	@Input()
	states: DeleteConfirmState[] = [];

	@Input()
	entitySelectFn: (row) => IEntitySelected;

	@Input()
	recordLabel: string;

	@Input()
	showChips = true;

	@Output()
	onConfirm = new EventEmitter<IDeleteConfirmationEvent>();

	deleteConfirmed = false;
	deleteInput = "";
	selectedRecords: IEntitySelected[] = [];
	selectedDataBridgedRecords: IEntitySelected[] = [];
	initializedWithDataBridgedRecords = false;

	get numRecordsToDelete() {
		const selectAllStates = this.states.filter(state => state.isAllSelected);
		return this.selectedRecords.length + this.selectedDataBridgedRecords.length + sumBy(selectAllStates, state => state.rowCount - state.deselectedIds.size);
	}

	constructor() { }

	ngOnInit() {
		const allRecords = flatMap(this.states, state => {
			if (state.isAllSelected) {
				return [];
			}
			return Array.from(state.selectedValues)
				.map((val: unknown) => {
					return this.entitySelectFn(val);
				});
		});
		this.selectedRecords = allRecords.filter(r => !r.alliantId);
		this.selectedDataBridgedRecords = allRecords.filter(r => !!r.alliantId);
		this.initializedWithDataBridgedRecords = !!this.selectedDataBridgedRecords.length;
	}

	remove(rec: IEntitySelected) {
		this.selectedRecords = this.selectedRecords.filter(x => x.recordId !== rec.recordId);
	}

	removeDataBridgedRecord(rec: IEntitySelected) {
		this.selectedDataBridgedRecords = this.selectedDataBridgedRecords.filter(x => x.recordId !== rec.recordId);
	}

	confirmDelete(event) {
		this.deleteConfirmed = this.deleteInput.toUpperCase() === "DELETE";
		if (this.deleteConfirmed && event.key === "Enter") {
			this.accept();
		}
	}

	public decline() {
		this.onConfirm.emit();
	}

	public accept() {
		const selectAllStates = this.states.filter(state => state.isAllSelected);
		const expectedCount = this.selectedRecords.length + this.selectedDataBridgedRecords.length + sumBy(selectAllStates, state => state.rowCount - state.deselectedIds.size);
		this.onConfirm.emit({ selectedIds: this.selectedRecords, expectedCount, selectedDataBridgedIds: this.selectedDataBridgedRecords });
	}

	public dismiss() {
		this.onConfirm.emit();
	}
}
