<div *ngIf="(issue.conflictTypeId == 1 || issue.conflictTypeId == 5) && issue.deal2Id">
	<strong>{{ issue.conflictTypeDescription }}</strong> for
	{{ issue.catalogTemplate }}
	<a [routerLink]="catalogLinkHelper.details(issue.catalogId)">#{{issue.catalogId}} - {{issue.catalogTitle}}</a>
	&mdash;
	{{issue.right1Description}} overlaps with
	<a [routerLink]="dealLinkHelper.details(issue.deal2Id)">Deal #{{issue.deal2Id}} - {{issue.deal2Title}}</a>
	{{issue.right2Description}}
</div>

<div *ngIf="(issue.conflictTypeId == 1 || issue.conflictTypeId == 5) && !issue.deal2Id">
	<strong>{{ issue.conflictTypeDescription }}</strong> for
	{{ issue.catalogTemplate }}
	<a [routerLink]="catalogLinkHelper.details(issue.catalogId)">#{{issue.catalogId}} - {{issue.catalogTitle}}</a>
	&mdash;
	{{issue.right1Description}} overlaps with {{issue.catalogTemplate}}
	<a [routerLink]="catalogLinkHelper.details(issue.catalogId)">#{{issue.catalogId}} - {{issue.catalogTitle}}</a>
	{{issue.right2Description}}
</div>

<div
	*ngIf="issue.conflictTypeId == 0 || issue.conflictTypeId == 2 || issue.conflictTypeId == 3 || issue.conflictTypeId == 4">
	<strong>{{ issue.conflictTypeDescription }}</strong> for
	{{ issue.catalogTemplate }}
	<a [routerLink]="catalogLinkHelper.details(issue.catalogId)">#{{issue.catalogId}} - {{issue.catalogTitle}}</a>
	&mdash;
	{{issue.right1Description}}
</div>