import { NgFor } from "@angular/common";
import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { RelationshipTypes } from "rl-common/models/relationship-types";

@Component({
    selector: "rl-relationship-type-select",
    templateUrl: "./relationship-type-select.component.html",
    styleUrls: ["./relationship-type-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RelationshipTypeSelectComponent),
            multi: true
        }
    ],
    imports: [ReactiveFormsModule, FormsModule, NgFor]
})
export class RelationshipTypeSelectComponent implements ControlValueAccessor, OnInit {

	@Input()
	disabled = false;

	@Input()
	relationships: RelationshipTypes[] = [RelationshipTypes.Child, RelationshipTypes.Parent];

	selectedRelationship: RelationshipTypes;

	onChange = (relationshipType: RelationshipTypes) => { };
	onTouched = () => { };

	constructor() { }

	writeValue(direction: RelationshipTypes): void {
		if (direction !== null && direction !== undefined && this.relationships !== undefined) {
			this.selectedRelationship = this.relationships.find(x => x === direction);
		}
		this.emitChange();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	emitChange() {
		this.onChange(this.selectedRelationship);
	}

	ngOnInit() {
	}

	getLabel(direction: RelationshipTypes) {
		return RelationshipTypes[direction];
	}
}
