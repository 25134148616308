import { Pipe, PipeTransform } from "@angular/core";
import { IDUtil } from "rl-common/utils";
import { CharTypeId } from "rl-common/consts";

@Pipe({ name: "parseCharType" })
export class ParseCharTypePipe implements PipeTransform {

	transform(entityId: string): CharTypeId {
		if (!entityId || !IDUtil.isEntityID(entityId)) {
			return null;
		}
		const split = IDUtil.splitEntityID(entityId);
		return split.charTypeID;
	}

}
