import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ICurrencyValue } from 'rl-common/services/company/company.models';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'rl-currency-selector',
    templateUrl: './currency-selector.component.html',
    styleUrls: ['./currency-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencySelectorComponent),
            multi: true
        }
    ],
    imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class CurrencySelectorComponent implements OnInit, ControlValueAccessor {
	@Input()
	label: string;

	@Input()
	currencyValues: ICurrencyValue[] = [];

	@Input()
	defaultCurrency: string;

	isDisabled = false;
	currencyControl = new UntypedFormControl("", [Validators.required]);
	onChange = (currency: string) => { };
	onTouched = () => { };
	constructor() { }

	ngOnInit(): void {
		if (this.defaultCurrency) {
			this.writeValue(this.defaultCurrency);
		}
	}

	writeValue(currency: string): void {
		this.currencyControl.setValue(currency);
		this.emitChange();
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
	emitChange() {
		this.onChange(this.currencyControl.value);
	}
}
