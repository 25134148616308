<ng-container [ngSwitch]="valueEntryType">
	<ng-container *ngSwitchCase="'none'">
		<!-- EMPTY -->
	</ng-container>
	<ng-container *ngSwitchCase="'single'">
		<rl-data-behavior-single-value-entry [cmd]="cmd" [control]="form.controls.value">
		</rl-data-behavior-single-value-entry>
	</ng-container>
	<ng-container *ngSwitchCase="'range'">
		<div class="d-flex align-items-center flex-wrap" [class.has-error]="form.hasError('invalidRange')">
			<rl-data-behavior-single-value-entry [cmd]="cmd" [control]="form.controls.rangeValue1" class="mb-1">
			</rl-data-behavior-single-value-entry>
			<span class="d-block m-1">and</span>
			<rl-data-behavior-single-value-entry [cmd]="cmd" [control]="form.controls.rangeValue2" class="mb-1">
			</rl-data-behavior-single-value-entry>
		</div>
		<div *ngIf="form.hasError('invalidRange')" class="text-danger">
			Range is invalid.
		</div>
	</ng-container>
</ng-container>