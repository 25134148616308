import { Component, effect, input } from "@angular/core";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { CreateEntityCmdVisibilityService } from "../create-entity-cmd-visibility.service";
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FeatureEnabledDirective } from "../../../directives/feature-enabled.directive";

@Component({
    selector: "rl-show-only-dropdown",
    templateUrl: "./show-only-dropdown.component.html",
    styleUrls: ["./show-only-dropdown.component.scss"],
    imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, ReactiveFormsModule, FormsModule, FeatureEnabledDirective]
})
export class ShowOnlyDropdownComponent {

	extractedCharIds = input<number[]>(null);

	get disableAllFields() {
		return this.createCmdVisibilityService.computedShowAll();
	}

	constructor(
		private readonly _oneConfigService: OneConfigService,
		readonly createCmdVisibilityService: CreateEntityCmdVisibilityService
	) {
		effect(() => {
			const extractedCharIds = this.extractedCharIds();
			this.createCmdVisibilityService.setExtractedChars(extractedCharIds);
		});
	}

}
