<div class="modal-header">
	<h2 class="modal-title">
		Associate Amounts to an Accounting Document
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()" data-cy="CopyModalCloseButton"
		role="button">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" *ngIf="form">
	<div *ngIf="amountDocuments">
		<h4>Associate <strong>{{amountDocuments.length}} Amounts</strong> to:</h4>
	</div>
	<form [formGroup]="form">
		<div *ngIf="!loadingText; else loading">
			<div *ngIf="invoices && invoices.length > 0" class="row mt-4 mb-3">
				<div class="col-4 d-flex">
					<input class="form-check-input me-3" type="radio" formControlName="associationType" [value]="0"
						(ngModelChange)="onSelectionChange($event)" id="existing" />
					<label class="form-check-label" for="existing">
						Existing Accounting Document:
					</label>
				</div>
				<div class="col">
					<select class="form-select w-auto" formControlName="existingInvoice">
						<option *ngFor='let invoice of invoices' [value]="invoice.recordID">
							{{invoice.title}}</option>
					</select>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-4 d-flex">
					<input class="form-check-input me-3" type="radio" formControlName="associationType" [value]="1"
						(ngModelChange)="onSelectionChange($event)" id="new" />
					<label class="form-check-label" for="new">
						New Accounting Document:
					</label>
				</div>
				<div class="col">
					<select class="form-select w-auto" formControlName="newInvoiceTemplate">
						<option *ngFor='let invoiceTemplate of invoiceTemplates' [value]="invoiceTemplate.templateID">
							{{invoiceTemplate.templateName}}</option>
					</select>
				</div>
			</div>
			<div *ngIf="amountDocuments.length > 1" class="row mb-4">
				<div class="col-4 d-flex">
					<input class="form-check-input me-3" type="radio" formControlName="associationType" [value]="2"
						(ngModelChange)="onSelectionChange($event)" id="newForEach" />
					<label class="form-check-label" for="newForEach">
						New Accounting Document for each selected Amount:
					</label>
				</div>
				<div class="col">
					<select class="form-select w-auto" formControlName="newInvoiceTemplateForMultiple">
						<option *ngFor='let invoiceTemplate of invoiceTemplates' [value]="invoiceTemplate.templateID">
							{{invoiceTemplate.templateName}}</option>
					</select>
				</div>
			</div>
		</div>
	</form>
	<ng-template #loading>
		<rl-loader [text]="loadingText"></rl-loader>
	</ng-template>
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="cancel()">Cancel</button>
	<button class="btn btn-success ms-3" (click)="save()" [disabled]="loadingText">
		<i *ngIf="isSaving" class="far fa-spinner fa-spin"></i>
		Save
	</button>
</div>