import { DocumentOptions } from "rl-common/services/entity-config/entity-config.models";

export interface DocumentOptionsMeta {
	displayName: string;
	docInd: number;
}

export namespace DocumentOptionsUtil {
	export const documentOptionsLookup: { [key in DocumentOptions]: DocumentOptionsMeta } = {
		[DocumentOptions.none]: {
			displayName: "None",
			docInd: 0
		},
		[DocumentOptions.generatesDoc]: {
			displayName: "Generates Document",
			docInd: 1
		},
		[DocumentOptions.sendForEsig]: {
			displayName: "Send for E-Signature",
			docInd: 2
		},
		[DocumentOptions.esigComplete]: {
			displayName: "E-Signature Complete",
			docInd: 3
		},
		[DocumentOptions.sendForEsigPartiesOnly]: {
			displayName: "Send for E-Signature (Parties Only)",
			docInd: 4
		}
	};

	export function toDisplayName(documentOptions: DocumentOptions) {
		return documentOptionsLookup[documentOptions].displayName;
	}
}
