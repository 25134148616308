<div class="modal-header mb-3">
	<h2 class="modal-title">{{headerText}}</h2>
</div>
<rl-panel-switcher [activeId]="step" class="no-panel">
	<rl-panel id="bulk-edit">
		<ng-template rlPanelContent>
			<div *ngIf="dataSource" class="modal-body">
				<p *ngIf="showFieldsWarningText()">Bulk Edit fields are determined by fields in current View. To adjust
					editable fields, exit modal and
					adjust your View.</p>
				<rl-grid #grid [dataSource]="dataSource" [options]="gridOptions" data-cy="BulkGridRLGrid">
					<ng-container
						*rlGridNestedTemplate="let amountsDataSource;let rowPath = rowPath; let parentRowData = parentRowData">
						<rl-bulk-amounts [amountsDataSource]="amountsDataSource" [parentRowData]="parentRowData"
							[rowPath]="rowPath">
						</rl-bulk-amounts>
					</ng-container>
				</rl-grid>
			</div>
			<div class="modal-footer">
				<button class="btn btn-light" (click)="cancel()" data-cy="BulkGridCancelButton">Cancel</button>
				<button *ngIf="invalid$" class="btn btn-success ms-3" [disabled]="(invalid$ | async) || !hasEdits || isBusy"
					(click)="bulk()" data-cy="BulkGridSaveButton">
					<i class="far fa-spinner fa-spin" *ngIf="isBusy"></i>Save
				</button>
			</div>
		</ng-template>
	</rl-panel>
	<rl-panel id="in-progress">
		<ng-template rlPanelContent>
			<div class="py-4">
				<p *ngIf="isListPage">Completion email will be sent once Bulk Edit has completed.</p>
				<rl-job-progress [jobId]="jobId" (onComplete)="complete()" *ngIf="jobId"
					[hideModalHeading]="true"></rl-job-progress>
			</div>
		</ng-template>
	</rl-panel>
	<rl-panel id="select-tagged-entity-rows">
		<ng-template rlPanelContent>
			<rl-bulk-grid-select-associations [charTypeId]="config.charTypeId"
				[assocCharTypeId]="selectAssociationCharTypeId" [templateId]="template"
				[parentCharTypeId]="parentCharTypeId" [parentRecordId]="config.parentRecordId"
				(onCancel)="cancelSelectAssociations()" (onApply)="applySelectedAssociations($event)">
			</rl-bulk-grid-select-associations>
		</ng-template>
	</rl-panel>
</rl-panel-switcher>