<div class="modal-header">
	<h2 class="modal-title">Confirm Upload</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<p class="text-medium">If you choose to continue, you will replace any existing Original attachment.</p>
	<p class="text-medium bold">Would you like to regenerate Key Art and Thumbnail images from this upload?</p>
	<div class="checkbox form-control-static">
		<label>
			<input type="checkbox" [(ngModel)]="makeKeyArt" class="form-check-input">
			Key Art
		</label>
	</div>
	<div class="checkbox form-control-static">
		<label>
			<input type="checkbox" [(ngModel)]="makeThumb" class="form-check-input">
			Thumbnail
		</label>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="cancel()">Cancel</button>
	<button type="button" class="btn btn-info" (click)="continue()">Continue</button>
</div>