<div class="d-flex align-items-center py-3 mb-4 border-bottom" *ngIf="isIntraChar">
	<h4 class="text-nowrap my-0 me-2">Associate as a</h4>
	<rl-relationship-type-select [(ngModel)]="selectedDirection" [relationships]="relationships"
		(change)="directionChanged()">
	</rl-relationship-type-select>
	<h4 class="flex-fill text-nowrap my-0 ms-2 overflow-hidden">record of
		<strong class="ps-1 text-truncate d-inline-block position-relative"
			style="max-width: 60%; top: 5px;">{{dataSource.parentTitle}}</strong>
	</h4>
</div>
<rl-component-relationship [dataSource]="dataSource" [assocCharTypeId]="charTypeId"
	(onEntityRelationshipChange)="updateState($event)" [selectedDirection]="selectedDirection">
</rl-component-relationship>