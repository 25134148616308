import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { INotification, NotificationSortColumn, NotificationSortDirection, NotificationStatus } from "rl-common/components/notifications/notification.models";
import { NotificationService } from "rl-common/components/notifications/notification.service";
import { CharTypeId } from "rl-common/consts";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { DateTimeFormatType } from "rl-common/utils";
import { Observable, Subscription } from "rxjs";
import { concatMap } from "rxjs/operators";
import { animationTransitionOpacity } from "../../../../common/components/animations/animations";
import { NewTabInModalDirective } from "../../../../common/directives/new-tab-in-modal.directive";
import { RouterLink } from "@angular/router";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { LocalDatePipe } from "../../../../common/pipes/local-date.pipe";

@Component({
    selector: "rl-header-notifications",
    templateUrl: "./header-notifications.component.html",
    styleUrls: ["./header-notifications.component.scss"],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: '250ms ease-out'
                    }
                })
            ])
        ])
    ],
    imports: [NewTabInModalDirective, RouterLink, NgIf, NgFor, AsyncPipe, LocalDatePipe]
})
export class HeaderNotificationsComponent implements OnInit, OnDestroy {
	public notifications$: Observable<INotification[]>;
	private readonly _subscriptions: Subscription[] = [];
	DateTimeFormatType = DateTimeFormatType;

	constructor(
		private readonly notificationService: NotificationService,
		public readonly linkHelper: LinkHelperService,
	) { }

	ngOnInit() {
		this.notifications$ = this.notificationService.unreadNotifications$;
		this.reload();
	}

	reload() {
		const sub = this.reload$().subscribe();
		this._subscriptions.push(sub);
	}

	reload$() {
		return this.notificationService.getNotifications(1, 10, CharTypeId.All, NotificationStatus.Unread, NotificationSortColumn.Created, NotificationSortDirection.Descending);
	}

	markAsRead(rowData: INotification) {
		const sub = this.notificationService.setNotificationStatus(rowData.charTypeId, [rowData.id], NotificationStatus.Read)
			.pipe(
				concatMap(() => this.reload$())
			)
			.subscribe();
		this._subscriptions.push(sub);
	}

	delete(notification: INotification) {
		const sub = this.notificationService.setNotificationStatus(notification.charTypeId, [notification.id], NotificationStatus.Deleted)
			.pipe(
				concatMap(() => this.reload$())
			)
			.subscribe();
		this._subscriptions.push(sub);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
