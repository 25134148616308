<div class="acct-operation-event container-fluid mb-2">
	<div class="row align-items-stretch">
		<div class="col-8 bg-light">
			<div class="row align-items-stretch h-100">
				<div class="col-4 acct-operation-event__item purple-arrow d-flex flex-column justify-content-center">
					<strong>Initial Status</strong>
					<span>{{ initialStatusLabel }}</span>
				</div>
				<div class="col-4 acct-operation-event__item purple-arrow d-flex flex-column justify-content-center">
					<strong>Workflow Action</strong>
					<span>{{ wfActionLabel }}</span>
				</div>
				<div class="col-4 acct-operation-event__item d-flex flex-column justify-content-center">
					<strong>Resulting Status</strong>
					<span>{{ resultingStatusLabel }}</span>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row acct-operation-event__accounts-row">
				<div class="col-5 d-flex flex-column acct-operation-event__item bg-white">
					<strong>Debit Account</strong>
					<span class="text-muted" *ngIf="creditDebitAccountOrGroups?.length === 0">None</span>
					<span *ngFor="let accountOrGroup of creditDebitAccountOrGroups">
						@if (accountOrGroup.debitId.isGroup) {
							<div class="d-flex flex-column">
								<span>{{ accountOrGroup.debitName }}</span>
								<em>(Account Group)</em>
							</div>
						} @else {
							<span>{{ accountOrGroup.debitName }}</span>
						}
					</span>
				</div>
				<div class="col-5 d-flex flex-column acct-operation-event__item">
					<strong>Credit Account</strong>
					<span class="text-muted" *ngIf="creditDebitAccountOrGroups?.length === 0">None</span>
					<span *ngFor="let accountOrGroup of creditDebitAccountOrGroups">
						@if (accountOrGroup.creditId.isGroup) {
							<div class="d-flex flex-column">
								<span>{{ accountOrGroup.creditName }}</span>
								<em>(Account Group)</em>
							</div>
						} @else {
							<span>{{ accountOrGroup.creditName }}</span>
						}
					</span>
				</div>
				<div class="col-2 acct-operation-event__accounts-buttons align-items-center justify-content-end pe-2">
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>