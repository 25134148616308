<div class="d-flex my-2 me-2 w-100">
	<div ngbNav #nav="ngbNav" [activeId]="activeTab()" (activeIdChange)="activeTabSet.set($event)"
		class="nav-tabs flex-column" orientation="vertical">
		<ng-container ngbNavItem="history">
			@if(hasHistory()){
			<button ngbNavLink class="btn btn-sm btn-link side-tab" title="Rights History">
				<i class="far fa-clock"></i>
			</button>
			}
			<ng-template ngbNavContent>
				<div class="d-flex align-items-center">
					<h4 class="mt-2">Conflict History</h4>
				</div>
				@if(dataSource()){
				<div class="p-3">
					<rl-conflict-check-issue-nested-data [parentRowData]="rowData()?.[0]"
						[nestedDataSource]="dataSource()">
					</rl-conflict-check-issue-nested-data>
				</div>
				}
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem="action">
			@if(hasActions()){
			<button ngbNavLink class="btn btn-sm btn-link side-tab" title="Rights Actions">
				<i class="far fa-binoculars"></i>
			</button>
			}
			<ng-template ngbNavContent>
				<div class="d-flex align-items-center">
					<h4 class="mt-2">Rights Actions</h4>
					@if(dataSource()?.columnStrategy){
					<rl-custom-views [charTypeId]="3" [gridViewColumnStrategy]="dataSource()?.columnStrategy"
						class="ms-auto">
					</rl-custom-views>
					}
				</div>
				@if(dataSource()){
				<rl-grid [dataSource]="dataSource()" class="d-block mb-4 me-2"></rl-grid>
				}
			</ng-template>
		</ng-container>

	</div>
	<div [ngbNavOutlet]="nav" class="tab-content mt-0 w-100"></div>
</div>