import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "lodash";
import { requireCheckboxesToBeCheckedValidator, rightslineEmailValidator } from "rl-common/components/char-data/elements/element-validators";
import { SearchOptions } from "rl-common/components/entities/entity-search/entity-search.models";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { GridSelectType } from "rl-common/components/grid/models/grid-select-type";
import { CharTypeId } from "rl-common/consts";
import { SearchOptionsFactory } from "rl-common/factories/search-options.factory";
import { FacetType } from "rl-common/models/facet-type";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { IApplicationInfo, IInviteUserRequest, IUserRole, IWfRole } from "rl-common/services/company/company.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { ISearchRequestModel } from "rl-common/services/search/models/search-request.model";
import { UserService } from "rl-common/services/user/user.service";
import { QueryUtil } from "rl-common/utils";
import { combineLatest, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { EntitySearchComponent } from "../../../../common/components/entities/entity-search/entity-search.component";
import { SessionService } from "../../../../common/services/session.service";
import { DivSettingKey } from "../../../../common/services/settings/settings.models";
import { SettingsService } from "../../../../common/services/settings/settings.service";

@Component({
    selector: "rl-invite-user-modal",
    templateUrl: "./invite-user-modal.component.html",
    styleUrls: ["./invite-user-modal.component.scss"],
    imports: [NgIf, EntitySearchComponent, ReactiveFormsModule, NgFor]
})
export class InviteUserModalComponent implements OnInit, OnDestroy {

	@Output()
	onComplete = new EventEmitter<boolean>();

	step = 0;

	private readonly _subscriptions: Subscription[] = [];

	dataSource: SearchGridDataSource<IEntitySearchDoc>;
	private readonly columns: GridColumn<IEntitySearchDoc>[] = [
		{
			key: "username",
			headerName: "Username",
			renderer: "text",
			width: "auto",
			getCellData: (user => user.characteristics[SearchFieldNames.User.email])
		},
		{
			key: "name",
			headerName: "Name",
			renderer: "text",
			width: "auto",
			getCellData: (user => user.title)
		},
		{
			key: "userId",
			headerName: "User ID",
			renderer: "text",
			width: "auto",
			getCellData: (user => user.recordID)
		},
		{
			key: "template",
			headerName: "Template",
			renderer: "text",
			width: "min-content",
			getCellData: (user => user.template)
		},
		{
			key: "lastUpdated",
			headerName: "Last Updated",
			renderer: "date",
			width: "min-content",
			getCellData: (user => user.lastUpdated)
		}
	];
	gridOptions: GridOptions<any> = {
		defaultGetCellDataFn: (column, doc) => {
			switch (column.key) {
				case "id":
					return doc.recordID;
				default:
					return doc.characteristics[column.key];
			}
		},
	};
	searchOptions: SearchOptions = {} as SearchOptions;

	form: UntypedFormGroup;

	apps: IApplicationInfo[] = [];
	identityRoles: IUserRole[] = [];
	workflowRoles: IWfRole[] = [];
	templates: ICharacteristicTemplate[] = [];
	isSaving: boolean;

	get selectedUser(): IEntitySearchDoc {
		return <IEntitySearchDoc>first(Array.from(this.dataSource.dataSelectStrategy.selectedState.selectedValues));
	}

	constructor(
		private readonly gridDataServiceBuilder: GridDataSourceBuilder,
		private readonly userService: UserService,
		private readonly activeModal: NgbActiveModal,
		private readonly oneConfigService: OneConfigService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly router: Router,
		private readonly sessionService: SessionService,
		private readonly settingsService: SettingsService) { }

	ngOnInit(): void {
		this.getRoles();
		this.templates = this.oneConfigService.getTemplatesWhere(CharTypeId.User, cmd => cmd.tagLabel === "email");

		this.searchOptions = SearchOptionsFactory.buildListPageOptions(CharTypeId.User);
		this.searchOptions.isRelatedDropdownEnabled = false;
		this.searchOptions.selectAllEnabled = false;
		this.searchOptions.areActionsEnabled = false;

		const columnStrategy = this.gridDataServiceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(CharTypeId.User);

		const dataSelectStrategy = this.gridDataServiceBuilder.dataSelectStrategies
			.searchDocDataSelectStrategy<number, IEntitySearchDoc>(doc => doc.recordID, doc => doc)
			.withSelectType(GridSelectType.Radio);

		this.dataSource = this.gridDataServiceBuilder.entitySearchDataSource(CharTypeId.User)
			.withProgress(true)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: "recordid", sortDir: 0 })
			.setColumns(this.columns)
			.withDataSelectStrategy(dataSelectStrategy)
			.withColumnStrategy(columnStrategy)
			.withFetchFn((ds) => {
				const start = ds.rowOffset$.value;
				const pageSize = ds.pageSize$.value;
				const sortKey = ds.sortKey$.value;
				const sortDir = ds.sortDir$.value;

				const query = QueryUtil.$and(
					QueryUtil.$eq(SearchFieldNames.Entity.charTypeID, CharTypeId.User),
					QueryUtil.$neq(SearchFieldNames.User.email, ""),
					QueryUtil.$eq_any(SearchFieldNames.Entity.templateID, this.templates.map(x => x.templateID)));

				const options: ISearchRequestOptions = {
					rows: pageSize,
					start,
					sortDir,
					sortField: <string>sortKey,
					facetFields: [
						FacetType.Template,
						FacetType.Status
					],
					filterQueries: ds.buildFilterQueries(),
				};

				const model: ISearchRequestModel = {
					keywords: ds.keywords,
					query: query,
					...options,
				};

				return this.userService.getUsers(model).pipe(
					tap((results) => {
						ds.setFacetResults(results.facetResults)
							.setExtraGridColumnResults(results.extraGridColumnResults);
					}),
					map((results) => ({
						rowData: results.documents.map(x => x.document),
						rowCount: results.numFound,
						extraGridColumnResults: results.extraGridColumnResults,
						facetResults: results.facetResults
					}))
				);
			});

		const sub = this.dataSource.fetchRows().subscribe();
		this._subscriptions.push(sub);
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	getRoles() {
		combineLatest([this.userService.getApps(), this.userService.getRoles()]).subscribe(
			([apps, roles]) => {
				this.apps = apps;
				this.identityRoles = roles.identityRoles;
				this.workflowRoles = roles.workflowRoles;
			});
	}

	public close() {
		this.activeModal.close(false);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}

	public nextStep() {
		this.createForm();
		this.step = 1;
	}

	createForm() {
		const user = this.selectedUser;

		this.form = this.formBuilder.group({
			name: new UntypedFormControl({ value: user?.title, disabled: !!user }, [Validators.required]),
			email: new UntypedFormControl({ value: user?.characteristics["email"], disabled: !!user }, [Validators.required, Validators.email]),
			template: new UntypedFormControl({ value: user?.templateID, disabled: !!user }, [Validators.required]),
			applications: new UntypedFormArray(
				this.apps.map(app => new UntypedFormControl(null)), requireCheckboxesToBeCheckedValidator(1)),
			identityRoles: new UntypedFormArray(
				this.identityRoles.map(role => new UntypedFormControl(null)), requireCheckboxesToBeCheckedValidator(1)),
			workflowRoles: new UntypedFormArray(
				this.workflowRoles.map(role => new UntypedFormControl(null)), requireCheckboxesToBeCheckedValidator(1)),
		});

		if (this.sessionService.userName.endsWith("@rightsline.com")) {
			this.settingsService.getDivSetting(DivSettingKey.OverrideRightslineEmailValidation).toPromise()
				.then((sett) => {
					if (!sett || sett.value != new Date().toISOString().slice(0, 10)) {
						this.form.controls.email.addValidators(rightslineEmailValidator(this.sessionService.userName));
					}
				})
				.catch((err) => {
					this.form.controls.email.addValidators(rightslineEmailValidator(this.sessionService.userName));
				});
		}
	}

	inviteUser() {
		this.isSaving = true;
		const request: IInviteUserRequest = {
			name: this.form.controls["name"].value,
			email: this.form.controls["email"].value,
			applicationUrlIds: this.mapList(this.form.controls["applications"].value, this.apps.map(x => x.appUrlID)),
			identityRoleIds: this.mapList(this.form.controls["identityRoles"].value, this.identityRoles.map(x => x.roleID)),
			workflowRoleIds: this.mapList(this.form.controls["workflowRoles"].value, this.workflowRoles.map(x => x.roleID)),
			recId: this.selectedUser?.recordID,
			templateId: this.form.controls["template"].value,
			disableMessages: false
		};
		const sub = this.userService.inviteUser(request)
			.subscribe(
				response => {
					this.router.navigateByUrl(`administration/users/edit/${response}`);
					this.close();
				},
				error => this.isSaving = false);
		this._subscriptions.push(sub);
	}

	mapList<T>(array: unknown[], values: Array<T>): T[] {
		const listToRtn = [];
		for (let i = 0; i < array.length; i++) {
			if (array[i]) {
				listToRtn.push(values[i]);
			}
		}
		return listToRtn;
	}
}
