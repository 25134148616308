import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ViewChild, ElementRef, Input } from "@angular/core";

import { DonutChartService } from "rl-common/services/d3/donut-chart.service";
import { IChartData } from "./donut-chart.models";

@Component({
    selector: "rl-donut-chart-component",
    templateUrl: "./donut-chart.component.html",
    styleUrls: ["./donut-chart.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent implements OnInit, AfterViewInit {
	@ViewChild("svgChartElement", { static: true })
	private svgChartElement: ElementRef<HTMLElement>;

	@ViewChild("svgChartTooltipElement", { static: true })
	private svgChartTooltipElement: ElementRef<HTMLElement>;

	@Input()
	public chartData: IChartData;

	@Input()
	public chartTitleDescription: string;

	public parentContainerWidth?: number;
	public parentContainerHeight?: number;

	constructor(private readonly donutChartService: DonutChartService) { }

	ngOnInit() {
	}

	ngAfterViewInit() {
		if (this.chartData.specifyParentContainerDimensions) {
			this.parentContainerWidth = this.chartData.width;
			this.parentContainerHeight = this.chartData.height;
		}

		const options = {
			width: this.chartData.width,
			height: this.chartData.height
		};

		this.donutChartService.drawDonutChart(
			this.chartData.chartId,
			this.svgChartElement.nativeElement,
			this.chartData.chartTitleHeader,
			this.chartData.chartTitleDescription,
			this.svgChartTooltipElement.nativeElement,
			this.chartData.data,
			options);
	}
}
