<div class="modal-body">
	<rl-feature-flag [key]="'useRoaringBitmaps'">
		<div on>
			@if(dataSource) {
			<rl-component-relationship-bitmap [dataSource]="dataSource" [assocCharTypeId]="assocCharTypeId" (onEntityRelationshipChange)="updateEntityRelationship($event)">
			</rl-component-relationship-bitmap>
			}
		</div>
		<div off>
			@if(assocDataSource) {
			<rl-component-relationship [dataSource]="assocDataSource" [assocCharTypeId]="assocCharTypeId" (onEntityRelationshipChange)="updateEntityRelationshipDeprecated($event)">
			</rl-component-relationship>
			}
		</div>
	</rl-feature-flag>
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="onCancel.next()">Cancel</button>
	<button class="btn btn-success ms-3" (click)="apply()">Apply</button>
</div>