<div class="panel mx-3 mb-3">
	<div class="panel__title">
		<span class="title--overline">Intelligence Center</span>
		<h3>AI Model Information</h3>
	</div>
	<div class="mt-3 d-flex justify-content-between">
		<section style="width:40%">
			<h4 class="mt-1">Claude 3 Sonnet <em>v1</em></h4>
			<h4 class="mb-4">Anthropic | Text & Vision Model | Max 200k</h4>
			<div class="bg-light rounded-panel p-3 info-panel">
				<p>
					Claude 3 Sonnet by Anthropic strikes the ideal balance between intelligence and speed - particularly
					for enterprise workloads.
				</p>
				<p>
					<strong>Model attributes</strong><br>
					Image to text & code, multilingual conversation, complex reasoning and analysis
				</p>
				<p>
					<strong>Model ID</strong><br>
					anthropic.claude-3-sonnet-20240229-v1:0
				</p>
			</div>
		</section>
		<section class="w-50">
			<h4 class="usage-title">Current Usage</h4>
			<ng-container *ngIf="!isLoading; else loading">
				<p class="mb-5">Your license allows for approximately {{normalizedTokenCount}} deal extracts per
					month.<br>
					<em>(assuming average of 20 fields and 25 page file size).</em>
				</p>
				<h4 class="d-flex fw-bold">Total Monthly Usage: <span class="ms-auto">{{monthlyTokenPercentage}}%</span>
				</h4>
				<div class="progress progress-total mb-5">
					<div class="progress-bar bg-success" role="progressbar" [style.width]="monthlyTokenPercentage + '%'"
						[attr.aria-valuenow]="monthlyTokenPercentage" aria-valuemin="0" aria-valuemax="100"></div>
				</div>
				<h4 class="mb-3 fw-bold">Monthly Usage per User</h4>
				<div *ngFor="let usage of userUsage | keyvalue">
					<h4 class="d-flex fw-bold">{{usage.key}}<span class="ms-auto">{{usage.value}}%</span></h4>
					<div class="progress progress-monthly mb-4">
						<div class="progress-bar bg-info" role="progressbar" [style.width]="usage.value + '%'"
							[attr.aria-valuenow]="usage.value" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
				</div>
			</ng-container>
			<ng-template #loading>
				<div class="text-center">
					<rl-loader></rl-loader>
				</div>
			</ng-template>
		</section>
	</div>


</div>