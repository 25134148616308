<div class="datemath__edit-row__row d-flex align-items-center" data-cy="DatemathEditRow_Row">
	<ng-content select="[slot=drag-handle]"></ng-content>
	<div class="flex-grow-1 ms-1">
		<span [innerText]="offsetDescription"></span>
		<a href="#" [innerText]="pathDescription" (click)="toggleSelectOpen($event)"
			[attr.data-cy]="pathDescription"></a>
	</div>
	<div class="flex-shrink-0">
		<button type="button" class="btn btn-link text-larger" title="Adjust DateMath"
			[ngbPopover]="editRowOptionsPopover" placement="bottom-right" [autoClose]="false"
			popoverClass="datemath__edit-row__options-popover" data-cy="AdjustDateMathButton">
			<strong>+/-</strong>
		</button>
		<button type="button" class="btn btn-icon btn-remove ms-2" title="Remove DateMath" (click)="removeRel()"
			data-cy="RemoveDateMathButton">
			<i class="far fa-times"></i>
		</button>
	</div>
</div>
<ng-content select="[slot=drag-placeholder]"></ng-content>
<ng-template [ngIf]="isSelectOpen">
	<div class="datemath__edit-row__edit">
		<rl-datemath-search-table [contextCharId]="contextCharId" [contextEntityId]="contextEntityId"
			[relationship]="relationship" (dateSelect)="dateSelected($event)"></rl-datemath-search-table>
	</div>
</ng-template>
<ng-template #editRowOptionsPopover>
	<div data-cy="EditRowOptionsPopover">
		<form class="d-flex align-items-center datemath__edit-row__options">
			<input type="number" class="form-control form-control-sm" [formControl]="yearsFC" data-cy="YearsInput" />
			<label class="me-3">Years</label>
			<input type="number" class="form-control form-control-sm" [formControl]="monthsFC" data-cy="MonthsInput" />
			<label class="me-3">Months</label>
			<input type="number" class="form-control form-control-sm" [formControl]="weeksFC" data-cy="WeeksInput" />
			<label class="me-3">Weeks</label>
			<span class="d-inline-flex align-items-center">
				<input type="number" class="form-control form-control-sm me-2" [formControl]="daysFC"
					data-cy="DaysInput" />
				<select class="form-select form-control-sm me-3" [formControl]="daysTypeFC" data-cy="DaysTypeFc">
					<option *ngFor="let pair of daysList" [ngValue]="pair[0]" [innerText]="pair[1]"
						[attr.data-cy]="pair[0]"></option>
				</select>
				<select class="form-select form-control-sm" [formControl]="priorToFC" data-cy="PriorToFc">
					<option *ngFor="let pair of priorToList" [ngValue]="pair[0]" [innerText]="pair[1]"
						[attr.data-cy]="pair[0]"></option>
				</select>
			</span>
		</form>
		<div class="d-flex justify-content-end mt-4 mb-2">
			<button type="button" class="btn btn-sm btn-light" (click)="cancelOptions()"
				data-cy="CancelOptionsButton">Cancel</button>
			<button type="button" class="btn btn-sm btn-info ms-2" (click)="applyOptions()"
				data-cy="ApplyOptionsButton">Done</button>
		</div>
	</div>
</ng-template>