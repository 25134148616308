import { NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { flatMap } from "lodash";
import { IEntityId } from "rl-common/models/i-entity-id";
import { ContactService } from "rl-common/services/contact/contact.service";
import { IDUtil } from "rl-common/utils";
import { IContactPartyGroup } from "../models/i-contact-party-group";
import { IParty } from "../models/i-party";
import { OverwriteSyncMode } from "../models/overwrite-sync-mode";
import { PartySelectorComponent } from "../party-selector/party-selector.component";

@Component({
    selector: "rl-overwrite-sync-selector",
    templateUrl: "./overwrite-sync-selector.component.html",
    styleUrls: ["./overwrite-sync-selector.component.scss"],
    imports: [NgSwitch, NgSwitchCase, PartySelectorComponent]
})
export class OverwriteSyncSelectorComponent implements OnInit {
	@Input()
	parentEntityId: IEntityId;

	@Input()
	partyDictionary: { [partyId: number]: IParty };

	@Input()
	contactPartyGroups: IContactPartyGroup[];

	@Input()
	overwriteSyncMode: OverwriteSyncMode;

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onComplete = new EventEmitter<string>();

	selectedList: IParty[] = [];

	constructor(
		private readonly _contactService: ContactService
	) { }

	ngOnInit() {
	}

	updateList(list: IParty[]) {
		this.selectedList = list;
	}

	getPartyContactIds(partyId: number) {
		const parties = flatMap(this.contactPartyGroups, g => g.contactParties);
		const party = parties.find(p => p.partyId === partyId);
		return party.contacts.map(c => c.recordId);
	}

	getContactIds(): number[] {
		const partyIds = this.selectedList.map(p => p.partyID);
		const contactIds = flatMap(partyIds, pid => this.getPartyContactIds(pid));
		return contactIds;
	}

	cancel() {
		this.onCancel.emit();
	}

	apply() {
		if (this.overwriteSyncMode === OverwriteSyncMode.Overwrite) {
			this.overwrite();
		} else if (this.overwriteSyncMode === OverwriteSyncMode.OverwriteCatalog) {
			this.overwriteCatalogHierarchy();
		}
	}

	overwrite() {
		const parties = flatMap(this.contactPartyGroups, g => g.contactParties)
			.filter(p => this.selectedList.find(item => item.partyID === p.partyId));
		const contactParties = parties.map(p => {
			return {
				contactIds: p.contacts.map(c => c.recordId),
				partyId: p.partyId
			};
		});
		this._contactService.overwriteContact(IDUtil.toIdString(this.parentEntityId), contactParties)
			.subscribe((response) => {
				this.onComplete.emit(response.jobID);
			});
	}

	overwriteCatalogHierarchy() {
		const charTypeId = this.parentEntityId.charTypeId;
		const recId = this.parentEntityId.recId;
		const parties = flatMap(this.contactPartyGroups, g => g.contactParties)
			.filter(p => this.selectedList.find(item => item.partyID === p.partyId));
		const contactParties = parties.map(p => {
			return {
				contactIds: p.contacts.map(c => c.recordId),
				partyId: p.partyId
			};
		});
		this._contactService.overwriteContactCatalogHierarchy(charTypeId, recId, contactParties)
			.subscribe(() => {
				this.onComplete.emit();
			});
	}
}
