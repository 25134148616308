import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import moment from 'moment';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';

export const ONE_HOUR = 3_600_000;
export const POLLING_FREQUENCY = 3;
export const DEFAULT_LOGO_SRC = "/assets/img/rightsline-logo-r.svg";

@Component({
    selector: 'rl-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy {

	logoSrc: string;

	private readonly _subs: Subscription[] = [];

	constructor() { }

	ngOnInit(): void {
		this.logoSrc = this.logoUrl();
		const nextHour = moment().add(POLLING_FREQUENCY, "hour").startOf("hour");
		const startTimerIn = nextHour.diff(moment());
		const timerSub = timer(startTimerIn, ONE_HOUR * POLLING_FREQUENCY).pipe(
			map(() => {
				const ticks = (new Date()).getTime();
				const logoUrl = this.logoUrl();
				return `${logoUrl}?${ticks}`;
			}),
		).subscribe(nextUrl => {
			this.logoSrc = nextUrl;
		});
		this._subs.push(timerSub);
	}

	private logoUrl() {
		return environment.logoUrl ?? DEFAULT_LOGO_SRC;
	}

	revertToDefaultSrc() {
		this.logoSrc = DEFAULT_LOGO_SRC;
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}

}
