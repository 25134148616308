import { transition, trigger, useAnimation } from "@angular/animations";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { Subscription } from "dexie";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { DropdownOptions } from "rl-common/components/dropdown/dropdown.models";
import { CharDataType, ConstUtils, SystemIndicators } from "rl-common/consts";
import { IPickListSource, ISourceField } from "rl-common/services/entity-config/entity-config.models";
import { EntityConfigService } from "rl-common/services/entity-config/entity-config.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { SessionService } from "rl-common/services/session.service";
import { CharDataTypeUtil } from "rl-common/utils/char-data-type.util";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { distinctUntilChanged, take, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../../../../../common/components/checkbox-input/checkbox-input.component";
import { DropdownSingleComponent } from "../../../../../../common/components/dropdown/dropdown-single/dropdown-single.component";
import { NumberInputComponent } from "../../../../../../common/components/number-input/number-input.component";
import { TextInputComponent } from "../../../../../../common/components/text/text-input/text-input.component";
import { FeatureEnabledDirective } from "../../../../../../common/directives/feature-enabled.directive";
import { CharTypeNamePipe } from "../../../../../../common/pipes/char-type-name.pipe";

interface SourceFieldEditForm {
	charTypeId: FormControl<number>;
	sourceFieldId: FormControl<number>;
	sourceFieldName: FormControl<string>;
	mergeTag: FormControl<string>;
	dataType: FormControl<KeyValueOption>;
	length: FormControl<number>;
	systemIndicator: FormControl<number>;
	multipleIndicator: FormControl<number>;
	extractable: FormControl<boolean>;
	reportIndicator: FormControl<number>;
	charValueSet: FormControl<KeyValueOption>;
	description: FormControl<string>;
	hint: FormControl<string>;
}

interface KeyValueOption {
	key: number | string;
	value: string;
}

@Component({
	selector: "rl-source-field-edit",
	templateUrl: "./source-field-edit.component.html",
	styleUrls: ["./source-field-edit.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [NgIf, ReactiveFormsModule, FormsModule, NgClass, TextInputComponent, FeatureEnabledDirective, DropdownSingleComponent, CheckboxInputComponent, NumberInputComponent, NgFor, CharTypeNamePipe]
})
export class SourceFieldEditComponent implements OnInit, OnDestroy {
	@Input()
	sourceField: ISourceField = null;

	@Input()
	charTypeId: number;

	@Input()
	charDataCount: number;

	@Input()
	hasCharValueSourceId: boolean;

	@Output()
	onComplete = new EventEmitter<boolean>();

	formLoaded = false;
	title = "";
	form: FormGroup<SourceFieldEditForm>;
	isSaving = false;
	systemSourceField = false;
	_subs: Subscription[] = [];

	dataTypeList: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};

	pickListSources: IPickListSource[];
	pickListSourceList: DropdownOptions<KeyValueOption> = {
		items: [],
		rowKeyFn: (type: KeyValueOption) => type.key,
		rowLabelFn: (type: KeyValueOption) => type.value
	};

	picklistDataTypeIds = [CharDataType.Money, CharDataType.Alphanumeric, CharDataType.Checkbox];

	get isCreate() {
		return !this.sourceField;
	}

	get isEdit() {
		return !!this.sourceField;
	}

	isSuperAdmin: boolean;

	constructor(
		private _entityConfigService: EntityConfigService,
		private readonly _fb: FormBuilder,
		private readonly _growlerService: GrowlerService,
		private readonly _sessionService: SessionService) { }

	ngOnInit(): void {
		const sub2 = this._sessionService.isRlAdmin$.pipe(
			take(1),
			tap(result => {
				this.isSuperAdmin = result;
			})
		).subscribe();
		this._subs.push(sub2);

		this.setupDataTypeList();
		this.setupPickListSourceList();
		if (this.sourceField === null) {
			// create mode
			this.title = "Create New Source Field";
			this.setupCreateSourceField();
		} else {
			this.title = "Edit " + CharTypeIdUtil.toReportLabel(this.sourceField.charTypeID) + " Source Field - <strong>" +
				this.sourceField.sourceFieldName + "</strong>";
			this.setupEditSourceField();
		}

		const sub = this.form?.controls?.dataType?.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(result => {
			if (!this.picklistDataTypeIds.includes(result.key as number)) {
				this.form.controls.charValueSet.reset();
			}
		})

		this._subs.push(sub);
	}

	setupDataTypeList() {
		for (const dt of ConstUtils.getCharDataTypeValues()) {
			if (dt) {
				this.dataTypeList.items.push({ key: dt, value: CharDataTypeUtil.toDisplayName(dt) });
			}
		}
	}

	setupPickListSourceList() {
		const sub = this._entityConfigService.getPickListSources().pipe(
			tap(pickListSources => {
				this.pickListSources = [...pickListSources].sort((a, b) => (a.pickListSourceLabel.localeCompare(b.pickListSourceLabel)));
				for (const pls of this.pickListSources) {
					this.pickListSourceList.items.push({ key: pls.pickListSourceID, value: pls.pickListSourceLabel });
				}
			})
		).subscribe();

		this._subs.push(sub);
		this.pickListSourceList.items.push({ key: null, value: "Picklist Source" });
	}

	setupCreateSourceField() {
		const _fb = this._fb;
		this.form = _fb.group<SourceFieldEditForm>({
			charTypeId: _fb.control(this.charTypeId, Validators.required),
			sourceFieldId: _fb.control(null, {}), //Source Field ID generated on create
			sourceFieldName: _fb.control(null, Validators.required),
			mergeTag: _fb.control(null, Validators.required),
			description: _fb.control(null, {}),
			multipleIndicator: _fb.control(null, {}),
			extractable: _fb.control(null, {}),
			systemIndicator: _fb.control({ value: 0, disabled: !this.isSuperAdmin }),
			dataType: _fb.control({ key: CharDataType.Alphanumeric, value: "" }, Validators.required),
			length: _fb.control(10000, {}),
			charValueSet: _fb.control({ key: null, value: "" }, {}),
			reportIndicator: _fb.control(1, {}),
			hint: _fb.control(null, {})
		});

		this.form.controls.sourceFieldName.valueChanges.pipe(
			distinctUntilChanged()
		).subscribe(() => {
			const mergeTag = this.form.controls.sourceFieldName.value.trim()
				// Non-word and non-space characters
				.replace(/[^\w\s]/g, "")
				// Spaces with underscore
				.replace(/\s+/g, "_")
				.toLowerCase();
			this.form.controls.mergeTag.setValue(mergeTag);
		});

		this.subEnableDisableSystemIndicatorFields();

		this.formLoaded = true;
	}

	setupEditSourceField() {
		// crabb test
		const _fb = this._fb;
		this.form = _fb.group<SourceFieldEditForm>({
			charTypeId: _fb.control(this.sourceField.charTypeID, Validators.required),
			sourceFieldId: _fb.control(this.sourceField.sourceFieldID, Validators.required),
			sourceFieldName: _fb.control(this.sourceField.sourceFieldName, Validators.required),
			mergeTag: _fb.control(this.sourceField.mergeTag, Validators.required),
			description: _fb.control(this.sourceField.description, {}),
			multipleIndicator: _fb.control(this.sourceField.multipleIndicator, {}),
			extractable: _fb.control(this.sourceField.extractable, {}),
			systemIndicator: _fb.control({ value: this.sourceField.systemIndicator, disabled: !this.isSuperAdmin }),
			dataType: _fb.control({ value: { key: this.sourceField.dataTypeID, value: "" }, disabled: this.charDataCount > 0 }, Validators.required),
			length: _fb.control(this.sourceField.length, {}),
			charValueSet: _fb.control({ value: { key: this.sourceField.charValueSetID, value: "" }, disabled: this.hasCharValueSourceId }),
			reportIndicator: _fb.control(this.sourceField.reportIndicator, {}),
			hint: _fb.control(this.sourceField.hint, {})
		});

		this.subEnableDisableSystemIndicatorFields();

		if (this.sourceField.systemIndicator !== 0) {
			this.form.controls.mergeTag.disable();
			this.form.controls.multipleIndicator.disable();
			this.form.controls.extractable.disable();
			this.form.controls.dataType.disable();
			this.form.controls.length.disable();
		}

		this.systemSourceField = (this.sourceField.systemIndicator === SystemIndicators.System);

		this.formLoaded = true;
	}

	private subEnableDisableSystemIndicatorFields() {
		this.form.controls.systemIndicator.valueChanges.subscribe(value => {
			if (value !== 0) {
				this.form.controls.mergeTag.disable();
				this.form.controls.multipleIndicator.disable();
				this.form.controls.extractable.disable();
				this.form.controls.dataType.disable();
				this.form.controls.length.disable();
			} else {
				this.form.controls.mergeTag.enable();
				this.form.controls.multipleIndicator.enable();
				this.form.controls.extractable.enable();
				this.form.controls.dataType.enable();
				this.form.controls.length.enable();
			}
			this.systemSourceField = (value === SystemIndicators.System);
		});
	}

	async submit($event: Event) {
		if (this.form.invalid) {
			$event.preventDefault();
		} else {
			this.isSaving = true;
			let success = true;
			let errorMessage = "";
			try {
				const sourceField: Partial<ISourceField> = {
					charTypeID: this.form.controls.charTypeId.value,
					sourceFieldID: this.form.controls.sourceFieldId.value,
					sourceFieldName: this.form.controls.sourceFieldName.value,
					mergeTag: this.form.controls.mergeTag.value,
					dataTypeID: this.form.controls.dataType.value.key as number,
					length: this.form.controls.length.value,
					systemIndicator: this.form.controls.systemIndicator.value,
					multipleIndicator: +this.form.controls.multipleIndicator.value,
					extractable: this.form.controls.extractable.value,
					reportIndicator: +this.form.controls.reportIndicator.value,
					charValueSetID: this.form.controls.charValueSet.value?.key as string,
					description: this.form.controls.description.value,
					hint: this.form.controls.hint.value
				};

				if (this.isCreate) {
					await this._entityConfigService.createSourceField(sourceField as ISourceField).toPromise();
				}
				if (this.isEdit) {
					await this._entityConfigService.updateSourceField(sourceField as ISourceField).toPromise();
				}
			} catch (e) {
				success = false;
				errorMessage = e?.error?.message;
			} finally {
				this.isSaving = false;
			}
			if (success) {
				this.onComplete.next(true);
				this._growlerService.success().growl("Source Field saved.");
			} else {
				this._growlerService.error().growl(errorMessage);
			}
		}
	}

	getNumericValue(value: number | string): number {
		return value as number;
	}

	close() {
		this.onComplete.next(null);
	}

	ngOnDestroy(): void {
		this._subs.forEach(sub => sub.unsubscribe());
	}
}
