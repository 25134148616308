<div class="add-contact">
	<div class="modal-header">
		<h2 class="modal-title">Add Contact</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="close()" data-cy="CloseModal">
			<i class="far fa-times"></i>
		</button>
	</div>
	<aw-wizard [awNavigationMode] class="rl-wizard mt-3" navBarLayout="small" [disableNavigationBar]="true">
		<aw-wizard-step [stepTitle]="'Select Contact'">
			<div class="modal-body">
				<ul ngbNav #nav="ngbNav" class="nav-tabs tabset--rl grey nav" [destroyOnHide]="false"
					[(activeId)]="activeTabId">
					<li [ngbNavItem]="1">
						<a ngbNavLink>Search Existing Contacts</a>
						<ng-template ngbNavContent>
							<rl-entity-search #entitySearch [searchOptions]="searchOptions"
								(selectedStateChange)="selectedStateChange($event)" [layoutMaxHeight]="'40vh'">
							</rl-entity-search>
							<div class="border-top border-bottom my-3 py-3">
								<h4>Selected {{ searchOptions.charTypeId | pluralCharTypeName }}</h4>
								<p *ngIf="!selectedContact">No {{ searchOptions.charTypeId | pluralCharTypeName }}
									selected.</p>
								<div class="mt-2" *ngIf="selectedContact">
									<rl-chip *ngFor="let x of [selectedContact]" (onRemove)="clearSelections()">
										{{ x.title && x.title.length > 0 ? x.title : "Record " + x.recordID}}
									</rl-chip>
								</div>
							</div>
						</ng-template>
					</li>
					<li [ngbNavItem]="2">
						<a ngbNavLink>Create New {{ searchOptions.charTypeId | charTypeName:false }}</a>
						<ng-template ngbNavContent>
							<rl-create-entity-basic #entityCreate [charTypeId]="this.searchOptions.charTypeId"
								[availableTemplateIds]="createTemplateIds" class="add-contact__entity-create">
							</rl-create-entity-basic>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav"></div>
			</div>
			<div class="modal-footer">
				<em *ngIf="isCreatingNewContact">Saving New {{ searchOptions.charTypeId | charTypeName:false }}</em>
				<button (click)="close()" class="btn btn-light me-1" data-cy="CancelButton">
					Cancel
				</button>
				<button *ngIf="activeTabId == 2" (click)="saveNewContact()"
					[disabled]="isCreatingNewContact || !createFormIsValid" class="btn btn-success me-1"
					data-cy="SaveButton">
					Save <i class="far fa-chevron-right ms-2"></i>
				</button>
				<button *ngIf="activeTabId == 1" class="btn btn-info" type="button" [disabled]="!selectedContact"
					awNextStep data-cy="ContinueButton">
					Continue <i class="far fa-chevron-right ms-2"></i>
				</button>
			</div>
		</aw-wizard-step>
		<aw-wizard-step [stepTitle]="'Create Chart of Accounts'">
			<div *ngIf="selectedContact" class="modal-body">
				<div class="bg-light border-bottom p-4 mb-3">
					<h4 class="m-0">
						<strong class="pe-2">Selected {{ searchOptions.charTypeId | charTypeName:false }}:</strong>
						{{selectedContact.title}}
					</h4>
				</div>
				<div class="py-2 px-4">
					<h3 class="my-1">Create Chart of Accounts for this {{ searchOptions.charTypeId | charTypeName:false
						}}</h3>
					<p class="mb-2">A Contact must have at least two accounts in their Chart of Accounts.</p>
					<rl-chart-of-accounts [contact]="selectedContact" (onAccountsLoad)="setAccounts($event)">
					</rl-chart-of-accounts>
				</div>
			</div>
			<div class="modal-footer">
				<em *ngIf="accounts?.length > 1">Click 'Continue' to create an Accounting Process for this {{
					searchOptions.charTypeId |
					charTypeName:false }} now.</em>
				<button type="button" class="btn btn-info" awPreviousStep data-cy="PreviousButton">
					<i class="far fa-chevron-left me-2"></i> Previous
				</button>
				<button (click)="close()" [disabled]="accounts?.length < 2" class="btn btn-success"
					data-cy="DoneButton">
					Done
				</button>
				<button (click)="continue()" [disabled]="accounts?.length < 2" class="btn btn-info"
					data-cy="ContinueButton">
					Continue <i class="far fa-chevron-right ms-2"></i>
				</button>
			</div>
		</aw-wizard-step>
	</aw-wizard>

</div>