<div class="modal-header">
	<h2 class="modal-title">{{ isView ? "View" : (!financialPeriod ? "Create" : "Edit") }} Financial Period</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="form" [formGroup]="form">
		<div class="char-data__group">
			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Year</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display"
						[class.has-error]="form.controls.year.invalid && form.controls.year.touched">
						<input type="text" class="form-control" formControlName="year" *ngIf="!isView" />
						<span *ngIf="isView">{{ financialPeriod?.year }}</span>
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Financial Period</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display"
						[class.has-error]="form.controls.period.invalid && form.controls.period.touched">
						<input type="text" class="form-control" formControlName="period" *ngIf="!isView" />
						<span *ngIf="isView">{{ financialPeriod?.period }}</span>
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>Start Date</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display"
						[class.has-error]="form.controls.startDate.invalid && form.controls.startDate.touched">
						<rl-date-input formControlName="startDate" [disabled]="isView"></rl-date-input>
					</div>
				</div>
			</div>

			<div class="char-data__group__element">
				<div class="char-data__label col-form-label">
					<span>End Date</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display"
						[class.has-error]="form.controls.endDate.invalid && form.controls.endDate.touched">
						<rl-date-input formControlName="endDate" [disabled]="isView"></rl-date-input>
					</div>
				</div>
			</div>

			<div class="char-data__group__element" *ngIf="financialPeriod">
				<div class="char-data__label col-form-label">
					<span>Status</span>
				</div>
				<div class="char-data__control container m-0">
					<div class="char-data-element-display">
						<span>{{ financialPeriod?.status == 1 ? "Locked" : "Open" }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="close()">Cancel</button>
	<button type="button" class="btn btn-info" *ngIf="isView && financialPeriod.status == 0" (click)="isView = false">
		Edit
	</button>
	<button type="button" class="btn btn-success" *ngIf="!isView" [disabled]="isSaving || this.form.invalid"
		(click)="save()">Save</button>
</div>