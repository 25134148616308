<div class="modal-header">
	<h2 class="modal-title">Please confirm edits of multiple Templates: </h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="decline()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div *ngFor="let templateAssoc of invalidWarnings">
		<div *ngFor="let charDataWarning of templateAssoc.charData">
			<span>{{charDataWarning.matchingCmd.label}} ({{charDataWarning.matchingCmd.tagLabel}}) cannot be found on
			</span>
			<span class="fst-italic">{{charDataWarning.templateName}} </span>
			<span>template</span>
		</div>
		<div *ngFor="let associationWarning of templateAssoc.associations">
			{{associationWarning}}
		</div>
	</div>
	Would you like to apply these changes across all applicable templates?
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light" (click)="decline()">No</button>
	<button type="button" class="btn btn-info" (click)="accept()">Yes</button>
</div>