import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "environments/environment";
import { NotificationSortColumn, NotificationSortDirection, NotificationStatus } from "rl-common/components/notifications/notification.models";
import { NotificationService } from "rl-common/components/notifications/notification.service";
import { CharTypeId } from "rl-common/consts";
import { ParentEntityService } from "rl-common/services/entity/parent-entity/parent-entity.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { QuickLinksService } from "rl-common/services/quick-links/quick-links.service";
import { SessionService } from "rl-common/services/session.service";
import { SettingsService } from "rl-common/services/settings/settings.service";
import { VersionService } from "rl-common/services/version/version.service";
import { Observable, Subscription, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { RefreshPromptComponent } from "./refresh-prompt/refresh-prompt.component";
import { NewTabInModalDirective } from "../../../common/directives/new-tab-in-modal.directive";
import { LogoComponent } from "./logo/logo.component";
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe } from "@angular/common";
import { UniversalSearchComponent } from "./universal-search/universal-search.component";
import { HeaderFavoritesComponent } from "./header-favorites/header-favorites.component";
import { FaIconDirective } from "../../../common/directives/fa-icon.directive";
import { HeaderNotificationsComponent } from "./header-notifications/header-notifications.component";
import { ProfileDropdownComponent } from "./profile-dropdown/profile-dropdown.component";

@Component({
    selector: "rl-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    imports: [NewTabInModalDirective, RouterLink, LogoComponent, NgIf, UniversalSearchComponent, NgbDropdown, NgbDropdownToggle, HeaderFavoritesComponent, NgbDropdownMenu, FaIconDirective, HeaderNotificationsComponent, NgSwitch, NgSwitchCase, ProfileDropdownComponent, NgSwitchDefault, AsyncPipe]
})
export class RlHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

	private _subscriptions: Subscription[] = [];
	count$: Observable<number>;
	divImageUrl$ = this._settingsService.divImageUrl$;
	divName$ = this.sessionService.divName$;
	isFavorite$: Observable<boolean>;

	@ViewChild("quickLinksDropdown", { static: true })
	dropdown: NgbDropdown;

	@ViewChild("notificationsDropdown", { static: true })
	notificationsDropdown: NgbDropdown;

	private refreshPromptOverlay: OverlayRef;
	private refreshEntityPromptOverlay: OverlayRef;

	envName = environment.name;
	getDisplayName = (envName) => {
		return envName.replace("_", " ");
	};
	public readonly environmentName = this.getDisplayName(environment.name);
	public shouldShowEnvironment = environment.name === "production";

	constructor(
		private readonly _settingsService: SettingsService,
		private readonly _notificationService: NotificationService,
		public readonly sessionService: SessionService,
		public readonly linkHelper: LinkHelperService,
		private readonly _quickLinksService: QuickLinksService,
		private readonly _router: Router,
		private readonly _overlay: Overlay,
		private readonly _injector: Injector,
		private readonly _versionService: VersionService, 
		private readonly _parentEntityService: ParentEntityService
	) { }

	ngOnInit(): void {
		const sub = this.sessionService.isValid$
			.pipe(filter(v => v))
			.subscribe(() => {
				this._quickLinksService.loadQuickLinks();
				this.updateNotificationCountFavorites();
			});

		const navigationEnd$ = this._router.events.pipe(filter(x => x instanceof NavigationEnd));
		this.isFavorite$ = combineLatest([this._quickLinksService.links$, navigationEnd$]).pipe(
			map(([links]) => {
				const currentUrl = this._router.url.split("#")[0];
				const favoritesLinks = links.map(x => x.url);
				const isFavorite = favoritesLinks.includes(currentUrl);
				return isFavorite;
			})
		);

		const refreshPromptSub = this._versionService.promptRefresh$.pipe(
			filter(() => !this.refreshEntityPromptOverlay)
		).subscribe(() => {
			this.openRefreshPromptOverlay();
		});

		const refreshEntityPromptSub = this._parentEntityService.promptEntityRefresh$.pipe(
			filter(() => !this.refreshPromptOverlay)
		).subscribe(() => {
			this.openRefreshPromptOverlay(true);
		});

		this._subscriptions.push(sub, refreshPromptSub, refreshEntityPromptSub);
	}

	private updateNotificationCountFavorites() {
		this.count$ = this._notificationService.unreadNotificationCount$;
		const sub = this._notificationService.getNotificationCount$(
			CharTypeId.All,
			NotificationStatus.Unread,
			NotificationSortColumn.Created,
			NotificationSortDirection.Descending)
			.subscribe();
		this._subscriptions.push(sub);
	}

	ngAfterViewInit() {
		const sub = this._router.events.pipe(
			filter(x => x instanceof NavigationEnd)
		).subscribe(() => {
			this.dropdown.close();
			this.notificationsDropdown.close();
		});

		this._subscriptions.push(sub);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	private openRefreshPromptOverlay(isEntityRefresh: boolean = false) {
		if (!this.refreshPromptOverlay) {
			const positionStrategy = this._overlay.position()
				.global()
				.centerHorizontally()
				.top("20px");
			const portal = new ComponentPortal(RefreshPromptComponent, undefined, this._injector);
			this.refreshPromptOverlay = this._overlay.create({
				positionStrategy: positionStrategy,
				hasBackdrop: true,
				backdropClass: "cdk-overlay-transparent-backdrop",
				disposeOnNavigation: true
			});
			const overlayRef = this.refreshPromptOverlay.attach(portal);
			const component: RefreshPromptComponent = overlayRef.instance;
			component.isEntityRefresh = isEntityRefresh;
			const closeSub = component.onClose.subscribe(() => this.closeRefreshOverlay());
			this._subscriptions.push(closeSub);
		}
	}

	private closeRefreshOverlay() {
		if (this.refreshPromptOverlay) {
			this.refreshPromptOverlay.dispose();
			this.refreshPromptOverlay = undefined;
		}
	}

}
