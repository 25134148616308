import { transition, trigger, useAnimation } from "@angular/animations";
import { NgFor, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { CharDataType, CharTypeId, SystemIndicators } from "rl-common/consts";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IAccountingOperation } from "rl-common/services/accounting-processes/models/i-accounting-operation";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { FeatureEnabledDirective } from "../../../../common/directives/feature-enabled.directive";
import { AccountOperationEventComponent } from "../account-operation-event/account-operation-event.component";
import { AccountingOperationDateComponent } from "../accounting-operation-date/accounting-operation-date.component";
import { AccountingOperationTriggerComponent } from "../accounting-operation-trigger/accounting-operation-trigger.component";

@Component({
    selector: "rl-account-operation",
    templateUrl: "./account-operation.component.html",
    styleUrls: ["./account-operation.component.scss"],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [
                useAnimation(animationTransitionOpacity, {
                    params: {
                        opacityStart: 0,
                        opacityEnd: 1,
                        time: "250ms ease-out"
                    }
                })
            ])
        ])
    ],
    imports: [NgIf, NgFor, AccountingOperationTriggerComponent, AccountOperationEventComponent, FeatureEnabledDirective, AccountingOperationDateComponent]
})

export class AccountOperationComponent implements OnInit {
	anchorTemplates: ICharacteristicTemplate[] = [];
	anchorTemplateDates: ICharacteristicMetaData[] = [];
	anchorTemplatesDates: { [key: number]: ICharacteristicMetaData[] } = {};
	datesDictionary: { [key: number]: string } = {};
	allocationTemplateSystemIndicator = SystemIndicators.AllocationTemplate;

	@Input()
	anchorTemplateId: number;

	@Input()
	accountingOperation: IAccountingOperation;

	constructor(private readonly _oneConfig: OneConfigService) { }

	ngOnInit() {
		this.anchorTemplates = this._oneConfig.getTemplates(CharTypeId.Amount).filter(x => x.systemIndicator === this.allocationTemplateSystemIndicator);
		this.anchorTemplates.forEach(t => {
			const templateCmds = this._oneConfig.getTemplateCmds(CharTypeId.Amount, t.templateID);
			this.anchorTemplatesDates[t.templateID] = templateCmds.filter(cmd => cmd.dataTypeID === CharDataType.Date);
		});

		this.updateDatesDirectory();
	}

	private updateDatesDirectory() {
		if (this.anchorTemplateId) {
			this.anchorTemplateDates = (this.anchorTemplateId in this.anchorTemplatesDates) ? this.anchorTemplatesDates[this.anchorTemplateId] : [];
			this.datesDictionary = this.anchorTemplateDates.reduce((dict, date) => {
				dict[date.characteristicID] = date.label;
				return dict;
			}, {} as { [key: number]: string });
		}
	}
}