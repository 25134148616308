import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { RevokeOrGrantAccessRequest } from "rl-common/services/document/document.models";
import { DocumentService } from "rl-common/services/document/document.service";
import { Observable } from "rxjs";

@Component({
    selector: "rl-document-associated-user-modal",
    templateUrl: "./document-associated-user-modal.component.html",
    styleUrls: ["./document-associated-user-modal.component.scss"],
    imports: [NgFor, NgIf, AsyncPipe]
})
export class DocumentAssociatedUserModalComponent implements OnInit {

	@Input()
	charTypeId: number;

	@Input()
	recordId: number;

	@Input()
	parentCharTypeId: number;

	@Input()
	parentRecordId: number;

	@Output()
	onComplete = new EventEmitter<boolean>();

	onClose = new EventEmitter<void>();
	isGrantAccess = false;
	associatedUsers$: Observable<IEntitySearchDoc[]>;
	removedCotactRecIds: { [recordId: number]: boolean } = {};

	constructor(private readonly _documentService: DocumentService, private readonly _modal: NgbActiveModal) { }

	ngOnInit(): void {
		this.associatedUsers$ = this._documentService.getAssociatedUsers(this.charTypeId, this.recordId, this.parentCharTypeId, this.parentRecordId);
	}

	close() {
		this.onClose.emit();
		this._modal.close();
	}

	removeAssociatedUser(userRecordId: number) {
		const userIDs: number[] = [userRecordId];
		const docIDs: number[] = [this.recordId];
		const request: RevokeOrGrantAccessRequest = {
			isGrantAccess: this.isGrantAccess,
			contactRecIds: userIDs,
			documentEntityRecIds: docIDs
		};
		this._documentService.revokeOrGrantContactAccess(request).subscribe((response) => {
			this.removedCotactRecIds[userRecordId] = true;
		});
	}
}
