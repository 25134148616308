import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbNav, NgbNavContent, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavOutlet } from "@ng-bootstrap/ng-bootstrap";
import { CharTypeId } from "rl-common/consts";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharDataExtDataDateMath } from "rl-common/models/i-char-data-ext-data-date-math";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { CharDataTableService } from "rl-common/services/char-data-table.service";
import { FaIconDirective } from "../../../directives/fa-icon.directive";
import { EditAlertsComponent } from "../../date-alerts/edit-alerts/edit-alerts.component";
import { DatemathEditComponent } from "../datemath-edit/datemath-edit.component";

@Component({
    selector: "rl-date-options",
    templateUrl: "./date-options.component.html",
    styleUrls: ["./date-options.component.scss"],
    imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, FaIconDirective, NgbNavContent, DatemathEditComponent, NgIf, EditAlertsComponent, NgbNavOutlet]
})
export class DateOptionsComponent implements OnInit {

	@Input()
	dateMath: ICharDataExtDataDateMath;

	@Input()
	alerts: ICharDataExtDataAlert[];

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	isRelative: boolean;

	@Input()
	parentEntityId = "";

	@Input()
	recCharId: number;

	@Input()
	isBulkEdit = false;

	@Output()
	dateMathChange = new EventEmitter<ICharDataExtDataDateMath>();

	@Output()
	alertsChange = new EventEmitter<ICharDataExtDataAlert[]>();

	@Output()
	onClose = new EventEmitter();

	showAlerts = false;

	constructor(private readonly _charDataTableService: CharDataTableService) { }

	ngOnInit() {
		const recordID = this._charDataTableService.recordID$.value;
		this.showAlerts = (!!this._charDataTableService.template.templateID && !this.isRelative
			&& (this._charDataTableService.template.charTypeID !== CharTypeId.Right || (recordID !== null && recordID !== -1)));
	}

	dateMathChanged($event: ICharDataExtDataDateMath) {
		this.dateMathChange.emit($event);
	}

	alertsChanged($event: ICharDataExtDataAlert[]) {
		this.alertsChange.emit($event);
	}
}
