<div class="modal-header">
	<h2 *ngIf="!isCopy" class="modal-title">Move to:</h2>
	<h2 *ngIf="isCopy" class="modal-title">Copy to:</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<rl-entity-search *ngIf="dataSource" [dataSource]="dataSource" [searchOptions]="searchOptions"
		(selectedStateChange)="select($event)" target="_blank">
	</rl-entity-search>
</div>
<div class="modal-footer">
	<button (click)="cancel()" class="btn btn-light me-3">Cancel</button>
	<button (click)="save()" class="btn btn-success" [disabled]="isSaving || !saveEnabled || !selectedCount">
		<i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
		<span *ngIf="!isCopy">
			Move
		</span>
		<span *ngIf="isCopy">
			Copy
		</span>
		<span>
			{{childCount}} {{childCharTypeId | charTypeName:childCount !== 1}} to {{selectedCount}} {{ charTypeId |
			charTypeName:selectedCount !== 1}}
		</span>
	</button>
</div>