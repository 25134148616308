import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { every, first, isEmpty, isEqual } from "lodash";
import { IParty } from "rl-common/components/contacts/models/i-party";
import { CharDataType, ConstUtils } from "rl-common/consts";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { MasterListResult } from "rl-common/services/alerts/alerts.models";
import { AlertsService } from "rl-common/services/alerts/alerts.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { IWorkflowConfigRole } from "rl-common/services/workflow-config/workflow-config.models";
import { WorkflowConfigService } from "rl-common/services/workflow-config/workflow-config.service";
import { IDUtil } from "rl-common/utils";
import { Observable, Subscription } from "rxjs";
import { ChipComponent } from "../../chip/chip.component";
import { FormTableControlDirective } from "../../form-table/form-table-control.directive";
import { FormTableRowComponent } from "../../form-table/form-table-row/form-table-row.component";
import { HtmlEditorComponent } from "../../html-editor/html-editor.component";
import { LoaderComponent } from "../../panel/loader/loader.component";
import { DateAlerts } from "../date-alerts-common.consts";
import { AlertWhenOption } from "./alert-form.model";

@Component({
    selector: "rl-alert-form",
    templateUrl: "./alert-form.component.html",
    styleUrls: ["./alert-form.component.scss"],
    imports: [LoaderComponent, ReactiveFormsModule, FormTableRowComponent, FormTableControlDirective, NgFor, NgIf, HtmlEditorComponent, ChipComponent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgClass, FormsModule, AsyncPipe]
})
export class AlertFormComponent implements OnInit, OnDestroy {

	private _isSubmitting = false;

	private _alert: ICharDataExtDataAlert;

	@Input()
	cmd: ICharacteristicMetaData;

	@Input()
	cmds: ICharacteristicMetaData[];

	@Input()
	isConfig = false;

	@Input()
	parentEntityId: string;

	@Input()
	parentTitle: string;

	@Input()
	templateId: number;

	@Input()
	notificationMessagesEnabled: boolean;

	@Output()
	onApply = new EventEmitter<ICharDataExtDataAlert>();

	@Output()
	onCancel = new EventEmitter<void>();

	formGroup: UntypedFormGroup;

	alertContainsTypes$: Observable<MasterListResult[]>;
	frequencyList$: Observable<MasterListResult[]>;
	timeFrameList$: Observable<MasterListResult[]>;
	addActionBtn: FormControl<boolean>;

	roles: IWorkflowConfigRole[];
	roleLookup: { [roleId: number]: IWorkflowConfigRole };

	parties: IParty[] = [];
	partyLookup: { [partyId: number]: IParty };

	otherTemplateDates: ICharacteristicMetaData[];

	selectedRoleIds: number[] = [];
	selectedPartyIds: number[] = [];
	selectedPartyNotifyChildren: { [partyId: number]: boolean } = {};

	emails: string[] = [];
	workingEmail: string;

	isLoading = true;
	selectAllNotifyChildContactsChecked = false;

	selectedRoleCount = 0;
	selectedPartyCount = 0;
	selectedRoleMemberCount = 0;

	whenSetOrUpdatedOption = AlertWhenOption.WhenSetOrUpdated;
	onDayBeforeOrAfterOption = AlertWhenOption.OnBeforeOrAfter;

	private readonly _subscriptions: Subscription[] = [];

	@Input()
	set isSubmitting(isSubmitting: boolean) {
		this._isSubmitting = isSubmitting;
	}

	get isSubmitting() {
		return this._isSubmitting;
	}

	@Input()
	set alert(alert: ICharDataExtDataAlert) {
		const alertChanged = !isEqual(alert, this._alert);
		this._alert = alert;
		if (alertChanged) {
			this.buildForm();
		}
	}

	get alert() {
		return this._alert;
	}

	get alertWhenOption() {
		return this.formGroup && this.formGroup.get("alertWhen").value as AlertWhenOption;
	}

	get alertWhenSetOrUpdated() {
		const alertWhen = this.alertWhenOption || AlertWhenOption.WhenSetOrUpdated;
		return alertWhen === AlertWhenOption.WhenSetOrUpdated;
	}

	get hasAnotherDate() {
		return this.formGroup && this.formGroup.get("templateDateCharId").value;
	}

	get quantityControl() {
		return this.formGroup && this.formGroup.get("quantity");
	}

	get allRolesSelected() {
		const allRoleIds = this.roles && this.roles.map(x => x.roleId) || [];
		return every(allRoleIds, roleId => this.selectedRoleIds.includes(roleId));
	}

	get allPartiesSelected() {
		const allPartyIds = this.parties && this.parties.map(x => x.partyID) || [];
		return every(allPartyIds, partyId => this.selectedPartyIds.includes(partyId));
	}

	get emailFormControl() {
		return this.formGroup && this.formGroup.get("email");
	}

	get emailIsEmpty() {
		const email = this.emailFormControl && this.emailFormControl.value || "";
		return isEmpty(email);
	}

	get hasNoOtherDates() {
		return !this.otherTemplateDates || this.otherTemplateDates.length === 0;
	}

	get canRemoveRecipients() {
		const recipientCount = this.selectedRoleIds.length + this.selectedPartyIds.length + this.emails.length;
		return recipientCount > 1;
	}

	constructor(
		private readonly _formBuilder: UntypedFormBuilder,
		private readonly _alertsService: AlertsService,
		private readonly _workflowConfigService: WorkflowConfigService,
		private readonly _oneConfig: OneConfigService) { }

	ngOnInit() {
		this.frequencyList$ = this._alertsService.frequencyList();
		this.timeFrameList$ = this._alertsService.timeFrameList();
		this.alertContainsTypes$ = this._alertsService.containsValueTypeList();
		this.selectedRoleCount = this.selectedRoleIds.length;
		this.selectedPartyCount = this.selectedPartyIds.length;

		const isModule = ConstUtils.isModuleLevelCharType(this.cmd.charTypeID);

		if (isModule) {
			this.parties = this._oneConfig.getParties(this.cmd.charTypeID, this.templateId);
		} else {
			// TODO: Do we allow parties on components in UX2? APP-8110
			this.parties = this._oneConfig.getDivParties();
		}

		this.partyLookup = this.parties.reduce((acc, next) => {
			return acc[next.partyID] = next;
		}, {});

		const sub = this._workflowConfigService.roles().subscribe((roles) => {
			this.roleLookup = roles.reduce((lookup, next) => {
				lookup[next.roleId] = next;
				return lookup;
			}, {});
			this.roles = roles;
			this.getSelectedRoleMemberCount(this.selectedRoleIds);

			this.isLoading = false;
		});

		this._subscriptions.push(sub);

		this.otherTemplateDates = this.cmds ? this.cmds.filter(x => x.dataTypeID === CharDataType.Date && x.characteristicID !== this.cmd.characteristicID) : [];

		if (this.alert.selectedPartyIDs.split(";").map(x => +x).length === Object.keys(this.selectedPartyNotifyChildren).length) {
			this.selectAllNotifyChildContactsChecked = true;
		}

		this.buildForm();
	}

	private buildForm() {
		const quantity = this.alert && this.alert.quantity || 0;
		this.selectedRoleIds = this.alert && this.alert.selectedRoleIDs && this.alert.selectedRoleIDs.split(";").map(x => +x) || [];
		this.selectedPartyIds = this.alert && this.alert.selectedPartyIDs && this.alert.selectedPartyIDs.split(";").map(x => +x) || [];

		const notifyChildren = this.alert && this.alert.notifyChildrenPartyIds && this.alert.notifyChildrenPartyIds.split(";").map(x => +x) || [];
		notifyChildren.forEach((pty) => {
			this.selectedPartyNotifyChildren[pty] = true;
		})

		this.emails = this.alert?.toEmail?.split(";") ?? [];
		const timeFrameControl = new UntypedFormControl(this.alert && this.alert.timeframeID || 1);
		const alertWhen = this.alert && this.alert.alertWhenSetOrUpdated ? AlertWhenOption.WhenSetOrUpdated : AlertWhenOption.OnBeforeOrAfter;
		const alertWhenControl = new UntypedFormControl(alertWhen);

		const id = IDUtil.splitEntityID(this.parentEntityId);
		const recId = id.recID;
		const parentTitle = this.parentTitle;
		const defaultSubject = DateAlerts.defaultSubject(recId, parentTitle);


		this.formGroup = this._formBuilder.group({
			alertWhen: alertWhenControl,
			quantity: new UntypedFormControl(quantity, Validators.required),
			frequencyId: this.alert && this.alert.frequencyID || 1,
			timeframeID: timeFrameControl,
			templateDateCharId: this.alert && this.alert.templateDateCharId,
			templateDateContainsType: this.alert && this.alert.templateDateContainsType,
			createCustomAlert: this.alert && this.alert.createCustomAlert,
			createEmailInvite: this.alert && this.alert.createEmailInvite,
			doNotSendIfRecordInactive: this.alert && this.alert.doNotSendIfRecordInactive,
			addActionBtn: this.alert && this.alert.addActionBtn,
			subject: this.alert && this.alert.subject || defaultSubject,
			bodyHtml: this.alert && this.alert.bodyHTML,
			email: new UntypedFormControl("", Validators.email),
			triggerSNSOrSQS: this.alert && this.alert.triggerSNSOrSQS
		});

		if (!this.notificationMessagesEnabled) {
			this.formGroup.controls.triggerSNSOrSQS.disable();
		}

		const timeFrameSub = timeFrameControl.valueChanges.subscribe(value => {
			this.timeFrameChanged(+value);
		});

		const beforeOrAfterSub = alertWhenControl.valueChanges.subscribe(value => {
			this.whenOptionChanged(value);
		});

		this.whenOptionChanged(alertWhen);

		this._subscriptions.push(beforeOrAfterSub);
		this._subscriptions.push(timeFrameSub);
		this.updateAddBtn();
	}

	updateAddBtn() {
		if (this.formGroup.get("createCustomAlert").value === true) {
			this.formGroup.controls["addActionBtn"].enable();
		}else {
			this.formGroup.controls["addActionBtn"].disable();
		}
	}

	private getSelectedRoleMemberCount(roleIds: number[]) {
		const selectedRoles = this.roles.filter(r => roleIds.includes(r.roleId));
		const memberCounts = selectedRoles.map(r => r.memberCount);
		this.selectedRoleMemberCount = memberCounts.reduce((partialSum, a) => partialSum + a, 0);
	}

	private timeFrameChanged(timeFrameId: number) {
		if (timeFrameId === 2) {
			this.disableControls("quantity", "frequencyId");
		} else {
			this.enableControls("quantity", "frequencyId");
		}
	}

	private whenOptionChanged(option: AlertWhenOption) {
		const formControlNames = ["quantity", "frequencyId", "timeframeID", "templateDateCharId", "templateDateContainsType"];
		if (option === AlertWhenOption.OnBeforeOrAfter) {
			this.enableControls(...formControlNames);
		} else {
			this.disableControls(...formControlNames);
		}
	}

	private enableControls(...formControlNames: string[]) {
		const controls = this.getFormControls(formControlNames);
		controls.forEach(control => control.enable({ emitEvent: false }));
	}

	private disableControls(...formControlNames: string[]) {
		const controls = this.getFormControls(formControlNames);
		controls.forEach(control => control.disable({ emitEvent: false }));
	}

	private getFormControls(formControlNames: string[]) {
		return formControlNames.map(name => this.formGroup.get(name));
	}

	addAnotherDate() {
		this.formGroup.patchValue({ templateDateCharId: first(this.otherTemplateDates).characteristicID });
		this.formGroup.patchValue({ templateDateContainsType: 0 });
	}

	removeOtherDate() {
		this.formGroup.patchValue({ templateDateCharId: null });
		this.formGroup.patchValue({ templateDateContainsType: null });
	}

	getRoleName(roleId: number) {
		if (!this.roleLookup || !(roleId in this.roleLookup)) {
			return "Unknown Role";
		}
		return this.roleLookup[roleId].roleName;
	}

	selectAllRoles() {
		if (this.allRolesSelected) {
			this.selectedRoleIds = [];
			this.selectedRoleCount = 0;
		} else {
			this.selectedRoleIds = this.roles.map(x => x.roleId);
			this.selectedRoleCount = this.selectedRoleIds.length;
		}
	}

	toggleRole(role: IWorkflowConfigRole) {
		const roleIndex = this.selectedRoleIds.findIndex(x => x === role.roleId);
		if (roleIndex >= 0) {
			this.selectedRoleIds.splice(roleIndex, 1);
			--this.selectedRoleCount;
			this.selectedRoleMemberCount -= role.memberCount;
		} else {
			this.selectedRoleIds.push(role.roleId);
			++this.selectedRoleCount;
			this.selectedRoleMemberCount += role.memberCount;
		}
	}

	removeRole(index: number) {
		this.selectedRoleIds.splice(index, 1);
	}

	getPartyName(partyId: number) {
		if (!this.partyLookup || !(partyId in this.partyLookup)) {
			return "Unknown Party";
		}
		return this.partyLookup[partyId].partyName;
	}

	isPartyNotifyChildrenIdChecked(partyId: number) {
		return this.selectedPartyNotifyChildren[partyId] === true;
	}

	allPartyNotifyChildrenIdsSelected() {
		const hasActiveFalse = Object.values(this.selectedPartyNotifyChildren).some(bool => bool === false)

		if (this.selectedPartyIds.length === Object.keys(this.selectedPartyNotifyChildren).length && !hasActiveFalse) {
			this.selectAllNotifyChildContactsChecked = true;
		} else {
			this.selectAllNotifyChildContactsChecked = false;
		}
	}

	selectAllParties() {
		if (this.allPartiesSelected) {
			this.selectedPartyIds = [];
			this.selectedPartyNotifyChildren = {}
			this.selectedPartyCount = 0;
			this.selectAllNotifyChildContactsChecked = false;
		} else {
			this.selectedPartyIds = this.parties.map(x => x.partyID);
			this.selectedPartyCount = this.selectedPartyIds.length;
		}
	}

	toggleParty(party: IParty) {
		this.selectedPartyNotifyChildren[party.partyID] = false;
		const partyIndex = this.selectedPartyIds.findIndex(x => x === party.partyID);
		if (partyIndex >= 0) {
			this.selectedPartyIds.splice(partyIndex, 1);
			delete this.selectedPartyNotifyChildren[party.partyID];
			--this.selectedPartyCount;
		} else {
			this.selectedPartyIds.push(party.partyID);
			++this.selectedPartyCount;
		}
	}

	removeParty(index: number) {
		this.selectedPartyIds.splice(index, 1);
	}

	selectAllNotifyChildContacts(isChecked: boolean) {
		if (isChecked) {
			const allPartyIds = this.parties.map(x => x.partyID);
			allPartyIds.forEach((pty) => {
				if (this.selectedPartyIds.includes(pty)) {
					this.selectedPartyNotifyChildren[pty] = true;
				}
			})
			this.selectAllNotifyChildContactsChecked = true;
		} else {
			this.selectedPartyNotifyChildren = {};
			this.selectAllNotifyChildContactsChecked = false;
		}
	}

	addEmail() {
		const email = this.formGroup.get("email").value;
		this.emails.push(email);
		this.formGroup.patchValue({ email: "" });
	}

	removeEmail(index: number) {
		this.emails.splice(index, 1);
	}

	save() {
		const alertWhenSetOrUpdated = this.alertWhenSetOrUpdated;
		const notifyChildrenPartyIds: string[] = [];
		for (const pty in this.selectedPartyNotifyChildren) {
			if (this.selectedPartyNotifyChildren[pty]) {
				notifyChildrenPartyIds.push(pty);
			}
		}

		const formFields = {
			alertWhenSetOrUpdated,
			quantity: alertWhenSetOrUpdated ? 0 : this.formGroup.get("quantity").value,
			frequencyID: alertWhenSetOrUpdated ? 0 : this.formGroup.get("frequencyId").value,
			timeframeID: alertWhenSetOrUpdated ? 1 : this.formGroup.get("timeframeID").value,
			templateDateCharId: alertWhenSetOrUpdated ? null : this.formGroup.get("templateDateCharId").value,
			templateDateContainsType: alertWhenSetOrUpdated ? null : this.formGroup.get("templateDateContainsType").value,
			createCustomAlert: this.formGroup.get("createCustomAlert").value,
			createEmailInvite: this.formGroup.get("createEmailInvite").value,
			doNotSendIfRecordInactive: this.formGroup.get("doNotSendIfRecordInactive").value,
			subject: this.formGroup.get("subject").value,
			bodyHTML: this.formGroup.get("bodyHtml").value,
			selectedRoleIDs: this.selectedRoleIds.join(";"),
			selectedPartyIDs: this.selectedPartyIds.join(";"),
			notifyChildrenPartyIds: notifyChildrenPartyIds.join(";"),
			toEmail: this.emails.join(";"),
			triggerSNSOrSQS: this.formGroup.get("triggerSNSOrSQS").value,
			addActionBtn: this.formGroup.get("addActionBtn").value
		};
		const alert: ICharDataExtDataAlert = { ...this.alert, ...formFields };
		this.onApply.emit(alert);
	}

	cancel() {
		this.onCancel.emit();
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}
}
