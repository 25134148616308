import { Component, Input, OnInit } from "@angular/core";
import { LinkHelperService } from "rl-common/services/link-helper.service";
import { NewTabInModalDirective } from "../../../directives/new-tab-in-modal.directive";
import { RouterLink } from "@angular/router";
import { NgIf } from "@angular/common";

@Component({
    selector: "rl-entity-link",
    templateUrl: "./entity-link.component.html",
    styleUrls: ["./entity-link.component.scss"],
    imports: [NewTabInModalDirective, RouterLink, NgIf]
})
export class EntityLinkComponent implements OnInit {
	@Input()
	title: string;

	@Input()
	charTypeId: number;

	@Input()
	recordId: number;

	@Input()
	recordRevisionId: number;
	constructor(
		readonly linkHelper: LinkHelperService
	) { }

	ngOnInit(): void {
	}

}
