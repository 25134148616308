<div class="char-data__group__element">
	<div class="char-data__label col-form-label">
		<span>{{label == 'Exchange Rate Source' ? label : label + ' Currency'}}</span>
	</div>
	<div class="char-data__control container m-0">
		<div class="char-data-element-display">
			<ng-template #groupText>
				<div class="mt-2">{{defaultCurrency}}</div>
			</ng-template>
			<ng-container *ngIf="label !== 'Group'; else groupText">
				<select class="form-select" [disabled]="true" [formControl]="currencyControl" (change)="emitChange()"
					data-cy="transactionCurrencyDropdownMenu">
					<option *ngFor="let currency of currencyValues" [ngValue]="currency.charValueLabel"
						[attr.data-cy]="currency.charValueID">
						{{currency.charValueLabel}}
					</option>
				</select>
			</ng-container>
		</div>
	</div>
</div>