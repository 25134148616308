<div class="acct-operation-event container-fluid mb-2">
	<div class="row align-items-stretch">
		<div class="col-8 bg-light">
			<p class="pt-3 pb-0">When the above Triggers are met, the following Workflow will be applied to the anchor template<strong>{{
				getAnchorTemplateText() }}</strong>:
			<div class="row align-items-center">
				<div class="col-4 acct-operation-event__item purple-arrow d-flex flex-column">
					<strong>Initial Status</strong>
					<select class="form-select me-2" [formControl]="form.controls.statusId" data-cy="SelectStatus">
						<option [ngValue]="status.stepId" *ngFor="let status of availableStatuses">
							{{ status.stepName }}
						</option>
					</select>
				</div>
				<div class="col-4 acct-operation-event__item purple-arrow d-flex flex-column">
					<strong>Workflow Action</strong>
					<select class="form-select me-2" [formControl]="form.controls.workflowActionId"
						data-cy="SelectWorkflowAction">
						<option [ngValue]="action.actionId" *ngFor="let action of availableWfActions">
							{{ action.actionName }}
						</option>
					</select>
				</div>
				<div class="col-4 acct-operation-event__item d-flex flex-column">
					<strong>Resulting Status</strong>
					<span>{{ this.resultingStatus.stepName }}</span>
				</div>
			</div>
			<div class="py-3">
				<input type="checkbox" [formControl]="form.controls.workflowDisabled" class="form-check-input"
					data-cy="DoNotApplyWorkflow" />
				<label class="form-check-label ps-2">
					Do not apply Workflow
				</label>
			</div>
		</div>
		<div class="col-4">
			<p class="pt-3 pb-0">Then at least one Account must be debited and another credited:</p>
			<div class="row align-items-center">
				<strong class="col-5">Debit Account</strong>
				<strong class="col-5">Credit Account</strong>
			</div>
			<div class="row align-items-center acct-operation-event__accounts-row py-1"
				*ngFor="let control of form.controls.accountOrGroups.controls; let i = index"
				[class.has-error]="form.hasError('accountUniqueness')">
				<div class="col-5">
					<select class="form-select me-2" [(ngModel)]="control.value.debitId" [compareWith]="compareAccountOrGroup"
						(ngModelChange)="updateAccountOrGroupControl(i)" [attr.data-cy]="'DebitAccountOrGroup' + i">
						<optgroup label="Accounts">
							<option [ngValue]="{ id: account.id, isGroup: false}" *ngFor="let account of availableDebitAccounts(control)">
								{{ account.name }}
							</option>
						</optgroup>
						<optgroup label="Account Groups">
							<option [ngValue]="{ id: accountGroup.id, isGroup: true }" *ngFor="let accountGroup of availableDebitAccountGroups(control)">
								{{ accountGroup.groupName }}
							</option>
						</optgroup>
					</select>
				</div>
				<div class="col-5">
					<select class="form-select me-2" [(ngModel)]="control.value.creditId" [compareWith]="compareAccountOrGroup"
						(ngModelChange)="updateAccountOrGroupControl(i)" [attr.data-cy]="'CreditAccountOrGroup' + i">
						<optgroup label="Accounts">
							<option [ngValue]="{ id: account.id, isGroup: false }" *ngFor="let account of availableCreditAccounts(control)">
								{{ account.name }}
							</option>
						</optgroup>
						<optgroup label="Account Groups">
							<option [ngValue]="{ id: accountGroup.id, isGroup: true }" *ngFor="let accountGroup of availableCreditAccountGroups(control)">
							{{ accountGroup.groupName }}
						</option>
						</optgroup>
					</select>
				</div>
				<div *ngIf="form.controls.accountOrGroups.controls.length > 1"
					class="col-2 acct-operation-event__accounts-buttons align-items-center justify-content-end pe-2">
					<button class="btn btn-sm btn-icon" (click)="removeAccount(i)" title="Delete" data-cy="Delete">
						<i class="far fa-trash-alt"></i>
					</button>
				</div>
			</div>
			<div *ngIf="form.hasError('accountUniqueness')" class="text-danger">
				Account or Account Groups must be unique.
			</div>
			<div *ngIf="form.hasError('accountsRequired')" class="text-danger">
				At least one credit/debit account combination is required.
				<button class="btn btn-sm btn-link" (click)="addAnotherAccountOrGroup()" data-cy="AddRow">+ Add Row</button>
			</div>
			<div *ngIf="form.controls.accountOrGroups.controls?.length > 0" class="py-1">
				<button class="btn btn-sm btn-link" (click)="addAnotherAccountOrGroup()" data-cy="AddAnotherRow">+ Add Another
					Row</button>
			</div>
		</div>
	</div>
</div>
<div class="d-flex mb-2">
	<div class="ms-auto">
		<button class="btn btn-light btn-sm me-2" (click)="cancel()" data-cy="CancelForm">
			Cancel
		</button>
		<button class="btn btn-info btn-sm" (click)="save()" [disabled]="!form.valid"
			data-cy="SaveForm">
			Save
		</button>
	</div>
</div>