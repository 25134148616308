import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, Provider } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WorksheetType, IWorksheet, IWorksheetRecords } from "../../services/clipboard/clipboard.models";
import { ClipboardService } from "../../services/clipboard/clipboard.service";
import { ClipboardModalAdapter, IClipboardComponent } from "./clipboard.adapter";
import { EditClipboardComponent } from "./edit-clipboard/edit-clipboard.component";
import { isArray } from "lodash";

@Component({
	selector: "rl-clipboard",
	templateUrl: "./clipboard.component.html",
	styleUrl: "./clipboard.component.scss",
	imports: [ReactiveFormsModule, FormsModule, NgIf, NgClass, NgFor, EditClipboardComponent]
})
export class ClipboardComponent implements OnInit, IClipboardComponent {

	@Input()
	clipboardType: WorksheetType;

	@Output()
	onComplete = new EventEmitter<boolean>();

	snapshots: IWorksheet<unknown>[] = [];
	selectedSnapshot: IWorksheet<unknown> = null;
	items: unknown[];
	mode: number = 1;
	selectSnapshot = true;

	get clipboardTypeName() {
		switch (this.clipboardType) {
			case WorksheetType.AvailsSnapshot: return "Avails Snapshot";
			case WorksheetType.Records: return "Worksheet";
			default: return "Not Supported";
		}
	}

	constructor(
		private readonly _clipboardService: ClipboardService) { }

	ngOnInit() {
		this._clipboardService.getWorksheets(this.clipboardType)
			.subscribe((cbs) => {
				this.snapshots = cbs;
			});
	}

	cancel() {
		this.onComplete.emit(false);
	}

	continue() {
		if (this.selectedSnapshot) {
			switch (this.selectedSnapshot.clipboardTypeId) {
				case WorksheetType.AvailsSnapshot: {
					if (isArray(this.selectedSnapshot.data)) {
						this.selectedSnapshot.data.push(...this.items);
						this.items = this.selectedSnapshot.data;
					}
					break;
				}
				case WorksheetType.Records: {
					const d = this.selectedSnapshot.data as IWorksheetRecords;
					this.items = d.recordIds;
					break;
				}
			}
		}
		this.selectSnapshot = false;
	}

	complete(event) {
		this.onComplete.emit(event);
	}
}

export const CLIPBOARD_MODAL_PROVIDER: Provider = {
	provide: ClipboardModalAdapter,
	useFactory: () => new ClipboardModalAdapter(ClipboardComponent)
};

