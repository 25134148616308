import { NgFor, NgIf } from "@angular/common";
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbDropdown, NgbDropdownButtonItem, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { TelerikReportingModule, TelerikReportViewerComponent } from "@progress/telerik-angular-report-viewer";
import { AlliantDataService } from "app/+alliant/services/alliant-data.service";
import { AlliantDataQueries } from "rl-common/services/alliant/models/alliant-data-queries";
import { DataQueryParm } from "rl-common/services/alliant/models/data-query-parm";
import { SessionService } from "rl-common/services/session.service";
import { LoaderComponent } from "../../../../common/components/panel/loader/loader.component";
import { AlliantDataQueryService } from "../../../services/alliant-data-query.service";
import { GrowlerService } from "./../../../../common/services/growler.service";
import { TokenService } from "./../../../../common/services/token.service";
import { ViewerMessages } from "./viewer-messages";

@Component({
    selector: "rl-report-viewer",
    templateUrl: "./report-viewer.component.html",
    styleUrls: ["./report-viewer.component.scss"],
    imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgIf, NgFor, NgbDropdownButtonItem, NgbDropdownItem, TelerikReportingModule, LoaderComponent]
})
export class ReportViewerComponent implements OnInit, OnDestroy {

	// todo TA42883 have documentation review custom messages

	// style definition which viewer will put on its internal body
	public viewerContainerStyle;
	@Input() hideQueryParameters = false;

	dataQueries: AlliantDataQueries;
	selectedDataQueryIndex: number;

	protected parmValuesToSet: { parmFieldName: string; value: any }[] = [];
	public hasParameters = false;

	protected viewModePrintPreview = "PRINT_PREVIEW";
	protected viewModeInteractive = "INTERACTIVE";
	public viewMode: string = this.viewModeInteractive;

	public currentPagingWorksetGuid: string;
	public reportString: string;
	public reportSource: any;

	public ready = false;
	public haveReport = false;
	public running = false;

	public authToken: string;

	public reportViewer: TelerikReportViewerComponent;

	public serviceUrl;
	@ViewChild("interactiveToggle", { static: true }) interactiveToggleButton: ElementRef;

	// keepClientAlive property on viewer
	// TODO TA43698: consider using report viewer keepClientAlive feature if Telerik fixes in a new version
	// lots of issues with this in Telerik Reporting version R2 2021 SP1, "^13.21.616".
	// once turned on, it looks like you can't turn it off, will ping even when viewer is destroyed without a way to stop
	// support ticket has been entered, but for now we'll not use that system
	public keepViewerAlive = false;
	public removeViewer = false;
	public cleanedUp = false;

	public scaleMode = "SPECIFIC";
	public scale = 1;

	private pageModePaged = "SINGLE_PAGE";
	private pageModeScrolling = "CONTINUOUS_SCROLL";
	public pageMode: string = this.pageModePaged;

	public currentPage = 0;
	public pageCount = 0;
	public minPage = 0;

	isMenuLoaded = false;
	loadingAdditionalItems = false;

	public exportOptions: Array<any> = [{ text: "CSV", value: "CSV" }, { text: "Excel", value: "XLS" }, { text: "PDF", value: "PDF" }, { text: "Word", value: "DOCX" }, { text: "XPS", value: "XPS" }];
	//#endregion

	//#region parameters
	// most parameter logic is in BaseReportRunner base class
	public isParmAreaOpen = false;

	async ngOnInit(): Promise<void> {

		this.serviceUrl = this._alliantData.getTelerikReportingServiceUrl(this._sessionService.divId);

		this.viewerContainerStyle = {
			position: "relative",
			width: "100%",
			height: "100%",
			paddingLeft: "5px",
			paddingRight: "5px",
			["font-family"]: "Plus Jakarta Sans"
		};

		this.dataQueries = await this._alliantData.getValidAlliantDataQueries().toPromise();

		this.initReportViewer();

		this.isMenuLoaded = true;
	}

	@ViewChild("reportViewer", { static: false }) set content(content: TelerikReportViewerComponent) {
		if (content) { // initially setter gets called with undefined, because ViewChild inside *ngIf
			this.reportViewer = content;
		}
	}

	//#region initialization
	constructor(public _alliantDataQuery: AlliantDataQueryService, private _alliantData: AlliantDataService, private _tokenService: TokenService, private _sessionService: SessionService, private _growler: GrowlerService) { }

	private async setup() {
		await this.parmSetup();
		this.ready = true;
	}

	private initReportViewer() {
		const ourStrings = ViewerMessages.english;
		this.reportViewer.viewerObject.stringResources = Object.assign(this.reportViewer.viewerObject.stringResources, ourStrings);
	}
	//#endregion

	//#region syncing with viewer
	// Sync up when telerik report viewer page changes
	public syncPages = (e, args): void => { // use an arrow function because otherwise 'this' context is lost
		// do some funky javascript because the report viewer does not expose an updated page count or current page property
		const viewer = e.data.sender;
		// the currentPage is sometimes a function and sometimes a number, thanks telerik
		this.currentPage = viewer.currentPage instanceof Function ? viewer.currentPage() : viewer.currentPage;
		this.pageCount = viewer.pageCount instanceof Function ? viewer.pageCount() : viewer.pageCount;
		if (this.pageCount > 0) {
			this.minPage = 1;
		}
		this.haveReport = true;
		this.running = false;
	};

	// change the page
	public onPageChange(e: any): void {
		const page = e.skip + 1;
		if (page > 0 && page <= this.pageCount) {
			this.reportViewer.commands.goToPage.exec(page);
		}
	}

	// record the viewMode is changing
	public toggleViewMode() {
		// we need to invoke the viewer command togglePrintPreview, but I want to keep track of the value change myself
		this.viewMode = this.viewMode === this.viewModeInteractive ? this.viewModePrintPreview : this.viewModeInteractive;
	}
	//#endregion

	public async selectQuery(i) {
		this.resetReport();
		this.ready = false;
		this.selectedDataQueryIndex = i;
		this._alliantDataQuery.currentDataQuery = await this._alliantData.getAlliantDataQuery(this.dataQueries.items[i].sid).toPromise();
		this.setup();
	}

	//#region run report
	// handle running the report
	public async runClick() {
		if (!this.ready) {
			return;
		}
		this.haveReport = false;
		this.running = true;
		if (this.reportSource) {
			this.reportViewer.clearReportSource();
			this.reportViewer.refreshReport();
		}
		const successful = await this.prepareReportRun();
		if (successful) {
			this.reportViewer.setReportSource(this.reportSource);
		}
	}
	//#endregion

	//#region reset report
	// reset the report view
	public resetReport() {
		this.reportViewer.clearReportSource();
		this.reportViewer.refreshReport();
	}
	//#endregion

	//#region export
	public async export(event: any) {

		const exportType = event;

		// grab the label if any, and add export type as file extension
		let fileName = this._alliantDataQuery.currentDataQuery.queryName;
		fileName += "." + exportType.toLowerCase();

		await this.exportDirectly(exportType, fileName);
	}
	//#endregion

	//#region report running
	// call dataQueryPrep endpoint to do data retrieval and formation of a reportString
	private async prepareReportRun(): Promise<boolean> {
		let prepResult;

		// cleanup the previous run
		if (this.currentPagingWorksetGuid != null && this.currentPagingWorksetGuid != "") {
			await this.cleanupPagingWorkset(this.currentPagingWorksetGuid);
		}

		// gather up parameter values
		const parmInfo = await this.gatherParameterValues();
		// get max row to retrieve if set
		const maxRows = 0;
		/**TODO check max rows to retrieve (not currently pulled)
		 *
		if (this.currentDataQuery?.maxRowsToRetrieveNumber) {
			maxRows = this.currentDataQuery?.maxRowsToRetrieveNumber;
		} else {
			this.currentDataQuery?.maxRowsToRetrieveNumber = 0;
		}
		 */

		// call server to get results into paging workset
		try {
			prepResult = await this._alliantData.dataQueryPrep(this._alliantDataQuery.currentDataQuery.querySid, this.viewMode === "INTERACTIVE", parmInfo, maxRows).toPromise();

		} catch (error) {
			// fatal error on the server, so just cleanup a little and re-throw
			console.error("caught error");
			this.running = false;
			this._growler.error().growl(error);
			throw error;
		}
		if (prepResult?.hardError) {
			console.error("hard error");
			// lots of 'hard' errors are a bit benign, like permissions issues, and should have a friendly message instead of a UO
			// truly bad stuff is just exceptions on the server, which will be exceptions re-thrown above
			this.running = false;
			this._growler.error().growl(prepResult.hardError);
			return false;
		}
		if (prepResult?.softError) {
			console.error("soft error", prepResult.softError);
			// soft errors are even softer, like no rows being returned
			this.running = false;
			this._growler.error().growl(prepResult.softError);
			return false;
		}
		if (prepResult?.validationError) {
			console.error("val error");
			// validation errors should be pretty rare as we are working with a dataquery entity that was saved to the db
			this.running = false;
			this._growler.error().growl(prepResult.validationError);
			return false;
		}

		// prep was successful
		if (prepResult?.reportString !== "") {
			this.currentPagingWorksetGuid = prepResult.pagingWorksetGuid;
			this.reportString = prepResult.reportString;
			this.reportSource = { report: this.reportString, parameters: {} } as unknown as JSON;
			this.running = false;
			return true;
		}
		return false;
	}

	// #export
	// export by hitting QueryServingHandler on the web server, instead of asking the telerik report viewer to export
	// this is faster if you haven't run the report yet because we do not need to render the report
	private async exportDirectly(exportType: string, fileName: string) {

		// re-run the report in case parameters have changed
		this.running = true;
		const successful = await this.prepareReportRun();
		if (!successful) {
			return;
		}
		this.running = false;

		const res = await this._alliantData.dataQueryExport(this.reportString, exportType, fileName).toPromise();

		const base64toBlob = (base64Data, contentType: string = "") => {
			contentType = contentType || "";
			const sliceSize = 1024;
			const byteCharacters = atob(base64Data);
			const bytesLength = byteCharacters.length;
			const slicesCount = Math.ceil(bytesLength / sliceSize);
			const byteArrays = new Array(slicesCount);

			for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
				const begin = sliceIndex * sliceSize;
				const end = Math.min(begin + sliceSize, bytesLength);

				const bytes = new Array(end - begin);
				for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters.charCodeAt(offset);
				}
				byteArrays[sliceIndex] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		};

		const blob = base64toBlob(res.exportedBytes, "application/octet-stream");
		const blobUrl = URL.createObjectURL(blob);
		// Create an <a> element on the page that points to our blob with the correct file name
		const link = document.createElement("a");
		link.href = blobUrl;
		link.download = fileName;
		document.body.appendChild(link);
		// Simulate a click to start the download
		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);
		document.body.removeChild(link);
		// todo some sort of export completed popup? or just rely on the browser to tell you got a file?
	}

	// errors reported by the Telerik Report Viewer are sent here
	public viewerError = (e, args) => {// use instance arrow function so 'this' in function is the component not the js viewer
		if (args === "No report." || args === "") {
			return;
		}// ignore this expected 'error', as we do not have a report early on
		// note in this context 'this' is not the component, but we can grab the viewer that errored
		if (this.cleanedUp) {
			return; // we are gone we don't care
		}
		const message: string = args;
		if (this.reportSource && message.includes("Client with ID") && message.includes("not found. Expired.")) {
			this.resetReportViewer();
			// message about session expiration
			console.error("Query session expired due to inactivity.Press Run again to start a new query session.");
			return;
		}

		this.running = false; // re-enable the run button
		this.haveReport = false;
		throw new Error("Error occurred in report viewer:/n/n" + args);

	};

	private async parmSetup() {
		// if there are no parameters we are done,
		if (this._alliantDataQuery.currentDataQuery.parms === null || this._alliantDataQuery.currentDataQuery.parms.length < 1) {
			return;
		}
		this.hasParameters = true;
		//if any parameter values were sent in early, set them now
		this.parmValuesToSet.forEach(pv => {
			this._alliantDataQuery.setParameterValue(pv.parmFieldName, pv.value);
		});
		this.parmValuesToSet = [];
	}


	//#region parameter gathering
	protected async gatherParameterValues(): Promise<any[]> {
		if (!this.hasParameters) {
			return [];
		}
		const results: any[] = [];
		//loop thru all parms to get nice value
		this._alliantDataQuery.currentDataQuery.parms.forEach(parmItem => {
			const value = this.getParmValue(parmItem);
			if (value !== null && value !== undefined) {
				results.push({ fieldName: parmItem.label, globalQueryParmSID: parmItem.globalQueryParmSid, value });
			}
		});
		return results;
	}

	//TODO: double check grabbedValue logic
	private getParmValue(parm: DataQueryParm): any {
		// grab the value(s) entered
		// note this is very similar to what column header filtering and quick search do
		const rawVal = parm.value;
		let grabbedValue: any = null;
		if (rawVal) {
			// For entity-typed fields with a value, do special processing
			if (parm.apiEndpoint && rawVal) {
				grabbedValue = rawVal.sid;
			} else if (rawVal instanceof Date) {
				grabbedValue = rawVal.toISOString();
			} else {
				if (typeof rawVal === "string") {
					let stringVal: string = rawVal;
					// filter value is user data so need to escape ' and \ with \  (note js also escapes with \ so that is why there are doubles here)
					stringVal = stringVal.replace("\\", "\\\\");
					stringVal = stringVal.replace("\'", "\\'");
					grabbedValue = stringVal;
				} else {
					grabbedValue = rawVal;
				}
			}
		}

		if (grabbedValue === null || grabbedValue === undefined) {
			return null;
		}
		return grabbedValue;
	}

	/*
	 * if the reportViewer gets in a bad state, remove it from the DOM and setup the new one
	 * */
	private async resetReportViewer() {
		this.removeViewer = true;
		await new Promise(r => setTimeout(r, 100)); // give some time for everything the catch up
		this.running = false;
		this.haveReport = false;
		this.currentPage = 0;
		this.pageCount = 0;
		this.removeViewer = false;
		this.initReportViewer();
	}

	//#region cleanup
	ngOnDestroy() {
		this.cleanedUp = true;

		// cleanup the previous run
		if (this.currentPagingWorksetGuid != null && this.currentPagingWorksetGuid != "") {
			this.cleanupPagingWorkset(this.currentPagingWorksetGuid);
			this.reportString = "";
			this.reportSource = {};
		}
		if (this.reportViewer) {
			this.reportViewer.clearReportSource();
			this.reportViewer.refreshReport();
		}

	}
	//#endregion
	private async cleanupPagingWorkset(pagingWorksetGuid: string) {
		await this._alliantData.cleanupPagingWorkset(pagingWorksetGuid).toPromise();
	}

	public async getAuthToken() {
		if (this.authToken) {
			return this.authToken;
		} else {
			this.authToken = await this._tokenService.assertToken().toPromise();
			return this.authToken;
		}
	}
}
