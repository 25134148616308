<div *ngIf="entity && template">
	<div data-cy="EditEntityContainer">
		<div class="modal-header" data-cy="EditEntityHeader">
			<h2 class="modal-title" *ngIf="entity">Edit {{entity.title}}</h2>
			<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()"
				data-cy="CloseEditEntityModuleButton">
				<i class="far fa-times"></i>
			</button>
		</div>
		<div class="modal-body scroll-vert" data-cy="ModalBody">
			<div *ngIf="template && !isRelative">
				<rl-component-relationships [charTypeId]="charTypeId" [templateId]="template.templateID"
					[recordId]="recordId" [defaultSelectStateDict]="defaultSelectStateDict"
					(onRelationshipStateUpdated)="updateRelationshipStates($event)" data-cy="RlComponentRelationships">

				</rl-component-relationships>
			</div>
			<div>
				<rl-char-data-table #table [charData]="charData" [template]="template" [recordId]="entity.recordID"
					[editMode]="true" [isRelative]="isRelative" [parentEntityId]="parentEntityId"
					(tableCharDataChange)="charData=$event.charData" data-cy="RlCharDataTable">
				</rl-char-data-table>
			</div>
		</div>
		<div class="modal-footer" data-cy="EditEntityFooter">
			<button (click)="cancel()" class="btn btn-light me-3" data-cy="CancelEditEntityModuleButton">Cancel</button>
			<button (click)="save()" class="btn btn-success" [class.disabled]="!table.valid"
				[disabled]="!table.valid || isSaving || !areRelsValid" data-cy="SaveEditEntityModuleButton">
				<i class="far fa-spinner fa-spin" *ngIf="isSaving"></i>
				Save
			</button>
		</div>
	</div>
</div>