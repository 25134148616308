<div class="mx-3">
	<div class="dashboard-panel-head d-flex justify-content-between align-items-center">
		<h3 *ngIf="displayHeader" class="m-0">Notifications</h3>
		<div class="d-flex justify-content-end align-items-center">
			<span class="text-nowrap">Filter by:</span>
			<select class="form-select ms-2" [(ngModel)]="selectedCharType" (change)="reload()">
				<option *ngFor="let opt of charTypeOptions" [value]="opt.value">{{opt.label}}</option>
			</select>
			<select class="form-select ms-3" [(ngModel)]="selectedStatus" (change)="reload()">
				<option *ngFor="let opt of statusOptions" [value]="opt.value">{{opt.label}}</option>
			</select>
			<div class="ms-3" ngbDropdown placement="bottom-right">
				<button aria-label="Expand Menu" class="btn btn-icon btn-info hide-caret" ngbDropdownToggle
					role="button"><i class="far fa-bars"></i></button>
				<div ngbDropdownMenu>
					<button ngbDropdownItem (click)="setSelectedRead()">Mark as Read</button>
					<button ngbDropdownItem (click)="setSelectedUnread()">Mark as Unread</button>
					<button ngbDropdownItem (click)="deleteSelected()">Delete</button>
				</div>
			</div>
		</div>
	</div>
	<rl-grid [options]="gridOptions" [dataSource]="dataSource" [layoutOptions]="{height: 290}"
		data-cy="NotificationsRLGrid">
		<div class="text-truncate" [class.bold]="cellData.status === 'S'" *rlCellTemplate="'link_id'; let cellData">
			<a [routerLink]="linkHelper.go(cellData.charTypeId, cellData.recordId)" target="_blank"
				(click)="markAsRead(cellData)">
				<span>{{cellData.recordId}}</span>
				<span *ngIf="cellData.recordRevisionId > 0">-{{cellData.recordRevisionId}}</span>
			</a>
		</div>
		<div class="text-truncate" [class.bold]="cellData.status === 'S'" *rlCellTemplate="'link_title'; let cellData">
			<a [routerLink]="linkHelper.go(cellData.charTypeId, cellData.recordId)" target="_blank"
				(click)="markAsRead(cellData)">
				<span>{{cellData.entityTitle}}</span>
				<span *ngIf="cellData.recordRevisionId > 0">-{{cellData.recordRevisionId}}</span>
			</a>
		</div>
		<div class="text-truncate" [class.bold]="cellData.isBold" *rlCellTemplate="'bold'; let cellData"
			[style.max-width.px]="500">
			{{cellData.value}}
		</div>
		<div class="text-truncate" [class.bold]="cellData.isBold" [style.max-width.px]="750"
			*rlCellTemplate="'html'; let cellData">
			{{cellData.value | htmlToPlain}}
		</div>
		<ng-container *rlGridRowHoverOps="let rowData">
			<button class="btn btn-icon btn-info" title="View" (click)="readNotification(rowData)">
				<i class="far fa-eye"></i>
			</button>
			<button class="btn btn-icon btn-remove" title="Delete">
				<i class="far fa-trash-alt" (click)="delete(rowData)"></i>
			</button>
		</ng-container>
	</rl-grid>
</div>