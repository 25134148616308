<div [class.ms-3]="!nameIsEmpty && level !== 0">
	<h5 *ngIf="!nameIsEmpty">
		<button class="btn btn-link" (click)="isExpanded = !isExpanded">
			<span [class.text-muted]="nameIsEmpty">{{ categoryName }}</span>
			({{reportCount}})
			<ng-container></ng-container>
			<i *ngIf="isExpanded" class="far fa-angle-up"></i>
			<i *ngIf="!isExpanded" class="far fa-angle-down"></i>
		</button>
	</h5>
	<ng-container *ngIf="isExpanded">
		<div *ngFor="let subCat of category.subCategories">
			<rl-select-izenda-report [category]="subCat" [level]="level + 1" (onReportSelected)="selectReport($event)"
				[selectedReports]="selectedReports" [unselectableReports]="unselectableReports">
			</rl-select-izenda-report>
		</div>
		<div class="list-group">
			<button *ngFor="let report of category.reports" class="list-group-item list-group-item-action"
				(click)="selectReport(report)"
				[disabled]="selectedReports.has(report.id) || unselectableReports.has(report.id)">
				<i class="far fa-plus me-2"
					[class.fa-plus]="!selectedReports.has(report.id) && !unselectableReports.has(report.id)"
					[class.fa-minus]="selectedReports.has(report.id) || unselectableReports.has(report.id)"></i>
				{{ report.name }}
			</button>
		</div>
	</ng-container>
</div>