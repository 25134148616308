<ul ngbNav #nav="ngbNav" class="nav-tabs">
	<li ngbNavItem>
		<a ngbNavLink data-cy="SearchTab">Search</a>
	
		<ng-template ngbNavContent>
			<rl-entity-search [searchOptions]="searchOptions" [keywords]="keywords"
				(selectedStateChange)="select($event)" target="_blank">
			</rl-entity-search>
		</ng-template>
	</li>
	<li ngbNavItem *ngIf="isMultiple">
		<a ngbNavLink data-cy="InputListTab">Input List</a>
	
		<ng-template ngbNavContent>
			<rl-input-list [childCharTypeId]="charTypeId" (onValidate)="updateValidated($event)"
				[includeParentRecordId]="isTablesOrRights"></rl-input-list>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="nav"></div>
