import { EventEmitter, Injectable, Type } from "@angular/core";
import { IAccountingContact } from "rl-common/services/accounting-processes/models/i-accounting-contact";
import { IAccountingProcess } from "rl-common/services/accounting-processes/models/i-accounting-process";
import { ModalAdapter } from "rl-common/services/modal-adapter/modal-adapter";
import { ICopyAccountingProcessResult } from "./models/i-copy-accounting-process-result";
export interface ICopyAccountingProcessModalComponent {
	currentContactId: number;
	selectedContact: IAccountingContact;
	accountingProcess: IAccountingProcess;
	onComplete: EventEmitter<ICopyAccountingProcessResult>;
}

@Injectable({ providedIn: "root" })
export class CopyAccountingProcessModalAdapter extends ModalAdapter<ICopyAccountingProcessModalComponent> {
	constructor(type: Type<ICopyAccountingProcessModalComponent>) {
		super(type);
	}

}


