import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from "@angular/forms";
import { cloneDeep, isEmpty, some } from "lodash";
import { ILayoutGroup, IModLayout } from "rl-common/components/mod-details-layout/mod-layout.models";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { PromptService } from "rl-common/services/prompt/prompt.service";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { TextInputComponent } from "../../../../../common/components/text/text-input/text-input.component";


export interface IModLayoutForm {
	sections: FormArray<FormControl<ILayoutGroup>>;
}

@Component({
	selector: "rl-edit-panel-groups-modal",
	templateUrl: "./edit-panel-groups-modal.component.html",
	styleUrls: ["./edit-panel-groups-modal.component.scss"],
	imports: [CdkDropList, NgFor, CdkDrag, CdkDragHandle, TextInputComponent, ReactiveFormsModule, FormsModule]
})
export class EditPanelGroupsModalComponent implements OnInit, OnDestroy, OnChanges {

	@Input()
	layout: IModLayout;

	form: FormGroup<IModLayoutForm>;
	newGroupName: string = ``;

	@Output()
	onComplete = new EventEmitter<IModLayout>();

	_subs: Subscription[] = [];

	get sections() {
		return this.form.controls.sections.value;
	}

	get isValid() {
		return this.form.valid;
	}

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _promptService: PromptService
	) { }

	ngOnInit() {
		this.buildForm(this.layout);
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.layout && this.layout) {
			this.buildForm(this.layout);
		}
	}

	buildForm(modLayout: IModLayout = null) {
		modLayout = modLayout ?? { groups: [] };
		const formControls = modLayout.groups.map(x => new FormControl<ILayoutGroup>(cloneDeep(x)));
		this.form = this._formBuilder.group({
			sections: new FormArray(formControls)
		}, { validators: panelGroupsValidator });
	}

	dropSection(event: CdkDragDrop<ILayoutGroup[]>) {
		const sections = this.sections;
		moveItemInArray(sections, event.previousIndex, event.currentIndex);
		this.form.controls.sections.setValue(sections);
	}

	moveToTop(previousIndex: number) {
		const sections = this.sections;
		moveItemInArray(sections, previousIndex, 0);
		this.form.controls.sections.setValue(sections);
	}

	addSection() {
		const control = new FormControl<ILayoutGroup>({ title: this.newGroupName, panels: [] });
		this.form.controls.sections.push(control);
		this.newGroupName = ``;
	}

	close() {
		this.onComplete.emit(null);
	}

	done() {
		this.onComplete.emit({ groups: this.sections });
	}

	deleteSection(deletePrompt: TemplateRef<unknown>, index: number) {
		const section = this.sections[index];
		// don't bother to prompt if the group is empty
		if (isEmpty(section.panels)) {
			this.removeSection(index);
			return;
		}

		const sub = this._promptService.confirm(`Delete Section`, deletePrompt, { group: section }).pipe(
			filter(result => !!result),
			tap(() => this.removeSection(index))
		).subscribe();

		this._subs.push(sub);
	}

	private removeSection(index: number) {
		this.form.controls.sections.removeAt(index);
	}

	ngOnDestroy(): void {
		this._subs.forEach(x => x.unsubscribe());
	}
}

export const panelGroupsValidator: ValidatorFn = (form: FormGroup<IModLayoutForm>): ValidationErrors | null => {
	const panelGroupsArray = form.controls.sections;
	if (isEmpty(panelGroupsArray.controls)) {
		return { sectionsRequired: true };
	}
	const titleCounts: { [title: string]: number } = panelGroupsArray.value.reduce((acc, next) => {
		const lowerTitle = next.title.trim().toLocaleLowerCase();
		if (lowerTitle in acc) {
			acc[lowerTitle] += 1;
		} else {
			acc[lowerTitle] = 1;
		}
		return acc;
	}, {});

	const hasDupes = some(Object.values(titleCounts), x => x > 1);
	if (hasDupes) {
		return { titleUniqueness: true };
	}

	return null;
};