import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IEntityId } from "rl-common/models/i-entity-id";
import { IContactPartyGroup } from "../contacts/models/i-contact-party-group";
import { IParty } from "../contacts/models/i-party";
import { OverwriteSyncMode } from "../contacts/models/overwrite-sync-mode";
import { OverwriteSyncSelectorComponent } from '../contacts/overwrite-sync-selector/overwrite-sync-selector.component';

@Component({
    selector: 'rl-overwrite-catalog-hierarchy-modal',
    templateUrl: './overwrite-catalog-hierarchy-modal.component.html',
    styleUrls: ['./overwrite-catalog-hierarchy-modal.component.scss'],
    imports: [OverwriteSyncSelectorComponent]
})
export class OverwriteCatalogHierarchyModalComponent implements OnInit {

	@Input()
	parentEntityId: IEntityId;

	@Input()
	contactPartyGroups: IContactPartyGroup[];

	@Input()
	overwriteSyncMode: OverwriteSyncMode;

	@Input()
	partyDictionary: { [partyId: number]: IParty };

	@Output()
	onComplete = new EventEmitter<string>();

	constructor() { }

	ngOnInit(): void {
	}

	cancel(jobId) {
		this.onComplete.emit(jobId);
	}
}
