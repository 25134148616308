import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { find } from "lodash";
import { CharTypeId } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ContactService } from "rl-common/services/contact/contact.service";
import { EntityService } from "rl-common/services/entity/entity.service";
import { GrowlerService } from "rl-common/services/growler.service";
import { IEntityData } from "rl-common/services/mod-detail/mod-detail.models";
import { SessionService } from "rl-common/services/session.service";
import { IDUtil } from "rl-common/utils";
import { CharacteristicUtil, TagLabel } from "rl-common/utils/characteristic.util";
import { Subscription } from "rxjs";
import { CharDataTableComponent } from "../../../char-data/char-data-table.component";
import { IContactsDictionaries } from "../../models/i-contacts-dictionaries";

export interface IEditCompleteEvent {
	newTitle: string;
}

@Component({
    selector: "rl-edit-contact-char-data",
    templateUrl: "./edit-contact-char-data.component.html",
    styleUrls: ["./edit-contact-char-data.component.scss"],
    imports: [CharDataTableComponent, NgIf]
})
export class EditContactCharDataComponent implements OnInit, OnChanges, OnDestroy {
	@Input()
	recordId: number;

	@Input()
	dictionaries: IContactsDictionaries;

	@Input()
	dealId: number;

	@Input()
	contactNameChar: ICharacteristicData;

	@Output()
	onEditComplete = new EventEmitter<IEditCompleteEvent>();

	@Output()
	onCancel = new EventEmitter<void>();

	@Output()
	onSave = new EventEmitter<void>();

	modalCharData: ICharacteristicData[];
	modalTemplate: ICharacteristicMetaDataCollection;
	entityData: IEntityData;
	isLoading = false;
	isSaving = false;

	subs: Subscription[] = [];
	constructor(
		private readonly _sessionService: SessionService,
		private readonly _entityService: EntityService,
		private readonly _contactService: ContactService,
		private readonly _growlerService: GrowlerService
	) { }

	ngOnInit() {
		this.setup();
	}

	ngOnChanges(): void {
		this.setup();
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => {
			sub.unsubscribe();
		});
	}

	setup() {
		if (!this.recordId) {
			return;
		}
		this.modalCharData = this.dictionaries.contacts[this.recordId].characteristics.characteristicDatas.filter(x => x.recordCharacteristicID > -1);
		const contactNameCharacteristic = this.dictionaries.contacts[this.recordId].characteristics.characteristicDatas.find(x => x === this.contactNameChar);
		if (contactNameCharacteristic && !this.modalCharData.some(c => c.recordCharacteristicID === contactNameCharacteristic.recordCharacteristicID)) {
			if (contactNameCharacteristic.recordCharacteristicID < 0) {
				contactNameCharacteristic.recordCharacteristicID = 0;
				this.modalCharData.push(contactNameCharacteristic);
			}
		}
		this.modalTemplate = this.dictionaries.contacts[this.recordId].characteristics.templateMetaData;
	}

	save() {
		this.isLoading = true;
		const entityId = IDUtil.toID(this._sessionService.divId, CharTypeId.User, this.recordId);
		const sub = this._entityService.saveCharData(entityId, this.modalCharData)
			.subscribe(charData => {
				const oldEntityTitle = CharacteristicUtil.getCharacteristic(TagLabel.EntityTitle, this.dictionaries.contacts[this.recordId].characteristics.templateMetaData, this.dictionaries.contacts[this.recordId].characteristics.characteristicDatas);
				const newEntityTitle = find(charData, cd => cd.charactersticID === oldEntityTitle.charactersticID);
				this.isLoading = false;
				this.modalCharData = charData;
				this.onEditComplete.emit({
					newTitle: newEntityTitle.value
				});
			});
		this.subs.push(sub);
	}

	saveContactOnDeal() {
		this.isSaving = true;
		this._contactService.updateContactOnDeal(CharTypeId.Transaction, this.dealId, this.recordId, this.modalCharData)
			.subscribe(() => {
				this._growlerService.success().growl(`Update complete`);
				this.onSave.emit();
				this.isSaving = false;
			});
	}

	cancel() {
		this.onCancel.emit();
	}
}
