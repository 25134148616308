<div class="modal-header">
	<h2 class="modal-title">
		@if(isNewPanel) {
		New Panel
		} @else {
		Edit Panel
		}
	</h2>
	<button type="button" class="btn btn-close" aria-label="Close" (click)="close()">
		<i class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-12">
			@if (form?.errors?.["titleUniqueness"]) {
			<div class="alert alert-danger">
				Panel titles must be unique.
			</div>
			}
			<rl-form-table-row>
				<ng-template rlFormTableLabel>Panel Title</ng-template>
				<ng-template rlFormTableControl>
					<rl-text-input class="mb-4" [formControl]="form.controls.title"
						[dataLabel]="'PanelTitleInput'"></rl-text-input>
				</ng-template>
			</rl-form-table-row>
			<rl-form-table-row>
				<ng-template rlFormTableLabel>Panel Type</ng-template>
				<ng-template rlFormTableControl>
					<rl-dropdown-single [formControl]="form.controls.panelType" [options]="panelTypeOptions"  [showEmptyOption]="false" [dataLabel]="'panelType'">
					</rl-dropdown-single>
				</ng-template>
			</rl-form-table-row>
			<rl-form-table-row>
				<ng-template rlFormTableLabel>Display On Summary</ng-template>
				<ng-template rlFormTableControl>
					<rl-checkbox-input [label]="''" [formControl]="form.controls.displayOnSummary"
						[dataLabel]="'DisplayOnSummary'"></rl-checkbox-input>
				</ng-template>
			</rl-form-table-row>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-light ms-auto" (click)="close()">Cancel</button>
	<button (click)="done()" [disabled]="!isValid" class="btn btn-success">
		Done
	</button>
</div>