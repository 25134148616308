<div class="modal-content">
	<div class="modal-header">
		<h2 class="modal-title">Date Alerts</h2>
		<button type="button" class="btn btn-close" aria-label="Close" (click)="closeModal()"
			data-cy="DateAlertsConfigCloseButton">
			<i class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body">
		<div *ngIf="!isLoading; else loading">
			<div class="alert-edit__body" *ngIf="isEditAlert">
				<h3>Edit Alert</h3>
				<rl-alert-form [cmd]="cmd" [alert]="editingAlert" [isSubmitting]="isSubmitting"
					[templateId]="templateId" [notificationMessagesEnabled]="notificationMessagesEnabled" (onApply)="updateAlert($event)" (onCancel)="closeModal()">
				</rl-alert-form>
			</div>
		</div>
		<div *ngIf="!isLoading; else loading">
			<div class="alert-edit__body" *ngIf="!isEditAlert">
				<h3>New <strong>{{ dateAlertEditingLabel }}</strong> Alert</h3>
				<rl-alert-form [cmd]="cmd" [cmds]="cmds" [alert]="editingAlert" [isSubmitting]="isSubmitting" [templateId]="templateId" [notificationMessagesEnabled]="notificationMessagesEnabled"
					(onApply)="addNewAlert($event)" (onCancel)="closeModal()">
				</rl-alert-form>
			</div>
		</div>
	</div>
</div>

<ng-template #loading>
	<rl-loader></rl-loader>
</ng-template>