import { transition, trigger, useAnimation } from "@angular/animations";
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { first, isEmpty, isEqual, isNull, isString, isUndefined } from "lodash";
import moment from "moment";
import { animationTransitionOpacity } from "rl-common/components/animations/animations";
import { ElementsUtil } from "rl-common/components/char-data/elements/elements.util";
import { BLANK_DATE } from "rl-common/components/date-edit/date-edit.models";
import { CharDataType, CharTypeId, MaxInt } from "rl-common/consts";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaData } from "rl-common/models/i-characteristic-meta-data";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { TriggerEventOperator } from "rl-common/services/accounting-processes/models/trigger-event-operator";
import { TriggerEventType } from "rl-common/services/accounting-processes/models/trigger-event-type";
import { IWfAction } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { IOneConfigWorkflowAction, IOneConfigWorkflowProcess } from "rl-common/services/one-config/one-config.models";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { Subscription } from "rxjs";
import { AccountingProcesses } from "../accounting-processes.consts";
import { IBuildAccountingOperationTrigger } from "../build-accounting-operation/models/i-build-accounting-operation-trigger";
import { BuildAccountingProcessService } from "../build-accounting-process-modal/build-accounting-process.service";
import { IAccountingOperationTriggerForm } from "./add-accounting-operation-trigger.models";
import { DataBehaviorValueEntryComponent } from "./data-behavior-value-entry/data-behavior-value-entry.component";



interface ITemplateOption {
	charTypeId: CharTypeId;
	template: ICharacteristicTemplate;
}
@Component({
	selector: "rl-add-accounting-operation-trigger",
	templateUrl: "./add-accounting-operation-trigger.component.html",
	styleUrls: ["./add-accounting-operation-trigger.component.scss"],
	animations: [
		trigger("fadeIn", [
			transition(":enter", [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: "250ms ease-out"
					}
				})
			])
		])
	],
	imports: [ReactiveFormsModule, NgFor, NgSwitch, NgSwitchCase, NgIf, DataBehaviorValueEntryComponent]
})
export class AddAccountingOperationTriggerComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	anchorTemplate: ICharacteristicTemplate;

	@Input()
	trigger: IBuildAccountingOperationTrigger;

	@Input()
	otherTriggers: IBuildAccountingOperationTrigger[] = [];

	@Input()
	disabled = false;

	@Output()
	onSave = new EventEmitter<IBuildAccountingOperationTrigger>();

	@Output()
	onCancel = new EventEmitter();

	triggerTypes: [TriggerEventType, string][] = [
		[null, "Select"],
		[TriggerEventType.TemplateAssociation, "Template Association"],
		[TriggerEventType.WorkflowAction, "Workflow Status Change"],
		[TriggerEventType.DataBehavior, "Data Behavior"],
		// [TriggerEventType.RecordCreation, "Record Creation"], // TODO: uncomment when the record creation trigger backend work is complete
	];

	anchorTemplateOptions: ITemplateOption[] = [];
	parentTableTemplateOptions: ITemplateOption[] = [];
	templateOptionControl: FormControl<ITemplateOption>;

	form: FormGroup<IAccountingOperationTriggerForm>;
	private formKey: string;

	accountingCharTypeId = CharTypeId.Invoice;
	accountingTemplates: ICharacteristicTemplate[] = [];
	workflowProcess: IOneConfigWorkflowProcess;
	availableWorkflowActions: IOneConfigWorkflowAction[] = [];
	resultingWorkflowActions: IWfAction[] = [];
	templateCmds: ICharacteristicMetaData[] = [];
	availableOperators: AccountingProcesses.IOperatorOption[] = [];
	valueEntryType: AccountingProcesses.ValueEntryType;
	amountTemplates: ICharacteristicTemplate[] = [];
	allTableTemplates: ICharacteristicTemplate[] = [];
	tableTemplates: ICharacteristicTemplate[] = [];


	get selectedDataBehaviorCmd() {
		const characteristicId = this.form.controls.characteristicId.value;
		const cmd = this.templateCmds.find(x => x.characteristicID === characteristicId);
		return cmd;
	}

	get resultingStatus() {
		const actionId: number = this.form.controls.actionId.value;
		if (!actionId || !this.workflowProcess) {
			return ``;
		}
		return this.resultingWorkflowActions.find(x => x.actionID === actionId)?.stepName ?? `No Status Change`;
	}

	formSubs: Subscription[] = [];

	private readonly _subs: Subscription[] = [];

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _oneConfig: OneConfigService,
		private readonly _companyService: CompanyService,
		private readonly _buildAccountingProcessService: BuildAccountingProcessService
	) { }

	ngOnInit(): void {
		this.accountingTemplates = [nullCharacteristicTemplate].concat(this._oneConfig.getTemplates(this.accountingCharTypeId));
		this.amountTemplates = [nullCharacteristicTemplate]
			.concat(this._oneConfig.getTemplates(CharTypeId.Amount))
			.filter(x => x.templateID !== this.anchorTemplate.templateID);
		this.allTableTemplates = this._oneConfig.getTemplates(CharTypeId.Usage);
		this.tableTemplates = [nullCharacteristicTemplate];
		const processId = this._oneConfig.getTemplateProcess(CharTypeId.Amount, this.anchorTemplate.templateID);
		this.workflowProcess = this._oneConfig.getCharTypeProcesses(CharTypeId.Amount).find(x => x.processId === processId);
		this.availableWorkflowActions = [nullWorkflowAction].concat(this.workflowProcess.workflowActions);

		const sub = this._companyService.getWfActions(1, MaxInt.Num, processId).subscribe(response => {
			this.resultingWorkflowActions = response.data;
		});

		this.parentTableTemplateOptions = this._oneConfig.getTemplates(CharTypeId.Usage).filter(tableTemplate => {
			const foundAnchorTemplate = this._oneConfig
				.getChildAssocTemplateIds(CharTypeId.Usage, tableTemplate.templateID, CharTypeId.Amount)
				.find(templateId => templateId === this.anchorTemplate.templateID);
			return !!foundAnchorTemplate;
		}).map(tableTemplate => ({ charTypeId: CharTypeId.Usage, template: tableTemplate } as ITemplateOption));
		this.anchorTemplateOptions = [({ charTypeId: CharTypeId.Amount, template: this.anchorTemplate } as ITemplateOption)];

		this._subs.push(sub);
		this.buildForm(this.trigger);
	}

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.trigger && !isEqual(changes.trigger.previousValue, changes.trigger.currentValue)) {
			setTimeout(() => {
				this.buildForm(this.trigger);
			}, 0);
		}
	}

	setTemplateCmds() {
		this.templateCmds = [nullCmd];
		const selectedTemplateOption = this.templateOptionControl.value;
		if (selectedTemplateOption) {
			const availableCmds = AccountingProcesses.dataBehaviorCmds(this._oneConfig, selectedTemplateOption.charTypeId, selectedTemplateOption.template.templateID);
			this.templateCmds = this.templateCmds.concat(availableCmds);
		}
	}

	buildForm(trig: IBuildAccountingOperationTrigger = null) {
		this.formSubs.forEach(sub => sub.unsubscribe());

		const defaultValues = this.defaultTriggerValues();
		const merged = { ...defaultValues, ...trig };

		this.form = this._formBuilder.group<IAccountingOperationTriggerForm>({
			id: this._formBuilder.control(merged.id),
			triggerType: this._formBuilder.control(merged.triggerType, [Validators.required]),
			actionId: this._formBuilder.control(merged.actionId),
			charTypeId: this._formBuilder.control(merged.charTypeId),
			templateId: this._formBuilder.control(merged.templateId),
			characteristicId: this._formBuilder.control(merged.characteristicId),
			operator: this._formBuilder.control(merged.operator),
			value: this._formBuilder.control(this.mapCharDataValues(merged, merged.value)),
			rangeValue1: this._formBuilder.control(this.mapCharDataValues(merged, merged.rangeValue1)),
			rangeValue2: this._formBuilder.control(this.mapCharDataValues(merged, merged.rangeValue2)),
			amountTemplateId: this._formBuilder.control(merged.amountTemplateId),
			tableTemplateId: this._formBuilder.control(merged.tableTemplateId),
		}, { validators: [requiredTriggerFieldsValidator, this.rangeValidator, this.distinctValidator] });

		const triggerTypeSub = this.form.controls.triggerType.valueChanges.subscribe((type) => {
			const defaults = this.defaultTriggerValues();
			this.form.controls.actionId.setValue(defaults.actionId);
			this.form.controls.charTypeId.setValue(defaults.charTypeId);
			this.form.controls.templateId.setValue(defaults.templateId);
			this.form.controls.characteristicId.setValue(defaults.characteristicId);
			this.form.controls.operator.setValue(defaults.operator);
			this.form.controls.value.setValue(this.mapCharDataValues(defaults, defaults.value));
			this.form.controls.rangeValue1.setValue(this.mapCharDataValues(defaults, defaults.rangeValue1));
			this.form.controls.rangeValue2.setValue(this.mapCharDataValues(defaults, defaults.rangeValue2));
			this.form.controls.amountTemplateId.setValue(defaults.amountTemplateId);
			this.form.controls.tableTemplateId.setValue(defaults.tableTemplateId);

			if (type === TriggerEventType.DataBehavior || type === TriggerEventType.WorkflowAction) {
				this.form.controls.charTypeId.setValue(CharTypeId.Amount);
				this.form.controls.templateId.setValue(this.anchorTemplate.templateID);
			}
		});

		let defaultTemplateOption = first(this.anchorTemplateOptions);
		if (trig?.triggerType === TriggerEventType.DataBehavior && trig?.charTypeId && trig?.templateId) {
			defaultTemplateOption = this.anchorTemplateOptions
				.concat(this.parentTableTemplateOptions)
				.find(x => x.charTypeId === trig.charTypeId && x.template.templateID === trig.templateId);
		}
		this.templateOptionControl = new FormControl<ITemplateOption>(defaultTemplateOption);
		const templateOptionChangesSub = this.templateOptionControl.valueChanges.subscribe((selectedOption) => {
			this.form.controls.charTypeId.setValue(selectedOption.charTypeId);
			this.form.controls.templateId.setValue(selectedOption.template.templateID);
			this.form.controls.characteristicId.setValue(nullCmd.characteristicID);
			this.setTemplateCmds();
		});

		this.setTemplateCmds();

		this.setAvailableOperators(merged.characteristicId);

		const characteristicIdSub = this.form.controls.characteristicId.valueChanges.subscribe((characteristicId) => {
			this.setAvailableOperators(characteristicId);
			this.form.controls.operator.setValue(first(this.availableOperators)?.operator);
		});

		this.setValueEntryType(merged.operator);

		const operatorSub = this.form.controls.operator.valueChanges.subscribe(operator => {
			this.form.controls.value.setValue([], { emitEvent: false });
			this.form.controls.rangeValue1.setValue([], { emitEvent: false });
			this.form.controls.rangeValue2.setValue([], { emitEvent: false });
			this.setValueEntryType(operator);
		});

		const amountTemplateIdSub = this.form.controls.amountTemplateId.valueChanges.subscribe(amountTemplateId => {
			if (amountTemplateId !== defaultValues.amountTemplateId) {
				const availableTableTemplates = this.allTableTemplates.filter(tableTemplate => {
					const childAmountTemplateIds = this._oneConfig.getChildAssocTemplateIds(CharTypeId.Usage, tableTemplate.templateID, CharTypeId.Amount);
					return childAmountTemplateIds.includes(amountTemplateId);
				});
				this.tableTemplates = [nullCharacteristicTemplate].concat(availableTableTemplates);
				// reset the table template if the amount selected cannot associated
				if (!availableTableTemplates.find(tmd => tmd.templateID === this.form.controls.tableTemplateId.value)) {
					this.form.controls.tableTemplateId.setValue(defaultValues.tableTemplateId, { emitEvent: false });
				}
			}
		});

		this.formSubs = [triggerTypeSub, characteristicIdSub, operatorSub, amountTemplateIdSub, templateOptionChangesSub];
		this.formKey = `AddAccountingOperationTrigger-${trig?.id ?? "0"}`;
		this._buildAccountingProcessService.registerForm(this.formKey, this.form);
	}

	setAvailableOperators(characteristicId: number) {
		if (!characteristicId) {
			this.availableOperators = [nullOperator];
			return;
		}

		const cmd = this.templateCmds.find(x => x.characteristicID === characteristicId);
		this.availableOperators = [nullOperator].concat(AccountingProcesses.availableOperators(cmd));
	}

	setValueEntryType(operator: TriggerEventOperator) {
		if (operator >= 0) {
			const operatorOption = this.availableOperators.find(x => x.operator === operator);
			this.valueEntryType = operatorOption?.entryType ?? AccountingProcesses.ValueEntryType.none;
		}
	}

	private mapCharDataValues(trig: IBuildAccountingOperationTrigger, value: string): ICharacteristicData[] {
		if (!value || isEmpty(value)) {
			return null;
		}
		const tmd = this._oneConfig.getTemplateMetaData(trig.charTypeId, trig.templateId);
		return AccountingProcesses.CharDataValueMapping.fromTriggerValues(tmd, trig.characteristicId, value);
	}

	defaultTriggerValues(): IBuildAccountingOperationTrigger {
		return {
			id: null,
			triggerType: null,
			actionId: null,
			charTypeId: CharTypeId.Amount,
			templateId: null,
			characteristicId: null,
			operator: null,
			value: null,
			rangeValue1: null,
			rangeValue2: null,
			amountTemplateId: null,
			tableTemplateId: null,
		} as IBuildAccountingOperationTrigger;
	}

	private toTrigger(form: FormGroup<IAccountingOperationTriggerForm>) {
		const charTypeId = form.controls.charTypeId.value;
		const templateId = form.controls.templateId.value;
		const characteristicId = form.controls.characteristicId.value;
		const tmd = this._oneConfig.getTemplateMetaData(charTypeId, templateId);

		const trig: IBuildAccountingOperationTrigger = {
			id: form.controls.id.value,
			triggerType: form.controls.triggerType.value,
			actionId: form.controls.actionId.value ?? 0,
			charTypeId: form.controls.charTypeId.value,
			templateId,
			characteristicId: form.controls.characteristicId.value ?? 0,
			operator: form.controls.operator.value ?? 0,
			value: AccountingProcesses.CharDataValueMapping.toTriggerValues(tmd, characteristicId, form.controls.value.value),
			rangeValue1: AccountingProcesses.CharDataValueMapping.toTriggerValues(tmd, characteristicId, form.controls.rangeValue1.value),
			rangeValue2: AccountingProcesses.CharDataValueMapping.toTriggerValues(tmd, characteristicId, form.controls.rangeValue2.value),
			amountTemplateId: form.controls.amountTemplateId.value,
			tableTemplateId: form.controls.tableTemplateId.value
		};
		return trig;
	}

	cancel() {
		this.onCancel.emit();
	}

	save() {
		const trig = this.toTrigger(this.form);
		this.onSave.emit(trig);
	}

	private rangeValidator: ValidatorFn = (form: FormGroup<IAccountingOperationTriggerForm>): ValidationErrors | null => {
		const triggerType = form.controls.triggerType.value;
		const operator = form.controls.operator.value;
		const rangeValue1 = form.controls.rangeValue1.value;
		const rangeValue2 = form.controls.rangeValue2.value;
		if (triggerType === TriggerEventType.DataBehavior && operator === TriggerEventOperator.Between && !isEmpty(rangeValue1) && !isEmpty(rangeValue2)) {
			const characteristicId = form.controls.characteristicId.value;
			const cmd = this.templateCmds.find(x => x.characteristicID === characteristicId);
			const startCd = first(rangeValue1);
			const endCd = first(rangeValue2);
			if (!!cmd && !isEmpty(startCd.value) && !isEmpty(endCd.value)) {
				switch (cmd.dataTypeID) {
					case CharDataType.Number:
						const startFloat = parseFloat(startCd.value);
						const endFloat = parseFloat(endCd.value);
						if (startFloat >= endFloat) {
							return { invalidRange: true };
						}
						break;
					case CharDataType.Currency:
					case CharDataType.FourDigitYear:
					case CharDataType.Percentage:
						const startInt = parseInt(startCd.value);
						const endInt = parseInt(endCd.value);
						if (startInt >= endInt) {
							return { invalidRange: true };
						}
						break;
					case CharDataType.Money:
						const startMoney = ElementsUtil.parseFloatOrLocalAmount(startCd.value);
						const endMoney = ElementsUtil.parseFloatOrLocalAmount(endCd.value);
						if (startMoney >= endMoney) {
							return { invalidRange: true };
						}
						break;
					case CharDataType.Date:
						const startDate = startCd.value;
						const endDate = endCd.value;
						const startMoment = moment(startDate, "MM/DD/YYYY", true);
						const endMoment = moment(endDate, "MM/DD/YYYY", true);
						if (startMoment.isValid() && endMoment.isValid() && startDate !== BLANK_DATE && endDate !== BLANK_DATE) {
							if (startMoment.isSame(endMoment) || startMoment.isAfter(endMoment)) {
								return { invalidRange: true };
							}
						}
						break;
				}
			}
		}
		return null;
	};

	private distinctValidator: ValidatorFn = (form: FormGroup<IAccountingOperationTriggerForm>): ValidationErrors | null => {
		const trig = this.toTrigger(form);
		const existingTriggerKeys = this.otherTriggers.map(t => this.triggerKey(t));
		const triggerKey = this.triggerKey(trig);
		const hasDupeKey = existingTriggerKeys.some(key => key === triggerKey);
		if (hasDupeKey) {
			return { duplicateTriggers: true };
		}
		const hasWorkFlowAlready = this.otherTriggers.some(x => x.triggerType === TriggerEventType.WorkflowAction);
		if (trig.triggerType === TriggerEventType.WorkflowAction && hasWorkFlowAlready) {
			return { duplicateWorkflow: true };
		}

		return null;
	};

	private triggerKey(trig: IBuildAccountingOperationTrigger) {
		// generates a distinct key per trigger
		const keyValuePairs = Object.keys(trig)
			.filter(key => key !== "id") // don't include the trigger id in our key function
			.map(key => {
				const value = `${key}:${trig[key]?.toString() ?? ""}`;
				const tuple: [string, string] = [key, value];
				return tuple;
			});

		// we must guarantee order every time this method is called
		keyValuePairs.sort((x, y) => x[0].localeCompare(y[0]));

		return keyValuePairs.map(x => x[1]).join("-");
	}

	ngOnDestroy(): void {
		this.formSubs.forEach(sub => sub.unsubscribe());
		this._subs.forEach(sub => sub.unsubscribe());
		if (this.formKey) {
			this._buildAccountingProcessService.unregisterForm(this.formKey);
		}
	}
}

const nullCharacteristicTemplate = { templateID: null, templateName: "Select" } as ICharacteristicTemplate;
const nullWorkflowAction = { actionId: null, actionName: "Select" } as IOneConfigWorkflowAction;
const nullCmd = { characteristicID: null, label: "Select" } as ICharacteristicMetaData;
const nullOperator = { operator: null, label: "Select" } as AccountingProcesses.IOperatorOption;

export const requiredTriggerFieldsValidator: ValidatorFn = (form: FormGroup<IAccountingOperationTriggerForm>): ValidationErrors | null => {
	const triggerType = form.controls.triggerType.value;

	const requiredValues: any[] = [];
	switch (triggerType) {
		case TriggerEventType.TemplateAssociation:
			requiredValues.push(form.controls.templateId.value);
			break;
		case TriggerEventType.WorkflowAction:
			requiredValues.push(form.controls.actionId.value);
			break;
		case TriggerEventType.DataBehavior:
			requiredValues.push(form.controls.characteristicId.value);
			requiredValues.push(form.controls.operator.value);
			const operator = form.controls.operator.value;
			if (operator != null && operator !== undefined) {
				const valueEntryType = AccountingProcesses.entryType(operator);
				switch (valueEntryType) {
					case AccountingProcesses.ValueEntryType.range:
						const range1CharData = form.controls.rangeValue1.value;
						requiredValues.push(first(range1CharData)?.value);
						const range2CharData = form.controls.rangeValue2.value;
						requiredValues.push(first(range2CharData)?.value);
						break;
					case AccountingProcesses.ValueEntryType.single:
						const valueCharData = form.controls.value.value;
						requiredValues.push(first(valueCharData)?.value);
						break;
				}
			}
			break;
		case TriggerEventType.RecordCreation:
			requiredValues.push(form.controls.amountTemplateId.value);
			requiredValues.push(form.controls.tableTemplateId.value);
			break;
	}

	const missingValues = requiredValues.filter(val => isNull(val) || isUndefined(val) || (isString(val) && isEmpty(val)));
	if (!isEmpty(missingValues)) {
		return { requiredFieldsMissing: true };
	}

	return null;
};
